import {
  faCheck,
  faClone,
  faMinus,
  faPause,
  faPlay,
  faPlus,
  faSearch,
  faTimesCircle,
  faTrash,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { useDropzone } from 'react-dropzone';
import ReactJoyride, { ACTIONS, EVENTS } from 'react-joyride';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import styled from 'styled-components';

import { makeAlert } from 'actions';
import {
  addImages,
  editImages,
  fetchImagesByRestaurantId,
  removeImages,
  uploadImage,
} from 'api/images';
import {
  addIngredient,
  addItem,
  deleteItem,
  duplicateItem,
  editIngredient,
  editItem,
  getDiets,
  getIngredients,
  getItemById,
  getItemsByRestaurantId,
  getRestaurantModifiers,
} from 'api/items';
import { getMenuAndCategoryTitleByRestaurantId } from 'api/menus';
import { Colors } from 'assets/colors';
import { generateURLWithParams, parseString } from 'utils';

import { Images } from 'assets/image';
import CircleButton from 'components/button/CircleButton';
import FormButton from 'components/button/FormButton';
import ConfirmDeleteModal from 'components/confirmDeleteModal';
import EditorComponent from 'components/editorComponent';
import Input, { Label } from 'components/forms/Input';
import ImageUploader from 'components/imageUploader';
import { AddMenuCard } from 'components/Menu/MenuCard';
import { Heading } from 'components/typography';
import { getRestaurantTax, getTaxes } from '../../api/restaurant';
import { colors, IMAGE_VALID_FORMATS, IMAGE_VALID_SIZE } from '../../constants';
import { getData } from '../../utils';
import { MenusTabs } from '../menus/Preview';

import 'react-lazy-load-image-component/src/effects/blur.css';

let localId = 0;
let ingName = '';
const initialForm = {
  name: '',
  value: '',
  value_label: '',
  value2: null,
  value2_label: '',
  value3: null,
  value3_label: '',
  images: [],
  description: '',
  item_diet: [],
  category: [],
  validImageFileType: true,
  priceOptions: [
    {
      title: '',
      price: null,
      localId,
    },
  ],
  tax: [],
};

function Tag(props) {
  return (
    <IngredientBallon disabled={props.disabled}>
      <span>{props.label}</span>
      {!props.disabled && (
        <FontAwesomeIcon
          icon={faTimesCircle}
          color={Colors.primary1}
          style={{ marginLeft: '15px' }}
          onClick={props.onClick}
        />
      )}
    </IngredientBallon>
  );
}

function AddNewItem({ locale, ...props }) {
  const screentype = props.location.pathname.split('/')[3];
  const editItemId =
    props.location.pathname.split('/').slice(-1)[0] !== 'add'
      ? props.location.pathname.split('/').slice(-1)[0]
      : false;

  const [form, setForm] = useState(initialForm);
  const [errors, setErrors] = useState([]);
  const [actionLoading, setActionLoading] = useState(false);
  const [modifiers, setModifiers] = useState([]);
  const [menuAndCategoryDetail, setMenuAndCategoryDetail] = useState([]);
  const [allImages, setAllImages] = useState([]);
  const [addLoading, setAddLoading] = useState(false);
  const [diets, setDiets] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [selectedModifiers, setSelectedModifiers] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedImage, setSelectedImage] = useState([]);
  const [onShowUploader, setOnShowUploader] = useState(false);
  const [duplicateLoading, setDuplicateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [pageMainLoading, setPageMainLoading] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [categoryPageNumber, setCategoryPageNumber] = useState(1);
  const [noMorePage, setNoMorePage] = useState(false);
  const [restaurantTax, setRestaurantTax] = useState([]);
  const [extraFields, setExtraFields] = useState({});
  const [extraFieldsErrors, setExtraFieldsErrors] = useState({});
  const selectedRestaurantData = useSelector(
    (state) => state.user.selectedRestaurantData,
  );

  const required = ['name', 'value', 'image', 'description'];

  const onDrop = useCallback((files) => {
    const reader = new FileReader();
    const url = reader.readAsDataURL(files[0]);
    const filesize = (files[0].size / 1000).toFixed(4); // KB
    const validFileType = IMAGE_VALID_FORMATS.includes(
      files[0].type.split('image/')[1],
    );

    setErrors([]);
    const tempErrors = [];

    if (filesize > IMAGE_VALID_SIZE) tempErrors.push('imageFileSize');
    if (!validFileType) tempErrors.push('validImageFileType');
    if (tempErrors.length === 0) {
      reader.onloadend = function (e) {
        setAddLoading(true);
        props
          .uploadImage({
            image: files[0],
            restaurants: [props.selectedRestaurant],
          })
          .then((res) => {
            setOnShowUploader(false);
            setAddLoading(false);
            setAllImages((prevState) => [getData(res), ...prevState]);
            setSelectedImage((prevState) => (!props.multiple
              ? [getData(res)]
              : [getData(res), ...prevState]));
          })
          .catch((e) => {
            setOnShowUploader(false);
            setAddLoading(false);
            console.log('error in uploading image', e);
          });
      };
    } else setErrors(tempErrors);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleChangeOptions = (value, id, name, index) => {
    let values = {};
    const temp = form.priceOptions.map((o) => {
      if (o.localId !== id) return o;
      o[name] = value;
      return o;
    });
    switch (id) {
    case 0:
      if (name === 'price') {
        values = { ...values, value };
      } else {
        values = { ...values, value_label: value };
      }
      break;
    case 1:
      if (name === 'price') {
        values = { ...values, value2: value };
      } else {
        values = { ...values, value2_label: value };
      }
      break;
    case 2:
      if (name === 'price') {
        values = { ...values, value3: value };
      } else {
        values = { ...values, value3_label: value };
      }
      break;
    case 3:
      if (name === 'price') {
        values = { ...values, value4: value };
      } else {
        values = { ...values, value4_label: value };
      }
      break;
    }
    setForm({ ...form, ...values, priceOptions: temp });
  };

  const handleTaxChange = (event, action) => {
    if (action.action === 'clear') {
      setForm({ ...form, tax: [] });
    } else if (action.action === 'remove-value') {
      let newTags = Array.from(form.tax);
      newTags = newTags.filter((tag) => tag.id !== action.removedValue.value);
      setForm({ ...form, tax: newTags });
    } else if (action.action === 'select-option') {
      setForm({ ...form, tax: [...form.tax, event] });
    }
  };

  const handleTaxInputChange = async (value, action) => {
    if (value !== '') {
      const res = await props.getTaxes(value, props.selectedRestaurant);
      return getData(res)?.results.map((tag) => ({
        label: tag.title,
        value: tag.id,
      }));
    }
  };

  const handleAddOption = () => {
    localId = form.priceOptions[form.priceOptions.length - 1].localId + 1;
    setForm({
      ...form,
      priceOptions: [
        ...form.priceOptions,
        {
          title: '',
          price: null,
          localId,
        },
      ],
    });
  };

  const formHandleChange = (e) => {
    const { value, name, files } = e.target;
    setForm({ ...form, [name]: value });
  };

  // const noEventFormHandleChange = (value, name) => {
  //   setForm({ ...form, [name]: value })
  // }

  const handleDietChange = (event, action) => {
    if (action.action === 'clear') {
      setForm({ ...form, item_diet: [] });
    } else if (action.action === 'remove-value') {
      let newIng = Array.from(form.item_diet);
      newIng = newIng.filter((ing) => ing.value !== action.removedValue.value);
      setForm({ ...form, item_diet: newIng });
    } else if (action.action === 'pop-value') {
    } else if (action.action !== 'create-option') {
      setForm({
        ...form,
        item_diet: [...form.item_diet, action.option],
      });
    }
  };

  const removePrice = (n) => {
    localId--;
    let option = form.priceOptions;
    switch (n) {
    case 3:
      setForm({
        ...form,
        value4: null,
        value4_label: null,
        priceOptions: form.priceOptions.filter((o) => o.localId !== 3),
      });
      break;
    case 2:
      if (form.priceOptions[3]) {
        option = option.filter((o) => o.localId !== 2);
        option[2].localId = 2;
        setForm({
          ...form,
          value4: null,
          value4_label: null,
          value3: form.value4,
          value3_label: form.value3_label,
          priceOptions: option,
        });
      } else {
        setForm({
          ...form,
          value3: null,
          value3_label: null,
          priceOptions: form.priceOptions.filter((o) => o.localId !== 2),
        });
      }
      break;
    case 1:
      if (form.priceOptions[3]) {
        option = option.filter((o) => o.localId !== 1);
        option[2].localId = 2;
        option[1].localId = 1;
        setForm({
          ...form,
          value4: null,
          value4_label: null,
          value3: form.value4,
          value3_label: form.value4_label,
          value2: form.value3,
          value2_label: form.value3_label,
          priceOptions: option,
        });
      } else if (form.priceOptions[2]) {
        option = option.filter((o) => o.localId !== 1);
        option[1].localId = 1;
        setForm({
          ...form,
          value3: null,
          value3_label: null,
          value2: form.value3,
          value2_label: form.value3_label,
          priceOptions: option,
        });
      } else {
        setForm({
          ...form,
          value2: null,
          value2_label: null,
          priceOptions: form.priceOptions.filter((o) => o.localId !== 1),
        });
      }
      break;
    }
  };

  // const handleIngredientInputChange = async (value, action) => {
  //   ingName = value;
  //   // setIngredients([{name: 'loading...'}])
  //   const res = await props.getIngredients(value);
  //   return getData(res)?.results.map((ingredient) => ({
  //     label: ingredient.name,
  //     value: ingredient.id,
  //   }));
  // };

  // const handleIngredientChange = (event, action) => {
  //   /**
  //    * add exist ingridient ti list
  //    * create ingridient handled in on onCreate method
  //    */
  //   const tempErrors = errors;
  //   if (event && event.length > 80) {
  //     tempErrors.push('ingredintLength');
  //     setErrors(tempErrors);
  //     return;
  //   }
  //   tempErrors.filter((error) => error !== 'ingredintLength');
  //   setErrors(tempErrors);

  //   if (action.action === 'clear') {
  //     setForm({ ...form, item_ingredient: [] });
  //   } else if (action.action === 'remove-value') {
  //     let newIng = Array.from(form.item_ingredient);
  //     newIng = newIng.filter((ing) => ing.value !== action.removedValue.value);
  //     setForm({ ...form, item_ingredient: newIng });
  //   } else if (action.action === 'pop-value') {
  //   } else if (action.action !== 'create-option') {
  //     setForm({
  //       ...form,
  //       item_ingredient: [...form.item_ingredient, action.option],
  //     });
  //   }
  // };

  const onCreate = (name) => {
    const newIngs = [...form.item_ingredient, { label: name, value: name }];
    setForm({
      ...form,
      item_ingredient: newIngs,
    });
    setActionLoading(true);
    props
      .addIngredient(name)
      .then((res) => {
        setForm((form) => ({
          ...form,
          item_ingredient: [
            ...form.item_ingredient.map((ing) => {
              if (ing.value === name) {
                ing.value = getData(res)?.id;
              }

              return ing;
            }),
          ],
        }));
        setActionLoading(false);
      })
      .catch((e) => {
        setActionLoading(false);
        console.log('error in creating items: ', e);
      });
  };

  const testExtraFields = () => {
    /** There are four things:
     * numbers:
     * max_integer_value
     * min_integer_value
     * Text:
     * max_string_length
     * min_string_length */
    setExtraFieldsErrors({});
    const labelsData = selectedRestaurantData?.parent?.extra_item_fields;
    /** Check each label to not exceed max sum */
    const temp = {};
    for (const labelName in extraFields) {
      const data = labelsData.find((label) => label.name === labelName);
      if (
        data?.max_integer_value &&
        Number(extraFields[labelName]) > Number(data?.max_integer_value)
      ) {
        temp[
          labelName
        ] = `${data?.form_label} cannot be more than ${data?.max_integer_value}`;
      }

      if (
        data?.min_integer_value &&
        Number(extraFields[labelName]) < Number(data?.min_integer_value)
      ) {
        temp[
          labelName
        ] = `${data?.form_label} cannot be less than ${data?.min_integer_value}`;
      }

      if (
        data?.max_string_length &&
        extraFields[labelName]?.length > data?.max_string_length
      ) {
        temp[
          labelName
        ] = `${data?.form_label} cannot be more than ${data?.max_string_length} characters`;
      }
      if (
        data?.min_string_length &&
        extraFields[labelName]?.length < data?.min_string_length
      ) {
        temp[
          labelName
        ] = `${data?.form_label} cannot be less than ${data?.min_string_length} characters`;
      }
    }

    /** Check all required fields to be defined */
    for (const i in labelsData) {
      const extraFieldObj = Object.keys(extraFields)?.find(
        (item) => item === labelsData[i]?.name,
      );

      if (
        labelsData[i]?.is_required &&
        (!extraFieldObj || !extraFields[extraFieldObj])
      ) {
        temp[labelsData[i]?.name] = `${labelsData[i]?.form_label} is required`;
      }
    }
    setExtraFieldsErrors(temp);
    return temp;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors([]);
    testExtraFields();

    const tempErrors = [];
    required.forEach((key, index) => {
      if (!form[key] && Object.keys(initialForm).includes(key)) { tempErrors.push(key); }
    });

    if (form.value < 0) {
      tempErrors.push('negativePrice');
    }
    if (form.imageFileSize > 201 && form.imageFile) {
      tempErrors.push('imageFileSize');
    }
    if (!form.validImageFileType && form.imageFile) {
      tempErrors.push('validImageFileType');
    }

    console.log(tempErrors);
    if (
      tempErrors.length === 0 &&
      Object.keys(testExtraFields())?.length === 0
    ) {
      screentype === 'add' ? handleAddItem() : handleEditItem();
    } else {
      setErrors(tempErrors);
      props.makeAlert('Some required fields are still empty!', 'error');
    }
  };

  const handleAddItem = () => {
    setActionLoading(true);
    // const ing = form.item_ingredient
    //   .filter((item) => item)
    //   .map((item) => item?.value);
    const diet = form.item_diet.filter((item) => item).map((item) => item?.value);
    const tax = form.tax.map((item) => item.value);

    let data = {
      restaurant: props.selectedRestaurant,
      images: form.images,
      name: form.name,
      value: form.value,
      value_label: form.value_label,
      description: form.description,
      // item_ingredient_ids: ing,
      item_diet_ids: diet,
      tax_ids: tax,
      modifier_ids: selectedModifiers,
      category: selectedCategory,
      contains_alcohol: form.contains_alcohol,
    };

    if (form.value2) {
      data = {
        ...data,
        value2: form.value2,
        value2_label: form.value2_label,
      };
    }

    if (form.value3) {
      data = {
        ...data,
        value3: form.value3,
        value3_label: form.value3_label,
      };
    }
    if (form.value4) {
      data = {
        ...data,
        value4: form.value4,
        value4_label: form.value4_label,
      };
    }
    if (extraFields && Object.keys(extraFields)?.length) {
      const obj = {};
      const extaArr = selectedRestaurantData?.parent?.extra_item_fields;
      for (let i = 0; i < extaArr?.length; i++) {
        const label = extaArr[i];
        obj[label.name] = extraFields[label.name];
      }
      data = {
        ...data,
        extra: obj,
      };
    }
    props
      .addItem(data, form.imageFile)
      .then((res) => {
        if (getData(res)?.id) {
          props.makeAlert(locale.item_added_alert);
          if (selectedImage.length > 0) {
            handleAddImage(getData(res)?.id);
          } else {
            setActionLoading(false);
            onSuccess();
          }
        }
      })
      .catch((e) => {
        props.makeAlert(locale.item_did_not_added_alert, 'error');
        setActionLoading(false);
      });
  };

  const handleAddImage = (itemId) => {
    // let images = items.filter(item => selected.includes(item.id))
    selectedImage.forEach((image) => {
      const data = { items: [...image.items, itemId] };
      props
        .editImages(image.id, data)
        .then((res) => {
          setActionLoading(false);
          onSuccess();
        })
        .catch((e) => {
          setActionLoading(false);
          console.log('error in attaching image', e);
        });
    });
  };

  const onSuccess = () => {
    const pathname = `/console/${props.location._back || 'create-items'}`;
    props.history.push(
      generateURLWithParams(pathname, props.location._backParams),
    );
  };

  const updateImages = (prevImages, newImages) => {
    const newImagesId = newImages.map((img) => img.id);
    const prevImagesId = prevImages.map((id) => id);
    prevImages.forEach((id) => {
      if (!newImagesId.includes(id)) {
        props.removeImages(id, {
          type: 'ITEM',
          id: editItemId,
        });
      }
    });
    newImages.forEach((image) => {
      if (!prevImagesId.includes(image.id)) {
        // props.editImages(image.id, data)
        props.addImages(image.id, {
          type: 'ITEM',
          id: editItemId,
        });
      }
    });
  };

  const handleEditItem = () => {
    setActionLoading(true);
    const ing = form.item_ingredient.map((item) => item.value);
    const diet = form.item_diet.map((item) => item.value);
    const tax = form.tax.map((item) => item.value);

    let data = {
      name: form.name,
      value: parseFloat(form.value),
      images: form.images,
      value_label: form.value_label,
      value2: form.value2,
      value2_label: form.value2_label,
      value3: form.value3,
      value3_label: form.value3_label,
      value4: form.value4,
      value4_label: form.value4_label,
      description: form.description,
      item_ingredient_ids: ing,
      item_diet_ids: diet,
      tax_ids: tax,
      modifier_ids: selectedModifiers,
      category: selectedCategory,
      contains_alcohol: form.contains_alcohol,
    };

    if (extraFields && Object.keys(extraFields)?.length) {
      const obj = {};
      const extaArr = selectedRestaurantData?.parent?.extra_item_fields;
      for (let i = 0; i < extaArr?.length; i++) {
        const label = extaArr[i];
        obj[label.name] = extraFields[label.name];
      }
      data = {
        ...data,
        extra: obj,
      };
    }
    // handleOnhide()

    /** if images chenged update theme */
    // if (props.onEdit) props.onEdit({ id: props.id, ...data, images: form.selected || form.images })
    // if (props.updateItems) props.updateItems({ ...data, images: selectedImage, id: props.id }, "update")

    // if (props.updateItems) props.updateItems({}, "add_loading", props.id)
    // const timer = setTimeout(() => {
    //   if (form.images && form.selected) updateImages(form.images, form.selected)

    if (form.images && selectedImage) updateImages(form.images, selectedImage);
    props
      .editItem(editItemId, data)
      .then((res) => {
        if (getData(res)) {
          /**  update Items List */
          setActionLoading(false);
          onSuccess();
        }
      })
      .catch((e) => {
        setActionLoading(false);
        props.makeAlert(locale.item_did_not_updated_alert, 'error');
      });

    // const handleUndo = (t) => {
    //   clearTimeout(timer)
    //   // if (props.updateItems) props.updateItems({}, "update")
    //   if (props.updateItems) props.updateItems({ ...initial, images: form.images, id: props.id }, "update")
    //
    //   setActionLoading(false)
    // }

    // props.makeAlert(parseString(locale.item_updated_alert, UNDO_PERIOD / 1000), null, handleUndo)
  };

  const selectImageFunction = (item) => {
    const isExist = selectedImage.find((e) => e.id === item.id);

    if (isExist) handleDeleteImage(item.id);
    else {
      setSelectedImage([item]);
      // setForm({ ...form, images: [item.id] })
    }
  };

  const handleSetMofifier = (id, title) => {
    let newModifier = selectedModifiers;
    const current = newModifier.find((i) => i === id);
    if (current) {
      newModifier = newModifier.filter((i) => i !== current);
    } else {
      newModifier = [...newModifier, id];
    }
    setSelectedModifiers(newModifier);
  };

  const handleDietInputChange = async (value, action) => {
    if (ingName !== value) {
      ingName = value;
      return [];
      // const res = await props.getDiets(value);
      // return getData(res)?.results.map((ingredient) => ({
      //   label: ingredient.title,
      //   value: ingredient.id,
      // }));
    }
  };

  const handleAddToGategory = (catId) => {
    let newCategory = selectedCategory;
    const current = selectedCategory.find((i) => i === catId);
    if (current) {
      newCategory = selectedCategory.filter((i) => i !== current);
    } else {
      newCategory = [...selectedCategory, catId];
    }
    setSelectedCategory(newCategory);
  };

  const handleDeleteImage = (id) => {
    const tmp = Array.from(selectedImage);
    const index = tmp.findIndex((el) => el.id === id);
    if (index > -1) tmp.splice(index, 1);
    setSelectedImage(tmp);
    // setForm({ ...form, images: tmp.map(t => t.id) })
  };

  const handleRemoveBalonItem = (item, key) => {
    let newIng = Array.from(form.item_ingredient);
    let newDiet = Array.from(form.item_diet);
    let newTags = Array.from(form.tax);
    switch (key) {
    case 'INGREDIENT':
      newIng = newIng.filter((ing) => ing.value !== item.value);
      setForm({ ...form, item_ingredient: newIng });
      break;
    case 'DIET':
      newDiet = newDiet.filter((ing) => ing.value !== item.value);
      setForm({ ...form, item_diet: newDiet });
      break;
    case 'TAX':
      newTags = newTags.filter((tag) => tag.value !== item.value);
      setForm({ ...form, tax: newTags });
    }
  };

  const handleDeleteItem = (e) => {
    setConfirmDeleteModal(false);
    e.preventDefault();
    setDeleteLoading(true);
    props
      .deleteItem(editItemId)
      .then((res) => {
        setDeleteLoading(false);
        onSuccess();
        props.makeAlert(parseString(locale.item_deleted_alert, 'success'));
      })
      .catch((e) => {
        setDeleteLoading(false);
        props.makeAlert(locale.item_did_not_deleted_alert, 'error');
      });

    // const timer = setTimeout(() => {
    //
    // }, UNDO_PERIOD)

    // const handleUndo = (t) => {
    //   clearTimeout(timer)
    //   props.setItems(items)
    // }
  };

  const handleBlockSymbol = (evt) => {
    switch (evt.key) {
    case '-':
      evt.preventDefault();
      break;
    case '+':
      evt.preventDefault();
      break;
    case 'e':
      evt.preventDefault();
      break;
    default:
    }
  };

  const customStylesAsyncSelect = {
    option: (provided, state) => ({
      ...provided,
    }),
    control: () => ({
      borderRadius: '5px',
      border: `px solid ${Colors.primary1}`,
      display: 'flex',
      flexDirection: 'row',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
  };

  const handleDuplicateItem = () => {
    setDuplicateLoading(true);
    props
      .duplicateItem(editItemId)
      .then((res) => {
        props.makeAlert('Item Duplicated', 'success');
        setDuplicateLoading(false);
        onSuccess();
      })
      .catch((e) => {
        setDuplicateLoading(false);
        console.log('error in DuplicateItem', e);
      });
  };

  const handleLoadMoreCat = () => {
    setCategoryPageNumber(categoryPageNumber + 1);
    props
      .getMenuAndCategoryTitleByRestaurantId(
        props.selectedRestaurant,
        categoryPageNumber,
      )
      .then((res) => {
        setMenuAndCategoryDetail([
          ...menuAndCategoryDetail,
          ...getData(res)?.results,
        ]);
      })
      .catch((e) => {
        if (e.status === 404) {
          setNoMorePage(true);
        }
        console.log('menuAndCategoryDetail erors', e);
      });
  };

  useEffect(() => {
    if (
      props.selectedRestaurant &&
      (props.permissions.includes('__panel__') ||
        props.permissions.includes('menus'))
    ) {
      props.getRestaurantTax(props.selectedRestaurant).then((res) => {
        const results = getData(res)?.results.map((r) => ({
          label: r.title,
          value: r.id,
        }));
        setRestaurantTax(results);
      });

      props
        .getRestaurantModifiers(props.selectedRestaurant)
        .then((res) => {
          setModifiers(getData(res));
        })
        .catch((e) => {
          console.log('setModifiers errors', e);
        });
      props
        .getMenuAndCategoryTitleByRestaurantId(props.selectedRestaurant)
        .then((res) => {
          setMenuAndCategoryDetail(getData(res)?.results);
        })
        .catch((e) => {
          console.log('menuAndCategoryDetail errors', e);
        });
      props
        .fetchImagesByRestaurantId(1, props.selectedRestaurant)
        .then((res) => {
          setAllImages(getData(res));
        })
        .catch((e) => {
          console.log('get images errors', e);
        });
    }
  }, []);

  useEffect(() => {
    setNoMorePage(false);
    // props.getIngredients().then((res) => {
    // setIngredients(getData(res)?.results);
    // props.getDiets().then((res) => {
    // setDiets(getData(res)?.results);
    setDiets([]);
    // });
    // });
  }, []);

  useEffect(() => {
    if (editItemId) {
      setPageMainLoading(true);
      props
        .getItemById(editItemId)
        .then((res) => {
          setPageMainLoading(false);
          let priceOptions = [];
          if (getData(res)?.value) {
            priceOptions = [
              {
                title: getData(res)?.value_label,
                price: getData(res)?.value,
                localId: 0,
              },
            ];
          }
          if (getData(res)?.value2) {
            priceOptions = [
              ...priceOptions,
              {
                title: getData(res)?.value2_label,
                price: getData(res)?.value2,
                localId: 1,
              },
            ];
          }
          if (getData(res)?.value3) {
            priceOptions = [
              ...priceOptions,
              {
                title: getData(res)?.value3_label,
                price: getData(res)?.value3,
                localId: 2,
              },
            ];
          }
          if (getData(res)?.value4) {
            priceOptions = [
              ...priceOptions,
              {
                title: getData(res)?.value4_label,
                price: getData(res)?.value4,
                localId: 3,
              },
            ];
          }
          if (
            getData(res)?.extra_data &&
            Object.keys(getData(res)?.extra_data)?.length
          ) {
            setExtraFields({ ...getData(res)?.extra_data });
          }
          setForm((prevState) => ({
            ...prevState,
            ...getData(res),
            images: getData(res)?.images.map((i) => i.id),
            priceOptions,
            description: getData(res)?.description,
            item_ingredient: (getData(res)?.item_ingredient || []).map((item) => ({
              label: item.name_org || item.name,
              value: item.id,
            })),
            item_diet: [],
            tax: getData(res)?.tax.map((t) => ({
              label: t.title,
              value: t.id,
            })),
          }));
          setSelectedImage(getData(res)?.images);
          setSelectedCategory(getData(res)?.category);
          setSelectedModifiers(
            getData(res)?.modifiers.map((option) => option.id),
          );
        })
        .catch((e) => {
          console.log('error in getting item for edit', e);
          setPageMainLoading(false);
        });
    } else {
      setForm({
        ...form,
        priceOptions: [{ title: '', price: null, localId: 0 }],
      });
    }
  }, [editItemId]);

  // console.log(form.tax);
  return (
    <Row
      style={{
        marginBottom: 125,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      {pageMainLoading ? (
        <SpinnerContainer>
          <Spinner animation="border" size="lg" />
        </SpinnerContainer>
      ) : (
        <>
          <Col
            md={12}
            style={{
              paddingTop: 100,
              paddingBottom: 25,
            }}
          >
            <Heading>{screentype === 'add' ? 'NEW ITEM' : 'EDIT ITEM'}</Heading>
          </Col>
          <Col lg={5} md={5} sm={12}>
            <Input
              id="cypress_item_name"
              label="Item Name"
              name="name"
              onChange={formHandleChange}
              value={form.name}
              errorText={errors.map((error) => (error === 'name' ? `${error} is required!` : null))}
              maxLength={50}
            />
            <Input
              id="cypress_item_description"
              label="Description"
              style={{ display: 'flex', flexDirection: 'column' }}
              containerStyle={{ marginTop: '3rem' }}
              as="textarea"
              rows={4}
              editor={false}
              name="description"
              onChange={(e) => formHandleChange(e, 'description')}
              value={form.description}
              errorText={errors.map((error) => (error === 'description' ? `${error} is required!` : null))}
              maxLength={1000}
            />
            {/* Metrics */}
            {selectedRestaurantData?.parent?.extra_item_fields?.map(
              (labelObj, index) => (
                <>
                  <Row className="mt-5 mb-3">
                    <Col md={12}>
                      <Label>{labelObj?.form_label}</Label>
                      <p>{labelObj?.form_hint}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Input
                        type={
                          labelObj?.field_type === 'number' ? 'tel' : 'string'
                        }
                        value={extraFields[labelObj?.name]}
                        onKeyDown={(e) => {
                          if (labelObj?.field_type === 'number') {
                            ['e', 'E', '+', '-'].includes(e.key) &&
                              e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          if (labelObj?.field_type === 'number') {
                            if (e.target.value === '' || e.target.value >= 0) {
                              const temp = { ...extraFields };
                              temp[labelObj?.name] = e.target.value;
                              setExtraFields(temp);
                            }
                          } else {
                            const temp = { ...extraFields };
                            temp[labelObj?.name] = e.target.value;
                            setExtraFields(temp);
                          }
                        }}
                        errorText={
                          extraFieldsErrors[labelObj?.name]
                            ? extraFieldsErrors[labelObj?.name]
                            : null
                        }
                      />
                    </Col>
                    {labelObj?.unit ? (
                      <Col md={4} style={{ paddingTop: '13px' }}>
                        <span>{labelObj?.unit}</span>
                      </Col>
                    ) : null}
                  </Row>
                </>
              ),
            )}

            <Row className="mt-5 mb-3">
              <Col md={4}>
                <Label>Price</Label>
              </Col>
              <Col md={6}>
                <Label>Options</Label>
              </Col>
            </Row>
            {form.priceOptions.map((o, index) => (
              <Row key={o.localId} style={{ marginTop: '-20px' }}>
                <Col md={4}>
                  <Input
                    id="cypress_item_price"
                    min="0"
                    type="tel"
                    pattern="[0-9]*"
                    value={o.price}
                    onKeyDown={(evt) => handleBlockSymbol(evt)}
                    onChange={(e) => handleChangeOptions(
                      e.target.value,
                      o.localId,
                      'price',
                      index,
                    )}
                    errorText={errors.map((error) => (error === 'value' ? `${error} is required!` : null))}
                  />
                </Col>
                {/* <Col md={7}>
                  <Input name='item-price-options' value={o.title} onChange={(e) => handleChangeOptions(e.target.value, o.localId, 'title', index)} /> */}
                <Col md={6}>
                  <Input
                    id="cypress_item_price_options"
                    name="item-price-options"
                    value={o.title}
                    onChange={(e) => handleChangeOptions(
                      e.target.value,
                      o.localId,
                      'title',
                      index,
                    )}
                  />
                </Col>
                {index !== 0 && (
                  <Col
                    md={2}
                    className="d-flex justify-content-start align-items-end"
                    style={{ paddingBottom: 25 }}
                  >
                    <CircleButton
                      style={{
                        backgroundColor: 'red',
                      }}
                      onClick={() => removePrice(o.localId)}
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </CircleButton>
                  </Col>
                )}
                {form.priceOptions.length < 4 &&
                  index === form.priceOptions.length - 1 && (
                  <Col
                    md={12}
                    className="d-flex justify-content-start align-items-center"
                    style={{ paddingBottom: 25 }}
                  >
                    <CircleButton onClick={handleAddOption}>
                      <FontAwesomeIcon icon={faPlus} />
                    </CircleButton>
                    <h5 style={{ marginLeft: '10px', marginTop: '5px' }}>
                      Add Another Price
                    </h5>
                  </Col>
                )}
              </Row>
            ))}
            <Input label="Add Additional Tax" noInput />
            <RowWrapper style={{ paddingLeft: 0 }}>
              <Col md={12} style={{ paddingLeft: 0 }}>
                <AsyncSelect
                  cacheOptions
                  // defaultOptions={form.tax?.map(t => ({
                  //   label: t.name,
                  //   value: t.id
                  // }))}
                  loadOptions={handleTaxInputChange}
                  value={null}
                  name="taxes"
                  onChange={handleTaxChange}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Type tax...."
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  styles={customStylesAsyncSelect}
                />
              </Col>
            </RowWrapper>
            <Row>
              <Col md={12} className="mb-2 mt-2">
                <span>
                  You can change default taxes from the profile tab.
                </span>
              </Col>
            </Row>
            <Row style={{ margin: '0 0 2rem 0' }}>
              <Col>
                {[...restaurantTax, ...form.tax].map((i) => (
                  <Tag
                    key={i.value}
                    label={i.label}
                    disabled={restaurantTax
                      .map((j) => j.value)
                      .includes(i.value)}
                    onClick={() => handleRemoveBalonItem(i, 'TAX')}
                  />
                ))}
              </Col>
            </Row>
            {/* <RowWrapper style={{ paddingLeft: 0 }}>
              <Col md={6} style={{ paddingLeft: 0 }}>
                <AsyncCreatableSelect
                  cacheOptions
                  defaultOptions={form.ingredients?.map((ingredient) => ({
                    label: ingredient.name,
                    value: ingredient.id,
                  }))}
                  loadOptions={handleIngredientInputChange}
                  value={[]}
                  isMulti
                  name="ingredients"
                  // onInputChange={handleIngredientInputChange}
                  onChange={handleIngredientChange}
                  onCreateOption={onCreate}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Type ingredient...."
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  styles={customStylesAsyncSelect}
                />
              </Col>
              <Col md={6} style={{ marginLeft: "20px" }}>
                <span>Type in your ingredient name and press enter</span>
              </Col>
            </RowWrapper> */}
            <Row style={{ margin: '1rem 0' }}>
              <Col>
                {form.item_ingredient?.map((i) => (
                  <Tag
                    key={i.value}
                    label={i.label}
                    onClick={() => handleRemoveBalonItem(i, 'INGREDIENT')}
                  />
                ))}
              </Col>
            </Row>
            {(props.permissions.includes('modifiers') ||
              props.permissions.includes('__panel__') ||
              props.permissions.includes('__admin__')) && (
              <Row>
                <Col md={12}>
                  <Label>Modifications</Label>
                  <ScrollSelectorCardWrapper>
                    {modifiers.length > 0 ? (
                      modifiers.map((m, index) => (
                        <ScrollSelectorCard
                          key={index}
                          active={selectedModifiers
                            .map((i) => i)
                            .includes(m.id)}
                          onClick={() => handleSetMofifier(m.id, m.title)}
                        >
                          <div className="checkBoxWrapper">
                            <Checkbox
                              active={selectedModifiers
                                .map((i) => i)
                                .includes(m.id)}
                            >
                              {selectedModifiers
                                .map((i) => i)
                                .includes(m.id) && (
                                <FontAwesomeIcon icon={faCheck} color="#fff" />
                              )}
                            </Checkbox>
                          </div>
                          <div className="nameWrapper">{m.title}</div>
                        </ScrollSelectorCard>
                      ))
                    ) : (
                      <span
                        style={{
                          display: 'flex',
                          alignSelf: 'center',
                          marginTop: '2rem',
                          fontFamily: 'Inter',
                          fontWeight: '600',
                          textTransform: 'capitalize',
                        }}
                      >
                        no modifiers available
                      </span>
                    )}
                  </ScrollSelectorCardWrapper>
                </Col>
              </Row>
            )}
            <Col
              md={12}
              className="d-flex justify-content-start align-items-center"
              style={{ paddingBottom: 25, marginBottom: '1.5rem' }}
            >
              {/* <Link to='/console/create-modifiers/add/'>
              <CircleButton onClick={() => console.log('go to mofif')}>
                <FontAwesomeIcon icon={faPlus} />
              </CircleButton>
              </Link> */}
              {/* <h5 style={{marginLeft: '10px', marginTop: '5px'}}>Add New Modifier</h5> */}
            </Col>
            <Form.Group
              style={{
                // marginTop: '2.5rem',
                display: 'flex',
              }}
            >
              <Input label="Contains Alcohol" noInput />
              <Checkbox
                active={form.contains_alcohol}
                style={{
                  margin: '0 0 0 25px',
                }}
                onClick={(e) => formHandleChange({
                  target: {
                    name: 'contains_alcohol',
                    value: !form.contains_alcohol,
                  },
                })}
              >
                {form.contains_alcohol && (
                  <FontAwesomeIcon icon={faCheck} color="#fff" />
                )}
              </Checkbox>
            </Form.Group>
            <Col md={12} style={{ paddingLeft: 0 }}>
              <Row style={{ margin: '1rem 0' }}>
                <Col>
                  {form.item_diet?.map((i) => (
                    <Tag
                      key={i.value}
                      label={i.label}
                      onClick={() => handleRemoveBalonItem(i, 'DIET')}
                    />
                  ))}
                </Col>
              </Row>
            </Col>
            <Row>
              <Col md={12} className="mt-5">
                <Label>Add to Category</Label>
                <ScrollSelectorCardWrapper style={{ height: '18rem' }}>
                  {menuAndCategoryDetail.length > 0 ? (
                    menuAndCategoryDetail.map((m, index) => (
                      <MenusNameAndTitle key={index}>
                        <div className="menu_card">{m.name}</div>
                        {m.categories.length > 0 ? (
                          m.categories.map((c, index_2) => (
                            <ScrollSelectorCard
                              key={index_2}
                              active={selectedCategory
                                .map((cat) => cat)
                                .includes(c.id)}
                              onClick={() => handleAddToGategory(c.id)}
                            >
                              <div className="checkBoxWrapper">
                                <Checkbox
                                  active={selectedCategory
                                    .map((cat) => cat)
                                    .includes(c.id)}
                                >
                                  {selectedCategory
                                    .map((cat) => cat)
                                    .includes(c.id) && (
                                    <FontAwesomeIcon
                                      icon={faCheck}
                                      color="#fff"
                                    />
                                  )}
                                </Checkbox>
                              </div>
                              <div className="nameWrapper">{c.name}</div>
                            </ScrollSelectorCard>
                          ))
                        ) : (
                          <span
                            style={{
                              display: 'flex',
                              alignSelf: 'center',
                              marginTop: '2rem',
                              fontFamily: 'Inter',
                              fontWeight: '600',
                              textTransform: 'capitalize',
                            }}
                          >
                            no category available
                          </span>
                        )}
                      </MenusNameAndTitle>
                    ))
                  ) : (
                    <span
                      style={{
                        display: 'flex',
                        alignSelf: 'center',
                        marginTop: '2rem',
                        fontFamily: 'Inter',
                        fontWeight: '600',
                        textTransform: 'capitalize',
                      }}
                    >
                      no menus available
                    </span>
                  )}
                  {!noMorePage && (
                    <div className="load_more_wrapper">
                      <Button
                        className="load_more_button"
                        onClick={handleLoadMoreCat}
                      >
                        load more
                      </Button>
                    </div>
                  )}
                </ScrollSelectorCardWrapper>
              </Col>
            </Row>
            <Row>
              <Col md={6} style={{ marginBottom: '3rem' }}>
                <FormButton id="cypress_item_save" onClick={handleSubmit}>
                  {actionLoading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    'Save'
                  )}
                </FormButton>
              </Col>
              <Col md={6} style={{ marginBottom: '3rem' }}>
                <Link to="/console/create-items">
                  <FormButton
                    style={{ backgroundColor: '#eee', color: '#4e4e4e' }}
                  >
                    Cancel
                  </FormButton>
                </Link>
              </Col>
            </Row>
          </Col>
          <Col lg={6} md={6} sm={12} className="inputs_wrapper">
            <Row>
              <Col lg={12} md={12} sm={12}>
                {screentype !== 'add' && (
                  <EditButtonWrapper>
                    <div
                      className="delete_button"
                      onClick={handleDuplicateItem}
                      style={{ backgroundColor: '${Colors.primary1}' }}
                    >
                      {duplicateLoading ? (
                        <Spinner
                          animation="border"
                          size="sm"
                          style={{ color: '#fff' }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faClone}
                          className="mx-3"
                          color="#fff"
                        />
                      )}
                    </div>
                    <div
                      id="cypress_item_delete"
                      className="delete_button"
                      onClick={() => setConfirmDeleteModal(true)}
                      style={{ marginLeft: '1rem', backgroundColor: '#E45628' }}
                    >
                      {deleteLoading ? (
                        <Spinner
                          animation="border"
                          size="sm"
                          style={{ color: '#fff' }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="mx-3"
                          color="#fff"
                        />
                      )}
                    </div>
                  </EditButtonWrapper>
                )}
                <Label>Add Item Photo</Label>
                <ImageUploader
                  onShowUploader={onShowUploader}
                  setOnShowUploader={setOnShowUploader}
                  allImages={allImages}
                  selectedImage={selectedImage}
                  selectImageFunction={selectImageFunction}
                  getInputProps={getInputProps}
                  getRootProps={getRootProps}
                  addLoading={addLoading}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: '3rem' }}>
              <Col lg={12} md={12} sm={12}>
                <span>Preview</span>
                <PreviewImageWrapper>
                  {selectedImage[0] ? (
                    <img
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                      src={selectedImage[0]?.image_med}
                    />
                  ) : (
                    <span
                      style={{
                        width: '100%',
                        height: '100%',
                        border: '1px solid gray',
                        borderRadius: '5px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      select an image first
                    </span>
                  )}
                </PreviewImageWrapper>
              </Col>
            </Row>
          </Col>
        </>
      )}
      <ConfirmDeleteModal
        onHide={() => setConfirmDeleteModal(false)}
        onClick={handleDeleteItem}
        show={confirmDeleteModal}
      />
    </Row>
  );
}

export default connect(
  (state) => ({
    selectedRestaurant: state.user.selectedRestaurant,
    locale: state.locale[state.locale.active],
    permissions: state.user.permissions,
    locale: state.locale[state.locale.active],
  }),
  {
    getIngredients,
    addIngredient,
    getRestaurantModifiers,
    getMenuAndCategoryTitleByRestaurantId,
    getRestaurantTax,
    getTaxes,
    fetchImagesByRestaurantId,
    uploadImage,
    getDiets,
    addItem,
    makeAlert,
    editImages,
    addImages,
    removeImages,
    getItemById,
    deleteItem,
    editItem,
    duplicateItem,
  },
)(AddNewItem);

const Checkbox = styled.div`
  width: 20px;
  height: 20px;
  margin: 10px 25px;
  min-width: 20px;
  min-height: 20px;
  border: 2px solid #999;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  ${(p) => p.active &&
    `
    background: #833AB4;
    border-color: #833AB4;
  `}
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const IngredientBallon = styled.div`
  display: inline-flex;
  min-width: 50px;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  background-color: ${(p) => (p.disabled ? '#ccc' : 'rgba(103, 39, 146, 0.1)')};
  margin: 5px;
  border-radius: 2rem;
  color: #000;
  text-transform: capitalize;
`;
const ScrollSelectorCardWrapper = styled.div`
  height: 10rem;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  background: #ffffff;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12),
    0px 5px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin-bottom: 2rem;
  color: #000;
  .load_more_wrapper {
    width: 100%;
    display: flex;
    margin-top: 20px;
    margin-bottom: 10px;
    justify-content: center;
    load_more_button {
      height: 200px;
      width: 50px;
      outline: none;
      border: none;
      background-color: red;
    }
  }
`;
const MenusNameAndTitle = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .menu_card {
    padding-left: 1.5rem;
    /* background-color: red; */
    min-height: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const ScrollSelectorCard = styled.div`
  height: 3rem;
  cursor: pointer;
  background-color: ${(p) => (p.active ? 'rgba(103, 39, 146, 0.1)' : 'transparent')};
  display: flex;
  flex-direction: row;
  align-items: center;
  .nameWrapper {
    flex: 6;
    /* background-color: yellow; */
  }
  .checkBoxWrapper {
    flex: 1;
    /* background-color: blue; */
    display: flex;
    justify-content: center;
  }
`;

const PreviewImageWrapper = styled.div`
  width: 80%;
  height: 20rem;
  filter: drop-shadow(0px 3px 14px rgba(0, 0, 0, 0.12));
`;

const EditButtonWrapper = styled.div`
  width: 80%;
  height: 3.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .delete_button {
    height: 2.75rem;
    width: 2.75rem;
    background: green;
    border-radius: 2rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14),
      0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
`;
const SpinnerContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-self: center;
  position: absolute;
  top: 300px;
`;
