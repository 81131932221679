import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  getLatestOrders,
} from 'api/orders';
import {
  changeOrderEmailNotification,
  getRestaurantById,
} from 'api/restaurant';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import BadgeLabel from 'components/common/BadgeLabel';
import Button from 'components/common/Button';
import { ReactComponent as CancelIcon } from 'assets/image/cancel.svg';
import { ReactComponent as CheckIcon } from 'assets/image/check.svg';
import Checkbox from 'components/common/Checkbox';
import CustomPagination from 'components/CustomPagination';
import { ReactComponent as DeliveryIcon } from 'assets/image/delivery.svg';
import Dropdown from 'components/common/Dropdown';
import Loading from 'components/Loading';
import NoPermissions from 'components/commons/NoPermissions';
import NoRestaurant from 'components/commons/NoRestaurant';
import OnboardingNeeded from 'components/commons/OnboardingNeeded';
import { ReactComponent as PickUpIcon } from 'assets/image/pickUp.svg';
import { ReactComponent as RefreshIcon } from 'assets/image/refresh.svg';
import { ReactComponent as ScheduledIcon } from 'assets/image/scheduled.svg';
import { ReactComponent as SearchIcon } from 'assets/image/search.svg';
import { ReactComponent as SunIcon } from 'assets/image/sun.svg';
import moment from 'moment';
import { seenNewOrder } from 'actions/orders';
import { useDebouncedCallback } from 'use-debounce';
import { useDispatch, useSelector } from 'react-redux';
import { wsUrl } from 'constants';
import usePagination from '../../hooks/usePagination';
import { getData } from '../../utils';
import Table from './Table';
import { StyledOrders } from './styles';
import PauseOrders from './PauseOrders';
import * as orderStatus from '../../utils/status';

function Orders() {
  const [search, setSearch] = useState('');
  const [searchKey, setSearchKey] = useState('');
  const [orderEmailNotification, setOrderEmailNotification] = useState(true);
  const [takeoutStatusLoading, setTakeoutStatusLoading] = useState(true);
  const [takeoutStatus, setTakeoutStatus] = useState(true);
  const [onboarded, setOnboarded] = useState(false);

  const [pause, setPause] = useState(!takeoutStatus);
  const [pauseLoading, setPauseLoading] = useState(false);
  const [newOrders, setNewOrders] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);

  const [activeTab, setActiveTab] = useState('Today');

  const TABS = [
    {
      title: 'Today',
      icon: <SunIcon />,
      state: '',
      state__not_in: `${orderStatus.COMPLETED},${orderStatus.CANCELLED_BY_RESTAURANT},${orderStatus.REJECTED_BY_RESTAURANT},${orderStatus.STRANDED}`,
    },
    {
      title: 'Scheduled',
      icon: <ScheduledIcon />,
      state: '',
      state__not_in: '',
      only_scheduled: 'only_scheduled',
    },
    {
      title: 'Completed',
      icon: <CheckIcon />,
      state: `${orderStatus.COMPLETED},${orderStatus.DELIVERED}`,
      state__not_in: '',
    },
    {
      title: 'Cancelled',
      icon: <CancelIcon />,
      state: `${orderStatus.CANCELLED_BY_RESTAURANT},${orderStatus.REJECTED_BY_RESTAURANT}`,
      state__not_in: '',
    },
  ];

  const [activeFilter, setActiveFilter] = useState('All orders');
  const [hideFilters, setHideFilters] = useState(false);
  const FILTERS = [
    { title: 'All orders' },
    { title: 'New' },
    { title: 'In progress' },
  ];

  const [selectedType, setSelectedType] = useState('all_types');
  const TYPE_OPTIONS = [
    {
      title: 'all_types',
      enum: null,
      element: <BadgeLabel label="All types" />,
    },
    {
      title: 'delivery',
      enum: 2,
      element: <BadgeLabel leadingIcon={<DeliveryIcon />} label="Delivery" />,
    },
    {
      title: 'pick_up',
      enum: 1,
      element: <BadgeLabel leadingIcon={<PickUpIcon />} label="Pick Up" />,
    },
  ];

  const [ordersLoading, setOrdersLoading] = useState(true);
  const [params, setParams] = useState({
    sorting: {
      ordering: '-timestamp',
    },
    search: null,
    type: null,
  });

  const [orders, setOrders] = useState([]);

  const permissions = useSelector((state) => state.user.permissions);
  const selectedRestaurant = useSelector(
    (state) => state.user.selectedRestaurant,
  );
  const dispatch = useDispatch();

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    `${wsUrl}ws/orders/`,
    {
      retryOnError: true,
      shouldReconnect: (closeEvent) => readyState === ReadyState.CLOSED,
      reconnectAttempts: 20,
      reconnectInterval: 1000,
    },
  );

  const connectToWebsocket = useCallback(() => {
    sendMessage(JSON.stringify({ restaurant: selectedRestaurant }));
  }, []);

  useEffect(() => {
    getOrders();
  }, [lastMessage]);

  useEffect(() => {
    if (readyState === ReadyState.OPEN) connectToWebsocket();
  }, [selectedRestaurant, readyState]);

  useEffect(() => {
    if (orders.length > 0) {
      setTimeout(() => {
        dispatch(seenNewOrder());
      }, 2000);
    }
  }, [orders]);

  useEffect(() => {
    /** get selected restaurant data and update ui */
    if (
      selectedRestaurant &&
      (permissions.includes('__panel__') || permissions.includes('orders'))
    ) {
      setTakeoutStatusLoading(true);
      dispatch(
        getRestaurantById(
          selectedRestaurant,
          'takeout,onboarded,email_notifications',
        ),
      ).then((res) => {
        setTakeoutStatus(getData(res)?.takeout);
        setOrderEmailNotification(getData(res)?.email_notifications);
        setOnboarded(getData(res)?.onboarded);
        setTakeoutStatusLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    setPause(!takeoutStatus);
  }, [takeoutStatus]);

  useEffect(() => {
    if (activeTab !== 'Today') {
      setHideFilters(true);
    } else {
      setHideFilters(false);
    }
  }, [activeTab]);

  const createQueryStates = () => {
    if (activeTab === 'Today' && activeFilter === 'New') {
      return `${orderStatus.NEW}`;
    } if (activeTab === 'Scheduled') {
      return `${orderStatus.IN_PROGRESS},${orderStatus.IN_PROGRESS_UPDATED}`;
    }
    return `${TABS.find((t) => t.title === activeTab)?.state}`;
  };

  const createQueryStatesNotIn = () => {
    if (activeTab === 'Today' && activeFilter === 'In progress') {
      return `${orderStatus.NEW},${orderStatus.COMPLETED},${orderStatus.CANCELLED_BY_RESTAURANT},${orderStatus.REJECTED_BY_RESTAURANT},${orderStatus.DELIVERED}`;
    }
    return `${TABS.find((t) => t.title === activeTab)?.state__not_in}`;
  };

  const getOrders = () => {
    if (
      selectedRestaurant &&
      (permissions.includes('__panel__') || permissions.includes('orders'))
    ) {
      setOrdersLoading(true);

      const queries = {
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        search: searchKey,
        ordering: '-is_new,-timestamp',
        state__in: createQueryStates(),
        state__not_in: createQueryStatesNotIn(),
        order_types: TYPE_OPTIONS.find((t) => t.title === selectedType)?.enum,
        page,
      };

      // if (activeTab === "Today") {
      //   queries.exclude_cancelled = 1;
      // }

      if (TABS.find((t) => t.title === activeTab)?.only_scheduled) {
        queries.only_scheduled = 1;
      }

      dispatch(getLatestOrders(selectedRestaurant, queries))
        .then((res) => {
          setOrdersLoading(false);
          if (getData(res)?.results) {
            const { results, ...rest } = getData(res);
            setOrders([...results]);
            setPagination &&
              setPagination({
                count: getData(res)?.count,
                next: getData(res)?.next,
                previous: getData(res)?.previous,
              });
          }
          setPageLoading(false);
        })
        .catch((err) => {
          setOrdersLoading(false);
        });
    }
  };

  const debounced = useDebouncedCallback(async (value) => {
    resetPaging();
    setSearchKey(value);
  }, 500);

  const {
    page, setPage, pagination, setPagination, paginationEl,
  } =
    usePagination(getOrders, ordersLoading);

  useEffect(() => {
    /** When active tab is "Today" we set new orders count to 0 */
    if (activeTab === 'Today') {
      setNewOrders(0);
    }
  }, [activeTab]);

  useEffect(() => {
    /** Get Orders */
    getOrders();
  }, [selectedType, activeTab, searchKey, activeFilter]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    debounced.callback(e.target.value);
  };

  const handleOrderEmailNotifChange = (e) => {
    const { checked } = e.target;
    setOrderEmailNotification(checked);
    setTakeoutStatusLoading(true);
    dispatch(
      changeOrderEmailNotification(selectedRestaurant, {
        notification: checked,
      }),
    )
      .then((res) => setTakeoutStatusLoading(false))
      .catch((err) => setTakeoutStatusLoading(false));
  };

  const resetPaging = () => {
    setPage(1);
    setPagination({
      count: null,
      next: null,
      previous: null,
    });
  };

  const handleChangeTab = (tabTitle) => {
    setActiveTab(tabTitle);
    resetPaging();
  };

  const handleChangeFilter = (filterTitle) => {
    resetPaging();
    setActiveFilter(filterTitle);
  };

  const handleOrderSeen = (id, data) => {
    if (data.error) return;
    delete data.order_items;
    const newOrders = orders.map((o) => {
      if (id !== o.id) return o;
      o = { ...o, ...data };
      return o;
    });
    setOrders([...newOrders]);
  };

  const handleOrderUpdate = (id, data) => {
    if (data.error) return;
    delete data.order_items;
    const newOrders = orders.map((o) => {
      if (id !== o.id) return o;
      o = { ...o, ...data };
      return o;
    });
    setOrders([...newOrders]);
  };

  if (!permissions.includes('__panel__') && !permissions.includes('orders')) {
    return (
      <StyledOrders>
        <NoPermissions />
      </StyledOrders>
    );
  }

  if (pageLoading) return <Loading />;

  if (!onboarded) {
    return (
      <StyledOrders>
        <OnboardingNeeded />
      </StyledOrders>
    );
  }

  return (
    <StyledOrders>
      {/* Search and email notif */}
      <div className="search-input flex-row">
        <input
          type="text"
          className="input"
          name="search"
          autoComplete="off"
          value={search}
          onChange={handleSearchChange}
          placeholder="Search"
        />
        <SearchIcon className="label" />

        <Checkbox
          loading={takeoutStatusLoading}
          classes="email-alert flex-row"
          name="emailAlert"
          checked={orderEmailNotification}
          onChange={handleOrderEmailNotifChange}
          label="Orders email notification"
        />
      </div>

      {/* Title row  */}
      <div className="title-row flex-row">
        <div className="flex-row">
          <h1 className="title">Orders</h1>
          <div className="pause-status-title">
            <span
              className={
                pause
                  ? 'pause-status-title--paused'
                  : 'pause-status-title--active'
              }
            />
            {pause ? <p>Orders paused</p> : <p>Accepting orders</p>}
          </div>
        </div>

        <div className="flex-row">
          <Button
            label="Refresh"
            leadingIcon={<RefreshIcon />}
            type="text"
            classes="refresh-btn"
            onClick={() => getOrders()}
          />
          <PauseOrders
            takeoutStatus={takeoutStatus}
            pause={pause}
            setPause={setPause}
            pauseLoading={pauseLoading}
            setPauseLoading={setPauseLoading}
          />
        </div>
      </div>

      {/* Table */}

      <div className="table-box ">
        <div className="flex-row">
          <div className="tabs">
            {TABS.map((item, index) => (
              <div className="tab ">
                <Button
                  label={item.title}
                  leadingIcon={item.icon}
                  trailingIcon={
                    item?.title === 'Today' &&
                    activeTab !== item?.title &&
                    newOrders > 0 ? (
                      <div className="new-orders-count">{newOrders}</div>
                      ) : null
                  }
                  type="text"
                  classes={`tab-btn ${
                    activeTab === item.title ? 'tab-btn--active' : ''
                  }`}
                  isActive={activeTab === item.title}
                  onClick={() => handleChangeTab(item.title)}
                />
              </div>
            ))}
          </div>

          <Dropdown
            label={TYPE_OPTIONS.find((o) => o.title === selectedType)?.element}
            options={TYPE_OPTIONS}
            onChange={(val) => {
              setSelectedType(val);
              resetPaging();
            }}
            selected={selectedType}
            optionClass="option"
          />
        </div>

        {/* Filters */}
        {hideFilters ? null : (
          <div className="filters">
            {FILTERS.map((item, index) => (
              <div className="filter">
                <Button
                  label={item.title}
                  type="text"
                  classes={`filter-btn ${
                    activeFilter === item.title ? 'filter-btn--active' : ''
                  }`}
                  isActive={activeFilter === item.title}
                  onClick={() => handleChangeFilter(item.title)}
                />
              </div>
            ))}
          </div>
        )}

        {/* Table */}
        {!hideFilters &&
        (activeFilter === 'New' || activeFilter === 'All orders') &&
        orders.filter((o) => o.state === orderStatus.NEW)?.length ? (
          <>
              <Table
              categoryTitle="New"
              ordersLoading={ordersLoading}
              orders={orders.filter((o) => o.state === orderStatus.NEW)}
              handleOrderSeen={handleOrderSeen}
              handleOrderUpdate={handleOrderUpdate}
            />
            </>
          ) : null}
        {!hideFilters &&
        (activeFilter === 'In progress' || activeFilter === 'All orders') &&
        orders.filter(
          (o) => o.state !== orderStatus.NEW &&
            o.state !== orderStatus.REJECTED_BY_RESTAURANT &&
            o.state !== orderStatus.CANCELLED_BY_RESTAURANT &&
            o.state !== orderStatus.DELIVERED &&
            o.state !== orderStatus.COMPLETED,
        )?.length ? (
          <Table
              categoryTitle="In progress"
              ordersLoading={ordersLoading}
              orders={orders.filter(
                (o) => o.state !== orderStatus.NEW &&
                o.state !== orderStatus.REJECTED_BY_RESTAURANT &&
                o.state !== orderStatus.CANCELLED_BY_RESTAURANT &&
                o.state !== orderStatus.DELIVERED &&
                o.state !== orderStatus.COMPLETED,
              )}
              noHeader={orders.filter((o) => o.state === orderStatus.NEW)?.length}
              handleOrderSeen={handleOrderSeen}
              handleOrderUpdate={handleOrderUpdate}
            />
          ) : null}
        {hideFilters ? (
          <Table
            categoryTitle=""
            ordersLoading={ordersLoading}
            orders={orders}
            handleOrderSeen={handleOrderSeen}
            handleOrderUpdate={handleOrderUpdate}
          />
        ) : null}
        {/* <PaginateTable
          headers={[
            { title: "customer" },
            { title: "status" },
            { title: "Pick up time" },
            { title: "Type" },
            { title: "items" },
            { title: "net" },
            { title: "code" },
          ]}
          dataArray={ordersLoading ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] : orders}
          rangeEl={
            <div className="table-category">
              <div className="table-category--left">
                <p className="table-category-title">New</p>
              </div>
            </div>
          }
          rowCreators={[
            (row, index) => <div className="name">{row?.user_guest?.name}</div>,
            (row, index) => <div className="">{getStatus(row)}</div>,
            (row, index) => (
              <div
                className={`eta ${
                  isEstimatedTimePassed(row) ? "eta--red" : ""
                }`}
              >
                {handleEstimateTime(row)}
              </div>
            ),
            (row, index) => (
              <div className="order-type">{getOrderType(row)}</div>
            ),
            (row, index) => <div className="items">{getOrderItems(row)}</div>,
            (row, index) => (
              <div className="net">${row?.restaurant_net?.toFixed(2)}</div>
            ),
            (row, index) => <div className="code">{row?.number}</div>,
          ]}
          hideResults
        /> */}
        <div className="pagination">{paginationEl}</div>
      </div>
    </StyledOrders>
  );
}

export default Orders;
