import {
  Alert,
  Card,
  Col,
  Container,
  Form,
  FormControl,
  InputGroup,
  Row,
} from 'react-bootstrap';
import {
  Link,
  Redirect,
  Route,
  BrowserRouter as Router,
  useParams,
  withRouter,
} from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  faEnvelope,
  faEye,
  faEyeSlash,
  faLock,
} from '@fortawesome/free-solid-svg-icons';

import { Colors } from 'assets/colors';
import CustomButton from 'components/customButton';
import CustomInput from 'components/customInput';
import { ReactComponent as EmailIcon } from 'assets/image/email.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as KeyIcon } from 'assets/image/key.svg';
import Loading from 'components/Loading';
import Navbar from 'components/Layout/Navbar';
import { connect } from 'react-redux';

import jsCookie from 'js-cookie';
import { login } from 'api/auth';
import { loginUser } from 'actions/user';
import { makeAlert } from 'actions';
import styled from 'styled-components';
import { checkRegex, getData } from '../../utils';
import AuthenticationCard from './AuthenticationCard';

const Submit = styled.div`
  cusror: pointer;
  a {
    color: ${Colors.white} !important;
    font-weight: bold;
    font-size: 15px !important;
  }
`;

const StyledForm = styled.div`
  padding: 0 55px;
  width: 100%;

  @media (max-width: 1120px) {
    padding: 0 25px;
  }
  // width: 90%;
  // .input_group {
  //   margin-top: 1rem;
  // }
  // .input {
  //   border: none;
  //   outline: none;
  //   border-bottom: 1px solid gray;
  //   border-radius: 0;
  // }
`;
const ErrorText = styled.span`
  color: "#7B7B7B";
  margin-top: 1rem;
  @media (max-width: 1120px) {
    font-size: 2rem;
  }
  @media (max-width: 575px) {
    font-size: 0.75rem;
    width: 80%;
  }
`;

const ForgetWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: #0984e3 !important;
  display: none;

  @media (max-width: 1120px) {
    display: block;
    font-size: 15px;
    text-align: left;
  }

  a {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    color: #0984e3 !important;
  }
`;

function Login({ locale, ...props }) {
  const [loading, setLoading] = useState(false);
  const [needVerification, setNeedVerification] = useState(false);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [showPass, setShowPass] = useState(false);
  const [login, setLogin] = useState({
    form: [
      {
        label: 'Email',
        name: 'email',
        type: 'email',
        icon: faEnvelope,
        value: null,
      },
      {
        label: 'Password',
        name: 'password',
        type: 'password',
        icon: faLock,
        eyeIcon: faEye,
        value: null,
      },
    ],
  });
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState(false);
  const params = useParams();

  const handleChange = (e, formName) => {
    const { value, name } = e.target;
    setLogin({ ...login, [formName]: value });
  };

  const checkEmail = () => {
    const emailRegx =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return checkRegex(email, emailRegx);
  };

  const handleSubmit = () => {
    if (checkEmail()) {
      submit();
    } else {
      setError(true);
    }
  };

  const submit = () => {
    const data = {
      email,
      password,
    };
    setLoading(true);
    props
      .login(data)
      .then((res) => {
        if (getData(res)?.key) {
          // GOOGLE TAG MANAGER: PUSH DATA TO DATA LAYER ON LOGIN --- REFACTOR LATER
          if (typeof window !== 'undefined') {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'login',
              authenticationMethod: 'email',
              email: data.email,
              userId: null,
            });
          }
          props.loginUser(getData(res)?.key, {});
          if (localStorage.getItem('previousPath')) {
            window.location.replace(localStorage.getItem('previousPath'));
          } else {
            props.history.push('/console');
          }
          setRedirect(true);
        } else if (res.status === 400) {
          props.makeAlert(
            'Some of your information isn’t correct. Please try again.',
            'error',
          );
        } else if (res.status === 403) {
          props.makeAlert(
            'Please check your inbox and verify your email address.',
            'error',
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        // GOOGLE TAG MANAGER: PUSH DATA TO DATA LAYER ON LOGIN --- REFACTOR LATER
        if (typeof window !== 'undefined') {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'error',
            errorType: 'login error',
            errorMessage: err,
          });
        }
      });
  };

  // const showPassword = () => {
  //   setLogin({
  //     ...login,
  //     form: login.form.map((field) => {
  //       if (field.name === "password") {
  //         field.type = field.type === "password" ? "text" : "password";
  //         field.eyeIcon = field.eyeIcon === faEyeSlash ? faEye : faEyeSlash;
  //       }
  //       return field;
  //     }),
  //   });
  // };

  useEffect(() => {
    setRedirect(props.isLoggedIn);
  }, [props.isLoggedIn]);

  const { from } = props.location.state || { from: { pathname: '/console' } };

  if (redirect === true) {
    return <Redirect to={from} />;
  }

  return (
    <AuthenticationCard
      title="Login"
      login
      handleSubmit={handleSubmit}
      loading={loading}
      locale={locale}
      valid={checkEmail()}
    >
      {(props.location?.state?.needVerification || needVerification) && (
        <Alert variant="danger">
          Go to your inbox and verify your email address.
        </Alert>
      )}
      <StyledForm>
        <CustomInput
          name="email"
          label={(
            <>
              <EmailIcon />
              {' '}
&nbsp;&nbsp;|&nbsp;&nbsp;
            </>
          )}
          type="email"
          placeholder="Email address"
          id="cypress_login_Email"
          value={email}
          setValue={setEmail}
          error={error}
        />
        <CustomInput
          name="password"
          label={(
            <>
              <KeyIcon />
              {' '}
&nbsp;&nbsp;|&nbsp;&nbsp;
            </>
          )}
          type={showPass ? 'text' : 'password'}
          placeholder="Password"
          id="cypress_login_Password"
          value={password}
          setValue={setPassword}
          icon={<div onClick={() => setShowPass(!showPass)}>Show</div>}
          error={error}
        />

        <ForgetWrap>
          <Link to="/forget-password">
            {locale.password_forgot_text}
            {' '}
          </Link>
        </ForgetWrap>
      </StyledForm>
      {/* {login.form.map((form, index) => (
          <>
            <Form.Label key={index} style={{ marginTop: "3rem" }}>
              {form.label}
            </Form.Label>
            <InputGroup className="input_group">
              <InputGroup.Prepend>
                <InputGroup.Text
                  style={{
                    background: "transparent",
                    border: "none",
                    borderBottom: "1px solid gray",
                    borderRadius: 0,
                  }}
                >
                  <FontAwesomeIcon icon={form.icon} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                className="input"
                id={`cypress_login_${form.label}`}
                placeholder={form.label}
                aria-label="Username"
                aria-describedby="basic-addon1"
                type={form.type}
                onChange={(e) => handleChange(e, form.name)}
              />
              {form.eyeIcon && (
                <InputGroup.Prepend onClick={showPassword}>
                  <InputGroup.Text
                    style={{
                      background: "transparent",
                      border: "none",
                      borderBottom: "1px solid gray",
                      borderRadius: 0,
                      cursor: "pointer",
                    }}
                  >
                    <FontAwesomeIcon icon={form.eyeIcon} />
                  </InputGroup.Text>
                </InputGroup.Prepend>
              )}
            </InputGroup>
          </>
        ))}
      </StyledForm> */}
      {/* {error && (
        <ErrorText>
          * Your username or password is incorrect, please try again.
        </ErrorText>
      )} */}
    </AuthenticationCard>
  );
}

export default connect(
  (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    tutorial: state.user.tutorial,
    locale: state.locale[state.locale.active],
  }),
  { login, loginUser, makeAlert },
)(Login);

// old login design .......................
// <div style={{ height: '100vh', display: 'flex', justifyContent: 'center' }}>
//   <Container>
//     <Row className="justify-content-center">
//       <Col xl={10} lg={12} md={9}>
//         <Card className="o-hidden border-0 shadow-lg my-5">
//           <Card.Body className="p-3">
//             {(props.location?.state?.needVerification || needVerification) && <Alert variant={'danger'}>
//               Go to your inbox and verify your email address.
//             </Alert>}
//             <Row className="">
//               <Col md={12}>
//                 <h3 className="h4 text-dark my-2 text-center font-weight-bold">{locale.login_title}</h3>
//                 <p className="p-0 m-0 text-center">{locale.login_text} </p>
//               </Col>
//             </Row>
//             <Row>
//               <div className="col-lg-12">
//                 <div className="p-5">
//                   <Form className="user" onSubmit={submit}>
//                     <Form.Group className="row">
//                       <Col md={{ span: 6, offset: 3 }} className="mb-3">
//                         <Form.Control
//                           type="text"
//                           className="form-control form-control-user"
//                           placeholder={locale.email_placeholder}
//                           name="email"
//                           onChange={handleChange} value={form.email}
//                         />
//                         {
//                           error.firstnameError &&
//                           <Form.Control.Feedback
//                             style={{ display: "block" }}
//                             type="invalid">*required</Form.Control.Feedback>
//                         }
//                       </Col>
//                       <Col md={{ span: 6, offset: 3 }} >
//                         <Form.Control
//                           name="password"
//                           onChange={handleChange}
//                           value={form.password}
//                           type="password"
//                           className="form-control form-control-user"
//                           placeholder={locale.password_placeholder} />
//                         {
//                           error.lastnameError
//                             ? <Form.Control.Feedback
//                               style={{ display: "block" }}
//                               type="invalid">*required</Form.Control.Feedback>
//                             : null
//                         }
//                         <ForgetWrap>
//                           <Link to="/forget-password">{locale.password_forgot_text} </Link>
//                         </ForgetWrap>
//                       </Col>
//                     </Form.Group>
//
//                     <Submit className="text-center">
//                       <a onClick={submit} className="btn btn-user" style={{ color: Colors.white, backgroundColor: Colors.orange, width: 120, fontWeight: 'bold', cursor: 'pointer' }}>
//                         {!loading ? locale.login_button_text : <Loading nopadding />}
//                       </a>
//                     </Submit>
//                     <br />
//                   </Form>
//                   <Switch className="text-center">
//                     <Link className="small" to="/register"> {locale.no_account_check} <span>{locale.signup_text} </span></Link>
//                   </Switch>
//                 </div>
//               </div>
//             </Row>
//           </Card.Body>
//         </Card>
//       </Col>
//     </Row>
//   </Container>
// </div>

// const Switch = styled.div`
//   a {
//     font-size: 15px;
//     color: #555 !important;
//
//     span {
//       display: block;
//       color: #f48530;
//       text-decoration: underline;
//       font-weight: bold;
//     }
//   }
// `
//

//

// old login design .......................
