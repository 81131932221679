import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .length-progress {
    font-size: 13px;
  }
`

const withMaxLength = (PassedField =>
    ({value, maxLength, ...props}) => {
      return (
        <Wrapper>
          <PassedField value={value} maxLength={maxLength} {...props} />
          {maxLength && <span className='length-progress'>{value ? value.length : 0}/{maxLength}</span>}
        </Wrapper>
      )
    }
)

export default withMaxLength;
