import React from "react";
import Navbar from "components/Layout/Navbar";
import { Col, Container, Row, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import Button from "components/button";

const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 50px 0 20px;
`;

const Circle = styled.div`
  width: 200px;
  height: 200px;
  background-color: ${(p) => p.theme.orange};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 50px;

  svg {
    font-size: 100px;
    color: #fff;
  }
`;

export default (props) => {
  const { status } = props.location.state;
  const confirmed = status.status === "succeeded";
  // console.log(status);
  return (
    <>
      <Navbar />
      <div
        style={{ height: "100vh", display: "flex", justifyContent: "center" }}
      >
        <Container className="my-5">
          <Row className="mb-3">
            <Col md={12}>
              <h3 className="h4 text-dark my-2 text-center font-weight-bold">
                {confirmed ? "Payment Confirmed" : "Payment Failed"}
              </h3>
              <p className="p-0 m-0 text-center">
                {confirmed
                  ? "Thank you for joining OmmniCart, we are excited to help you and your business take the next step"
                  : "It seems you're not paying! Hmm..."}
              </p>
            </Col>
          </Row>
          <Row className="mb-3 text-center">
            <StyledCol md={12}>
              <Circle>
                <FontAwesomeIcon icon={faCheck} size={"lg"} />
              </Circle>
              {/* <h4 className="h5 text-dark my-2 text-center font-weight-bold">
                Payment Summary
              </h4> */}
              <Button
                onClick={() => props.history.push("/console")}
                // type="submit"
                // disabled={!stripe || loading}
                className="font-weight-bold px-5"
              >
                Continue
              </Button>
            </StyledCol>
          </Row>
        </Container>
      </div>
    </>
  );
};
