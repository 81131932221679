import "react-lazy-load-image-component/src/effects/blur.css";

import Input, { Label } from "components/forms/Input";
import React, { useEffect, useState } from "react";
import {
  createModifier,
  getItemsByRestaurantId,
  getRestaurantModifiers,
} from "api/items";
import {
  faCheck,
  faMinus,
  faPause,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { getModifier, updateModifier } from "../../api/items";

import { AddMenuCard } from "components/Menu/MenuCard";
import CircleButton from "components/button/CircleButton";
import Col from "react-bootstrap/Col";
import { Colors } from "assets/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import FormButton from "components/button/FormButton";
import { Heading } from "components/typography";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import Loading from "components/Loading";
import { MenusTabs } from "../menus/Preview";
import NoPermissions from "components/commons/NoPermissions";
import Row from "react-bootstrap/Row";
import { SearchBar } from "components/forms/Input";
import Spinner from "react-bootstrap/Spinner";
import { connect } from "react-redux";
import {getData, priceTransformer} from "../../utils";
import { makeAlert } from "actions";
import styled from "styled-components";

const AddNewButton = styled.button`
  background: #ffffff;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.12);
  color: #4e4e4e;
  font-weight: bold;
  font-size: 16px;
`;

const ItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  margin-top: 25px;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.12);
  height: 400px;
  margin-bottom: 25px;
  overflow-y: scroll;
  grid-gap: 5px 20px;
`;
const Item = styled.div`
  background: ${Colors.white};
  display: flex;
  opacity: ${(p) => (p.deleting ? 0.5 : 1)}
  flex-direction: row;
  ${(p) => p.loading && `align-items: center;`}
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
  display: flex;
  color: #373636;
  flex: 0 21%;
  .form-group {
    margin: 0;
  }
  .title {
    font-weight: bold;
    text-align: left;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }

  .lazy-load-image-background {
  }

  .price {
    // margin: 5px 0;
    // padding-bottom: 5px;
  }

  .body {
    cursor: pointer;
    display: flex;
    width: 100%;
    padding: 10px;
    flex-direction: column;
    justify-content: space-between;
}
  }

  .description {
    font-size: 15px;
    font-size: 15px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  img {
    height: 80px;
    object-fit: cover;
    width: 100px;
  }
  .button {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    background: ${(p) => (!p.paused ? "#4BB43A" : "#E45628")};
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 28px;
  }
`;

const Checkbox = styled.div`
  width: 20px;
  height: 20px;
  margin: 10px 25px;
  min-width: 20px;
  min-height: 20px;
  border: 2px solid #999;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  ${(p) =>
    p.active &&
    `
    background: #833AB4;
    border-color: #833AB4;
  `}
`;

const FormControl = styled(Form.Control)`
  border-color: #833ab4;
  padding: 15px 20px;
  font-size: 20px;
  border-radius: 0.35rem;
  background: #fff;
  width: 100%;
  margin-bottom: 25px;
`;

let localId = 0;

const AddEditModifiers = ({ locale, ...props }) => {
  const [name, setName] = useState("");
  const [nonTaxable, setNonTaxable] = useState(false);
  const [mandatory, setMandatory] = useState(false);
  const [type, setType] = useState(2);
  const [loading, setLoading] = useState(true);
  const [addEditLoading, setAddEditLoading] = useState(false);
  const [itemsLoading, setItemsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [minSelections, setMinSelections] = useState(0);
  const [maxSelections, setMaxSelections] = useState(1);
  const [sorting, setSorting] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [errors, setErrors] = useState([]);
  const [options, setOptions] = useState([
    {
      title: "",
      price: null,
      localId,
    },
  ]);

  const id =
    props.location.pathname.split("/").slice(-1)[0] !== "add"
      ? props.location.pathname.split("/").slice(-1)[0]
      : null;

  const handleAddOption = () => {
    localId++;
    setOptions([
      ...options,
      {
        title: "",
        price: null,
        localId,
        type: "NEW",
        id: null,
      },
    ]);
  };

  const handleRemoveOption = (localId) => {
    let newOptions = options.map((o) => {
      if (o.localId !== localId) return o;
      o.type = "DELETED";
      return o;
    });
    setOptions(newOptions);
  };

  const handleBlockSymbol = (evt) => {
    switch (evt.key) {
      case "-":
        evt.preventDefault();
        break;
      case "+":
        evt.preventDefault();
        break;
      case "e":
        evt.preventDefault();
        break;
      default:
        return;
    }
  };

  const handleChangeOptions = (value, id, name) => {
    let temp = options.map((o) => {
      if (o.localId !== id) return o;
      o[name] = value;
      o.type = "UPDATED";
      return o;
    });
    setOptions(temp);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    const newItems = Array.from(items).filter(
      (item) =>
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.description.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredItems(newItems);
  };

  useEffect(() => {
    if (
      props.selectedRestaurant &&
      (props.permissions.includes("__panel__") ||
        props.permissions.includes("menus"))
    ) {
      props.getItemsByRestaurantId(props.selectedRestaurant).then((res) => {
        setItems(getData(res));
        setFilteredItems(getData(res));
        setItemsLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    if (id) {
      setLoading(true);
      props.getModifier(id).then((res) => {
        setName(getData(res)?.title);
        setType(getData(res)?.modifier_type);
        setNonTaxable(getData(res)?.non_taxable);
        setMandatory(getData(res)?.mandatory);
        setMinSelections(getData(res)?.min_selections);
        setMaxSelections(getData(res)?.max_selections);
        setOptions(
          getData(res)?.options.map((o, index) => ({
            ...o,
            localId: index,
            type: "NOT_CHANGED",
          }))
        );
        localId = getData(res)?.options.length;
        setSelectedItems(getData(res)?.item);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  const handleSelectItem = (id) => {
    const temp = !selectedItems.includes(id)
      ? [...selectedItems, id]
      : selectedItems.filter((i) => i !== id);
    setSelectedItems(temp);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors([]);
    let tempErrors = [];
    if (name === "") {
      tempErrors.push("name");
    }
    options
      .filter((o) => o.type !== "DELETED")
      .forEach((o) => {
        if (o.title === "") {
          tempErrors.push("option" + o.localId);
        }
      });

    if (minSelections > maxSelections) {
      return props.makeAlert("Min selections cannot be greater than Max selections", "error");
    }

    if (tempErrors.length === 0) {
      let data = {
        title: name,
        min_selections: minSelections,
        max_selections: maxSelections,
        options_: options
          .filter((o) => !(!o.id && o.type === "DELETED"))
          .map((o) => ({
            ...o,
            type: !o.id ? "NEW" : o.type,
          })),
        item: selectedItems,
        restaurant: props.selectedRestaurant,
        modifier_type: type,
        non_taxable: nonTaxable,
        mandatory,
      };

      if (id) {
        setAddEditLoading(true);
        props
          .updateModifier(id, data)
          .then((res) => {
            setAddEditLoading(false);
            props.history.push("/console/create-modifiers/");
          })
          .catch((e) => {
            setAddEditLoading(false);
            console.log("error in edit modifier", e);
          });
      } else {
        setAddEditLoading(true);
        props
          .createModifier(data)
          .then((res) => {
            setAddEditLoading(false);
            props.history.push("/console/create-modifiers/");
          })
          .catch((e) => {
            setAddEditLoading(false);
            console.log("error in creare modifier", e);
          });
      }
    } else {
      props.makeAlert("Some required fields are still empty!", "error");
      setErrors(tempErrors);
    }
  };

  if (
    !props.permissions.includes("__panel__") &&
    !props.permissions.includes("menus")
  )
    return <NoPermissions />;

  if (!props.selectedRestaurant) {
    return (
      <Row className="fulls">
        <Col xs={12} sm={12} className="text-center">
          <h2 className="h3 text-white">{locale.no_restaurant}</h2>
        </Col>
      </Row>
    );
  }

  return (
    <Row
      style={{
        marginBottom: 125,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      {loading ? (
        <Loading
          animation="border"
          style={{
            marginTop: 300,
          }}
        />
      ) : (
        <>
          <Col
            md={12}
            style={{
              paddingTop: 100,
              paddingBottom: 25,
            }}
          >
            <Heading>{id ? "Edit Modifier" : `New Modifier`}</Heading>
          </Col>
          <Col lg={5} md={5}>
            <Input
              id="cypress_mod_name"
              label="Modifier Name"
              onChange={(e) => setName(e.target.value)}
              value={name}
              errorText={errors.map((error) =>
                error === "name" ? `${error} is required!` : null
              )}
              maxLength={50}
            />
            <Input
              label="Modifier Type"
              noInput
              containerStyle={{ marginTop: "2.5rem" }}
            />
            <Form.Group>
              <FormControl
                defaultValue={1}
                as="select"
                onChange={(e) => setType(e.target.value)}
                value={type}
              >
                <option value={1}>Single Choice</option>
                <option value={2}>Multiple Choice</option>
                <option value={4}>Automatically Applied</option>
              </FormControl>
            </Form.Group>
            <Form.Group
              style={{
                // marginTop: '2.5rem',
                display: "flex",
              }}
            >
              <Input label="Non Taxable" noInput />
              <Checkbox
                active={nonTaxable}
                style={{
                  margin: "0 0 0 25px",
                }}
                onClick={() => setNonTaxable(!nonTaxable)}
              >
                {nonTaxable && <FontAwesomeIcon icon={faCheck} color="#fff" />}
              </Checkbox>
            </Form.Group>
            <Form.Group
              style={{
                // marginTop: '2.5rem',
                display: "flex",
              }}
            >
              <Input label="Mandatory" noInput />
              <Checkbox
                active={mandatory}
                style={{
                  margin: "0 0 0 25px",
                }}
                onClick={() => setMandatory(!mandatory)}
              >
                {mandatory && <FontAwesomeIcon icon={faCheck} color="#fff" />}
              </Checkbox>
            </Form.Group>

            <Row>
              <Col>
                <Input
                  id="cypress_min_selections"
                  label="Min selections"
                  min="0"
                  type="number"
                  onChange={(e) => setMinSelections(e.target.value)}
                  value={minSelections}
                />
              </Col>

              <Col>
                <Input
                  id="cypress_max_selections"
                  label="Max selections"
                  type="number"
                  min="1"
                  onChange={(e) => setMaxSelections(e.target.value)}
                  value={maxSelections}
                  errorText={errors.map((error) => error === "name" ? `Max should be at least 1` : null)}
                />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Label>Options</Label>
              </Col>
              <Col md={4}>
                <Label>Price</Label>
              </Col>
            </Row>
            {options
              .filter((o) => o.type !== "DELETED")
              .map((o, index) => (
                <Row key={o.localId}>
                  <Col md={6}>
                    <Input
                      id="cypress_mod_price_options"
                      onChange={(e) =>
                        handleChangeOptions(e.target.value, o.localId, "title")
                      }
                      value={o.title}
                      errorText={
                        errors.find(
                          (e) =>
                            e.includes("option") &&
                            parseInt(e.split("option")[1]) === o.localId
                        ) && `This field is required!`
                      }
                    />
                  </Col>
                  <Col md={4}>
                    <Input
                      id="cypress_mod_price"
                      type="tel"
                      pattern="[0-9]*"
                      min="0"
                      onChange={(e) =>
                        handleChangeOptions(e.target.value, o.localId, "price")
                      }
                      value={o.price}
                      onKeyDown={(evt) => handleBlockSymbol(evt)}
                    />
                  </Col>
                  {index !== 0 && (
                    <Col
                      md={2}
                      className="d-flex justify-content-start align-items-end"
                      style={{ paddingBottom: 25 }}
                    >
                      <CircleButton
                        style={{
                          backgroundColor: "red",
                        }}
                        onClick={() => handleRemoveOption(o.localId)}
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </CircleButton>
                    </Col>
                  )}
                </Row>
              ))}
            {true && (
              <div
                className="d-flex justify-content-start align-items-center"
                style={{ paddingBottom: 25 }}
              >
                <CircleButton onClick={handleAddOption}>
                  <FontAwesomeIcon icon={faPlus} />
                </CircleButton>
                <h5 style={{ marginLeft: "10px", marginTop: "5px" }}>
                  Add Another Option
                </h5>
              </div>
            )}
            <Row style={{ marginTop: "4rem" }}>
              <Col md={6} style={{ marginBottom: "3rem" }}>
                <FormButton onClick={handleSubmit} id="cypress_mod_save_button">
                  {addEditLoading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Save"
                  )}
                </FormButton>
              </Col>
              <Col md={6} style={{ marginBottom: "3rem" }}>
                <Link to="/console/create-modifiers">
                  <FormButton
                    style={{ backgroundColor: "#eee", color: "#4e4e4e" }}
                  >
                    Cancel
                  </FormButton>
                </Link>
              </Col>
            </Row>
          </Col>
          <Col lg={6} md={6}>
            <Label>Add to Item</Label>
            <SearchBar
              placeholder="Start Typing..."
              onChange={handleSearch}
              style={{
                width: "100%",
              }}
            />
            {itemsLoading ? (
              <Loading />
            ) : (
              <ItemsWrapper>
                <AddMenuCard
                  title={"New Item"}
                  style={{
                    background: "#fff",
                    maxHeight: 70,
                  }}
                />
                {filteredItems.map((item, index) =>
                  !item.add_loading ? (
                    <Item
                      className={`step2`}
                      onClick={() => handleSelectItem(item.id)}
                      key={item.id}
                    >
                      <Checkbox active={selectedItems.includes(item.id)}>
                        {selectedItems.includes(item.id) && (
                          <FontAwesomeIcon icon={faCheck} color="#fff" />
                        )}
                      </Checkbox>
                      <LazyLoadImage
                        src={item.images[0] && item.images[0].image_med}
                        effect="blur"
                      />
                      <div className="body">
                        <span className="title">{item.name}</span>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                          }}
                        >
                          <span className="price">{priceTransformer(item.value)}</span>
                        </div>
                      </div>
                    </Item>
                  ) : !item.id ? (
                    <Item loading={true}>
                      <Loading />
                    </Item>
                  ) : null
                )}
              </ItemsWrapper>
            )}
          </Col>
        </>
      )}
    </Row>
  );
};

export default connect(
  (state) => ({
    selectedRestaurant: state.user.selectedRestaurant,
    tutorial: state.user.tutorial,
    permissions: state.user.permissions,
    locale: state.locale[state.locale.active],
  }),
  {
    getRestaurantModifiers,
    createModifier,
    getItemsByRestaurantId,
    getModifier,
    updateModifier,
    makeAlert,
  }
)(AddEditModifiers);
