import styled from "styled-components";

export const StyledTooltip = styled.div`
  display: inline;

  .tooltip-body {
    display: none;
    width: 252px;
    height: 144px;
    border-radius: 6px;
    background: #ffffff;
    border: 0.5px solid rgba(118, 118, 118, 0.28);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    position: absolute;
    bottom: 100%;
    opacity: 1;
    padding: 14px 12px 10px;

    &:before {
      content: "";
      width: 10px;
      height: 10px;
      border: 0.5px solid rgba(118, 118, 118, 0.28);
      position: absolute;
      top: 1px;
      /* left: 50%; */
      transform: rotate(45deg) translateY(-4px) translateX(-50%);
      border-bottom: none;
      border-right: none;
      border-radius: 3px 0 0 0;
      background-color: white;
      left: 94px;
    }
  }

  .tooltip-wrapper {
    position: relative;
    cursor: pointer;
    display: inline;

    &:hover {
      .tooltip {
        display: block;
      }
    }
  }
`;
