import { fetchApi } from './'

export const uploadImage = (data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`restaurant-images/`, 'POST', data, true))

  return res
}
export const editImages = (id, data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`restaurant-images/${id}/`, 'PATCH', data))

  return res
}
export const addImages = (id, payload) => async (dispatch, getState) => {
  const data = {...payload, action: 'ADD'}
  let res = await dispatch(fetchApi(`restaurant-images/${id}/change/`, 'POST', data))

  return res
}
export const removeImages = (id, payload) => async (dispatch, getState) => {
  const data = {...payload, action: 'REMOVE'}
  let res = await dispatch(fetchApi(`restaurant-images/${id}/change/`, 'POST', data))

  return res
}

export const fetchImages = (page = 1) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`restaurant-images/?query={id, image}&page=${page}`, 'GET'))

  return res
}

export const fetchImagesByRestaurantId = (page = 1, id) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`restaurants/${id}/images?query={id, image_med, stock}&page=${page}`, 'GET'))
  // res = res.sort((a, b) => a.stock - b.stock)
  return res
}
