import React from "react";
import { StyledBadgeLabelWrapper } from "./styles";
import PropTypes from "prop-types";

const BadgeLabel = ({
  label,
  leadingIcon,
  trailingIcon,
  classes,
  ...props
}) => {
  return (
    <StyledBadgeLabelWrapper>
      <div className={`wrapper ${classes}`}>
        {leadingIcon ? (
          <span className="icon icon--lead">{leadingIcon}</span>
        ) : null}
        <span>{label}</span>
        {trailingIcon ? (
          <span className="icon icon--trail">{trailingIcon}</span>
        ) : null}
      </div>
    </StyledBadgeLabelWrapper>
  );
};

BadgeLabel.propTypes = {
  label: PropTypes.element,
  classes: PropTypes.string,
  leadingIcon: PropTypes.element,
  trailingIcon: PropTypes.element,
};

export default BadgeLabel;
