import React, { useState } from "react";

import { Colors } from "assets/colors";
import CustomInput from "../customInput";
import { InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { geolocated } from "react-geolocated";
import { getData } from "../../utils";
import { googleForwardGeocoding } from "../../api/geocoding";
import styled from "styled-components";
import { useDebouncedCallback } from "use-debounce";

const SearchInput = ({
  name,
  id,
  label,
  type,
  placeholder,
  value,
  setValue,
  error,
  ...props
}) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [geoResults, setGeoResults] = useState([]);
  const [preventGeocoding, setPreventGeocoding] = useState(false);
  const [choosedLocation, setChoosedLocation] = useState(null);

  const debounced = useDebouncedCallback(async (value) => {
    if (value !== "") {
      setShow(true);
      setLoading(true);
      setGeoResults([]);
      let res = await props.googleForwardGeocoding(value, [
        props.coords?.longitude,
        props.coords?.latitude,
      ]);
      setGeoResults(getData(res));
      setLoading(false);
    } else {
      setShow(false);
      setGeoResults([]);
    }
  }, 500);

  const handleChange = (e) => {
    setValue(e.target.value);
    debounced.callback(e.target.value);
  };

  const handleClick = (event) => {
    setValue(event);
    if (props.onSelect) props.onSelect(event);
    handleEditAddress("_", event.place_name, event.text, event.center);
    setPreventGeocoding(true);
    setShow(false);
  };

  const handleFocus = (event) => event.target.select();

  const handleEditAddress = async (
    location,
    value,
    short_address,
    coordinates
  ) => {
    setChoosedLocation((prev) => ({
      address_complete: value,
      address: short_address,
      coordinates,
      editable: false,
    }));
  };

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <StyledInputGroup style={{}}>
        <CustomInput
          name={name}
          id={id}
          label={label}
          type={type}
          placeholder={placeholder}
          value={value}
          setValue={setValue}
          error={error}
          onChange={handleChange}
        />
      </StyledInputGroup>
      {show && (
        <StyledOptions>
          {!loading ? (
            geoResults.length > 0 ? (
              geoResults.map((r) => (
                <SearchResult key={r.id} onClick={() => handleClick(r)}>
                  {r.place_name}
                </SearchResult>
              ))
            ) : (
              <SearchResult>Nothing found.</SearchResult>
            )
          ) : (
            <SearchResult>Fetching restults...</SearchResult>
          )}
        </StyledOptions>
      )}
    </div>
  );
};

export default connect((state) => ({}), {
  googleForwardGeocoding,
})(
  geolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 1000,
  })(SearchInput)
);

const StyledInputGroup = styled(InputGroup)`
  .form-control {
    border: none;
  }
`;
const SearchResult = styled.div`
  padding: 15px 0.75rem !important;
  &:hover {
    background: #6d717e;
    cursor: pointer;
    color: #fff;
  }
`;

export const StyledOptions = styled.div`
  position: absolute;
  bottom: 87%;
  max-height: 325px;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 0 -10px 15px 0 rgb(0 0 0 / 5%);
  border-bottom: none;
  overflow: auto;

  .item {
    border-bottom: 1px solid #ececec;
    margin: 0 18px;
    padding: 15px 0;
    color: rgba(21, 21, 21, 0.9);
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;

    p {
      margin-bottom: 0;
    }

    &:hover {
      color: ${Colors.primary1};
    }
  }
`;
