import { fetchApi } from './'

export const createPromotion = (data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`promotions/create/`, 'POST', data))

  return res
}

export const createDeal = (data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`promotions/deals/`, 'POST', data))

  return res
}

export const validateVoucher = (data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`promotions/validations/`, 'POST', data))

  return res
}

export const getCurrentPromotion = (restaurant) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`promotions/?restaurant=${restaurant}`, 'GET'))

  return res
}

export const getCurrentDeals = (restaurant) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`promotions/deals/current/?restaurant=${restaurant}`, 'GET'))

  return res
}

export const getAllDeals = (restaurant) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`promotions/deals/all/?restaurant=${restaurant}`, 'GET'))

  return res
}

export const getPromotionMenu = (restaurant) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`promotions/restaurants/${restaurant}/promotion_get_or_create/?query={id}`, 'GET'))

  return res
}

export const getDigitalBoardMenu = (restaurant) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`restaurants/${restaurant}/digitalboard_get_or_create/?query={id}`, 'GET'))

  return res
}
