import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  estimateOrder,
  isRideServiceAvailable,
  updateOrderState,
} from 'api/orders';
import { useDispatch } from 'react-redux';

import Button from 'components/common/Button';
import { ReactComponent as ClockIcon } from 'assets/image/clock.svg';
import { ReactComponent as CommentIcon } from 'assets/image/comment.svg';
import { ReactComponent as GiftIcon } from 'assets/image/gift.svg';
import { ReactComponent as InfoIcon } from 'assets/image/info.svg';
import Input from 'components/common/Input';
import { ReactComponent as OpenLinkIcon } from 'assets/image/openLink.svg';
import RadioButtonGroup from 'components/RadioButtonGroup';
import RealTime from 'components/RealTime';
import { ReactComponent as ScheduledIcon } from 'assets/image/schedule.svg';
import SkeletonLoading from 'components/common/SkeletonLoading';
import moment from 'moment';
import { toast } from 'react-toastify';
import { StyledWrapper } from './styles';
import TimeDialogue from '../TimeDialogue';
import { getData, insertDecimal } from '../../../utils';
import { RIDE_STATUS } from '../../../constants';
import { ModalContext } from '../../../context/modalContext';
import * as orderStatus from '../../../utils/status';

function OrderDialogue({
  data,
  getStatus,
  getOrderType,
  handleOrderUpdate,
  ...props
}) {
  const { handleModal } = useContext(ModalContext);

  const [timeEST, setTimeEST] = useState('');
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [timeConfirmLoading, setTimeConfirmLoading] = useState(false);
  const [orderTime, setOrderTime] = useState(data?.estimate);
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [readyLoading, setReadyLoading] = useState(false);
  const [deliverLoading, setDeliverLoading] = useState(false);
  const [selectedEstimateOption, setSelectedEstimateOption] = useState();
  const [estimatedPickupTs, setEstimatedPickupTs] = useState(null);
  const [serviceAvailable, setServiceAvailable] = useState(false);
  const dispatch = useDispatch();

  const isDelivery = () => data?.order_type === 2;

  const isScheduled = () => data?.scheduled_for;

  const estLimitToOne = (time) =>
    /** Earlieat pickup estimate cannot be less than 1 minute */
    (time > 0 ? time : 1);
  const ESTIMATE_OPTIONS = [
    { id: 0, title: '15 minutes', time: 15 },
    { id: 1, title: '25 minutes', time: 25 },
    { id: 2, title: '45 minutes', time: 45 },
    {
      id: 3,
      title: (
        <div className="input-wrap">
          <Input
            type="number"
            id="custom-time-for-estimate"
            value={timeEST}
            onKeyDown={(e) => {
              ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
            }}
            onChange={(val) => {
              if (val === '' || (val > 0 && val <= 60)) {
                setTimeEST(val);
              } else if (val > 60) {
                toast.error('Custom time cannot be more than 60 minutes');
              }
            }}
            placeholder="Custom time"
            inputClasses="est-input"
          />
          <span className="unit">min</span>
        </div>
      ),
      time: timeEST,
    },
  ];
  const DELIVERY_ESTIMATE_OPTIONS = [
    {
      id: 0,
      title: estimatedPickupTs ? (
        `Next Available Pickup (${estLimitToOne(
          moment(estimatedPickupTs).diff(moment(), 'minutes'),
        )} mins)`
      ) : (
        <SkeletonLoading
          borderRadius="2px"
          width="258px"
          height="16px"
          count={1}
        />
      ),
      time: moment(estimatedPickupTs).diff(moment(), 'minutes'),
    },
    {
      id: 1,
      title: (
        <div className="input-wrap">
          <Input
            type="number"
            id="custom-time-for-estimate"
            value={timeEST}
            onKeyDown={(e) => {
              ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
            }}
            onChange={(val) => {
              if (val === '' || (val > 0 && val <= 60)) {
                setTimeEST(val);
              } else if (val > 60) {
                toast.error('Custom time cannot be more than 60 minutes');
              }
            }}
            placeholder="Custom time"
            inputClasses="est-input"
          />
          <span className="unit">min</span>
        </div>
      ),
      time: timeEST,
    },
  ];

  const isAcceptingDisabled = () => {
    /** An estimate must be selected */
    if (isScheduled()) {
      return false;
    }
    if (isDelivery()) {
      if (!estimatedPickupTs) {
        return true;
      }
      return selectedEstimateOption?.id === 1
        ? !timeEST
        : !selectedEstimateOption?.time;
    }
    return selectedEstimateOption?.id === 3
      ? !timeEST
      : !selectedEstimateOption?.time;
  };

  const ORDER_DIALOGUE_SETTING = [
    {
      oStatus: [orderStatus.NEW],
      timeSelection: false,
      timeEstSelection: true,
      buttons: (
        <div className="btns">
          <Button
            label="Reject Order"
            type="neutral"
            classes="reject-btn"
            onClick={() => handleRejectOrder(data.id)}
            loading={rejectLoading}
          />
          <Button
            label="Accept Order"
            type="filled"
            classes="confirm-btn"
            onClick={() => handleAcceptOrder(data.id)}
            loading={acceptLoading}
            disabled={isAcceptingDisabled()}
          />
        </div>
      ),
    },
    {
      oStatus: [orderStatus.IN_PROGRESS, orderStatus.IN_PROGRESS_UPDATED],
      timeSelection: true,
      timeEstSelection: false,
      buttons: (
        <div className="btns">
          <Button
            label="Cancel Order"
            type="neutral"
            classes="reject-btn"
            onClick={() => handleRejectOrder(data.id)}
            loading={rejectLoading}
          />
          <Button
            label="Order Ready"
            type="filled"
            classes="confirm-btn"
            onClick={() => handleOrderReady(data.id)}
            loading={readyLoading}
          />
        </div>
      ),
    },
    {
      oStatus: [orderStatus.READY, orderStatus.READY_UPDATED],
      timeSelection: true,
      timeEstSelection: false,
      buttons: (
        <div className="btns">
          <Button
            label="Cancel Order"
            type="neutral"
            classes={`reject-btn ${isDelivery() ? 'm-0' : ''}`}
            onClick={() => handleRejectOrder(data.id)}
            loading={rejectLoading}
          />
          {isDelivery() ? null : (
            <Button
              label="Order Delivered"
              type="filled"
              classes="confirm-btn"
              onClick={() => handleOrderDeliver(data.id)}
              loading={deliverLoading}
            />
          )}
        </div>
      ),
    },
    {
      oStatus: [orderStatus.ON_THE_WAY],
      timeSelection: true,
      timeEstSelection: false,
      buttons: (
        <div className="completed">
          <InfoIcon />
          <span>Order is on the way</span>
        </div>
      ),
    },
    {
      oStatus: [orderStatus.COMPLETED],
      timeSelection: false,
      timeEstSelection: false,
      buttons: (
        <div className="completed">
          <InfoIcon />
          <span>Order has been completed</span>
        </div>
      ),
    },
    {
      oStatus: [orderStatus.DELIVERED],
      timeSelection: false,
      timeEstSelection: false,
      buttons: (
        <div className="completed">
          <InfoIcon />
          <span>Order has been delivered</span>
        </div>
      ),
    },
    {
      oStatus: [orderStatus.REJECTED_BY_RESTAURANT],
      timeSelection: false,
      timeEstSelection: false,
      buttons: (
        <div className="completed">
          <InfoIcon />
          <span>Order has been rejected</span>
        </div>
      ),
    },
    {
      oStatus: [orderStatus.CANCELLED_BY_RESTAURANT],
      timeSelection: false,
      timeEstSelection: false,
      buttons: (
        <div className="completed">
          <InfoIcon />
          <span>Order has been cancelled</span>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (!isDelivery()) {
      setSelectedEstimateOption(ESTIMATE_OPTIONS[0]);
    }
  }, []);

  useEffect(() => {
    if (estimatedPickupTs && isDelivery()) {
      setSelectedEstimateOption(DELIVERY_ESTIMATE_OPTIONS[0]);
    }
  }, [estimatedPickupTs]);

  useEffect(() => {
    setEstimatedPickupTs(null);
    setServiceAvailable(null);

    if (data?.state === 0 && data?.order_type === 2) {
      dispatch(isRideServiceAvailable(data?.id)).then((res) => {
        const error = getData(res)?.data?.error;
        if (!error) {
          dispatch(estimateOrder(data?.id)).then((res) => {
            setEstimatedPickupTs(getData(res)?.pickupts);
            setSelectedEstimateOption(
              estLimitToOne(
                moment(getData(res)?.pickupts).diff(moment(), 'minutes'),
              ),
            );
          });
        } else {
          setEstimatedPickupTs({ error });
        }
        setServiceAvailable(!error);
      });
    }
  }, []);

  const checkCustomEstimateForDelivery = () => {
    /** If it's delivery type, customEstimate should be less than earliest pickup time*/

    if (
      data.order_type === 2 &&
      Number(selectedEstimateOption?.time || timeEST) >=
        moment(estimatedPickupTs).diff(moment(), 'minutes')
    ) {
      /** Delivery type */
      return true;
    } if (data?.order_type === 1) {
      /** Pickup type */
      return true;
    }
    return false;
  };

  const handleAcceptOrder = (id) => {
    if (!isScheduled()) {
      /** On demand */
      if (Number(selectedEstimateOption?.time || timeEST) <= 0) return;
      if (data.order_type === 2 && !selectedEstimateOption?.time && !timeEST) return;

      if (checkCustomEstimateForDelivery()) {
        setAcceptLoading(true);

        dispatch(
          updateOrderState(id, {
            state: 1,
            estimated_time: parseInt(selectedEstimateOption?.time || timeEST),
          }),
        )
          .then((res) => {
            setAcceptLoading(false);
            handleOrderUpdate(id, getData(res));
            handleModal();
          })
          .catch((err) => {
            setAcceptLoading(false);
          });
      } else {
        toast.error('Custom time cannot be less than earliest pickup time');
      }
    } else {
      /** Scheduled */
      setAcceptLoading(true);

      dispatch(
        updateOrderState(id, {
          state: 1,
        }),
      )
        .then((res) => {
          setAcceptLoading(false);
          handleOrderUpdate(id, getData(res));
          handleModal();
        })
        .catch((err) => {
          setAcceptLoading(false);
        });
    }
  };

  const handleRejectOrder = (id) => {
    setRejectLoading(true);

    dispatch(
      updateOrderState(id, {
        state: 2,
      }),
    )
      .then((res) => {
        setRejectLoading(false);
        handleOrderUpdate(id, getData(res));
        handleModal();
      })
      .catch((err) => {
        setRejectLoading(false);
      });
  };

  const handleOrderReady = (id) => {
    setReadyLoading(true);
    dispatch(
      updateOrderState(id, {
        state: 4,
      }),
    )
      .then((res) => {
        setReadyLoading(false);
        handleModal();
      })
      .catch((err) => {
        setReadyLoading(false);
      });
  };

  const isEstimatedTimePassed = (time) => {
    const remainingTime = moment(time).diff(moment(), 'minutes');

    if (remainingTime && remainingTime < 0) {
      return true;
    }
    return false;
  };

  const showEst = () => {
    const setting = ORDER_DIALOGUE_SETTING.find((s) => s.oStatus.includes(data?.state));

    if (isScheduled(data) || data?.state === orderStatus.ON_THE_WAY) {
      // If the order is scheduled or it's on the way, we cannot update the time
      return false;
    }

    return setting.timeSelection;
  };

  const showEStSelection = () => {
    const setting = ORDER_DIALOGUE_SETTING.find((s) => s.oStatus.includes(data?.state));
    if (isScheduled()) {
      return false;
    }
    return setting.timeEstSelection;
  };

  const createButtons = () => {
    const setting = ORDER_DIALOGUE_SETTING.find((s) => s.oStatus.includes(data?.state));
    return setting.buttons;
  };

  const handleUpdateOrder = (id, time) => {
    setTimeConfirmLoading(true);
    dispatch(
      updateOrderState(id, {
        state: 7,
        estimated_time: parseInt(time),
      }),
    )
      .then((res) => {
        setOrderTime(getData(res).estimate);
        handleOrderUpdate(id, getData(res));
        setTimeConfirmLoading(false);
        setShowTimeModal(false);
      })
      .catch((err) => {
        setTimeConfirmLoading(false);
        setShowTimeModal(false);
      });
  };

  const handleOrderDeliver = (id) => {
    setDeliverLoading(true);

    dispatch(
      updateOrderState(id, {
        state: orderStatus.DELIVERED,
      }),
    )
      .then((res) => {
        setDeliverLoading(false);
        handleOrderUpdate(id, getData(res));
        handleModal();
      })
      .catch((err) => {
        setDeliverLoading(false);
      });
  };

  const order_items =
    data?.migration_id && data?.migrated_json_order_details?.items?.length
      ? data?.migrated_json_order_details?.items.map((item) => ({
        ...item,
        total_value: item.value.toString().replace('$', ''),
      }))
      : data?.order_items;

  const getItemValue = (value) => {
    switch (data?.migration_driver) {
      case 'DD':
        return value.replace('$', '');
      case 'FH':
        return insertDecimal(value / 100);
      default:
        return value;
    }
  };

  const isMigratedData = useMemo(() => data?.migration_id &&
    data?.migration_driver !== 'MealMe' &&
    data?.migration_driver !== 'GFOE' &&
    data?.migrated_json_order_details, [data]);

  const transformDetailLabel = (label) => {
    const capitalizeLabel = (text) => text.charAt(0).toUpperCase() + text.slice(1);
    return capitalizeLabel(label.split('_').join(' '));
  };
  const orderPriceDetail = useMemo(() => {
    const priceDetail = isMigratedData && Object.keys(data?.migrated_json_order_details?.details).length
      ? Object.keys(data?.migrated_json_order_details?.details)?.map((option) => (
        <li>
          {transformDetailLabel(option)}
          :
          {' '}
          ${getItemValue(data?.migrated_json_order_details?.details[option])}
        </li>
      ))
      : null;

    return <ul>{priceDetail}</ul>;
  }, [data]);
  return (
    <StyledWrapper>
      <div className="top">
        <div className="title">
          Order #
          {data?.number}
        </div>
        <div className="badges">
          <span>{getStatus(data)}</span>
          {data?.is_gift ? (
            <span className="gift">
              <GiftIcon />
              {' '}
              <span>Gift</span>
            </span>
          ) : null}
          <span className="order-type">{getOrderType(data)}</span>
        </div>
        <div className="placed-on">
          Placed on:
          {' '}
          {moment(data?.timestamp).format('MMM D, LT')}
        </div>
      </div>
      {/* Customer */}
      <div className={`customer ${data?.is_gift ? 'customer-gift' : ''}`}>
        {data?.is_gift ? (
          <div className="customer-name-title">Sender</div>
        ) : null}
        <div className="customer-row">
          <div className="customer-name">
            {data?.is_gift
              ? data?.gift_sender_name || data?.customer?.name
              : data?.customer?.name}
          </div>
          <a className="customer-link" href={`tel:${data?.customer?.phone}`}>
            <span>{data?.customer?.phone}</span>
            {' '}
            <OpenLinkIcon />
          </a>
          <a
            className="customer-link customer-email"
            href={`mailto:${data?.customer?.email}`}
          >
            <span>{data?.customer?.email}</span>
            {' '}
            <OpenLinkIcon />
          </a>
        </div>
      </div>
      {/* Gift Recipient */}
      {data?.is_gift ? (
        <div className="customer customer-gift">
          <div className="customer-name-title">Recipient</div>
          <div className="customer-row">
            <div className="customer-name">{data?.recipient_name}</div>
            <a className="customer-link" href={`tel:${data?.recipient_number}`}>
              <span>{data?.recipient_number}</span>
              {' '}
              <OpenLinkIcon />
            </a>
          </div>
          <div className="customer-gift-mess">
            <span className="customer-gift-mess-title">Gift message:</span>
            <span>{data?.gift_message}</span>
          </div>
        </div>
      ) : null}
      {/* Address */}
      {/* {isDelivery() ? (
        <div className="address">
          <span>Address</span>
          <span className="address-text ">{data?.address?.address}</span>
          <a
            href={`https://google.com/maps?q=${data?.address?.location?.lat},${data?.address?.location?.lng}`}
            target="_blank"
          >
            Direction
          </a>
        </div>
      ) : null} */}

      {isScheduled() ? (
        <div className="scheduled">
          <ScheduledIcon />
          <span>Scheduled for</span>
          <span className="scheduled-text ">
            {moment(data?.scheduled_for).format('ddd MMM DD, LT')}
            -
            {moment(data?.scheduled_for).add(1, 'hour').format('LT')}
          </span>
        </div>
      ) : null}
      {/* Delivery estimates */}
      {isDelivery() ? (
        <div className="delivery-eta">
          <div className="delivery-eta-col">
            <span>Delivery status</span>
            <span className="delivery-eta-text">
              {RIDE_STATUS.find((s) => s.id === data.delivery_state)?.title ||
                '—'}
            </span>
          </div>
          <div className="delivery-eta-col">
            <span>Pickup ETA</span>
            <span className="delivery-eta-text">
              <RealTime
                interval={30000}
                renderTime={() => (data?.estimated_pickup_time
                  ? `${moment(data?.estimated_pickup_time).format(
                    'MMM DD, hh:mm A',
                  )} (
            ${moment(data?.estimated_pickup_time).fromNow()})`
                  : '—')}
              />
            </span>
          </div>
          <div className="delivery-eta-col">
            <span>Driver</span>
            <span className="delivery-eta-text">
              <span>{data?.driver_name ? data?.driver_name : '—'}</span>
              {data?.driver_phone ? (
                <a
                  className="driver-phone-link"
                  href={`tel:${data?.driver_phone}`}
                >
                  <span>Call</span>
                </a>
              ) : null}
            </span>
          </div>
        </div>
      ) : null}
      <div className="est">
        <p>
          Merchant:
          {data?.merchant?.name}
        </p>
      </div>
      {/* Time Estimate */}
      {showEst() ? (
        <div
          className={`estimate ${
            isEstimatedTimePassed(orderTime) ? 'estimate--passed' : ''
          }`}
        >
          <div>
            <ClockIcon />
            <span>Estimated time: </span>
            <span>
              <RealTime
                interval={30000}
                renderTime={() => moment(orderTime).fromNow()}
              />
            </span>
          </div>
          <Button
            label="Update Time"
            type="neutral"
            classes="update-time-btn"
            onClick={() => setShowTimeModal(true)}
          />
        </div>
      ) : null}
      {/* Time modal */}
      {showTimeModal ? (
        <TimeDialogue
          handleCancel={() => setShowTimeModal(false)}
          handleConfirm={(time) => handleUpdateOrder(data.id, time)}
          loading={timeConfirmLoading}
        />
      ) : null}
      {/* Product items */}
      <div className="products">
        {order_items.map((item, index) => (
          <div className="product" key={index}>
            <div className="product-left">
              <div className="product-num">{item?.qty}</div>
              <div className="product-multiply">×</div>
              <div className="product-detail">
                <div className="product-detail-name">{item?.item_name}</div>
                <div className="product-detail-mods">
                  {(item?.order_options?.length > 0
                    ? item.order_options
                    : item.order_modifier
                  )
                    .map(
                      (m) => `${m.qty > 1 ? m.qty : ''} ${
                        m.modifier_option?.title || m.title
                      }`,
                    )
                    .join(' · ')}
                </div>
                {item?.message ? (
                  <div className="product-detail-mods">
                    <CommentIcon />
                    <span>{item?.message}</span>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="product-price">
              $
              {item?.total_value}
            </div>
          </div>
        ))}
      </div>
      {/* Financial details */}
      <div className="financial">
        <div>
          {isMigratedData && Object.keys(data?.migrated_json_order_details?.details).length ? (
            orderPriceDetail
          ) : (
          <ul>
            <li>
              Subtotal:
              {insertDecimal(data.food_value - data.markup_value)}
            </li>
            <li>
              Tax:
              ${insertDecimal(data?.order_nets?.total_tax)}
            </li>
            <li>
              Merchant Tip:
              ${insertDecimal(data?.order_nets?.restaurant_tip_amount)}
            </li>
            <li>
              Payment Fees:
              ${insertDecimal(data?.order_nets?.restaurant_fee)}
            </li>
            <li>
              Merchant Net:
              ${insertDecimal(data.restaurant_net)}
            </li>
          </ul>)}
        </div>
      </div>
      {/* Time estimate selection */}
      {showEStSelection() ? (
        isDelivery() ? (
          <div className="est">
            <p>Estimated time</p>
            <RadioButtonGroup
              options={DELIVERY_ESTIMATE_OPTIONS}
              selectedOption={selectedEstimateOption}
              setSelectedOption={setSelectedEstimateOption}
              classes="list"
              labelClasses="list-item-custom"
              checkmarkClasses="checkmark-custom"
            />
            <p className="est-info">
              <InfoIcon />
              <span>
                Pick a time that accurately estimates how long this order will
                take to prepare.
              </span>
            </p>
          </div>
        ) : (
          <div className="est">
            <p>Estimated time</p>
            <RadioButtonGroup
              options={ESTIMATE_OPTIONS}
              selectedOption={selectedEstimateOption}
              setSelectedOption={setSelectedEstimateOption}
              classes="list"
              labelClasses="list-item-custom"
              checkmarkClasses="checkmark-custom"
            />
            <p className="est-info">
              <InfoIcon />
              <span>
                Pick a time that accurately estimates how long this order will
                take to prepare.
              </span>
            </p>
          </div>
        )
      ) : null}
      {/* Buttons */}
      {createButtons()}
    </StyledWrapper>
  );
}

OrderDialogue.propTypes = {};

export default OrderDialogue;
