import moment from "moment";
import React, { useEffect, useReducer, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Jumbotron,
  ListGroup,
  Row,
} from "react-bootstrap";
import { connect } from "react-redux";
import Select from "react-select";
import styled from "styled-components";

import { makeAlert } from "actions";
import {
  createDeal,
  getAllDeals,
  getCurrentDeals,
  validateVoucher,
} from "api/promotions";
import { getRestaurantById, updateRestaurant } from "api/restaurant";
import Loading from "components/Loading";

import { Colors } from "assets/colors";
import { getData } from "../../utils";

const PricingCard = styled(Card)`
  box-shadow: 0px 0px 30px -6px rgba(0, 0, 0, 0.2);
  display: flex;
  border-width: 0;
  justify-content: center;
  margin-bottom: 50px;
  border-radius: 0.5rem;
  margin-top: 15px;
`;
const StyledCardHeader = styled(Card.Header)`
  background: ${Colors.white} !important;
  border: none !important;
`;

const Title = styled.h3`
  font-size: 1.75rem;
  color: ${Colors.normalGray};
  font-weight: bold;
  text-align: center;
`;

const Subtitle = styled.h3`
  font-size: 1.1rem;
  color: ${Colors.darkGray};
  margin-bottom: 15px;
  text-align: center;
`;

const StyledLabel = styled(Form.Label)`
  font-weight: bold;
  display: block;
`;

const StyledDealCard = styled.div`
  width: 400px;
  height: 200px;
  border-radius: 20px;
  background: #888;
  background-image: url(${(p) => p.image});
  background-size: cover;
  position: relative;
  overflow: hidden;

  .body {
    color: #fff !important;
    padding: 25px;
    position: relative;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    p {
      font-size: 20px;
      margin: 0;
      padding: 0;
    }
  }

  .overlay {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 1;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
`;

const DealCard = (props) => {
  const [restaurant, setRestaurant] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.selectedRestaurant) {
      props.getRestaurantById(props.selectedRestaurant).then((res) => {
        setRestaurant(getData(res));
        setLoading(false);
      });
    }
  }, []);

  return (
    <StyledDealCard
      image={restaurant.images && restaurant.images[0]?.image_small}
    >
      {loading ? (
        <Loading />
      ) : (
        <div className="body">
          <h3>{restaurant.name}</h3>
          <div>
            <p>
              {props.deal_type === 0
                ? "Free " + props.amount_text
                : props.deal_type === 1
                ? "Recieve " + props.discount + "% " + props.discount_text
                : null}
            </p>
            <p>
              {props.deal_type === 0
                ? props.amount + " offers left"
                : props.deal_type === 1
                ? "Expires " + moment(props.valid_to).fromNow()
                : null}
            </p>
          </div>
        </div>
      )}
      <div className="overlay" />
    </StyledDealCard>
  );
};

const Promotions = (props) => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      form: {
        valid_from: null,
        valid_to: null,
        discount: null,
        discount_text: null,
        restaurant: props.selectedRestaurant,
        deal_type: null,
        amount: null,
        amount_text: null,
      },
      errors: [],
      all: [],
      current: [],
      voucher: {},
    }
  );

  const fields = [
    {
      name: "deal_type",
      label: "Deal Type",
      placeholder: "Whether you deal is percentage or free meal.",
      required: true,
      type: "select",
      options: [
        {
          value: 1,
          label: "Percentage",
        },
        {
          value: 0,
          label: "Free Meal",
        },
      ],
    },
    {
      name: "valid_from",
      label: "Valid From",
      placeholder: "When should it start",
      required: state.form.deal_type === 1,
      type: "date",
      hidden: state.form.deal_type !== 1,
    },
    {
      name: "valid_to",
      label: "Valid To",
      placeholder: "When should it end",
      required: state.form.deal_type === 1,
      type: "date",
      hidden: state.form.deal_type !== 1,
    },
    {
      name: "amount",
      label: "Amount",
      placeholder: "How many free meals you're offering",
      required: state.form.deal_type === 0,
      type: "number",
      hidden: state.form.deal_type !== 0,
    },
    {
      name: "discount",
      label: "Percentage Off",
      placeholder: "How much off (%)",
      required: state.form.deal_type === 1,
      type: "number",
      hidden: state.form.deal_type !== 1,
    },
    {
      name: "discount_text",
      label: "Discount Label",
      placeholder: "Label after percentage number",
      required: state.form.deal_type === 1,
      type: "text",
      hidden: state.form.deal_type !== 1,
    },
    {
      name: "amount_text",
      label: "Free Meal Items",
      placeholder: "Which Items are you offering for this deal?",
      required: state.form.deal_type === 0,
      type: "text",
      hidden: state.form.deal_type !== 0,
    },
  ];

  useEffect(() => {
    setState({ currentLoading: true });
    props.getCurrentDeals(props.selectedRestaurant).then((res) => {
      setState({ current: getData(res)?.results, currentLoading: false });
    });

    setState({ allLoading: true });
    props.getAllDeals(props.selectedRestaurant).then((res) => {
      setState({ all: getData(res)?.results, allLoading: false });
    });
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setState({ form: { ...state.form, [name]: value } });
  };

  const handleSelectChange = (event, action) => {
    const { value } = event;
    const { name } = action;

    setState({ form: { ...state.form, [name]: value } });
  };

  const validateFields = () => {
    let errs = [];
    fields.forEach((field, i) => {
      if (field.required && state.form[field.name] === null) {
        errs = [...errs, field.name];
      }
    });

    setState({ errors: errs });
    if (errs.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateFields()) {
      return;
    }

    setState({ createLoading: true });

    let payload = state.form;

    if (payload.valid_from) {
      payload.valid_from += "T00:00";
    }
    if (payload.valid_to) {
      payload.valid_to += "T00:00";
    }

    props
      .createDeal(payload)
      .then((res) => {
        setState({
          current: [getData(res), ...state.current],
          all: [getData(res), ...state.all],
          createLoading: false,
        });
        props.makeAlert("Promotion succesfully created and sent!");
      })
      .catch((err) => {
        setState({ createLoading: false });
        props.makeAlert("Promotion didnt send! try again!", "error");
      });
  };
  // console.log(state.voucher);

  const handleVoucherSubmit = (event) => {
    event.preventDefault();

    if (!state.form.code) {
      return;
    }

    setState({ voucherLoading: true });

    let payload = {
      code: state.form.code,
    };

    props
      .validateVoucher(payload)
      .then((res) => {
        setState({
          voucher: getData(res)
            ? getData(res)
            : { ...getData(res), used: false },
        });
        setState({ voucherLoading: false });
      })
      .catch((err) => {
        setState({ voucherLoading: false });
        setState({ voucher: err });
      });
  };

  return (
    <Container>
      <Row>
        <Col md={12}>
          <PricingCard>
            <StyledCardHeader>
              <Title> {"Create Promotion"} </Title>
              <Subtitle className="mb-2">
                Create a Promotion to get loyal customers.
              </Subtitle>
            </StyledCardHeader>
            <Card.Body>
              <Container>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    {fields
                      .filter((field) => !field.hidden)
                      .map((field) => (
                        <Col sm={6} key={field.name}>
                          <Form.Group>
                            <StyledLabel>{field.label}*</StyledLabel>
                            {field.type === "select" ? (
                              <Select
                                value={field.options.find(
                                  (o) => o.value === state.form[field.name]
                                )}
                                name={field.name}
                                options={field.options}
                                onChange={handleSelectChange}
                                // className={`form-control rounded-pill basic-multi-select option`}
                                // classNamePrefix="select"
                              />
                            ) : (
                              <Form.Control
                                type={field.type}
                                name={field.name}
                                // as='textarea'
                                // placeholder={field.placeholder}
                                value={state.form[field.name]}
                                onChange={handleChange}
                              />
                            )}
                            <Form.Label>{field.placeholder}</Form.Label>
                            {state.errors.includes(field.name) && (
                              <p className="text-danger">
                                This field is required.
                              </p>
                            )}
                          </Form.Group>
                        </Col>
                      ))}
                    <Col sm={12}>
                      <Button type="submit">
                        {state.createLoading ? (
                          <Loading nopadding={true} />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <Jumbotron className="mt-3">
                  <h4>Deal Preview</h4>

                  <DealCard
                    selectedRestaurant={props.selectedRestaurant}
                    getRestaurantById={props.getRestaurantById}
                    {...state.form}
                  />
                </Jumbotron>
              </Container>
            </Card.Body>
          </PricingCard>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <PricingCard>
            <StyledCardHeader>
              <Title> {"Validate Voucher Codes"} </Title>
              <Subtitle className="mb-2">
                Enter the Voucher code and find if it's used or expired.
              </Subtitle>
            </StyledCardHeader>
            <Card.Body>
              <Container>
                <Form onSubmit={handleVoucherSubmit}>
                  <Row>
                    <Col sm={12}>
                      <Form.Group>
                        <StyledLabel>Code*</StyledLabel>
                        <Form.Control
                          type="text"
                          name="code"
                          // as='textarea'
                          // placeholder={field.placeholder}
                          value={state.code}
                          onChange={handleChange}
                        />
                        <Form.Label>Enter voucher code</Form.Label>
                        {state.errors.includes("code") && (
                          <p className="text-danger">This field is required.</p>
                        )}
                      </Form.Group>
                    </Col>
                    <Col sm={12}>
                      <Button type="submit">
                        {state.validateLoading ? (
                          <Loading nopadding={true} />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </Col>
                  </Row>
                  <Jumbotron className="mt-3">
                    {state.voucher.used
                      ? "Code is used in " +
                        moment(state.voucher.last_used_date).format("LLLL")
                      : "Code is valid and not used!"}
                  </Jumbotron>
                </Form>
              </Container>
            </Card.Body>
          </PricingCard>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <PricingCard>
            <StyledCardHeader>
              <Title> {"Ingoing Promotions"} </Title>
              <Subtitle className="mb-2">
                These promotions are currently available to customers.
              </Subtitle>
            </StyledCardHeader>
            <Card.Body>
              <ListGroup>
                {!state.currentLoading ? (
                  state.current.length > 0 ? (
                    state.current.map((p) => (
                      <ListGroup.Item>
                        {p.discount}% | {p.max_users} customers | From{" "}
                        {moment(p.valid_from).format("YYYY MM DD")} to{" "}
                        {moment(p.valid_to).format("YYYY MM DD")}
                      </ListGroup.Item>
                    ))
                  ) : (
                    <p className="text-center">Nothing Here Amigo...</p>
                  )
                ) : (
                  <Loading />
                )}
              </ListGroup>
            </Card.Body>
          </PricingCard>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <PricingCard>
            <StyledCardHeader>
              <Title> {"Promotions History"} </Title>
              <Subtitle className="mb-2">
                All the promotions you ever made.
              </Subtitle>
            </StyledCardHeader>
            <Card.Body>
              <ListGroup>
                {!state.allLoading ? (
                  state.all.length > 0 ? (
                    state.all.map((p) => (
                      <ListGroup.Item>
                        {p.discount}% | {p.max_users} customers | From{" "}
                        {moment(p.valid_from).format("YYYY MM DD")} to{" "}
                        {moment(p.valid_to).format("YYYY MM DD")}
                      </ListGroup.Item>
                    ))
                  ) : (
                    <p className="text-center">Nothing Here Amigo...</p>
                  )
                ) : (
                  <Loading />
                )}
              </ListGroup>
            </Card.Body>
          </PricingCard>
        </Col>
      </Row>
    </Container>
  );
};

export default connect(
  (state) => ({
    selectedRestaurant: state.user.selectedRestaurant,
  }),
  {
    getRestaurantById,
    createDeal,
    makeAlert,
    getCurrentDeals,
    validateVoucher,
    getAllDeals,
  }
)(Promotions);
