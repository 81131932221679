import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import jsCookie from "js-cookie";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter,
} from "react-router-dom";
import { Col, Container, Row, Card, Form } from "react-bootstrap";
import styled from "styled-components";
import Navbar from "components/Layout/Navbar";
import { loginUser } from "actions/user";
import { makeAlert } from "actions";
import { login } from "api/auth";
import Loading from "components/Loading";
import { Colors } from "assets/colors";

const StyledCard = styled(Card)`
  /* background: red; */
  width: 70vw;
  padding: 2rem 3rem 2rem 2rem;
  @media (max-width: 575px) {
    width: 95vw;
    padding: 1rem 1.5rem 1rem 1rem;
  }
  .title {
    color: #000;
    font-family: Inter;
    font-weight: bold;
    font-size: 1.25rem;
    @media (max-width: 575px) {
      font-size: 1.25rem;
    }
  }
  .text {
    margin-top: 0.5rem;
    color: #000;
    font-family: Inter;
    font-weight: 400;
    font-size: 1rem;
    @media (max-width: 575px) {
      font-size: 0.8rem;
    }
  }
  .underline_header {
    font-style: italic;
    text-decoration: underline;
  }
`;
const PrivacyContainer = styled.div`
  width: 100vw;
  min-height: 70vh;
  display: flex;
  justify-content: center;
`;

const PrivacyScreen = ({ locale, ...props }) => {
  return (
    <>
      <Navbar />
      <PrivacyContainer>
        <StyledCard className="o-hidden border-0 shadow-lg my-5">
          <h3>Privacy Policy</h3>
          <h6>Effective Date: Jan 23, 2019</h6>
          <span style={{ marginTop: "2rem" }} className="title">
            Introduction
          </span>
          <p className="text">
            We are Omnicart Inc. ("Omnicart"), a Canadian registered company
            with a registered address at <strong>NORTH VANCOUVER, BC</strong>.
            Our corporation number is <strong>780903332</strong>. We provide a
            merchant platform as service offering described at omnicart.tech
            (our "Services"). We also operate the website available at: 
            <a href="https://www.omnicart.tech/">https://www.omnicart.tech/</a>
             (the "Website").
          </p>
          <p className="text">
            This Privacy Policy explains what personal information Omnicart
            collects about you and why, what we do with that information, how we
            share it, and how we handle the content you place in our products
            and services. For the purposed of this Privacy Policy, "Personal
            Information" means any information related to an identifiable
            individual or that may be used to identify an individual. Please
            read the following carefully to understand our practices regarding
            Personal Information, including Personal Information of visitors to
            our Website, our customers (“Customers”] and the end users of our
            Customers where such information is collected through the
            Services("End Users") .
          </p>
          <p className="text">
            For the purposes of the Personal Information Protection and
            Electronic Documents Act (the “PIPEDA”) : <br /> * Omnicart is the
            Data Controller as a supplier of services, an employer, and where
            dealing with its suppliers; <br /> * Omnicart is the Data Processor
            in respect of Personal Information about End Users of the Services,
            which it processes on behalf of its Customers, as explained further
            below. Such End Users should be notified of this processing by the
            Data Controller in each case.
          </p>
          <p className="text">
            Our Data Protection Officer may be contacted at{" "}
            <a href="contact@omnicart.tech">contact@omnicart.tech</a>.
          </p>
          <span style={{ marginTop: "2rem" }} className="title">
            Changes to our Privacy Policy{" "}
          </span>
          <p className="text">
            We may change this Privacy Policy from time to time. We encourage
            you to review this Privacy Policy each time you use our Website or
            our Services to see if the policy has been changed, as indicated by
            the “Effective Date” set out above. We may at our discretion choose
            to highlight the fact that we made material changes by posting a
            notice on our Website and/or by sending you a notice (if we have
            your contact details), as appropriate.
          </p>
          <span style={{ marginTop: "2rem" }} className="title">
            Information you provide us
          </span>
          <p className="text">
            You may give us Personal Information by:
            <br />
            * Using our service as a Customer of ours. Where you are our
            Customer or are an employee of our Customer you may provide us with
            Personal Information. The type of information collected includes
            your name; company name; business address; email address; phone
            number; username; password; business tax number and basic site
            information i.e. URL/Channel/Impression Traffic etc.
            <br />
            * Corresponding with us by phone, e-mail or otherwise. We ask you to
            disclose only as much information as is necessary to provide you
            with our products or services or to submit a
            question/suggestion/comment in relation to our Website or our
            services or products.
            <br />
            * Filling in forms on our Website such as the contact us form.
            <br />
            * Applying to work with us. The type of information you may provide
            in your CV, a cover letter, your name, address, e-mail address and
            phone number. CVs should include information relevant to your
            employment history and education (degrees obtained, places worked,
            positions held, relevant awards, and so forth). We ask that you do
            not disclose sensitive personal information (e.g. gender, height,
            weight, medical information, religion, philosophical or political
            beliefs, financial data) in your application.
            <br />
            * Supplying us with goods or services. Suppliers may provide us with
            information which may include a contact name; email address;
            business address; and telephone number.
            <br />
            Information we collect from your use of Omnicart Services
            <br />
            Omnicart may also collect certain information from visitors to and
            customers of the Website, such as Internet addresses. This
            information is logged to help diagnose technical problems, to
            administer our Website and in order to constantly improve the
            quality of the Services. We may also track and analyze
            non-identifying and aggregate usage and volume statistical
            information from our visitors and customers and provide such
            information to third parties.
            <br />
            Technologies such as cookies, beacons, scripts and tags are used by
            us and our third-party partners. These technologies are used in
            analyzing trends, administering the Website, tracking End Users'
            movements around the Website, and gathering demographic information
            about our End User base as a whole. We may receive reports based on
            the use of these technologies by these companies on an individual
            and aggregated basis. Various browsers may offer their own
            management tools for removing these types of tracking technologies.
            <br />
            Our third-party partners also employ clear gifs (a.k.a. Web
            Beacons/Web Bugs), images, and scripts that help them better manage
            content on our Website. We do not tie the information gathered to
            our Customers' or End Users' Personal Information.
          </p>
          <span style={{ marginTop: "2rem" }} className="title">
            Cookies on the Website{" "}
          </span>
          <p className="text">
            When you interact with the Website we strive to make that experience
            easy and meaningful. When you come to our Website, our web server
            sends a cookie to your computer. Cookies are files that web browsers
            place on a computer's hard drive and are used to tell us whether
            customers and visitors have visited the Website previously. If you
            click on a link to a third-party Website, such third party may also
            transmit cookies to you.
          </p>
          <p className="text">
            Standing alone, cookies do not identify you personally. They merely
            recognize your browser. Unless you choose to identify yourself to
            Omnicart, either by responding to a promotional offer, opening an
            account, or registering for a free trial, you remain anonymous to
            Omnicart. Cookies come in two flavors: session and persistent-based.
            Session cookies exist only during an online session. They disappear
            from your computer when you close your browser software or turn off
            your computer. Persistent cookies remain on your computer after
            you've closed your browser or turned off your computer. They include
            such information as a unique identifier for your browser.
          </p>
          <p className="text">
            Omnicart uses session cookies containing encrypted information to
            allow the system to uniquely identify you while you are logged in.
            This information allows Omnicart to process your online transactions
            and requests. Session cookies help us make sure you are who you say
            you are after you've logged in and are required in order to use the
            Omnicart application. Omnicart uses persistent cookies that only
            Omnicart can read and use, to identify the fact that you are an
            Omnicart customer or prior Omnicart Website visitor (whatever the
            case may be). We are especially careful about the security and
            confidentiality of the information stored in persistent cookies. For
            example, we do not store account numbers or passwords in persistent
            cookies. Users who disable their web browsers ability to accept
            cookies will be able to browse our Website but will not be able to
            successfully use our Services. End Users may be able to limit
            cookies used by their browsers and third parties by updating
            settings
            <br />
            at: 
            <a href={"http://www.aboutads.info/choices"}>
              {" "}
              http://www.aboutads.info/choices
            </a>{" "}
            and 
            <a href={"http://www.youronlinechoices.eu"}>
              {" "}
              http://www.youronlinechoices.eu
            </a>
            .
          </p>
          <span style={{ marginTop: "2rem" }} className="title">
            How we use information we collect
          </span>
          <p className="text">
            We collect the Personal Information described above in order to
            provide our Services to our Customers and their End Users, to market
            our products and services, to improve our Website and to recruit
            staff.
            <br />
            We will use the information we collect in the following ways:
          </p>
          <p className="text">
            <span className="underline_header">End Users</span>
            <br />
            * To answer your Customer support questions, where you are a
            customer or End User of an organization which uses our Services
            (i.e. our customer support chat bots).
            <br />
            * To improve the quality of our Services. Your interactions (i.e.
            your conversations with our chat bots) are used to determine the
            effectiveness of our Services and to improve the quality of our
            Services; Omnicart utilizes anonymized "End User" interactions to
            information and train our machine learning algorithms.
            <br />* The legal bases for the processing of your data should be
            set out in your contract with the Data Controller and details
            provided in the Data Controller's Privacy Notice.
          </p>
          <p className="text">
            <span className="underline_header">Customers</span>
            <br />
            * To fulfill our obligations arising from our contract with you i.e.
            to provide you with our Services;
            <br />
            * To set your company up as a customer on our systems.
            <br />
            * To liaise with you about projects that we are undertaking with
            you.
            <br />
            * To deliver information about our products and services, where you
            have subscribed to receive same.
            <br />
            * To ensure payment of our invoices.
            <br />
            <span className="underline_header">Website users </span>
            <br />
            * To administer and improve our Website and for internal operations,
            including troubleshooting, data analysis, testing, research,
            statistical and survey purposes.
            <br />
            * As part of our efforts to keep our Website safe and secure.
            <br />
            * To measure or understand the effectiveness of advertising we serve
            to you and others, and to deliver relevant advertising to you.
            <br />* To make suggestions and recommendations to you and other
            users of our Website about services that may interest you or them;
          </p>
          <span style={{ marginTop: "2rem" }} className="title">
            Who we share your information with{" "}
          </span>
          <p className="text">
            We may share your Personal Information with our selected business
            partners, suppliers and sub-contractors for the performance of any
            contract we enter into with them or you. For example, these business
            partners may include our web hosting provider and our IT service
            providers.
          </p>
          <p className="text">
            We may share your Personal Information with our selected business
            partners, suppliers and sub-contractors for the performance of any
            contract we enter into with them or you. For example, these business
            partners may include our web hosting provider and our IT service
            providers.
          </p>
          <p className="text">
            We use a number of sub processors and service providers to both
            assist us in providing the Website and Services and
            <br />
            In addition, we may disclose your Personal Information to third
            parties:
            <br />
            * In the event that we sell or buy any business or assets, in which
            case we may disclose your Personal Information to the prospective
            seller or buyer of such business or assets.
            <br />
            * If we or substantially all of our assets are acquired by a third
            party, in which case information held by us about our Customers and
            their End Users will be one of the transferred assets.
            <br />* If we are under a duty to disclose or share your information
            in order to comply with any legal obligation, or in order to enforce
            or apply our terms of use and other agreements; or to protect our
            rights, property, or safety, our customers, or others. This includes
            exchanging information with other companies and organizations for
            the purposes of fraud protection and credit risk reduction.
          </p>
          <h4 style={{ fontFamily: "Inter" }}>Contact Us</h4>
          <h6 style={{ fontFamily: "Inter" }}>
            Our Data Protection Officer can be reached at 
            <a href="contact@omnicart.tech">contact@omnicart.tech</a>.
          </h6>
        </StyledCard>
      </PrivacyContainer>
    </>
  );
};

export default connect(
  (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    locale: state.locale[state.locale.active],
  }),
  { login, loginUser, makeAlert }
)(PrivacyScreen);

// "privacy_introduction": "Introduction",
// "privacy_introduction_text_1": "We are Omnicart Inc. ('Omnicart'), a Canadian registered company with a registered address at [INSERT ADDRESS]. Our corporation number is [INSERT NUMBER]. We provide a restaurant platform as service offering described at omnicart.tech (our 'Services'). We also operate the website available at: https://www.omnicart.tech/ (the 'Website').",
// "privacy_introduction_text_2": "This Privacy Policy explains what personal information Omnicart collects about you and why, what we do with that information, how we share it, and how we handle the content you place in our products and services. For the purposed of this Privacy Policy, 'Personal Information' means any information related to an identifiable individual or that may be used to identify an individual. Please read the following carefully to understand our practices regarding  Personal Information, including Personal Information of visitors to our Website,  our customers (“Customers”] and the end users of our Customers where such information is collected through the Services('End Users'). ",
// "privacy_introduction_text_3": "For the purposes of the European General Data Protection Regulation (the “GDPR”) :
// * XXX is the Data Controller as a supplier of services, an employer, and where dealing with its suppliers;
// * XXX is the Data Processor in respect of Personal Information about End Users of the Services, which it processes on behalf of its Customers, as explained further below. Such End Users should be notified of this processing by the Data Controller in each case.",

// <h2> { locale.privacy_policy_title } </h2>
// <p> { locale.privacy_policy_line1 } <a href="https://dineeasy.com"> { locale.privacy_policy_line1_a_tag } </a> { locale.privacy_policy_line1_cont } </p>
// <p> { locale.privacy_policy_line2 } </p>
// <p> { locale.privacy_policy_line3 } </p>
// <p> { locale.privacy_policy_line4 } </p>
// <p> { locale.privacy_policy_line5 } </p>
// <p> { locale.privacy_policy_line6 } </p>
// <p> { locale.privacy_policy_line7 } </p>
// <p> { locale.privacy_policy_line8 } </p>
// <p> { locale.privacy_policy_line9 } <a title={locale.privacy_policy_line9_a_tag_title} href="https://getterms.io/"> {locale.privacy_policy_line9_a_tag}</a></p>
