import React from 'react'
import Modal from 'react-bootstrap/Modal'
import styled from 'styled-components'
import {connect} from 'react-redux'

const StyledModalBody = styled(Modal.Body)`
  * {
    direction: ${p => p.rtl ? 'rtl' : 'ltr'};
    text-align: ${p => p.rtl ? "right" : 'left'};
    /* font-family: 'Vazir', sans-serif; */
  }
`

const ModalBody = props => <StyledModalBody {...props} />

export default connect(state => ({rtl: state.locale[state.locale.active].rtl}))(ModalBody)
