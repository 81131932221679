import React, { useContext, useState, useEffect } from "react";
import { StyledTable } from "./styles";
import PaginateTable from "components/PaginateTable";
import Switch from "components/common/Switch";
import { getData } from "utils";
import { ModalContext } from "../../../context/modalContext";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "components/common/Dropdown";
import { ReactComponent as MoreIcon } from "assets/image/more.svg";
import { useHistory } from "react-router";
import { deleteDiscountApiCall, editDiscountApiCall } from "api/discounts";
import { getRestaurantById } from "api/restaurant";
import DeleteModal from "../DeleteModal";
import SkeletonLoading from "components/common/SkeletonLoading";
import { priceTransformer } from "../../../utils";

const Table = ({
  categoryTitle,
  loading,
  discounts,
  pagination,
  resetPaging,
  reload,
  ...props
}) => {
  const ROW_OPTIONS = [
    {
      title: <div>Edit discount</div>,
      element: <div className="more-btn--list-item">Edit discount</div>,
    },
    {
      title: <div>Delete</div>,
      element: (
        <div className="more-btn--list-item more-btn--list-item-red">
          Delete
        </div>
      ),
    },
  ];
  const MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const { handleModal } = useContext(ModalContext);
  const [data, setData] = useState([...discounts]);
  const [statusLoading, setStatusLoading] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const restaurantId = useSelector((state) => state.user?.selectedRestaurant);

  useEffect(() => {
    setData([...discounts]);
    setStatusLoading([...discounts.map((d) => false)]);
  }, [discounts]);

  const getDayAndMonth = (date) => {
    return `${MONTHS[new Date(date).getMonth()]} ${new Date(date).getDate()}`;
  };

  const handleOpenDiscount = (row) => {
    history.push(`/console/discounts/edit/${row?.id}`);
  };

  const handleUpdateStatus = (row) => {
    let itemIndex = data.findIndex((i) => i.id === row?.id);
    let temp = [...data];

    let updatedData = {
      name: row.name,
      description: row.description,
      amount: row.amount,
      discount_type: row.discount_type.value,
      starts_at: row.starts_at,
      ends_at: row.ends_at,
      is_active: !row.is_active,
      items: row.items.map((i) => i.id),
      active_hours: row.active_hours,
    };
    let statusLoadingTemp = [...statusLoading];
    statusLoadingTemp[itemIndex] = true;
    setStatusLoading(statusLoadingTemp);
    dispatch(editDiscountApiCall(restaurantId, row.id, updatedData))
      .then((res) => {
        statusLoadingTemp[itemIndex] = false;
        setStatusLoading(statusLoadingTemp);
        temp[itemIndex].is_active = getData(res)?.is_active;
        setData([...temp]);
      })
      .catch((err) => {
        statusLoadingTemp[itemIndex] = false;
        setStatusLoading(statusLoadingTemp);
      });
  };

  return (
    <StyledTable>
      {/* Table */}
      <PaginateTable
        loading={loading}
        headers={[
          { title: "Name", width: "238px" },
          { title: "Type", width: "168px" },
          { title: "Amount", width: "134px" },
          { title: "Applys to", width: "198px" },
          { title: "Active dates", width: "204px" },
          { title: "Status", width: "72px" },
          { title: "", width: "auto" },
        ]}
        dataArray={loading ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] : data}
        rowCreators={[
          (row, index) => <div className="">{row?.name}</div>,
          (row, index) => (
            <div className="strong">{row?.discount_type?.label}</div>
          ),
          (row, index) => (
            <div className="strong">
              {row?.discount_type?.value === 0
                ? `${row?.amount}%`
                : priceTransformer(row?.amount)}
            </div>
          ),
          (row, index) => (
            <div className="">
              {row?.on_all_items
                ? `All products`
                : `${row?.items?.length} products`}
            </div>
          ),
          (row, index) => (
            <div className="">
              {" "}
              <>
                {row?.active_hours && row?.active_hours[0]?.weekdays?.length ? (
                  <p className="line">Weekly</p>
                ) : null}
                <p>
                  {!row?.starts_at ? (
                    <p>Forever</p>
                  ) : (
                    <>
                      {getDayAndMonth(row?.starts_at)}{" "}
                      {row?.ends_at ? " to " : ""}
                      {row?.ends_at ? getDayAndMonth(row?.ends_at) : ""}
                    </>
                  )}
                </p>
              </>
            </div>
          ),
          (row, index) => (
            <div onClick={(e) => e.stopPropagation()}>
              {statusLoading[index] ? (
                <SkeletonLoading width={36} height={20} count={1} />
              ) : (
                <Switch
                  checked={row?.is_active}
                  onChange={() => handleUpdateStatus(row)}
                  classes="switch-box"
                />
              )}
            </div>
          ),
          (row, index) => (
            <div onClick={(e) => e.stopPropagation()}>
              <Dropdown
                label={<MoreIcon />}
                options={ROW_OPTIONS}
                onChange={(val) => {
                  if (val.props.children === "Edit discount") {
                    handleOpenDiscount(row);
                  } else {
                    handleModal(<DeleteModal row={row} reload={reload} />);
                  }
                }}
                selected={null}
                noArrow
                btnClass="more-btn"
                btnOpenClass="more-btn--open"
              />
            </div>
          ),
        ]}
        noRecord="There are no records to display."
        onClick={loading ? () => {} : handleOpenDiscount}
        newRowClasses="new-row"
        dataPaging={pagination}
      />
    </StyledTable>
  );
};

export default Table;
