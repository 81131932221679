import React from "react";
import styled from "styled-components";
import { Colors } from "assets/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const CircleCheckBox = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1rem;
  border: 2px solid ${Colors.primary1};
  display: flex;
  justify-content: center;
  align-items: center;
  .active_class {
    width: 0.75rem;
    height: 0.75rem;
    background-color: ${Colors.primary1};
    border-radius: 0.5rem;
  }
`;

const SquareCheckBox = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 2px;
  border: ${(p) =>
    p.active ? `3px solid ${Colors.primary1}` : "3px solid rgba(0, 0, 0, 0.6)"};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => (p.active ? Colors.primary1 : "#fff")};
`;

const CheckBox = ({ ...props }) => {
  const { type, style, onClick, active } = props;
  const renderCheckBox = () => {
    switch (type) {
      case "CIRCLE":
        return (
          <CircleCheckBox style={style} onClick={onClick}>
            {active && <div className="active_class" />}
          </CircleCheckBox>
        );
        break;
      case "SQUARE":
        return (
          <SquareCheckBox active={active} style={style} onClick={onClick}>
            {active && (
              <FontAwesomeIcon icon={faCheck} style={{ color: "#fff" }} />
            )}
          </SquareCheckBox>
        );
        break;
      default:
        return (
          <div style={style} onClick={onClick}>
            def
          </div>
        );
    }
  };
  return renderCheckBox();
};
export default CheckBox;
