import styled from "styled-components";
import { Colors } from "assets/colors";

export const StyledWrapper = styled.div`
  width: 774px;

  .top {
    height: 60px;
    padding: 18px 20px;
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
  }

  .title {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: ${Colors.textPrimary1}
    margin-right: 12px;
  }

  .badges {
    display: flex;
    flex-direction: row;
  }

  .status {
    margin-right: 6px;
    height: 28px;
    align-items: center;
    display: flex;

    &--new {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #9c400b;
      background: #f9e6d2;
      border-radius: 4px;
      padding: 4px 8px;

      svg {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }

    &--scheduled {
      padding: 4px 6px;
    }

    &--accepted {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #045f5a;
      background: #deefef;
      border-radius: 4px;
      padding: 4px 8px;
    }

    &--ready {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #0e623f;
      background: #cbf4c9;
      border-radius: 4px;
      padding: 4px 8px;
    }

    &--on-the-way {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #4b3a95;
      background: #e5dffc;
      border-radius: 4px;
      padding: 4px 8px;
    }

    &--completed {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #0e623f;
      background: #cbf4c9;
      border-radius: 4px;
      padding: 4px 8px;
    }

    &--rejected {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: ${Colors.textPrimary1}
      background: #e7e8e9;
      border-radius: 4px;
      padding: 4px 8px;
    }

    &--cancelled {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #aa2720;
      background: #fae5e4;
      border-radius: 4px;
      padding: 4px 8px;
    }
  }

  .order-type {
    height: 28px !important;
  }

  .type-size {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${Colors.textPrimary1}
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 4px;

    .icon--lead {
      margin-right: 4px;
    }

    .icon {
      width: 16px;
      height: 16px;

      svg {
        width: 16px;
        height: 16px;
        margin-top: -2px;
      }
    }
  }

  .order-type {
    border: 1px solid #dcdddd;
    width: 79px;
    height: 24px;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 6px;
  }

  .placed-on {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #666b6d;
    position: absolute;
    right: 68px;
    top: 50%;
    transform: translateY(-50%);
  }

  .modal-close-btn {
    top: 16px;
    right: 20px;
    z-index: 2;
  }

  .customer {
    height: 52px;
    background: #f5f7f8;
    border-top: 1px solid #dde1e4;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: ${Colors.textPrimary1}
    padding: 17px 20px;

    &-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }

    &-gift {
      display: flex;
      flex-direction: column;
      height: fit-content;
      padding: 12px 20px 14px 20px;

      &-mess {
        width: 704px;
        height: fit-content;
        background: #f2e0ef;
        border-radius: 5px;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: ${Colors.textPrimary1}
        padding: 13px 12px;
        display: flex;
        flex-direction: row;
        margin-top: 14px;

        &-title {
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          margin-right: 12px;
          color: #666b6d;
          white-space: nowrap;
        }
      }
    }
  }

  .customer-name-title {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #666b6d;
    margin-bottom: 4px;
  }

  .customer-name {
    width: 210px;
  }

  .customer-link {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #2b64f5;
    width: 210px;

    svg {
      margin-left: 4px;
    }
  }

  .customer-email {
    margin-right: 90px;
  }

  .products {
    background: #f5f7f8;
    border-top: 1px solid #dde1e4;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: ${Colors.textPrimary1}
    padding: 18px 20px;
    max-height: calc(100vh - 567px);
    overflow: auto;
  }

  .product {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    &-left {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }

    &-num {
      width: 15px;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: ${Colors.textPrimary1}
      text-align: center;
    }

    &-multiply {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: ${Colors.textPrimary1}
    }

    &-detail {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 16px;

      &-name {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: ${Colors.textPrimary1}
      }

      &-mods {
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #666b6d;

        svg {
          margin-right: 4px;
          color: #95989a;
        }
      }
    }

    &-price {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #666b6d;
    }
  }

  .financial {
    background: #f5f7f8;
    border-top: 1px solid #dde1e4;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: ${Colors.textPrimary1}
    padding: 14px 20px;
    display: flex;
    flex-direction: column;

    p {
      margin: 0;
    }

    &-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 4px;
    }

    &-bold {
      font-weight: bold;
    }
  }

  .est {
    background: #f5f7f8;
    border-top: 1px solid #dde1e4;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: ${Colors.textPrimary1}
    padding: 12px 20px;
    display: flex;
    flex-direction: column;

    p {
      margin: 0;
    }

    &-info {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #666b6d;
      display: flex;

      svg {
        margin-right: 6px;
      }
    }
  }

  .completed {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #666b6d;
    display: flex;
    justify-content: center;
    padding: 12px;

    svg {
      margin-right: 6px;
    }
  }

  .list {
    padding: 0 !important;
    flex-direction: row;
    display: flex;
    align-items: center;
  }

  .list-item-custom {
    margin: 15px 0 !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: flex-start !important;
    background: #f5f7f8 !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 18px !important;
    line-height: 22px !important;
    color: ${Colors.textPrimary1} !important;
    white-space: nowrap !important;
    padding-right: 0 !important;
    margin-right: 20px !important;
    padding-left: 26px !important;
  }

  .checkmark-custom {
    top: 50% !important;
    transform: translateY(-50%) !important;
    left: 0 !important;
    height: 14px !important;
    width: 14px !important;
    background: #ffffff !important;
    box-shadow: 0px 0px 0px 1px #dcdddd, 0px 2px 5px rgba(60, 66, 87, 0.1),
      0px 1px 1px rgba(0, 0, 0, 0.1) !important;
    border: none !important;

    &:after {
      width: 6px !important;
      height: 6px !important;
      background: white !important;
    }
  }

  .container input:checked ~ .checkmark-custom {
    background: #2b64f5 !important;
    box-shadow: 0px 0px 0px 1px #2b64f5, 0px 2px 5px rgba(60, 66, 87, 0.1),
      0px 1px 1px rgba(0, 0, 0, 0.1) !important;
  }

  .est-input {
    height: 32px !important;
    font-size: 14px !important;
    width: 202px !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding: 7px 12px !important;
  }

  .btns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 12px 20px;
    border-top: 1px solid #dde1e4;
  }

  .reject-btn {
    padding: 9px 10px;
    margin-right: 12px;
    font-size: 14px;
    line-height: 18px;
    color: #c7322d;
    font-weight: bold;
  }

  .confirm-btn {
    padding: 9px 10px;
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
  }

  .update-time-btn {
    padding: 9px 10px;
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    color: ${Colors.textPrimary1}
  }

  .estimate {
    background: #f5f7f8;
    border-top: 1px solid #dde1e4;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #0e623f;
    padding: 12px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &--passed {
      color: #c7322d;
    }

    p {
      margin: 0;
    }

    svg {
      width: 20px;
      height: 20px;
      padding: 2px;
      margin-right: 8px;
    }
  }

  .address {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    padding: 17px 20px;
    color: #666b6d;
    background: #f5f7f8;
    border-top: 1px solid #dde1e4;
    padding: 12px 20px;
    display: flex;
    align-items: center;

    &-text {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: ${Colors.textPrimary1}
      margin-right: 8px;
      margin-left: 12px;
    }

    a {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #2b64f5;
      text-decoration-line: underline;
    }
  }

  .scheduled {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    padding: 17px 20px;
    color: #666b6d;
    background: #f5f7f8;
    border-top: 1px solid #dde1e4;
    padding: 12px 20px;
    display: flex;
    align-items: center;

    &-text {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: ${Colors.textPrimary1}
      margin-right: 8px;
      margin-left: 12px;
    }

    a {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #2b64f5;
      text-decoration-line: underline;
    }

    svg {
      margin-right: 8px;
      width: 20px;
      height: 20px;
    }
  }

  .m-0 {
    margin-right: 0;
  }

  .delivery-eta {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    padding: 17px 20px;
    color: #666b6d;
    background: #f5f7f8;
    border-top: 1px solid #dde1e4;
    padding: 12px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &-col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 234px;
    }

    &-text {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: ${Colors.textPrimary1}
      margin-top: 4px;
    }
  }

  .driver-phone-link {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #2b64f5;
    margin-left: 8px;
  }

  .gift {
    width: 58px;
    height: 28px;
    background: #f2e0ef;
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #963686;
    padding: 6px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      margin-right: 4px;
    }
  }

  .time-input {
    position: relative;
  }

  .unit {
    position: absolute;
    right: 32px;
    top: 50%;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #7d8284;
    transform: translateY(-50%);
  }
`;
