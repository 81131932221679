import "react-toastify/dist/ReactToastify.css";

import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";

import { UNDO_PERIOD } from "constants";
import { connect } from "react-redux";
import styled from "styled-components";

class Alerts extends Component {
  state = {
    type: "",
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.alerts !== prevProps.alerts) {
      const alert = this.props.alerts[0];
      const comp = (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {alert && <p>{alert.text}</p>}
          {alert.undo && (
            <button
              style={{
                background: "transparent",
                border: "none",
                fontWeight: "bold",
                color: "#fff",
                textAlign: "left",
              }}
              onClick={alert.undo}
            >
              {this.props.locale.undo}
            </button>
          )}
        </div>
      );

      const body = {
        position: "top-right",
        autoClose: false,
        autoClose: alert.undo ? UNDO_PERIOD : 3000,
        // rtl: true,
        hideProgressBar: alert.undo ? false : true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
      };

      switch (alert && alert.type) {
        case "error":
          toast.error(comp, body);
          break;
        case "warning":
          toast.warning(comp, body);
          break;
        default:
          toast.info(comp, body);
          return;
      }
    }
  };

  render() {
    return <ToastContainer className={this.state.type} />;
  }
}

export default connect((state) => ({
  alerts: state.alerts,
  locale: state.locale[state.locale.active],
}))(Alerts);
