import { Button, Card, Form, Spinner } from "react-bootstrap";

import { ReactComponent as ArrowLeftIcon } from "assets/image/arrowLeft.svg";
import CustomButton from "components/customButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Images } from "assets/image";
import { LANDING_PAGE_ADDRESS } from "../../constants";
import { Link } from "react-router-dom";
import Loading from "components/Loading";
import LoadingCard from "components/LoadingCard";
import React, { useState } from "react";
import { ReactComponent as UserIcon } from "assets/image/person.svg";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { Colors } from "assets/colors";
import { ReactComponent as OCLogoIcon } from "assets/image/OCLogo.svg";

const Baner = styled.div`
  .logo_image {
    width: 14rem;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 3rem;
  width: 90%;
  height: 2.5rem;
  border-radius: 1.5rem;
  background-color: #833ab4;
  border: none;
  outline: none;
  &:hover {
    background: #421d5a;
  }
  .button_children {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: none !important;
    .button_icon {
      position: absolute;
      right: 5px;
    }
  }
`;

const StyledBtnChildren = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: none !important;
  .button_icon {
    position: absolute;
    right: 5px;
  }
  .btn-desc {
    text-transform: none !important;
    font-size: 14px !important;
    color: #ffffff !important;
    opacity: 0.5 !important;
    font-weight: normal !important;
  }
`;

const TermsErrorText = styled.span`
  width: 72%;
  margin-top: 1rem;
  color: red;
`;

const StyledAuthCard = styled(Card)`
  width: 630px;
  min-height: 20rem;
  border-radius: 0 !important;
  margin-top: 1rem;
  padding: 30px 0 20px 0;
  margin-bottom: 2rem;
  border: 1px solid #ececec;
  background: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 15px #0000000d;
  margin-top: 51px;

  @media (max-width: 1120px) {
    width: 100vw;
    height: 100vh;
    margin: 0;
    border: none;
    overflow: auto;
    padding-top: 16px;
  }

  .form-mobile {
    width: 100%;
    @media (max-width: 1120px) {
      height: 100%;
      overflow: auto;
      padding: 0;
      padding-bottom: 130px;
      width: 100%;
    }
  }

  .bottom-mobile {
    width: 100%;
    @media (max-width: 1120px) {
      width: 100%;
      position: absolute;
      bottom: 0;
      padding: 20px 0;
      background: white;
      z-index: 1000;
    }
  }

  .cross {
    background-color: rgba(236, 236, 236, 0.4);
    width: 32px;
    height: 32px;
    padding: 13px;
    color: #151515;
    font-size: 10px;
    position: absolute;
    right: 45px;
    text-decoration: none;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1120px) {
      right: 25px;
      font-size: 14px;
      display: ${(p) => (p.hideCross ? "none" : "")};
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .back-btn {
    background: rgba(236, 236, 236, 0.4);
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    padding: 0;

    svg {
      color: #373333;
      font-size: 16px;
      width: 16px;
      height: 16px;
    }
  }
  .web {
    @media (max-width: 1120px) {
      display: none !important;
    }
  }
  .mobile-back {
    display: none;
    @media (max-width: 1120px) {
      display: block;
      width: 32px;
      height: 32px;
      font-size: 12px;
      font-weight: bold;
      margin: 0;
      margin-right: 15px;
    }
  }
  .padded {
    width: 100%;
    padding: 0 55px !important;

    @media (max-width: 1120px) {
      padding: 0 25px !important;
    }
  }
  .mobile-bottom {
    @media (max-width: 1120px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0;
    }
  }
  .mobile {
    display: none;
    @media (max-width: 1120px) {
      display: flex;
      width: 100%;
      height: 38px;
      text-align: center;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;
      color: black;
      padding: 0;

      &:hover {
        color: black;
      }
    }
  }
  .auth {
    background: #8a8a8a;
    color: #fff;
    height: 56px;
    font-weight: bold;
    font-size: 18px;
    width: 100%;
    border-radius: 0;
    @media (max-width: 1120px) {
      height: 56px;
      padding: 15px;
    }
  }
  .valid {
    background: ${Colors.primary1};
    color: #fff;
    height: 56px;
    font-weight: bold;
    font-size: 18px;
    width: 100%;
    border-radius: 0;

    @media (max-width: 1120px) {
      height: 56px;
      padding: 15px 0;
    }
  }
  .flex-1 {
    flex: 1;
  }
  .title {
    font-size: 24px;
    color: #151515;
    font-weight: bold;
    text-align: center;
    margin: auto;
    float: left;
    margin-left: 55px;
    margin-bottom: 14px;

    p {
      margin: 0;

      @media (max-width: 1120px) {
        display: flex;
        flex-direction: row;
      }
    }

    @media (max-width: 1120px) {
      font-size: 22px;
      margin-left: 25px;
      margin-top: 0;
    }
  }
  .line {
    width: 100%;
    background: #ececec;
    border: 1px solid #ececec;
    margin: 30px 0 20px 0;

    @media (max-width: 1120px) {
      margin-top: 0;
    }
  }
  .check_box_wrapper {
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 75%;
    .check_box_text {
      margin-left: 1.5rem;
    }
    .form-check-input {
      width: 1.75rem;
      height: 1.75rem;
    }
  }
  //     @media (max-width: 1120px) {

  //   width: 35rem;
  //   border-radius: 2rem;
  //   .check_box_wrapper {
  //     width: 65%;
  //   }
  // }
  // @media (max-width: 600px) {
  //   width: 30rem;
  //   border-radius: 1rem;
  //   padding-top: 1rem;
  //   .check_box_wrapper {
  //     width: 90%;
  //   }
  // }
  // @media (max-width: 520px) {
  //   width: 25rem;
  // }
  // @media (max-width: 455px) {
  //   width: 20rem;
  // }

  .agree {
    display: flex;
    align-items: center;
    margin-left: 51px;
    margin-bottom: 15px;
    font-size: 12px;
    font-weight: bold;
    color: rgba(21, 21, 21, 0.6);

    p {
      margin-bottom: 0;
      margin-left: 12px;
    }

    @media (max-width: 1120px) {
      margin-left: 30px;
    }
  }
`;
const StyledContainer = styled.div`
  background-image: #ffffff;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;

  @media (max-width: 1120px) {
    margin: 0;
    padding: 0;
  }

  .screen_title {
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 4rem;
  }
  .screen_subtitle {
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 1rem;
  }
`;
const StyledLogo = styled(Link)`
  text-decoration: none;  
cursor:pointer;

   &:hover {
    text-decoration: none;
  }
  h1 {
    color: #151515;
    font-size: 20px;
    font-weight: bold;
    line-height: 12px;
    margin: 0;
}

svg {
  width: 136px;
}
  }
  span {
    font-size: 12px;
    color: black;
  }
  .colored {
    color: ${Colors.primary1};
    font-size: 16px;
    font-weight: bold;
  }
`;

const StyledSignUpBtn = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: #ffffff;
  }
  background: #151515;
  font-size: 14px;
  color: #ffffff;
  padding: 10px 15px;
  margin: 0;
  border: none;
`;

const StyledLoginBtn = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: #ececec;
  }
  background: #ffffff;
  font-size: 14px;
  color: #ececec;
  padding: 10px 15px;
  margin: 0;
  border: 1px solid #151515;

  span {
    color: #151515;
  }

  svg {
    color: #151515;
    width: 12px;
  }
`;

const StyledHeader = styled.div`
  width: calc(100% - 400px);
  min-width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1120px) {
    display: none;
  }
`;

const ForgetWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: #0984e3 !important;

  @media (max-width: 1120px) {
    display: ${(p) => (p.mobileHide ? "none" : "block")};
  }

  a {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    color: #0984e3 !important;
  }
`;
const PageLink = styled(Link)`
  color: #000 !important;
  font-size: 1rem;
  font-weight: 600;
`;

const Switcher = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  .switcher_button {
    margin-left: 5px;
    color: #000;
    cursor: pointer;
    font-weight: bold;
  }
`;

const AuthenticationCard = (props) => {
  const {
    children,
    title,
    login,
    handleSubmit,
    register,
    step,
    setStep,
    loading,
    locale,
    changeCheckBox,
    handleNextRegisterForm,
    personalForm2Validation,
    valid,
    isCardFilled,
    parent,
  } = props;

  const [agreed, setAgreed] = useState(false);

  const buttonFunction = (props) => {
    if (login) {
      return <span>Login</span>;
    } else if (register) {
      switch (step) {
        case 0:
          return (
            <StyledBtnChildren>
              <span>Continue</span>
              <FontAwesomeIcon className="button_icon" icon={faSignInAlt} />
            </StyledBtnChildren>
          );

          break;
        case 1:
          return (
            <StyledBtnChildren>
              <span>Next </span>
              <span className="btn-desc">
                {parent?.is_subscription_required
                  ? "(two more step)"
                  : "(one more step)"}
              </span>
            </StyledBtnChildren>
          );

          break;
        case 2:
          if (parent?.is_subscription_required) {
            return (
              <StyledBtnChildren>
                <span>
                  Next <span className="btn-desc">(one more step)</span>
                </span>
              </StyledBtnChildren>
            );
          } else {
            return (
              <StyledBtnChildren>
                <span>Submit</span>
              </StyledBtnChildren>
            );
          }

          break;
        case 3:
          return (
            <StyledBtnChildren>
              <span>Submit</span>
            </StyledBtnChildren>
          );

          break;
        default:
      }
    }
  };

  const cardTitleGenerator = () => {
    if (step === 1) {
      return "Get Started";
    } else if (step === 2) {
      return (
        <p>
          {" "}
          <CustomButton
            classes="back-btn mobile-back"
            label={<ArrowLeftIcon />}
            onClick={handleGoBack}
          />
          Security
        </p>
      );
    } else if (step === 3) {
      return (
        <p>
          <CustomButton
            classes="back-btn mobile-back"
            label={<ArrowLeftIcon />}
            onClick={handleGoBack}
          />
          <p className="web">Final step: </p>Bank detail
        </p>
      );
    }
  };

  const handleClickCardBtn = () => {
    if (register) {
      if (step === 3) {
        return handleSubmit;
      } else if (!parent?.is_subscription_required && step === 2) {
        return handleSubmit;
      } else {
        return handleNextRegisterForm;
      }
    } else {
      return handleSubmit;
    }
  };

  const handleGoBack = () => {
    if (step === 2) {
      setStep(1);
    } else if (step === 3) {
      setStep(2);
    }
  };

  const createBtnClasses = () => {
    // when form is not valid it's gray in login and forget-pass forms
    if (register) {
      return "valid flex-1";
    } else {
      if (valid) {
        return "valid";
      } else {
        return "auth";
      }
    }
  };

  const handleDisableSubmit = () => {
    if (step === 3) {
      if (isCardFilled && agreed) {
        return false;
      } else {
        return true;
      }
    } else if (!parent?.is_subscription_required && step === 2) {
      if (personalForm2Validation(true) && agreed) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const showAgreeToTerms = () => {
    if (step === 3 && !props.initialLoading && !loading) {
      return true;
    } else if (
      !parent?.is_subscription_required &&
      step === 2 &&
      !props.initialLoading &&
      !loading
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <StyledContainer>
      <StyledHeader>
        <StyledLogo to="/">
          <OCLogoIcon />
        </StyledLogo>
        {login ? (
          <StyledSignUpBtn to="/register">
            Don't have an account yet? <strong>&nbsp;Get Started</strong>
          </StyledSignUpBtn>
        ) : (
          <StyledLoginBtn to="/login">
            <UserIcon />
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <span>
              Already have an account? <strong>Login</strong>
            </span>
          </StyledLoginBtn>
        )}
      </StyledHeader>
      {props.initialLoading ? (
        <LoadingCard withSpinner loading={props.initialLoading} />
      ) : (
        <>
          <StyledAuthCard hideCross={register && step !== 1}>
            <a target="_blank" href={LANDING_PAGE_ADDRESS} className="cross">
              &#10005;
            </a>
            {login && <div className="title">Welcome back</div>}
            {register && <div className="title">{cardTitleGenerator()}</div>}
            <div className="form-mobile">{props.children}</div>

            <div className="bottom-mobile">
              <hr className="line" />
              {showAgreeToTerms() ? (
                <div className="agree">
                  <input
                    type="checkbox"
                    checked={agreed}
                    onChange={(e) => {
                      setAgreed(e.target.checked);
                    }}
                  />
                  <p>
                    I agree to the{" "}
                    <a target="_blank" href="/terms/delivery">
                      terms of service
                    </a>{" "}
                    and{" "}
                    <a target="_blank" href="/privacy">
                      privacy policy
                    </a>
                  </p>
                </div>
              ) : null}
              <div className="padded row mobile-bottom">
                {login ? (
                  <StyledLoginBtn className="mobile" to="/register">
                    Don't have an account yet?{" "}
                    <strong>&nbsp;Get Started</strong>
                  </StyledLoginBtn>
                ) : step === 1 ? (
                  <StyledLoginBtn className="mobile" to="/login">
                    <span>
                      Already have an account? <strong>Login</strong>
                    </span>
                  </StyledLoginBtn>
                ) : null}
                {register && (step === 2 || step === 3) && (
                  <CustomButton
                    classes="back-btn web"
                    label={<ArrowLeftIcon />}
                    onClick={handleGoBack}
                  />
                )}

                <CustomButton
                  classes={createBtnClasses()}
                  label={buttonFunction()}
                  id={login ? "cypress_login_button" : ""}
                  onClick={handleClickCardBtn()}
                  loading={loading}
                  disabled={handleDisableSubmit()}
                />

                {!register && (
                  <ForgetWrap mobileHide>
                    <Link to="/forget-password">
                      {locale.password_forgot_text}{" "}
                    </Link>
                  </ForgetWrap>
                )}
              </div>
            </div>
          </StyledAuthCard>
          <LoadingCard loading={loading} />
        </>
      )}
    </StyledContainer>
  );
};

export default AuthenticationCard;
