import styled from "styled-components";
import { Colors } from "assets/colors";

export const StyledWrapper = styled.div`
  position: relative;
  padding-top: 82px;

  .search-input {
    width: 100%;
    height: 52px;
    border: 1px solid #d8dce2;
    border-top: none;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    border-right: none;
    border-left: none;

    .input {
      border: none;
      width: 100%;
      height: 100%;
      padding-left: 82px;
      padding-top: 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      transition: all 1s ease;
      color: ${Colors.textPrimary1};

      &::placeholder {
        color: #7b7b7b;
      }

      &:focus,
      &:focus-visible {
        outline: none;
        background: #f7f9fa;
        transition: all 1s ease;

        + .label {
          color: ${Colors.primary1};
        }
      }
    }

    .label {
      position: absolute;
      left: 56px;
    }
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .title-row {
    padding-left: 56px;
    padding-right: 56px;
    margin-bottom: 40px;
    display: block;

    .title {
      font-weight: 800;
      font-size: 28px;
      line-height: 34px;
      color: ${Colors.textPrimary1} !important;
      margin-right: 27px;
      margin-bottom: 0;
    }
  }

  .table-box {
    margin: 0 56px;
    margin-bottom: 100px;
  }

  .add-btn {
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 18px;
    color: ${Colors.textPrimary1};
    padding: 5px 8px 5px 6px !important;
    margin: 0 !important;
    height: 28px !important;
  }

  .icon--lead {
    width: 16px !important;
    height: 16px !important;
  }

  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-right: -12px;
  }

  .empty {
    margin: 126.5px auto;
    width: 291px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;

    &-title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: ${Colors.textPrimary1};
      margin: 20.7px auto 2px;
    }

    &-desc {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #666b6d;
      margin-bottom: 20px;
      width: ;
    }

    &-btn {
      padding: 5px 8px 5px 6px !important;
      margin: 0 !important;
    }

    .tag {
      color: #b8bbbc;
    }
  }
`;
