import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as ArrowDownIcon } from "assets/image/arrowDown.svg";
import RangeCalendar from "components/RangeCalendar";
import PaginateTable from "components/PaginateTable";
import { ReactComponent as CrossIcon } from "assets/image/cross.svg";
import { ReactComponent as TickIcon } from "assets/image/tick.svg";
import { ModalContext } from "../../../context/modalContext";
import CustomPagination from "components/CustomPagination";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getPayoutAnalytics } from "../../../api/analytics";
import { calcSomeDaysAgoDate, formatDate, getData } from "../../../utils";
import { StyledTableModal } from "./styles";
import moment from "moment";
import usePagination from "../../../hooks/usePagination";
import PaginatedOrders from "../PaginatedOrders";

const Payouts = () => {
  const { handleModal } = useContext(ModalContext);
  const [payoutsStartDateDatePicker, setPayoutsStartDateDatePicker] =
    useState(null);
  const [payoutsEndDateDatePicker, setPayoutsEndDateDatePicker] =
    useState(null);
  const [payoutsEndDate, setPayoutsEndDate] = useState(null);
  const [payoutsStartDate, setPayoutsStartDate] = useState(null);
  const [payoutsShowDatePicker, setPayoutsShowDatePicker] = useState(false);
  const [payoutsData, setPayoutsData] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  ]);
  const [payoutsLoading, setPayoutsLoading] = useState(true);
  const [payoutsFilter, setPayoutsFilter] = useState("30days");
  const [results, setResults] = useState(0);
  const dispatch = useDispatch();
  const selectedRestaurantId = useSelector(
    (state) => state.user?.selectedRestaurant
  );
  // const [pagination, setPagination] = useState({
  //   count: 0,
  //   next: null,
  //   previous: null,
  // });
  // const [page, setPage] = useState(1);

  const PAYOUTS_TABLE = [
    {
      element: "7 Days",
      filterClassName: "overview-filter",
      activeFilterClassName: "overview-filter--active",
      name: "7days",
    },
    {
      element: "14 Days",
      filterClassName: "overview-filter",
      activeFilterClassName: "overview-filter--active",
      name: "14days",
    },
    {
      element: "30 Days",
      filterClassName: "overview-filter",
      activeFilterClassName: "overview-filter--active",
      name: "30days",
    },
  ];
  const [payoutsDropdown, setPayoutsDropdown] = useState("daily");
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    /** Clear date picker selected dates */
    if (payoutsFilter !== "date" && payoutsEndDateDatePicker) {
      setPayoutsStartDateDatePicker(null);
      setPayoutsEndDateDatePicker(null);
    }
  }, [payoutsFilter]);

  useEffect(() => {
    /** Set StartDate and EndDate for each filter */
    switch (payoutsFilter) {
      case "7days":
        setPayoutsStartDate(calcSomeDaysAgoDate(7));
        setPayoutsEndDate(formatDate(new Date()));

        break;
      case "14days":
        setPayoutsStartDate(calcSomeDaysAgoDate(14));
        setPayoutsEndDate(formatDate(new Date()));

        break;
      case "30days":
        setPayoutsStartDate(calcSomeDaysAgoDate(30));
        setPayoutsEndDate(formatDate(new Date()));

        break;
      case "date":
        setPayoutsStartDate(formatDate(payoutsStartDateDatePicker));
        setPayoutsEndDate(formatDate(payoutsEndDateDatePicker));
        break;

      default:
        break;
    }
  }, [payoutsFilter, payoutsStartDateDatePicker, payoutsEndDateDatePicker]);

  useEffect(() => {
    getPayouts(true);
  }, [selectedRestaurantId, payoutsStartDate, payoutsEndDate]);

  const getPayouts = (startFromZero) => {
    if (payoutsStartDate) {
      setPayoutsLoading(true);
      setPayoutsData(
        startFromZero
          ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
          : [...payoutsData, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      );
      let lastItemId = startFromZero
        ? null
        : payoutsData[payoutsData?.length - 1]?.id || "";
      if (startFromZero) {
        setHasMore(false);
      }
      dispatch(
        getPayoutAnalytics(
          selectedRestaurantId,
          payoutsStartDate,
          payoutsEndDate,
          lastItemId
        )
      )
        .then((res) => {
          setPayoutsData(
            startFromZero
              ? getData(res)?.data
              : [
                  ...(payoutsData[0]?.id ? payoutsData : []),
                  ...getData(res)?.data,
                ]
          );
          setResults(
            startFromZero
              ? getData(res)?.data?.length
              : results + getData(res)?.data?.length
          );
          setHasMore(getData(res)?.has_more);
          setPayoutsLoading(false);
        })
        .catch((err) => {
          setPayoutsLoading(false);
        });
    }
  };

  // const { page, setPage, pagination, setPagination, paginationEl } =
  //   usePagination(getOrders);
  // const [pagination, setPagination] = useState({
  //   count: 0,
  //   next: null,
  //   previous: null,
  // });
  // const [page, setPage] = useState(1);

  const getDayAndMonth = (date) => {
    const MONTHS = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return MONTHS[new Date(date).getMonth()] + " " + new Date(date).getDate();
  };

  const getPayoutsFilter = () => {
    switch (payoutsFilter) {
      case "7days":
        return "7 days";
      case "14days":
        return "14 days";
      case "30days":
        return "30 days";
      case "date":
        return (
          <>
            {payoutsStartDate ? getDayAndMonth(payoutsStartDate) : ""}{" "}
            {payoutsEndDate && " →"}
            {payoutsEndDate ? ` ${getDayAndMonth(payoutsEndDate)}` : ""}
          </>
        );

      default:
        break;
    }
  };

  const formatDateToTableDate = (date) => {
    const MONTHS = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return date
      ? `${MONTHS[new Date(moment.utc(date * 1000)).getMonth()]} ${moment
          .utc(date * 1000)
          .format("DD")}`
      : null;
  };

  return (
    <div className="payouts-box">
      <div className="overview-top table-spacing">
        <div className="overview-title">Payout table</div>
        <div className="overview-filters">
          {/* <button className={`export`}>
            <ExportIcon /> Export
          </button> */}
          {/* <span className="separator"></span> */}
          {/* <DropDown
            btnEl={
              <>
                {ordersDropdown} <TwowayArrowIcon className="m-left-12" />
              </>
            }
            btnClasses={`overview-filter dropdown-btn`}
            listClasses="dropdown-list"
            itemClasses="dropdown-item"
            listItems={["Daily", "Weekly", "Monthly"]}
            dropdownClasses="dropdown"
            setSelected={setPayoutsDropdown}
            selected={ordersDropdown}
          /> */}

          {/* <span className="separator separator--daily"></span> */}
          {PAYOUTS_TABLE.map((filter, index) => {
            return (
              <button
                key={index}
                className={`${filter.filterClassName} ${
                  payoutsFilter === filter.name
                    ? filter.activeFilterClassName
                    : ""
                }`}
                onClick={() => setPayoutsFilter(filter.name)}
              >
                {filter.element}
              </button>
            );
          })}
          <RangeCalendar
            actionOnSelect={() => setPayoutsFilter("date")}
            startDate={payoutsStartDateDatePicker}
            setStartDate={setPayoutsStartDateDatePicker}
            endDate={payoutsEndDateDatePicker}
            setEndDate={setPayoutsEndDateDatePicker}
            showDatePicker={payoutsShowDatePicker}
            setShowDatePicker={setPayoutsShowDatePicker}
            isActive={payoutsFilter === "date"}
            customCanelFunction={() =>
              payoutsFilter === "date" && setPayoutsFilter("7days")
            }
          />
        </div>
      </div>
      {payoutsDropdown === "daily" ? (
        // ============================== Daily table ==============================
        <PaginateTable
          loading={payoutsLoading}
          loadMoreText={hasMore ? "Load more records" : ""}
          headers={[
            { title: "Amount", width: "149.02px" },
            { title: "status", width: "161.36px" },
            { title: "Date", width: "156.95px" },
            { title: "EXTERNAL ACCOUNT", width: "auto" },
          ]}
          dataArray={payoutsData}
          dataPaging={{ count: results }}
          rowCreators={[
            (row, index) => `$${(row?.amount / 100).toFixed(2)}`,
            (row, index) => {
              return (
                <span
                  className={`paid ${!row?.status === "paid" ? "refund" : ""}`}
                >
                  {row?.status === "paid" ? <TickIcon /> : <CrossIcon />}{" "}
                  {row?.status === "paid" ? "Paid" : "Refunded"}
                </span>
              );
            },
            (row, index) => formatDateToTableDate(row?.arrival_date),
            (row, index) =>
              `${row?.destination?.bank_name} •••• ${row?.destination?.last4}`,
          ]}
          onLoadMore={() => {
            getPayouts();
          }}
        />
      ) : payoutsDropdown === "weekly" ? (
        <>
          {[1, 2].map((week, index) => (
            // ============================== Weekly table ==============================
            <React.Fragment key={index}>
              <PaginateTable
                loading={payoutsLoading}
                viewMoreText={`View all for week ${week}`}
                headers={[
                  "Payment date",
                  "status",
                  "Order number",
                  "Merchant net",
                  "Tax",
                  "Payment Fees",
                  "Application Fees",
                ]}
                dataArray={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                rangeEl={
                  <div className="range">
                    <div className="range--left">
                      <p className="range-title">Week {week}</p>
                      <p className="range-range">{getPayoutsFilter()}</p>
                    </div>
                    <ArrowDownIcon />
                  </div>
                }
                rowCreators={[
                  (row, index) => "22 Nov, 15:08",
                  (row, index) => {
                    return index % 2 === 0 ? (
                      <span className="paid">
                        <TickIcon /> Paid
                      </span>
                    ) : (
                      <span className="paid refund">
                        <CrossIcon /> Refunded
                      </span>
                    );
                  },
                  (row, index) => "2HvlRPPcv1ElF",
                  (row, index) => <span className="bold">$50.12</span>,
                  (row, index) => <span className="bold">$0.28</span>,
                  (row, index) => <span className="bold">$1.43</span>,
                  (row, index) => <span className="bold">$1.11</span>,
                ]}
                onViewAllClick={() => {
                  handleModal(
                    <StyledTableModal>
                      <div className="title">Payout . {getPayoutsFilter()}</div>
                      <PaginateTable
                        loading={payoutsLoading}
                        headers={[
                          "Payment date",
                          "status",
                          "Order number",
                          "Merchant net",
                          "Tax",
                          "Payment Fees",
                          "Application Fees",
                        ]}
                        dataArray={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                        rowCreators={[
                          (row, index) => "22 Nov, 15:08",
                          (row, index) => {
                            return index % 2 === 0 ? (
                              <span className="paid">
                                <TickIcon /> Paid
                              </span>
                            ) : (
                              <span className="paid refund">
                                <CrossIcon /> Refunded
                              </span>
                            );
                          },
                          (row, index) => "2HvlRPPcv1ElF",
                          (row, index) => <span className="bold">$50.12</span>,
                          (row, index) => <span className="bold">$0.28</span>,
                          (row, index) => <span className="bold">$1.43</span>,
                          (row, index) => <span className="bold">$1.11</span>,
                        ]}
                        detailed={true}
                        // pagination={
                        // <CustomPagination
                        //   paginationObj={pagination}
                        //   page={page}
                        //   setPage={setPage}
                        // />
                        // }
                      />
                      {/* <CustomPagination
                        paginationObj={pagination}
                        page={page}
                        setPage={setPage}
                      /> */}
                    </StyledTableModal>
                  );
                }}
              />
            </React.Fragment>
          ))}{" "}
          <div className="table-pagination">
            {/* <CustomPagination
              paginationObj={pagination}
              page={page}
              setPage={setPage}
            /> */}
          </div>
        </>
      ) : (
        <>
          {" "}
          {[1, 2].map((month, index) => (
            // ============================== Monthly table ==============================
            <React.Fragment key={index}>
              <PaginateTable
                viewMoreText={`View all for month ${month}`}
                headers={[
                  "Payment date",
                  "status",
                  "Order number",
                  "Merchant net",
                  "Tax",
                  "Payment Fees",
                  "Application Fees",
                ]}
                dataArray={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                rangeEl={
                  <div className="range">
                    <div className="range--left">
                      <p className="range-title">Month {month}</p>
                      <p className="range-range">{getPayoutsFilter()}</p>
                    </div>
                    <ArrowDownIcon />
                  </div>
                }
                rowCreators={[
                  (row, index) => "22 Nov, 15:08",
                  (row, index) => {
                    return index % 2 === 0 ? (
                      <span className="paid">
                        <TickIcon /> Paid
                      </span>
                    ) : (
                      <span className="paid refund">
                        <CrossIcon /> Refunded
                      </span>
                    );
                  },
                  (row, index) => "2HvlRPPcv1ElF",
                  (row, index) => <span className="bold">$50.12</span>,
                  (row, index) => <span className="bold">$0.28</span>,
                  (row, index) => <span className="bold">$1.43</span>,
                  (row, index) => <span className="bold">$1.11</span>,
                ]}
                onViewAllClick={() => {
                  handleModal(
                    <StyledTableModal>
                      <div className="title">
                        Payouts . {getPayoutsFilter()}
                      </div>
                      <PaginateTable
                        headers={[
                          "Payment date",
                          "status",
                          "Order number",
                          "Merchant net",
                          "Tax",
                          "Payment Fees",
                          "Application Fees",
                        ]}
                        dataArray={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                        rowCreators={[
                          (row, index) => "22 Nov, 15:08",
                          (row, index) => {
                            return index % 2 === 0 ? (
                              <span className="paid">
                                <TickIcon /> Paid
                              </span>
                            ) : (
                              <span className="paid refund">
                                <CrossIcon /> Refunded
                              </span>
                            );
                          },
                          (row, index) => "2HvlRPPcv1ElF",
                          (row, index) => <span className="bold">$50.12</span>,
                          (row, index) => <span className="bold">$0.28</span>,
                          (row, index) => <span className="bold">$1.43</span>,
                          (row, index) => <span className="bold">$1.11</span>,
                        ]}
                        detailed={true}
                        pagination={
                          <CustomPagination
                            paginationObj={pagination}
                            page={page}
                            setPage={setPage}
                          />
                        }
                      />
                    </StyledTableModal>
                  );
                }}
              />
            </React.Fragment>
          ))}
          <div className="table-pagination">
            <CustomPagination
              paginationObj={pagination}
              page={page}
              setPage={setPage}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Payouts;
