import { StyledBack, StyledLoadingBox } from "./styles";

import React from "react";
import { Spinner } from "react-bootstrap";

/*
 * Loading card is place in the button of page
 */

const LoadingCard = ({ loading, withSpinner, ...props }) => {
  return (
    <>
      {loading ? (
        <StyledBack>
          <StyledLoadingBox withSpinner>
            <div className="spinner">
              <Spinner animation="border" color="#fff" className="mt-5" />
            </div>
            <h1>Welcome to Omnicart</h1>
            <p>
              🕵️ Pro Tips: Easily modify your merchant’s digital menus in
              real-time 🎉
            </p>
          </StyledLoadingBox>
        </StyledBack>
      ) : null}
    </>
  );
};

export default LoadingCard;
