import React from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  ${(p) => !p.nopadding && `padding: 1rem`};
  ${(p) => p.color && `color: ${p.color}`}
  .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const Loading = (props) => {
  return (
    <Wrapper
      className={props.classes}
      color={props.color}
      nopadding={props.nopadding}
    >
      <Spinner animation="border" />
    </Wrapper>
  );
};

export default Loading;
