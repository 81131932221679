import React from "react";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import CreateRestaurant from "components/home/CreateRestaurant";

const NoPermissions = ({ text, locale, loading }) => {
  const history = useHistory();

  if (loading) return <Spinner animation="border" />;

  return (
    <Row className="fulls">
      <Col xs={12} sm={12} className="text-center">
        {text ? (
          <h2>{text}</h2>
        ) : (
          <>
            <h2 className="h3">You don't have access to this section</h2>
            <h2 className="h3">Upgrade your subscription to get access</h2>
          </>
        )}
        <Button
          onClick={() =>
            history.push("/console/billing", { edit_plan_modal: true })
          }
        >
          Upgrade
        </Button>
      </Col>
    </Row>
  );
};

export default connect((state) => ({
  locale: state.locale[state.locale.active],
}))(NoPermissions);
