import { fetchApi } from "./";

export const getItemById = (id) => async (dispatch, getState) => {
  let res = await dispatch(
    fetchApi(`items/${id}/?query={id,name,description,
    images,category,modifiers,value,value_label,value2,
    value2_label,value3,value3_label,value4,value4_label,
    contains_alcohol,tax,extra_data}`)
  );

  return res;
};

export const getItemCategories = (id) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`items/${id}/?query={category}`));

  return res;
};

export const editItem = (id, data) => async (dispatch, getState) => {
  let sendDataForm = "image" in data;
  let res = await dispatch(
    fetchApi(`items/${id}/`, "PATCH", data, sendDataForm)
  );

  return res;
};

export const changeItemIsActive = (id) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`items/${id}/status/`, "POST"));

  return res;
};

export const deleteItem = (id) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`items/${id}/`, "DELETE"));

  return res;
};

export const addItem = (data, file) => async (dispatch, getState) => {
  let res = await dispatch(
    fetchApi(`items/`, "POST", data, file ? true : false)
  );

  return res;
};

export const getItemsByRestaurantId =
  (id, query) => async (dispatch, getState) => {
    let url = `restaurants/${id}/items/?query={id,is_active,name,description,images{image_med},value, category}`;
    if (query) url += `&search=${query}`;
    let res = await dispatch(fetchApi(url, "GET"));

    return res;
  };

export const getItems = (id, query, page) => async (dispatch, getState) => {
  let url = `items/?restaurant=${id}&query={id,is_active,name,description,images{image_med},value, category}`;
  if (query) url += `&search=${query}`;
  if (page) url += `&page=${page}`;
  let res = await dispatch(fetchApi(url, "GET"));

  return res;
};

export const getRestaurantItemByName =
  (id, name = "") =>
  async (dispatch, getState) => {
    let res = await dispatch(
      fetchApi(`restaurants/${id}/items?search=${name}`, "GET")
    );

    return res;
  };

export const getRestaurantOrderedItems =
  (id, filter) => async (dispatch, getState) => {
    let res = await dispatch(
      fetchApi(
        `restaurants/${id}/items?query={id,name,description,image,value}&ordering=${filter}`,
        "GET"
      )
    );

    return res;
  };

export const getFilteredRestaurantItems =
  (id, category, menu) => async (dispatch, getState) => {
    let url = `restaurants/${id}/items?query={id,name,description,image,value}`;
    if (category) url += `&category=${category}`;
    let res = await dispatch(fetchApi(url, "GET"));

    return res;
  };

export const moveItem =
  (id, position, categoryId) => async (dispatch, getState) => {
    let url = `items/${id}/move`;
    let payload = { item: id, position, category: categoryId };
    let res = await dispatch(fetchApi(url, "POST", payload, false));

    return res;
  };

export const getIngredients =
  (search = "", page) =>
  async (dispatch, getState) => {
    let url = `ingredient/?query={id,name,name_org}&search=${search}`;
    if (page) url += `&page=${page}`;
    let res = await dispatch(fetchApi(url, "GET"));

    return res;
  };

export const getDiets =
  (search = "") =>
  async (dispatch, getState) => {
    let res = await dispatch(
      fetchApi(`diet-fact/?query={id,title}&search=${search}`, "GET")
    );

    return res;
  };

export const addIngredient = (name) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`ingredient/`, "POST", { name }, false));

  return res;
};

export const editIngredient = (id, data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`ingredient/${id}/`, "PUT", data, false));

  return res;
};

export const fetchItemImages =
  (page = 1) =>
  async (dispatch, getState) => {
    let res = await dispatch(fetchApi(`items/`, "GET"));

    return res;
  };

export const getRestaurantModifiers = (id) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`restaurants/${id}/modifiers/`, "GET"));

  return res;
};
export const getModifiers = (id, query, page) => async (dispatch, getState) => {
  let url = `modifiers/?restaurant=${id}`;
  if (query) url += `&search=${query}`;
  if (page) url += `&page=${page}`;
  let res = await dispatch(fetchApi(url));

  return res;
};

export const duplicateItem = (id) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`items/${id}/duplicate/`, "POST"));
  return res;
};

export const createModifier = (data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`modifiers/`, "POST", data));

  return res;
};

export const getModifier = (id) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`modifiers/${id}/`));

  return res;
};

export const duplicateModifier = (id) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`modifiers/${id}/duplicate/`, "POST"));

  return res;
};

export const updateModifier = (id, data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`modifiers/${id}/`, "PUT", data));

  return res;
};

export const deleteModifier = (id) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`modifiers/${id}/`, "DELETE"));

  return res;
};

export const moveModifier = (id, position) => async (dispatch, getState) => {
  let url = `modifiers/${id}/move/`;
  let res = await dispatch(fetchApi(url, "POST", { position }, false));

  return res;
};
