import { Colors } from "assets/colors";
import React from "react";
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  background-color: ${Colors.white};
  .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const OverlayLoading = (props) => {
  return (
    <>
      {props.loading > 0 ? (
        <Wrapper>
          <Spinner animation="border" />
        </Wrapper>
      ) : null}
    </>
  );
};

export default connect(
  (state) => ({ loading: state.loading }),
  null
)(OverlayLoading);
