import styled from "styled-components";

export const StyledTableModal = styled.div`
  width: 1128px;
  max-width: 100vw;
  padding: 24px;

  .title {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: #1a1f36;
    margin-bottom: 40px;
  }

  table {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
  }

  .results {
    padding-left: 0 !important;
  }
`;
