import React from "react";
import Button from "react-bootstrap/Button";
import styled from "styled-components";

import { Colors } from "assets/colors";
import Loading from "components/Loading";

const StyeledButton = styled(Button)`
  width: 50px;
  height: 50px;
  background: ${Colors.primary1};
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(p) => p.noShadow && `box-shadow: none;`}
`;

const CircleButton = (props) => (
  <StyeledButton
    {...props}
    disabled={props.loading}
    variant={props.variant}
    type="submit"
    onClick={props.onClick}
  >
    {!props.loading ? props.children : <Loading nopadding />}
  </StyeledButton>
);

export default CircleButton;

CircleButton.defaultProps = {
  variant: "primary",
  onClick: null,
};
