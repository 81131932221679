import {
  faCheck,
  faPause,
  faPlay,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import ReactJoyride, { ACTIONS, EVENTS } from "react-joyride";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { connect } from "react-redux";
import { Link, Redirect, withRouter } from "react-router-dom";
import Select from "react-select";
import Switch from "react-switch";
import { FixedSizeGrid as Grid } from "react-window";
import styled from "styled-components";

import { ChangeTutorial } from "actions/user";
import {
  changeCategoryItems,
  getCategoriesByRestaurantId,
  getCategory,
} from "api/categories";
import {
  addItem,
  changeItemIsActive,
  editItem,
  getFilteredRestaurantItems,
  getItemCategories,
  getItems,
  getItemsByRestaurantId,
  getRestaurantItemByName,
  getRestaurantOrderedItems,
} from "api/items";
import NoPermissions from "components/commons/NoPermissions";
import NoRestaurant from "components/commons/NoRestaurant";
import Loading from "components/Loading";

import { Colors } from "assets/colors";
import CustomButton from "components/button";
import FormButton from "components/button/FormButton";
import { SearchBar } from "components/forms/Input";
import { AddMenuCard } from "components/Menu/MenuCard";
import { Heading } from "components/typography";
import useDebounce from "../../hooks/useDebounce";
import {getData, priceTransformer} from "../../utils";
import { MenusTabs } from "../menus/Preview";
import ItemModal from "./ItemModal";

import "react-lazy-load-image-component/src/effects/blur.css";

const SearchInput = styled.input`
  width: 400px;
  margin: 10px 0;
`;

const ItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  margin-top: 25px;
  overflow-x: hidden;
  grid-gap: 10px 40px;
  padding: 20px 25px 20px 25px;
  ${(p) =>
    p.isCategory &&
    `
    max-height: 40vh;
    overflow-y: scroll;
  `}
`;
const Item = styled.div`
  background: ${Colors.white};
  display: flex;
  opacity: ${(p) => (p.deleting ? 0.5 : 1)}
  flex-direction: row;
  ${(p) => p.loading && `align-items: center;`}
  justify-content: space-between;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.12);
  display: flex;
  color: #373636;
  flex: 0 21%;

  &:hover {
    text-decoration: none !important;
  }
  .form-group {
    margin: 0;
  }
  .title {
    font-weight: bold;
    text-align: left;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }

  .lazy-load-image-background {
  }

  .price {
    // margin: 5px 0;
    // padding-bottom: 5px;
  }

  .body {
    cursor: pointer;
    display: flex;
    width: 100%;
    padding: 10px;
    flex-direction: column;
    justify-content: space-between;
}
  }

  .description {
    font-size: 15px;
    font-size: 15px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  img {
    height: 80px;
    object-fit: cover;
    width: 100px;
  }
  .button {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    background: ${(p) => (!p.paused ? "#4BB43A" : "#E45628")};
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 28px;
  }
`;

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
`;
const CustomButtonText = styled.span`
  font-size: 1.1rem;
  font-weight: 500;
`;
const HeaderTitle = styled.span`
  font-size: 1.65rem;
  font-weight: 700;
  display: flex;
  align-self: center;
  color: ${Colors.darkGray};
`;
const HeaderDetail = styled.span`
  font-size: 1.05rem;
  display: flex;
  align-self: center;
  color: ${Colors.darkGray};
`;
const FilterLable = styled.span`
  font-weight: bold;
  color: ${Colors.darkestGray};
  padding-bottom: 15px;
`;
const StyledHr = styled.hr`
  border-top: ${`1px solid ${Colors.grayBorder}`};
  width: 97%;
`;

const Options = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 12px;

  .option {
    flex: 1 0 21%;
    padding: 0 15px;

    .label {
      font-weight: bold;
    }

    .select__control {
      border: none;
      box-shadow: none;
      background: transparent;
    }
  }
`;

const Checkbox = styled.div`
  width: 20px;
  height: 20px;
  margin: 10px 25px;
  min-width: 20px;
  min-height: 20px;
  border: 2px solid #999;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  ${(p) =>
    p.active &&
    `
    background: #833AB4;
    border-color: #833AB4;
  `}
`;

const Items = ({ locale, ...props }) => {
  const [items, setItems] = useState([]);
  const [actionLoading, setActionLoading] = useState(false);
  const [category, setCategory] = useState({});
  const [selecteds, setSelecteds] = useState([]);
  const [sorting, setSorting] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNext, setHasNext] = useState(false);
  const [moreLoading, setMoreLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [menus, setMenus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState({ show: false, type: null, id: null });
  const [run, setRun] = useState(false);
  const [joyrideStepIndex, setJoyrideStepIndex] = useState(0);
  const [deleting, setDeleting] = useState([]);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);

  const isCategory = props.match.path.includes("categories");
  const categoryID = props.match.params.id;

  const sortings = [
    {
      label: locale.price_title,
      value: "value",
    },
    {
      label: locale.name_title,
      value: "name",
    },
  ];
  const steps = [
    {
      content: (
        <div>
          <h3 class="text-center font-weight-bold">
            {locale.items_tour_title}
          </h3>
          <br />
          <p class="text-center">{locale.items_tour_description}</p>
        </div>
      ),
      target: "#fullscreen",
      disableBeacon: true,
      placement: "center",
    },
    {
      target: ".step2",
      content: (
        <div>
          <h3 class=" font-weight-bold">{locale.items_tour_title_2}</h3>
          <br />
          <p>{locale.items_tour_description_3}</p>
        </div>
      ),
      spotlightClicks: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: true,
    },
    {
      target: ".edit-4",
      content: (
        <div>
          <h3 class=" font-weight-bold">{locale.items_tour_title_3}</h3>
          <br />
          <p class="">{locale.items_tour_description_3}</p>
        </div>
      ),
      spotlightClicks: true,
    },
  ];

  const editItems = (data, action = "add", id) => {
    let newList = Array.from(filteredItems);
    switch (action) {
      case "add":
        newList.splice(0, 0, data);
        setFilteredItems(newList);
        break;
      case "remove":
        newList = newList.filter((item) => item.id !== data);
        setFilteredItems(newList);
        break;
      case "update":
        newList = newList.map((item) =>
          item.id === data.id ? { ...item, ...data } : item
        );
        setFilteredItems(newList);
        break;
      case "add_loading":
        if (!id) {
          newList.splice(0, 0, { add_loading: true });
        } else {
          newList.splice(newList.map((i) => i.id).indexOf(id), 1, {
            add_loading: true,
          });
        }
        setFilteredItems(newList);
        break;
      default:
        break;
    }
  };

  const handleSorting = (e) => {
    let type = sorting === e.label ? -1 : 1;
    let sorted = Array.from(filteredItems);

    if (e.label === "Price") {
      sorted.sort((a, b) => {
        let comparison = 0;
        if (parseFloat(a[e.value]) < parseFloat(b[e.value])) {
          comparison = -1;
        }
        if (parseFloat(a[e.value]) > parseFloat(b[e.value])) {
          comparison = 1;
        }
        return comparison * type;
      });
    } else {
      sorted.sort((a, b) => {
        let comparison = 0;
        if (a[e.value] < b[e.value]) {
          comparison = -1;
        }
        if (a[e.value] > b[e.value]) {
          comparison = 1;
        }
        return comparison * type;
      });
    }

    setSorting(e.label);
    setFilteredItems(sorted);
  };

  const handleCatehories = (e) => {
    if (!e) {
      setFilteredItems(items);
      setCategoriesSelected(null);
      return;
    }

    let SelectedCategoriesArray = e.map((c) => c.value);
    let newItems = items.filter((item) => {
      return SelectedCategoriesArray.every(
        (element) => item.category.indexOf(element) > -1
      );
    });

    setFilteredItems(newItems);
    setCategoriesSelected(e);
  };

  useEffect(() => {
    setLoading(true);
    props
      .getItems(props.selectedRestaurant, debouncedSearch)
      .then((res) => {
        setFilteredItems(getData(res)?.results);
        let matches = getData(res)?.next?.match(/page=([^&]*)/);
        if (matches?.length) setPage(matches[1]);
        setHasNext(getData(res)?.next);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error in getting items by restaurant id", e);
      });
  }, [debouncedSearch]);

  const handleSearch = (e) => {
    const { value } = e.target;
    setLoading(true);
    setSearch(value);
  };

  const loadMore = () => {
    setMoreLoading(true);
    props
      .getItems(props.selectedRestaurant, debouncedSearch, page)
      .then((res) => {
        setFilteredItems((p) => [...p, ...getData(res)?.results]);
        let matches = getData(res)?.next?.match(/page=([^&]*)/);
        if (matches?.length) setPage(matches[1]);
        setHasNext(getData(res)?.next);
        setMoreLoading(false);
      })
      .catch((e) => {
        console.log("error in getting items by restaurant id", e);
      });
  };

  const onHide = () => {
    if (modal.type === "edit" && modal.intro) {
      setRun(true);
    }
    setDeleting((deleting) => deleting.filter((d) => d !== modal.id));
    setModal({ show: false, type: null, id: null, intro: null });
  };

  const openModal = (item) => {
    item
      ? setModal({ show: true, type: "edit", id: item.id, intro: run })
      : setModal({ show: true, type: "add", id: null, intro: run });

    if (!item && run) {
      endTour();
    }
    setRun(false);
    setJoyrideStepIndex(Math.min(steps.length - 1, joyrideStepIndex + 1));
  };

  const endTour = () => {
    setRun(false);
    setJoyrideStepIndex(0);
    if (props.tutorial === "items") {
      props.ChangeTutorial("Menu", 3);
    }
  };

  const handleCallback = (data) => {
    const { action, index, status, type } = data;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setJoyrideStepIndex(stepIndex);
    }
    if ([EVENTS.TOUR_END, EVENTS.TOOLTIP_CLOSE].includes(type)) {
      endTour();
    }
  };

  useEffect(() => {
    if (
      props.selectedRestaurant &&
      (props.permissions.includes("__panel__") ||
        props.permissions.includes("menus"))
    ) {
      if (isCategory)
        props.getCategory(categoryID).then((res) => {
          setCategory(getData(res));
          setSelecteds(getData(res)?.items.map((i) => i.id));
        });
    }
  }, []);

  const handleIsActive = (id, checked) => {
    const newItems = filteredItems.map((item) => {
      if (item.id !== id) return item;

      item.is_active = !item.is_active;
      return item;
    });
    setFilteredItems(newItems);
    props.changeItemIsActive(id).then((res) => {
      // console.log(res);
    });
  };

  const handleAddRemoveItemToFromCategory = (item) => {
    if (selecteds.includes(item.id)) {
      setSelecteds([...selecteds.filter((i) => i !== item.id)]);
    } else {
      setSelecteds([...selecteds, item.id]);
    }
  };

  const handleSubmit = () => {
    if (!category) return;

    setActionLoading(true);
    props.changeCategoryItems(category.id, selecteds).then((res) => {
      props.history.push(
        `/console/create-menus/view/${category.menu}?category=${category.id}`
      );
      setActionLoading(false);
    });
  };

  if (
    !props.permissions.includes("__panel__") &&
    !props.permissions.includes("menus")
  )
    return <NoPermissions />;

  if (!props.selectedRestaurant) {
    return (
      <Row className="fulls">
        <Col xs={12} sm={12} className="text-center">
          <h2 className="h3 text-white">{locale.no_restaurant}</h2>
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col
        md={12}
        style={{
          marginTop: 100,
        }}
      >
        <Heading className="pb-4">
          {isCategory ? "Add Items to " + (category?.name || "...") : ""}
        </Heading>
        <SearchBar
          onChange={handleSearch}
          placeholder="Start typing..."
          style={
            {
              // display: 'none'
            }
          }
        />
      </Col>
      <Col md={12} className="mb-4" id="fullscreen">
        <ReactJoyride
          showSkipButton
          continuous
          scrollToFirstStep
          run={run}
          steps={steps}
          stepIndex={joyrideStepIndex}
          callback={(data) => handleCallback(data)}
        />
        <ItemsWrapper isCategory={isCategory}>
          <Link
            as={AddMenuCard}
            to={{
              pathname: "/console/create-items/add",
              state: "add",
            }}
          >
            <AddMenuCard
              title={"New Item"}
              style={{
                background: "#fff",
              }}
            />
          </Link>
          {loading ? (
            <Loading />
          ) : (
            <>
              {filteredItems.map((item, index) =>
                !item.add_loading ? (
                  <Item
                    paused={!item.is_active}
                    className={`step2`}
                    deleting={deleting.includes(item.id)}
                    key={item.id}
                  >
                    {isCategory && (
                      <div
                        className="d-flex align-items-center justify-content-center"
                        onClick={() => handleAddRemoveItemToFromCategory(item)}
                      >
                        <Checkbox active={selecteds.includes(item.id)}>
                          {selecteds.includes(item.id) && (
                            <FontAwesomeIcon icon={faCheck} color="#fff" />
                          )}
                        </Checkbox>
                      </div>
                    )}
                    <Link
                      as={AddMenuCard}
                      to={
                        isCategory
                          ? null
                          : {
                              pathname: `/console/create-items/edit/${item.id}`,
                              state: "edit",
                            }
                      }
                      style={{
                        textDecoration: "none",
                        display: "flex",
                        flex: 1,
                        color: "#373636",
                      }}
                    >
                      <LazyLoadImage
                        src={
                          (item.images[0] && item.images[0].image_med) ||
                          "https://1d4au92ejd571ijfyy1m3feh-wpengine.netdna-ssl.com/wp-content/themes/velocity/images/no-image-found-360x250.png"
                        }
                        effect="blur"
                      />
                      <div
                        className="body"
                        // onClick={() => !deleting.includes(item.id) ? openModal(item) : null}
                      >
                        <span className="title">{item.name}</span>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                          }}
                        >
                          <span className="price">{priceTransformer(item.value)}</span>
                          {!isCategory && (
                            <Switch
                              // className="mt-2 mr-2"
                              onChange={(value) =>
                                handleIsActive(item.id, value)
                              }
                              checked={item.is_active}
                              handleDiameter={18}
                              height={24}
                              offColor="#E45628"
                              uncheckedIcon={
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    paddingRight: 2,
                                    fontSize: 14,
                                    color: "#fff",
                                    fontWeight: "bold",
                                  }}
                                >
                                  OFF
                                </div>
                              }
                              checkedIcon={
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "#fff",
                                    fontSize: 14,
                                    height: "100%",
                                    paddingLeft: 2,
                                    fontWeight: "bold",
                                  }}
                                >
                                  ON
                                </div>
                              }
                            />
                          )}
                        </div>
                      </div>
                    </Link>
                  </Item>
                ) : !item.id ? (
                  <Item loading={true}>
                    <Loading />
                  </Item>
                ) : null
              )}
            </>
          )}
        </ItemsWrapper>
        {hasNext && !loading && (
          <div className="d-flex justify-content-center py-3">
            <Button onClick={loadMore} disabled={moreLoading}>
              {moreLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Load More"
              )}
            </Button>
          </div>
        )}
        {isCategory && (
          <Row>
            <Col md={3} style={{ margin: "3rem 0" }}>
              <FormButton onClick={handleSubmit}>
                {actionLoading || !category ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Save"
                )}
              </FormButton>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};
export default withRouter(
  connect(
    (state) => ({
      selectedRestaurant: state.user.selectedRestaurant,
      tutorial: state.user.tutorial,
      permissions: state.user.permissions,
      locale: state.locale[state.locale.active],
    }),
    {
      getItemsByRestaurantId,
      getItems,
      getRestaurantItemByName,
      getRestaurantOrderedItems,
      getCategoriesByRestaurantId,
      addItem,
      getItemCategories,
      editItem,
      getCategory,
      getFilteredRestaurantItems,
      ChangeTutorial,
      changeItemIsActive,
      changeCategoryItems,
    }
  )(Items)
);
