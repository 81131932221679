import { Colors } from "assets/colors";
import styled from "styled-components";

export const StyledInput = styled.div`
  position: relative;
  width: 100%;
  height: 55px;
  margin: 7.5px auto;
  display: flex;
  align-items: center;

  .label {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #ececec;


    svg {
      width: 18px;
      height: 12px;
      color: ${(p) => {
        if (p.error) {
          return `${Colors.alert}`;
        } else {
          return "#dadada";
        }
      }};
    }
  }

  .input {
    padding-left: 61px !important;
    padding-right: 56px !important;
    padd-top: 20px !important;
    width: 100% !important;
    height: 100% !important;
    color: #151515 !important;
    font-weight: bold !important;
    font-size: 14px !important;
    border: ${(p) => {
      if (p.error) {
        return `1px solid ${Colors.alert} !important`;
      } else {
        return "1px solid #ececec !important";
      }
    }}

    &:focus {
      border: 1px solid ${Colors.primary1} !important;
     outline: none !important;
    }

    @media (max-width: 1120px) {
      height: 51px !important;
    }
  }

  input:focus + label {
    outline: none !important;
    svg {
      color: #151515 !important;
    }
  }

  input::placeholder {
    font-size: 14px
    color: rgba(21, 21, 21, 0.5);
    font-weight: normal;
  }

  .stick-right {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .no-border {
        border: none !important;
  }

  .phone-input {
        border-radius: 0 !important;
        padding-right: 0 !important;
        padding-left: 43px !important;

@media (max-width: 1120px) {
    height: 100% !important;
}
  }

  .phone-country {
    width: 40px !important;
    height: 100% !important;
    border: none !important;
    background: transparent !important;
    transform: scale(0.8);
  }

  .dropdown-box {
    background: white !important;
    position: absolute;
    bottom: 100%;
    max-height: 325px;
    width: 100%;
    background-color: #ffffff !important;
    border: 1px solid #ececec;
    box-shadow: 0 -10px 15px 0 rgb(0 0 0 / 5%) !important;
    border-bottom: none;
    overflow: auto;
    padding-right: 0 !important;
    border-right: none !important;
    outline: none !important;
    width: 287px !important;
    margin-left: -62px !important;

    li {
    background: white !important;
    height: 40px !important;
    width: 100% !important;
    // border-bottom: 1px solid gray;
    height: 51px !important;
    align-items: center !important;
    display: flex !important;
    padding: 20px !important;
    margin: 0 15px !important;
    border-right: none !important;
    outline: none !important;
    }
  }

  .country-list {
    height: 51px;
    align-items: center;
    display: flex;
    padding: 20px;
    margin: 0 15px;
  }
`;
