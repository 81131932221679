import React, { useContext, useEffect, useState } from "react";
import { StyledWrapper } from "./styles";
import RadioButtonGroup from "components/RadioButtonGroup";
import Input from "components/common/Input";
import Button from "components/common/Button";
import { toast } from "react-toastify";

const TimeDialogue = ({ handleCancel, handleConfirm, loading, ...props }) => {
  const [customTime, setCustomTime] = useState("");
  const TIME_OPTIONS = [
    { id: 0, title: "+ 5 minutes", time: 5 },
    { id: 1, title: "+ 10 minutes", time: 10 },
    { id: 2, title: "+ 15 minutes", time: 15 },
    {
      id: 3,
      title: (
        <div className="input-wrap">
          <Input
            inputClasses="time-input"
            type="number"
            id="custom-time-input"
            value={customTime}
            onKeyDown={(e) => {
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
            }}
            onChange={(val) => {
              if (val === "" || (val > 0 && val <= 60)) {
                setCustomTime(val);
              } else if (val > 60) {
                toast.error("Custom time cannot be more than 60 minutes");
              }
            }}
            placeholder="Custom time"
          />
          <span className="unit">min</span>
        </div>
      ),
    },
  ];
  const [selectedOption, setSelectedOption] = useState(TIME_OPTIONS[0]);

  return (
    <StyledWrapper>
      <div className="time-dialogue-backdrop" onClick={handleCancel}></div>
      <div className="time-dialogue">
        <div className="top">
          <h2 className="title">Update Time</h2>
          <p className="description">
            Any time you select will be added to the estimated time.
          </p>
        </div>

        <RadioButtonGroup
          options={TIME_OPTIONS}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          classes="time-list"
          labelClasses="time-list-item"
          checkmarkClasses="checkmark"
        />

        <div className="time-btns">
          <Button
            onClick={handleCancel}
            label="Cancel"
            type="neutral"
            classes="cancel-btn"
          />
          <Button
            onClick={() => {
              handleConfirm(
                selectedOption.id === 3 ? customTime : selectedOption.time
              );
            }}
            label="Confirm"
            type="filled"
            classes="confirm-btn"
            disabled={selectedOption.id === 3 && !customTime}
            loading={loading}
          />
        </div>
      </div>
    </StyledWrapper>
  );
};

TimeDialogue.propTypes = {};

export default TimeDialogue;
