import React, { useContext, useEffect, useState } from "react";
import { StyledWrapper, StyledTableModal } from "./styles";
import { ReactComponent as SunIcon } from "assets/image/sun.svg";
import { ReactComponent as CalendarIcon } from "assets/image/calendar.svg";
import { ReactComponent as ArrowDownIcon } from "assets/image/arrowDown.svg";
import { ReactComponent as IIcon } from "assets/image/i.svg";
import { ReactComponent as SolidArrowDownIcon } from "assets/image/solidArrowDown.svg";
import { ReactComponent as SolidArrowTopIcon } from "assets/image/solidArrowTop.svg";
// import DatePicker from "react-datepicker";
import RangeCalendar from "components/RangeCalendar";
import PaginateTable from "components/PaginateTable";
import { ReactComponent as TwowayArrowIcon } from "assets/image/twowayArrow.svg";
import { ReactComponent as ExportIcon } from "assets/image/export.svg";
import { ReactComponent as CrossIcon } from "assets/image/cross.svg";
import { ReactComponent as TickIcon } from "assets/image/tick.svg";
import useModal from "../../hooks/useModal";
import { ModalContext } from "../../context/modalContext";
import CustomPagination from "components/CustomPagination";
import Overview from "./Overview";
import Orders from "./Orders";
import Payouts from "./Payouts";
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;
const Analytics = () => {
  const { handleModal } = useContext(ModalContext);
  const [pagination, setPagination] = useState({
    count: 10,
    next: 2,
    previous: 1,
  });
  const [page, setPage] = useState(1);

  // const [payoutStartDate, setPayoutStartDate] = useState(null);
  // const [payoutEndDate, setPayoutEndDate] = useState(null);
  // const [payoutShowDatePicker, setPayoutShowDatePicker] = useState(false);
  // const [payoutFilter, setPayoutFilter] = useState("7days");
  // const PAYOUT_TABLE = [
  //   {
  //     element: "7 Days",
  //     filterClassName: "overview-filter",
  //     activeFilterClassName: "overview-filter--active",
  //     name: "7days",
  //   },
  //   {
  //     element: "14 Days",
  //     filterClassName: "overview-filter",
  //     activeFilterClassName: "overview-filter--active",
  //     name: "14days",
  //   },
  //   {
  //     element: "30 Days",
  //     filterClassName: "overview-filter",
  //     activeFilterClassName: "overview-filter--active",
  //     name: "30days",
  //   },
  // ];
  // const [payoutDropdown, setPayoutDropdown] = useState("daily");

  const getDayAndMonth = (date) => {
    const MONTHS = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return MONTHS[date.getMonth()] + " " + date.getDate();
  };

  // const getPayoutFilter = () => {
  //   switch (payoutFilter) {
  //     case "7days":
  //       return "7 days";
  //     case "14days":
  //       return "14 days";
  //     case "30days":
  //       return "30 days";
  //     case "date":
  //       return (
  //         <>
  //           {payoutStartDate ? getDayAndMonth(payoutStartDate) : ""}{" "}
  //           {payoutEndDate && " →"}
  //           {payoutEndDate ? ` ${getDayAndMonth(payoutEndDate)}` : ""}
  //         </>
  //       );

  //     default:
  //       break;
  //   }
  // };

  return (
    <StyledWrapper>
      <h1 className="title">Sales</h1>
      <RangePicker showTime />
      {/* Overview */}
      <Overview />
      {/* Orders Table */}
      <Orders />
      {/* Payout Table */}
      <Payouts />
    </StyledWrapper>
  );
};

export default Analytics;
