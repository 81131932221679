import React from 'react'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'

const StyledModal = styled(Modal)`
    .modal-content{
        border-radius: 38px;
    }

    .modal-header{
        h6{
            color: #343434;	
            	
            font-size: 25.2px;	
            font-weight: bold;	
            letter-spacing: 0.47px;	
            line-height: 31px;
        }

        button{
            margin-right:20px;
        }
    }
`

const CustomModal = (props) => {
    return (
        <StyledModal show={props.show} size="lg" onHide={props.onHide}>
            <Modal.Header closeButton className="py-4">
                <h6 className="ml-5 mb-0">
                    {
                        props.header
                    }
                </h6>
            </Modal.Header>
            <Modal.Body className="px-5">
                {
                    props.children
                }
            </Modal.Body>
        </StyledModal>
    )
}

export default CustomModal