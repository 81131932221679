import React, { useState, useRef, useEffect } from 'react'
import { Row, Col, Card, Form, Container } from 'react-bootstrap'
import Button from 'components/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCheck } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import { loginUser } from 'actions/user'
import { apiUrl } from '../../constants'
import { createTransaction, getSubscription } from "api/billing/customers"
import Checkout from 'containers/checkout'
import Loading from 'components/Loading'
import {BillingForm} from 'containers/billing/billing-address'

const PaymentDetails = ({locale, ...props}) => {
    let history = useHistory();

    const [loading, setLoading] = useState(false)
    const [stripe, setStripe] = useState(null)
    const [step, setStep] = useState(1)
    const [billing, setBilling] = useState({})
    const checkout = useRef();
    const address = useRef();

    const { registerForm } = props

    useEffect(() => {
    //    console.log(checkout,address);
     }, [checkout, address]);

    const [paymentDetails, setPaymentDetails] = useState({
        method: '',
        cardHolderName: '',
        cardNumber: '',
        expierDate: {
            month: 1,
            year: 2020
        }
    })
    const [paymentDetailsError, setPaymentDetailsError] = useState({ method: false, cardHolderName: false, cardNumber: false, expierDate: false })

    const handleChange = (event) => {
        let name = event.target.name
        let value = event.target.value

        if (name === "expierDateMonth")
            setPaymentDetails({ ...paymentDetails, expierDate: { ...paymentDetails.expierDate, month: value } })
        else if (name === "expierDateYear")
            setPaymentDetails({ ...paymentDetails, expierDate: { ...paymentDetails.expierDate, year: value } })
        else
            setPaymentDetails({ ...paymentDetails, [name]: value })
    }

    const handlePayment = (id) => {
        history.push('/login', {needVerification: true})
    }

    return (
        <Row>
            {/** Payment Details */}
            <Col md={6} sm={12} >
                <Card className="o-hidden border-0 shadow-lg my-5">
                    <Card.Body className="px-3">
                        <Container>
                            <Row className="mb-3">
                                <Col md={12}>
                                    <h3 className="h4 text-dark my-2 text-center font-weight-bold">Payment Details</h3>
                                    <p className="p-0 m-0 text-center">Plese enter your payment details.</p>
                                </Col>
                            </Row>

                            <Row>
                                {
                                  step === 1
                                    ? <BillingForm
                                        ref={address}
                                        setBilling={setBilling}
                                        setLoading={setLoading}
                                        onSubmit={() => setStep(2)}
                                      />
                                    : <Checkout
                                      ref={checkout}
                                      billing={billing}
                                      setLoading={setLoading}
                                      plan={props.registerForm.plan}
                                      onSubmit={handlePayment}
                                    />
                                }
                            </Row>
                            <Row className="text-center">
                                <Col md={12}>
                                  <a
                                      onClick={() => step === 1 ? props.setStep(3) : setStep(1)}
                                      className="p-3">
                                      <FontAwesomeIcon icon={faArrowLeft} className="mx-3" />
                                      Go Back!
                                  </a>
                                  <Button
                                    onClick={() => step === 1 ? address.current.handleSubmit() : checkout.current.handleSubmit()}
                                    // type="submit"
                                    // disabled={!stripe || loading}
                                    className="font-weight-bold px-5"
                                  >
                                      {loading ? <Loading nopadding={true} /> : step === 1 ? 'Next' : 'Subscribe'}
                                  </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
            </Col>

            {/** Payment Summery */}
            <Col md={6} sm={12} >
                <Card className="o-hidden border-0 shadow-lg my-5">
                    <Card.Body className="px-5">
                        <h3 className="h4 text-dark my-2 text-center font-weight-bold">
                            Payment Summery
                        </h3>
                        <p className="p-0 m-0 text-center">
                            This is a recurring monthly payment which can later be changed in your profile
                        </p>
                        <hr />
                        <h4 className="text-center font-weight-bold text-dark">{registerForm.plan.nickname}</h4>
                        <hr />
                        <div className="d-flex align-items-center justify-content-around">
                            <span style={{ fontSize: '1.5rem', fontWeight: '600', color: '#4c4c4c' }}>
                                Total
                            </span>
                            {
                                !registerForm.plan.amount ?
                                    <span style={{ fontSize: '1.5rem', fontWeight: '600', color: '#4c4c4c' }}>Free</span>
                                    :
                                    <span>
                                        <span style={{ fontSize: '1.5rem', fontWeight: '600', color: '#4c4c4c' }}>{registerForm.plan.amount}{locale.currency}/</span>
                                        <span style={{ color: '#4c4c4c', fontWeight: '500', marginTop: '1.25rem' }}>month</span>
                                    </span>
                            }
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default connect(state => ({locale: state.locale[state.locale.active]}), {getSubscription})(PaymentDetails)
