import locale_fa from 'constants/locale_fa'
import locale_en from 'constants/locale_en'

export const locale = (state = {
  active: 'en',
  fa: locale_fa,
  en: locale_en
}, action) => {
  switch (action.type) {
    case 'CHANGE_LOCALE':
      return { ...state, active: action.active }
    case 'ADD_NEW_LOCALE':
      return { ...state, [action.locale.name]: action.locale }
    default:
      return state
  }
}
