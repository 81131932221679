import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter,
} from "react-router-dom";
import { Col, Container, Row, Card, Form } from "react-bootstrap";
import styled from "styled-components";
import Navbar from "components/Layout/Navbar";

const StyledCard = styled(Card)`
  /* background: red; */
  width: 70vw;
  padding: 2rem 3rem 2rem 2rem;
  @media (max-width: 575px) {
    width: 95vw;
    padding: 1rem 1.5rem 1rem 1rem;
  }
  .small_title {
    color: #000;
    font-size: 13px;
  }
  .title {
    color: #000;
    font-family: Inter;
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    margin-top: 2rem;
    @media (max-width: 575px) {
      font-size: 1.25rem;
    }
  }
  .title_2 {
    color: #000;
    font-family: Inter;
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    margin-top: 2rem;
    @media (max-width: 575px) {
      font-size: 1.25rem;
    }
  }
  .bold_p {
    margin-top: 0.5rem;
    color: #000;
    font-family: Inter;
    font-weight: 700;
    font-size: 1.2rem;
    @media (max-width: 575px) {
      font-size: 0.85rem;
    }
  }
  .text {
    margin-top: 0.5rem;
    color: #000;
    font-family: Inter;
    font-weight: 600;
    font-size: 1rem;
    @media (max-width: 575px) {
      font-size: 0.85rem;
    }
  }
  .underline_header {
    font-style: italic;
    text-decoration: underline;
  }
  ol {
    padding-left: 1.25rem;
  }
  p {
    margin-top: 0.5rem;
    color: #000;
    font-family: Inter;
    font-weight: 400;
    font-size: 1rem;
    @media (max-width: 575px) {
      font-size: 0.85rem;
    }
  }
`;
const PrivacyContainer = styled.div`
  width: 100vw;
  min-height: 70vh;
  display: flex;
  justify-content: center;
`;

const PwaPrivacyDelivery = (props) => {
  return (
    <>
      <Navbar />
      <PrivacyContainer>
        <StyledCard className="o-hidden border-0 shadow-lg my-5">
          <span className="small_title">
            ("Omnicart", "Dine Easy", "we", "us", "our", etc)
          </span>
          <span className="title">Omnicart Platform Privacy Policy</span>
          <span className="title_2">
            This Privacy Policy explains how Omnicart collects, uses and
            discloses personal information
          </span>
          <p>
            Dine Easy Holding Inc. takes privacy and data security seriously. We
            are committed to transparency in the collection, use and disclosure
            of your personal information. This Privacy Policy describes how we
            use personal information, and the circumstances under which we may
            share this information with a third party. This Privacy Policy
            applies to the privacy practices of Omnicart on our website located
            at: www.omnicart.tech, or the various associated websites, platforms
            or applications (collectively and each individually, the "Omnicart
            Platform") but does not include personal information relating to
            employees in such a capacity.
          </p>
          <p>
            By visiting and/or using the Omnicart Platform, you consent to the
            collection, use, storage, disclosure and transfer of your personal
            information as set out in this policy. In some cases, we may ask you
            to provide explicit consent if we intend to collect, use or disclose
            your personal information otherwise than as indicated in this
            Privacy Policy, however sometimes your consent may be implied
            through your conduct with us.
          </p>
          <p>
            If you do not agree with any part of this Privacy Policy you should
            not conduct any transactions on the Omnicart Platform, navigate away
            from the Omnicart Platform immediately, and uninstall our
            application.
          </p>
          <span className="title">
            Collection and Use of Personal Information
          </span>
          <span className="title_2">
            This section explains the purposes for which Omnicart collects
            personal information
          </span>
          <ul type="1">
            <li className="text">Account Creation:</li>
            <p>
              We collect your name, phone number, email address, a hashed
              password of your selection, the date and time of account creation
              and the IP address and device identifier of the device used for
              account creation. We use this information to administer your
              account, to enable your access to the Omnicart Platform, verify
              your identity as an authorized user concerning any accounts, and
              to implement, carry out and maintain security measures aimed at
              protecting our customers from identity theft, fraud and
              unauthorized access to an account. You may choose to register for
              an account via a third- party social media network, including
              Facebook and Google ("Social Media"). If you do so, you authorize
              us to access certain Social Media account information consistent
              with your Social Media privacy settings, including your name. We
              use this information to administer your account, and to enable
              your access to the Omnicart Platform.
            </p>
            <li className="text">Social Media:</li>
            <p>
              We may offer you the opportunity to otherwise engage with Omnicart
              or its content on or through Social Media. When you engage with us
              through Social Media websites, plug-ins and applications, you may
              allow us to have access to and disclose certain information
              associated with your Social Media account (e.g., name, username,
              email address, profile picture) to deliver content or as part of
              the operation of the website, plug-in or application.
            </p>
            <li className="text">Placing an Order:</li>
            <p>
              We collect information relating to any orders you place on the
              Omnicart Platform, including the IP address of the device used to
              place the order to help protect against theft, fraud and
              unauthorized access to an account, and certain payment information
              using the secure services of our payment processors. Payment
              operations are outsourced to our payment processors and we do not
              store your credit card information in our systems other than the
              first four and last four digits of the credit card number.
            </p>
            <p>
              If your order has been placed for delivery, we collect the
              physical address to which you have requested delivery. We use this
              information to complete your delivery, and to allow you to select
              previously entered addresses for future deliveries. If you submit
              an order for alcohol, you may be asked to provide
              government-issued identification upon delivery, to confirm that
              you are of legal age.
            </p>
            <li className="text">Order History:</li>
            <p>
              We may collect your order history including the date, time, cost,
              order type (takeout or delivery order), physical address and
              delivery instructions (if a delivery order), method of purchase,
              and order details, including specific food items ordered and
              special order instructions. We use this information to ensure that
              your order has been completed and to improve our service
              offerings.
            </p>
            <li className="text">Marketing and Other Communications:</li>
            <p className="bold_p">
              This section explains when we may contact you for marketing
              purposes or to inform you about important changes or other
              developments to the Omnicart Platform, products, services or your
              account
            </p>
            <p>
              We may use your personal information to contact you for your views
              regarding Omnicart and to notify you occasionally about important
              changes or developments to the Omnicart Platform, products,
              services or your account. You cannot opt out of receiving
              notifications of important changes or developments (e.g. to this
              Privacy Policy or the Terms of Service/Use) unless you discontinue
              use of the Omnicart Platform.
            </p>
            <p>
              You agree that we may use your personal information to let you
              know about our other events, products and services that may be of
              interest to you including services that may be the subject of
              direct marketing. We may contact you to do so by telephone,
              SMS/MMS and email and if you use our mobile application, via push
              notification. It is always your choice whether to receive or to
              continue receiving such marketing materials and updates from
              Omnicart. We will not send you any direct marketing communications
              unless you have separately consented to receive such
              communications or such consent has been deemed to have been given
              under applicable law. If you no longer wish to receive direct
              marketing communications, you can opt-out using the unsubscribe
              mechanism in the marketing communication itself (e.g. by clicking
              the unsubscribe link at the bottom of an email or by replying with
              the word "STOP" to an SMS/MMS or by following other provided
              instructions). To manage push notifications, go to the settings
              page of your phone or tablet and change the push notification
              permission for the Omnicart app. If you switch off in-app push
              notifications, you will no longer receive updates on your orders
              via push.
            </p>
            <li className="text">Courier and Merchant Reviews:</li>
            <p>
              We may offer you the opportunity to submit a review of couriers
              and/or merchants on the Omnicart Platform. When your review is
              published, the results may be made available to couriers and/or
              merchants in appropriate circumstances. You also consent to us
              using such reviews in any marketing or advertising materials.
            </p>
            <li className="text">Referrals:</li>
            <p>
              The referral, "refer a friend" and other similar features on the
              Omnicart Platform permit you to send an email to other individuals
              that may be interested in Omnicart. This feature may require you
              to submit your name and email address, as well as the name and
              email address of the recipient. We do not use the names or email
              addresses submitted in these circumstances for any other purpose
              without the consent of you or the email recipient to do so. Please
              ensure that you only submit email addresses of individuals with
              whom you have a personal or family relationship and who would want
              to receive the message.
            </p>
            <li className="text">Employment Opportunities:</li>
            <p>
              In connection with a job application or related inquiry, you may
              provide us with personal information including a resume, cover
              letter, or similar employment-related materials. This information
              will be used for the purposes of processing and responding to your
              application for current and future career opportunities, and to
              manage any ongoing working relationship.
            </p>
            <li className="text">Surveys and Research:</li>
            <p>
              From time to time, Omnicart may offer you the opportunity to
              participate in one of our surveys or other research, or to provide
              feedback regarding your order or experience with Omnicart. The
              information obtained through our surveys and research is used in
              an aggregated, non-personally identifiable form to help us
              understand users of the Omnicart Platform and to enhance our
              product and service offerings.
            </p>
            <li className="text">Contests and Promotions:</li>
            <p>
              When you enter a contest or participate in a promotion, Omnicart
              may collect your name, address, phone number, email address, and
              other information you provide. This information is used to
              administer your participation in the contest, sweepstakes or
              promotion.
            </p>
            <li className="text">Apply to Become a Merchant or Vendor:</li>
            <p>
              You may apply to become a vendor on the Omnicart Platform by
              providing us with your name, phone number, email address, physical
              address as well as other vendor materials. In most instances, this
              information would be considered business contact information
              rather than personal information. We use this information for the
              purpose of processing and responding to your application, and to
              manage any ongoing vendor relationship.
            </p>
            <li className="text">Contact Us:</li>
            <p>
              When you contact us with a comment or question, we collect the
              information you provide such as your name and email address, along
              with additional information that we may need to help us promptly
              respond to your inquiry. We retain this information to assist you
              in the future and to improve our customer service and service
              offerings. We also use personal information to establish and
              manage our relationship with you and provide quality service. We
              may record voice calls for training and quality purposes.
            </p>
            <span className="title">Information We Collect Automatically</span>
            <p className="bold_p">
              This section explains the information we collect automatically
              when you visit the Omnicart Platform
            </p>
            <p>
              We and our third-party service providers, including Google
              Analytics, collect, process, store and analyze information when
              you visit the Omnicart Platform, including IP address, your
              location when the Omnicart mobile application is used, device
              identifiers, browser characteristics, operating system details,
              language preferences, referring URLs, length of visits, pages
              viewed and other related information. We also use "cookies" or
              other tools that track, measure and analyze the behaviors and
              usage patterns of visitors to the Omnicart Platform. We use this
              information to help us understand how visitors engage with the
              Omnicart Platform and to improve our visitors’ experience.
            </p>
            <p style={{ fontWeight: "700" }}>
              A cookie is a tiny element of data that the Omnicart Platform can
              send to your browser, which may then be stored on your web browser
              or mobile device so we can recognize you when you return. The
              information that cookies collect includes the date and time of
              your visit, your registration information, session identification
              number, and your navigational history and preferences
            </p>
            <p>
              We use cookies for analytics purposes as well as for certain
              features of the Omnicart Platform. The use of cookies is an
              industry standard and many major browsers and smartphones are
              initially set up to accept them. You may set your web browser or
              smartphone to notify you when you receive a cookie or to not
              accept certain cookies. However, if you decide not to accept
              cookies from the Omnicart Platform, you may not be able to take
              advantage of all its features.
            </p>
            <p>
              Some third-party service providers may use cookies on our site. We
              have no control over those cookies and they are not covered by
              this Privacy Policy.
            </p>
            <p>
              You can obtain additional information on Google Analytics’
              collection and processing of data and data privacy and security,
              including how to learn more about opting out of Google Analytics’
              data collection by visiting the Google Analytics website.
            </p>
            <span className="title_2">
              This section explains under what circumstances personal
              information may be disclosed by Omnicart
            </span>
            <p>
              The personal information you provide to us will be held on our
              servers which may be in Canada or the United States. The third
              parties with whom we share your information may undertake various
              activities such as processing credit card payments and providing
              services or products for us. In addition, we may need to provide
              your personal information to any vendors that a customer has
              placed an order with and couriers to allow the order to be
              processed and delivered. By submitting your personal data, you
              agree to this transfer, storing or processing. We will take all
              steps reasonably necessary to ensure that your personal
              information is treated securely and in accordance with this
              Privacy Policy.
            </p>
            <p>
              If you have consented we may allow select third parties, including
              marketing and advertising companies, our affiliates and
              associates, to contact you occasionally about other products or
              services that may be of interest to you. They may contact you by
              mail, telephone, mobile messaging (e.g. SMS, MMS, etc.) as well as
              by email. If you change your mind about being contacted by these
              parties in the future, please let us know by using the contact
              details set out at the end of this Privacy Policy and/or by
              amending your profile accordingly.
            </p>
            <p>
              If our business enters into a joint venture with, purchases or is
              sold to or merged with another business entity, your personal
              information may be disclosed or transferred to the target company,
              our new business partners or owners or their advisors provided
              that the information disclosed continues to be used for the
              purposes permitted by this Privacy Policy by the entity acquiring
              the information.
            </p>
            <p>
              We may use the personal information that you provide to us if we
              are under a duty to disclose or share your personal information in
              order to comply with (and/or where we believe we are under a duty
              to comply with) any applicable legal obligation; or in order to
              enforce the Terms of Service/Use and any other agreement we have
              with you. This includes exchanging personal information with other
              companies and other organisations for the purposes of fraud
              protection and prevention. We and our service providers may use or
              disclose your personal information in response to a search warrant
              or other legally valid inquiry or order, or to another
              organization for the purposes of investigating a breach of an
              agreement or contravention of law or detecting, suppressing or
              preventing fraud, or as otherwise required or permitted by
              applicable law or legal process. Your personal information may
              also be used or disclosed where necessary or desirable for the
              establishment, exercise or defence of legal claims and to
              investigate or prevent actual or suspected loss or harm to persons
              or property.
            </p>
            <span className="title">
              Safeguarding and Retention of Personal Information
            </span>
            <span className="title_2">
              This section explains the safeguards Omnicart has put in place to
              protect your personal information and the length of time Omnicart
              may retain your personal information
            </span>
            <p>
              We have implemented administrative, technical and physical
              measures to protect your personal information from unauthorised
              access and against unlawful processing, accidental loss,
              destruction and damage. We restrict access to your personal
              information to Omnicart personnel and authorized service providers
              who require access to fulfill their job requirements.
            </p>
            <p>
              We will keep your information where we have an ongoing legitimate
              business need to do so (for example, while you hold an account
              with us and for a reasonable period of time thereafter, or to
              enable us to meet our legal, tax or accounting obligations). We
              may retain certain data as necessary to prevent fraud or future
              abuse, or for legitimate business purposes, such as analysis of
              aggregated, non-personal information, or account recovery, or if
              required or permitted by law. All retained personal information
              will remain subject to the terms of this Privacy Policy. If you
              request that your name be removed from our databases, it may not
              be possible to completely delete all your personal information due
              to technological and legal constraints.
            </p>
            <p>
              The transmission of information via the internet is not completely
              secure. Although we will take steps to protect your personal
              information, we cannot guarantee the security of your personal
              information or other data transmitted to the Omnicart Platform;
              any transmission is at your own risk. Once we have received your
              personal information, we will use organizational and technical
              safeguards to try to prevent unauthorised access. Please also note
              that the Omnicart Platform contains links to third party websites,
              which are not governed by this Privacy Policy, and JUST EAT is not
              responsible for the collection, use or disclosure of Personal
              Information by such third party websites. When you click on such a
              link, you will leave our service and go to another site. During
              this process, another entity may collect personal information from
              you.
            </p>
            <span className="title">
              Access to Personal Information and Withdrawal of Consent
            </span>
            <br />
            <span className="title_2">
              This section explains how you may gain access to your personal
              information held by Omnicart and how you may withdraw consent to
              the collection and use of personal information
            </span>
            <p>
              collection and use of personal information You have the right to
              access, update and correct inaccuracies in your personal
              information in our custody and control, subject to certain
              exceptions prescribed by law. You may request access, updating and
              corrections of inaccuracies in other personal information we have
              in our custody or control by emailing or writing to us at the
              contact information set out below. We may request certain personal
              information for the purposes of verifying the identity of the
              individual seeking access to their personal information records
              and may restrict access to personal information as required or
              permitted by applicable law or legal process.
            </p>
            <p>
              You may withdraw or modify your consent to any ongoing and future
              collection and use of personal information at any time, subject to
              legal and contractual restrictions, provided that reasonable
              notice is given to us. If you withdraw your consent to receive
              information regarding certain products, publications, services or
              events being offered by Omnicart, you will no longer receive those
              types of communications.
            </p>
            <span className="title">Updates to the Privacy Policy</span>
            <p>
              This Privacy Policy is current as of the “Last Updated” date which
              appears at the bottom of the page. Your continued use of the
              Omnicart Platform after any change of our Privacy Policy will
              constitute your acceptance of the revised terms of this Privacy
              Policy. We may update this Privacy Policy from time to time as
              required to reflect changes to our privacy practices. We encourage
              you to periodically review this page for the latest information on
              our privacy practices.
            </p>
            <span className="title">Contact Us</span>
            <p>
              Omnicart welcomes your comments, concerns and questions regarding
              this Privacy Policy and the use of your personal information. If
              you would like access to your information, if you have any
              comments, queries or requests relating to our use of your personal
              information, if you would like a copy of our Privacy Policy
              emailed to you or if you find any errors in our information about
              you, please contact us at:
            </p>
            <p>Dine Easy Holding Inc.</p>
            <p>301 - 134 Abbott street</p>
            <p>Vancouver, BC</p>
            <p>V6B 2K4</p>
            <p>
              or by email at{" "}
              <a href={"https://www.contact@omnicart.tech"}>
                contact@omnicart.tech
              </a>
            </p>
          </ul>
        </StyledCard>
      </PrivacyContainer>
    </>
  );
};

export default PwaPrivacyDelivery;
