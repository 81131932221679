import React from "react";
import { Nav } from "react-bootstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { Colors } from "assets/colors";

const SNav = styled(Nav)`
  border: none !important;
  .nav-link {
    display: block;
    padding: 5px 20px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    color: ${Colors.primary1};
    font-size: 15px;
    letter-spacing: 2px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .nav-item.show .nav-link,
  .nav-link.active {
    background-color: #fff;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14),
      0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-bottom: 4px solid ${Colors.primary1};
    color: ${Colors.primary1};
  }
  .nav-link {
    background: #e3e5e6;
  }
`;

const CardTabs = ({ tabs, onSelect, activeKey }) => {
  const permissions = useSelector((state) => state.user.permissions);

  return (
    <SNav
      style={{
        paddingLeft: "1.25rem",
      }}
      onSelect={onSelect}
      variant="tabs"
      activeKey={activeKey}
    >
      {tabs
        .filter(
          (t) =>
            !t.private ||
            permissions.includes(t.name) ||
            permissions.includes("__panel__") ||
            permissions.includes("__admin__")
        )
        .map((t) => (
          <Nav.Item key={t.id}>
            <Nav.Link eventKey={t.id}>
              <FontAwesomeIcon icon={t.icon} /> {t.title}
            </Nav.Link>
          </Nav.Item>
        ))}
    </SNav>
  );
};

export default CardTabs;
