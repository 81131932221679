import React from 'react'
import {
  Route,
  Redirect,
} from 'react-router-dom'
import { connect } from 'react-redux'

const PrivateRoute = ({ component: Component, isLoggedIn, ...rest }) => {
  return (<Route {...rest} render={(props) => (
    isLoggedIn === true
      ?
        <Component {...props} />
      :
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }
          }}
        />
  )} />
  )
}
export default connect(state => ({ isLoggedIn: state.user.isLoggedIn }))(PrivateRoute)
