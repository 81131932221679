import styled from "styled-components";
import { Colors } from "assets/colors";

export const StyledWrapper = styled.div`
  margin: 24px 0;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #dde1e4;
  margin-bottom: 270px;

  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .box {
    background: #f5f7f8;
    padding: 16px 24px;
    border-bottom: 1px solid #dde1e4;

    &--top {
      border-radius: 5px 5px 0 0;
    }

    &--bottom {
      border-bottom: none;
      background: #ffffff;
      padding: 12px 20px;
      border-radius: 0 0 5px 5px;
      display: flex;
      justify-content: space-between;
    }
  }

  .box-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
  }

  .name-input,
  .info {
    width: 359px !important;
    height: 32px !important;
    padding: 7px 12px !important;

    &-note {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #7d8284;
      margin: 4px 16px 0;
      display: flex;
      margin-bottom: 16px;
    }
  }

  .info-note {
    margin-left: 0;
    margin-top: 9px;
    margin-bottom: 0;

    svg {
      margin-right: 6px;
    }
  }

  .list {
    padding-left: 0 !important;
    margin-top: 14px;

    &-item-custom {
      font-weight: 600;
      font-size: 14px !important;
      line-height: 18px;
      color: #2b3235 !important;
      padding-left: 26px !important;
    }
  }

  .checkmark {
    width: 16px !important;
    height: 16px !important;
    background: #ffffff !important;
    box-shadow: 0px 0px 0px 1px #dcdddd, 0px 2px 5px rgba(60, 66, 87, 0.1),
      0px 1px 1px rgba(0, 0, 0, 0.1) !important;
    border: none !important;

    &:after {
      width: 7px !important;
      height: 7px !important;
      background: white !important;
    }
  }

  .container input:checked ~ .checkmark {
    background: #2b64f5 !important;
    box-shadow: 0px 0px 0px 1px #2b64f5, 0px 2px 5px rgba(60, 66, 87, 0.1),
      0px 1px 1px rgba(0, 0, 0, 0.1) !important;
  }

  .date-dropdown {
    &-label {
      width: 164px;
      justify-content: space-between !important;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;

      &-btn {
        height: 32px;
        padding: 7px 10px 7px 12px !important;
        background: #ffffff;
      }

      &-btn-open {
        height: 32px;
        width: 186px;
        background: #ffffff !important;
        box-shadow: 0px 0px 0px 2px rgba(43, 100, 245, 0.4),
          0px 2px 4px rgba(0, 0, 0, 0.07), 0px 1px 1px rgba(0, 0, 0, 0.05) !important;
      }

      &-list {
        top: 100%;
        left: 0;
        margin-top: 8px !important;
        width: 186px !important;
        background: #ffffff;
        border: 0.5px solid #d0d1d2;
        box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        padding: 8px 0;

        &-item {
          margin: 0;
          height: 36px;
          border: none;
          padding: 8px 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: ${Colors.textPrimary1};
          background: #ffffff;
          cursor: pointer;

          &:hover {
            background: #f5f7f8;
          }
        }
      }
    }
  }

  .btn {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    padding: 5px 8px;
    margin-right: 8px;

    &-cancel {
      &:hover {
        color: ${Colors.textPrimary1};
      }
    }

    &-create {
      margin-right: 0;

      &:hover {
        color: #ffffff;
      }
    }

    &-delete {
      color: #c7322d;

      &:hover {
        color: #c7322d;
      }
    }
  }

  .percentage-input {
    position: relative;
    width: 186px !important;
    height: 32px !important;
  }

  .input-label {
    position: absolute;
    left: 160px;
    top: 50%;
    transform: translateY(-50%);
    font-family: "Manrope";
  }

  .search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-family: "Manrope";
    z-index: 2;
    color: ${Colors.textPrimary1};
  }

  .search-input {
    padding: 8px 34px !important;
    width: 365px !important;
    height: 32px !important;
    margin-right: 8px;
  }

  .selected {
    margin-bottom: 8px;
    max-height: 200px;
    overflow: auto;
    margin-top: 12px;
  }

  .input-row {
    display: flex;
    flex-direction: row;
  }

  .browse {
    width: 68px;
    height: 32px;
    padding: 7px 9px;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;

    &:hover {
      color: ${Colors.textPrimary1};
    }
  }

  .row {
    &-box {
      padding: 6px 0;
      position: relative;
      display: flex;
      align-items: center;

      &:nth-child(1) {
        border: none;
        align-items: center;
        display: flex;
      }

      svg {
        color: ${Colors.textPrimary1};
        margin-left: 17px;
        cursor: pointer;
      }
    }
  }

  .item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    width: 441px;

    &-left {
      display: flex;
      flex-direction: column;
    }

    &-right {
      display: flex;
      align-items: center;
    }

    &-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #2b3235;
      margin-top: 2px;
      margin-bottom: 2px;
    }

    &-price {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #666b6d;
      margin-bottom: 0;
    }

    &-label {
      width: 100%;

      &--sub {
        color: #7b7b7b;
      }
    }

    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
      border: 1px solid #dde1e4;
      border-radius: 5px;
      overflow: hidden;
      background: #ffffff;
    }
  }

  .item-checkbox {
    width: 100%;
    position: relative;
    margin: 16px 0;

    .checkmark-icon {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    label {
      padding-left: 24px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #7b7b7b;
    }
  }

  .date-box,
  .weekday-box {
    margin-left: 33px;
  }

  .date-range {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #4e5457;
    margin-bottom: 8px;
  }

  .date-row {
    display: flex;
    flex-direction: row;
  }

  .weekly-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 16px;
  }

  .to {
    margin: 0 12px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #4e5457;
  }

  .btns-right {
    display: flex;
    margin-left: auto;
  }
`;
