import "react-lazy-load-image-component/src/effects/blur.css";

import { IMAGE_VALID_FORMATS, IMAGE_VALID_SIZE } from "../../constants";
import Input, { Label } from "components/forms/Input";
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  addImages,
  editImages,
  fetchImagesByRestaurantId,
  removeImages,
  uploadImage,
} from "api/images";
import {
  addIngredient,
  getIngredients,
  getRestaurantModifiers,
} from "api/items";
import {
  createMenu,
  deleteMenu,
  duplicateMenu,
  editMenu,
  getMenuById,
} from "api/menus";
import {
  faClone,
  faPlus,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { getEndTime } from "utils";
import Button from "react-bootstrap/Button";
import CheckBox from "components/CheckBox";
import CircleButton from "components/button/CircleButton";
import Col from "react-bootstrap/Col";
import { Colors } from "assets/colors";
import ConfirmDeleteModal from "components/confirmDeleteModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormButton from "components/button/FormButton";
import { Heading } from "components/typography";
import ImageUploader from "components/imageUploader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Switch from "react-switch";
import { connect } from "react-redux";
import { getData } from "../../utils";
import { getMenuAndCategoryTitleByRestaurantId } from "api/menus";
import { makeAlert } from "actions";
import moment from "moment";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";

const orderType = [
  // {
  //   title: 'Pickup',
  //   id: 1,
  //   name: 'takeout',
  //   private: true,
  // },
  {
    title: "Delivery / Pickup",
    id: 2,
    name: "delivery",
    private: true,
  },
];

const WEEKDAYS = [
  { id: 7, title: "Su" },
  { id: 1, title: "M" },
  { id: 2, title: "Tu" },
  { id: 3, title: "W" },
  { id: 4, title: "Th" },
  { id: 5, title: "F" },
  { id: 6, title: "Sa" },
];

let localId = 0;

const AddNewItem = ({ locale, permissions, ...props }) => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      activeOrderType: 0,
    }
  );
  const [activeWeekDays, setActiveWeekDays] = useState([1, 2, 3, 4, 5, 6, 7]);
  const [activeOrderType, setActiveOrderType] = useState([0]);
  const [allImages, setAllImages] = useState([]);
  const [menuImages, setMenuImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState([]);
  const [menuName, setMenuName] = useState("");
  const [menuDescription, setMenuDescription] = useState("");
  const [menuStartTime, setMenuStartTime] = useState("");
  const [menuEndTime, setMenuEndTime] = useState("");
  const [onShowUploader, setOnShowUploader] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [published, setPublished] = useState(false);
  const [customPhoto, setCustomPhoto] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteMenuLoading, setDeleteMenuLoading] = useState(false);
  const [duplicateLoading, setDuplicateLoading] = useState(false);
  const [pageMainLoading, setPageMainLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [nextInput, setNextInput] = useState(null);
  const [activeHours, setActiveHours] = useState([
    {
      id: null,
      start: "",
      end: "",
      weekdays: [1, 2, 3, 4, 5, 6, 7],
      localId,
    },
  ]);
  const handleAddActiveHour = () => {
    localId++;
    setActiveHours([
      ...activeHours,
      {
        start: "",
        end: "",
        weekdays: [1, 2, 3, 4, 5, 6, 7],
        localId,
        type: "NEW",
        id: null,
      },
    ]);
  };

  const handleRemoveActiveHour = (localId) => {
    let newActiveHours = activeHours.map((o) => {
      if (o.localId !== localId) return o;
      o.type = "DELETED";
      return o;
    });
    setActiveHours(newActiveHours);
  };

  const onDrop = useCallback((files) => {
    const reader = new FileReader();
    const url = reader.readAsDataURL(files[0]);
    const filesize = (files[0].size / 1000).toFixed(4); // KB
    const validFileType = IMAGE_VALID_FORMATS.includes(
      files[0].type.split("image/")[1]
    );
    setErrors([]);
    let tempErrors = [];
    if (filesize > IMAGE_VALID_SIZE) tempErrors.push("imageFileSize");
    if (!validFileType) tempErrors.push("validImageFileType");
    if (tempErrors.length === 0) {
      reader.onloadend = function (e) {
        setAddLoading(true);
        props
          .uploadImage({
            image: files[0],
            restaurants: [props.selectedRestaurant],
          })
          .then((res) => {
            setOnShowUploader(false);
            setAddLoading(false);
            setAllImages((prevState) => {
              return [getData(res), ...prevState];
            });
            setSelectedImage((prevState) => {
              return !props.multiple
                ? [getData(res)]
                : [getData(res), ...prevState];
            });
          })
          .catch((e) => {
            setOnShowUploader(false);
            setAddLoading(false);
            console.log("error in uploading image", e);
          });
      };
    } else setErrors(tempErrors);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const menuID =
    props.location.pathname.split("/").slice(-1)[0] !== "add"
      ? props.location.pathname.split("/").slice(-1)[0]
      : null;

  const handleChangeOrderType = (id) => {
    if (activeOrderType.includes(id)) {
      if (activeOrderType.length === 1) return;
      setActiveOrderType(activeOrderType.filter((t) => t !== id));
    } else {
      setActiveOrderType([...activeOrderType, id]);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    switch (name) {
      case "name":
        setMenuName(value);
        break;
      case "description":
        setMenuDescription(value);
        break;
    }
  };

  const handleChangeActiveHour = (value, id, name) => {
    let temp = activeHours.map((o) => {
      if (o.localId !== id) return o;
      o[name] = value;
      o.type = "UPDATED";
      return o;
    });
    setActiveHours(temp);
  };

  const weekdaysHandler = (id, weekId) => {
    let temp = activeHours.map((o) => {
      if (o.localId !== id) return o;
      let current = o.weekdays.find((id) => id === weekId);
      if (current) {
        o.weekdays = o.weekdays.filter((id) => id !== current);
      } else {
        o.weekdays = [...o.weekdays, weekId];
      }
      o.type = "UPDATED";
      return o;
    });

    setActiveHours(temp);
  };

  const getDurationTime = (start, end) => {
    return moment
      .utc(moment(end, "HH:mm:ss").diff(moment(start, "HH:mm:ss")))
      .format("HH:mm:ss");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors([]);
    let tempErrors = [];
    if (menuName === "") {
      tempErrors.push("name");
    }
    activeHours
      .filter((o) => o.type !== "DELETED")
      .forEach((o) => {
        if (o.start === "") {
          tempErrors.push("activeHour_start_" + o.localId);
        }
        if (o.end === "") {
          tempErrors.push("activeHour_end_" + o.localId);
        }
        if (o.weekdays.length === 0) {
          tempErrors.push("weekdays_" + o.localId);
        }
      });
    if (tempErrors.length === 0) {
      menuID ? updateMenu(e) : createMenu(e);
    } else {
      setErrors(tempErrors);
      props.makeAlert("Some required fields are still empty!", "error");
    }
  };

  const createMenu = () => {
    let data = {
      restaurant: props.selectedRestaurant,
      is_active: published,
      name: menuName,
      description: menuDescription,
      order_type: activeOrderType,
      active_hours_: activeHours
        .filter((o) => !(!o.id && o.type === "DELETED"))
        .map((o) => ({
          ...o,
          from_hour: o.start,
          duration: getDurationTime(o.start, o.end),
          type: !o.id ? "NEW" : o.type,
        })),
      are_item_images_visible: customPhoto,
    };
    setLoading(true);
    props
      .createMenu(data)
      .then((res) => {
        if (getData(res)?.id) {
          if (selectedImage.length > 0) {
            handleAddImage(getData(res)?.id);
          } else {
            setLoading(false);
            props.history.push("/console/create-menus");
          }
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log("error in create menu", e);
      });
  };

  const handleAddImage = (menuId) => {
    selectedImage.forEach((image) => {
      const data = { menus: [...image.menus, menuId] };
      props
        .editImages(image.id, data)
        .then((res) => {
          setLoading(false);
          props.history.push("/console/create-menus");
        })
        .catch((e) => {
          setLoading(false);
          console.log("error on attaching image", e);
        });
    });
  };

  const selectImageFunction = (item) => {
    let isExist = selectedImage.find((e) => e.id === item.id);

    if (isExist) handleDeleteImage(item.id);
    else {
      setSelectedImage([item]);
    }
  };

  const handleDeleteMenu = () => {
    setConfirmDeleteModal(false);
    setDeleteMenuLoading(true);
    props
      .deleteMenu(menuID)
      .then((res) => {
        setDeleteMenuLoading(false);
        props.makeAlert("Menu Deleted!", "success");
        props.history.push("/console/create-menus");
      })
      .catch((e) => {
        setDeleteMenuLoading(false);
        props.makeAlert(e, "error");
        console.log("error in deleting menu", e);
      });
  };

  const updateMenu = (e) => {
    if (e) e.preventDefault();
    setLoading(true);
    let data = {
      name: menuName,
      is_active: published,
      description: menuDescription,
      restaurant: props.selectedRestaurant,
      order_type: activeOrderType,
      active_hours_: activeHours
        .filter((o) => !(!o.id && o.type === "DELETED"))
        .map((o) => ({
          ...o,
          from_hour: o.start,
          duration: getDurationTime(o.start, o.end),
          type: !o.id ? "NEW" : o.type,
        })),
      are_item_images_visible: customPhoto,
    };
    setLoading(true);
    props
      .editMenu(menuID, data)
      .then((res) => {
        setLoading(false);
        props.history.push("/console/create-menus");
      })
      .catch((e) => {
        setLoading(true);
        console.log("error in updating menu", e);
      });
    updateImages(menuImages, selectedImage);
  };

  const updateImages = (prevImages, newImages) => {
    let newImagesId = newImages.map((img) => img.id);
    let prevImagesId = prevImages.map((img) => img.id);
    if (customPhoto) {
      prevImages.forEach((img) => {
        if (!newImagesId.includes(img.id)) {
          props.removeImages(img.id, {
            type: "MENU",
            id: menuID,
          });
        }
      });
      newImages.forEach((image) => {
        if (!prevImagesId.includes(image.id)) {
          props.addImages(image.id, {
            type: "MENU",
            id: menuID,
          });
        }
      });
    } else {
      prevImages.forEach((img) => {
        props.removeImages(img.id, {
          type: "MENU",
          id: menuID,
        });
      });
    }
  };

  const handleDeleteImage = (id) => {
    let tmp = Array.from(selectedImage);
    let index = tmp.findIndex((el) => el.id === id);
    if (index > -1) tmp.splice(index, 1);
    setSelectedImage(tmp);
  };

  const handleChangePublish = (e) => {
    setPublished(e);
  };

  const handleTogglePhoto = (e) => {
    setCustomPhoto(!customPhoto);
  };

  const handleDuplicateMenu = () => {
    setDuplicateLoading(true);
    props
      .duplicateMenu(menuID)
      .then((res) => {
        setDuplicateLoading(false);
        props.makeAlert("Menu Duplicated", "success");
        props.history.push("/console/create-menus");
      })
      .catch((e) => {
        setDuplicateLoading(false);
        console.log("error in duplicating menu", e);
      });
  };

  useEffect(() => {
    if (
      props.selectedRestaurant &&
      (permissions.includes("__panel__") || permissions.includes("menus"))
    ) {
      if (menuID) {
        setPageMainLoading(true);
        props
          .getMenuById(menuID)
          .then((res) => {
            setPageMainLoading(false);
            setMenuName(getData(res)?.name);
            setMenuDescription(getData(res)?.description);
            setMenuImages(getData(res)?.images);
            setActiveOrderType(getData(res)?.order_type);
            setMenuStartTime(getData(res)?.from_hour);
            setMenuEndTime(
              getEndTime(getData(res)?.from_hour, getData(res)?.duration)
            );
            setActiveWeekDays(getData(res)?.weekdays);
            setPublished(getData(res)?.is_active);
            setSelectedImage(getData(res)?.images);
            setCustomPhoto(getData(res)?.are_item_images_visible);
            if (getData(res)?.active_hours.length > 0)
              setActiveHours(
                getData(res)
                  ?.active_hours.sort((a, b) => a.id - b.id)
                  .map((o, index) => ({
                    ...o,
                    start: o.from_hour,
                    end: getEndTime(o.from_hour, o.duration),
                    localId: index,
                    type: "NOT_CHANGED",
                  }))
              );
            localId = getData(res)?.active_hours.length;
          })
          .catch((e) => {
            setPageMainLoading(false);
            console.log("error in loading edit menu", e);
          });
      }
    }
  }, []);

  useEffect(() => {
    if (
      props.selectedRestaurant &&
      (permissions.includes("__panel__") || permissions.includes("menus"))
    ) {
      props
        .fetchImagesByRestaurantId(1, props.selectedRestaurant)
        .then((res) => {
          setAllImages(getData(res));
        })
        .catch((e) => {
          console.log("error in getting image", e);
        });
    }
  }, []);

  return (
    <Row>
      {pageMainLoading ? (
        <SpinnerContainer>
          <Spinner animation="border" size="lg" />
        </SpinnerContainer>
      ) : (
        <Col
          md={12}
          style={{
            paddingTop: 100,
            paddingBottom: 25,
          }}
        >
          <Heading>{menuID ? "EDIT MENU" : "NEW MENU"}</Heading>
          <Row
            style={{
              marginBottom: 125,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Col lg={5} md={5} style={{ marginTop: "2.5rem" }}>
              <Input
                id="cypress_menu_name"
                label="Menu Name"
                name="name"
                onChange={handleChange}
                value={menuName}
                errorText={errors.map((error) =>
                  error === "name" ? `${error} is required!` : null
                )}
                maxLength={50}
              />
              <Col style={{ marginTop: "2.5rem", paddingLeft: 0 }}>
                <Label>Available for:</Label>
                <RowWrapper>
                  {orderType
                    .filter(
                      (t) =>
                        !t.private ||
                        permissions.includes(t.name) ||
                        permissions.includes("__panel__") ||
                        permissions.includes("__admin__")
                    )
                    .map((type, index) => (
                      <Col key={index}>
                        <Row>
                          <CheckBox
                            onClick={() => handleChangeOrderType(type.id)}
                            type={"SQUARE"}
                            active={activeOrderType.includes(type.id)}
                          />
                          <p style={{ marginLeft: "0.5rem", color: "#000" }}>
                            {type.title}
                          </p>
                        </Row>
                      </Col>
                    ))}
                </RowWrapper>
              </Col>
              <Col style={{ marginTop: "2.5rem", paddingLeft: 0 }}>
                <Label>Active Hours</Label>
                {activeHours
                  .filter((o) => o.type !== "DELETED")
                  .map((a, index) => (
                    <div
                      key={a.localId}
                      style={{
                        borderBottom:
                          activeHours.length - 1 !== index
                            ? "1px solid #ccc"
                            : "none",
                        padding: index !== 0 ? "15px 0" : "0 0 15px 0",
                      }}
                    >
                      <div className="d-flex justify-content-end align-items-end">
                        {index !== 0 && (
                          <Label>
                            <Button
                              variant="danger"
                              onClick={() => handleRemoveActiveHour(a.localId)}
                              style={{
                                borderRadius: 100,
                                padding: "2px 15px",
                              }}
                            >
                              Remove <FontAwesomeIcon icon={faTimes} />
                            </Button>
                          </Label>
                        )}
                      </div>
                      <div className="d-flex align-items-center">
                        <CustomTimePicker
                          time={{}}
                          value={a.start}
                          onChange={(time) =>
                            handleChangeActiveHour(time, a.localId, "start")
                          }
                          type="start"
                          nextInput={nextInput}
                          error={errors.includes(
                            "activeHour_start_" + a.localId
                          )}
                        />
                        <span style={{ margin: "0 1rem" }}>to</span>
                        <CustomTimePicker
                          time={{}}
                          value={a.end}
                          onChange={(time) =>
                            handleChangeActiveHour(time, a.localId, "end")
                          }
                          type="end"
                          setNextInput={setNextInput}
                          error={errors.includes("activeHour_end_" + a.localId)}
                        />
                      </div>
                      <div className="d-flex mt-4">
                        {WEEKDAYS.map((weekday, index) => (
                          <WeekDay
                            onClick={() =>
                              weekdaysHandler(a.localId, weekday.id)
                            }
                            active={a.weekdays.includes(weekday.id)}
                          >
                            <span
                              style={{
                                color: "#fff",
                                fontFamily: "Inter",
                                fontSize: "1.3rem",
                                fontWeight: "bold",
                              }}
                            >
                              {weekday.title}
                            </span>
                          </WeekDay>
                        ))}
                      </div>
                      {errors.includes("weekdays_" + a.localId) ? (
                        <div>
                          <span style={{ marginTop: "1rem", color: "red" }}>
                            At least one day should be active
                          </span>
                        </div>
                      ) : null}
                    </div>
                  ))}
                <div
                  className="d-flex justify-content-start align-items-center mt-4"
                  style={{ paddingBottom: 25 }}
                  onClick={handleAddActiveHour}
                >
                  <CircleButton>
                    <FontAwesomeIcon icon={faPlus} />
                  </CircleButton>
                  <h5 style={{ marginLeft: "10px", marginTop: "5px" }}>
                    Add Another Active Hour
                  </h5>
                </div>
              </Col>
              <Row style={{ marginTop: "4rem" }}>
                <Col md={6} style={{ marginBottom: "2rem", paddingLeft: 0 }}>
                  <FormButton
                    id="cypress_menu_save"
                    onClick={(e) => handleSubmit(e)}
                  >
                    {loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <span>Save</span>
                    )}
                  </FormButton>
                </Col>
                <Col md={6} style={{ marginBottom: "3rem" }}>
                  <Link to="/console/create-menus">
                    <FormButton
                      style={{ backgroundColor: "#eee", color: "#4e4e4e" }}
                    >
                      Cancel
                    </FormButton>
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={6} style={{ marginTop: "-1rem" }}>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <EditButtonWrapper>
                    <Switch
                      className="mr-3"
                      onChange={handleChangePublish}
                      checked={published}
                      handleDiameter={18}
                      height={24}
                      offColor="#E45628"
                      uncheckedIcon={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            paddingRight: 2,
                            // fontSize: 13,
                            fontWeight: "bold",
                            color: "#fff",
                          }}
                        >
                          OFF
                        </div>
                      }
                      checkedIcon={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            paddingLeft: 2,
                            fontWeight: "bold",
                            color: "#fff",
                          }}
                        >
                          ON
                        </div>
                      }
                    />
                    {menuID && (
                      <>
                        <div
                          className="delete_button"
                          onClick={handleDuplicateMenu}
                          style={{ backgroundColor: Colors.primary1 }}
                        >
                          {duplicateLoading ? (
                            <Spinner
                              animation="border"
                              size="sm"
                              style={{ color: "#fff" }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faClone}
                              className="mx-3"
                              color={"#fff"}
                            />
                          )}
                        </div>
                        <div
                          id="cypress_menu_delete"
                          className="delete_button"
                          onClick={() => setConfirmDeleteModal(true)}
                          style={{
                            marginLeft: "1rem",
                            backgroundColor: "#E45628",
                          }}
                        >
                          {deleteMenuLoading ? (
                            <Spinner
                              animation="border"
                              size="sm"
                              style={{ color: "#fff" }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faTrash}
                              className="mx-3"
                              color={"#fff"}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </EditButtonWrapper>
                  <Switch
                    className="mr-3"
                    onChange={handleTogglePhoto}
                    checked={customPhoto}
                    handleDiameter={18}
                    height={24}
                    offColor="#E45628"
                    uncheckedIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          paddingRight: 2,
                          fontWeight: "bold",
                          color: "#fff",
                        }}
                      >
                        OFF
                      </div>
                    }
                    checkedIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          paddingLeft: 2,
                          fontWeight: "bold",
                          color: "#fff",
                        }}
                      >
                        ON
                      </div>
                    }
                  />
                  <Label>Placeholder photo</Label>
                  <ImageUploader
                    onShowUploader={onShowUploader}
                    setOnShowUploader={setOnShowUploader}
                    allImages={allImages}
                    selectedImage={selectedImage}
                    selectImageFunction={selectImageFunction}
                    getInputProps={getInputProps}
                    getRootProps={getRootProps}
                    addLoading={addLoading}
                    disabled={!customPhoto}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "3rem" }}>
                {customPhoto && (
                  <Col lg={12} md={12} sm={12}>
                    <span>Preview</span>
                    <PreviewImageWrapper>
                      {selectedImage[0] ? (
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          src={selectedImage[0]?.image_med}
                        />
                      ) : (
                        <span
                          style={{
                            width: "100%",
                            height: "100%",
                            border: "1px solid gray",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          select an image first
                        </span>
                      )}
                    </PreviewImageWrapper>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
      )}
      <ConfirmDeleteModal
        onHide={() => setConfirmDeleteModal(false)}
        onClick={handleDeleteMenu}
        show={confirmDeleteModal}
      />
    </Row>
  );
};

export default connect(
  (state) => ({
    selectedRestaurant: state.user.selectedRestaurant,
    locale: state.locale[state.locale.active],
    permissions: state.user.permissions,
  }),
  {
    getIngredients,
    addIngredient,
    getRestaurantModifiers,
    getMenuAndCategoryTitleByRestaurantId,
    fetchImagesByRestaurantId,
    uploadImage,
    getMenuById,
    createMenu,
    editImages,
    editMenu,
    addImages,
    removeImages,
    deleteMenu,
    makeAlert,
    duplicateMenu,
  }
)(AddNewItem);


const CustomTimePicker = (props) => {
  const [amPm, setAmPm] = useState("AM");
  const [minute, setMinute] = useState(null);
  const [hour, setHour] = useState(null);
  const [time, setTime] = useState("00:00:00");
  const [initial, setInitial] = useState(true);
  const [nextInput, setNextInput] = useState(null);
  const hourInput = useRef(null);
  const minuteInput = useRef(null);

  useEffect(() => {
    if (props.value && initial) {
      setInitial(false);
      let unformattedHour = props.value.slice(0, 2);
      let unformattedMinute = props.value.slice(3, 5);

      let formattedHour =
        unformattedHour <= 12 ? unformattedHour : unformattedHour - 12;
      if (unformattedHour >= 12) setAmPm("PM");

      if (unformattedHour === "00") {
        formattedHour = 12;
      }

      setHour(parseInt(formattedHour));
      setMinute(parseInt(unformattedMinute));
    }
  }, [props.value]);

  useEffect(() => {
    if (props.setNextInput) props.setNextInput(hourInput);
    if (props.nextInput) setNextInput(props.nextInput);
  }, [props.nextInput]);

  const handleSetAM = (amPm) => {
    setAmPm(amPm);
    handleSubmit(false, false, amPm);
  };

  const handleChange = (event, isMinute, min, max) => {
    const { value } = event.target;
    if (isNaN(value)) return;
    if (value < min || value > max) {
      if (isMinute) {
        if ((value.toString().length >= 2 || value * 10 > max) && nextInput) {
          nextInput.current.focus();
        }
      } else {
        if (value.toString().length >= 2 || value * 10 > max) {
          minuteInput.current.focus();
        }
      }
      return;
    }

    if (isMinute) {
      if (
        (value.toString().replace("0", "").length >= 2 || value * 10 > max) &&
        nextInput
      ) {
        nextInput.current.focus();
      }
      setMinute(parseInt(value));
    } else {
      if (value.toString().length >= 2 || value * 10 > max) {
        minuteInput.current.focus();
      }
      setHour(parseInt(value));
    }
    handleSubmit(parseInt(value), isMinute);
  };

  const handleSubmit = (value, isMinute, isAm) => {
    let newHour = hour;
    let newMinute = minute;
    if (value) {
      if (isMinute) {
        newMinute = value;
      } else {
        newHour = value;
      }
    }
    newHour = parseInt(newHour);
    newMinute = parseInt(newMinute);
    if (isAm) {
      switch (isAm) {
        case "AM":
          newHour = newHour;
          break;
        case "PM":
          if (newHour === 12) break;
          newHour = newHour + 12;
          break;
      }

      newHour = newHour === 12 && isAm === "AM" ? 0 : newHour;
      newHour = newHour === 12 && isAm === "PM" ? 12 : newHour;
    } else {
      newHour =
        amPm === "AM" || (amPm === "PM" && newHour === 12)
          ? newHour
          : newHour + 12;

      newHour = newHour === 12 && amPm === "AM" ? 0 : newHour;
      newHour = newHour === 12 && amPm === "PM" ? 12 : newHour;
    }

    newHour = newHour === 24 ? 0 : newHour;

    newHour = newHour < 10 ? "0" + newHour : newHour;
    newMinute = newMinute < 10 ? "0" + newMinute : newMinute;
    let result = newHour + ":" + newMinute + ":00";
    result = result.replace(/\NaN/g, "00");
    setTime(result);
    if (props.onChange) props.onChange(result);
  };
  const handleFocus = (event) => {
    event.target.select();
  };

  return (
    <div>
      <TimePickerInputWrapper>
        <div className="time_inputs_wrapper">
          <input
            className="time_input"
            id="cypress_menu_time_hours"
            type="text"
            onChange={(e) => handleChange(e, false, 0, 12)}
            min={0}
            max={12}
            value={
              (parseInt(hour) !== 0
                ? hour && parseInt(hour) < 10
                  ? "0" + hour
                  : hour
                : "00") || ""
            }
            onFocus={handleFocus}
            placeholder="00"
            ref={hourInput}
            name="hour"
          />
          <span>:</span>
          <input
            className="time_input"
            id="cypress_menu_time_mins"
            type="text"
            onChange={(e) => handleChange(e, true, 0, 59)}
            min={0}
            max={59}
            value={
              (parseInt(minute) !== 0
                ? minute && parseInt(minute) < 10
                  ? "0" + minute
                  : minute
                : "00") || ""
            }
            onFocus={handleFocus}
            placeholder="00"
            ref={minuteInput}
            name="minute"
          />
        </div>
        <div className="time_format_wrapper">
          <TimeFormatWrapper
            onClick={() => handleSetAM("AM")}
            top={true}
            active={amPm === "AM"}
          >
            am
          </TimeFormatWrapper>
          <TimeFormatWrapper
            onClick={() => handleSetAM("PM")}
            active={amPm === "PM"}
          >
            pm
          </TimeFormatWrapper>
        </div>
      </TimePickerInputWrapper>
      {props.error ? (
        <span style={{ marginBottom: "1rem", color: "red" }}>
          This field is required
        </span>
      ) : null}
    </div>
  );
};

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const TimePickerInputWrapper = styled.div`
  display: flex;
  width: 12rem;
  height: 4rem;
  background-color: #E2E2E2;
  border-radius: 12px;
  .time_inputs_wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex: 4;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type=number] {
      -moz-appearance: textfield;
    }
    input[type="time"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      cursor:pointer;
      display: block;
      width:20px;
      color: red;
      text-align:center;
      position:relative;
    }
    .time_input{
      width: 3rem;
      height: 3rem;
      border-radius: 7px;
      border: 1px solid ${Colors.primary1}
      outline: none;
      padding-left: 13px;
    }
  }
  .time_format_wrapper{
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

const TimeFormatWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  background: ${(p) => (p.active ? Colors.primary1 : "#E2E2E2")}
  color: ${(p) => (p.active ? "#fff" : "#828282")};
  ${(p) =>
    p.top
      ? "border-top-right-radius: 12px"
      : "border-bottom-right-radius: 12px"}
`;
const WeekDay = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
  background-color: ${(p) => (p.active ? Colors.primary1 : "#828282")};
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: ${(p) => (p.active ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : null)};
`;
const RestaurantImages = styled(LazyLoadImage)`
  height: 5.5rem;
  width: 100%;
  object-fit: cover;
  ${(p) => p.active && "border: 2px solid red"};
`;
const AddPhotoWrapper = styled.div`
  /* background-color: red; */
  width: 80%;
  padding: 5px 5px;
  height: 20rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  overflow-y: scroll;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  /* margin-top: -4rem */
  .imageWrapper {
    height: 5.5rem;
    cursor: pointer;
  }
  .add_photo_button {
    height: 5.5rem;
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12);
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
`;
const PreviewImageWrapper = styled.div`
  width: 80%;
  height: 20rem;
  filter: drop-shadow(0px 3px 14px rgba(0, 0, 0, 0.12));
`;
const EditButtonWrapper = styled.div`
  width: 80%;
  height: 3.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .delete_button {
    height: 2.75rem;
    width: 2.75rem;
    background: green;
    border-radius: 2rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14),
      0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
`;
const SpinnerContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-self: center;
  position: absolute;
  top: 300px;
`;
