import React from "react";
import { StyledError } from "./styles";

/** Error fallback UI component */

const ErrorFallback = ({ error }) => {
  return (
    <StyledError>
      <pre>Oops!! Something went wrong</pre>
      <pre className="btn" onClick={() => window.location.reload()}>
        Try refreshing your browser
      </pre>
      <pre className="text">
        If you still face issues please reach out to us at{" "}
      </pre>
      <pre className="email">contact@omnicart.tech</pre>
    </StyledError>
  );
};

export default ErrorFallback;
