import styled from "styled-components";
import { Colors } from "assets/colors";

export const StyledError = styled.div`
  padding: 40px 0;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #727272;

  .error {
    font-size: 85px;
  }

  pre {
    width: 100vw;
    white-space: break-spaces;
    padding: 20px;
    color: #727272;
  }

  .btn {
    background: ${Colors.primary1};
    padding: 21px;
    color: white;
    font-weight: bold;
    border-radius: 4px;
    margin: 0 20px;
    max-width: 321px;
    min-width: 266px;
    width: 80vw;
    cursor: pointer;

    &:hover {
      background-color: #3f378d;
      color: white;
    }
  }

  .text {
    margin-top: 10vh;
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 0;
  }

  .email {
    margin-top: 0;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0;
    color: ${Colors.primary1};
  }
`;
