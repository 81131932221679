import { fetchApi } from './'

export const login = (data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`user/login/`, 'POST', data, false, true, true))

  return res
}

export const resetPassword = (data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`user/rest-auth/password/reset/`, 'POST', data, false, true))

  return res
}

export const resetPasswordConfirm = (data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`user/rest-auth/password/reset/confirm/`, 'POST', data, false, true))

  return res
}

export const verifyEmail = (data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`user/rest-auth/account-confirm-email/`, 'POST', data, false, true))

  return res
}

export const register = (data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`user/rest-auth/registration/`, 'POST', data, false, true))

  return res
}

export const userInfo = () => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`user/rest-auth/user/`, 'GET', {}, false, true))
  dispatch({type: 'USER_INFO', info: res})

  return res
}
export const updateUser = (data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`user/rest-auth/user/`, 'PATCH', data, false, true))
  dispatch({type: 'USER_INFO', info: res})

  return res
}

export const changePassword = (data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`user/rest-auth/change-password/`, 'PUT', data, false, true))

  return res
}

export const logout = () => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`user/rest-auth/logout/`, 'POST', {}, false, true))

  return res
}

export const getClaimingStatus = () => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`merchant_claimers/details/mine/`, 'GET'))

  return res
}

export const getMyPermissions = () => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`user/my_permissions/`, 'GET', {}, false, true))

  return res
}

export const subscribeToPush = (payload) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`user/push-token/`, 'POST', {push_token: payload}, false, true))

  return res
}

export const getCrispKey = (id) => async (dispatch, getState) => {
  let res = await dispatch(
      fetchApi(
          `organizations/${id}/get_crisp_key/`,
          "GET",
          null,
          false,
          false,
          true
      )
  );
  return res?.data;
};
