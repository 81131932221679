import React, { useContext, useEffect, useCallback, useState } from "react";
import Button from "components/common/Button";
import PauseDialogue from "../PauseDialogue";
import { ModalContext } from "../../../context/modalContext";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useSelector, useDispatch } from "react-redux";
import { wsUrl } from "constants";
import { ReactComponent as PauseIcon } from "assets/image/pause.svg";
import { ReactComponent as ResumeIcon } from "assets/image/resume.svg";
import { changeTakeoutStatus } from "api/restaurant";

/** Pause and Unpause logic & UI */

const PauseOrders = ({
  takeoutStatus,
  pause,
  setPause,
  pauseLoading,
  setPauseLoading,
  ...props
}) => {
  const { handleModal } = useContext(ModalContext);
  const selectedRestaurant = useSelector(
    (state) => state.user.selectedRestaurant
  );
  const dispatch = useDispatch();

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    wsUrl + "ws/pause-orders-status/",
    {
      retryOnError: true,
      shouldReconnect: (closeEvent) => {
        return readyState === ReadyState.CLOSED;
      },
      reconnectAttempts: 20,
      reconnectInterval: 1000,
    }
  );

  const connectToWebsocket = useCallback(() => {
    // WebSocket connection to pause-orders-status
    sendMessage(JSON.stringify({ restaurant: selectedRestaurant }));
  }, []);

  useEffect(() => {
    if (readyState === ReadyState.OPEN) connectToWebsocket();
  }, [selectedRestaurant, readyState]);

  useEffect(() => {
    if (lastMessage) {
      const data = JSON.parse(lastMessage.data);
      if (data.type === "PAUSE_ORDERS") {
        setPause(data.payload.pause);
      }
    }
  }, [lastMessage]);

  const handleConfirm = (sOption, customReason, customTime) => {
    setPauseLoading(true);
    try {
      if (!pause) {
        /** Pausing orders */
        dispatch(
          changeTakeoutStatus(selectedRestaurant, {
            takeout_status: pause,
            reason: sOption.id + 1,
            reason_text: sOption.id !== 2 ? sOption.title : customReason,
          })
        )
          .then((res) => {
            setPause(!getData(res)?.takeout_status);
            setPauseLoading(false);
          })
          .catch((err) => setPauseLoading(false));
      } else {
        /** Unpausing order */

        dispatch(
          changeTakeoutStatus(selectedRestaurant, {
            takeout_status: pause,
            reason: sOption.id + 1,
            reason_text: sOption.id !== 2 ? sOption.title : null,
            duration:
              sOption.id !== 2 ? Number(sOption.time) : Number(customTime),
          })
        )
          .then((res) => {
            setPause(!getData(res)?.takeout_status);
            setPauseLoading(false);
            if (timer) {
              clearTimeout(timer);
            }
            let timer = setTimeout(() => {
              setShow(false);
              setPause(true);
            }, customTime * 3600 * 1000);
          })
          .catch((e) => {
            setPauseLoading(false);
          });
      }
    } catch (err) {}
  };

  return (
    <Button
      label={pause ? "Resume orders" : "Pause orders"}
      leadingIcon={pause ? <ResumeIcon /> : <PauseIcon />}
      type="neutral"
      onClick={() =>
        handleModal(
          <PauseDialogue
            takeoutStatus={takeoutStatus}
            pause={pause}
            setPause={setPause}
            handleConfirm={handleConfirm}
            loading={pauseLoading}
          />,
          true // Hide close Icon
        )
      }
    />
  );
};

PauseOrders.propTypes = {};

export default PauseOrders;
