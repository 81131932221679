import { Colors } from "assets/colors";
import styled from "styled-components";

export const StyledOptions = styled.div`
  position: absolute;
  bottom: 99%;
  max-height: 325px;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 0 -10px 15px 0 rgb(0 0 0 / 5%);
  border-bottom: none;
  overflow: auto;

  @media (max-width: 1120px) {
    position: fixed;
    width: 100vw;
    height: 100vh;
    bottom: 0;
    left: 0;
    z-index: 1001;
    top: 0;
    background: white;
    max-height: none;
  }

  .item {
    border-bottom: 1px solid #ececec;
    margin: 0 18px;
    padding: 15px 0;
    color: rgba(21, 21, 21, 0.9);
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;

    @media (max-width: 1120px) {
      padding: 5px 0;
    }

    p {
      margin-bottom: 0;
      @media (max-width: 1120px) {
        height: 48px;
        display: flex;
        align-items: center;
        padding-left: 10px;
      }
    }

    &:hover {
      color: ${Colors.primary1};

      @media (max-width: 1120px) {
        p {
          margin-bottom: 0;
          background: rgba(101, 89, 216, 0.05);
          height: 48px;
          display: flex;
          align-items: center;
          padding-left: 10px;
          border-radius: 8px;
        }
      }
    }
  }
`;
export const StyledInput = styled.div`
  position: relative;
  width: 100%;
  height: 55px;
  margin: 7.5px auto;
  display: flex;
  align-items: center;

  .label {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(21, 21, 21, 0.5);

    svg {
      width: 18px;
      height: 12px;
      color: ${(p) => {
        if (p.error) {
          return "#e84118";
        } else {
          return "#dadada";
        }
      }};
    }
  }

  .react-select-container div {
    border: none !important;
    &:first-child {
    height: 100% !important;
    }
    &:nth-child(2) {
        height: 100% !important;
        border: 1px solid gray;
      }

    div {
      &:first-child {
        height: 100% !important;
        padding-top: 16px;
      }
      &:nth-child(2) {
        display: flex;
        align-items: center;
      }
    }
  }

  .selected {
    color: #151515 !important;
    font-weight: bold !important;
  }

  .arrow {
    position: absolute;
    right: 18px;
    svg {
      color: rgba(21, 21, 21, 0.7);
      font-size: 8px;
      width:13px;
    }
  }

  .input {
    width: 100% !important;
    height: 100% !important;
    padding-left: 18px;
    padding-right: 56px;
    font-size: 14px !important;
    display: flex;
    align-items: center;
    border: ${(p) => {
      if (p.error) {
        return "1px solid #e84118 !important";
      } else {
        return "1px solid #ececec !important";
      }
    }};

      p {
    width: 193px;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
        margin-top: 12px;

  }
  }

  .focused {
    border: 1px solid #ececec !important;
    outline: none !important;
    box-shadow:  0 0 15px 0 rgba(0, 0, 0, 0.05);
  }

  .rotate {
    transform: rotate(180deg);
    transition: .3s ease;
  }

  input:focus + label {
    outline: none !important;
    svg {
      color: #151515 !important;
    }
  }

  input::placeholder {
    font-size: 14px
    color: rgba(21, 21, 21, 0.5);
    font-weight: normal;
  }

  .stick-right {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .no-border {
        border: none !important;
  }

  .phone-input {
        border-radius: 0 !important;
        padding-right: 0 !important;
  }

  .phone-country {
    width: 40px !important;
    border: none !important;
    background: transparent !important;
  }
`;
export const StyledClose = styled.div`
  display: none;

  @media (max-width: 1120px) {
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 20px 25px;
    border-top: solid 1px #ececec;
  }

  .btn {
    background: #ececec;
    width: 100%;
    margin: 0;
    color: #151515;
    border-radius: 0;
    height: 56px;
    font-weight: bold;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
`;
