import { Colors } from "assets/colors";
import styled from "styled-components";

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  transform: translateY(29px);

  .btn {
    width: 283px;
    height: 48px;
    background-color: ${Colors.primary1};
    color: ${Colors.white};
    font-weight: bold;

    &:hover {
      color: #121212;
    }
  }

  .btn-right {
    position: absolute;
    right: 0;
    top: -30%;
    top: -60px;
  }
`;
