/*
 * actions/index.js
 *
 * actions related to user e.g. authentication
 *
 */

import { endLoading, startLoading } from ".";
import { logout, userInfo } from "../api/auth";
import { persistStore, purgeStoredState } from "redux-persist";

import { createBrowserHistory } from "history";
import { getData } from "../utils";
import { getMyRestaurants } from "../api/restaurant";
import jsCookie from "js-cookie";
import { persistor } from "../store";
import { useHistory } from "react-router-dom";

export const setToken = (token) => (dispatch, getState) => {
  dispatch({ type: "TOKEN", token });
  jsCookie.set("token", token);
};

export const setMyPermissions = (permissions) => (dispatch, getState) => {
  dispatch({ type: "UPDATE_MY_PERMISSIONS", permissions });
};

export const loginUser = (token, info) => (dispatch, getState) => {
  dispatch({ type: "USER_LOGGED_IN", isLoggedIn: true });
  dispatch({ type: "USER_INFO", info });
  dispatch(setToken(token));

  dispatch(userInfo()).then((res) => {
    dispatch(getMyRestaurants()).then((res) => {
      let selectedRestaurant =
        getState().user.selectedRestaurant ||
        (getData(res)?.results[0] && getData(res)?.results[0].id);
      let selectedRestaurantData =
        getData(res)?.results.find(
          (res) => res.id === getState().user.selectedRestaurant
        ) ||
        (getData(res)?.results[0] && getData(res)?.results[0]);
      if (selectedRestaurant) {
        dispatch({
          type: "USER_SELECTED_RESTAURANT_CHANGED",
          selectedRestaurant,
        });
      }
    });
    dispatch(endLoading());
  });
};

export const setAuthDecision = (decided) => async (dispatch, getState) => {
  dispatch({ type: "USER_AUTH_DECIDED", isAuthDecided: decided });
};

const purge = async () => {
  await persistor.purge();
  await persistor.purge();
  console.log("Factory reset performed.");
};

export const logoutUser = () => (dispatch, getState) => {
  dispatch({ type: "TOKEN", token: null });
  dispatch({ type: "USER_LOGGED_IN", isLoggedIn: false });
  dispatch({ type: "USER_INFO", info: null });
  dispatch({ type: "PURGE_USER" });

  localStorage.clear();
  sessionStorage.clear();

  setTimeout(() => persistor.purge(), 200);

  purge();

  // persistStore().purge();
  // purgeStoredState()

  Object.keys(jsCookie.get()).forEach(function (cookieName) {
    jsCookie.remove(cookieName);
  });

  dispatch(logout());
};

export const logoutWhenUnauthorized = (status) => (dispatch, getState) => {
  if (status === 401) {
    dispatch(logoutUser());
  }
};

export const changeUserRestaurants = (value) => (dispatch, getState) => {
  dispatch({ type: "USER_RESTAURANTS_CHANGED", restaurants: value });
};

export const changeSelectedRestaurantData = (value) => (dispatch, getState) => {
  dispatch({
    type: "SET_SELECTED_RESTAURANT_DATA",
    selectedRestaurantData: value,
  });
};

export const changeSelectedRestaurant = (value) => (dispatch, getState) => {
  dispatch({
    type: "USER_SELECTED_RESTAURANT_CHANGED",
    selectedRestaurant: value,
  });
  dispatch({ type: "NEW_ORDERS_SEEN" });
  dispatch(startLoading());
  let browserHistory = createBrowserHistory();
  if (browserHistory.location.pathname.includes("/console/create-menus/")) {
    browserHistory.push("/console/create-menus");
  }
  setTimeout(() => {
    window.location.reload();
    // this.props.history.replace(current);
  });
  dispatch(endLoading());
};

export const changeSelectedRestaurantWithoutReload =
  (value) => (dispatch, getState) => {
    dispatch({
      type: "USER_SELECTED_RESTAURANT_CHANGED",
      selectedRestaurant: value,
    });
    dispatch({ type: "NEW_ORDERS_SEEN" });
    dispatch(startLoading());
    let browserHistory = createBrowserHistory();
    if (browserHistory.location.pathname.includes("/console/create-menus/")) {
      browserHistory.push("/console/create-menus");
    }
    dispatch(endLoading());
  };

export const ChangeTutorial =
  (stepName, stepNumber) => (dispatch, getState) => {
    // dispatch({ type: 'TUTORIAL', tutorial: stepName, tutorialSteps: stepNumber })
  };
