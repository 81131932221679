import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter,
} from "react-router-dom";
import { Col, Container, Row, Card, Form } from "react-bootstrap";
import styled from "styled-components";
import Navbar from "components/Layout/Navbar";

const StyledCard = styled(Card)`
  /* background: red; */
  width: 70vw;
  padding: 2rem 3rem 2rem 2rem;
  @media (max-width: 575px) {
    width: 95vw;
    padding: 1rem 1.5rem 1rem 1rem;
  }
  .title {
    color: #000;
    font-family: Inter;
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    margin-top: 2rem;
    @media (max-width: 575px) {
      font-size: 1.25rem;
    }
  }
  .text {
    margin-top: 0.5rem;
    color: #000;
    font-family: Inter;
    font-weight: 400;
    font-size: 1rem;
    @media (max-width: 575px) {
      font-size: 0.85rem;
    }
  }
  .underline_header {
    font-style: italic;
    text-decoration: underline;
  }
  ol {
    padding-left: 1.25rem;
  }
`;
const PrivacyContainer = styled.div`
  width: 100vw;
  min-height: 70vh;
  display: flex;
  justify-content: center;
`;

const PwaPrivacyScreen = (props) => {
  return (
    <>
      <Navbar />
      <PrivacyContainer>
        <StyledCard className="o-hidden border-0 shadow-lg my-5">
          <span className="title">Terms and Conditions</span>
          <ol type="1">
            <li className="text">
              By providing your personal information to the merchant merchant
              (the “Merchant,” “Merchant”, or “Business”) using technology
              powered by Omnicart Holding Inc. (“Omnicart,” “we,” “us,” or
              “our”) when you visit or use merchant branded websites and branded
              mobile applications powered by Omnicart (collectively, the
              “Services”), and any other website or mobile application that
              links to these Terms of Use and our Privacy Policy (collectively,
              the “Agreement”), you acknowledge that you have read, understood,
              and agree to be legally bound by the terms and conditions set
              forth. If you do not agree to any of these terms, then please do
              not use the Services.
            </li>
            <li className="text">
              All Services or material on this Site are copyrighted and belong
              to Omnicart and its respective merchant and merchant partners. No
              part of this web site may be reproduced, published, distributed,
              displayed, performed, copied or stored for public or private use
              in any information retrieval system, or transmitted in any form by
              any mechanical, photographic or electronic process, including
              electronically or digitally on the Internet or World Wide Web, or
              over any network, or local area network, without express written
              permission of the Merchant and Omnicart.
            </li>
            <li className="text">
              This Site may contain links to other sites (such as those of other
              merchants) not maintained by the Merchant or Omnicart. These links
              are provided as a service to users and are not sponsored by or
              affiliated with this web site, mobile application, or other
              services powered by Omnicart and may not be governed by our
              policies.
            </li>
          </ol>
          <span className="title">Privacy Policy</span>
          <ol type="1">
            <li className="text">
              Personal information that we collect from you may include your
              first name, last name, email address, telephone, password for
              account creation purposes, and address for the purpose of Contact
              Tracing for COVID-19. By providing us with your personal
              information, you consent to our collection, use and disclosure of
              that information as described in this Privacy Policy. If you do
              not want your personal information to be collected, used or
              disclosed in this manner, please do not submit your personal
              information to us.
            </li>
            <li className="text">
              The Merchant and its online platform partner Omnicart do not and
              will not give or sell any personal information that you provide to
              us to any third parties except with your prior permission, as
              disclosed in this policy, as may be permitted or required by law.
            </li>
            <li className="text">
              The Merchant and its online platform partner Omnicart may use your
              personal information for contacting you about products and
              services, special offers and upcoming promotions from our
              affiliates and merchants you have visited, and other marketing
              partners of interest that we think may be of interest to you for
              the purposes of marketing. If at any time you choose to opt out of
              receiving such products and services, special offers and upcoming
              promotions, you will be able to do so.
            </li>
            <li className="text">
              Any personal information that you provide to us will only be
              retained in accordance with this policy only for as long as may be
              necessary or relevant for the purpose of collection or as may be
              required or permitted by law, after which time it will be
              destroyed or made anonymous unless you further consent to its
              continued retention. Personal information will be stored if a user
              creates an account, for the purposes of accessing a customer's
              order history, and to provide the customer with the best service.
              This personal data is obtained strictly for business purposes in
              order to provide the best customer service, and the Merchant, its
              online platform partner Omnicart, and its affiliates will take
              reasonable steps to protect this personal information in
              accordance with this privacy policy and applicable law.
            </li>
            <li className="text">
              The Merchant and its online platform partner Omnicart cannot
              guarantee the confidentiality or security of any information sent
              by you to us via the Site. The Omnicart Site does not use cookies
              or automatically collect personal information from any visitors.
            </li>
          </ol>
          <span className="title">General</span>
          <ol type="1">
            <li className="text">
              Omnicart reserves the right to modify or supplement the above
              policies at any time. If a material change to the Terms and
              Conditions or the Privacy Policy is made, Omnicart will post a
              notice on its website notifying all customers of such change.
              Should you have any suggestions or concerns regarding any of our
              policies or any changes to any of our policies, please contact us
              via the "Contact Us" section of our website.
            </li>
            <li className="text">
              This policy is governed by the laws of the Province of British
              Columbia and the laws of Canada applicable therein.
            </li>
          </ol>
        </StyledCard>
      </PrivacyContainer>
    </>
  );
};

export default PwaPrivacyScreen;
