import styled from "styled-components";
import { Colors } from "assets/colors";

export const StyledWrapper = styled.div`
  width: 272px;
  position: fixed;
  background: #f7f9fa;
  padding: 24px 12px 28px;
  border-right: 1px solid #d8dce2;
  z-index: 999;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .logo {
    margin-bottom: 24px;
    cursor: pointer;
    margin-left: 8px;
  }

  .link-wrapper {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 8px;
    background: transparent;
    cursor: pointer;
    color: ${Colors.primaryText1};

    &:hover {
    }

    .icon {
      width: 24px;
      height: 24px;
    }
  }

  .active-tab {
    .link-wrapper {
      background: ${`${Colors.primary1}25`};
      color: ${Colors.primary1};
      border-radius: 5px;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      cursor: pointer;
    }
  }

  a {
    text-decoration: none;
    position: relative;

    &:hover {
      text-decoration: none;
    }
  }

  .sections {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }

  .section {
    margin-bottom: 24px;

    &--bottom {
      margin-bottom: 0;
    }
  }

  .notif {
    min-width: 15px;
    height: 15px;
    border-radius: 20px;
    background: #f64646;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 11px;
    line-height: 14px;
    padding: 3px;

    &-badge {
      margin-left: 8px;
    }
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;
