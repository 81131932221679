import React, { useEffect, useState } from "react";

const RealTime = ({ renderTime, interval }) => {
  const [time, setTime] = useState(renderTime());
  const [intervalId, setIntervalId] = useState(null)

  useEffect(() => {
    if(intervalId) {
      clearInterval(intervalId)
    }
    const id = setInterval(() => {
      setTime(renderTime());
    }, interval);
    setIntervalId(id)

    return () => {
      clearInterval(intervalId);
    };
  }, [renderTime]);

  return <React.Fragment>{time}</React.Fragment>;
};

export default RealTime;
