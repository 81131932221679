import React from "react";
import { StyledWrapper } from "./styles";
import PropTypes from "prop-types";

/** A Radio group selection */

const RadioButtonGroup = ({
  options,
  selectedOption,
  setSelectedOption,
  optionSameText,
  classes,
  labelClasses,
  checkmarkClasses,
  ...props
}) => {
  return (
    <StyledWrapper>
      <div className={`wrapper ${classes}`}>
        {options?.map((option) => {
          return (
            <div>
              <label
                onClick={() => {
                  setSelectedOption(option);
                }}
                className={`container ${labelClasses}`}
              >
                {option.title} {optionSameText}
                <input
                  type="radio"
                  onChange={() => {}}
                  checked={option.id === selectedOption?.id ? "checked" : ""}
                />
                <span className={`checkmark ${checkmarkClasses}`}></span>
              </label>
            </div>
          );
        })}
      </div>
    </StyledWrapper>
  );
};

RadioButtonGroup.propTypes = {
  options: PropTypes.array.isRequired,
  selectedOption: PropTypes.any.isRequired,
  setSelectedOption: PropTypes.func.isRequired,
  optionSameText: PropTypes.func,
  classes: PropTypes.string,
  labelClasses: PropTypes.string,
  checkmarkClasses: PropTypes.string,
};

export default RadioButtonGroup;
