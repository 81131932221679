import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import jsCookie from "js-cookie";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter,
} from "react-router-dom";
import { Col, Container, Row, Card, Form } from "react-bootstrap";
import styled from "styled-components";

import Navbar from "components/Layout/Navbar";
import { loginUser } from "actions/user";
import { makeAlert } from "actions";
import { login } from "api/auth";
import Loading from "components/Loading";
import { Colors } from "assets/colors";

const StyledCard = styled(Card)`
  /* background: red; */
  width: 70vw;
  padding: 2rem 3rem 2rem 2rem;
  @media (max-width: 575px) {
    width: 95vw;
    padding: 1rem 1.5rem 1rem 1rem;
  }
  .title {
    color: #000;
    font-family: Inter;
    font-weight: bold;
    font-size: 1.25rem;
    @media (max-width: 575px) {
      font-size: 1rem;
    }
  }
  .text {
    margin-top: 0.5rem;
    color: #000;
    font-family: Inter;
    font-weight: 400;
    font-size: 1rem;
    @media (max-width: 575px) {
      font-size: 0.8rem;
    }
  }
  .underline_header {
    text-decoration: underline;
    font-family: Inter;
    font-weight: 600;
  }
  .header {
    display: flex;
    align-self: center;
    color: #000;
    font-family: Inter;
    font-weight: 600;
  }
  .list_wrapper {
    color: #000;
    font-family: Inter;
    font-weight: 400;
    font-size: 1rem;
  }
`;
const PrivacyContainer = styled.div`
  width: 100vw;
  min-height: 70vh;
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
`;

const Terms = (props) => {
  return (
    <>
      <Navbar />
      <PrivacyContainer>
        <StyledCard className="o-hidden border-0 shadow-lg my-5">
          <span className="header">OmmniCart Terms of Use</span>
          <p style={{ marginTop: "3rem" }} className="text">
            OmmniCart Inc. <strong>(“OmmniCart”)</strong> OWNS AND OPERATES THE
            WEBSITE LOCATED AT{" "}
            <a href="https://www.omnicart.tech/">https://www.omnicart.tech/</a>{" "}
            <strong>(“Website”)</strong> AND PROVIDES THE PLATFORM AS A SERVICE
            PLATFORM DESCRIBED ON THE WEBSITE (“SERVICE”). THESE TERMS OF USE
            APPLY TO ALL USERS OF THE WEBSITE, INCLUDING USERS WHO UPLOAD ANY
            MATERIALS TO THE WEBSITE, USERS WHO USE SERVICES PROVIDED THROUGH
            THIS WEBSITE AND USERS WHO SIMPLY VIEW THE CONTENT ON OR AVAILABLE
            THROUGH THIS WEBSITE.
          </p>
          <p className="text">
            BY USING THIS WEBSITE OR SUBSCRIBING FOR THE SERVICE YOU INDICATE
            YOUR ACCEPTANCE OF THESE TERMS OF USE. IF YOU DO NOT ACCEPT THESE
            TERMS OF USE, THEN DO NOT USE THE OMNICART SERVICE OR THIS WEBSITE
            OR ANY OF ITS CONTENT OR SERVICES. THESE TERMS OF USE MAY BE AMENDED
            OR UPDATED BY OMNICART FROM TIME TO TIME WITHOUT NOTICE AND THE
            TERMS OF USE MAY HAVE CHANGED SINCE YOUR LAST VISIT TO THIS WEBSITE.
            IT IS YOUR RESPONSIBILITY TO REVIEW THESE TERMS OF USE FOR ANY
            CHANGES. YOUR USE AFTER ANY AMENDMENTS OR UPDATES OF THESE TERMS OF
            USE SHALL SIGNIFY YOUR ASSENT TO AND ACCEPTANCE OF SUCH REVISED
            TERMS. ANY NEW FEATURES THAT MAY BE ADDED TO THIS WEBSITE FROM TIME
            TO TIME WILL BE SUBJECT TO THESE TERMS OF USE, UNLESS STATED
            OTHERWISE. YOU SHOULD VISIT THIS PAGE PERIODICALLY TO REVIEW THESE
            TERMS OF USE.
          </p>
          <ol className="list_wrapper">
            <li>
              <p className="text">
                <span className="underline_header">The Service. </span>You may
                subscribe for the Service through the Website or by executing an
                order form provided by OmmniCart. The Services may include
                additional service such as professional services where expressly
                provided .for under an order form agreed to by you and
                OmmniCart.
              </p>
            </li>
            <li>
              <p className="text">
                <span className="underline_header">License of Use. </span>Except
                for User Content, the OmmniCart Service, this Website, and the
                Service and the information and materials that it contains, are
                the property of OmmniCart and its licensors, and are protected
                from unauthorized copying and dissemination by copyright law,
                trademark law, and other intellectual property laws. Subject to
                the terms of this Agreement, where you subscribe for the Service
                then OmmniCart grants you a non-transferable, non-exclusive,
                license to use the Services for your internal business purposes
                on the terms of such subscription (the “<strong>License</strong>
                ”). The OmmniCart Service is licensed to you and not sold.
                Nothing in the Terms gives you a right to use the OmmniCart or
                OmmniCart names, trademarks, logos, domain names, and other
                distinctive brand features without our prior written consent.
                You shall not attempt to override or circumvent any of the usage
                rules or restrictions on the Website. Any future release,
                update, or other addition to functionality of the Website or
                OmmniCart Service shall be subject to the terms of these Terms.
              </p>
            </li>
            <li>
              <p className="text">
                <span className="underline_header">Content. </span>All
                information, data, text, software, music, sound, photographs,
                graphics, video, messages or other materials, whether publicly
                posted or privately transmitted to the Website or through the
                Services by viewers or users whether in forums or otherwise (“
                <strong>User Content</strong>”), is the sole responsibility of
                such viewers or users. This means that the viewer or user, and
                not OmmniCart, are entirely responsible for all such material
                uploaded, posted, emailed, transmitted or otherwise made
                available by using the Service. OmmniCart does not control or
                actively monitor User Content and, as such, does not guarantee
                the accuracy, integrity or quality of such content. Users
                acknowledge that by using the Service, they may be exposed to
                materials that are offensive, indecent or objectionable. Under
                no circumstances will OmmniCart be liable in any way for any
                materials, including, but not limited to, for any errors or
                omissions in any materials or any defects or errors in any
                printing or manufacturing, or for any loss or damage of any kind
                incurred as a result of the viewing or use of any materials
                posted, emailed, transmitted or otherwise made available via the
                Service.
              </p>
            </li>
            <li>
              <p className="text">
                <span className="underline_header">
                  Restrictions on User Content and Use of the Service.{" "}
                </span>{" "}
                OmmniCart reserves the right at all times (but will have no
                obligation) to remove or refuse to distribute any User Content
                and to terminate users or reclaim usernames. We also reserve the
                right to access, read, preserve, and disclose any information as
                we reasonably believe is necessary to (i) satisfy any applicable
                law, regulation, legal process or governmental request, (ii)
                enforce these Terms of Use, including investigation of potential
                violations hereof, (iii) detect, prevent, or otherwise address
                fraud, security or technical issues, (iv) respond to user
                support requests, or (v) protect the rights, property or safety
                of our users and the public.{" "}
              </p>
            </li>
            <p className="text">
              In using the Website, OmmniCart Service You shall not:
            </p>
            <ol type="a">
              <li>
                <p className="text">
                  copy any content unless expressly permitted to do so herein;
                </p>
              </li>
              <li>
                <p className="text">
                  upload, post, email, transmit or otherwise make available any
                  material that:
                </p>
              </li>
              <ol type="i">
                <li>
                  <p className="text">
                    is unlawful, harmful, threatening, abusive, harassing,
                    tortuous, defamatory, vulgar, obscene, pornographic,
                    libelous, invasive of another's privacy, hateful, or
                    racially or ethnically objectionable, encourages criminal
                    behavior, gives rise to civil liability, violates any law,
                    or is otherwise objectionable;
                  </p>
                </li>
                <li>
                  <p className="text">
                    You do not have a right to make available under any law or
                    under a contractual relationship;
                  </p>
                </li>
                <li>
                  <p className="text">
                    infringes any patent, trademark, trade secret, copyright or
                    other proprietary rights of any party (including privacy
                    rights);
                  </p>
                </li>
                <li>
                  <p className="text">
                    is or contains unsolicited or unauthorized advertising,
                    solicitations for business, promotional materials, "junk
                    mail," "spam," "chain letters," "pyramid schemes," or any
                    other form of solicitation;
                  </p>
                </li>
                <li>
                  <p className="text">
                    contains software viruses or any other computer code, files
                    or programs designed to interrupt, destroy or limit the
                    functionality of any computer software or hardware or
                    telecommunications equipment or data or the Website or that
                    of any users or viewers of the Website or that compromises a
                    user’s privacy; or
                  </p>
                </li>
                <li>
                  <p className="text">
                    contains any falsehoods or misrepresentations or create an
                    impression that You know is incorrect, misleading, or
                    deceptive, or any material that could damage or harm minors
                    in any way;
                  </p>
                </li>
              </ol>
              <li>
                <p className="text">
                  impersonate any person or entity or misrepresent their
                  affiliation with a person or entity;{" "}
                </p>
              </li>
              <li>
                <p className="text">
                  forge headers or otherwise manipulate identifiers in order to
                  disguise the origin of any material transmitted to or through
                  the Website or impersonate another person or organization;{" "}
                </p>
              </li>
              <li>
                <p className="text">
                  interfere with or disrupt the Website or servers or networks
                  connected to the Website, or disobey any requirements,
                  procedures, policies or regulations of networks connected to
                  the Website or probe, scan, or test the vulnerability of any
                  system or network or breach or circumvent any security or
                  authentication measures;{" "}
                </p>
              </li>
              <li>
                <p className="text">
                  intentionally or unintentionally violate any applicable local,
                  state, national or international law or regulation;{" "}
                </p>
              </li>
              <li>
                <p className="text">
                  collect or store personal data about other users or viewers;{" "}
                </p>
              </li>
              <li>
                <p className="text">
                  license, sell, rent, lease, transfer, assign, distribute,
                  host, or otherwise commercially exploit the Website or
                  OmmniCart Service;{" "}
                </p>
              </li>
              <li>
                <p className="text">
                  modify, translate, make derivative works of, disassemble,
                  decompile, reverse compile or reverse engineer any part of the
                  OmmniCart Service or any software provided as part of the
                  Website, except to the extent the foregoing restrictions are
                  expressly prohibited by applicable law; or{" "}
                </p>
              </li>
              <li>
                <p className="text">
                  register for the Service on behalf of a group or corporate
                  entity.{" "}
                </p>
              </li>
            </ol>
            <p className="text">
              You also agree not to access the Website in a manner that utilizes
              the resources of the Website more heavily than would be the case
              for an individual person using a conventional web browser.
              Notwithstanding the foregoing, operators of public search engines
              may use spiders or other bots for the purpose of creating publicly
              available searchable indices of the materials on this Website.{" "}
            </p>
            <li>
              <p className="text">
                <span className="underline_header">License of Content. </span>{" "}
                By submitting, posting or displaying User Content on or through
                the Service, you grant us (and our agents) a non-exclusive,
                royalty-free license (with the right to sublicense) to use,
                copy, modify, transmit, display and distribute such User Content
                . OmmniCart will not be responsible or liable for any use of
                User Content in accordance with these Terms. You represent and
                warrant that you have all the rights, power and authority
                necessary to grant the rights granted herein to any User Content
                that you submit.
              </p>
            </li>
            <li>
              <p className="text">
                <span className="underline_header">PAYMENT TERMS </span>{" "}
              </p>
              <ol type="a">
                <li>
                  <p className="text">
                    <span className="underline_header">Fees. </span> Services
                    are purchased for subscription terms with payment for the
                    specific period being due and payable in advance . There are
                    no charges for set-up or basic support. 
                  </p>
                </li>
                <li>
                  <p className="text">
                    <span className="underline_header">
                      Payment & Charges.{" "}
                    </span>{" "}
                    Your access to and use of the Services is subject to Your
                    payment of the applicable fees due for the Services selected
                    by You ("Fees") set out on the Website and all other
                    applicable amounts, charges and taxes indicated to You when
                    You purchase Services (or otherwise notified to You by
                    OmmniCart from time to time) when you use the Service or
                    noted on and viewable in Your account profile. Unless
                    otherwise stated, all invoiced amounts are due upon receipt
                    and processed immediately using the credit card on file for
                    You. Receipts for purchased Services will be delivered to
                    You electronically. You shall: (i) keep the billing, credit
                    card and payment information You provide to OmmniCart or its
                    suppliers or payment processors, including name, credit card
                    number and expiry date, mailing address, email address and
                    telephone number, accurate and up to date; otherwise, we may
                    suspend the Services; (ii) promptly advise OmmniCart if Your
                    credit card information changes due to loss, theft,
                    cancellation or otherwise; (iii) be liable for Your failure
                    to pay any Fees billed to You by OmmniCart caused by Your
                    failure to provide OmmniCart with up to date billing
                    information. To offset its additional processing costs,
                    OmmniCart may charge You for administrative charges as set
                    from time to time for administrative or account activities
                    including: collection efforts due to non-payment or having a
                    balance over Your credit limit; returned or rejected
                    payments; or changes in personal identifier information. All
                    administrative charges charged to You will be indicated to
                    You on Your invoice or receipt and You shall pay all such
                    charges.{" "}
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p className="text">
                <span className="underline_header">Feedback. </span>If You
                provide OmmniCart with any suggestions, comments or other
                feedback relating to any aspect of the Website and/or Service ("
                <strong>Feedback</strong>"), OmmniCart may use such Feedback in
                the Website or in any other OmmniCart products or services
                (collectively, "<strong>OmmniCart Offerings</strong>").
                Accordingly, You agree that: (a) OmmniCart is not subject to any
                confidentiality obligations in respect to the Feedback, (b) the
                Feedback is not confidential or proprietary information of You
                or any third party and You have all of the necessary rights to
                disclose the Feedback to OmmniCart, (c) OmmniCart (including all
                of its successors and assigns and any successors and assigns of
                any of the OmmniCart Offerings) may freely use, reproduce,
                publicize, license, distribute, and otherwise commercialize
                Feedback in any OmmniCart Offerings, and (d) You are not
                entitled to receive any compensation or re-imbursement of any
                kind from OmmniCart or any of the other users of the Website in
                respect of the Feedback.
              </p>
            </li>
            <li>
              <p className="text">
                <span className="underline_header">Advertising. </span>You
                acknowledge and agree that the Website may contain
                advertisements. If You elect to have any business dealings with
                anyone whose products or services may be advertised on the
                Website, You acknowledge and agree that such dealings are solely
                between You and such advertiser and You further acknowledge and
                agree that OmmniCart shall not have any responsibility or
                liability for any losses or damages that You may incur as a
                result of any such dealings. You shall be responsible for
                obtaining access to the Website and acknowledge that such access
                may involve third-party fees (such as Internet service provider
                access or data fees). You shall be solely responsible for any
                such fees and also for obtaining any equipment that is required
                to access the Website. It is your responsibility to ascertain
                whether any information or materials downloaded from the Website
                are free of viruses, worms, Trojan Horses, or other items of a
                potentially destructive nature.
              </p>
            </li>
            <li>
              <p className="text">
                <span className="underline_header">
                  Links & Third-Party Websites.{" "}
                </span>
                This Website (including User Content) may contain links to other
                websites that are not owned or controlled by OmmniCart. In no
                event shall any reference to any third party, third party
                product or service be construed as an approval or endorsement by
                OmmniCart of that third party, third party product or service.
                OmmniCart is also not responsible for the content of any linked
                websites. Any third-party websites or services accessed from the
                Website are subject to the terms and conditions of those
                websites and or services and You are responsible for determining
                those terms and conditions and complying with them. The presence
                on the Website of a link to any other website(s) does not imply
                that OmmniCart endorses or accepts any responsibility for the
                content or use of such websites, and You hereby release
                OmmniCart from all liability and/damages that may arise from
                Your use of such websites or receipt of services from any such
                websites.
              </p>
            </li>
            <li>
              <p className="text">
                <span className="underline_header">
                  DISCLAIMER OF REPRESENTATIONS, WARRANTIES AND CONDITIONS.{" "}
                </span>
                THE WEBSITE, SERVICE AND ALL MATERIALS PROVIDED THEREIN ARE
                PROVIDED "AS IS." OMNICART SPECIFICALLY DISCLAIMS ALL
                REPRESENTATIONS, WARRANTIES AND CONDITIONS, EITHER EXPRESS,
                IMPLIED, STATUTORY, BY USAGE OF TRADE, COURSE OF DEALING OR
                OTHERWISE INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF
                MERCHANTABILITY, NON-INFRINGEMENT, TITLE, SATISFACTORY QUALITY
                OR FITNESS FOR A PARTICULAR PURPOSE. ANY INFORMATION OR MATERIAL
                DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE
                IS AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE SOLELY
                RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM, LOSS OF
                DATA, OR ANY OTHER LOSS THAT RESULTS FROM DOWNLOADING OR USING
                ANY SUCH MATERIAL. OMNICART DOES NOT WARRANT, ENDORSE,
                GUARANTEE, PROVIDE ANY CONDITIONS OR REPRESENTATIONS, OR ASSUME
                ANY RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR
                OFFERED BY ANY THIRD PARTY THROUGH THE WEBSITE OR IN RESPECT TO
                ANY WEBSITE THAT CAN BE REACHED FROM A LINK ON THE WEBSITE OR
                FEATURED IN ANY BANNER OR OTHER ADVERTISING ON THE WEBSITE, AND
                OMNICART SHALL NOT BE A PARTY TO ANY TRANSACTION THAT YOU MAY
                ENTER INTO WITH ANY SUCH THIRD PARTY. OMNICART WILL NOT BE
                LIABLE FOR ANY TYPE OF CONTENT EXCHANGED BY MEANS OF THE
                SERVICE.
              </p>
            </li>
            <li>
              <p className="text">
                <span className="underline_header">
                  LIMITATION OF LIABILITY.{" "}
                </span>
                UNDER NO CIRCUMSTANCES SHALL OMNICART BE LIABLE FOR ANY DIRECT,
                INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY OR OTHER
                DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, ANY DAMAGES
                THAT RESULT FROM (I) YOUR USE OF OR YOUR INABILITY TO USE THIS
                WEBSITE, OMNICART APP OR THE SERVICE, (II) THE COST OF
                PROCUREMENT OF SUBSTITUTE GOODS, DATA, INFORMATION OR SERVICES,
                (III) ERRORS, MISTAKES, OR INACCURACIES IN THE MATERIALS ON THE
                WEBSITE, (IV) PERSONAL INJURY OR PROPERTY DAMAGE OF ANY KIND
                WHATSOEVER ARISING FROM OR RELATING TO YOUR USE OF THE SERVICE,
                ANY BUGS, VIRUSES, TROJAN HORSES, OR ANY OTHER FILES OR DATA
                THAT MAY BE HARMFUL TO COMPUTER OR COMMUNICATION EQUIPMENT OR
                DATA THAT MAY HAVE BEEN TRANSMITTED TO OR THROUGH THE WEBSITE OR
                OMNICART APP, OR (V) ANY ERRORS OR OMISSIONS IN ANY MATERIAL ON
                THE WEBSITE OR ANY OTHER LOSS OR DAMAGE OF ANY KIND ARISING FROM
                OR RELATING TO YOUR USE OF THE WEBSITE. THESE LIMITATIONS SHALL
                APPLY EVEN IF OMNICART HAS BEEN ADVISED OF THE POSSIBILITY OF
                SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED
                HEREIN, OMNICART’S LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM
                OR RELATED TO THIS AGREEMENT (FOR ANY CAUSE WHATSOEVER AND
                REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE
                LIMITED TO THE GREATER OF (A) FIFTY U.S. DOLLARS ($50) OR (B)
                AMOUNTS YOU’VE PAID OMNICART IN THE PRIOR 12 MONTHS (IF ANY)THE
                FOREGOING LIMITATIONS SHALL APPLY TO THE FULLEST EXTENSION
                PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.
              </p>
            </li>
            <li>
              <p className="text">
                <span className="underline_header">INDEMNIFICATION. </span>YOU
                SHALL INDEMNIFY AND HOLD OMNICART AND ITS SUBSIDIARIES,
                AFFILIATES, OFFICERS, AGENTS, AND EMPLOYEES, HARMLESS FROM ALL
                CLAIMS, ACTIONS, PROCEEDINGS, DEMANDS, DAMAGES, LOSSES, COSTS,
                AND EXPENSES (INCLUDING REASONABLE ATTORNEYS' FEES), INCURRED IN
                CONNECTION WITH ANY MATERIALS SUBMITTED, POSTED, TRANSMITTED OR
                MADE AVAILABLE BY YOU THROUGH THE SERVICE AND/OR ANY VIOLATION
                BY YOU OF THESE TERMS OF USE.
              </p>
            </li>
            <li>
              <p className="text">
                <span className="underline_header">Termination. </span>OmmniCart
                may, under certain circumstances and without prior notice,
                immediately terminate Your ability to access the Website or
                portions thereof. Cause for such termination shall include, but
                not be limited to, (a) breaches or violations of these Terms of
                Use or any other agreement that You may have with OmmniCart
                (including, without limitation, non-payment of any fees owed in
                connection with the website or otherwise owed by You to
                OmmniCart), (b) requests by law enforcement or other government
                agencies, (c) a request by You, (d) discontinuance or material
                modification to the website (or any part thereof), (e)
                unexpected technical, security or legal issues or problems,
                and/or (f) participation by You, directly or indirectly, in
                fraudulent or illegal activities. Termination of Your access to
                the Website may also include removal of some or all of the
                materials uploaded by You to the Website. You acknowledge and
                agree that all terminations may be made by OmmniCart in its sole
                discretion and that OmmniCart shall not be liable to You or any
                third-party for any termination of Your access to this Website
                or for the removal of any of the materials uploaded by You to
                the Website. Any termination of these terms of use by OmmniCart
                shall be in addition to any and all other rights and remedies
                that OmmniCart may have.
              </p>
            </li>
            <li>
              <p className="text">
                <span className="underline_header">
                  Availability & Updates.{" "}
                </span>
                OmmniCart may alter, suspend, or discontinue this Website at any
                time and for any reason or no reason, without notice. The
                Website and/or OmmniCart Service may be unavailable from time to
                time due to maintenance or malfunction of computer or network
                equipment or other reasons. OmmniCart may periodically add or
                update the information and materials on this Website without
                notice.{" "}
              </p>
            </li>
            <li>
              <p className="text">
                <span className="underline_header">Security. </span>Information
                sent or received over the Internet is generally unsecure and
                OmmniCart cannot and does not make any representation or
                warranty concerning security of any communication to or from the
                Website or any representation or warranty regarding the
                interception by third parties of personal or other information.
                You are responsible for safeguarding the password that you use
                to access the Service and you are responsible for any activities
                or actions under your password. You agree to keep your password
                secure. OmmniCart will not be liable for any loss or damage
                arising from your failure to comply with these requirements.
              </p>
            </li>
            <li>
              <p className="text">
                <span className="underline_header">General. </span>These Terms
                of Use, together with any privacy policy that may be published
                on the Website, constitutes the entire agreement between the
                parties relating to the Website and Service and all related
                activities. These terms of use shall not be modified except in
                writing signed by both parties or by a new posting of these
                terms of use issued by OmmniCart. If any part of these Terms of
                Use is held to be unlawful, void, or unenforceable, that part
                shall be deemed severed and shall not affect the validity and
                enforceability of the remaining provisions. The failure of
                OmmniCart to exercise or enforce any right or provision under
                these Terms of Use shall not constitute a waiver of such right
                or provision. Any waiver of any right or provision by OmmniCart
                must be in writing and shall only apply to the specific instance
                identified in such writing. You may not assign the terms of use,
                or any rights or licenses granted hereunder, whether
                voluntarily, by operation of law, or otherwise without
                OmmniCart’s prior written consent.{" "}
              </p>
            </li>
            <p className="text">
              If you have any questions about these Terms or if you wish to make
              any complaint or claim with respect to the OmmniCart Service,
              please contact us at:{" "}
              <strong>
                <a href="contact@omnicart.tech">contact@omnicart.tech</a>
              </strong>
            </p>
          </ol>
          <p className="text"> Effective Date: Jan 23, 2019 </p>
        </StyledCard>
      </PrivacyContainer>
    </>
  );
};
export default Terms;
