import styled from "styled-components";
import { Colors } from "assets/colors";

export const StyledTable = styled.div`
  

  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

.badge{
    border-radius: 4px;
    background-color: #F1F2F3;
    color: #505559;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: bold;
  }

  .table-category {
    background: #f1f3f4;
    border-radius: 0;
    width: 100%;
    height: 32px;
    padding: 10px 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    width: 100%;
    position: absolute;
    left: 0;
    top: 28px;
    border-top: 1px solid #e7e8e9;

    &--no-header {
      top: 12px;
    }

    &--left {
      display: flex;
      align-items: center;
      flex-direction: row;

      p {
        margin-bottom: 0;
      }
    }

    &-title {
      color: #4f566b;
      font-weight: 600;
font-size: 12px;
line-height: 18px;
    }

    &-range {
      color: #1a1f36;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      margin-left: 8px;
    }
  }

  .th-range {
     padding-bottom: 43px;


    &:before {
      display: none;
    }
  }

  .table-top {
    margin-bottom: 16px;
  }

  table {
    width: 100%;
    margin: 0;
    margin-top: 20px;

    th {
      font-weight: 600;
      font-size: 11px;
      line-height: 16px;
      height: 20px;
      border-bottom: none;
      letter-spacing: 0.06em;
text-transform: uppercase;
    }

    td {
      border-top: 1px solid #e7e8e9;
      border-bottom: none;
      min-height: 46px;
      padding: 13px 10px;
      vertical-align: top;

    }
  }

  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .name {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${Colors.textPrimary1};
    display: flex;
    flex-direction: row;
    align-items: center;

  }

  .status {
    &--new {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #9c400b;
      background: #f9e6d2;
      border-radius: 4px;
      padding: 4px 8px;
      display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 4px;
    width: 16px;
    height: 16px;
    }


  }
  &--scheduled {
      padding: 4px 6px;
    }

    &--accepted {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #045f5a;
      background: #deefef;
      border-radius: 4px;
      padding: 4px 8px;

      display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 8px;
    width: 16px;
    height: 16px;
    }
    }

    &--ready {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #0e623f;
      background: #cbf4c9;
      border-radius: 4px;
      padding: 4px 8px;
    }

    &--on-the-way {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #4b3a95;
      background: #e5dffc;
      border-radius: 4px;
      padding: 4px 8px;
    }

    &--completed {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #0e623f;
      background: #cbf4c9;
      border-radius: 4px;
      padding: 4px 8px;
    }
    
    &--delivered {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #0e623f;
      background: #cbf4c9;
      border-radius: 4px;
      padding: 4px 8px;
    }

    &--rejected {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: ${Colors.textPrimary1};
      background: #e7e8e9;
      border-radius: 4px;
      padding: 4px 8px;
    }

    &--cancelled {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #aa2720;
      background: #fae5e4;
      border-radius: 4px;
      padding: 4px 8px;
    }
  }

  .eta {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: ${Colors.textPrimary1};

    &--red {
      color: #c7322d;
    }

    &--first-row {
      font-weight: 600;
font-size: 12px;
line-height: 16px;
color: #707070;
display: block;
    }
   
    &--sec-row {
      font-weight: bold;
font-size: 14px;
line-height: 18px;
color: ${Colors.textPrimary1};
display: block;

    }
  }

  .order-type {
    border: 1px solid #dcdddd;
    width: 79px;
    height: 24px;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
  }

  .items {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${Colors.textPrimary1};

    &--more {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-decoration-line: underline;
      color: #707070;
    }
  }

  .net {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: ${Colors.textPrimary1};
  }

  .code {
    font-family: "Menlo" !important;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: ${Colors.textPrimary1};
  }

  .type-size {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${Colors.textPrimary1};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 4px;

    .icon--lead {
      margin-right: 4px;
    }

    .icon {
      width: 16px;
      height: 16px;

      svg {
        width: 16px;
    height: 16px;
    margin-top: -2px;
      }
    }
  }

  .new-row {
    background: #FFFBFB;
  }

  .new-badge {
    width: 6px;
height: 6px;
background: #F64646;
border-radius: 4px;
margin-right: 8px;
  }

`;
