import {
  faArrowsAlt,
  faCaretRight,
  faCheck,
  faPen,
  faPlus,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Spinner } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import styled from "styled-components";

import { editItem, moveItem } from "api/items";
import { editMenu, getMenuById } from "api/menus";
import Loading from "components/Loading";

import {
  createCategory,
  editCategory,
  removeCategory,
  reorderCategory,
} from "../../api/categories";
import { Colors } from "assets/colors";
import CircleButton from "components/button/CircleButton";
import ConfirmDeleteModal from "components/confirmDeleteModal";
import ItemCard from "components/Menu/Item";
import { AddMenuCard } from "components/Menu/MenuCard";
import { formatTime, getEndTime, immutableMove } from "../../utils";
import { getData } from "../../utils";
import { MenusTabs } from "../menus/Preview";

import "react-lazy-load-image-component/src/effects/blur.css";

const SContainer = styled(Container)`
  .action-btn {
    margin-left: 10px;
    border-radius: 100px;
    width: 30px;
    min-width: 30px;
    max-width: 30px;
    height: 30px;
    min-height: 30px;
    max-height: 30px;
    background: #fff;
    display: flex;
    border: none;
    align-items: center;
    justify-content: center;
    outline: none;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14),
      0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
  .no_menu_warning {
    font-size: 2rem;
    font-family: Inter;
    font-weight: 600;
    color: #4c4c4c;
    text-transform: uppercase;
  }
`;

const WeekDay = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  cursor: pointer;
  background-color: ${(p) => (p.active ? Colors.primary1 : "#828282")};
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: ${(p) => (p.active ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : null)};
`;

const SCard = styled(Card)`
  background: #f1f1f1;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.12);
  padding: 25px;

  @media (max-width: 1350px) {
    width: 400px;
  }

  .header {
    margin-bottom: 30px;
  }

  .title {
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.15px;
    color: #121212;
    margin-bottom: 0;
  }

  .price {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.87);
  }

  .action-btn {
    margin-left: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    border: none;
    align-items: center;
    outline: none;
    justify-content: center;
  }
`;
const SCategory = styled.div`
  background: ${(p) => (p.active ? "#F1F1F1" : `#FFFFFF`)};
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.12);
  border: none;
  width: 100%;
  padding-right: 25px;
  padding-left: 25px;
  h5 {
    margin: 0;
  }
  .button {
    border: none;
    outline: none;
    background: ${(p) => (p.active ? "#F1F1F1" : `#FFFFFF`)};
    padding: 25px;
    padding-right: 0;
    padding-left: 0;
    width: 100%;
    text-align: left;
    height: 100%;
    .title_wrapper {
      display: flex;
      flex-direction: column;
      .title {
      }
      .text {
        color: rgba(0, 0, 0, 0.87);
        font-size: 14px;
      }
    }
  }
  .title {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
`;
const SItem = styled.div`
  background: ${Colors.white};
  display: flex;
  opacity: ${(p) => (p.deleting ? 0.5 : 1)}
  flex-direction: row;
  ${(p) => p.loading && `align-items: center;`}
  justify-content: space-between;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
  display: flex;
  color: #373636;
  flex: 0 21%;
  .form-group {
    margin: 0;
  }
  .remove-item {
        border: none;
        padding: 10px;
        display: flex;
        background: transparent;
        outline: none;
  }
  .title {
    font-weight: bold;
    text-align: left;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }

  .lazy-load-image-background {
  }

  .price {
    // margin: 5px 0;
    // padding-bottom: 5px;
  }

  .body {
    cursor: pointer;
    display: flex;
    width: 100%;
    padding: 10px;
    flex-direction: column;
    justify-content: space-between;
}
  }

  .description {
    font-size: 15px;
    font-size: 15px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  img {
    height: 80px;
    object-fit: cover;
    width: 100px;
  }
  .button {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    background: ${(p) => (!p.paused ? "#4BB43A" : "#E45628")};
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 28px;
  }
`;

const ReorderingHandle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  &:hover {
    cursor: pointer;
  }
`;

const Category = ({
  selected,
  c,
  handleSelect,
  add,
  menuID,
  handleRemoveCategory,
  editing,
  deleteLoading,
  ...props
}) => {
  const [name, setName] = useState(c?.name);
  const [description, setDescription] = useState(c?.description);
  const [createLoading, setCreateLoading] = useState(false);

  const handleAddNewCategory = (event) => {
    event.preventDefault();
    setCreateLoading(true);
    if (!name) return;
    props
      .createCategory({
        name,
        menu: menuID,
        items_positions: [0],
      })
      .then((res) => {
        setCreateLoading(false);
        if (getData(res)?.id) {
          props.onAddNewCategory(getData(res));
          setName("");
        } else {
          console.log("error in creating with wrong status");
        }
      })
      .catch((e) => {
        setCreateLoading(false);
        console.log("error in create new category", e);
      });
  };

  const handleEditCategory = (event) => {
    event.preventDefault();
    props.handleEditCategory(null, c.id, { name });
  };

  if (add) {
    return (
      <SCategory className="d-flex align-items-center justify-content-between py-4">
        <Form onSubmit={handleAddNewCategory}>
          <Form.Group style={{ margin: 0 }}>
            <Form.Control
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoFocus
              type="text"
              placeholder="Category Name..."
            />
          </Form.Group>
        </Form>
        <CircleButton
          onClick={name && handleAddNewCategory}
          style={{
            width: 40,
            height: 40,
          }}
        >
          {createLoading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <FontAwesomeIcon icon={faPlus} />
          )}
        </CircleButton>
      </SCategory>
    );
  }

  if (!c) return null;
  return (
    <Draggable draggableId={c.id} index={props.index}>
      {(provided, snapshot) => (
        <SCategory
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          active={c.id === selected?.id}
          className="d-flex align-items-center justify-content-between"
        >
          <ReorderingHandle>
            <FontAwesomeIcon icon={faArrowsAlt} />
          </ReorderingHandle>
          <button className="button" onClick={() => handleSelect(c.id)}>
            {false ? (
              <Form onSubmit={handleEditCategory}>
                <Form.Group style={{ margin: 0 }}>
                  <Form.Control
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoFocus
                    type="text"
                    placeholder="Category Name..."
                  />
                </Form.Group>
              </Form>
            ) : (
              <div>
                <h5 className="title">{c.name}</h5>
                <p
                  className="text-muted"
                  style={{
                    margin: 0,
                  }}
                >
                  ({c.items.length} Items)
                </p>
              </div>
            )}
          </button>
          <div className="d-flex align-items-end">
            {true ? (
              selected?.id !== c.id ? (
                <>
                  <button
                    className="action-btn"
                    onClick={() => props.setEditingCategory(c.id)}
                  >
                    <FontAwesomeIcon icon={faPen} color="#7B7B7B" />
                  </button>
                  <button
                    className="action-btn"
                    onClick={() =>
                      props.setConfirmDeleteModal({ id: c.id, selected: false })
                    }
                  >
                    {deleteLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <FontAwesomeIcon icon={faTrash} color="#7B7B7B" />
                    )}
                  </button>
                </>
              ) : (
                <FontAwesomeIcon
                  icon={faCaretRight}
                  size="2x"
                  color="#7B7B7B"
                  // onClick={() => handleDuplicate(id)}
                />
              )
            ) : null}
          </div>
        </SCategory>
      )}
    </Draggable>
  );
};

const Item = ({ item, category, menu, index, ...props }) => {
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <SItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          paused={!item.is_active}
          className={`step2`}
          key={item.id}
        >
          <ReorderingHandle
            style={{
              paddingLeft: 10,
            }}
          >
            <FontAwesomeIcon icon={faArrowsAlt} />
          </ReorderingHandle>
          <LazyLoadImage
            src={
              (item.images[0] && item.images[0].image_med) ||
              "https://1d4au92ejd571ijfyy1m3feh-wpengine.netdna-ssl.com/wp-content/themes/velocity/images/no-image-found-360x250.png"
            }
            effect="blur"
          />
          <Link
            className="body"
            to={{
              pathname: `/console/create-items/edit/${item.id}`,
              state: "edit",
              _back: `create-menus/view/${menu}`,
              _backParams: { category },
            }}
            style={{
              textDecoration: "none",
              color: "rgb(55, 54, 54)",
            }}
          >
            <span className="title">{item.name}</span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <span className="price">${item.value}</span>
            </div>
          </Link>
          <button className="remove-item">
            {props.removeItemLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <FontAwesomeIcon
                icon={faTimes}
                color="#7B7B7B"
                onClick={() => props.handleRemove(item, category)}
              />
            )}
          </button>
        </SItem>
      )}
    </Draggable>
  );
};

const Header = styled.div`
  background: #fafafa;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  padding: 15px 25px;
  margin-bottom: 4rem;

  h3 {
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const WEEKDAYS = [
  { id: 7, title: "Su" },
  { id: 1, title: "M" },
  { id: 2, title: "Tu" },
  { id: 3, title: "W" },
  { id: 4, title: "Th" },
  { id: 5, title: "F" },
  { id: 6, title: "Sa" },
];

const ViewMenu = (props) => {
  const [menu, setMenu] = useState({});
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState({});
  const [published, setPublished] = useState(props.is_active);
  const [showNewCategory, setShowNewCategory] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);
  const [time, setTime] = useState({ start: "", end: "" });
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteSelectedLoading, setDeleteSelectedLoading] = useState(false);
  const [noMenu, setNoMenu] = useState(false);
  const [removeItemLoading, setRemoveItemLoading] = useState(false);
  const [repositionLoading, setRepositionLoading] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

  useEffect(() => {
    window.addEventListener("beforeunload", beforeunload);

    return () => {
      window.removeEventListener("beforeunload", beforeunload);
    };
  }, [repositionLoading]);

  const beforeunload = (e) => {
    if (repositionLoading) {
      e.preventDefault();
      e.returnValue = true;
    }
  };

  let menuID = props.location.pathname.split("/").slice(-1)[0];
  if (menuID === "") menuID = props.location.pathname.split("/").slice(-2)[0];

  const selectedCategory = props.location.search?.split("?category=")[1];

  useEffect(() => {
    if (
      props.selectedRestaurant &&
      (props.permissions.includes("__panel__") ||
        props.permissions.includes("menus"))
    ) {
      props
        .getMenuById(menuID)
        .then((res) => {
          let categories = getData(res)
            ?.categories.sort((a, b) => a.position - b.position)
            .map((c) => getSortedItems(c));

          setNoMenu(false);
          setMenu({
            ...getData(res),
            categories,
          });
          setPublished(getData(res)?.is_active);
          let category = categories[0];
          setSelected(category);
          if (getData(res)?.active_hours) {
            setTime({
              start: formatTime(
                getData(res)?.active_hours &&
                  getData(res)?.active_hours[0]?.from_hour
              ),
              end: formatTime(
                getData(res)?.active_hours &&
                  getData(res)?.active_hours[0]?.from_hour,
                getData(res)?.active_hours[0]?.duration
              ),
            });
          }
          if (selectedCategory)
            setSelected(
              categories.find((c) => c.id === parseInt(selectedCategory))
            );
          setLoading(false);
        })
        .catch((e) => {
          setNoMenu(true);
          console.log("error in opening menu", e);
          setLoading(false);
        });
    }
  }, []);

  const getSortedItems = (category) => {
    if (!category) return category;
    let items = category.items;
    items = items.sort((a, b) => {
      let itemA = category.items.find((el) => el.id === a.id);
      let itemPositionA =
        itemA &&
        itemA.item_position.find((pos) => pos.category === category.id);
      itemPositionA = itemPositionA ? itemPositionA.position : 0;

      let itemB = category.items.find((el) => el.id === b.id);
      let itemPositionB =
        itemB &&
        itemB.item_position.find((pos) => pos.category === category.id);
      itemPositionB = itemPositionB ? itemPositionB.position : 0;

      return itemPositionA - itemPositionB;
    });
    return { ...category, items };
  };

  const handleCategoryDragEnd = (result) => {
    let { destination, source, draggableId } = result;
    /**  */
    if (!destination) return;

    /** no change */
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    let categories = immutableMove(
      menu.categories,
      source.index,
      destination.index
    );
    setMenu({ ...menu, categories });
    setRepositionLoading(true);
    props.reorderCategory(draggableId, destination.index + 1).then((res) => {
      setRepositionLoading(false);
    });
  };

  const handleItemDragEnd = (result) => {
    let { destination, source, draggableId } = result;

    /**  */
    if (!destination) return;

    /** no change */
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    /** just change ordering in one column */
    if (destination.droppableId === source.droppableId) {
      setRepositionLoading(true);
      props
        .moveItem(draggableId, destination.index + 1, selected.id)
        .then((res) => {
          setRepositionLoading(false);
        });

      let items = immutableMove(
        selected.items,
        source.index,
        destination.index
      );
      let newSelected = { ...selected, items };
      let newCategories = menu.categories.map((c) => {
        if (c.id !== selected.id) return c;
        return newSelected;
      });
      setMenu({ ...menu, categories: newCategories });
      setSelected(newSelected);
      return;
    }
  };

  const handleSelect = (id) => {
    setEditingCategory(null);
    let category = menu.categories.find((c) => c.id === id);
    // category = getSortedItems(category)
    setSelected(category);
  };

  const handleChangePublish = (e) => {
    setPublished(e);
    props.editMenu(menuID, { is_active: e }).then((res) => {
      setPublished(e);
    });
  };

  const handleAddNewCategory = (c) => {
    props.reorderCategory(c.id, 1);
    setMenu((menu) => ({ ...menu, categories: [c, ...menu.categories] }));
    setShowNewCategory(false);
    setSelected(c);
  };

  const handleSetEditingCategory = (id) => {
    handleSelect(id);
    setEditingCategory(id);
  };

  const handleEditCategory = (event, id, data) => {
    props.editCategory(id, data).then((res) => {
      let newCategories = menu.categories.map((c) => {
        if (c.id !== id) return c;
        return { ...c, ...data };
      });
      setMenu((menu) => ({ ...menu, categories: newCategories }));
      setEditingCategory(false);
    });
  };

  const handleRemoveCategory = (id, selected) => {
    setConfirmDeleteModal(false);
    if (selected) {
      setDeleteSelectedLoading(true);
    } else {
      setDeleteLoading(id);
    }
    props
      .removeCategory(id)
      .then((res) => {
        setDeleteLoading(false);
        setDeleteSelectedLoading(false);
        let newCategories = menu.categories.filter((c) => c.id !== id);
        setMenu((menu) => ({ ...menu, categories: newCategories }));
        setSelected(newCategories[0]);
      })
      .catch((e) => {
        setDeleteLoading(false);
        setDeleteSelectedLoading(false);
        console.log("error in deleting category", e);
      });
  };

  const handleSelectedChange = (e) => {
    const { name, value } = e.target;
    setSelected((selected) => ({ ...selected, [name]: value }));
  };

  const handleRemoveItemFromCategory = (item, category) => {
    // console.log(item);
    let newCategories = item.category.filter((el) => el !== category);
    let newItem = {
      category: newCategories,
      name: item.name,
      value: item.value,
    };
    setRemoveItemLoading(item.id);
    props
      .editItem(item.id, newItem)
      .then((res) => {
        setRemoveItemLoading(false);
        setMenu((menu) => ({
          ...menu,
          categories: menu.categories.map((c) => {
            if (c.id !== category) return c;
            let items = c.items.filter((i) => i.id !== item.id);
            c.items = items;
            return c;
          }),
        }));
      })
      .catch((e) => {
        setRemoveItemLoading(false);
        console.log("error in deleting item", e);
      });
  };

  if (noMenu) {
    return (
      <SContainer>
        <Row>
          <Col
            md={12}
            style={{
              paddingTop: 200,
              paddingBottom: 25,
            }}
            className="d-flex align-center justify-content-center"
          >
            <span className="no_menu_warning">Menu Not Found</span>
          </Col>
        </Row>
      </SContainer>
    );
  }

  return (
    <SContainer>
      <Row>
        <Col
          md={12}
          style={{
            paddingTop: 100,
            paddingBottom: 25,
          }}
        >
          {!loading ? (
            <>
              <Header>
                <h3>{menu.name}</h3>
                <div className="d-flex align-items-center">
                  <Switch
                    className="mr-2"
                    onChange={handleChangePublish}
                    checked={published}
                    handleDiameter={18}
                    height={24}
                    offColor="#E45628"
                    uncheckedIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          color: "#fff",
                          fontSize: 14,
                          paddingRight: 2,
                          fontWeight: "bold",
                        }}
                      >
                        OFF
                      </div>
                    }
                    checkedIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          paddingLeft: 2,
                          color: "#fff",
                          fontSize: 14,
                          fontWeight: "bold",
                        }}
                      >
                        ON
                      </div>
                    }
                  />
                  <div className="mx-3" style={{ color: "#282328" }}>
                    {time.start}
                    {" - "}
                    {time.end}
                  </div>
                  {WEEKDAYS.filter((w) => menu?.weekdays?.includes(w.id)).map(
                    (weekday, index) => (
                      <WeekDay>
                        <span
                          style={{
                            color: "#fff",
                            fontFamily: "Inter",
                            fontSize: "15px",
                            fontWeight: "bold",
                          }}
                        >
                          {weekday.title}
                        </span>
                      </WeekDay>
                    )
                  )}
                </div>
              </Header>
              <Row>
                <DragDropContext onDragEnd={handleCategoryDragEnd}>
                  <Droppable droppableId={"categories"} type="items">
                    {(provided, snapshot) => (
                      <Col
                        md={5}
                        style={{
                          paddingRight: 0,
                        }}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <AddMenuCard
                          title={"New Category"}
                          onClick={() => setShowNewCategory(true)}
                          style={{
                            background: "#fff",
                            height: 70,
                          }}
                        />
                        {showNewCategory && (
                          <Category
                            className="py-3"
                            add
                            menuID={menuID}
                            createCategory={props.createCategory}
                            onAddNewCategory={handleAddNewCategory}
                          />
                        )}
                        {menu.categories.map((c, index) => (
                          <Category
                            key={c.id}
                            index={index}
                            c={c}
                            editing={
                              editingCategory === c.id && c.id !== selected.id
                            }
                            handleSelect={handleSelect}
                            selected={selected}
                            handleRemoveCategory={handleRemoveCategory}
                            setEditingCategory={handleSetEditingCategory}
                            handleEditCategory={handleEditCategory}
                            deleteLoading={deleteLoading === c.id}
                            setConfirmDeleteModal={setConfirmDeleteModal}
                          />
                        ))}
                        {provided.placeholder}
                      </Col>
                    )}
                  </Droppable>
                </DragDropContext>
                <Col
                  md={5}
                  style={{
                    paddingLeft: 0,
                  }}
                >
                  {selected ? (
                    <SCard className="mb-4">
                      <div className="d-flex align-items-center justify-content-between pb-4">
                        {selected.id === editingCategory ? (
                          <Form.Group style={{ margin: 0 }}>
                            <Form.Control
                              value={selected?.name}
                              onChange={handleSelectedChange}
                              autoFocus
                              name="name"
                              type="text"
                              placeholder="Category Name..."
                            />
                          </Form.Group>
                        ) : (
                          <h5 className="title">{selected.name}</h5>
                        )}
                        <div className="d-flex align-items-end">
                          {selected.id !== editingCategory ? (
                            <>
                              <button
                                className="action-btn"
                                onClick={() => setEditingCategory(selected.id)}
                              >
                                <FontAwesomeIcon icon={faPen} color="#7B7B7B" />
                              </button>
                              <button
                                className="action-btn"
                                onClick={() =>
                                  setConfirmDeleteModal({
                                    id: selected.id,
                                    selected: true,
                                  })
                                }
                              >
                                {deleteSelectedLoading ? (
                                  <Spinner animation="border" size="sm" />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    color="#7B7B7B"
                                  />
                                )}
                              </button>
                            </>
                          ) : (
                            <button
                              className="action-btn"
                              onClick={(e) =>
                                handleEditCategory(e, selected.id, {
                                  name: selected.name,
                                  description: selected.description,
                                })
                              }
                            >
                              <FontAwesomeIcon icon={faCheck} color="#7B7B7B" />
                            </button>
                          )}
                        </div>
                      </div>
                      {selected.id === editingCategory ? (
                        <Form.Group style={{ marginBottom: "2rem" }}>
                          <Form.Control
                            value={selected?.description}
                            onChange={handleSelectedChange}
                            name="description"
                            // autoFocus
                            as="textarea"
                            type="text"
                            placeholder="Category Name..."
                          />
                        </Form.Group>
                      ) : (
                        <p className="description">{selected?.description}</p>
                      )}
                      <DragDropContext onDragEnd={handleItemDragEnd}>
                        <Droppable droppableId={"items"} type="items">
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {selected?.items.length > 0
                                ? selected?.items?.map((item, index) => (
                                    <Item
                                      key={item.id}
                                      index={index}
                                      category={selected.id}
                                      menu={menu.id}
                                      handleRemove={
                                        handleRemoveItemFromCategory
                                      }
                                      item={item}
                                      removeItemLoading={
                                        removeItemLoading === item.id
                                      }
                                    />
                                  ))
                                : "No items added yet."}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                      <div
                        className="d-flex justify-content-start align-items-center"
                        style={{ paddingTop: 25 }}
                      >
                        <CircleButton
                          onClick={() =>
                            props.history.push(
                              `/console/create-categories/${selected.id}/items/`
                            )
                          }
                          style={{
                            width: 35,
                            height: 35,
                          }}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </CircleButton>
                        <h5 style={{ marginLeft: "10px", marginTop: "5px" }}>
                          Add Items
                        </h5>
                      </div>
                    </SCard>
                  ) : (
                    ""
                  )}
                </Col>
                <Col md={2}></Col>
              </Row>
            </>
          ) : (
            <Loading />
          )}
        </Col>
      </Row>
      <ConfirmDeleteModal
        onHide={() => setConfirmDeleteModal(false)}
        onClick={() =>
          handleRemoveCategory(
            confirmDeleteModal.id,
            confirmDeleteModal.selected
          )
        }
        show={confirmDeleteModal}
      />
    </SContainer>
  );
};

export default connect(
  (state) => ({
    selectedRestaurant: state.user.selectedRestaurant,
    locale: state.locale[state.locale.active],
    permissions: state.user.permissions,
  }),
  {
    getMenuById,
    createCategory,
    removeCategory,
    editMenu,
    editItem,
    editCategory,
    reorderCategory,
    moveItem,
  }
)(ViewMenu);
