import { PagingRow } from "./styles";
import React from "react";
import SkeletonLoading from "components/common/SkeletonLoading";
import Pagination from "@mui/material/Pagination";

/*
 * Pagination it gives, currentPage,...
 */

const CustomPagination = ({
  paginationObj,
  page,
  setPage,
  loading,
  ...props
}) => {
  return (
    <PagingRow>
      {paginationObj.count ? (
        <Pagination
          count={Math.ceil(paginationObj.count / 10)}
          onChange={(event, number) => {
            setPage(number);
          }}
        />
      ) : loading ? (
        <div className="skeleton">
          <SkeletonLoading
            borderRadius={"4px"}
            width={"76px"}
            height={"26px"}
            count={1}
          />{" "}
          <SkeletonLoading
            borderRadius={"4px"}
            width={"28px"}
            height={"26px"}
            count={1}
          />{" "}
          <SkeletonLoading
            borderRadius={"4px"}
            width={"28px"}
            height={"26px"}
            count={1}
          />{" "}
          <SkeletonLoading
            borderRadius={"4px"}
            width={"28px"}
            height={"26px"}
            count={1}
          />{" "}
          <SkeletonLoading
            borderRadius={"4px"}
            width={"28px"}
            height={"26px"}
            count={1}
          />{" "}
          <SkeletonLoading
            borderRadius={"4px"}
            width={"67px"}
            height={"26px"}
            count={1}
          />
        </div>
      ) : null}
    </PagingRow>
  );
};

export default CustomPagination;
