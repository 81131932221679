export const orders = (state = [], action) => {
  switch (action.type) {
    case "NEW_ORDER":
      return [...state, action.payload];
    case "ORDER_DELIVERY":
      if (Array.isArray(state)) {
        return [
          ...state.map((o) => {
            if (o.id !== action.payload.id) return o;
            return action.payload;
          }),
        ];
      }
    case "NEW_ORDERS_SEEN":
      return [];
    default:
      return state;
  }
};

export const orderNotifAlarm = (state = false, action) => {
  switch (action.type) {
    case "CHANGE_NOTIF_STATUS":
      return (state = action.bool);
    default:
      return state;
  }
};
