// NOTE: THE COMMENTED OUT CODE IS THE OLD LANDING PAGE DEPRECIATED

import {useEffect} from "react";
import { LANDING_PAGE_ADDRESS } from "../../constants";

const Homepage = ({ locale, ...props }) => {
  useEffect(() => {
    window.location.href = LANDING_PAGE_ADDRESS;
  }, []);
  
  return(null);
}

export default Homepage;