/*
 * actions/index.js
 *
 * main / common redux actions here
 *
 */

import { getData } from "../utils";
import jsCookie from "js-cookie";
import { loginUser } from "./user";

export const getInitialData = () => (dispatch, getState) => {
  asyncGet("token")
    .then((res) => {
      getData(res) ? dispatch(loginUser(getData(res))) : dispatch(endLoading());
    })
    .catch((e) => {
      dispatch(endLoading());
    });
};

export default (action) => {
  return action;
};

export const makeAlert = (text, type, undo) => {
  return { type: "ADD_TO_ALERTS", text, alertType: type, undo };
};

export const startLoading = () => {
  return { type: "ON" };
};

export const endLoading = () => {
  return { type: "OFF" };
};

export const asyncGet = async (key) => {
  let value = await jsCookie.get(key);
  return value;
};
