import styled from "styled-components";
import { Colors } from "assets/colors";

export const StyledWrapper = styled.div`
  .delete-modal {
    width: 412px;
    padding: 20px 0;
  }

  .title {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #131b1f;
    margin-bottom: 8px;
    padding: 0 20px;
  }

  .desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #666b6d;
    padding: 0 20px;
    margin-bottom: 12px;
  }

  .qa {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #131b1f;
    padding: 0 20px;
    margin-bottom: 20px;
  }

  .btns {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #dde1e4;
    padding: 16px 20px;
    padding: 16px 20px 0;
    justify-content: flex-end;
  }

  .btn-delete {
    background: #c7322d;
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px #c7322d,
      0px 1px 1px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    height: 28px;
    padding: 5px 8px;
    color: #ffffff;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
  }

  .btn-cancel {
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
      0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    border-radius: 4px;
    width: 61px;
    height: 28px;
    padding: 5px 8px;
    color: #131b1f;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    margin-right: 12px;
  }
`;
