import React from "react";
import { StyledError } from "./styles";

/** Error fallback UI component */

const NotFoundPage = () => {
  return (
    <StyledError>
      <pre className="error">404</pre>
      <pre>Page not found</pre>
      <a href="/console" className="btn">
        Go home
      </a>
      <pre className="text">
        If you still face issues please reach out to us at{" "}
      </pre>
      <pre className="email">contact@omnicart.tech</pre>
    </StyledError>
  );
};

export default NotFoundPage;
