import React, { useState, useEffect, useRef } from 'react'
import { Row, Form, Col, Card } from 'react-bootstrap'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Link, useHistory } from 'react-router-dom'

import Loading from 'components/Loading'
import { loginUser, setToken } from 'actions/user'
import { makeAlert } from 'actions'
import { register, userInfo } from 'api/auth'
import { createCustomer, merchantClaimers } from 'api/billing/customers'
import Button from 'components/button'

const BusinessDetails = ({locale,...props}) => {
    let history = useHistory();

    let tmp = (({
        province,
        city,
        address,
        postal_code,
        business_number,
        website,
    }) => ({
        BusinessName: "",
        Province: province,
        City: city,
        StreetAddress: address,
        PostalCode: postal_code,
        BusinessNumber: business_number,
        Website: website,
    }))(props.registerForm)

    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({
        BusinessName: '',
        Province: '',
        City: '',
        StreetAddress: '',
        PostalCode: '',
        BusinessNumber: '',
        Website: '',
        ...tmp
    })
    const [redirect, setRedirect] = useState(false)
    const [error, setError] = useState({
        BusinessNameError: false,
        ProvinceError: false,
        CityError: false,
        StreetAddressError: false,
        PostalCodeError: false,
        BusinessNumberError: false,
        WebsiteError: false
    })

    const handleChange = (e) => {
        setError({
            BusinessNameError: false,
            ProvinceError: false,
            CityError: false,
            StreetAddressError: false,
            PostalCodeError: false,
            BusinessNumberError: false,
            WebsiteError: false
        })
        const { value, name } = e.target
        setForm({ ...form, [name]: value })
    }

    const submit = async (e) => {
        e.preventDefault();

        const data = {
            province: form.Province,
            city: form.City,
            address: form.StreetAddress,
            postal_code: form.PostalCode,
            business_number: form.BusinessNumber,
            business_name: form.BusinessName,
            website: form.Website,
        }

        if (validate()) {
            setLoading(true)
            // console.log(props.registerForm);
            // console.log(data);
            props.register({ ...props.registerForm, ...data }).then(res => {
                // props.loginUser(register.key, {})
                // props.setStep(3)
                history.push('/login', {needVerification: true})

                // setRedirect(true)
            }).catch(err => {
                setLoading(false)
            })
            // await props.setToken(register.key)

            // const customers = await props.createCustomer({
            //     first_name: props.registerForm.first_name,
            //     last_name: props.registerForm.last_name,
            //     address_1: data.address,
            //     city: data.city,
            //     country: 'CA'
            // })
            // props.setRegisterForm({ ...props.registerForm, billing: {...props.registerForm.billing, ...data}, token: register.key })
            // props.setStep(3)
        }
    }

    const validate = () => {
        const { BusinessName, Province, City, StreetAddress, PostalCode,
            BusinessNumber, Website } = form
        let { BusinessNameError, ProvinceError, CityError, StreetAddressError,
            PostalCodeError, BusinessNumberError, WebsiteError } = form
        let valid = true
        if (!BusinessName) {
            BusinessNameError = true
            valid = false
        }
        if (!Province) {
            ProvinceError = true
            valid = false
        }
        if (!StreetAddress) {
            StreetAddressError = true
            valid = false
        }
        if (!City) {
            CityError = true
            valid = false
        }
        if (!PostalCode) {
            PostalCodeError = true
            valid = false
        }
        if (!BusinessNumber) {
            BusinessNumberError = true
            valid = false
        }
        if (!Website) {
            WebsiteError = true
            valid = false
        }
        setError({ BusinessNameError, ProvinceError, CityError, PostalCodeError, BusinessNumberError, WebsiteError })
        return valid
    }

    return (
        <Card className="o-hidden border-0 shadow-lg my-5">
            <Card.Body>
                <Row className="">
                    <Col md={12}>
                        <h3 className="h4 text-dark my-2 text-center font-weight-bold">{locale.businessdetails_text} </h3>
                        <p className="p-0 m-0 text-center">{locale.give_businessdetail_text} </p>

                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <div className="col-lg-8">
                        <div className="p-5">

                            <Form className="user" onSubmit={submit}>
                                <Form.Group className="row">
                                    <Form.Label>{locale.business_name}* </Form.Label>

                                    <Form.Control
                                        type="text"
                                        className="form-control "
                                        placeholder={locale.business_name}
                                        name="BusinessName"
                                        onChange={handleChange}
                                        value={form.BusinessName}
                                    />
                                    {
                                        error.BusinessNameError &&
                                        <Form.Control.Feedback
                                            style={{ display: "block" }}
                                            type="invalid">{locale.required_text}* </Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <Form.Group className="row">
                                    <Form.Label>{locale.province_name}*</Form.Label>

                                    <Form.Control
                                        name="Province"
                                        onChange={handleChange}
                                        value={form.Province}
                                        type="text"
                                        className="form-control "
                                        placeholder={locale.province_name} />
                                    {
                                        error.ProvinceError
                                            ? <Form.Control.Feedback
                                                style={{ display: "block" }}
                                                type="invalid">{locale.required_text}*</Form.Control.Feedback>
                                            : null
                                    }
                                </Form.Group>
                                <Form.Group className="row">
                                    <Form.Label>{locale.city_name}* </Form.Label>

                                    <Form.Control
                                        name="City"
                                        onChange={handleChange}
                                        value={form.City}
                                        type="text"
                                        className="form-control "
                                        placeholder={locale.city_name} />
                                    {
                                        error.CityError &&
                                        <Form.Control.Feedback
                                            style={{ display: "block" }}
                                            type="invalid">{locale.valid_city_required}*</Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <Form.Group className="row">
                                    <Form.Label>{locale.street_address}*</Form.Label>

                                    <Form.Control
                                        name="StreetAddress"
                                        onChange={handleChange}
                                        value={form.StreetAddress}
                                        type="text" className="form-control " placeholder={locale.street_address} />
                                    {
                                        error.StreetAddressError &&
                                        <Form.Control.Feedback
                                            style={{ display: "block" }}
                                            type="invalid">{locale.required_text}*</Form.Control.Feedback>
                                    }

                                </Form.Group>
                                <Form.Group className="row">
                                    <Form.Label>{locale.postal_code}*</Form.Label>

                                    <Form.Control
                                        name="PostalCode"
                                        onChange={handleChange}
                                        value={form.PostalCode}
                                        type="text" className="form-control " placeholder={locale.postal_code} />
                                    {
                                        error.PostalCodeError &&
                                        <Form.Control.Feedback style={{ display: "block" }} type="invalid">{locale.required_text}*</Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <Form.Group className="row">
                                    <Form.Label>{locale.business_number}*</Form.Label>

                                    <Form.Control
                                        name="BusinessNumber"
                                        onChange={handleChange}
                                        value={form.BusinessNumber}
                                        type="text" className="form-control " placeholder={locale.business_number} />
                                    {
                                        error.BusinessNumberError &&
                                        <Form.Control.Feedback style={{ display: "block" }} type="invalid">{locale.required_text}*</Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <Form.Group className="row">
                                    <Form.Label>{locale.website_address}*</Form.Label>

                                    <Form.Control
                                        name="Website"
                                        onChange={handleChange}
                                        value={form.Website}
                                        type="text" className="form-control" placeholder={locale.website_address} />
                                    {
                                        error.WebsiteError &&
                                        <Form.Control.Feedback style={{ display: "block" }} type="invalid">{locale.required_text}*</Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <div className="text-center">
                                    <a
                                        onClick={() => props.setStep(1)}
                                        className="p-3">
                                        <FontAwesomeIcon icon={faArrowLeft} className="mx-3" />
                                        {locale.go_back_text}
                                    </a>
                                    <Button
                                        onClick={submit}
                                        className="px-5"
                                    >
                                        {!loading ? 'Submit' : <Loading nopadding={true} />}
                                    </Button>

                                </div>
                                <hr />
                            </Form>
                            <div className="text-center">
                                <Link className="small" to="/login">{locale.existing_account_text} </Link>
                            </div>
                        </div>
                    </div>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default connect(
    (state) => ({ isLoggedIn: state.user.isLoggedIn, locale: state.locale[state.locale.active] }),
    { register, loginUser, makeAlert, setToken, userInfo, createCustomer, merchantClaimers }
)(BusinessDetails)
