import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Pagination,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "components/Loading";
import ModalBody from "components/modal/StyledModalBody";
import MyButton from "components/button";
import RBCard from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import { connect } from "react-redux";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const StyledBody = styled(RBCard.Body)`
  & > div {
    width: 100% !important;
  }
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .right {
    h6 {
      color: #404040 !important;
      font-size: 20px;
      font-weight: bold;
    }
  }
  .left {
    color: #999 !important;
    cursor: pointer;
  }

  .subtitle {
    font-size: 15px;
    margin-top: 10px;
  }
`;
const Options = styled.div`
  padding: 2px 5px;
`;
const StyledModalBody = styled(ModalBody)`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const SubmitWrap = styled.div`
  .primary {
  }

  .secondary {
  }
`;
const Submit = styled(MyButton)`
  margin-right: 10px;
`;

const Card = ({ locale, ...props }) => {
  const initialModal = { show: false, type: null, id: null };
  const [modal, setModal] = useState(initialModal);

  const handleEdit = (id) => {
    if (!props.setEditing) {
      props.openModal(true);
      return;
    }
    props.setEditing(id);
    setModal({ show: true, type: "edit", id });
  };

  const handleHide = () => {
    props.setEditing(null);
    setModal(initialModal);
  };

  const handleSubmit = () => {
    props.onSubmit();
    handleHide();
  };

  const handleCloseModal = () => {
    props.setEditing(null);
    setModal({ show: false });
  };

  return (
    <RBCard className="card shadow mb-4" {...props}>
      {!props.loading ? (
        <>
          <StyledBody className="card-body">
            <Header>
              <div className="right">
                <h6 className="m-0 font-weight-bold text-primary">
                  {props.title}
                </h6>
              </div>
              <div className="left">
                {(props.setEditing || props.openModal) && (
                  <Options
                    className={props.editClassName}
                    onClick={() => handleEdit(props.title.toLowerCase())}
                  >
                    {locale.edit} <FontAwesomeIcon icon={faEdit} />
                  </Options>
                )}
              </div>
            </Header>
            <p className="subtitle">{props.subtitle}</p>
            {props.children}
          </StyledBody>
          <Modal size="lg" show={modal.show} onHide={() => handleHide()}>
            <StyledModalBody>
              <Container>
                <Header>
                  <div className="right">
                    <h6 className="m-0 font-weight-bold text-primary">
                      {props.title}
                    </h6>
                    <p className="subtitle">{props.subtitle}</p>
                  </div>
                </Header>
                {props.children}
                {props.editing === props.title.toLowerCase() && (
                  <SubmitWrap>
                    <Submit
                      className="primary rounded-pill px-4 shadow-sm"
                      onClick={handleSubmit}
                    >
                      {!props.updating ? "Submit Edit" : <Loading nopadding />}
                    </Submit>
                    <Button variant="light" onClick={() => handleCloseModal()}>
                      Discard
                    </Button>
                  </SubmitWrap>
                )}
              </Container>
            </StyledModalBody>
          </Modal>
        </>
      ) : (
        <Loading />
      )}
    </RBCard>
  );
};

export default connect((state) => ({
  locale: state.locale[state.locale.active],
}))(Card);
