import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faBold,
  faListOl,
  faListUl,
} from "@fortawesome/free-solid-svg-icons";
import { Editor, EditorState, RichUtils } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import "draft-js/dist/Draft.css";
import { Colors } from "assets/colors";

const SLabel = styled(Form.Label)`
  font-weight: bold;
  font-size: 20px;
  line-height: 16px;
  letter-spacing: 0.4px;
  margin-bottom: 15px;
  color: #4e4e4e;
`;
const EditorWrapper = styled.div`
  border: 1px solid #833ab4;
  padding: 15px 20px;
  cursor: text;
  margin-bottom: 25px;
  min-height: 100px;
  font-size: 20px;
  border-radius: 0.35rem;
`;
const SInput = styled(Form.Group)`
  margin-bottom: 0;
  .length-progress {
    position: absolute;
    right: 15px;
  }
  input,
  select {
    border-color: #833ab4;
    padding: 25px 20px;
    font-size: 20px;
    width: 100%;
    color: #222;
    ${(p) =>
      !p.noInput && p.lessMargin ? `margin-bottom: 5px` : `margin-bottom: 25px`}
  }
  .button_wrapper {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    .edit_button {
      width: 3rem;
      height: 3rem;
      border: 1px solid rgba(0, 0, 0, 0.15);
      margin-left: 1rem;
      border-radius: 5px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
`;
const SButton = styled(Button)`
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  height: 60px;
  padding: 0;
  transition: 0.5s all;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #ffffff !important;
  color: #000 !important;
  outline: none !important;
  text-transform: uppercase;
  font-weight: bold;
  border: none !important;
  margin-right: 15px;
  padding: 11px;

  .form-control:focus {
    box-shadow: none;
  }

  input {
    border: none !important;
    padding: 15px;
  }
`;

export const Label = (props) => <SLabel>{props.children}</SLabel>;

const Input = (props) => {
  const {
    style,
    label,
    type,
    placeholder,
    multiline,
    as,
    rows,
    editor,
    errorText,
    maxLength,
    value,
    containerStyle,
  } = props;
  return (
    <SInput
      lessMargin={errorText || maxLength}
      noInput={props.noInput}
      style={containerStyle}
    >
      {props.label && <Label>{props.label}</Label>}
      {editor && !props.noInput && <DocumentEditorButton {...props} />}
      {!editor && !props.noInput && (
        <Form.Control {...props}>{props.children}</Form.Control>
      )}
      {errorText && (
        <span style={{ color: "red", textTransform: "capitalize" }}>
          {errorText}
        </span>
      )}
      {maxLength && (
        <span className="length-progress">
          {value ? value.length : 0}/{maxLength}
        </span>
      )}
    </SInput>
  );
};

export const SearchBar = (props) => (
  <SButton style={{ ...props.style, position: "relative" }}>
    <Form.Control {...props} />
    <FontAwesomeIcon
      icon={faSearch}
      color={Colors.primary1}
      size={"lg"}
      className="mx-3"
      style={{
        position: "absolute",
        top: "50%",
        right: "16px",
        transform: "translateY(-50%)",
      }}
    />
  </SButton>
);

export default Input;

const DocumentEditorButton = (props) => {
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );
  const editor = React.useRef(null);
  const focusEditor = () => {
    editor.current.focus();
  };

  useEffect(() => {
    if (props.value)
      setEditorState(EditorState.createWithContent(stateFromHTML(props.value)));
  }, [props.value]);

  const handleKeyCommand = (command, state) => {
    const newState = RichUtils.handleKeyCommand(state, command);

    if (newState) {
      setEditorState(newState);
      return "handled";
    }

    return "not-handled";
  };

  const handleChange = (state) => {
    setEditorState(state);
    props.onChange(stateToHTML(state.getCurrentContent()));
  };

  const handleAction = (action) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, action));
  };
  const handleBlockTypeAction = (action) => {
    setEditorState(RichUtils.toggleBlockType(editorState, action));
  };

  const editButtons = [
    {
      icon: faBold,
      action: "BOLD",
      actionFx: handleAction,
    },
    {
      icon: faListUl,
      action: "unordered-list-item",
      actionFx: handleBlockTypeAction,
    },
    {
      icon: faListOl,
      action: "ordered-list-item",
      actionFx: handleBlockTypeAction,
    },
  ];

  return (
    <>
      <div className="button_wrapper">
        {editButtons.map((button, index) => (
          <button className="edit_button">
            <FontAwesomeIcon
              icon={button.icon}
              color={"#FCB045"}
              size={"lg"}
              onClick={() => button.actionFx(button.action)}
            />
          </button>
        ))}
      </div>
      <EditorWrapper onClick={focusEditor}>
        <Editor
          ref={editor}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          onChange={handleChange}
        />
      </EditorWrapper>
    </>
  );
};
