import React, { useEffect, useState, useReducer } from 'react'
import styled from 'styled-components'
import { Pagination, Modal, Button, Col, Form, Container } from 'react-bootstrap'
import { connect } from 'react-redux'
import RBCard from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV, faFileUpload, faEdit } from '@fortawesome/free-solid-svg-icons';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import FormImage from 'components/forms/Image'
import withMaxLength from 'components/forms/withMaxLength'
import { formatTime, getEndTime, subtractArrays, checkRegex, formatPhoneNumber } from 'utils'

const StyledText = styled.div`
  width: 100%;
  margin-bottom: 1rem;

  p, input, img, textarea {
    margin: 0;
  }
  .time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 5px;
    span {
      flex: 1;
    }

    .to {
      margin: 0 10px;
    }
  }
  textarea {
    min-height: 200px;
  }
  strong {
    display: block;
  }

  .hour {
    padding: 2px 10px;
    background: #e0e0e0;
    border-radius: 5px;
  }
  .phone_input_container{
    border: none;
    outline: none;
    border-radius: 0
    width: 100%
  }
  .react-tel-input .form-control{
    width: 100%
  }
`

const TimePickerWrap = styled.div`
  margin-bottom: 1rem;
  text-align: center;
  span {
    display: block;
    text-align: center;
  }
  .to {
    margin-top: 0.5rem;
  }
`

const Empty = styled.div`
  color: red;
  font-weight: bold;
`

const WMLFormControl = withMaxLength(Form.Control)

const Error = (props) => (
  !props.text
    ? <Form.Label style={{ color: 'red' }}>{props.locale.field_cannot_be_empty}</Form.Label>
    : <Form.Label style={{ color: 'red' }}>{props.text}</Form.Label>
)

const Text = ({ locale, ...props }) => {
  const name = props.title && props.title.toLowerCase()
  const [state, setState] = useReducer((state, newState) =>
    ({ ...state, ...newState }),
    { errors: [] }
  )
  const handleChange = (e, type) => {
    if (type !== 'phone') {
      const { name, value } = e.target
      let tempErrors = []
      if (props.regex) {
        if (!checkRegex(value, props.regex)) {
          tempErrors.push({ name, type: 'regex' })
        } else {
          tempErrors.filter(error => error.name !== name && error.type !== 'regex')
        }
        setState({ errors: tempErrors })
      }
    }
    props.handleChange(e, type)
  }

  return (
    <StyledText>
      <strong>{props.title}</strong>
      {
        props.editing !== props.group.toLowerCase()
          ? props.subtitle
            ? props.type === 'select'
              ? props.subtitle.map((s, index) => s.title + (index !== props.subtitle.length - 1 ? ', ' : ''))
              : props.type === 'time'
                ? <div className={'time'}>{props.subtitle}</div>
                : props.title === 'Website'
                  ? <a href={props.subtitle} target='_blank'>{props.subtitle}</a>
                  : props.type !== 'password'
                    ? props.type === 'phone_number'
                      ? <p>{formatPhoneNumber(props.subtitle)}</p>
                      : <p>{props.subtitle}</p>
                    : <p>{props.placeholder}</p>
            : props.type === 'password'
              ? <p>{props.placeholder}</p>
              : ''
          // : <Empty>{locale.empty_field_placeholder}</Empty>
          : props.type === 'file'
            ? <FormImage
                type="file"
                name={props.name || props.title.toLowerCase()}
                placeholder="Item image"
                errors={state.errors}
                // value={form.image}
                onChange={props.handleChange}
              />
            : props.type === 'time'
              ? <TimePickerWrap>
                <span className='from'>from</span>
                <Form.Control
                  type='time'
                  defaultValue={props.from_hour}
                  onChange={(time) => props.handleTimeChange(time, props.id, 'from')}
                />
                <span className='to'>to</span>
                <Form.Control
                  type='time'
                  defaultValue={getEndTime(props.from_hour, props.duration)}
                  onChange={(time) => props.handleTimeChange(time, props.id, 'to')}
                />
              </TimePickerWrap>
              : props.type === 'phone_number'
                ? <PhoneInput
                  name={props.name || props.title.toLowerCase()}
                  onChange={(e) => handleChange(e, 'phone')}
                  value={props.subtitle?.toString()}
                  placeholder={props.placeholder}
                  containerClass='phone_input_container'
                  buttonClass='phone_input_button'
                  onlyCountries={['ca']}
                  country='ca'
                  countryCodeEditable={false}
                />
                : props.type === 'select'
                  ? null
                  : <WMLFormControl
                    regex={props.regex}
                    name={props.name || props.title.toLowerCase()}
                    maxLength={props.maxLength}
                    as={props.type === 'textarea' ? 'textarea' : 'input'}
                    onChange={handleChange}
                    type={props.type === 'password' ? 'password' : 'text'}
                    value={props.subtitle?.toString()}
                    placeholder={props.placeholder}
                  />

      }
      {state.errors.find(error => error.name === name && error.type === 'regex') && <Error locale={locale} text={'This field is not valid!'} />}
      {(props.comp && props.editing !== props.group.toLowerCase()) && props.comp}
      {(props.editingComp && props.editing === props.group.toLowerCase()) && props.editingComp}
      {props.editing !== props.group.toLowerCase() && !props.value && <p className='text-danger'>Please fill up this field.</p>}
    </StyledText>
  )
}

export default connect(state => ({ locale: state.locale[state.locale.active] }))(Text)
