import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import DropdownGeneral from 'components/common/DropdownGeneral';
import BadgeLabel from 'components/common/BadgeLabel';
import Input from 'components/common/Input';
import { ReactComponent as MerchantIcon } from 'assets/image/merchant.svg';
import { ReactComponent as SearchIcon } from 'assets/image/search.svg';
import { ReactComponent as CrossIcon } from 'assets/image/cross.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getMyPermissions } from 'api/auth';
import { getMyRestaurants, getRestaurantById } from 'api/restaurant';
import {
  changeSelectedRestaurant,
  changeUserRestaurants,
  setMyPermissions,
  changeSelectedRestaurantData,
  changeSelectedRestaurantWithoutReload,
} from 'actions/user';
import { getData } from 'utils';
import { toast } from 'react-toastify';
import { wsUrl } from 'constants';
import { changeAlarmStatus } from 'actions/orders';
import dispatchAction from 'actions';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import sound from 'assets/sounds/notif_2.mp3';
import useDebounce from 'hooks/useDebounce';
import { useHistory } from 'react-router-dom';
import { StyledWrapper } from './styles';

function SelectMerchant() {
  const [key, setKey] = useState();
  const [merchants, setMerchants] = useState([]);
  const selectedRestaurant = useSelector(
    (state) => state.user.selectedRestaurant,
  );
  const selectedRestaurantData = useSelector(
    (state) => state.user?.selectedRestaurantData,
  );
  const orderNontifStatus = useSelector((state) => state.orderNotifAlarm);
  const dispatch = useDispatch();
  const merchantId = (new URLSearchParams(window.location.search)).get('merchantId');
  const debouncedSearch = useDebounce(key, 500);
  const soundRef = useRef();

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    `${wsUrl}ws/orders/`,
    {
      retryOnError: true,
      shouldReconnect: (closeEvent) => readyState === ReadyState.CLOSED,
      reconnectAttempts: 20,
      reconnectInterval: 1000,
    },
  );

  const connectToWebsocket = useCallback(
    () => sendMessage(JSON.stringify({ restaurant: selectedRestaurant })),
    [],
  );
  const history = useHistory();

  useEffect(() => {
    if (lastMessage) {
      soundRef.current.muted = false;
      const data = JSON.parse(lastMessage.data);
      if (data.type === 'NEW_ORDER') soundRef.current.play();
      if (data.type === 'NEW_ORDER') dispatch(changeAlarmStatus(true));
      if (data.type) dispatch(dispatchAction(data));
    }
  }, [lastMessage]);

  useEffect(() => {
    if (readyState === ReadyState.OPEN) connectToWebsocket();
  }, [selectedRestaurant, readyState]);

  useEffect(() => {
    dispatch(getMyPermissions()).then((res) => {
      dispatch(setMyPermissions(getData(res)));
    });
    dispatch(getMyRestaurants()).then((res) => {
      setMerchants(getData(res)?.results);
      setTimeout(() => handleDefaultRestaurant(getData(res)?.results), 200);
    });
  }, []);

  useEffect(() => {
    /** Search for the merchant user typed */
    dispatch(getMyRestaurants(debouncedSearch)).then((res) => {
      setMerchants(getData(res)?.results);
      dispatch(changeUserRestaurants(getData(res)?.results));
    });
  }, [debouncedSearch]);

  const handleDefaultRestaurant = async (restList) => {
    if (merchantId) {
      if (selectedRestaurant !== +merchantId) {
        const merchant =
        merchants.find((rest) => rest.id === +merchantId) ||
        (await dispatch(getRestaurantById(merchantId))).data;

        dispatch(
          changeSelectedRestaurantData(merchant),
        );
        dispatch(changeSelectedRestaurant(+merchantId));
      }

      history.replace('/console');
    } else if (selectedRestaurant && typeof selectedRestaurant !== 'number') {
      // Set a default restaurant
      if (restList?.length > 0) {
        if (restList[0]) {
          dispatch(changeSelectedRestaurantData(restList[0]));
          dispatch(changeSelectedRestaurantWithoutReload(restList[0]?.id));
        }
      } else {
        toast.error('There are no merchants');
      }
    } else if (selectedRestaurant && typeof selectedRestaurant === 'number') {
      // Update the restaurant data
      dispatch(getMyRestaurants(selectedRestaurantData?.label)).then((res) => {
        const rest = getData(res)?.results?.find(
          (r) => r?.id === selectedRestaurant,
        );
        if (rest) {
          dispatch(changeSelectedRestaurantData(rest));
          dispatch(changeSelectedRestaurantWithoutReload(rest?.id));
        }
      });
    }
  };

  const handleMerchantsChange = (merchant) => {
    dispatch(
      changeSelectedRestaurantData(
        merchants.find((rest) => rest.id === merchant?.id),
      ),
    );
    dispatch(changeSelectedRestaurant(merchant?.id));
  };

  return (
    <StyledWrapper>
      <audio
        ref={soundRef}
        loop={orderNontifStatus}
        style={{ display: 'none' }}
        muted
      >
        <source src={sound} type="audio/mp3" />
      </audio>
      <DropdownGeneral
        label={(
          <BadgeLabel
            label={<p className="truncate">{selectedRestaurantData?.name}</p>}
            leadingIcon={<MerchantIcon />}
          />
        )}
        options={[]}
        onChange={() => {}}
        selected={null}
        btnClass="dropdown-button"
        btnOpenClass="dropdown-button"
        listView={(
          <div className="list-view">
            <Input
              type="text"
              id="search-among-merchants"
              value={key}
              onChange={(val) => setKey(val)}
              placeholder=""
              inputClasses="search-input"
              leadingIcon={<SearchIcon className="search-icon" />}
              trailingIcon={
                key && (
                  <CrossIcon
                    onClick={() => setKey('')}
                    className="cross-icon"
                  />
                )
              }
              autofocus
            />
            <div className="merchant-list">
              {merchants?.length ? (
                merchants.map((item, index) => (
                  <div
                    key={index}
                    id={item?.id}
                    onClick={() => handleMerchantsChange(item)}
                    className="merchant-list-item"
                  >
                    {item?.name}
                  </div>
                ))
              ) : (
                <div className="merchant-list-item">No options</div>
              )}
            </div>
          </div>
        )}
      />
    </StyledWrapper>
  );
}

export default SelectMerchant;
