import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import {
  Link,
  Redirect,
  Route,
  BrowserRouter as Router,
  withRouter,
} from "react-router-dom";
import React, { useEffect, useReducer, useState } from "react";
import { changeSelectedRestaurant, changeUserRestaurants } from "actions/user";

import { Colors } from "assets/colors";
import Loading from "components/Loading";
import NoPermissions from "components/commons/NoPermissions";
import { connect } from "react-redux";
import { createRestaurant } from "api/restaurant";
import { getData } from "../../utils";
import { getMyRestaurantsCount } from "api/restaurant";
import { makeAlert } from "actions";
import styled from "styled-components";

const StyledButton = styled.button`
  margin-top: 10px;
  width: calc(100% - 35px);
  background: ${Colors.primary1};
  border-radius: 3px;
  font-weight: bold;
  // font-size: 20px;
  padding: 7px 0;
  border: none;

  text-align: center;
  text-transform: uppercase;

  color: #fff;
`;

const StyledFormLable = styled(Form.Label)`
  color: ${Colors.darkestGray};
  font-weight: 500;
  font-size: 1.35rem;
  text-align: ${(p) => (p.rtl ? "right" : "left")};
  width: 100%;
`;

const CreateRestaurant = ({ locale, ...props }) => {
  const [modal, setModal] = useState(false);
  const [qty, setQty] = useState(false);
  const [qtyLoading, setLoadingQty] = useState(false);
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      form: {},
      fields: [
        {
          type: "text",
          label: locale.place_profile_title_name,
          value: "",
          name: "name",
        },
        {
          type: "text",
          label: locale.info_card_address,
          value: "",
          name: "address",
        },
        {
          type: "text",
          label: locale.info_card_phone,
          value: "",
          name: "phone",
        },
        // {
        //   type: 'text',
        //   label: locale.info_card_website,
        //   value: '',
        //   name: 'site',
        // },
        {
          type: "text",
          kind: "textarea",
          label: locale.place_profile_title_description,
          value: "",
          name: "description",
        },
        // {
        //   type: 'file',
        //   label: locale.place_profile_title_logo,
        //   value: '',
        //   name: 'logo',
        // },
      ],
    }
  );
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    props.getMyRestaurantsCount().then((res) => {
      setQty(getData(res)?.count);
    });
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({ form: { ...state.form, [name]: value } });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = [];
    state.fields.forEach((field) => {
      if (!state.form[field.name]) {
        errors = [...errors, field.name];
      }
    });
    setError(errors);
    if (errors.length == 0) {
      setLoading(true);
      props.createRestaurant(state.form).then((res) => {
        props.changeSelectedRestaurant(getData(res)?.id);
        setLoading(false);
      });
    }
  };

  return (
    <>
      {/* <StyledButton onClick={() => setModal(true)}>Add Restaurant</StyledButton> */}
      <Modal show={modal} onHide={() => setModal(false)} size="lg">
        <Container
          style={{
            direction: "ltr",
          }}
        >
          <Form
            style={{ color: Colors.black, fontSize: "1.25rem" }}
            onSubmit={handleSubmit}
          >
            <Modal.Header>Add Merchant</Modal.Header>
            <Modal.Body>
              {!props.permissions.includes("__panel__") &&
              props.permissions[props.permissions.length - 1]
                ?.remaining_restaurants <= 0 ? (
                <NoPermissions
                  text={`Your current plan only supports ${
                    props.permissions[props.permissions.length - 1]
                      .total_restaurants
                  } merchants, please upgrade your account to add a new merchant.`}
                />
              ) : (
                <Row>
                  {state.fields.map((inputDetail, index) => (
                    <Col
                      key={index}
                      md={inputDetail.kind === "textarea" ? 12 : 6}
                    >
                      <Form.Group>
                        <StyledFormLable rtl={locale.rtl}>
                          {inputDetail.label}*
                        </StyledFormLable>
                        <Form.Control
                          as={
                            inputDetail.kind === "textarea"
                              ? "textarea"
                              : "input"
                          }
                          onChange={(e) => handleChange(e)}
                          type={inputDetail.type}
                          name={inputDetail.name}
                          value={state[inputDetail.name]}
                          style={{ border: `2px solid ${Colors.grayBorder} ` }}
                          rows="8"
                        />
                        {error.includes(inputDetail.name) && (
                          <Form.Control.Feedback
                            style={{ display: "block" }}
                            type="invalid"
                          >
                            *required
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  ))}
                  <Col md={12}>
                    <Form.Control
                      disabled={loading}
                      onChange={(e) => handleChange(e)}
                      type={"submit"}
                      className="btn btn-primary"
                      value="Create"
                    />
                  </Col>
                </Row>
              )}
            </Modal.Body>
          </Form>
        </Container>
      </Modal>
    </>
  );
};

export default connect(
  (state) => ({
    locale: state.locale[state.locale.active],
    permissions: state.user.permissions,
  }),
  {
    createRestaurant,
    changeSelectedRestaurant,
    getMyRestaurantsCount,
  }
)(CreateRestaurant);
