import React, { useState } from "react";
import { useContext } from "react";
import { ModalContext } from "../../../context/modalContext";
import { StyledWrapper } from "./styles";
import Button from "components/common/Button";
import { useDispatch, useSelector } from "react-redux";
import { deleteDiscountApiCall } from "api/discounts";

const DeleteModal = ({ row, reload, ...props }) => {
  const { handleModal } = useContext(ModalContext);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const dispatch = useDispatch();
  const restaurantId = useSelector((state) => state.user?.selectedRestaurant);

  const handleDelete = (row) => {
    setDeleteLoading(true);
    dispatch(deleteDiscountApiCall(restaurantId, row?.id))
      .then((res) => {
        if (res === 204) {
          setDeleteLoading(false);
          handleModal();
          reload();
        }
      })
      .catch((err) => {
        setDeleteLoading(false);
      });
  };

  return (
    <StyledWrapper>
      {" "}
      <div className="delete-modal">
        <p className="title">Delete discount</p>
        <p className="desc">
          Deleting this discount will not affect customers who have already
          redeemed it, but new redemptions of the discount won't be allowed.
        </p>
        <p className="qa">
          Are you sure you want to delete this discount? This can't be undone.
        </p>
        <div className="btns">
          <Button
            onClick={() => handleModal()}
            label="Cancel"
            type="neutral"
            classes="btn-cancel"
          />
          <Button
            onClick={() => handleDelete(row)}
            label="Delete"
            type="neutral"
            classes="btn-delete"
            loading={deleteLoading}
          />
        </div>
      </div>
    </StyledWrapper>
  );
};

export default DeleteModal;
