import styled from "styled-components";
import ArrowDown from "assets/image/arrowDown.svg";
import { Colors } from "assets/colors";

export const StyledWrapper = styled.div`
  padding-bottom: 120px;

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 38px;
    color: #1a1f36 !important;
    padding: 0 40px;
    margin-bottom: 0;
  }

  .orders-box {
    margin-top: 73px;
  }

  .payouts-box {
    margin-top: 79px;
  }

  .overview {
    &-box {
      margin-top: 60.5px;
    }

    &-up {
      transform: rotate(180deg);
    }

    &-smooth {
      transition: 0.3s ease;
    }

    &-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      padding: 0 40px;
    }

    &-title {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 27px;
      color: #1a1f36;
    }

    &-filters {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }

    &-filter {
      position: relative;
      margin: 0 0 0 10px;
      background: #ffffff;
      border: none;
      border-radius: 4px;
      color: #4f566a;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
        0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);
      padding: 6px 8px;
      display: flex;
      align-items: center;

      &:hover {
        box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.22),
          0px 0px 0px 1px rgba(60, 66, 87, 0.16),
          0px 1px 1px rgba(0, 0, 0, 0.16);
      }

      &--sun {
        margin-right: 8px;
      }

      &--cal {
        margin-right: 11px;
      }

      &--active {
        color: #050505;
        box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px #3c4257,
          0px 1px 1px rgba(0, 0, 0, 0.12);
      }
    }

    &-table {
      display: flex;
      flex-direction: row;
      position: relative;
      padding-left: 17px;
      justify-content: flex-start;

      &-line {
        width: 100%;
        border-top: 1px solid #d8dce2;
        position: absolute;
        left: 0;
        bottom: 0;

        &--top {
          top: 0;
          height: 1px;
        }
      }

      &-cell {
        border-right: 1px solid #d8dce2;
        padding: 21px 24px;
        width: 25%;
        position: relative;
        height: 92px;

        &:nth-child(4) {
          border-right: none;
          padding-right: 40px;
        }

        @keyframes down-to-up {
          0% {
            opacity: 0;
            transform: translateX(-50%) translateY(10px);
          }
          100% {
            opacity: 1;
            transform: translateX(-50%) translateY(0);
          }
        }

        .tooltip {
          display: none;
          width: 252px;
          height: 144px;
          border-radius: 6px;
          background: #ffffff;
          border: 0.5px solid rgba(118, 118, 118, 0.28);
          box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
          border-radius: 6px;
          position: absolute;
          bottom: 100%;
          opacity: 1;
          left: 39px;
          top: 25px;
          /* left: 50%;
          top: 95%; */
          transform: translateX(-50%);
          padding: 14px 12px 10px;
          animation: down-to-up 0.5s;

          &-box {
            position: relative;
            cursor: pointer;

            &:hover {
              .tooltip {
                display: block;
              }
            }
          }

          &:before {
            content: "";
            width: 10px;
            height: 10px;
            border: 0.5px solid rgba(118, 118, 118, 0.28);
            position: absolute;
            top: 1px;
            /* left: 50%; */
            transform: rotate(45deg) translateY(-4px) translateX(-50%);
            border-bottom: none;
            border-right: none;
            border-radius: 3px 0 0 0;
            background-color: white;
            left: 94px;
          }

          &-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
          }

          &-current {
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: ${Colors.textPrimary1};
            margin: 0 0 7px;
          }

          &-previous {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #6e7579;
          }

          &-bottom {
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #4a5256;
            background: #f9f9fa;
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 74px;
            left: 0;
            padding: 10px 12px;
            border-radius: 0 0 6px 6px;
          }
        }
      }

      &-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 6px;
        height: 20px;
      }

      &-rate {
        background: #e3e8ee;
        border-radius: 4px;
        color: #4f566b;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        padding: 1px 4px;
        display: flex;
        align-items: center;
        max-height: 20px;

        svg {
          margin-right: 6px;
        }

        &--green {
          color: #21752b;
          background-color: #dbf5d6;

          svg {
            margin-top: 0.6px;
          }
        }

        &--red {
          color: #a94646;
          background-color: #fee7e7;

          svg {
            margin-top: 1px;
          }
        }
      }

      &-price {
        color: #1a1f36;
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
      }

      &-title {
        color: #4f566b;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;

        svg {
          color: #8a92a1;
          margin-left: 4px;
        }
      }
    }
  }

  .coming-soon {
    background: #faf3e6;
    color: #cc8601;
    border-radius: 22px;
    padding: 4px 8px;
    width: fit-content;
    height: 26px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 6px;
    }

    p {
      margin-bottom: 0;
    }
  }

  .m-left-12 {
    margin-left: 12px;
  }

  .export {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #050505;
    margin-right: 16px;
    background-color: white;
    border: none;

    svg {
      margin-right: 8px;
    }
  }

  .separator {
    width: 0px;
    height: 20px;
    border: 1px solid #dedfe3;
    margin-right: 6px;

    &--daily {
      margin-left: 16px;
      margin-right: 6px;
    }
  }

  .dropdown-list {
    padding: 4px 0;
    width: 89px !important;
  }

  .dropdown-btn {
    margin-left: 0;
  }

  .dropdown {
    margin-left: 10px;
  }

  .dropdown-item {
    color: #3c4257;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    padding: 7px 12px;
    cursor: pointer;
  }

  .table-pagination {
    margin: 24px auto;
  }

  .payout-box {
    margin-top: 100px;
  }

  .range {
    background: #c4c4c42e;
    border-radius: 4px;
    width: 100%;
    height: 38px;
    padding: 10px 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    width: 100%;
    position: absolute;
    left: 0;
    top: 33px;

    &--left {
      display: flex;
      align-items: center;
      flex-direction: row;

      p {
        margin-bottom: 0;
      }
    }

    &-title {
      color: #4f566b;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
    }

    &-range {
      color: #1a1f36;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      margin-left: 8px;
    }
  }

  .table-top {
    margin-bottom: 16px;
  }

  .status {
    &--new {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #9c400b;
      background: #f9e6d2;
      border-radius: 4px;
      padding: 4px 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 4px;
        width: 16px;
        height: 16px;
      }
    }
    &--scheduled {
      padding: 4px 6px;
    }

    &--accepted {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #045f5a;
      background: #deefef;
      border-radius: 4px;
      padding: 4px 8px;

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 8px;
        width: 16px;
        height: 16px;
      }
    }

    &--ready {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #0e623f;
      background: #cbf4c9;
      border-radius: 4px;
      padding: 4px 8px;
    }

    &--on-the-way {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #4b3a95;
      background: #e5dffc;
      border-radius: 4px;
      padding: 4px 8px;
    }

    &--completed {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #0e623f;
      background: #cbf4c9;
      border-radius: 4px;
      padding: 4px 8px;
    }

    &--rejected {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: ${Colors.textPrimary1};
      background: #e7e8e9;
      border-radius: 4px;
      padding: 4px 8px;
    }

    &--cancelled {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #aa2720;
      background: #fae5e4;
      border-radius: 4px;
      padding: 4px 8px;
    }
  }
`;
