import { fetchApi } from './'
import {generateURLWithParams} from '../utils'

export const getMenusByRestaurantId = (id, params = {}, query, page) => async (dispatch, getState) => {
  let url = generateURLWithParams(`restaurants/${id}/menus/`, {
    query: `{id,name,image,images{id,image_med},position,from_hour,duration,weekdays,is_active,order_type,active_hours}`,
    ...params
  })
  if (query) url += `&search=${query}`
  if (page) url += `&page=${page}`

  let res = await dispatch(fetchApi(url))

  return res
}

export const getMenuById = (id) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`menus/${id}/?query={id,active_hours,order_type,description,name,images,from_hour,duration,weekdays,is_active,categories{id,name,featured,description,position,items{id,name,images{image_med},value,item_position}},are_item_images_visible,placeholder_image}`, 'GET'))
  return res
}

export const getMenuAndCategoryTitleByRestaurantId = (id, pageNumber=1) => async (dispatch, getState) => {
  let url = generateURLWithParams(`restaurants/${id}/menus/`, {
    query: `{id,name,categories{id,name}}`,
    page: pageNumber
  })
  let res = await dispatch(fetchApi(url))
  return res
}

export const duplicateMenu = (id) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`menus/${id}/duplicate/`, 'POST'))
  return res
}

export const createMenu = (payload) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`menus/`, 'POST', payload))

  return res
}

export const editMenu = (id, payload) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`menus/${id}/`, 'PATCH', payload))
  return res
}

export const copyMenuTo = (id, data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`menus/${id}/copy/`, 'POST', data))
  return res
}

export const deleteMenu = (id) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`menus/${id}/`, 'DELETE'))
  return res
}

export const moveMenu = (id, position) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`menus/${id}/move/`, 'POST', { position }, false))

  return res
}

export const fetchMenus = (page = 1) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`menus/?page=${page}`, 'GET'))

  return res
}
