import moment from "moment";
import { toast } from "react-toastify";
import {getCrispKey} from "../api/auth";

export const isEmpty = (obj) => {
  if (typeof obj !== typeof true && typeof obj !== typeof 0 && !obj)
    return true;
  if (obj.length === 0) return true;
  if (obj === "") return true;
  if (typeof obj === typeof {} && obj.hasOwnProperty("id"))
    return obj.id.value ? obj.id.value === "" : obj.id === "";
  return false;
};
export const isPushNotificationSupported = () => {
  return "serviceWorker" in navigator && "PushManager" in window;
};

export const askUserPermission = async () => {
  return await Notification.requestPermission();
};

export const generateURLWithParams = (url, params) => {
  if (!params) return url;
  let newURL = url;
  Object.keys(params).forEach((key, index) => {
    if (params[key]) {
      let sign = index === 0 ? "?" : "&";
      newURL += `${sign}${key}=${params[key]}`;
    }
  });
  return newURL;
};
export const checkRegex = (str, regex) => {
  if (!regex) return true;
  var patt = new RegExp(regex);
  var res = patt.exec(str);
  if (res === null) return false;
  var ok = false;
  for (var i = 0; i < getData(res)?.length; i++) {
    if (res[i] === str) ok = true;
  }
  return ok;
};

export const immutableMove = (arr, from, to) => {
  return arr.reduce((prev, current, idx, self) => {
    if (from === to) {
      prev.push(current);
    }
    if (idx === from) {
      return prev;
    }
    if (from < to) {
      prev.push(current);
    }
    if (idx === to) {
      prev.push(self[from]);
    }
    if (from > to) {
      prev.push(current);
    }
    return prev;
  }, []);
};

export const subtractArrays = (a, b, key) => {
  if (!key) return a.filter((x) => !b.includes(x));
  else return a.filter((x) => !b.map((i) => i[key]).includes(x[key]));
};

export const insertDecimal = (num) => {
  if (!num) return 0;
  // return (Math.round(num * 100) / 100).toFixed(2);
  return Number(num).toFixed(2);
};

export const lastNthElements = (array, n) => {
  if (array == null) return void 0;
  if (n == null) return array[array.length - 1];
  return array.slice(Math.max(array.length - n, 0));
};

export const parseString = (str, ...variables) => {
  let string = str;
  for (let variable in variables) {
    string = string.replace("%s", variables[variable]);
  }
  return string;
};

export const getEndTime = (start, duration) => {
  if (!start || !duration) return false;
  return moment(moment(start, "HH:mm:ss"))
    .add(moment.duration(duration), "hours")
    .format("HH:mm:ss");
};

export const formatTime = (from_hour, duration) => {
  if (!duration) {
    return moment(from_hour, "HH:mm:ss").format("hh:mm A");
  } else {
    return moment(from_hour, "HH:mm:ss")
      .add(moment.duration(duration), "hours")
      .format("hh:mm A");
  }
};

export const numToWeekday = (num) => {
  switch (num) {
    case 1:
      return "M";
      break;
    case 2:
      return "Tu";
      break;
    case 3:
      return "W";
      break;
    case 4:
      return "Th";
      break;
    case 5:
      return "F";
      break;
    case 6:
      return "Sa";
      break;
    case 7:
      return "Su";
      break;

    default:
      break;
  }
};

export const toFarsiNumber = (n) => {
  const farsiDigits = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];

  return n.toString().replace(/\d/g, (x) => farsiDigits[x]);
};

export const toPersianNumber = (str) => {
  const string = str && str.toString();
  if (string && string.length > 0) {
    let persianNum = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
    let chars = string.split("");
    for (let number in string) {
      if (/\d/.test(chars[number])) {
        chars[number] = persianNum[chars[number]];
      }
    }
    return chars.join("");
  }
};

export const formatPhoneNumber = (number) => {
  if (!number) return number;
  const cleaned = ("" + number).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return number;
};

export const formatPrice = (price, k) => {
  if (!price) return;
  // let thousand = 1000
  // if (!k) thousand = 1
  return price.toLocaleString();
};

export const blendColors = (color1, color2, percentage) => {
  color1 = color1 || "#000000";
  color2 = color2 || "#ffffff";
  percentage = percentage || 0.5;

  var cvs = document.createElement("canvas");
  cvs.style.cssText = "display: none;";

  var ctx = cvs.getContext("2d");
  cvs.width = 90;
  cvs.height = 25;
  document.body.appendChild(cvs);

  ctx.fillStyle = color1;
  ctx.fillRect(0, 0, 30, 25);

  ctx.fillStyle = color2;
  ctx.fillRect(60, 0, 30, 25);

  if (color1.length == 4)
    color1 =
      color1[1] + color1[1] + color1[2] + color1[2] + color1[3] + color1[3];
  else color1 = color1.substring(1);
  if (color2.length == 4)
    color2 =
      color2[1] + color2[1] + color2[2] + color2[2] + color2[3] + color2[3];
  else color2 = color2.substring(1);

  color1 = [
    parseInt(color1[0] + color1[1], 16),
    parseInt(color1[2] + color1[3], 16),
    parseInt(color1[4] + color1[5], 16),
  ];
  color2 = [
    parseInt(color2[0] + color2[1], 16),
    parseInt(color2[2] + color2[3], 16),
    parseInt(color2[4] + color2[5], 16),
  ];

  var color3 = [
    (1 - percentage) * color1[0] + percentage * color2[0],
    (1 - percentage) * color1[1] + percentage * color2[1],
    (1 - percentage) * color1[2] + percentage * color2[2],
  ];

  color3 =
    "#" + intToHex(color3[0]) + intToHex(color3[1]) + intToHex(color3[2]);

  ctx.fillStyle = color3;
  ctx.fillRect(30, 0, 30, 25);

  return color3;
};

export const intToHex = (num) => {
  var hex = Math.round(num).toString(16);
  if (hex.length == 1) hex = "0" + hex;
  return hex;
};

export const urlB64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  const outputData = outputArray.map((output, index) =>
    rawData.charCodeAt(index)
  );

  return outputData;
};



export const getData = (res) => {
  /** Get the data part of response */
  return res?.data || res;
};

export const errorHandling = (res) => {
  /** Following the new pattern of error responses from server,
  we should show the messages, errors and details */
  if (res?.data?.status === "success") {
    if (res?.data?.message) {
      /** Success messages */
      toast.success(res?.data?.message);
    }
  }

  if (res?.data?.status === "failed") {
    /** Error messages */
    if (res?.data?.detail) {
      /** Detail */
      toast.error(res?.data?.detail);
    }
    if (res?.data?.errors) {
      if (res?.data?.errors?.length > 0) {
        /** Errors array */
        let errors = res?.data?.errors;
        for (let i = 0; i < errors?.length; i++) {
          let textError = Object.values(errors[i])[0];
          toast.error(textError);
        }
      }
    }
  }

  // For old apis
  if (!res?.data?.status && res?.data?.detail) {
    toast.error(res?.data?.detail);
  }
};

export const isFailedRequest = (res) => {
  /** Is the request failed or not */
  return getData(res)?.status === "failed";
};

export const calcSomeDaysAgoDate = (numberOfDaysBefore) => {
  /** Calculte numberOfDaysBefore days before now  */
  return formatDate(
    new Date().setDate(new Date().getDate() - numberOfDaysBefore)
  );
};

export const formatDate = (date) => {
  /** Formate date to YYYY-MM-DD */
  return date ? moment(date).format("YYYY-MM-DD") : null;
};

export const generateQuery = (queryArray) => {
  /** Get an array of queries and generate a query string */
  let validatedQueryArray = [];
  for (let i = 0; i < queryArray.length; i++) {
    const element = queryArray[i];
    if (element) {
      validatedQueryArray.push(element);
    }
  }

  return validatedQueryArray?.length ? `?${validatedQueryArray.join("&")}` : "";
};

export const actionCreator = (type, payload) => {
  return {
    type,
    payload,
  };
};

export const priceTransformer = (value, showPrefix = true) => {
  const transformedValue = (Math.round((value || 0) * 100) / 100).toFixed(2)
  if(showPrefix){
    return `$${transformedValue}`
  }
  return `${transformedValue}`;
};

export const initialCrispChat = (dispatch, organizationId, userInfo, merchant) => {

  dispatch(getCrispKey(organizationId)).then(r => {
    const crispId = r?.crisp_public_key
    if(crispId && userInfo){
      window.$crisp=[];
      window.CRISP_WEBSITE_ID=crispId;
      const s = document.createElement('script');
      s.src = "https://client.crisp.chat/l.js";
      s.async=1;
      // $crisp.push(["do", "session:reset"]);
      document.getElementsByTagName("head")[0].appendChild(s);
      window.$crisp.push(["set", "user:email", [userInfo?.email]]);
      window.$crisp.push(["set", "user:phone", [userInfo?.phone]]);
      window.$crisp.push(["set", "user:company", [userInfo?.business_name]]);
      window.$crisp.push(["config", "color:theme", ["blue"]]);
      window.$crisp.push(["set", "user:nickname", [merchant?.name]]);
      window.$crisp.push(["set", "session:data", ["is_merchant", "1"]]);
    }
  })
}

export function formatAmount(amount, {
  isCent,
  hideSign,
} = {}) {
  if (amount == null) {
    return '-';
  }
  const value = isCent ? amount / 100 : amount;
  return `${hideSign ? '' : '$ '}${(Math.round((value || 0) * 100) / 100).toFixed(2)}`;
}