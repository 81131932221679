import React, { useState } from "react";

import { Spinner } from "react-bootstrap";
import Stripe from "./";
import { connect } from "react-redux";
import { getData } from "../../utils";
import { onboardMerchantLink } from "api/orders";
import { useParams } from "react-router-dom";

const Reauth = (props) => {
  const params = useParams();
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    props
      .onboardMerchantLink(params.account, {
        base_url: window.location.origin,
      })
      .then((res) => {
        window.location = getData(res)?.url;
        setLoading(false);
      });
  };

  return (
    <Stripe
      title="Something went wrong!"
      buttonText="Try Again"
      buttonClick={handleClick}
      loading={loading}
    >
      <p>You're here cause one of the below happened:</p>
      <ul>
        <li>The link is expired</li>
        <li>The link was already visited</li>
        <li>The account has been rejected</li>
      </ul>
    </Stripe>
  );
};

export default connect(null, { onboardMerchantLink })(Reauth);
