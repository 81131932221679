import {
  faCheck,
  faClone,
  faMinus,
  faPause,
  faPlay,
  faPlus,
  faSearch,
  faTimesCircle,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";

import { Colors } from "assets/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Images } from "assets/image";
import { LazyLoadImage } from "react-lazy-load-image-component";
import React from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";

const ImageUploader = (props) => {
  const {
    onShowUploader,
    setOnShowUploader,
    allImages,
    selectedImage,
    selectImageFunction,
    getInputProps,
    getRootProps,
    addLoading,
    disabled,
  } = props;
  if (onShowUploader) {
    return (
      <DropZone {...getRootProps()}>
        <div
          className="close_btn"
          onClick={(e) => {
            e.stopPropagation();
            setOnShowUploader(false);
          }}
        >
          <span style={{ fontWeight: "bold" }}>X</span>
        </div>
        <FontAwesomeIcon
          icon={faUpload}
          color="#E45628"
          style={{ width: "60px", height: "60px" }}
        />
        {addLoading ? (
          <Spinner
            animation="border"
            style={{ marginTop: "2rem", color: Colors.primary1 }}
            size="lg"
          />
        ) : (
          <span className="upload_hint" style={{ marginTop: "2rem" }}>
            Drag and drop images or click to browse your files
          </span>
        )}
        {/* <div className='confirm_text' style={{marginTop: '2rem'}}>
            <FontAwesomeIcon icon={faCheck} color='#4BB43A' style={{ width: '17px', height: '17px' }} />
            <span style={{marginLeft: '10px'}}>Upload complete</span>
          </div> */}
        <input {...getInputProps()} />
      </DropZone>
    );
  } else {
    return (
      <AddPhotoWrapper disabled={disabled}>
        <div
          className="add_photo_button"
          onClick={() => setOnShowUploader(true)}
        >
          <FontAwesomeIcon
            icon={faPlus}
            color="#000"
            style={{ width: "10px" }}
          />
          <img style={{ marginLeft: "10px" }} src={Images.image_vector} />
        </div>
        {allImages.map((image, index) => (
          <div
            key={image.id}
            className="imageWrapper"
            id="cypress_image"
            onClick={(e) => {
              e.stopPropagation();
              selectImageFunction(image);
            }}
          >
            <RestaurantImages
              active={selectedImage.map((i) => i.id).includes(image.id)}
              src={image.image_med}
              effect="blur"
              alt="image"
            />
          </div>
        ))}
      </AddPhotoWrapper>
    );
  }
};

export default ImageUploader;

const DropZone = styled.div`
  outline: none;
  width: 80%;
  height: 20rem;
  background: #ffffff;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.12);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  .close_btn {
    padding: 0.75rem;
    width: 1rem;
    height: 1rem;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #e45628;
    color: #fff;
    margin: 1rem;
  }
  .confirm_text {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    color: #4bb43a;
  }
  .upload_hint {
    width: 300px;
    height: 53px;
    left: 890px;
    top: 336px;

    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    /* or 125% */

    text-align: center;
    letter-spacing: 0.4px;
  }
`;

const AddPhotoWrapper = styled.div`
  filter: ${(p) => (p.disabled ? "opacity(0.7) blur(5px)" : "unset")};
  /* background-color: red; */
  width: 80%;
  padding: 5px 5px;
  height: 20rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  overflow-y: scroll;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  /* margin-top: -4rem */
  .imageWrapper {
    height: 5.5rem;
    cursor: pointer;
  }
  .add_photo_button {
    height: 5.5rem;
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12);
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
`;
const RestaurantImages = styled(LazyLoadImage)`
  height: 5.5rem;
  width: 100%;
  object-fit: cover;
  ${(p) => p.active && "border: 2px solid red"};
`;
