import styled from "styled-components";
import { Colors } from "assets/colors";

export const StyledWrapper = styled.div`
  position: relative;
  padding-top: 40px;

  .loading-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 119px;

    .spinner-border {
      width: 18px;
      height: 18px;
      border-width: 2.5px;
      border-color: #2b64f5;
      border-right-color: transparent;
    }
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .flex-row-end {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .title-row {
    padding-left: 56px;
    padding-right: 56px;
    margin-bottom: 40px;
    display: block;

    .title {
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
      color: ${Colors.textPrimary1} !important;
      margin-left: 12px;
      margin-bottom: 0;
    }

    .back {
      width: 32px;
      height: 32px;

      &-btn {
        padding: 0;
      }
    }
  }
`;
