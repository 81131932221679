import React from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";

import SelectMerchant from "components/Layout/Sidebar/SelectMerchant";
import CreateRestaurant from "components/home/CreateRestaurant";

const NoRestaurant = ({ locale }) => {
  return (
    <Row className="fulls">
      <Col xs={12} sm={12} className="text-center">
        <h2 className="h3 text-white">{locale.no_restaurant}</h2>
      </Col>
      <Col xs={12} sm={12} className="text-center">
        <SelectMerchant /> <CreateRestaurant topbar={true} />
      </Col>
    </Row>
  );
};

export default connect((state) => ({
  locale: state.locale[state.locale.active],
}))(NoRestaurant);
