import styled from "styled-components";
import { Colors } from "assets/colors";

export const StyledCurvedHeader = styled.div`
  width: auto;
  position: fixed;
  top: 0;
  left: 272px;
  z-index: 107;
  display: flex;
  color: ${Colors.black};

  .left {
    width: 235px;
  }
  .right {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: auto;

    .user {
      padding: 1rem 0 4rem;
      font-size: 20px;
    }
  }

  @media (min-width: 1500px) {
    background-size: 100%;
  }
`;
