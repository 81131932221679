import { Card, Col, Container, Form, Row } from "react-bootstrap";
import {
  Link,
  Redirect,
  Route,
  BrowserRouter as Router,
  withRouter,
} from "react-router-dom";
import React, { useEffect, useState } from "react";

import { Colors } from "assets/colors";
import Loading from "components/Loading";
import Navbar from "components/Layout/Navbar";
import { connect } from "react-redux";
import { getData } from "../../utils";
import jsCookie from "js-cookie";
import { loginUser } from "actions/user";
import { makeAlert } from "actions";
import { resetPasswordConfirm } from "api/auth";
import styled from "styled-components";

const Switch = styled.div`
  a {
    font-size: 15px;
    color: #555 !important;

    span {
      display: block;
      color: ${Colors.primary1};
      text-decoration: underline;
      font-weight: bold;
    }
  }
`;

const Submit = styled.div`
  a {
    color: ${Colors.white} !important;
    font-weight: bold;
    font-size: 15px !important;
  }
`;

const ForgetWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  a {
    font-size: 15px;
    color: #555 !important;
  }
`;

const StyledCard = styled(Card)`
  @media (max-width: 1120px) {
    width: 100vw;
    height: 100vh;
    margin: 0;
    border: none;
    overflow: auto;
  }

  .error {
    width: 100%;
    border: 1px solid red;
  }
`;

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({});
  const [redirect, setRedirect] = useState(false);
  const [error] = useState({});
  const [formError, setFormError] = useState(false);

  const handleChange = (e) => {
    const { value, name } = e.target;

    setForm({ ...form, [name]: value });
  };

  const validatePasses = () => {
    /** Check if both password are the same we can reset it */
    let valid = false;

    if (
      form.new_password1 &&
      form.new_password2 &&
      form.new_password1 === form.new_password2
    ) {
      valid = true;
      setFormError(false);
    } else {
      setFormError(true);
    }

    return valid;
  };

  const submit = () => {
    if (validatePasses()) {
      setLoading(true);
      const { uid, token } = props.match.params;
      props
        .resetPasswordConfirm({ ...form, uid, token })
        .then((res) => {
          props.makeAlert("Password succesfully changed!");
          setRedirect(true);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setRedirect(props.isLoggedIn);
  }, [props.isLoggedIn]);

  const { from } = props.location.state || { from: { pathname: "/console" } };

  if (redirect === true) {
    return <Redirect to={from} />;
  }

  return (
    <>
      <Navbar />
      <div
        style={{ height: "100vh", display: "flex", justifyContent: "center" }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col xl={10} lg={12} md={9}>
              <StyledCard className="o-hidden border-0 shadow-lg my-5">
                <Card.Body className="p-3">
                  <Row className="">
                    <Col md={12}>
                      <h3 className="h4 text-dark my-2 text-center font-weight-bold">
                        Confirm Reset Password
                      </h3>
                      <p className="p-0 m-0 text-center">
                        Enter your new password
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <div className="col-lg-12">
                      <div className="p-5">
                        <Form className="user" onSubmit={submit}>
                          <Form.Group className="row">
                            <Col md={{ span: 6, offset: 3 }} className="mb-3">
                              <Form.Control
                                type="password"
                                className={`form-control form-control-user ${
                                  formError && "error"
                                }`}
                                placeholder="New Password"
                                name="new_password1"
                                onChange={handleChange}
                                value={form.email}
                              />
                              {error.firstnameError && (
                                <Form.Control.Feedback
                                  style={{ display: "block" }}
                                  type="invalid"
                                >
                                  *required
                                </Form.Control.Feedback>
                              )}
                            </Col>
                            <Col md={{ span: 6, offset: 3 }} className="mb-3">
                              <Form.Control
                                type="password"
                                className={`form-control form-control-user ${
                                  formError && "error"
                                }`}
                                placeholder="Confirm New Password"
                                name="new_password2"
                                onChange={handleChange}
                                value={form.email}
                              />
                              {error.firstnameError && (
                                <Form.Control.Feedback
                                  style={{ display: "block" }}
                                  type="invalid"
                                >
                                  *required
                                </Form.Control.Feedback>
                              )}
                            </Col>
                          </Form.Group>

                          <Submit className="text-center">
                            <a
                              onClick={submit}
                              className="btn btn-user"
                              style={{
                                color: Colors.white,
                                background: "${Colors.primary1}",
                                minWidth: 120,
                                fontWeight: "bold",
                              }}
                            >
                              {!loading ? (
                                "Reset Password"
                              ) : (
                                <Loading noPadding={true} />
                              )}
                            </a>
                          </Submit>
                          <br />
                        </Form>
                      </div>
                    </div>
                  </Row>
                </Card.Body>
              </StyledCard>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    locale: state.locale[state.locale.active],
  }),
  { resetPasswordConfirm, loginUser, makeAlert }
)(Login);
