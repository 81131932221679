import { fetchApi } from ".";
import { generateQuery } from "../utils";

export const getDiscountsApiCall =
  (restaurantId, page, searchKey, cancelObj) => async (dispatch, getState) => {
    let query = generateQuery([
      searchKey ? `search=${searchKey}` : "",
      page > 1 ? `page=${page}` : "",
    ]);

    let res = await dispatch(
      fetchApi(
        `restaurants/${restaurantId}/discounts/${query}`,
        null,
        null,
        null,
        null,
        null,
        cancelObj
      )
    );

    return res;
  };

export const getDiscountByIdApiCall =
  (restaurantId, id) => async (dispatch, getState) => {
    let res = await dispatch(
      fetchApi(`restaurants/${restaurantId}/discounts/${id}/`)
    );

    return res;
  };

export const createDiscountApiCall =
  (restaurantId, data) => async (dispatch, getState) => {
    let res = await dispatch(
      fetchApi(`restaurants/${restaurantId}/discounts/`, "POST", data)
    );

    return res;
  };

export const editDiscountApiCall =
  (restaurantId, id, data) => async (dispatch, getState) => {
    let res = await dispatch(
      fetchApi(`restaurants/${restaurantId}/discounts/${id}/`, "PUT", data)
    );

    return res;
  };

export const getItemsApiCall = (restaurantId) => async (dispatch, getState) => {
  let res = await dispatch(
    fetchApi(
      `restaurants/${restaurantId}/items/?query={id,name,description,images{image_thumbnail}}`
    )
  );

  return res;
};

export const deleteDiscountApiCall =
  (restaurantId, id) => async (dispatch, getState) => {
    let res = await dispatch(
      fetchApi(`restaurants/${restaurantId}/discounts/${id}/`, "DELETE")
    );

    return res;
  };
