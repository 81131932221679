import { Card } from "react-bootstrap";
import { Colors } from "assets/colors";
import styled from "styled-components";

export const Image = styled.div`
  width: 100%;
  height: 115px;
  object-fit: cover;
  background-image: url(${(p) => p.src});
  background-size: cover;
  z-index: 0;
  display: flex;
  justify-content: flex-end;
`;

export const StyledCard = styled(Card)`
  height: 180px;
  width: 320px;
  cursor: pointer;
  min-height: 80px;
  color: ${Colors.white};
  border-radius: 0;
  background-size: cover;
  border: none !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 5px 8px #bcbcc0 !important;

  .header {
    display: flex;
    justify-content: space-between;
    background: #f3f3f3;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 115px;
    z-index: 1;
    span {
      display: unset !important;
    }
  }

  .card-body {
    z-index: 99;
    height: 83px;
    background: #f3f3f3;
    padding: 0;
    display: flex;

    .type-badge {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      width: 30px;
      padding-bottom: 10px;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    padding: 7px 4px;
    width: calc(100% - 30px);
    .buttonsWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 0.5rem 0 0.5rem;
      display: inline;
      width: 25px;
      position: absolute;
      right: 19px;
    }
    .times {
      display: flex;
      flex-wrap: wrap;
      height: 56px;
      overflow: hidden;
      width: calc(100% - 42px);
    }
    .time-rule {
      background-color: ${Colors.lightGray2};
      padding: 2px 4px;
      border-radius: 4px;
      margin: 1.5px;
      font-size: 9px;
      white-space: nowrap;
      height: fit-content;
      width: 48%;
      text-overflow: ellipsis;
      overflow: hidden;

      &__sm {
        font-size: 7px;
      }
    }
    .more {
      font-size: 9px;
      color: black;
      text-align: left;
      margin-right: auto;
      margin-left: 10px;
      margin-top: 3px;
    }
    .card-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #8e8e93 !important;
      text-transform: uppercase;
      padding-bottom: 7px;
      margin: 0;
      font-size: 16px;
      margin-bottom: 3px;
      width: 86%;
    }

    .action-btn {
      margin: 4px;
      border-radius: 50%;
      width: 26px;
      height: 26px;
      background: ${Colors.darkGray2};
      color: ${Colors.white};
      display: flex;
      border: none;
      outline: none;
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14),
        0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
      position: relative;

      svg {
        color: white;
        font-size: 12px;
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
      }
    }
  }

  hr {
    border-top: 1px solid ${Colors.white};
  }

  .stats {
    display: flex;
    justify-content: space-around;
  }
`;
export const StyledAddMenuCard = styled(StyledCard)`
  background: #f1f1f1;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.12);
  color: #121212;
  background-image: none;
  display: flex;
  font-size: 17px;
  flex-direction: row;
  justify-content: center;
  align-item: center;
  border-radius: 0 !important;

  p {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Options = styled.div`
  padding: 12px 15px;
  padding-top: 0;
  float: right;
  width: 50px;
  text-align: center;
  margin-right: -20px;
`;
