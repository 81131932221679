import styled from "styled-components";
import { Colors } from "assets/colors";

export const StyledTableModal = styled.div`
  width: 1128px;
  max-width: 100vw;
  padding: 24px;
  min-height: 562px;

  .title {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: #1a1f36;
    margin-bottom: 40px;
  }

  table {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
  }

  .results {
    padding-left: 0 !important;
  }

  .status {
    &--new {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #9c400b;
      background: #f9e6d2;
      border-radius: 4px;
      padding: 4px 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 4px;
        width: 16px;
        height: 16px;
      }
    }
    &--scheduled {
      padding: 4px 6px;
    }

    &--accepted {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #045f5a;
      background: #deefef;
      border-radius: 4px;
      padding: 4px 8px;

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 8px;
        width: 16px;
        height: 16px;
      }
    }

    &--ready {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #0e623f;
      background: #cbf4c9;
      border-radius: 4px;
      padding: 4px 8px;
    }

    &--on-the-way {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #4b3a95;
      background: #e5dffc;
      border-radius: 4px;
      padding: 4px 8px;
    }

    &--completed {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #0e623f;
      background: #cbf4c9;
      border-radius: 4px;
      padding: 4px 8px;
    }

    &--rejected {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: ${Colors.textPrimary1};
      background: #e7e8e9;
      border-radius: 4px;
      padding: 4px 8px;
    }

    &--cancelled {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #aa2720;
      background: #fae5e4;
      border-radius: 4px;
      padding: 4px 8px;
    }
  }
`;
