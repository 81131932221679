import React, { Suspense, useEffect, useState } from 'react';

import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import {
  BrowserRouter as Router, Route, Switch, useLocation,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';

import './App.less';
import 'react-loading-skeleton/dist/skeleton.css';
import Alerts from 'containers/alerts';
import { getInitialData } from 'actions';
import OverlayLoading from 'components/overlayLoading';
import ErrorBoundary from 'components/errorBoundary';
import ErrorFallback from 'components/errorFallback';
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from '@mui/material/styles';
import { ModalProvider } from './context/modalContext';
import Routes from './routes';
import { store, persistor } from './store';
import { initialCrispChat } from './utils';

toast.configure();

const theme = {
  primary: '#2B64F5',
  dark: '#222',
  orange: '#FF7D00',
  orangeLow: '#FF7D0099',
};

const MuiTheme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

function App(props) {
  const [loading, setLoading] = useState(store.getState().loading);
  const location = useLocation();

  useEffect(() => {
    store.dispatch(getInitialData());
    const user = store.getState()?.user;
    const organizationId = user?.selectedOrganization;
    initialCrispChat(store?.dispatch, organizationId, user?.info, user?.selectedRestaurantData);
  }, []);

  useEffect(
    () => {
      if (['login', 'register', '404'].some((path) => location.pathname.includes(path))) {
        return;
      }
      localStorage.setItem('previousPath', `${location.pathname}${location.search}`);
    },
    [location],
  );

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Provider store={store}>
        <PersistGate loading={<OverlayLoading />} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <MuiThemeProvider theme={MuiTheme}>
              <ModalProvider>
                <Alerts />
                <Suspense fallback={<OverlayLoading />}>
                  <Switch>
                    <Routes />
                  </Switch>
                </Suspense>
              </ModalProvider>
            </MuiThemeProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
