export const SET_SELECTED_DATE_TYPE_TITLE = "SET_SELECTED_DATE_TYPE_TITLE";
export const SET_IS_WEEKLY = "SET_IS_WEEKLY";
export const SET_WEEK_DAYS = "SET_WEEK_DAYS";
export const SET_FROM_HOUR = "SET_FROM_HOUR";
export const SET_TO_HOUR = "SET_TO_HOUR";
export const SET_IS_DAILY = "SET_IS_DAILY";
export const SET_HAS_END_DATE = "SET_HAS_END_DATE";
export const SET_START_DATE = "SET_START_DATE";
export const SET_START_DATE_FROM_HOUR = "SET_START_DATE_FROM_HOUR";
export const SET_END_DATE = "SET_END_DATE";
export const SET_END_DATE_FROM_HOUR = "SET_END_DATE_FROM_HOUR";
export const RESET_DATES = "RESET_DATES";
export const RESET_WEEKS = "RESET_WEEKS";
export const RESET_DAILY = "RESET_DAILY";
export const RESET_END_DATE = "RESET_END_DATE";
export const FILL_DATES_FIELDS = "FILL_DATES_FIELDS";
