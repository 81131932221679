import React from "react";
import { ReactComponent as ExportIcon } from "assets/image/export.svg";
// import { ReactComponent as ArrowDownIcon } from "assets/image/arrowDown.svg";
import { StyledPaginate } from "./styles";
import SkeletonLoading from "components/common/SkeletonLoading";
import Tooltip from "../Tooltip";
import { ReactComponent as NoItemIcon } from "assets/image/noItem.svg";
import { ReactComponent as IIcon } from "assets/image/i.svg";

const PaginateTable = ({
  headers,
  dataArray,
  onViewAllClick,
  rowCreators,
  detailed,
  rangeEl,
  viewMoreText,
  loading,
  dataPaging,
  getData,
  pagination,
  onLoadMore,
  loadMoreText,
  hideResults,
  noRecord,
  onClick,
  newRowClasses,
  ...props
}) => {
  return (
    <StyledPaginate>
      <table>
        <tbody>
          <tr className={rangeEl && "no-border"}>
            {headers.map((header, index) => (
              <th
                key={index}
                className={rangeEl && "th-range"}
                style={{ width: header.width }}
              >
                {header.title}
                {header.info && (
                  <Tooltip
                    tooltipBoxClasses=""
                    label={<IIcon className="info" />}
                    tooltipBody={<>{header.info}</>}
                    tooltipBodyClasses="tooltip"
                  />
                )}
              </th>
            ))}
            {rangeEl}
          </tr>
          {dataArray?.length === 0
            ? null
            : dataArray?.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  className={
                    newRowClasses ? (row?.new ? newRowClasses : null) : null
                  }
                  onClick={() => (onClick ? onClick(row) : null)}
                >
                  {rowCreators.map((rowCreator, index) => (
                    <td key={index}>
                      {loading && !row?.id ? (
                        <SkeletonLoading
                          borderRadius={"2px"}
                          width={"70%"}
                          height={"16px"}
                          count={1}
                        />
                      ) : (
                        rowCreator(row, rowIndex)
                      )}
                    </td>
                  ))}
                </tr>
              ))}
        </tbody>
      </table>
      {dataArray?.length === 0 ? (
        <div className="no-item">
          <NoItemIcon />
          <p>
            {noRecord
              ? noRecord
              : "There are no records to display for the selected date."}
          </p>
        </div>
      ) : null}
      <div className={`table-footer ${detailed ? "detailed-footer" : ""}`}>
        {hideResults ? null : loading ? (
          <p className="results">
            <SkeletonLoading
              borderRadius={"3px"}
              width={"100px"}
              height={"18px"}
              count={1}
            />
          </p>
        ) : dataArray?.length > 0 ? (
          <p className="results">
            <strong>{dataPaging?.count}</strong> &nbsp; results
            {/* {detailed && (
              <div className="export-all">
                <ExportIcon /> Export all orders
              </div>
            )} */}
          </p>
        ) : null}

        {detailed ? null : dataArray?.length && viewMoreText ? (
          <p className="view-all" onClick={onViewAllClick}>
            {viewMoreText} <ExportIcon />
          </p>
        ) : null}

        {loadMoreText ? (
          <p className="view-all center" onClick={onLoadMore}>
            {loading && dataArray?.length && dataArray[0]?.id ? (
              <>
                <span>Loading</span>
              </>
            ) : (
              loadMoreText
            )}
          </p>
        ) : null}

        {detailed && <div id="page">{pagination}</div>}
      </div>
    </StyledPaginate>
  );
};

export default PaginateTable;
