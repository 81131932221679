import { fetchApi } from './'

export const updateOpeningHourById = (id, body) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`opening-hours/${id}/`, 'PUT', body, true))

  return res
}

export const addOpeningHour = (body) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`opening-hours/`, 'POST', body, true))

  return res
}
