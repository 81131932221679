import React, { useContext, useEffect, useState } from 'react';

import { ReactComponent as ArrowDownIcon } from 'assets/image/arrowDown.svg';
import { ReactComponent as CrossIcon } from 'assets/image/cross.svg';
import CustomPagination from 'components/CustomPagination';
import PaginateTable from 'components/PaginateTable';
import RangeCalendar from 'components/RangeCalendar';
import { ReactComponent as ScheduledIcon } from 'assets/image/scheduled.svg';
import { ReactComponent as TickIcon } from 'assets/image/tick.svg';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  calcSomeDaysAgoDate, formatAmount, formatDate, getData,
} from 'utils';
import { StyledTableModal } from './styles';
import { getLatestOrders } from '../../../api/orders';
import { getRestaurantById } from '../../../api/restaurant';
import PaginatedOrders from '../PaginatedOrders';
import { ModalContext } from '../../../context/modalContext';

import * as orderStatus from '../../../utils/status';

function Orders() {
  const { handleModal } = useContext(ModalContext);
  const [ordersStartDateDatePicker, setOrdersStartDateDatePicker] =
    useState(null);
  const [ordersEndDateDatePicker, setOrdersEndDateDatePicker] = useState(null);
  const [ordersEndDate, setOrdersEndDate] = useState(null);
  const [ordersStartDate, setOrdersStartDate] = useState(null);
  const [ordersShowDatePicker, setOrdersShowDatePicker] = useState(false);
  const [ordersData, setOrdersData] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
  const [ordersLoading, setOrdersLoading] = useState(true);
  const [ordersFilter, setOrdersFilter] = useState('30days');
  const [results, setResults] = useState(' - ');
  const [paymentDriver, setPaymentDriver] = useState('');
  const dispatch = useDispatch();
  const selectedRestaurantId = useSelector(
    (state) => state.user?.selectedRestaurant,
  );

  const ORDERS_TABLE = [
    {
      element: '7 Days',
      filterClassName: 'overview-filter',
      activeFilterClassName: 'overview-filter--active',
      name: '7days',
    },
    {
      element: '14 Days',
      filterClassName: 'overview-filter',
      activeFilterClassName: 'overview-filter--active',
      name: '14days',
    },
    {
      element: '30 Days',
      filterClassName: 'overview-filter',
      activeFilterClassName: 'overview-filter--active',
      name: '30days',
    },
  ];
  const [ordersDropdown, setOrdersDropdown] = useState('daily');

  useEffect(() => {
    /** Clear date picker selected dates */
    if (ordersFilter !== 'date' && ordersEndDateDatePicker) {
      setOrdersStartDateDatePicker(null);
      setOrdersEndDateDatePicker(null);
    }
  }, [ordersFilter]);

  useEffect(() => {
    /** Set StartDate and EndDate for each filter */
    switch (ordersFilter) {
    case '7days':
      setOrdersStartDate(calcSomeDaysAgoDate(7));
      setOrdersEndDate(formatDate(new Date()));

      break;
    case '14days':
      setOrdersStartDate(calcSomeDaysAgoDate(14));
      setOrdersEndDate(formatDate(new Date()));

      break;
    case '30days':
      setOrdersStartDate(calcSomeDaysAgoDate(30));
      setOrdersEndDate(formatDate(new Date()));

      break;
    case 'date':
      setOrdersStartDate(formatDate(ordersStartDateDatePicker));
      setOrdersEndDate(formatDate(ordersEndDateDatePicker));
      break;

    default:
      break;
    }
  }, [ordersFilter, ordersStartDateDatePicker, ordersEndDateDatePicker]);

  useEffect(() => {
    getOrders();
  }, [selectedRestaurantId, ordersStartDate, ordersEndDate]);

  useEffect(() => {
    dispatch(getRestaurantById(selectedRestaurantId)).then((res) => {
      setPaymentDriver(getData(res).parent.payment_driver);
    });
  }, [selectedRestaurantId]);

  const getOrders = () => {
    if (ordersStartDate) {
      setOrdersLoading(true);
      setOrdersData([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
      const queries = {
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        ordering: '-is_new,-timestamp',
        start: ordersStartDate,
        end: ordersEndDate,
      };
      dispatch(getLatestOrders(selectedRestaurantId, queries))
        .then((res) => {
          setOrdersData(getData(res)?.results.filter((o) => Object.keys(o?.order_nets).length > 0));
          setResults(getData(res)?.count);
          setOrdersLoading(false);
        })
        .catch((err) => {
          setOrdersLoading(false);
        });
    }
  };

  const getDayAndMonth = (date) => {
    const MONTHS = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    return `${MONTHS[new Date(date).getMonth()]} ${new Date(date).getDate()}`;
  };

  const getOrdersFilter = () => {
    switch (ordersFilter) {
    case '7days':
      return '7 days';
    case '14days':
      return '14 days';
    case '30days':
      return '30 days';
    case 'date':
      return (
        <>
          {ordersStartDate ? getDayAndMonth(ordersStartDate) : ''}
          {' '}
          {ordersEndDate && ' →'}
          {ordersEndDate ? ` ${getDayAndMonth(ordersEndDate)}` : ''}
        </>
      );

    default:
      break;
    }
  };

  const formatDateToTableDate = (date) => {
    const MONTHS = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    return date
      ? `${MONTHS[new Date(date).getMonth()]} ${moment(date).format(
        'DD',
      )} , ${moment(date).format('HH:mm')}`
      : null;
  };

  const getStatus = (order) => {
    /** Map order state to a badge */
    switch (order?.state) {
    case orderStatus.NEW:
      return (
        <div
          className={`status status--new ${
            isScheduled(order) ? 'status--scheduled' : ''
          }`}
        >
          {isScheduled(order) ? <ScheduledIcon /> : null}
          <span>New</span>
        </div>
      );
      break;
    case orderStatus.IN_PROGRESS:
      return (
        <div
          className={`status status--accepted ${
            isScheduled(order) ? 'status--scheduled' : ''
          }`}
        >
          {isScheduled(order) ? <ScheduledIcon /> : null}
          <span>Accepted</span>
        </div>
      );
      break;
    case orderStatus.CANCELLED_BY_RESTAURANT:
      return <div className="status status--cancelled">Cancelled</div>;
      break;
    case orderStatus.READY:
      return <div className="status status--ready">Ready</div>;
      break;
    case orderStatus.ON_THE_WAY:
      return <div className="status status--on-the-way">On the Way</div>;
      break;
    case orderStatus.COMPLETED:
      return <div className="status status--completed">Completed</div>;
      break;
    case orderStatus.IN_PROGRESS_UPDATED:
      return <div className="status status--accepted">Accepted</div>;
      break;
    case orderStatus.READY_UPDATED:
      return <div className="status status--ready">Ready</div>;
      break;
    case orderStatus.STRANDED:
      return <div className="">-</div>;
      break;
    case orderStatus.REJECTED_BY_RESTAURANT:
      return <div className="status status--rejected">Rejected</div>;
      break;

    default:
      break;
    }
  };

  const isScheduled = (order) => order?.scheduled_for;

  const handleStatus = (order) => {
    /** Map order state to a badge */
    switch (order?.state) {
    case orderStatus.NEW:
      return (
        <div
          className={`status status--new ${
            isScheduled(order) ? 'status--scheduled' : ''
          }`}
        >
          {isScheduled(order) ? <ScheduledIcon /> : null}
          <span>New</span>
        </div>
      );
      break;
    case orderStatus.IN_PROGRESS:
      return (
        <div
          className={`status status--accepted ${
            isScheduled(order) ? 'status--scheduled' : ''
          }`}
        >
          {isScheduled(order) ? <ScheduledIcon /> : null}
          <span>Accepted</span>
        </div>
      );
      break;
    case orderStatus.CANCELLED_BY_RESTAURANT:
      return <div className="status status--cancelled">Cancelled</div>;
      break;
    case orderStatus.READY:
      return <div className="status status--ready">Ready</div>;
      break;
    case orderStatus.ON_THE_WAY:
      return <div className="status status--on-the-way">On the Way</div>;
      break;
    case orderStatus.COMPLETED:
      return <div className="status status--completed">Completed</div>;
      break;
    case orderStatus.IN_PROGRESS_UPDATED:
      return <div className="status status--accepted">Accepted</div>;
      break;
    case orderStatus.READY_UPDATED:
      return <div className="status status--ready">Ready</div>;
      break;
    case orderStatus.STRANDED:
      return <div className="">-</div>;
      break;
    case orderStatus.REJECTED_BY_RESTAURANT:
      return <div className="status status--rejected">Rejected</div>;
      break;

    default:
      break;
    }
  };

  const createBadge = (orderData, index) => {
    /** Create payment result badge for the table */
    if (orderData?.state === orderStatus.NEW) {
      return ' - ';
    } if (orderData?.state === orderStatus.CANCELLED_BY_RESTAURANT) {
      return (
        <div key={index} className="paid refund">
          <CrossIcon />
          <span>Cancelled</span>
        </div>
      );
    } if (orderData?.state === orderStatus.REJECTED_BY_RESTAURANT) {
      return (
        <div key={index} className="paid refund">
          <CrossIcon />
          <span>Rejected</span>
        </div>
      );
    }
    return (
      <div key={index} className="paid ">
        <TickIcon />
        <span>Paid</span>
      </div>
    );
  };

  const orderRowCreator = () => [
    (row, index) => formatDateToTableDate(row?.state_modified),
    (row, index) => handleStatus(row),
    (row, index) => row?.number,
    (row, index) => (
      <span className="bold">
        {formatAmount(row.order_nets?.restaurant_net)}
      </span>
    ),
    (row, index) => (
      <span className="bold">
        {formatAmount(row?.order_nets.total_tax)}

      </span>
    ),
    (row, index) => (
      <span className="bold">
        {formatAmount(row?.order_nets.merchant_payment_fee)}
      </span>
    ),
    (row, index) => (
      <span className="bold">
        {formatAmount(row?.order_nets.commission)}
      </span>
    ),
  ];

  return (
    <div className="orders-box">
      <div className="overview-top table-spacing">
        <div className="overview-title">Orders table</div>
        <div className="overview-filters">
          {/* <button className={`export`}>
            <ExportIcon /> Export
          </button> */}
          {/* <span className="separator"></span> */}
          {/* <DropDown
            btnEl={
              <>
                {ordersDropdown} <TwowayArrowIcon className="m-left-12" />
              </>
            }
            btnClasses={`overview-filter dropdown-btn`}
            listClasses="dropdown-list"
            itemClasses="dropdown-item"
            listItems={["Daily", "Weekly", "Monthly"]}
            dropdownClasses="dropdown"
            setSelected={setOrdersDropdown}
            selected={ordersDropdown}
          /> */}

          {/* <span className="separator separator--daily"></span> */}
          {ORDERS_TABLE.map((filter, index) => (
            <button
              key={index}
              className={`${filter.filterClassName} ${
                ordersFilter === filter.name
                  ? filter.activeFilterClassName
                  : ''
              }`}
              onClick={() => setOrdersFilter(filter.name)}
            >
              {filter.element}
            </button>
          ))}
          <RangeCalendar
            actionOnSelect={() => setOrdersFilter('date')}
            startDate={ordersStartDateDatePicker}
            setStartDate={setOrdersStartDateDatePicker}
            endDate={ordersEndDateDatePicker}
            setEndDate={setOrdersEndDateDatePicker}
            showDatePicker={ordersShowDatePicker}
            setShowDatePicker={setOrdersShowDatePicker}
            isActive={ordersFilter === 'date'}
            customCanelFunction={() => ordersFilter === 'date' && setOrdersFilter('7days')}
          />
        </div>
      </div>
      {ordersDropdown === 'daily' ? (
        // ============================== Daily table ==============================
        <PaginateTable
          loading={ordersLoading}
          viewMoreText="View all orders"
          headers={[
            { title: 'Payment date', width: '149.02px' },
            { title: 'status', width: '161.36px' },
            { title: 'Order number', width: '156.95px' },
            { title: 'Merchant net', width: '152.7px' },
            { title: 'Tax', width: '118.42px' },
            {
              title: 'Payment Fees',
              info: paymentDriver === 'stripe' ? '2.9% per transaction.' : '',
              width: '175.95px',
            },
            {
              title: 'Application Fees',
              info: 'Fixed percent of order subtotal taken from every order.',
              width: 'auto',
            },
          ]}
          dataArray={ordersData}
          dataPaging={{ count: results }}
          rowCreators={orderRowCreator()}
          onViewAllClick={() => {
            handleModal(
              <PaginatedOrders
                selectedRestaurantId={selectedRestaurantId}
                ordersStartDate={ordersStartDate}
                ordersEndDate={ordersEndDate}
                formatDateToTableDate={formatDateToTableDate}
                getOrdersFilter={getOrdersFilter}
                rowCreators={orderRowCreator()}
              />,
              // <StyledTableModal>
              //   <div className="title">Orders · {getOrdersFilter()}</div>
              //   <PaginateTable
              //     getData={getOrders}
              //     loading={ordersLoading}
              //     headers={[
              //       "Payment date",
              //       "status",
              //       "Order number",
              //       "Merchant net",
              //       "Tax",
              //       "Stripe Fees",
              //       "Payment Fees",
              //     ]}
              //     dataArray={ordersData}
              //     dataPaging={pagination}
              //     rowCreators={[
              //       (row, index) => formatDateToTableDate(row?.state_modified),
              //       (row, index) => {
              //         return (
              //           <span
              //             className={`paid ${!row?.is_paid ? "refund" : ""}`}
              //           >
              //             <TickIcon /> {row?.is_paid ? "Paid" : "Refunded"}
              //           </span>
              //         );
              //       },
              //       (row, index) => {
              //         row?.number;
              //       },
              //       (row, index) => (
              //         <span className="bold">{row?.nets?.restaurant_net}</span>
              //       ),
              //       (row, index) => (
              //         <span className="bold">{row?.nets?.total_tax}</span>
              //       ),
              //       (row, index) => (
              //         <span className="bold">{row?.nets?.stripe}</span>
              //       ),
              //       (row, index) => <span className="bold">{row?.number}</span>,
              //     ]}
              //     detailed={true}
              //   />
              // </StyledTableModal>
            );
          }}
        />
      ) : ordersDropdown === 'weekly' ? (
        <>
          {[1, 2].map((week, index) => (
            // ============================== Weekly table ==============================
            <React.Fragment key={index}>
              <PaginateTable
                loading={ordersLoading}
                viewMoreText={`View all for week ${week}`}
                headers={[
                  'Payment date',
                  'status',
                  'Order number',
                  'Merchant net',
                  'Tax',
                  'Payment Fees',
                  'Application Fees',
                ]}
                dataArray={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                rangeEl={(
                  <div className="range">
                    <div className="range--left">
                      <p className="range-title">
                        Week
                        {week}
                      </p>
                      <p className="range-range">{getOrdersFilter()}</p>
                    </div>
                    <ArrowDownIcon />
                  </div>
                )}
                rowCreators={[
                  (row, index) => '22 Nov, 15:08',
                  (row, index) => <span>{handleStatus(row)}</span>,
                  (row, index) => '2HvlRPPcv1ElF',
                  (row, index) => <span className="bold">$50.12</span>,
                  (row, index) => <span className="bold">$0.28</span>,
                  (row, index) => <span className="bold">$1.43</span>,
                  (row, index) => <span className="bold">$1.11</span>,
                ]}
                onViewAllClick={() => {
                  handleModal(
                    <StyledTableModal>
                      <div className="title">
                        Orders .
                        {getOrdersFilter()}
                      </div>
                      <PaginateTable
                        loading={ordersLoading}
                        headers={[
                          'Payment date',
                          'status',
                          'Order number',
                          'Merchant net',
                          'Tax',
                          'Payment Fees',
                          'Application Fees',
                        ]}
                        dataArray={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                        rowCreators={[
                          (row, index) => '22 Nov, 15:08',
                          (row, index) => (index % 2 === 0 ? (
                            <span className="paid">
                              <TickIcon />
                              {' '}
                              Paid
                            </span>
                          ) : (
                            <span className="paid refund">
                              <CrossIcon />
                              {' '}
                              Refunded
                            </span>
                          )),
                          (row, index) => '2HvlRPPcv1ElF',
                          (row, index) => <span className="bold">$50.12</span>,
                          (row, index) => <span className="bold">$0.28</span>,
                          (row, index) => <span className="bold">$1.43</span>,
                          (row, index) => <span className="bold">$1.11</span>,
                        ]}
                        detailed
                        // pagination={
                        // <CustomPagination
                        //   paginationObj={pagination}
                        //   page={page}
                        //   setPage={setPage}
                        // />
                        // }
                      />
                      {/* <CustomPagination
                        paginationObj={pagination}
                        page={page}
                        setPage={setPage}
                      /> */}
                    </StyledTableModal>,
                  );
                }}
              />
            </React.Fragment>
          ))}
          {' '}
          <div className="table-pagination">
            {/* <CustomPagination
              paginationObj={pagination}
              page={page}
              setPage={setPage}
            /> */}
          </div>
        </>
      ) : (
        <>
          {' '}
          {[1, 2].map((month, index) => (
            // ============================== Monthly table ==============================
            <React.Fragment key={index}>
              <PaginateTable
                viewMoreText={`View all for month ${month}`}
                headers={[
                  'Payment date',
                  'status',
                  'Order number',
                  'Merchant net',
                  'Tax',
                  'Payment Fees',
                  'Application Fees',
                ]}
                dataArray={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                rangeEl={(
                  <div className="range">
                    <div className="range--left">
                      <p className="range-title">
                        Month
                        {month}
                      </p>
                      <p className="range-range">{getOrdersFilter()}</p>
                    </div>
                    <ArrowDownIcon />
                  </div>
                )}
                rowCreators={[
                  (row, index) => '22 Nov, 15:08',
                  (row, index) => (index % 2 === 0 ? (
                    <span className="paid">
                      <TickIcon />
                      {' '}
                      Paid
                    </span>
                  ) : (
                    <span className="paid refund">
                      <CrossIcon />
                      {' '}
                      Refunded
                    </span>
                  )),
                  (row, index) => '2HvlRPPcv1ElF',
                  (row, index) => <span className="bold">$50.12</span>,
                  (row, index) => <span className="bold">$0.28</span>,
                  (row, index) => <span className="bold">$1.43</span>,
                  (row, index) => <span className="bold">$1.11</span>,
                ]}
                onViewAllClick={() => {
                  handleModal(
                    <StyledTableModal>
                      <div className="title">
                        Orders .
                        {getOrdersFilter()}
                      </div>
                      <PaginateTable
                        headers={[
                          'Payment date',
                          'status',
                          'Order number',
                          'Merchant net',
                          'Tax',
                          'Payment Fees',
                          'Application Fees',
                        ]}
                        dataArray={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                        rowCreators={[
                          (row, index) => '22 Nov, 15:08',
                          (row, index) => (index % 2 === 0 ? (
                            <span className="paid">
                              <TickIcon />
                              {' '}
                              Paid
                            </span>
                          ) : (
                            <span className="paid refund">
                              <CrossIcon />
                              {' '}
                              Refunded
                            </span>
                          )),
                          (row, index) => '2HvlRPPcv1ElF',
                          (row, index) => <span className="bold">$50.12</span>,
                          (row, index) => <span className="bold">$0.28</span>,
                          (row, index) => <span className="bold">$1.43</span>,
                          (row, index) => <span className="bold">$1.11</span>,
                        ]}
                        detailed
                        pagination={(
                          <CustomPagination
                            paginationObj={pagination}
                            page={page}
                            setPage={setPage}
                          />
                        )}
                      />
                    </StyledTableModal>,
                  );
                }}
              />
            </React.Fragment>
          ))}
          <div className="table-pagination">
            <CustomPagination
              paginationObj={pagination}
              page={page}
              setPage={setPage}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Orders;
