import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Nav, Container } from "react-bootstrap";
import { StyledNav } from "./styles";

import { Images } from "assets/image";

const Navbar = ({ locale, ...props }) => {
  return (
    <StyledNav variant="light" expand="lg">
      <Container>
        <nav as={Navbar.Brand} href="#home">
          <Link to="/">
            <img src={Images.omniLogo} style={{ height: 35, paddingLeft: 0 }} />
          </Link>
        </nav>
        <nav as={Nav} className="">
          <Nav.Link as={Link} to="/login">
            {locale.nav_login_text}
          </Nav.Link>
          <Nav.Link as={Link} className="getstarted" to="/register">
            {locale.nav_get_started_text}{" "}
          </Nav.Link>
        </nav>
      </Container>
    </StyledNav>
  );
};

export default connect(
  (state) => ({ locale: state.locale[state.locale.active] }),
  {}
)(Navbar);
