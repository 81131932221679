import styled from "styled-components";
import CheckSvg from "assets/image/checkWhite.svg";
import { Colors } from "assets/colors";

export const StyledCheckbox = styled.div`
  cursor: pointer;

  label {
    cursor: pointer;
  }

  &:hover input ~ .checkmark-icon {
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.2),
      0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);
  }

  &:hover input:checked ~ .checkmark-icon {
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.2), 0px 0px 0px 1px #2b64f5,
      0px 1px 1px rgba(0, 0, 0, 0.12);
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark-icon {
      background-color: #2b64f5;
      box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px #2b64f5,
        0px 1px 1px rgba(0, 0, 0, 0.12);

      &:after {
        display: block;
        background-image: url(${CheckSvg});
        background-size: 10px 10px;
      }
    }
  }

  .checkmark-icon {
    position: absolute;
    top: 2px;
    left: -10px;
    height: 14px;
    width: 14px;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    padding: 3px 2px;
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
      0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);

    &--loading {
      position: absolute;
      left: -10px;
      top: 2px;
      margin-top: -2px;
    }

    &:after {
      content: "";
      height: 10px;
      width: 10px;
      position: absolute;
      display: none;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
