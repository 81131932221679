import { Colors } from "assets/colors";
import { Navbar } from "react-bootstrap";
import styled from "styled-components";

export const StyledNav = styled(Navbar)`
  background-image: linear-gradient(
    180deg,
    ${Colors.primary1} 10%,
    ${Colors.primary1} 100%
  );
  color: ${Colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  nav {
    display: flex;
    align-items: center;
    a {
      font-weight: bold;
      color: ${Colors.white} !important;
    }
    .getstarted {
      color: ${Colors.primary1} !important;
      background: ${Colors.white};
      border-radius: 100px;
    }
  }

  img {
    filter: brightness(100) grayscale(1) contrast(100);
  }
`;
