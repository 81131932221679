import "react-lazy-load-image-component/src/effects/blur.css";

import { Card, Spinner } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { copyMenuTo, deleteMenu, duplicateMenu, editMenu } from "api/menus";
import {
  faCopy,
  faGift,
  faPen,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { formatTime, getEndTime, blendColors, getData } from "utils";

import { Colors } from "assets/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "components/Loading";
import Switch from "react-switch";
import { connect } from "react-redux";
import { getPromotionMenu } from "api/promotions";
import { makeAlert } from "actions";
import moment from "moment";
import styled from "styled-components";

const Image = styled.div`
  width: 100%;
  height: 115px;
  object-fit: cover;
  background-image: url(${(p) => p.src});
  background-size: cover;
  z-index: 0;
  display: flex;
  justify-content: flex-end;
`;

const StyledCard = styled(Card)`
  height: 100%;
  cursor: pointer;
  min-height: 80px;
  color: ${Colors.white};
  background-size: cover;
  border: none !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;

  .header {
    display: flex;
    justify-content: space-between;
    background: #f3f3f3;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 115px;
    z-index: 1;
    span {
      display: unset !important;
    }
  }

  .card-body {
    z-index: 99;
    height: 85px;
    background: #f3f3f3;
    padding: 0;
    display: flex;

    .type-badge {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      width: 30px;
      padding-bottom: 10px;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: calc(100% - 30px);
    .buttonsWrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0 0.5rem 0 0.5rem;
    }
    .card-title {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: #8e8e93 !important;
      text-transform: uppercase;
      padding-bottom: 5px;
      margin: 0;
      font-size: 18px;
    }

    .action-btn {
      margin-left: 10px;
      border-radius: 100px;
      width: 30px;
      height: 30px;
      background: #fff;
      display: flex;
      border: none;
      align-items: center;
      justify-content: center;
      outline: none;
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14),
        0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
    }
  }

  hr {
    border-top: 1px solid ${Colors.white};
  }

  .stats {
    display: flex;
    justify-content: space-around;
  }
`;
const StyledAddMenuCard = styled(StyledCard)`
  background: #f1f1f1;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.12);
  color: #121212;
  background-image: none;
  display: flex;
  font-size: 17px;
  flex-direction: row;
  justify-content: center;
  align-item: center;
  border-radius: 0 !important;

  p {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Options = styled.div`
  padding: 12px 15px;
  padding-top: 0;
  float: right;
  width: 50px;
  text-align: center;
  margin-right: -20px;
`;

const MenuCard = ({ id, index, moveCard, locale, ...props }) => {
  const [time, setTime] = useState({ start: "", end: "" });
  const [show, setShow] = useState(false);
  const [restaurant, setRestaurant] = useState(null);
  const [copyActive, setCopyActive] = useState(false);
  const [published, setPublished] = useState(props.is_active);
  const [duplicateLoading, setDuplicateLoading] = useState(false);

  const popover = useRef(null);

  const menuTime = { from_hour: props.from_hour, duration: props.duration };

  useEffect(() => {
    setPublished(props.is_active);
  }, [props.is_active]);

  useEffect(() => {
    if (props.active_hours) {
      setTime({
        start: formatTime(
          props.active_hours && props.active_hours[0]?.from_hour
        ),
        end: formatTime(
          props.active_hours && props.active_hours[0]?.from_hour,
          props.active_hours[0]?.duration
        ),
      });
    }
  }, [props.active_hours]);

  const getEnd = (start, duration) => {
    let t = moment(start).add(
      moment(duration, "HH:mm:ss").format("HH"),
      "hours"
    );
    return t;
  };

  const handleDelete = (e) => {
    e.preventDefault();
    props.onDelete(id);
    closePopover();
  };

  const handleEdit = (event) => {
    props.onEdit(event);
    closePopover();
  };

  const handleChangePublish = (e) => {
    setPublished(e);
    props.editMenu(id, { is_active: e }).then((res) => {
      setPublished(e);
    });
  };
  const closePopover = () => {
    // popover.current.hide()
    document.body.click();
    setShow(false);
  };

  const ref = useRef(null);

  const handleDuplicate = (id, e) => {
    e.stopPropagation();
    setDuplicateLoading(true);
    props
      .duplicateMenu(id)
      .then((res) => {
        setDuplicateLoading(false);
        props.setMenus((menus) => [
          {
            ...menus.find((menu) => menu.id === id),
            id: getData(res)?.id,
            tag: "New",
            name: `${menus.find((menu) => menu.id === id).name} (Duplicated)`,
            is_active: false,
          },
          ...menus.filter((menu) => !menu.type),
        ]);
      })
      .catch((e) => {
        setDuplicateLoading(false);
        console.log("error in duplicating menu", e);
      });
    closePopover();
  };

  const handleCopyTo = (id, restaurant) => {
    props.makeAlert("Menu is started copying...");
    props.copyMenuTo(id, { restaurant }).then((res) => {
      props.makeAlert("Menu Copied to the requested restaurant.");
    });
    closePopover();
  };

  if (props.promo) return null;
  // return <PromoMenuCard locale={locale} {...props} />

  const color =
    props.types.length === 1
      ? props.types[0].color
      : props.types.length === 2
      ? blendColors(props.types[0].color, props.types[1].color)
      : props.types.length === 3
      ? blendColors(
          blendColors(props.types[0].color, props.types[1].color),
          props.types[2].color
        )
      : "";

  return (
    <StyledCard
      onClick={props.onClick}
      ref={ref}
      // style={{ opacity }}
      className={props.className + " shadow-sm "}
    >
      <Image
        src={props.images && props.images[0] ? props.images[0].image_med : ""}
        effect="blur"
      >
        <div onClick={(e) => e.stopPropagation()}>
          <Switch
            className="mt-2 mr-2"
            onChange={handleChangePublish}
            checked={published}
            handleDiameter={18}
            height={24}
            offColor="#E45628"
            uncheckedIcon={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  paddingRight: 2,
                  // fontSize: 13,
                  fontWeight: "bold",
                }}
              >
                OFF
              </div>
            }
            checkedIcon={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  paddingLeft: 2,
                  fontWeight: "bold",
                }}
              >
                ON
              </div>
            }
          />
        </div>
      </Image>
      <Card.Body>
        <div
          className="type-badge"
          style={{
            backgroundColor: color,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {/* <FontAwesomeIcon size='lg' icon={props.type?.icon} /> */}
          {props.types.map((t, index) => (
            <img
              src={t.image}
              style={{ marginBottom: index !== props.types.length - 1 ? 5 : 0 }}
            />
          ))}
        </div>
        <div className="body">
          <Card.Title className="font-weight-bold" style={{ color: "#8E8E93" }}>
            {props.name}
          </Card.Title>
          <div className="d-flex justify-content-between h-100">
            <span className="" style={{ color: "#282328" }}>
              {time.start}
              {" - "}
              {time.end} {props.multipleActiveHours && <span>+</span>}
            </span>
            <div
              className="buttonsWrapper"
              onClick={(e) => e.stopPropagation()}
            >
              <button className="action-btn" onClick={handleEdit}>
                <FontAwesomeIcon icon={faPen} color="#7B7B7B" />
              </button>
              <button
                className="action-btn"
                onClick={(e) => handleDuplicate(id, e)}
              >
                {duplicateLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <FontAwesomeIcon icon={faCopy} color="#7B7B7B" />
                )}
              </button>
            </div>
          </div>
        </div>
      </Card.Body>
    </StyledCard>
  );
};

export const AddMenuCard = (props) => {
  return (
    <StyledAddMenuCard
      id="step3 cypress_add_button"
      style={props.style}
      as={props.as}
      className={"justify-content-center shadow-sm" + props.className}
      onClick={props.onClick}
    >
      <Card.Text className="text-center font-weight-bolder">
        {!props.duplicating && (
          <FontAwesomeIcon icon={faPlus} className="mx-2" />
        )}
        {props.duplicating ? <Loading /> : props.title || "New Menu"}
      </Card.Text>
    </StyledAddMenuCard>
  );
};

const PromoMenuCard = (props) => {
  return (
    <StyledAddMenuCard
      id="step3"
      className="justify-content-center shadow-sm"
      onClick={props.onClick}
    >
      <Card.Text
        className="text-center"
        style={{
          flexDirection: "column",
        }}
      >
        <Card.Title className="font-weight-bold text-center text-primary">
          <FontAwesomeIcon icon={faGift} className="mx-2" />
          {!props.digitalBoard ? "Promotion Menu" : "Digital Boards"}
        </Card.Title>
        <span>
          {!props.digitalBoard
            ? "This menu is automatically created for your promotions."
            : "This menu is automatically created for your digital boards."}
        </span>
      </Card.Text>
    </StyledAddMenuCard>
  );
};

export default connect(
  (state) => ({
    selectedRestaurant: state.user.selectedRestaurant,
    locale: state.locale[state.locale.active],
  }),
  {
    editMenu,
    deleteMenu,
    makeAlert,
    duplicateMenu,
    copyMenuTo,
    getPromotionMenu,
  }
)(MenuCard);
