import React, { useEffect, useState } from "react";

import COUNTRIES_JSON from "./countries.json";
import { locale } from "../reducers/locale";
import { store } from "../store";

export const baseUrl = process.env.REACT_APP_BASE_URL;
export const wsUrl = process.env.REACT_APP_WS_URL;

export const isDevelopment = process.env.NODE_ENV === "development";
// export const env = process.env.NODE_ENV;
// export const branch = process.env.REACT_APP_BRANCH;

// export const baseUrl = 'http://127.0.0.1:8000/'
// export const baseUrl =
//   env === "production" && branch === "production"
//     ? "https://api.dineeasy.ca/"
//     : env == "production" && branch == "staging"
//     ? "https://stage.api.omnicart.tech/"
//     : "https://stage.api.omnicart.tech/";
// export const wsUrl =
//   env === "production" && branch === "production"
//     ? "wss://api.dineeasy.ca/"
//     : env == "production" && branch == "staging"
//     ? "wss://stage.api.omnicart.tech/"
//     : "wss://stage.api.omnicart.tech/";
export const apiUrl = baseUrl + "api/v1";
export const appScheme = isDevelopment
  ? "exp://127.0.0.1:19000/--"
  : "dineeasy://";

export const VAPID_KEY =
  "BJbZo2XzOzzGZDHD-09IedeIeH2dcO3szvQ7VcefCcFaSSfnTyst377TlOqp0W3EOQdV_GgsbcP4CHfI-vr3AXA";
export const STRIPE_PUBLIC_KEY = isDevelopment
  ? "pk_test_pSvcKcIyFSfH1bfNoTVVuyr500Zwuvvtsi"
  : "pk_live_z1UWhSofbYiWxsZd8QnBjsLH00eNU0cwGf";

const lo = store.getState().locale[store.getState().locale.active];

export const PLEXI_STATE = [
  {
    value: 0,
    title: "PENDING",
  },
  {
    value: 1,
    title: "ACCEPTED",
  },
  {
    value: 2,
    title: "CANCELED",
  },
  {
    value: 3,
    title: "PREPARING",
  },
  {
    value: 4,
    title: "PREPARED",
  },
  {
    value: 5,
    title: "DELIVERED",
  },
  {
    value: 6,
    title: "CLOSED",
  },
];

export const WEEKDAYS = [
  lo.weekdays_1,
  lo.weekdays_2,
  lo.weekdays_3,
  lo.weekdays_4,
  lo.weekdays_5,
  lo.weekdays_6,
  lo.weekdays_7,
];

export const colors = {
  orange: "#FF7D00",
  orangeLow: "#FF7D0055",
};

export const SEPRATOR = "_____";

export const IMAGE_VALID_FORMATS = ["jpeg", "jpg", "png", "gif"];
export const IMAGE_VALID_SIZE = 10490;

export const UNDO_PERIOD = 2000;

export const COUNTRIES = COUNTRIES_JSON;

export const RIDE_STATUS = [
  {
    id: 0,
    name: "processing",
    title: "Processing",
  },
  {
    id: 1,
    name: "noDriversAvailable",
    title: "No Drivers Available",
  },
  {
    id: 6,
    name: "serviceDisruption",
    title: "Service Disruption",
  },
  {
    id: 2,
    name: "accepted",
    title: "Accepted",
  },
  {
    id: 3,
    name: "arriving",
    title: "Arriving",
  },
  {
    id: 4,
    name: "inProgress",
    title: "In Progress",
  },
  {
    id: 5,
    name: "cancelled",
    title: "Cancelled",
  },
  {
    id: 7,
    name: "completed",
    title: "Completed",
  },
];
// PROCESSING= 0
// NODRIVERSAVAILABLE = 1
// ACCEPTED = 2
// ARRIVING = 3
// INPRIGRESS = 4
// CANCELLED = 5
// SERVICEDISRUPTION = 6
// COMPLETED = 7

export const LANDING_PAGE_ADDRESS = "https://omnicart.tech";
