import React, { useState, useEffect } from "react";

export default () => {
  let [modal, setModal] = React.useState(false);
  let [modalContent, setModalContent] = React.useState(null);
  let [hideCloseBtn, setHideCloseBtn] = React.useState(null);
  let [classes, setClasses] = React.useState("");

  useEffect(() => {
    if (modal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modal]);

  let handleModal = (content = false, hideCross, classes) => {
    if (hideCross) {
      setHideCloseBtn(true);
    } else {
      setHideCloseBtn(false);
    }
    if (classes) {
      setClasses(classes);
    } else {
      setClasses("");
    }
    setModal(!modal);
    if (content) {
      setModalContent(content);
    }
  };

  return {
    modal,
    handleModal,
    modalContent,
    hideCloseBtn,
    classes,
    setClasses,
  };
};
