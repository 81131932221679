import { NavLink, Redirect, useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as AnalyticsIcon } from "assets/image/analytics.svg";
import BadgeLabel from "components/common/BadgeLabel";
import { ReactComponent as BillingIcon } from "assets/image/billing.svg";
import { ReactComponent as DiscountsIcon } from "assets/image/discounts.svg";
import { ReactComponent as HomeIcon } from "assets/image/homeIcon.svg";
import { ReactComponent as LogoutIcon } from "assets/image/logout.svg";
import { ReactComponent as MenusIcon } from "assets/image/menus.svg";
import { ReactComponent as OCLogoIcon } from "assets/image/OCLogo.svg";
import { ReactComponent as OrdersIcon } from "assets/image/orders.svg";
import { ReactComponent as ProfileIcon } from "assets/image/profile.svg";
import { ReactComponent as QrIcon } from "assets/image/qr.svg";
import SelectMerchant from "./SelectMerchant";
import { StyledWrapper } from "./styles";
import { changeAlarmStatus } from "actions/orders";
import { logoutUser } from "actions/user";

const Sidebar = ({ ...props }) => {
  const [redirect, setRedirect] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders);
  const orderNotifAlarm = useSelector((state) => state.orderNotifAlarm);

  const isOrdersTabActive = () => {
    return location.pathname.includes("/orders");
  };
  const SIDEBAR_ITEMS = [
    [
      {
        id: 1,
        title: "Home",
        icon: <HomeIcon />,
        url: "/console",
      },
      {
        id: 1,
        title: "Menus",
        icon: <MenusIcon />,
        url: "/console/create-menus",
      },
      {
        id: 2,
        title: "Orders",
        icon: <OrdersIcon />,
        url: "/console/orders",
        badge:
          orders?.length > 0 && orderNotifAlarm && !isOrdersTabActive() ? (
            <div className="notif">
              {orders?.length > 9 ? "+9" : orders?.length}
            </div>
          ) : (
            ""
          ),
      },
      {
        id: 3,
        title: "Sales",
        icon: <AnalyticsIcon />,
        url: "/console/analytics",
      },
      {
        id: 4,
        title: "Discounts",
        icon: <DiscountsIcon />,
        url: "/console/discounts",
      },
      // {
      //   id: 1,
      //   title: "Billing",
      //   icon: <BillingIcon />,
      //   url: "/console/billing",
      // },
      // {
      //   id: 2,
      //   title: "QR code & stand",
      //   icon: <QrIcon />,
      //   url: "/console/profile-qrcode",
      // },
      {
        id: 3,
        title: "Profile",
        icon: <ProfileIcon />,
        url: "/console/profile-info",
      },
    ],
  ];

  useEffect(() => {
    if (isOrdersTabActive() && orders?.length > 0) {
      dispatch(changeAlarmStatus(false));
    }
  }, [location]);

  const handleLogout = () => {
    dispatch(logoutUser());
    setRedirect(true);
  };

  const handleGoHome = () => {
    history.push("/console");
  };

  const SIDEBAR_BOTTOM_ITEMS = [
    {
      id: 1,
      title: "llll",
      element: (
        <div onClick={handleLogout}>
          <BadgeLabel
            label={"Log out"}
            leadingIcon={<LogoutIcon />}
            classes="link-wrapper"
          />
        </div>
      ),
    },
  ];

  if (redirect) return <Redirect to="/" />;

  return (
    <StyledWrapper>
      <OCLogoIcon className="logo" onClick={handleGoHome} />
      <SelectMerchant />
      <div className="sections">
        {SIDEBAR_ITEMS.map((section, index) => (
          <div key={index} className="column section">
            {section.map((item, i) => (
              <NavLink
                exact={item.url === "/console" ? true : false}
                to={item.url}
                key={i}
                activeClassName="active-tab"
              >
                <BadgeLabel
                  label={
                    <span className="flex-row">
                      <span>{item.title}</span>
                      <div className="notif-badge">{item.badge || ""}</div>
                    </span>
                  }
                  leadingIcon={item.icon}
                  classes="link-wrapper"
                />
              </NavLink>
            ))}
          </div>
        ))}
        <div className="column section section--bottom">
          {SIDEBAR_BOTTOM_ITEMS.map((item, index) => (
            <div key={index}>{item?.element}</div>
          ))}
        </div>
      </div>
    </StyledWrapper>
  );
};

export default Sidebar;
