import { Card, Col, Dropdown, Modal, Row } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import ReactJoyride, { ACTIONS, EVENTS } from "react-joyride";
import { connect, useSelector } from "react-redux";
import {
  copyMenuTo,
  createMenu,
  deleteMenu,
  duplicateMenu,
  editMenu,
  fetchMenus,
  getMenusByRestaurantId,
  moveMenu,
} from "api/menus";
import {
  faCar,
  faPalette,
  faReceipt,
  faSatelliteDish,
  faSortAmountDown,
} from "@fortawesome/free-solid-svg-icons";
import { getDigitalBoardMenu, getPromotionMenu } from "api/promotions";
import { Colors } from "assets/colors";

import Backend from "react-dnd-html5-backend";
import Button from "react-bootstrap/Button";
import CardTabs from "components/card/CardTabs";
import { ChangeTutorial } from "actions/user";
import CustomButton from "components/customButton";
import { DndProvider } from "react-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Images } from "assets/image";
import Loading from "components/Loading";
import MenuCard from "components/MenuCard";
import ModalBody from "components/modal/StyledModalBody";
import NoPermissions from "components/commons/NoPermissions";
import NoRestaurant from "components/commons/NoRestaurant";
import Spinner from "react-bootstrap/Spinner";
import { StyledButtonContainer } from "./styles";
import { getData } from "../../utils";
import { makeAlert } from "actions";
import { store } from "../../store";
import styled from "styled-components";
import useDebounce from "../../hooks/useDebounce";
import { withRouter } from "react-router-dom";

const StyledModalBody = styled(ModalBody)`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const StyledDiv = styled.div`
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(280px, 1fr)); */
  grid-template-columns: repeat(3, minmax(280px, 1fr));
  grid-gap: 40px;
  @media (max-width: 1400px) {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
`;

const PreviewWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

const ModalHeader = styled(Modal.Header)`
  border: none;
  position: absolute;
  right: 10px;
  z-index: 99999;
`;

const PricingCard = styled(Card)`
  box-shadow: 0px 0px 30px -6px rgba(0, 0, 0, 0.2);
  display: flex;
  font-size: 14px;
  border-width: 0;
  justify-content: center;
  margin-bottom: 50px;
  border-radius: 0.5rem;
  padding: 30px;
  .select {
    width: 8rem;
  }
  .select_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .status-label {
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      font-size: 13px;
      width: 120px;
      border: 1px solid;
      padding: 3px;
    }
  }
`;

const TabItem = styled.div`
  font-size: 16px;
  margin-right: 1.5rem;
  color: ${Colors.darkGray2};
  font-weight: ${(p) => (p.active ? "bold" : "normal")};
  ${(p) => p.active && `border-bottom: 2.5px solid ${Colors.darkGray2}`};
  text-transform: uppercase;

  &:hover {
    color: ${Colors.darkGray2};
    border-bottom: 3px solid ${Colors.darkGray2};
    text-decoration: none;
  }
`;

const SDropdown = styled(Dropdown)`
  background: #ffffff;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 5px 15px;

  button {
    background: #ffffff !important;
    color: #000 !important;
    outline: none !important;
    text-transform: uppercase;
    font-weight: bold;
    border: none !important;
    box-shadow: none !important;
  }
  .dropdown-menu {
    width: 100%;
    text-transform: uppercase;
  }
`;
const locale = store.getState().locale[store.getState().locale.active];
const MENU_TYPES = [
  {
    id: -1,
    title: locale.preview_all_menus,
    query: "",
    icon: faReceipt,
    name: "all",
  },
  // {
  //     id: 1,
  //     title: locale.preview_take_out,
  //     query: '',
  //     icon: faSatelliteDish,
  //     color: '#833AB4',
  //     image: Images.take_out,
  //     name: 'takeout',
  //     private: true,
  // },
  {
    id: 0,
    title: locale.preview_dine_in,
    query: "",
    icon: faPalette,
    color: "#E45628",
    image: Images.dine_in,
    name: "dine_in",
  },
  {
    id: 2,
    title: locale.preview_delivery,
    query: "",
    icon: faCar,
    color: "#FF9F18",
    image: Images.delivery,
    name: "delivery",
    private: true,
  },
];

const CREATE_TABS = [
  {
    title: locale.preview_my_menus,
    path: "/console/create-menus",
    name: "menus",
  },
  {
    title: locale.preview_my_items,
    path: "/console/create-items",
    name: "items",
  },
  {
    title: locale.preview_my_modifiers,
    path: "/console/create-modifiers",
    name: "modifiers",
    private: true,
  },
];

const ORDER_SORTINGS = [
  {
    id: -1,
    title: locale.preview_menus_a_z,
    query: { ordering: "name" },
  },
  {
    id: 0,
    title: locale.preview_newest_oldest,
    query: { ordering: "-created_at" },
  },
  {
    id: 1,
    title: locale.preview_oldest_newest,
    query: { ordering: "created_at" },
  },
  {
    id: 2,
    title: locale.preview_most_popular,
    query: { ordering: "popularity" },
  },
];

const Options = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`;

export const MenusTabs = (props) => {
  const location = useLocation();
  const permissions = useSelector((state) => state.user.permissions);

  return (
    <div
      style={{
        display: "flex",
        padding: "22px 0 25px 20px",
      }}
    >
      {CREATE_TABS.filter(
        (t) =>
          !t.private ||
          permissions.includes(t.name) ||
          permissions.includes("__panel__") ||
          permissions.includes("__admin__") ||
          permissions.includes("__panel__") ||
          permissions.includes("__admin__")
      ).map((tab) => {
        return (
          <TabItem
            key={tab.title}
            as={Link}
            to={tab.path}
            active={location.pathname.includes(tab.path)}
          >
            {tab.title}
          </TabItem>
        );
      })}
    </div>
  );
};

const Preview = (props) => {
  const [menus, setMenus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [isForCreateMenu, setIsForCreateMenu] = useState(true);
  const [selectedMenuForEdit, setSelectedMenuForEdit] = useState({});
  const [update, setUpdate] = useState(false);
  const [joyrideStepIndex, setJoyrideStepIndex] = useState(0);
  const [joyrideRun, setJoyrideRun] = useState(false);
  const [deleting, setDeleting] = useState([]);
  const [activeType, setActiveType] = useState(-1);
  const [activeSorting, setActiveSorting] = useState(-1);
  const [hasNext, setHasNext] = useState(false);
  const [moreLoading, setMoreLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);
  const location = useLocation();
  const history = useHistory();

  const [pagination, setPagination] = useState({
    count: 0,
    next: null,
    previous: null,
  });
  const [params, setParams] = useState({
    sorting: {
      ordering: "name",
    },
    search: null,
    type: null,
  });
  const steps = [
    {
      target: ".step-1",
      content: (
        <div>
          <h3 class="text-center font-weight-bold">Create or Edit Menus</h3>
          <br />
          <p class="text-center">
            Click an existing menu to edit it, or click "Create New Menu" to
            create a new menu and edit it. Once you create a menu, you can then
            add categories to it. You can add existing items to one of the menu
            categories. Once completed publish your menu for users to see on
            Omnicart mobile app.
          </p>
        </div>
      ),
      disableBeacon: true,
      placement: "center",
    },
    {
      target: ".step-2",
      content: (
        <div>
          <p class="text-center">This is an existing menus.</p>
        </div>
      ),
    },
    {
      target: ".step-21",
      content: (
        <div>
          <p class="text-center">You can edit it by clicking here.</p>
        </div>
      ),
      spotlightClicks: true,
    },
    {
      target: "#step3",
      content: (
        <div>
          <p class="text-center">Click here to create a new menu</p>
        </div>
      ),
      spotlightClicks: true,
    },
    {
      target: ".step-2",
      content: (
        <div>
          <p class="text-center">Click here to edit your new menu</p>
        </div>
      ),
      spotlightClicks: true,
    },
  ];
  let intro =
    (props.location && props.location.state && props.location.state.tutorial) ||
    props.tutorial === 3;

  useEffect(() => {
    if (props.selectedRestaurant) {
      handleGetData();
    }
  }, [params]);

  const handleGetData = (loadMore) => {
    if (!loadMore) setLoading(true);
    if (loadMore) setMoreLoading(true);
    props
      .getMenusByRestaurantId(
        props.selectedRestaurant,
        {
          ...params.sorting,
          order_types: params.type,
          page,
        },
        debouncedSearch,
        page
      )
      .then((res) => {
        let data =
          getData(res)?.results &&
          getData(res)?.results.map((menu) => {
            return { ...menu };
          });
        data.forEach((menu, index) => {
          if (!menu.position && menu.position !== 0)
            props.editMenu(menu.id, { position: index });
        });
        setMenus((p) => [...p, ...data]);
        let matches = getData(res)?.next?.match(/page=([^&]*)/);
        if (matches?.length) setPage(matches[1]);
        setHasNext(getData(res)?.next);
        setMoreLoading(false);
        setLoading(false);
        if (intro) {
          startIntro();
        }
      });
  };

  const showMenu = (id) => {
    let name = menus.find((menu) => menu.id === id).name;
    if (name) name = name.replace(/\s+/g, "-").toLowerCase();
    props.history.push(props.location.pathname + `/view/${id}`);
  };

  const setModalShow = (payload) => {
    setShow(payload);
    if (!payload) {
      setSelectedMenuForEdit({});
      if (intro) {
        setJoyrideRun(true);
      }
    }
  };

  const editMenu = (event, menu) => {
    event.stopPropagation();
    props.history.push(props.location.pathname + `/edit/${menu.id}`);
    // setModalShow(true)
    // setIsForCreateMenu(false)
    // setSelectedMenuForEdit(menu)
  };

  const handleDelete = (id) => {
    setLoading(true);
    props
      .deleteMenu(id)
      .then((res) => {
        props.makeAlert("Item Deleted!");
        let newMenu = Array.from(menus);
        newMenu = newMenu.filter((element) => element.id !== id);
        setMenus(newMenu);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const addMenu = () => {
    setModalShow(true);
    setIsForCreateMenu(true);
    setJoyrideRun(false);
  };

  const createMenu = async (data, images) => {
    setLoading(true);
    setModalShow(false);

    let newMenu = await props.createMenu(data);
    newMenu = { ...newMenu, images };
    let newMenus = Array.from(menus);
    newMenus.splice(0, 0, newMenu);
    setMenus(newMenus);
    setLoading(false);

    if (intro) {
      setJoyrideStepIndex(joyrideStepIndex + 1);
      setJoyrideRun(true);
    }

    return newMenu;
    // })
    // .catch(e => {
    //     setLoading(false)
    //     if (intro) { setJoyrideRun(true) }
    // })
  };

  const updateMenu = (id, data) => {
    let newMenus = Array.from(menus);
    let menuIndex = newMenus.findIndex((menu) => menu.id === id);
    if (menuIndex > -1) {
      newMenus[menuIndex] = { ...menus[menuIndex], ...data };
      setMenus(newMenus);
    }
  };

  const startIntro = () => {
    setJoyrideRun(true);
  };

  const showPromotionsMenu = () => {
    // setJoyrideRun(true)
    props.getPromotionMenu(props.selectedRestaurant).then((res) => {
      props.history.push(
        props.location.pathname + `/${getData(res)?.results[0].id}/promotion`,
        { is_promotion: true }
      );
    });
  };

  const showDigitalBoardsMenu = () => {
    // setJoyrideRun(true)
    props.getDigitalBoardMenu(props.selectedRestaurant).then((res) => {
      props.history.push(
        props.location.pathname +
          `/${getData(res)?.results[0].id}/digital-board`,
        { is_digitalboard: true }
      );
    });
  };

  const handleCallback = (data) => {
    const { action, index, status, type } = data;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setJoyrideStepIndex(stepIndex);
    }
    if ([EVENTS.TOUR_END, EVENTS.TOOLTIP_CLOSE].includes(type)) {
      endTour();
    }
  };

  const endTour = () => {
    setJoyrideRun(false);
    setJoyrideStepIndex(0);
    intro = false;
    if (props.tutorial === 3) {
      props.ChangeTutorial("addItemToMenu", 4);
    }
  };

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = menus[dragIndex];
      let tmpMenu = Array.from(menus);
      tmpMenu.splice(dragIndex, 1);
      tmpMenu.splice(hoverIndex, 0, dragCard);
      setMenus(tmpMenu);
      props.moveMenu(dragCard.id, hoverIndex + 1);
    },
    [menus]
  );

  // const testClick = () => {
  // props.copyMenuTo(104, {restaurant: 100832}).then(res => {
  //   console.log(res);
  // })
  // }

  const handleActiveType = (id) => {
    setMenus([]);
    setPagination({});
    setPage(1);
    setActiveType(id);
    setParams({ ...params, page: 1, type: id >= 0 ? id : null });
  };

  const handleActiveSorting = (id) => {
    setMenus([]);
    setPagination({});
    setActiveSorting(id);
    setParams({
      ...params,
      page: 1,
      sorting: ORDER_SORTINGS.find((s) => s.id === id).query,
    });
  };

  if (
    !props.permissions.includes("__panel__") &&
    !props.permissions.includes("menus")
  )
    return <NoPermissions />;

  if (!props.selectedRestaurant) {
    return <NoRestaurant />;
  }

  return (
    <Row
      style={
        {
          // marginTop: 200,
        }
      }
    >
      <Col>
        <Options>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: 100,
              marginBottom: 15,
            }}
          >
            <SDropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <FontAwesomeIcon
                  icon={faSortAmountDown}
                  color={Colors.primary1}
                  size={"lg"}
                  className="mr-3"
                />
                {ORDER_SORTINGS.find((s) => s.id === activeSorting).title}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {ORDER_SORTINGS.map((s) => (
                  <Dropdown.Item onClick={() => handleActiveSorting(s.id)}>
                    {s.title}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </SDropdown>
          </div>
        </Options>
      </Col>
      <Col
        md={12}
        style={{
          marginTop: 50,
        }}
      >
        <StyledButtonContainer>
          <CustomButton
            classes="btn btn-right"
            label="+ New Menu"
            onClick={() => history.push("/console/create-menus/add")}
            id="cypress_add_menu_button"
          />
        </StyledButtonContainer>
        <CardTabs
          onSelect={handleActiveType}
          activeKey={activeType}
          tabs={MENU_TYPES}
        />
        <ReactJoyride
          showSkipButton
          continuous
          scrollToFirstStep
          stepIndex={joyrideStepIndex}
          steps={steps}
          run={joyrideRun}
          callback={handleCallback}
        />
        {/* <PreviewWrapper>
                <Button
                    onClick={() => props.history.push('/console/preview/' + props.selectedRestaurant)}
                >
                    Merchant Preview
                </Button>
            </PreviewWrapper> */}
        <PricingCard>
          <Card.Body>
            {/* <DndProvider backend={Backend}> */}
            <StyledDiv className="step-1">
              <MenuCard
                promo
                digitalBoard
                onClick={() => showDigitalBoardsMenu()}
              />
              {!loading ? (
                menus?.map((item, index) =>
                  item.type === "DUPLICATING" ? (
                    <MenuCard addMenu duplicating />
                  ) : (
                    <MenuCard
                      update={update}
                      setUpdate={setUpdate}
                      onClick={() => showMenu(item.id)}
                      onEdit={(event) => editMenu(event, item)}
                      onDelete={handleDelete}
                      setMenus={setMenus}
                      menus={menus}
                      className={index === 0 ? "step-2" : ""}
                      key={item.id}
                      index={index}
                      id={item.id}
                      moveCard={moveCard}
                      type={MENU_TYPES.find((t) => t.id === item.order_type)}
                      types={MENU_TYPES.filter((t) =>
                        item.order_type.includes(t.id)
                      )}
                      {...item}
                      // from_hour={item.active_hours[0] && item.active_hours[0]?.from_hour}
                      // duration={item.active_hours[0] && item.active_hours[0]?.duration}
                      // multipleActiveHours={item.active_hours.length > 1}
                    />
                  )
                )
              ) : (
                <Loading />
              )}
            </StyledDiv>

            {hasNext && !loading && (
              <div className="d-flex justify-content-center py-3">
                <Button
                  onClick={() => handleGetData(true)}
                  disabled={moreLoading}
                >
                  {moreLoading ? <Spinner animation="border" /> : "Load More"}
                </Button>
              </div>
            )}
            {/* </DndProvider> */}
          </Card.Body>
        </PricingCard>
      </Col>

      {/* <Modal size="lg" show={show} onHide={() => { setModalShow(false) }}>
                <ModalHeader closeButton />
                <StyledModalBody>
                    <MenuSetting
                        create={isForCreateMenu}
                        onHide={() => { setModalShow(false) }}
                        {...selectedMenuForEdit}
                        setModal={() => setModalShow(false)}
                        createMenu={createMenu}
                        updateMenu={updateMenu}
                        setDeleting={setDeleting}
                        setMenus={setMenus}
                        menus={menus}
                    />
                </StyledModalBody>
            </Modal> */}
    </Row>
  );
};

export default connect(
  (state) => ({
    selectedRestaurant: state.user.selectedRestaurant,
    tutorial: state.user.tutorialSteps,
    permissions: state.user.permissions,
    locale: state.locale[state.locale.active],
  }),
  {
    getMenusByRestaurantId,
    createMenu,
    deleteMenu,
    makeAlert,
    ChangeTutorial,
    moveMenu,
    editMenu,
    copyMenuTo,
    fetchMenus,
    getPromotionMenu,
    getDigitalBoardMenu,
    duplicateMenu,
  }
)(withRouter(Preview));
