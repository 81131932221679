import React from 'react'
import styled from 'styled-components'

export const Heading = styled.h2`
    font-weight: bold;
    font-size: 36px;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: #4E4E4E;
`