import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { editImages, fetchImages } from "api/images";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

import Card from "./Card";
import { Colors } from "assets/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { getData } from "../../utils";
import { makeAlert } from "actions";
import styled from "styled-components";

const StyledText = styled.div`
  width: 100%;
  p,
  input,
  img,
  textarea {
    margin: 0;
    margin-bottom: 1rem;
  }
  .time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 5px;
    span {
      flex: 1;
    }

    .to {
      margin: 0 10px;
    }
  }
  textarea {
    min-height: 200px;
  }
  strong {
    display: block;
  }

  .hour {
    padding: 2px 10px;
    background: #e0e0e0;
    border-radius: 5px;
  }
`;
const ImagesWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const ImageWrap = styled.div`
  position: relative;
  padding: 0 10px;

  img {
    position: relative;
    border-radius: 10px;
  }

  span {
    display: flex;
    top: 0;
    position: absolute;
    margin: 5px;
    background: rgba(0, 0, 0, 0.5);
    color: ${Colors.white};
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 100px;
    font-size: 25px;
    font-weight: 900;
  }
`;
const Image = styled.img`
  max-height: 100px;
  ${(p) => p.pale && "filter: brightness(30%);"}
`;
const More = styled.div`
  border-radius: 10px;
  position: relative;
  background-image: url(${(p) => p.image});
  margin-bottom: 1rem;
  flex: 1;
  align-items: center;
  display: flex;
  justify-content: center;

  .text {
    color: ${Colors.white};
    font-size: 20px;
    font-weight: bold;
    z-index: 999;
  }

  .overlay {
    border-radius: 10px;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: rgba(0, 0, 0, 0.8);
  }
`;

const RestaurantImage = (props) => {
  const [showMore, setShowMore] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const {
    loading,
    setEditing,
    updating,
    editing,
    submit,
    images,
    setRestaurant,
    restaurant,
  } = props;

  const handleDeleteImage = (img) => {
    let id = img.id;
    const data = {
      items: img.items,
      restaurants: img.restaurants.filter((rest) => rest !== restaurant.id),
    };
    props.editImages(id, data).then((res) => {
      setRestaurant({
        ...restaurant,
        images: restaurant.images.filter((image) => image.id !== id),
      });
      props.makeAlert("Image Successfully Deleted From Your Restaurant!");
    });
  };

  const handleAddImage = (img) => {
    let id = img.id;
    const data = {
      items: img.items,
      restaurants: [...img.restaurants, restaurant.id],
    };
    props.editImages(id, data).then((res) => {
      setRestaurant({
        ...restaurant,
        images: [...restaurant.images, getData(res)],
      });
      props.makeAlert("Image Successfully Added From Your Restaurant!");
    });
  };

  const handleChange = (e) => {
    const { value, name, files } = e.target;
    const reader = new FileReader();
    if (files) {
      const url = reader.readAsDataURL(files[0]);

      reader.onloadend = function (e) {
        setRestaurant({
          ...restaurant,
          [name + "File"]: files[0],
          [name]: reader.result,
        });
      };
    } else {
      setRestaurant({ ...restaurant, [name]: value });
    }
  };

  const imageList = images
    ? showMore
      ? images
      : Array.from(images).splice(0, 3)
    : [];
  return (
    <>
      <Card
        title="Images"
        loading={loading}
        setEditing={null}
        openModal={setModalShow}
        updating={updating}
        editing={editing}
        onSubmit={submit}
        editClassName="edit-4"
      >
        <StyledText>
          <ImagesWrap>
            {imageList &&
              imageList.map((img) => (
                <ImageWrap key={img.id}>
                  <Image data-src={img.image} src={img.image} />
                </ImageWrap>
              ))}

            {/** Show More */}
            {images && !showMore && images.length > 3 ? (
              <More
                onClick={() => setShowMore(!showMore)}
                image={images[images.length - 1].image}
              >
                <div className="overlay" />
                <div className="text">+{images.length - 3} More</div>
              </More>
            ) : null}
          </ImagesWrap>
        </StyledText>
      </Card>

      <ImageModalContent
        show={modalShow}
        onHide={() => setModalShow(false)}
        fetchImages={props.fetchImages}
        handleDeleteImage={handleDeleteImage}
        handleAddImage={handleAddImage}
        selectedImages={images}
      />
    </>
  );
};

const ImageModalContent = (props) => {
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(1);

  const getImage = (pageNo) => {
    props.fetchImages(pageNo).then((res) => {
      setImages(getData(res)?.results);
      setPage(pageNo);
    });
  };

  useEffect(() => {
    getImage(1);
  }, []);

  return (
    <Modal size="lg" show={props.show} onHide={props.onHide}>
      <Modal.Body>
        <Container>
          <Row>
            <Col md={12}>
              <h4>Selected Images</h4>
              <StyledText>
                <ImagesWrap>
                  {props.selectedImages &&
                    props.selectedImages.map((img) => (
                      <ImageWrap key={img.id}>
                        <Image src={img.image} />
                        <span onClick={() => props.handleDeleteImage(img)}>
                          <FontAwesomeIcon icon={faTimes} />
                        </span>
                      </ImageWrap>
                    ))}
                </ImagesWrap>
              </StyledText>

              {/**
            editing === 'images'
                ?
                : null
        }
        {
            !(editing === 'images') && !showMore && images.length > 3
                ?
                : null
        */}
              {/* <BtnsWrap>
                                <ChooseBtn variant='secondary' onClick={() => console.log('choose')}>Choose from Gallery</ChooseBtn>
                                {
                                    restaurant.image
                                        ? <>
                                            <Form.Label className="d-block">Image</Form.Label>
                                            <Image src={restaurant.image} width={100} height={100} />
                                            <a className="btn" onClick={() => fileUpload.click()}>
                                                <FontAwesomeIcon icon={faFileUpload} className="mx-3" />
                                                Click to change item Image
                  </a>
                                        </>
                                        : <a className="btn" onClick={() => fileUpload.click()}>
                                            <FontAwesomeIcon icon={faFileUpload} className="mx-3" />
                                            Click to upload item Image
                </a>
                                }
                                <Form.Control
                                    type="file"
                                    name="imageFile"
                                    placeholder="Item Image"
                                    onChange={handleChange}
                                    ref={el => fileUpload = el}
                                    style={{ display: 'none' }}
                                />
                            </BtnsWrap>
                         */}
            </Col>

            <Col>
              <hr />
              <h4>Images</h4>
              <StyledText>
                <ImagesWrap>
                  {images &&
                    images.map((img) => (
                      <ImageWrap key={img.id}>
                        <Image src={img.image} />
                        <span onClick={() => props.handleAddImage(img)}>
                          <FontAwesomeIcon icon={faCheck} />
                        </span>
                      </ImageWrap>
                    ))}
                </ImagesWrap>
              </StyledText>
            </Col>
          </Row>
          <Row className="p-3">
            <Col md={4}>
              <Button variant={"light"} onClick={() => getImage(page + 1)}>
                next
              </Button>
              {page === 1 ? null : (
                <Button variant={"light"} onClick={() => getImage(page - 1)}>
                  prev
                </Button>
              )}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default connect(null, { makeAlert, editImages, fetchImages })(
  RestaurantImage
);
