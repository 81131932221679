import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import PropTypes from "prop-types";

/** Skeleton Loading */

const SkeletonLoading = ({ borderRadius, width, height, count, ...props }) => {
  return (
    <SkeletonTheme
      baseColor="#F0F2F3"
      highlightColor="#fff"
      borderRadius={borderRadius}
      width={width}
      height={height}
    >
      <Skeleton count={count} />
    </SkeletonTheme>
  );
};

SkeletonLoading.propTypes = {
  borderRadius: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  count: PropTypes.number,
};

export default SkeletonLoading;
