import jsCookie from "js-cookie";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import {
  Link,
  Redirect,
  Route,
  BrowserRouter as Router,
  withRouter,
} from "react-router-dom";
import styled from "styled-components";

import { makeAlert } from "actions";
import { loginUser } from "actions/user";
import { resetPassword } from "api/auth";
import Navbar from "components/Layout/Navbar";
import Loading from "components/Loading";

import { Colors } from "assets/colors";
import { ReactComponent as OCLogoIcon } from "assets/image/OCLogo.svg";
import { ReactComponent as EmailIcon } from "assets/image/email.svg";
import { ReactComponent as UserIcon } from "assets/image/person.svg";
import CustomButton from "components/customButton";
import CustomInput from "components/customInput";
import LoadingCard from "components/LoadingCard";
import { LANDING_PAGE_ADDRESS } from "../../constants";
import { locale } from "../../reducers/locale";
import { checkRegex } from "../../utils";

const Switch = styled.div`
  a {
    font-size: 15px;
    color: #555 !important;

    span {
      display: block;
      color: ${Colors.primary1};
      text-decoration: underline;
      font-weight: bold;
    }
  }
`;

const Submit = styled.div`
  a {
    color: ${Colors.white} !important;
    font-weight: bold;
    font-size: 15px !important;
  }
`;

const BtnWrapper = styled.div`
  width: 100%;
`;

const StyledTitle = styled.div`
  color: #151515;
  font-size: 24px;
  text-align: left;
  font-weight: bold;
  width: 100%;
  margin-bottom: 30px;
  padding-left: 56px;

  @media (max-width: 1120px) {
    margin-bottom: 11px;
    font-size: 22px;
    padding-left: 25px;
  }
`;

const StyledLine = styled.div`
  width: 100%;
  height: 1px;
  background: #ececec;
  border: 1px solid #ececec;
  margin: 30px 0 20px 0;
`;

const StyledPadded = styled.div`
  width: 100%;
  padding: 0 55px !important;

  @media (max-width: 1120px) {
    padding: 0 25px !important;
  }

  .hide-in-desktop {
    display: none;
    margin-left: -25px;

    @media (max-width: 1120px) {
      display: block;
    }
  }
`;

const BottomWrap = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;

  @media (max-width: 1120px) {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding-bottom: 20px;
  }
`;
const ForgetWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: #0984e3 !important;

  @media (max-width: 1120px) {
    display: ${(p) => (p.mobileHide ? "none" : "block")};
    display: ${(p) => (p.mobileShow ? "block" : "none")};
    width: 100%;
    text-align: left;
    padding-left: 25px;
    margin-top: 0;

    a {
      text-align: left !important;
    }
  }

  a {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    color: #0984e3 !important;
  }
`;

const StyledHeader = styled.div`
  width: calc(100% - 400px);
  min-width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledLogo = styled(Link)`
  text-decoration: none;
cursor:pointer;
   &:hover {
    text-decoration: none;
  }
  h1 {
    color: #151515;
    font-size: 20px;
    font-weight: bold;
    line-height: 12px;
    margin: 0;
}
  }
  span {
    font-size: 12px;
    color: black;
  }
  .colored {
    color: ${Colors.primary1};
    font-size: 16px;
    font-weight: bold;
  }

  svg {
    width: 136px;
  }
`;

const StyledSignUpBtn = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: #ffffff;
  }
  background: #151515;
  font-size: 14px;
  color: #ffffff;
  padding: 10px 15px;
  margin: 0;
  border: none;
`;

const StyledLoginBtn = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: #ececec;
  }
  background: #ffffff;
  font-size: 14px;
  color: #ececec;
  padding: 10px 15px;
  margin: 0;
  border: 1px solid #151515;

  span {
    color: #151515;
  }

  svg {
    color: #151515;
    width: 12px;
  }
`;

const StyledContainer = styled.div`
  background-image: #ffffff;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;

  .screen_title {
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 4rem;
  }
  .screen_subtitle {
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 1rem;
  }
`;

const StyledAuthCard = styled(Card)`
  width: 630px;
  min-height: 20rem;
  border-radius: 0;
  margin-top: 1rem;
  padding: 30px 0 20px 0;
  margin-bottom: 2rem;
  border: 1px solid #ececec;
  background: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 15px #0000000d;
  margin-top: 51px;

  @media (max-width: 1120px) {
    width: 100vw !important;
    height: 100vh;
    margin: 0;
    border: none !important;
    overflow: auto;
    position: fixed !important;
    top: 0;
  }

  .cross {
    background-color: rgba(236, 236, 236, 0.4);
    width: 32px;
    height: 32px;
    padding: 13px;
    color: #151515;
    font-size: 10px;
    position: absolute;
    right: 45px;
    text-decoration: none;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1120px) {
      right: 25px;
      font-size: 17px;
      width: 32px;
      height: 32px;
      padding: 0;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .back-btn {
    background: rgba(236, 236, 236, 0.4);
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    padding: 0;

    svg {
      color: #373333;
      font-size: 16px;
      width: 16px;
      height: 16px;
    }
  }
  .padded {
    width: 100%;
    padding: 0 55px !important;
  }
  .auth {
    background: #8a8a8a;
    color: #fff;
    height: 56px;
    font-weight: bold;
    font-size: 18px;
    width: 100%;
    border-radius: 0;
  }
  .register {
    background: ${Colors.primary1};
    color: #fff;
    height: 56px;
    font-weight: bold;
    font-size: 18px;
    width: 100%;
    border-radius: 0;
  }
  .title {
    font-size: 24px;
    color: #151515;
    font-weight: bold;
    text-align: left;
    align-self: end;
    margin-left: 55px;
    margin-bottom: 14px;
  }
  .line {
    width: 100%;
    background: #ececec;
    border: 1px solid #ececec;
    margin: 30px 0 20px 0;
  }
  .check_box_wrapper {
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 75%;
    .check_box_text {
      margin-left: 1.5rem;
    }
    .form-check-input {
      width: 1.75rem;
      height: 1.75rem;
    }
  }
  @media (max-width: 800px) {
    width: 35rem;
    border-radius: 2rem;
    .check_box_wrapper {
      width: 65%;
    }
  }
  @media (max-width: 600px) {
    width: 30rem;
    border-radius: 1rem;
    padding-top: 1rem;
    .check_box_wrapper {
      width: 90%;
    }
  }
  @media (max-width: 520px) {
    width: 25rem;
  }
  @media (max-width: 455px) {
    width: 20rem;
  }
`;

const Login = ({ locale, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({});
  const [email, setEmail] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const { value, name } = e.target;

    setForm({ ...form, [name]: value });
  };

  const handleSubmit = () => {
    if (checkEmail()) {
      submit();
    } else {
      setError(true);
    }
  };

  const submit = () => {
    setLoading(true);
    props
      .resetPassword({ email })
      .then((res) => {
        props.makeAlert("Email sent! Check your inbox!");
        // console.log(res);
        setRedirect(true);
        setLoading(false);
      })
      .catch((e) => {
        // props.makeAlert('Wrong Credentials!', 'error')
        console.log(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    setRedirect(props.isLoggedIn);
  }, [props.isLoggedIn]);

  useEffect(() => {
    if (email) {
      if (checkEmail()) {
        setError(false);
      } else {
        setError(true);
      }
    }
  }, [email]);

  const { from } = props.location.state || { from: { pathname: "/console" } };

  if (redirect === true) {
    return <Redirect to={from} />;
  }

  const checkEmail = () => {
    let emailRegx =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return checkRegex(email, emailRegx);
  };

  return (
    <StyledContainer>
      <StyledHeader>
        <StyledLogo to="/">
          <OCLogoIcon />
        </StyledLogo>
        <StyledSignUpBtn to="/register">
          Don't have an account yet? <strong>&nbsp;Get Started</strong>
        </StyledSignUpBtn>
      </StyledHeader>
      <StyledAuthCard>
        <a target="_blank" href={LANDING_PAGE_ADDRESS} className="cross">
          &#10005;
        </a>
        <StyledTitle>{locale.reset_password}</StyledTitle>
        <StyledPadded>
          <CustomInput
            name="email"
            label={<EmailIcon />}
            type="email"
            placeholder="Email address"
            value={email}
            setValue={setEmail}
            error={error}
          />
          <ForgetWrap mobileShow>
            <Link
              className="hide-in-desktop"
              style={{ textDecoration: "none" }}
              to="/login"
            >
              <div className="switcher_button">Remember Password?</div>
            </Link>
          </ForgetWrap>
        </StyledPadded>
        <ForgetWrap mobileShow>
          <Link
            style={{ color: "white", textDecoration: "none" }}
            to="/login"
          ></Link>
        </ForgetWrap>
        <BottomWrap>
          <StyledLine />
          <StyledPadded>
            <BtnWrapper>
              <CustomButton
                classes={email && !error ? "register" : "auth"}
                label={locale.reset_password_button}
                onClick={handleSubmit}
                loading={loading}
              />
            </BtnWrapper>
            <ForgetWrap mobileHide>
              <Link
                style={{ color: "white", textDecoration: "none" }}
                to="/login"
              >
                <div className="switcher_button">Remember Password?</div>
              </Link>
            </ForgetWrap>
          </StyledPadded>
        </BottomWrap>
      </StyledAuthCard>
      <LoadingCard loading={loading} />
    </StyledContainer>
  );
};

export default connect(
  (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    locale: state.locale[state.locale.active],
  }),
  { resetPassword, loginUser, makeAlert }
)(Login);
