import {
  faChartBar,
  faCheck,
  faHamburger,
  faPaperPlane,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import introJs from "intro.js";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Card, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";

import { makeAlert } from "actions";
import { changeSelectedRestaurant, changeUserRestaurants } from "actions/user";
import { getClaimingStatus } from "api/auth";
import { getMyRestaurants } from "api/restaurant";
import { Colors } from "assets/colors";
import PageDescription from "components/pageDescription";
import VideoTutorial from "components/Video";
import {Alert as AntdAlert} from "antd"
import { locale } from "../../reducers/locale";
import { getData } from "../../utils";

import "intro.js/introjs.css";

const StyledCard = styled(Card)`
  box-shadow: 0px 0px 30px -6px rgba(0, 0, 0, 0.2);
  display: flex;
  border-width: 0;
  justify-content: center;
  text-align: center;
  margin-bottom: 50px;
  border-radius: 0.5rem;
  margin-top: 1rem;
  flex: 1;
`;

const StyledCardHeader = styled(Card.Header)`
  background: ${Colors.white} !important;
  border: none !important;
`;

const StyledButton = styled(Button)`
  margin-top: 25px;
`;

const TodoItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  background: ${Colors.white};
  border-radius: 50px;
  height: 49px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  justify-content: space-between;
  margin-top:auto;
  cursor:pointer;
  ${(p) =>
    !p.checked &&
    `&:hover {
      box-shadow: 0 2px 10px 0 rgba(0,0,0,0.5);
      background: ${Colors.primary1};
      p {
        color: ${Colors.white} !important;
      }
      span {
        background: ${Colors.primary1};
      }
      transition: 0.3s all;
    }`}
  p {
    margin: 0 !important;
    color: ${(p) => (p.checked ? Colors.green : Colors.normalGray)};

    font-size: 20px;
    font-weight: 800;
    letter-spacing: 0.38px;
    line-height: 24px;
  }
  span {
    width: 60px;
    background: ${(p) => (p.checked ? Colors.green : Colors.normalGray)};
    font-size: 27px;
    font-weight: 800;
    color: ${Colors.white};
    height: 100%;
    border-top-${(p) => (p.rtl ? "right" : "left")}-radius: 50px;
    border-bottom-${(p) => (p.rtl ? "right" : "left")}-radius: 50px;
    justify-content: center;
    display: flex;
    align-items: center;
    color: #FFFFFF;

    letter-spacing: 0.51px;
    line-height: 33px;
  }
`;

const Box = styled.div`
  width: 26px;
  height: 26px;
  margin: 0 32px;
  border-radius: 50px;
  border: 2px solid ${(p) => (p.checked ? "#68d300" : "#555")};
  background-color: ${(p) => (p.checked ? "#68d300" : "#fff")};
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 15px;
    color: #fff;
  }
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 350px;
  border-radius: 0.5rem;
  border: none;
`;

const Title = styled.h3`
  font-size: 1.75rem;
  color: ${Colors.normalGray};
  font-weight: bold;
  text-align: center;
`;

const Subtitle = styled.h3`
  font-size: 1.1rem;
  color: ${Colors.darkGray};
  margin-bottom: 15px;
  text-align: center;
`;

const Home = ({ locale, ...props }) => {
  const [status, setStatus] = useState(null);
  useEffect(() => {
    props.getClaimingStatus().then((res) => {
      setStatus(getData(res)?.status);
    });
  }, []);

  return (
    <>

      <AntdAlert
        message={<b>Important Notice: Merchant Panel Discontinuation</b>}
        description={<div>
          The Merchant Panel is merging with our Admin Panel for a better experience. It will soon be discontinued and replaced by the Admin Panel at <a style={{fontWeight: 'bolder'}} href="https://admin.omnicart.tech/" target="_blank">https://admin.omnicart.tech/</a>.
          If you need to continue using the Merchant Panel, please let us know via this <a href="https://forms.gle/HTiHzfKCPGrYC5k98" target="_blank" style={{fontWeight: 'bolder'}}>Google Form</a>.
        </div>}
        type="error"
      />

      {props.trial_end &&
      moment(props.trial_end).diff(moment(), "days") < 31 ? (
        <Alert
          variant="info"
          className="w-100 justify-content-between d-flex align-items-center"
        >
          {moment(props.trial_end).diff(moment(), "days") <= 0
            ? `Your free trial ended ${Math.abs(
                moment(props.trial_end).diff(moment(), "days")
              )} days ago, please upgrade your account`
            : `Your free trial is ending in ${Math.abs(
                moment(props.trial_end).diff(moment(), "days")
              )} days`}
          <Button as={Link} to="/console/billing">
            Upgrade
          </Button>
        </Alert>
      ) : null}
      {status != null && (
        <Alert
          variant={status === 0 ? "info" : status === 1 ? "success" : "danger"}
        >
          {status === 0
            ? locale.home_page_alert_text
            : status === 1
            ? locale.home_page_alert_text2
            : locale.home_page_alert_text3}
        </Alert>
      )}
      <Row className="mb-3">
        <Col md={12} className="d-flex">
          <VideoTutorial
            title={locale.video_tutorial_title}
            subtitle={
              <>
                {locale.video_tutorial_subtitle_up} <br />{" "}
                {locale.video_tutorial_subtitle_down}
              </>
            }
            src="https://www.youtube.com/embed/JjBUPURVXY4"
          />
        </Col>
        {/* <Col md={5} className="d-flex">
          <TodoList locale={locale} {...props} />
        </Col> */}
      </Row>
      <PageDescription data-intro="Hello step one!" />
    </>
  );
};

const TodoList = ({ locale, ...props }) => {
  const history = useHistory();

  const menuTutorial = () => {
    if (props.menus[0]) {
      history.push("/console/create-menus/" + props.menus[0].id, {
        tutorial: true,
      });
    } else {
      props.makeAlert("Create Menu First", "error");
    }
  };

  const todos = [
    {
      title: locale.to_dos_title1,
      checked: true,
      href: "/console/profile-info",
    },
    {
      title: locale.to_dos_title2,
      checked: false,
      href: "/console/create-items",
    },
    {
      title: locale.to_dos_title3,
      checked: false,
      href: "/console/create-menus",
    },
    {
      title: locale.to_dos_title4,
      checked: false,
      onClick: menuTutorial,
    },
    {
      title: locale.to_dos_title5,
      checked: false,
      onClick: menuTutorial,
    },
  ];

  return (
    <StyledCard data-intro="Hello step one!">
      <StyledCardHeader>
        <Title> {locale.to_do_list_title} </Title>
        <Subtitle className="mb-2">{locale.to_do_list_subtitle}</Subtitle>
      </StyledCardHeader>

      <Card.Body className="d-flex align-items-flex-start flex-column">
        {todos.map((todo, index) => (
          <TodoItem
            rtl={locale.rtl}
            key={index}
            className={index < props.tutorialStep - 1 ? "cheked" : ""}
            checked={index < props.tutorialStep - 1}
            onClick={() =>
              todo.href
                ? history.push(todo.href, { tutorial: true })
                : todo.onClick()
            }
          >
            <span>{index + 1}</span>
            <p>{todo.title}</p>

            <Box checked={index < props.tutorialStep - 1}>
              {index < props.tutorialStep - 1 && (
                <i className="fas fa-check">
                  <FontAwesomeIcon icon={faCheck} />
                </i>
              )}
            </Box>
          </TodoItem>
        ))}
      </Card.Body>
    </StyledCard>
  );
};

export default connect(
  (state) => ({
    selectedRestaurant: state.user.selectedRestaurant,
    tutorial: state.user.tutorial,
    tutorialStep: state.user.tutorialSteps,
    menus: state.user.menus,
    trial_end: state.user.info?.trial_end,
    locale: state.locale[state.locale.active],
  }),
  {
    getMyRestaurants,
    changeSelectedRestaurant,
    changeUserRestaurants,
    makeAlert,
    getClaimingStatus,
  }
)(Home);
