import { fetchApi } from '../'
import { apiUrl } from '../../constants'

// export const editImages = (id, data) => async (dispatch, getState) => {
//   // let sendDataForm = 'image' in data
//   let res = await dispatch(fetchApi(`restaurant-images/${id}/`, 'PATCH', data))
//
//   return res
// }

export const getCustomers = () => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`silvercustomers/`, 'GET'))

  return res
}

export const getBillingAddress = () => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`billing/customer/billing_address/`, 'GET'))

  return res
}

export const createBillingAddress = (data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`billing/customer/billing_address/`, 'POST', data))

  return res
}

export const updateBillingAddress = (id, data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`billing/customer/billing_address/${id}/`, 'PATCH', data))

  return res
}

export const createCustomer = (data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`billing/customer/`, 'POST', data))

  return res
}

export const getCustomerPaymentMethods = (data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`billing/customer/payment_method/`, 'POST', data))

  return res
}

export const getCustomerInvoices = (data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`billing/customer/invoice/`, 'POST', data))

  return res
}

export const getCustomerSubscriptions = (data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`billing/customer/subscription/`, 'POST', data))

  return res
}

export const getSubscription = (id) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`billing/customer/subscription/${id}/`, 'GET'))

  return res
}

export const redeemPromoCode = (id, data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`billing/customer/subscription/${id}/redeem/`, 'PUT', data))

  return res
}
export const cancelSubscription = (id) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`billing/customer/subscription/${id}/cancel/`, 'POST'))

  return res
}

export const updateSubscription = (id, data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`billing/customer/subscription/${id}/`, 'PATCH', data))

  return res
}

export const merchantClaimers = (data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`merchant_claimers/list/`, 'POST', data))

  return res
}

export const createPaymentMethode = (customerId) => async (dispatch, getState) => {
  const data = {
    "payment_processor_name": "braintree_triggered",
    "payment_processor": apiUrl + "/payment_processors/braintree_triggered/",
    "state": "enabled"
  }
  let res = await dispatch(fetchApi(`silvercustomers/${customerId}/payment_methods/`, 'POST', data))

  return res
}

export const createSubscription = (customerId, data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`silvercustomers/${customerId}/subscriptions/`, 'POST', data, false))

  return res
}

export const createInvoice = (data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`silverinvoices/`, 'POST', data, false))

  return res
}

export const issueInvoice = (id, data) => async (dispatch, getState) => {
  let res = dispatch(fetchApi(`silverinvoices/${id}/state/`, 'PUT', data, false))

  return res
}

export const createTransaction = (customerId, data) => async (dispatch, getState) => {
  let res = dispatch(fetchApi(`silvercustomers/${customerId}/transactions/`, 'POST', data, false))

  return res
}

export const orderPlexiStand = (id, data) => async (dispatch, getState) => {
  let res = dispatch(fetchApi(`restaurants/${id}/plexi_stand/`, 'POST', data, false))

  return res
}

export const orderPlexiStandPrice = (id, data) => async (dispatch, getState) => {
  let res = dispatch(fetchApi(`restaurants/${id}/plexi_orders/price/`, 'POST', data, false))

  return res
}
