import React, { useEffect, useState } from "react";
import { ReactComponent as SunIcon } from "assets/image/sun.svg";
import { ReactComponent as IIcon } from "assets/image/i.svg";
import { ReactComponent as SolidArrowDownIcon } from "assets/image/solidArrowDown.svg";
import { ReactComponent as SolidArrowTopIcon } from "assets/image/solidArrowTop.svg";
import RangeCalendar from "components/RangeCalendar";
import { useDispatch, useSelector } from "react-redux";
import { getOverview } from "../../../api/analytics";
import { calcSomeDaysAgoDate, formatDate, getData } from "../../../utils";
import SkeletonLoading from "components/common/SkeletonLoading";
import Tooltip from "components/Tooltip";
import { ReactComponent as ClockIcon } from "assets/image/clock.svg";
import moment from "moment";

const Overview = () => {
  const [overviewStartDateDatePicker, setOverviewStartDateDatePicker] =
    useState(null);
  const [overviewEndDateDatePicker, setOverviewEndDateDatePicker] =
    useState(null);
  const [overviewStartDate, setOverviewStartDate] = useState(null);
  const [overviewEndDate, setOverviewEndDate] = useState(null);
  const [overviewShowDatePicker, setOverviewShowDatePicker] = useState(false);
  const [overviewData, setOverviewData] = useState(null);
  const [overviewLoading, setOverviewLoading] = useState(true);
  const [overviewFilter, setOverviewFilter] = useState("today");
  const dispatch = useDispatch();
  const selectedRestaurantId = useSelector(
    (state) => state.user?.selectedRestaurant
  );

  const OVERVIEW_FILTERS = [
    {
      element: (
        <>
          <SunIcon className="overview-filter--svg overview-filter--sun" />
          <span>Today</span>
        </>
      ),
      filterClassName: "overview-filter",
      activeFilterClassName: "overview-filter--active",
      name: "today",
    },
    {
      element: "Yesterday",
      filterClassName: "overview-filter",
      activeFilterClassName: "overview-filter--active",
      name: "yesterday",
    },
    {
      element: "7 Days",
      filterClassName: "overview-filter",
      activeFilterClassName: "overview-filter--active",
      name: "7days",
    },
    {
      element: "30 Days",
      filterClassName: "overview-filter",
      activeFilterClassName: "overview-filter--active",
      name: "30days",
    },
  ];

  useEffect(() => {
    /** Clear date picker selected dates */
    if (overviewFilter !== "date" && overviewEndDateDatePicker) {
      setOverviewStartDateDatePicker(null);
      setOverviewEndDateDatePicker(null);
    }
  }, [overviewFilter]);

  useEffect(() => {
    /** Set StartDate and EndDate for each filter */
    switch (overviewFilter) {
      case "today":
        setOverviewStartDate(formatDate(new Date()));
        setOverviewEndDate(null);
        break;
      case "yesterday":
        setOverviewStartDate(calcSomeDaysAgoDate(1));
        setOverviewEndDate(calcSomeDaysAgoDate(1));
        break;
      case "7days":
        setOverviewStartDate(calcSomeDaysAgoDate(7));
        setOverviewEndDate(formatDate(new Date()));
        break;
      case "30days":
        setOverviewStartDate(calcSomeDaysAgoDate(30));
        setOverviewEndDate(formatDate(new Date()));
        break;
      case "date":
        setOverviewStartDate(formatDate(overviewStartDateDatePicker));
        setOverviewEndDate(formatDate(overviewEndDateDatePicker));
        break;

      default:
        break;
    }
  }, [overviewFilter, overviewStartDateDatePicker, overviewEndDateDatePicker]);

  useEffect(() => {
    if (overviewStartDate) {
      setOverviewLoading(true);
      dispatch(
        getOverview(selectedRestaurantId, overviewStartDate, overviewEndDate)
      )
        .then((res) => {
          setOverviewData(getData(res));
          setOverviewLoading(false);
        })
        .catch((err) => {
          setOverviewLoading(false);
        });
    }
  }, [selectedRestaurantId, overviewStartDate, overviewEndDate]);

  const getOverviewFilter = () => {
    switch (overviewFilter) {
      case "today":
        return ["Today", "Yesterday"];
      case "yesterday":
        return ["Yesterday", "Day before yesterday"];
      case "7days":
        return ["7 Days", "7 days before"];
      case "30days":
        return ["30 days", "30 days before"];
      default:
        return null;
    }
  };

  const getDayAndMonth = (date) => {
    const MONTHS = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return MONTHS[new Date(date).getMonth()] + " " + new Date(date).getDate();
  };

  const mapAPIDateToTitleDate = (start, end) => {
    return (
      <>
        {start ? getDayAndMonth(start) : ""}
        {end && " →"}
        {end ? ` ${getDayAndMonth(end)}` : ""}
      </>
    );
  };

  return (
    <div className="overview-box">
      <div className="overview-top">
        <div className="overview-title">Overview</div>
        <div className="overview-filters">
          {OVERVIEW_FILTERS.map((filter, index) => {
            return (
              <button
                key={index}
                className={`${filter.filterClassName} ${
                  overviewFilter === filter.name
                    ? filter.activeFilterClassName
                    : ""
                }`}
                onClick={() => setOverviewFilter(filter.name)}
              >
                {filter.element}
              </button>
            );
          })}
          <RangeCalendar
            actionOnSelect={() => setOverviewFilter("date")}
            startDate={overviewStartDateDatePicker}
            setStartDate={setOverviewStartDateDatePicker}
            endDate={overviewEndDateDatePicker}
            setEndDate={setOverviewEndDateDatePicker}
            showDatePicker={overviewShowDatePicker}
            setShowDatePicker={setOverviewShowDatePicker}
            isActive={overviewFilter === "date"}
            customCanelFunction={() =>
              overviewFilter === "date" && setOverviewFilter("today")
            }
          />
        </div>
      </div>
      <div className="overview-table">
        {/* Cells */}
        {[
          {
            title: "Net sales",
            name: "net_sales",
            isDollar: true,
            description:
              "The sum of your gross sales minus its returns, allowances, and discounts.",
          },
          {
            title: "Total sales",
            name: "total_sales",
            isDollar: true,
            description:
              "The total amount of sales a company earned throughout a specific period of time.",
          },
          {
            title: "Accepted orders",
            name: "accepted_orders",
            isDollar: false,
            description: "Number of accepted orders.",
          },
          {
            title: "Merchant views",
            name: "merchant_view",
            isDollar: false,
            description: "Number of users who viewed your online menu.",
          },
        ].map((cell, index) => (
          <div key={index} className="overview-table-cell">
            <div className="overview-table-top">
              <div className="overview-table-title">
                <span>{cell.title}</span>
                {cell?.noInfo ? null : (
                  <Tooltip
                    tooltipBoxClasses="tooltip-box"
                    label={<IIcon />}
                    tooltipBody={
                      <>
                        <div className="tooltip-row tooltip-current">
                          <span>
                            {getOverviewFilter()
                              ? getOverviewFilter()[0]
                              : mapAPIDateToTitleDate(
                                  overviewData?.current_period?.from,
                                  overviewData?.current_period?.to
                                )}
                          </span>
                          <span>
                            {`${cell?.isDollar ? "$" : ""}${(
                              overviewData?.current_period?.data[cell.name] || 0
                            )
                              .toFixed(cell?.isDollar ? 2 : 0)
                              ?.toLocaleString()}`}
                          </span>
                        </div>
                        <div className="tooltip-row tooltip-previous">
                          <span>
                            {getOverviewFilter()
                              ? getOverviewFilter()[1]
                              : mapAPIDateToTitleDate(
                                  overviewData?.past_period?.from,
                                  overviewData?.past_period?.to
                                )}
                          </span>
                          <span>
                            {`${cell?.isDollar ? "$" : ""}${(
                              overviewData?.past_period?.data[cell.name] || 0
                            )
                              ?.toFixed(cell?.isDollar ? 2 : 0)
                              ?.toLocaleString()}`}
                          </span>
                        </div>
                        <div className="tooltip-bottom">
                          {cell?.description}
                        </div>
                      </>
                    }
                    tooltipBodyClasses="tooltip"
                  />

                  // <div className="tooltip-box">
                  //   <IIcon />
                  //   {!cell?.isComingSoon && (
                  //     <div className="tooltip">
                  //       <div className="tooltip-row tooltip-current">
                  //         <span>
                  //           {getOverviewFilter()
                  //             ? getOverviewFilter()[0]
                  //             : mapAPIDateToTitleDate(
                  //                 overviewData?.current_period?.from,
                  //                 overviewData?.current_period?.to
                  //               )}
                  //         </span>
                  //         <span>
                  //           {`${cell?.isDollar ? "$" : ""}${(
                  //             overviewData?.current_period?.data[cell.name] || 0
                  //           )
                  //             .toFixed(cell?.isDollar ? 2 : 0)
                  //             ?.toLocaleString()}`}
                  //         </span>
                  //       </div>
                  //       <div className="tooltip-row tooltip-previous">
                  //         <span>
                  //           {getOverviewFilter()
                  //             ? getOverviewFilter()[1]
                  //             : mapAPIDateToTitleDate(
                  //                 overviewData?.past_period?.from,
                  //                 overviewData?.past_period?.to
                  //               )}
                  //         </span>
                  //         <span>
                  //           {`${cell?.isDollar ? "$" : ""}${(
                  //             overviewData?.past_period?.data[cell.name] || 0
                  //           )
                  //             ?.toFixed(cell?.isDollar ? 2 : 0)
                  //             ?.toLocaleString()}`}
                  //         </span>
                  //       </div>
                  //       <div className="tooltip-bottom">
                  //         The sum of a your’s gross sales minus its returns,
                  //         allowances, and discounts.
                  //       </div>
                  //     </div>
                  //   )}
                  // </div>
                )}
              </div>

              {overviewLoading ? (
                <div>
                  <SkeletonLoading
                    borderRadius={"3px"}
                    width={"32px"}
                    height={"16px"}
                    count={1}
                  />
                </div>
              ) : (
                <div
                  className={`overview-table-rate ${
                    overviewData?.growth[cell.name] === 0 ||
                    overviewData?.growth[cell.name] === "inf"
                      ? ""
                      : overviewData?.growth[cell.name] > 0
                      ? "overview-table-rate--green"
                      : "overview-table-rate--red"
                  }`}
                >
                  {overviewData?.growth[cell.name] === 0 ||
                  overviewData?.growth[cell.name] ===
                    "inf" ? null : overviewData?.growth[cell.name] < 0 ? (
                    <SolidArrowDownIcon />
                  ) : (
                    <SolidArrowTopIcon />
                  )}{" "}
                  <span>
                    {Math.abs(
                      typeof overviewData?.growth[cell.name] === "number"
                        ? overviewData?.growth[cell.name]
                        : 0
                    ).toFixed(1)}
                    %
                  </span>
                </div>
              )}
            </div>
            <div className="overview-table-price">
              {overviewLoading ? (
                <SkeletonLoading
                  borderRadius={"3px"}
                  width={"88px"}
                  height={"24px"}
                  count={1}
                />
              ) : (
                `${cell?.isDollar ? "$" : ""}${(
                  overviewData?.current_period?.data[cell.name] || 0
                )
                  .toFixed(cell?.isDollar ? 2 : 0)
                  ?.toLocaleString()}` || " - "
              )}
            </div>
          </div>
        ))}
        {/* Merchant view */}
        {/* <div className="overview-table-cell">
          <div className="overview-table-top">
            <div className="overview-table-title">
              <span>Merchant view</span>
              <IIcon />
            </div>
            <div
              className={`overview-table-rate ${
                false
                  ? "overview-table-rate--green"
                  : "overview-table-rate--red"
              }`}
            >
              {false ? null : true ? (
                <SolidArrowDownIcon />
              ) : (
                <SolidArrowTopIcon />
              )}
              <span>1.3%</span>
            </div>
          </div>
          <div className="overview-table-price">10,637</div>
        </div> */}
        <div className="overview-table-line overview-table-line--top"></div>
        <div className="overview-table-line"></div>
      </div>
    </div>
  );
};

export default Overview;
