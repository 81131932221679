import { Card, Spinner } from "react-bootstrap";
import { Image, StyledCard } from "./styles";
import React, { useEffect, useRef, useState } from "react";
import { faCopy, faPen } from "@fortawesome/free-solid-svg-icons";
import { formatTime, numToWeekday, blendColors, getData } from "utils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Switch from "react-switch";
import { connect } from "react-redux";
import { duplicateMenu } from "api/menus";
import { editMenu } from "api/menus";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Colors } from "assets/colors";

/*
 * Menu card which is used in menu list
 */

const MenuCard = ({ id, index, moveCard, locale, ...props }) => {
  const [times, setTimes] = useState([]);
  const [show, setShow] = useState(false);
  const [published, setPublished] = useState(props.is_active);
  const [duplicateLoading, setDuplicateLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setPublished(props.is_active);
  }, [props.is_active]);

  useEffect(() => {
    if (props.active_hours?.length) {
      let tempTimes = [];
      for (let i = 0; i < props.active_hours.length; i++) {
        tempTimes.push({
          days: props.active_hours[i]?.weekdays,
          start: formatTime(
            props.active_hours && props.active_hours[i]?.from_hour
          ),
          end: formatTime(
            props.active_hours && props.active_hours[i]?.from_hour,
            props.active_hours[i]?.duration
          ),
        });
      }
      setTimes(tempTimes);
    }
  }, [props.active_hours]);

  const handleEdit = (event) => {
    props.onEdit(event);
    closePopover();
  };

  const handleChangePublish = (e) => {
    setPublished(e);
    dispatch(editMenu(id, { is_active: e })).then((res) => {
      setPublished(e);
    });
  };
  const closePopover = () => {
    // popover.current.hide()
    document.body.click();
    setShow(false);
  };

  const ref = useRef(null);

  const handleDuplicate = (id, e) => {
    e.stopPropagation();
    setDuplicateLoading(true);
    props
      .duplicateMenu(id)
      .then((res) => {
        setDuplicateLoading(false);
        props.setMenus((menus) => [
          {
            ...menus.find((menu) => menu.id === id),
            id: getData(res)?.id,
            tag: "New",
            name: `${menus.find((menu) => menu.id === id).name} (Duplicated)`,
            is_active: false,
          },
          ...menus.filter((menu) => !menu.type),
        ]);
      })
      .catch((e) => {
        setDuplicateLoading(false);
      });
    closePopover();
  };

  if (props.promo) return null;

  const color =
    props.types.length === 1
      ? props.types[0].color
      : props.types.length === 2
      ? blendColors(props.types[0].color, props.types[1].color)
      : props.types.length === 3
      ? blendColors(
          blendColors(props.types[0].color, props.types[1].color),
          props.types[2].color
        )
      : "";

  const areDaysInRow = (days) => {
    let expectedLength = days[days.length - 1] - days[0] + 1;
    let realLength = days.length;
    return realLength === expectedLength;
  };

  return (
    <StyledCard
      onClick={props.onClick}
      ref={ref}
      className={props.className + " shadow-sm "}
    >
      <Image
        src={props.images && props.images[0] ? props.images[0].image_med : ""}
        effect="blur"
      >
        <div onClick={(e) => e.stopPropagation()}>
          <Switch
            className="mt-2 mr-2"
            onChange={handleChangePublish}
            checked={published}
            handleDiameter={18}
            height={24}
            offColor="#E45628"
            uncheckedIcon={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  paddingRight: 2,
                  // fontSize: 13,
                  fontWeight: "bold",
                }}
              >
                OFF
              </div>
            }
            checkedIcon={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  paddingLeft: 2,
                  fontWeight: "bold",
                }}
              >
                ON
              </div>
            }
          />
        </div>
      </Image>
      <Card.Body>
        <div
          className="type-badge"
          style={{
            backgroundColor: Colors.primary1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {/* <FontAwesomeIcon size='lg' icon={props.type?.icon} /> */}
          {props.types.map((t, index) => (
            <img
              src={t.image}
              style={{ marginBottom: index !== props.types.length - 1 ? 5 : 0 }}
            />
          ))}
        </div>
        <div className="body">
          <Card.Title className="font-weight-bold" style={{ color: "#8E8E93" }}>
            {props.name}
          </Card.Title>
          <div className="d-flex justify-content-between times">
            {times?.map((time, index) => {
              if (index < 3 || (index === 3 && times.length === 4)) {
                return (
                  <span
                    key={index}
                    className="time-rule"
                    style={{ color: "#282328" }}
                  >
                    {time.days?.length > 2 && areDaysInRow(time.days) ? (
                      <span>
                        {numToWeekday(time.days[0])}-
                        {numToWeekday(time.days[time.days?.length - 1])}:
                      </span>
                    ) : (
                      <span>
                        {time.days.map((t, i) => {
                          if (time.days?.length - 1 === i) {
                            return numToWeekday(t) + ":";
                          } else {
                            return numToWeekday(t) + ",";
                          }
                        })}
                      </span>
                    )}
                    {time.start.split(" ")[0]}
                    <span className="time-rule__sm">
                      {time.start.split(" ")[1]}
                    </span>
                    {"-"}
                    {time.end.split(" ")[0]}
                    <span className="time-rule__sm">
                      {time.end.split(" ")[1]}
                    </span>
                  </span>
                );
              } else if (index === 3 && times.length > 4) {
                return <span className="more">And More...</span>;
              }
            })}
          </div>
          <div className="buttonsWrapper" onClick={(e) => e.stopPropagation()}>
            <button className="action-btn" onClick={handleEdit}>
              <FontAwesomeIcon icon={faPen} color="#7B7B7B" />
            </button>
            <button
              className="action-btn"
              onClick={(e) => handleDuplicate(id, e)}
            >
              {duplicateLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <FontAwesomeIcon icon={faCopy} color="#7B7B7B" />
              )}
            </button>
          </div>
        </div>
      </Card.Body>
    </StyledCard>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  duplicateMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuCard);
