import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import styled from "styled-components";
import { Colors } from "assets/colors";

import Loading from "components/Loading";

const StyeledButton = styled(Button)`
  background-color: ${Colors.primary1};
  border-color: ${Colors.primary1};
  border-radius: 50px;
  box-shadow: 0px 4px 12px -3px rgba(0, 0, 0, 0.47);

  &:hover {
    background-color: ${(p) => p.theme.orangeLow};
    border-color: ${(p) => p.theme.orangeLow};
  }

  &:active {
    background-color: ${(p) => p.theme.orangeLow} !important;
    border-color: ${(p) => p.theme.orangeLow} !important;
  }

  &:focus {
    background-color: ${(p) => p.theme.orange};
    border-color: ${(p) => p.theme.orange};
    box-shadow: 0 0 0 0.2rem ${(p) => p.theme.orangeLow} !important;
  }
`;

const CustomButton = (props) => (
  <StyeledButton
    {...props}
    disabled={props.loading}
    variant={props.variant}
    type="submit"
    onClick={props.onClick}
  >
    {!props.loading ? props.children : <Loading nopadding />}
  </StyeledButton>
);

export default CustomButton;

CustomButton.defaultProps = {
  variant: "primary",
  onClick: null,
};
