import styled from "styled-components";
import { Colors } from "assets/colors";

export const StyledTable = styled.div`
  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .results {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: ${Colors.textPrimary1};
    padding-left: 0;
    margin-top: 21px;
  }

  .table-category {
    background: #f1f3f4;
    border-radius: 0;
    width: 100%;
    height: 32px;
    padding: 10px 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    width: 100%;
    position: absolute;
    left: 0;
    top: 28px;
    border-top: 1px solid #e7e8e9;

    &--no-header {
      top: 12px;
    }

    &--left {
      display: flex;
      align-items: center;
      flex-direction: row;

      p {
        margin-bottom: 0;
      }
    }

    &-title {
      color: #4f566b;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
    }

    &-range {
      color: #1a1f36;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      margin-left: 8px;
    }
  }

  .table-top {
    margin-bottom: 10px;
  }

  table {
    width: 100%;
    margin: 0;
    margin-top: 20px;

    th {
      font-weight: 600;
      font-size: 11px;
      line-height: 16px;
      height: 20px;
      border-bottom: none;
      letter-spacing: 0.06em;
      text-transform: uppercase;
    }

    td {
      border-top: 1px solid #e7e8e9;
      border-bottom: none;
      min-height: 42px;
      padding: 12px 8px;
      vertical-align: top;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: ${Colors.textPrimary1};

      p {
        margin: 0;
      }
    }
  }

  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .strong {
    font-weight: bold;
  }

  .line {
    margin-bottom: 4px;
  }

  .more-btn {
    width: 38px !important;
    height: 22px;
    border-radius: 5px;
    text-align: center;
    padding: 0 13px !important;
    display: flex;
    align-items: center;
    justify-content: center;

    &--open {
      width: 38px !important;
      height: 22px;
      background: #ffffff !important;
      box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
        0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);
      padding: 9px 13px !important;

      svg {
        margin-top: -2px;
      }
    }

    svg {
      margin-top: -2px;
    }

    &--list-item {
      width: 146px;
      height: 36px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: ${Colors.textPrimary1};
      padding: 8px 12px 10px;

      &-red {
        font-weight: 600;
        color: #c7322d;
      }
    }
  }

  .delete-modal {
    width: 412px;
    padding: 20px;
  }

  .title {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #131b1f;
    margin-bottom: 8px;
  }

  .desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #666b6d;
    margin-bottom: 12px;
  }

  .qa {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #131b1f;
    margin-bottom: 20px;
  }

  .btns {
    display: flex;
  }

  .btn-cancel {
    background: #c7322d;
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px #c7322d,
      0px 1px 1px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    width: 61px;
    height: 28px;
    padding: 5px 8px;
    color: #ffffff;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
  }

  .btn-delete {
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
      0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    border-radius: 4px;
    width: 61px;
    height: 28px;
    padding: 5px 8px;
    color: #131b1f;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
  }

  .switch-box {
    height: 20px;
  }
`;
