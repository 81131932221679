import styled from "styled-components";

export const PagingRow = styled.div`
  display: flex;
  justify-content: center;

  .skeleton {
    width: 294px;
    justify-content: space-around;
    display: flex;
    flex-direction: row;
  }

  ul {
    margin-bottom: 0;
  }

  .MuiPaginationItem-previousNext[aria-label="Go to next page"] {
    position: relative;
    margin-right: 40px;
    margin-left: 9px;

    &:before {
      content: "Next";
      position: absolute;
      background: #ffffff;
      box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
        0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      padding: 6px 8px;
      color: #3c4256;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      top: 0px;
      left: 0px;
    }

    &:hover {
      &:before {
        box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.22),
          0px 0px 0px 1px rgba(60, 66, 87, 0.16),
          0px 1px 1px rgba(0, 0, 0, 0.16);
      }
    }
  }

  .MuiPaginationItem-previousNext[aria-label="Go to previous page"] {
    position: relative;
    margin-left: 30px;
    margin-right: 9px;

    &:before {
      content: "Previous";
      position: absolute;
      background: #ffffff;
      box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
        0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      padding: 6px 8px;
      color: #3c4256;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      right: 0px;
      top: 0px;
    }

    &:hover {
      &:before {
        box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.22),
          0px 0px 0px 1px rgba(60, 66, 87, 0.16),
          0px 1px 1px rgba(0, 0, 0, 0.16);
      }
    }
  }

  .MuiPaginationItem-page {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    height: 28px;
    color: #000000;
    text-align: center;
    padding: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 0 3px;
    min-width: 25px;
    animation: none !important;
    padding: 0 3px;

    &:hover {
      min-width: 25px;
    }

    &:hover,
    &:focus {
      box-shadow: none !important;
    }

    &:hover,
    &focus {
      background: #e7f0ff;
      border-radius: 4px;
      outline: 1px solid #e7f0ff;
    }
  }

  .Mui-selected {
    color: white;
    overflow: hidden;
    background: #3765f0 !important;
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px #3765f0,
      0px 1px 1px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    height: 28px;
    min-width: 23px !important;
  }

  .MuiCollapse-container {
    transition-duration: 0s !important;
  }

  .MuiPaginationItem-circular {
    /* display: none; */
    animation: none;
    transition: none !important;
    transition-duration: 0s !important;
  }

  button {
    transition-duration: 0s !important;
    animation: none !important;
  }

  .disbaled {
    span:before {
      color: #bfc1c6 !important;
      color: #bfc1c6 !important;
    }
  }
`;
