import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import React, { useEffect, useState } from "react";
import { deleteModifier, duplicateModifier } from "../../api/items";
import {
  faArrowsAlt,
  faCopy,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { getModifiers, getRestaurantModifiers, moveModifier } from "api/items";

import { AddMenuCard } from "components/Menu/MenuCard";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import CardColumns from "react-bootstrap/CardColumns";
import Col from "react-bootstrap/Col";
import ConfirmDeleteModal from "components/confirmDeleteModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "components/Loading";
import { MenusTabs } from "../menus/Preview";
import NoPermissions from "components/commons/NoPermissions";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { connect } from "react-redux";
import {getData, priceTransformer} from "../../utils";
import { immutableMove } from "../../utils";
import styled from "styled-components";
import useDebounce from "../../hooks/useDebounce";

const Modifiers = ({ locale, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [repositionLoading, setRepositionLoading] = useState(false);
  const [modifiers, setModifiers] = useState([]);
  const [hasNext, setHasNext] = useState(false);
  const [moreLoading, setMoreLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

  useEffect(() => {
    if (
      props.selectedRestaurant &&
      (props.permissions.includes("__panel__") ||
        props.permissions.includes("menus"))
    ) {
      handleGetData();
    }
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", beforeunload);

    return () => {
      window.removeEventListener("beforeunload", beforeunload);
    };
  }, [repositionLoading]);

  const beforeunload = (e) => {
    if (repositionLoading) {
      e.preventDefault();
      e.returnValue = true;
    }
  };

  const handleGetData = (loadMore, fresh) => {
    if (!loadMore) setLoading(true);
    if (loadMore) setMoreLoading(true);
    if (fresh) {
      setModifiers([]);
      setPage(1);
      setSearch("");
    }
    props
      .getModifiers(
        props.selectedRestaurant,
        fresh ? "" : debouncedSearch,
        fresh ? 1 : page
      )
      .then((res) => {
        setModifiers((p) => [...p, ...sortOptions(getData(res)?.results)]);
        let matches = getData(res)?.next?.match(/page=([^&]*)/);
        if (matches?.length) setPage(matches[1]);
        setHasNext(getData(res)?.next);
        setMoreLoading(false);
        setLoading(false);
      });
  };

  const sortOptions = (modifiers) => {
    return modifiers.map((m) => {
      m.options = m.options.sort((a, b) => a.position - b.position);

      return m;
    });
  };

  if (
    !props.permissions.includes("__panel__") &&
    !props.permissions.includes("menus")
  )
    return <NoPermissions />;

  if (!props.selectedRestaurant) {
    return (
      <Row className="fulls">
        <Col xs={12} sm={12} className="text-center">
          <h2 className="h3 text-white">{locale.no_restaurant}</h2>
        </Col>
      </Row>
    );
  }

  const handleEdit = (id) => {
    props.history.push(`/console/create-modifiers/edit/${id}`);
  };

  const handleDuplicate = (id) => {
    setLoading(true);
    props
      .duplicateModifier(id)
      .then((res) => {
        handleGetData(false, true);
      })
      .catch((e) => {
        console.log("error in duplicate", e);
      });
  };

  const handleDelete = (id) => {
    setConfirmDeleteModal(false);
    setLoading(true);
    props
      .deleteModifier(id)
      .then((res) => {
        setLoading(false);
        handleGetData(false, true);
      })
      .catch((e) => {
        setLoading(false);
        console.log("error in delete modifiers", e);
      });
  };

  const handleItemDragEnd = (result) => {
    let { destination, source, draggableId } = result;

    /**  */
    if (!destination) return;

    /** no change */
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    /** just change ordering in one column */
    if (destination.droppableId === source.droppableId) {
      setRepositionLoading(true);
      props.moveModifier(draggableId, destination.index + 1).then((res) => {
        setRepositionLoading(false);
      });

      let options = modifiers.find((m) => m.id === source.droppableId)?.options;
      options = immutableMove(options, source.index, destination.index);
      setModifiers(
        modifiers.map((m) => {
          if (m.id !== source.droppableId) return m;
          return { ...m, options };
        })
      );
      return;
    }
  };

  return (
    <Wrapper>
      <div
        style={{
          marginTop: 80,
        }}
      >
        <SCardColumns
          as={loading ? "div" : modifiers.length > 0 ? SCardColumns : "div"}
        >
          <DragDropContext onDragEnd={handleItemDragEnd}>
            <AddMenuCard
              title={"New Modifier"}
              className="mb-6"
              onClick={() =>
                props.history.push("/console/create-modifiers/add/")
              }
              style={{
                background: "#fff",
                height: 70,
              }}
            />
            {loading ? (
              <Loading />
            ) : (
              modifiers.map((m) => (
                <Droppable droppableId={m.id} type="items">
                  {(provided, snapshot) => (
                    <SCard
                      className="mb-4"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      <div className="header d-flex align-items-center justify-content-between">
                        <div className="title">{m.title}</div>
                        <div className="d-flex align-items-end">
                          <button
                            className="action-btn"
                            onClick={() => handleEdit(m.id)}
                          >
                            <FontAwesomeIcon icon={faPen} color="#fff" />
                          </button>
                          <button
                            className="action-btn"
                            onClick={() => handleDuplicate(m.id)}
                          >
                            <FontAwesomeIcon icon={faCopy} color="#fff" />
                          </button>
                          <button
                            className="action-btn"
                            onClick={() => setConfirmDeleteModal(m.id)}
                          >
                            <FontAwesomeIcon icon={faTrash} color="#fff" />
                          </button>
                        </div>
                      </div>
                      <div className="items">
                        {m.options.map((o, index) => (
                          <Draggable draggableId={o.id} index={index}>
                            {(provided, snapshot) => (
                              <Item
                                className="d-flex align-items-center justify-content-between"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <ReorderingHandle>
                                  <FontAwesomeIcon icon={faArrowsAlt} />
                                </ReorderingHandle>

                                <div className="title">{o.title}</div>
                                <div className="price">
                                  {o.price ? priceTransformer(o.price) : null}
                                </div>
                              </Item>
                            )}
                          </Draggable>
                        ))}
                      </div>
                      {provided.placeholder}
                    </SCard>
                  )}
                </Droppable>
              ))
            )}
          </DragDropContext>
        </SCardColumns>
        {hasNext && !loading && (
          <div className="d-flex justify-content-center py-3">
            <Button onClick={() => handleGetData(true)} disabled={moreLoading}>
              {moreLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Load More"
              )}
            </Button>
          </div>
        )}
      </div>
      <ConfirmDeleteModal
        onHide={() => setConfirmDeleteModal(false)}
        onClick={() => handleDelete(confirmDeleteModal)}
        show={confirmDeleteModal}
      />
    </Wrapper>
  );
};

export default connect(
  (state) => ({
    selectedRestaurant: state.user.selectedRestaurant,
    tutorial: state.user.tutorial,
    permissions: state.user.permissions,
    locale: state.locale[state.locale.active],
  }),
  {
    getRestaurantModifiers,
    getModifiers,
    duplicateModifier,
    deleteModifier,
    moveModifier,
  }
)(Modifiers);

const SCardColumns = styled(CardColumns)``;
const AddNewButton = styled.button`
  background: #ffffff;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.12);
  color: #4e4e4e;
  font-weight: bold;
  font-size: 16px;
`;

const SCard = styled(Card)`
  background: #f1f1f1;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.12);
  padding: 25px;

  .header {
    margin-bottom: 30px;
  }

  .title {
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    width: 100%;
    letter-spacing: 0.15px;
    color: #121212;
  }

  .price {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.87);
  }

  .action-btn {
    margin-left: 10px;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    background-color: #4e4e4e;
    display: flex;
    border: none;
    align-items: center;
    justify-content: center;
    outline: none;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14),
      0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
`;

const Item = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.12);
  padding: 25px;
`;

const ReorderingHandle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  &:hover {
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  @media (min-width: 576px) {
    .card-columns {
      widows: 2 !important;
    }
  }
`;
