import React, { useState, useEffect, useCallback, useContext } from "react";
import { StyledWrapper } from "./styles";
import { ReactComponent as BackIcon } from "assets/image/back.svg";
import Button from "components/common/Button";
import { useHistory, useParams } from "react-router";
import Form from "./Form";
import { useDispatch, useSelector } from "react-redux";
import { getDiscountByIdApiCall } from "api/discounts";
import { getData } from "utils";
import Loading from "components/Loading";
import SkeletonLoading from "components/common/SkeletonLoading";

const CreateDiscounts = () => {
  const [discountData, setDiscountData] = useState(null);
  const history = useHistory();
  const { id } = useParams();
  const [detailsLoading, setDetailsLoading] = useState(id ? true : false);
  const dispatch = useDispatch();
  const restaurantId = useSelector((state) => state.user?.selectedRestaurant);

  const handleGoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    if (id) {
      dispatch(getDiscountByIdApiCall(restaurantId, id))
        .then((res) => {
          setDiscountData(getData(res));
          setDetailsLoading(false);
        })
        .catch((err) => setDetailsLoading(false));
    }
  }, [id]);

  return (
    <StyledWrapper>
      {/* Title row  */}
      <div className="title-row flex-row">
        <div className="flex-row-end">
          <Button
            label={<BackIcon className="back" />}
            type="text"
            classes="back-btn"
            type="text"
            onClick={handleGoBack}
          />
          <h1 className="title">
            {id ? (
              detailsLoading ? (
                <SkeletonLoading
                  borderRadius={4}
                  width={172}
                  height={23}
                  count={1}
                />
              ) : (
                discountData?.name
              )
            ) : (
              "Create a discount"
            )}
          </h1>
        </div>
        {/* Form */}
        {detailsLoading ? (
          // {true ? (
          <div className="loading-box">
            <Loading />
          </div>
        ) : (
          <Form discountData={discountData} />
        )}
      </div>
    </StyledWrapper>
  );
};

export default CreateDiscounts;
