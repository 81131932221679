import React from 'react'
import styled from 'styled-components'
import Modal from '../../containers/billing/modal'
import Button from 'react-bootstrap/Button'

const ConfirmDeleteModal = props => {
    const { onHide, onClick, show } = props
    return (
        <Modal
            show={show}
            header={'Confirm Deletion'}
            onHide={onHide}
        >
            <ConfirmDeleteModalWrapper>
                <p className='text'>Are you sure you want to delete? This action cannot be undone</p>
                <div className='button_wrapper'>
                    <Button
                        id="cypress_confirm_delete"
                        style={{ width: '150px', background: 'red', outline: 'none', border: 'none', marginRight: '20px', marginTop: '10px' }}
                        onClick={onClick}
                    >
                        Yes, delete it
                </Button>
                    <Button
                        style={{ width: '150px', background: '#0092ff', outline: 'none', border: 'none', marginTop: '10px' }}
                        onClick={onHide}
                    >
                        No, keep it
                </Button>
                </div>
            </ConfirmDeleteModalWrapper>
        </Modal>
    )
}

export default ConfirmDeleteModal

const ConfirmDeleteModalWrapper = styled.div`
  width: 100%;
  min-height: 100px;
  .text{
    font-family: Inter;
    font-weight: 500;
    font-size: 18px
  }
  .button_wrapper{
    displya: flex;
    flex-direciton: row;
    margin-top: 1.5rem;
  }
`