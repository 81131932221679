import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/css/sb-admin-2.min.css";
import { StyledWrapper, StyledContent, StyledBody, Footer } from "./styles";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";

const Layout = ({ locale, layoutStyles, ...props }) => {
  return (
    <StyledWrapper>
      <Topbar />
      <Sidebar />
      <StyledContent>
        <StyledBody
          className={`m-0 w-100`}
          fluid={false}
          style={layoutStyles ? layoutStyles : { paddingTop: "30px" }}
        >
          {props.children}
          <Footer/>
        </StyledBody>
      </StyledContent>
    </StyledWrapper>
  );
};

export default withRouter(
  connect((state) => ({
    locale: state.locale[state.locale.active],
    orders: state.orders.length,
  }))(Layout)
);
