import React from "react";
import { useHistory } from "react-router-dom";

import Stripe from "./";

const Reauth = (props) => {
  const history = useHistory();
  const handleClick = () => {
    history.push("/console/orders");
  };

  return (
    <Stripe
      title="Account successfully created!"
      buttonText="Go to Console"
      buttonClick={handleClick}
    >
      <p>
        Your ordering payment is setup, now you can get orders via Omnicart.
      </p>
    </Stripe>
  );
};

export default Reauth;
