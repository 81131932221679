import React, { useContext, useEffect } from "react";
import { StyledModalWrapper } from "./styles";
import { ReactComponent as SolidCloseIcon } from "assets/image/solidClose.svg";
import { createPortal } from "react-dom";
import { ModalContext } from "context/modalContext";

const GeneralModal = ({ ...props }) => {
  const { modal, handleModal, modalContent, hideCloseBtn, classes } =
    useContext(ModalContext);

  if (modal) {
    return createPortal(
      <StyledModalWrapper onClick={() => handleModal()}>
        <div onClick={(e) => e.stopPropagation()} className="modal-general">
          {hideCloseBtn ? null : (
            <div onClick={() => handleModal()} className={`close ${classes}`}>
              <SolidCloseIcon />
            </div>
          )}
          {modalContent}
        </div>
      </StyledModalWrapper>,
      document.getElementById("modal-root")
    );
  } else {
    return null;
  }
};

export default GeneralModal;
