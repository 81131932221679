import React, {
  useState, useEffect, forwardRef, useRef, useImperativeHandle,
} from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { connect } from 'react-redux';
// import {withRouter} from 'react-router'
import useRouter from 'use-react-router';
import StripeScriptLoader from 'react-stripe-script-loader';
import {
  StripeProvider,
  Elements,
  CardNumberElement,
} from 'react-stripe-elements';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLIC_KEY } from 'constants';

import Button from 'components/button';
import { createCustomer } from 'api/billing/customers';
import Loading from 'components/Loading';
import CardSection from './CardSection';

const CheckoutForm = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const submit = useRef();
  const { history, location, match } = useRouter();

  const onSuccess = (status) => {
    history.push('/payment-status', { status });
  };

  const onFailure = (status) => {
    history.push('/payment-status', { status });
  };

  const stripePaymentMethodHandler = (result, email) => {
    if (result.error) {
      // Show error in payment form
    } else {
      // Otherwise send paymentMethod.id to your server
      props.createCustomer({ payment_method: result.paymentMethod.id })
        .then((result) => {
          // return result.json();
          props.setLoading(false);
        }).then((customer) => {
        // The customer has been created
          if (props.onSubmit) { props.onSubmit(); }
        });
    }
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { billing } = props;
    let body = {
      type: 'card',
      card: elements.getElement(CardElement),
    };
    props.setLoading(true);
    if (billing) {
      body = {
        ...body,
        billing_details: {
          address: {
            city: billing.city,
            country: billing.city,
            line1: billing.line_1,
            line2: billing.line_2,
            postal_code: billing.postal_code,
            state: billing.province,
          },
          email: billing.email,
          name: `${billing.first_name} ${billing.last_name}`,
          phone: billing.business_number,
        },
      };
    }
    const result = await stripe.createPaymentMethod(body);

    stripePaymentMethodHandler(result);
    if (props.onSubmit) props.onSubmit(result);
  };

  useImperativeHandle(ref, () => ({
    handleSubmit: () => submit.current.click(),
    loading,
  }));

  const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

  return (
    <StripeScriptLoader
      uniqueId="myUniqueId"
      script="https://js.stripe.com/v3/"
      loader="Loading..."
    >
      <StripeProvider apiKey="stripeApiKey">
        <Elements stripe={stripePromise}>
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <CardSection />
            <button style={{ display: 'none' }} ref={submit} type="submit">submit</button>
          </form>
        </Elements>
      </StripeProvider>
    </StripeScriptLoader>
  );
});

export default connect(null, { createCustomer }, null, { forwardRef: true })(CheckoutForm);
