import styled from "styled-components";
import { Colors } from "assets/colors";

export const StyledOrders = styled.div`
  position: relative;
  padding-top: 82px;

  .search-input {
    width: 100%;
    height: 52px;
    border: 1px solid #d8dce2;
    border-top: none;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    border-right: none;
    border-left: none;
    
    .input {
      border: none;
      width: 100%;
      height: 100%;
      padding-left: 82px;
      padding-top: 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      transition: all 1s ease;
      color: ${Colors.textPrimary1};

      &::placeholder {
        color: #7b7b7b;
      }

      &:focus,
      &:focus-visible {
        outline: none;
        background: #f7f9fa;
        transition: all 1s ease;

        + .label {
          color: ${Colors.primary1};
        }
      }
    }

    .label {
      position: absolute;
      left: 56px;
    }
  }

  .email-alert {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #7b7b7b;
    margin-right: 56px;
    background: transparent;
    position: absolute;
    right: 0;

    label {
      white-space: nowrap;
      margin-left: 10px;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #7b7b7b;
      margin-bottom: 0;
    }
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .title-row {
    padding-left: 56px;
    padding-right: 56px;
    margin-bottom: 40px;

    .title {
      font-weight: 800;
      font-size: 28px;
      line-height: 34px;
      color: ${Colors.textPrimary1} !important;
      margin-right: 27px;
    }

    .pause-status-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &--active {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #21ad59;
      }

      &--paused {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #c7322d;
      }

      p {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #363d41;
        margin-left: 7px;
        margin-bottom: 0;
      }
    }
  }

  .refresh-btn {
    font-weight: 700 !important;
    margin-right: 16px !important;
  }

  .table-box {
    margin: 0 56px;
    margin-bottom: 100px;
  }

  .tabs {
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    width: fit-content;
    overflow: hidden;
    box-shadow: 0px 0px 0px 1px #DCDDDD, 0px 2px 5px rgba(60, 66, 87, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.1);
  }

  .tab {
    width: 178px;
    height: 52px;
    position: relative;

    @media (max-width: 1350px) {
      width: 168px;
    } 

    &-btn {
      box-shadow: -1px 0px 0px #dcdddd !important;
      width: 100% !important;
      padding: 17px 42px !important;
      height: 100% !important;
      background: #f9fafa !important;
      border-radius: 0 !important;
      color: ${Colors.textPrimary1} !important;
      justify-content: center !important;

      &--active {
        box-shadow: -1px 0px 0px #dcdddd, inset 0px -2.5px 0px #2b64f5 !important;
        color: #2b64f5 !important;
        font-weight: bold !important;
      }

      &:hover {
        background: rgba(255, 255, 255, 0.6) !important;
      }
    }
  }

  .option {
    width: 158px;
    height: 36px;
    padding: 8px 12px 10px 12px;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
  }

  .filters {
    display: flex;
    flex-direction: row;
    width: fit-content;
    margin: 20px 0;
  }

  .filter {
    width: fit-content;
    height: 38px;

    &-btn {
      border: 1px solid #dcdddd !important;
      padding: 9px 21px !important;
      height: 100% !important;
      border-radius: 50px !important;
      color: ${Colors.textPrimary1} !important;
      justify-content: center !important;
      margin-right: 6px !important;
      white-space: nowrap;

      &--active {
        font-weight: bold !important;
        background: #f3f4f4 !important;
        border: 1px solid ${Colors.textPrimary1} !important;
        color: ${Colors.textPrimary1} !important;
      }

      &:hover {
        border: 1px solid #95989a !important;
      }
    }
  }

  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-right: -12px;

  }

  .name {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${Colors.textPrimary1};
  }

  .status {
    &--new {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #9c400b;
      background: #f9e6d2;
      border-radius: 4px;
      padding: 4px 10px;
      
      svg {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }

    }
    
    &--scheduled {
      padding: 4px 6px;
    }
    
    &--accepted {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #045f5a;
      background: #deefef;
      border-radius: 4px;
      padding: 4px 8px;
      
    }

    &--ready {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #0e623f;
      background: #cbf4c9;
      border-radius: 4px;
      padding: 4px 8px;
      
    }

    &--onTheWay {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #4b3a95;
      background: #e5dffc;
      border-radius: 4px;
      padding: 4px 8px;
      
    }

    &--completed {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #0e623f;
      background: #cbf4c9;
      border-radius: 4px;
      padding: 4px 8px;
      
    }

    &--rejected {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: ${Colors.textPrimary1};
      background: #e7e8e9;
      border-radius: 4px;
      padding: 4px 8px;
      
    }

    &--cancelled {
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #aa2720;
      background: #fae5e4;
      border-radius: 4px;
      padding: 4px 8px;
      
    }
  }

  .eta {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: ${Colors.textPrimary1};

    &--red {
      color: #c7322d;
    }
  }

  .order-type {
    border: 1px solid #dcdddd;
    width: 79px;
    height: 24px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
  }

  .items {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${Colors.textPrimary1};

    &--more {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-decoration-line: underline;
      color: #707070;
    }
  }

  .net {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: ${Colors.textPrimary1};
  }

  .code {
    font-family: "Menlo" !important;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: ${Colors.textPrimary1};
  }

  .type-size {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${Colors.textPrimary1};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 4px;

    .icon--lead {
      margin-right: 4px;
    }

    .icon {
      width: 16px;
      height: 16px;

      svg {
        width: 16px;
    height: 16px;
      }
    }
  }


.new-orders-count {
    background: red;
    z-index: 2;
    font-weight: bold;
    font-size: 11px;
    line-height: 14px;
    color: #FFFFFF;
    width: 15px;
    height: 15px;
    background: #F74646;
    border-radius: 50%;
    text-align: center;
padding-top: 0.5px;
}

`;
