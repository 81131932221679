import React, { useState } from "react";
import { connect } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";

import { logoutUser } from "actions/user";
import { MenusTabs } from "../../../containers/menus/Preview";
import { StyledCurvedHeader } from "./styles";

const Topbar = ({ locale, ...props }) => {
  const [redirect, setRedirect] = useState(false);
  const { current } = props;
  const location = useLocation();

  if (redirect) return <Redirect to="/" />;

  return (
    <StyledCurvedHeader
      rtl={props.rtl}
      background={current && current.background}
      style={
        !(
          current?.to?.includes("/console/create") ||
          location.pathname.includes("/console/create")
        )
          ? null
          : {
              height: "8rem",
              backgroundImage: "linear-gradient(white, transparent)",
              width: "calc(100vw - 14rem)",
            }
      }
    >
      <div className={`right container`}>
        {!(
          current?.to?.includes("/console/create") ||
          location.pathname.includes("/console/create")
        ) ? null : (
          <MenusTabs />
        )}
      </div>
    </StyledCurvedHeader>
  );
};

export default connect(
  (state) => ({
    rtl: state.locale[state.locale.active].rtl,
    user: state.user.info,
  }),
  { logoutUser }
)(Topbar);
