import styled from "styled-components";

export const StyledLoadingBox = styled.div`
  width: 708px;
  background-color: #ffffff;
  padding: 32px 72px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  padding-top: 0;
  height: 132px;
  bottom: 18px;
  position: fixed;

  h1 {
    display: none;
  }

  @media (max-width: 1120px) {
    padding: 25px;
    text-align: center;
    box-shadow: none;
    position: absolute;
    width: 100%;
    bottom: 0;
    height: fit-content;

    .spinner {
      display: ${(p) => (p.withSpinner ? "block" : "none")};
    }

    h1 {
      display: block;
      font-size: 22px;
      color: #151515;
      font-weight: bold;
    }
  }

  div {
    &:first-child {
      margin-bottom: 7px;
    }
  }

  p {
    margin: 0;
  }
`;

export const StyledBack = styled.div`
  background: white;
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 100px;
  display: flex;
  justify-content: center;

  @media (max-width: 1120px) {
    position: fixed;
    top: 0;
    bottom: 192px;
    height: calc(100vh - 174px) !important;
  }
`;
