import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faUser,
  faHamburger,
  faPaperPlane,
  faChartBar,
  faPaperclip,
  faHandPaper,
  faCopy,
  faPen,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Colors } from "assets/colors";

const CustomCardHeader = styled(Card.Header)`
  font-size: 1rem;
  color: #fff;
  flex: 1;
  background-color: ${Colors.primary1} !important;
  height: 100px;
  clip-path: ellipse(80% 90% at 50% 0%);

  h3 {
    color: #fff !important;
    font-weight: bold;
    font-size: 1.4rem;
  }
  span {
    font-size: 1.4rem;
  }
`;
const StyledCard = styled(Card)`
  box-shadow: 0px 0px 30px -6px rgba(0, 0, 0, 0.2);
  display: flex;
  border-width: 0;
  justify-content: center;
  text-align: center;
  margin-bottom: 50px;
  border-radius: 0.5rem;
  margin-top: 15px;
  flex: 1;
`;
const StyledCol = styled(Col)`
  cursor: pointer;
  .card:hover {
    box-shadow: 0px 0px 30px -6px rgba(0, 0, 0, 0.4);
  }
`;
const Title = styled.h3`
  font-size: 1.5rem;
  color: #222;
  font-weight: bold;
  text-align: center;
`;

const Subtitle = styled.h3`
  font-size: 1rem;
  color: #999;
  margin-bottom: 15px;
  text-align: center;
`;

const PageDescription = ({ locale, ...props }) => {
  const handleClick = (path) => {
    props.history.push(path);
  };
  const pages = [
    {
      title: locale.page_description_page_title1,
      icon: faUser,
      description: locale.page_description_page_description1,
      path: "/console/profile-info",
    },
    {
      title: locale.page_description_page_title2,
      icon: faPen,
      description: locale.page_description_page_description2,
      path: "/console/create-menus",
    },
    // {
    // title: locale.page_description_page_title3,
    // icon: faCreditCard,
    // description: locale.page_description_page_description3,
    // path: '/console/billing'
    // },
    {
      title: locale.page_description_page_title4,
      icon: faChartBar,
      description: locale.page_description_page_description4,
      path: "/console/analytics",
    },
  ];

  return null;
  // (
  //   <>
  //     <Row>
  //       <Col md={12}>
  //         <Title> {locale.page_description_title} </Title>
  //         <Subtitle> {locale.page_description_subtitle} </Subtitle>
  //       </Col>
  //     </Row>
  //     <Row className="justify-content-around" {...props}>
  //       {pages.map((page, index) => (
  //         <StyledCol
  //           key={index}
  //           md={5}
  //           xs={12}
  //           className="d-flex"
  //           onClick={() => handleClick(page.path)}
  //         >
  //           <StyledCard className="d-block">
  //             <CustomCardHeader>
  //               <h3>{page.title}</h3>
  //               <span>
  //                 <FontAwesomeIcon icon={page.icon} />
  //               </span>
  //             </CustomCardHeader>
  //             <Card.Body>
  //               <Card.Subtitle className="mb-2 text-justify">
  //                 {page.description}
  //               </Card.Subtitle>
  //             </Card.Body>
  //           </StyledCard>
  //         </StyledCol>
  //       ))}
  //     </Row>
  //   </>
  // );
};

export default connect((state) => ({
  locale: state.locale[state.locale.active],
}))(withRouter(PageDescription));
