import { faDrawPolygon, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment, { defineLocale } from "moment";
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { Button, Col } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import ReactJoyride, { ACTIONS, EVENTS } from "react-joyride";
import ReactMapGL, {
  GeolocateControl,
  Layer,
  LinearInterpolator,
  Marker,
  Source,
  WebMercatorViewport,
} from "react-map-gl";
import { RENDER_STATE } from "react-map-gl-draw";
import { DrawPolygonMode, EditingMode, Editor } from "react-map-gl-draw";
import { QRCode } from "react-qrcode-logo";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AsyncSelect from "react-select/async";
import styled from "styled-components";

import { makeAlert } from "actions";
import { ChangeTutorial } from "actions/user";
import { changePassword, updateUser } from "api/auth";
import { editImages } from "api/images";
import { addOpeningHour, updateOpeningHourById } from "api/openingHours";
import {
  getRestaurantById,
  getTags,
  getTaxes,
  updateRestaurant,
} from "api/restaurant";
import Logo from "assets/image/qr-logo.png";
import NoPermissions from "components/commons/NoPermissions";
import Loading from "components/Loading";
import { IMAGE_VALID_FORMATS, WEEKDAYS } from "constants";
import { formatTime, getEndTime, subtractArrays } from "utils";

import { Colors } from "assets/colors";
import { getData } from "../../utils";
import Card from "./Card";
// import CustomText from './Text'
import RestaurantImage from "./RestaurantImage";
import Text from "./Text";

export function getEditHandleStyle({ feature, state }) {
  switch (state) {
    case RENDER_STATE.SELECTED:
    case RENDER_STATE.HOVERED:
    case RENDER_STATE.UNCOMMITTED:
      return {
        fill: "rgb(251, 176, 59)",
        fillOpacity: 1,
        stroke: "rgb(255, 255, 255)",
        strokeWidth: 2,
        r: 7,
      };

    default:
      return {
        fill: "rgb(251, 176, 59)",
        fillOpacity: 1,
        stroke: "rgb(255, 255, 255)",
        strokeWidth: 2,
        r: 5,
      };
  }
}

export function getFeatureStyle({ feature, index, state }) {
  switch (state) {
    case RENDER_STATE.SELECTED:
    case RENDER_STATE.HOVERED:
    case RENDER_STATE.UNCOMMITTED:
    case RENDER_STATE.CLOSING:
      return {
        stroke: "rgb(251, 176, 59)",
        strokeWidth: 2,
        fill: "rgb(251, 176, 59)",
        fillOpacity: 0.3,
        strokeDasharray: "4,2",
      };

    default:
      return {
        stroke: "rgb(60, 178, 208)",
        strokeWidth: 2,
        fill: "rgb(60, 178, 208)",
        fillOpacity: 0.1,
      };
  }
}

const dataLayer = {
  id: "data",
  type: "fill",
  paint: {
    "fill-color": {
      stops: [[0, "orange"]],
    },
    "fill-opacity": 0.2,
  },
};

const dataStrokeLayer = {
  id: "lines",
  type: "line",
  paint: {
    "line-width": 2,
    "line-color": "darkorange",
    // 'line-dasharray': [1, 1],
  },
};

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

const SIZE = 20;

const Image = styled.img`
  max-height: 100px;
  ${(p) => p.pale && "filter: brightness(30%);"}
`;

const QRWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .qrcode {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.31);
    border-radius: 30px;
    padding: 10px 15px;
    font-weight: bold;
    width: 200px;
    max-width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    cursor: pointer;
  }

  canvas {
    max-width: 150px;
    max-height: 150px;
  }
`;
const OpeningsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  strong {
    text-align: center;
  }
`;

const StyledMap = styled(Map)`
  width: 400px;
  height: 500px;
`;

const withSetState =
  (PassedField, setState) =>
  ({ ...props }) =>
    <PassedField {...props} setState={setState} />;

const customStylesAsyncSelect = {
  option: (provided, state) => ({
    ...provided,
  }),
  control: () => ({
    borderRadius: "5px",
    border: "1px solid #ccc",
    display: "flex",
    flexDirection: "row",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const Profile = ({ locale, ...props }) => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { errors: [] }
  );
  const [loading, setLoading] = useState(true);
  const [tags, setTags] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [viewport, setViewport] = useState({
    width: 400,
    height: 400,
    latitude: -37.7577,
    longitude: 22.4376,
    zoom: 12,
  });
  const [updating, setUpdating] = useState(false);
  const [restaurant, setRestaurant] = useState({});
  const [user, setUser] = useState({});
  const [editing, setEditing] = useState(null);
  const [updatedOHs, setUpdatedOHs] = useState([]);
  const [joyrideStepIndex, setJoyrideStepIndex] = useState(0);
  const [joyrideRun, setJoyrideRun] = useState(false);
  // const Text = withSetState(CustomText, setState)
  const [mode, setMode] = useState(null);
  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(null);
  const editorRef = useRef(null);

  const onSelect = useCallback((options) => {
    setSelectedFeatureIndex(options && options.selectedFeatureIndex);
  }, []);

  const onDelete = useCallback(() => {
    if (selectedFeatureIndex !== null && selectedFeatureIndex >= 0) {
      editorRef.current.deleteFeatures(selectedFeatureIndex);
    }
  }, [selectedFeatureIndex]);

  const onUpdate = useCallback(({ editType }) => {
    if (editType === "addFeature") {
      setMode(new EditingMode());
    }
  }, []);

  const handleViewportChange = (e) => {
    setViewport(e);
  };

  const drawTools = (
    <div className="mapboxgl-ctrl-top-left">
      <div className="mapboxgl-ctrl-group mapboxgl-ctrl">
        <button
          className="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon d-flex align-items-center justify-content-center"
          title="Polygon tool (p)"
          onClick={() => setMode(new DrawPolygonMode())}
        >
          <FontAwesomeIcon icon={faDrawPolygon} className="mx-3" />
        </button>
        <button
          className="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_trash d-flex align-items-center justify-content-center"
          title="Delete"
          onClick={onDelete}
        >
          <FontAwesomeIcon icon={faTrash} className="mx-3" />
        </button>
      </div>
    </div>
  );

  const features = editorRef.current && editorRef.current.getFeatures();
  const selectedFeature =
    features &&
    (features[selectedFeatureIndex] || features[features.length - 1]);

  let fileUpload = React.createRef();
  const steps = [
    // {
    //   targrt: '#app',
    //   contnt: 'profile'
    // },
    {
      target: ".fulls",
      content: (
        <>
          <h3 class="text-center font-weight-bold">{locale.tut_profile}</h3>
          <br />
          <p class="text-center">{locale.tut_profile_text}</p>
        </>
      ),
      disableBeacon: true,
      placement: "center",
      // position: 'top'
    },
    {
      target: ".edit-1",
      content: (
        <>
          <h3 class=" font-weight-bold">{locale.tut_profile_info} </h3>
          <br />
          <p>{locale.tut_profile_info_text}</p>
        </>
      ),
      spotlightClicks: true,
    },
    {
      target: ".edit-2",
      content: (
        <div>
          <h3 class=" font-weight-bold">{locale.tut_profile_info}</h3>
          <br />
          <p>{locale.tut_profile_info_text}</p>
        </div>
      ),
      spotlightClicks: true,
    },
    {
      target: ".edit-3",
      content: (
        <div>
          <h3 class=" font-weight-bold"> {locale.tut_profile_place_loc} </h3>
          <br />
          <p class="">{locale.tut_profile_place_loc_text}</p>
        </div>
      ),
      spotlightClicks: true,
    },
    {
      target: ".edit-4",
      content: (
        <div>
          <h3 class=" font-weight-bold">{locale.tut_profile_place_img}</h3>
          <br />
          <p class="">{locale.tut_profile_place_img_text}</p>
        </div>
      ),
      spotlightClicks: true,
    },
    {
      target: ".edit-5",
      content: (
        <div>
          <h3 class=" font-weight-bold">{locale.tut_profile_place_hrs}</h3>
          <br />
          <p>{locale.tut_profile_place_hrs}</p>
        </div>
      ),
      spotlightClicks: true,
    },
    {
      target: ".edit-6",
      content: (
        <div>
          <h3 class=" font-weight-bold">{locale.tut_profile_place_qr}</h3>
          <br />
          <p>{locale.tut_profile_place_qr_text}</p>
        </div>
      ),
      spotlightClicks: true,
    },
  ];

  useEffect(() => {
    if (props.selectedRestaurant) {
      props.getRestaurantById(props.selectedRestaurant).then((res) => {
        // if (getData(res)?.opening_hours.length < 7) {
        //   let missings = subtractArrays(
        //     [1, 2, 3, 4, 5, 6, 7],
        //     getData(res)?.opening_hours.map((o) => o.weekday)
        //   );

        //   missings.forEach((weekday) => {
        //     props
        //       .addOpeningHour({
        //         restaurant: getData(res)?.id,
        //         weekday,
        //         from_hour: "00:00:00",
        //         duration: "00:00:00",
        //       })
        //       .then((res) => {
        //         props
        //           .getRestaurantById(props.selectedRestaurant)
        //           .then((res) => {
        //             setRestaurant(getData(res));
        //           });
        //       });
        //   });
        // }
        if (!getData(res)?.location) {
          getData(res).location = {
            lat: 0,
            lng: 0,
          };
        }
        setRestaurant(getData(res));

        setLoading(false);
        if (
          (props.location &&
            props.location.state &&
            props.location.state.tutorial) ||
          props.tutorial === "profile"
        ) {
          setJoyrideRun(true);
        }
      });
    }
    props.getTags().then((res) => {
      setTags(getData(res)?.results);
    });
    props.getTaxes().then((res) => {
      setTaxes(getData(res)?.results);
    });
  }, []);

  const downloadQR = (id) => {
    const canvas = document.getElementById("react-qrcode-logo");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download =
      restaurant.name.replace(/\s+/g, "-").toLowerCase() + "-qrcode" + ".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const submit = (e, category) => {
    setUpdating(true);
    let data = {
      name: restaurant.name,
      description: restaurant.description,
      phone: restaurant.phone,
      tags_ids: restaurant.tags.map((t) => t.id),
      tax_ids: restaurant.tax.map((t) => t.id),
    };
    if (restaurant.website) data = { ...data, website: restaurant.website };
    if (restaurant.liquor_license)
      data = { ...data, liquor_license: restaurant.liquor_license };
    if (restaurant.coverFile) data = { ...data, cover: restaurant.coverFile };
    if (restaurant.logoFile) data = { ...data, logo: restaurant.logoFile };
    if (category === "location") {
      data = {
        address: restaurant.address,
      };
    }
    props
      .updateRestaurant(
        props.selectedRestaurant,
        data,
        restaurant.logoFile || restaurant.coverFile
      )
      .then((res) => {
        if (getData(res)?.id) {
          if (category === "location") {
            setRestaurant((restaurant) => ({
              ...restaurant,
              location: getData(res)?.location,
            }));
          }
          props.makeAlert("Updated Successfully!");
          setEditing(null);
          setUpdating(false);
        }
      })
      .catch((e) => {
        console.log("error in updating Restaurant data", e);
        setUpdating(false);
      });
  };

  const handleChange = (e, type) => {
    if (e.target) {
      const { value, name, files } = e.target;
      const reader = new FileReader();
      if (files) {
        const url = reader.readAsDataURL(files[0]);

        reader.onloadend = function (e) {
          setRestaurant({
            ...restaurant,
            [name + "File"]: files[0],
            [name]: reader.result,
          });
        };
      } else {
        setRestaurant({ ...restaurant, [name]: value });
      }
    } else if (type === "phone") {
      setRestaurant({ ...restaurant, phone: e });
    } else {
      setRestaurant({ ...restaurant, ...e });
    }
  };

  const handlePasswordChange = (e) => {
    const { value, name, files } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handlePasswordSubmit = (e) => {
    // e.preventDefault()
    setUpdating(true);

    props
      .changePassword(user)
      .then((res) => {
        setEditing(null);
        setUpdating(false);
        props.makeAlert("Updated Successfully!");
      })
      .catch((e) => {
        console.log("error in changing password", e);
        setUpdating(false);
      });
  };

  const handleTimeChange = (time, id, which) => {
    // const {value} = event.target
    const formattedTime = time.target.value + ":" + "00";
    let selected = restaurant.opening_hours.find((o) => o.id === id);

    if (which === "from") {
      const endTime = getEndTime(selected.from_hour, selected.duration);
      const duration = moment
        .utc(
          moment(endTime, "HH:mm:ss").diff(moment(formattedTime, "HH:mm:ss"))
        )
        .format("HH:mm:ss");
      selected.from_hour = formattedTime;
      selected.duration = duration;
    } else {
      const duration = moment
        .utc(
          moment(formattedTime, "HH:mm:ss").diff(
            moment(selected.from_hour, "HH:mm:ss")
          )
        )
        .format("HH:mm:ss");
      selected.duration = duration;
    }

    const opening_hours = [
      ...restaurant.opening_hours.filter((o) => o.id !== id),
      selected,
    ];
    setRestaurant({ ...restaurant, opening_hours });
    setUpdatedOHs([...updatedOHs.filter((u) => u !== id), id]);
  };

  const handleLocationChange = (e) => {
    if (e.target.value) {
      const { value, name } = e.target;
      setRestaurant({
        ...restaurant,
        location: { ...restaurant.location, [name]: parseFloat(value) },
      });
    } else {
      setRestaurant({
        ...restaurant,
        location: { lat: e.lngLat[1], lng: e.lngLat[0] },
      });
    }
  };

  const validateFields = () => {};

  const handleCallback = (data) => {
    const { action, index, type } = data;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setJoyrideStepIndex(stepIndex);
    }
    if ([EVENTS.TOUR_END, EVENTS.TOOLTIP_CLOSE].includes(type)) {
      endTour();
    }
  };

  const endTour = () => {
    setJoyrideRun(false);
    setJoyrideStepIndex(0);
    if (props.tutorial === 1) {
      props.ChangeTutorial("items", 2);
    }
  };

  if (
    !props.permissions.includes("__panel__") &&
    !props.permissions.includes("profile")
  )
    return <NoPermissions />;

  if (!props.selectedRestaurant) {
    return (
      <Row className="fulls">
        <Col xs={12} sm={12} className="text-center">
          <h2 className="h3 text-white">
            No restaurant yet! Please create one first!
          </h2>
        </Col>
      </Row>
    );
  }

  const handleTagChange = (event, action) => {
    if (action.action === "clear") {
      setRestaurant({ ...restaurant, tags: [] });
    } else if (action.action === "remove-value") {
      let newTags = Array.from(restaurant.tags);
      newTags = newTags.filter((tag) => tag.id !== action.removedValue.value);
      setRestaurant({ ...restaurant, tags: newTags });
    } else if (action.action === "pop-value") {
    } else if (action.action !== "create-option") {
      setRestaurant({
        ...restaurant,
        tags: [
          ...restaurant.tags,
          { title: action.option.label, id: action.option.value },
        ],
      });
    }
  };

  const handleTaxChange = (event, action) => {
    if (action.action === "clear") {
      setRestaurant({ ...restaurant, tax: [] });
    } else if (action.action === "remove-value") {
      let newTags = Array.from(restaurant.tax);
      newTags = newTags.filter((tag) => tag.id !== action.removedValue.value);
      setRestaurant({ ...restaurant, tax: newTags });
    } else if (action.action === "pop-value") {
    } else if (action.action !== "create-option") {
      setRestaurant({
        ...restaurant,
        tax: [
          ...restaurant.tax,
          { title: action.option.label, id: action.option.value },
        ],
      });
    }
  };

  let tagName = "";
  const handleTaxInputChange = async (value, action) => {
    if (tagName !== value) {
      tagName = value;

      const res = await props.getTaxes(value);
      return getData(res)?.results.map((tag) => ({
        label: tag.title,
        value: tag.id,
      }));
    }
  };

  const handleTagInputChange = async (value, action) => {
    if (tagName !== value) {
      tagName = value;

      const res = await props.getTags(value);
      return getData(res)?.results.map((tag) => ({
        label: tag.title,
        value: tag.id,
      }));
    }
  };

  return (
    <>
      <Row className="fulls">
        <ReactJoyride
          showProgress
          showSkipButton
          continuous
          scrollToFirstStep
          stepIndex={joyrideStepIndex}
          steps={steps}
          run={joyrideRun}
          callback={handleCallback}
        />
        <Col xs={12} sm={8}>
          <Card
            title={locale.place_profile_title}
            loading={loading}
            setEditing={setEditing}
            updating={updating}
            editing={editing}
            onSubmit={submit}
            editClassName="edit-1"
          >
            <Text
              title={locale.place_profile_title_name}
              subtitle={restaurant.name}
              value={restaurant.name}
              group={locale.place_profile_title}
              editing={editing}
              handleChange={handleChange}
              updating={updating}
              maxLength={80}
            />
            <Text
              title={locale.place_profile_title_description}
              empty="Please enter a description about your restaurant."
              subtitle={restaurant.description}
              value={restaurant.description}
              group={locale.place_profile_title}
              type="textarea"
              editing={editing}
              handleChange={handleChange}
              updating={updating}
              maxLength={1000}
            />
            <Text
              title={"Liquor License"}
              subtitle={restaurant.liquor_license}
              name={"liquor_license"}
              value={restaurant.liquor_license}
              group={locale.place_profile_title}
              editing={editing}
              handleChange={handleChange}
              updating={updating}
              maxLength={80}
            />
            <Text
              title={"Tags"}
              group={locale.place_profile_title}
              subtitle={restaurant.tags}
              value={restaurant?.tags?.length > 0}
              editing={editing}
              type="select"
              handleChange={handleChange}
              editingComp={
                <AsyncSelect
                  value={restaurant.tags?.map((tag) => ({
                    label: tag.title,
                    value: tag.id,
                  }))}
                  isMulti
                  name="tags"
                  cacheOptions
                  defaultOptions={tags?.map((tag) => ({
                    label: tag.title,
                    value: tag.id,
                  }))}
                  loadOptions={handleTagInputChange}
                  // onInputChange={handleDietInputChange}
                  onChange={handleTagChange}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  placeholder={"Type tag..."}
                  styles={customStylesAsyncSelect}
                />
              }
              updating={updating}
            />
            <Text
              title={"Taxes"}
              group={locale.place_profile_title}
              subtitle={restaurant.tax}
              value={restaurant?.tax?.length > 0}
              editing={editing}
              type="select"
              handleChange={handleChange}
              editingComp={
                <AsyncSelect
                  value={restaurant.tax?.map((tax) => ({
                    label: tax.title,
                    value: tax.id,
                  }))}
                  isMulti
                  name="tax"
                  cacheOptions
                  defaultOptions={taxes?.map((tax) => ({
                    label: tax.title,
                    value: tax.id,
                  }))}
                  loadOptions={handleTaxInputChange}
                  onChange={handleTaxChange}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  placeholder={"Type tax..."}
                  styles={customStylesAsyncSelect}
                />
              }
              updating={updating}
            />
          </Card>
          {/* <RestaurantImage
            loading={loading}
            setEditing={setEditing}
            updating={updating}
            editing={editing}
            onSubmit={submit}
            images={restaurant.images}
            setRestaurant={setRestaurant}
            restaurant={restaurant}
          /> */}
          {
            <Card
              editClassName="edit-3"
              title={locale.places_location_title}
              loading={loading}
              setEditing={setEditing}
              updating={updating}
              editing={editing}
              onSubmit={(e) => submit(e, "location")}
            >
              <Text
                title={locale.info_card_address}
                subtitle={restaurant.address}
                value={restaurant.address}
                group={locale.places_location_title}
                editing={editing}
                handleChange={handleChange}
                updating={updating}
                maxLength={200}
              />
              <ReactMapGL
                {...viewport}
                mapboxApiAccessToken="pk.eyJ1IjoidGhldmFoaWRhbCIsImEiOiJjazl6eXdwY2QwejdlM3FxZGplYm1qNmFiIn0.kDckQSV9Prg3lHlcwADA4w"
                width="100%"
                height="400px"
                latitude={restaurant.location?.lat}
                longitude={restaurant.location?.lng}
                zoom={12}
                mapStyle="mapbox://styles/mapbox/light-v9"
                onViewportChange={handleViewportChange}
              >
                <>
                  <Source
                    type="geojson"
                    data={{
                      // ...data,
                      features,
                    }}
                  >
                    <Layer {...dataLayer} />
                  </Source>
                  {/* <Source type="geojson" data={{
                  // ...data,
                  features: [{type: restaurant.delivery_area?.type, geometry: restaurant.delivery_area}]
                }}>
                  <Layer
                    {...dataStrokeLayer}
                  />
                </Source> */}
                </>
                {editing === locale.places_location_title.toLowerCase() && (
                  <Editor
                    ref={editorRef}
                    style={{ width: "100%", height: "100%" }}
                    clickRadius={12}
                    mode={mode}
                    onSelect={onSelect}
                    onUpdate={onUpdate}
                    editHandleShape={"circle"}
                    featureStyle={getFeatureStyle}
                    editHandleStyle={getEditHandleStyle}
                  />
                )}
                {editing === locale.places_location_title.toLowerCase() &&
                  drawTools}
                <Marker
                  longitude={restaurant.location?.lng}
                  latitude={restaurant.location?.lat}
                  offsetTop={-20}
                  offsetLeft={-10}
                  draggable={editing}
                  onDragEnd={handleLocationChange}
                >
                  <svg
                    height={SIZE}
                    viewBox="0 0 24 24"
                    style={{
                      cursor: "pointer",
                      fill: "#dd0000",
                      stroke: "none",
                      transform: `translate(${-SIZE / 2}px,${-SIZE}px)`,
                    }}
                  >
                    <path d={ICON} />
                  </svg>
                </Marker>
                {/* <ControlPanel polygon={selectedFeature} /> */}
              </ReactMapGL>
            </Card>
          }
          <Card
            title={locale.account_profile_title}
            loading={loading}
            setEditing={setEditing}
            updating={updating}
            editing={editing}
            onSubmit={handlePasswordSubmit}
            editClassName="edit-1"
          >
            <Text
              title={locale.account_password_name}
              subtitle={user.password}
              value={true}
              placeholder={"Enter Your Old Password"}
              type="password"
              name="old_password"
              group={locale.account_profile_title}
              editing={editing}
              handleChange={handlePasswordChange}
              updating={updating}
            />
            <Text
              title={locale.account_new_password_name}
              subtitle={user.password}
              value={true}
              placeholder={"Enter Your New Password"}
              type="password"
              name="new_password"
              group={locale.account_profile_title}
              editing={editing}
              handleChange={handlePasswordChange}
              updating={updating}
            />
          </Card>
        </Col>
        <Col xs={12} sm={4}>
          <Card
            title={"Images"}
            loading={loading}
            setEditing={setEditing}
            updating={updating}
            editing={editing}
            onSubmit={submit}
            editClassName="edit-1"
          >
            <Text
              title={locale.place_profile_title_logo}
              group={"Images"}
              empty="Please add your restaurants logo."
              editing={editing}
              type="file"
              handleChange={handleChange}
              comp={<Image src={restaurant.logo} />}
              value={restaurant.logo}
              updating={updating}
            />
            <Text
              title={"Cover"}
              group={"Images"}
              empty="Please add your restaurants cover."
              editing={editing}
              type="file"
              handleChange={handleChange}
              comp={<Image src={restaurant.cover} />}
              value={restaurant.cover}
              updating={updating}
            />
          </Card>
          <Card
            title={locale.info_card_title}
            loading={loading}
            setEditing={setEditing}
            updating={updating}
            editing={editing}
            onSubmit={submit}
            editClassName="edit-2"
          >
            <Text
              title={locale.info_card_phone}
              subtitle={restaurant.phone}
              value={restaurant.phone}
              type="phone_number"
              group={locale.info_card_title}
              editing={editing}
              handleChange={handleChange}
              updating={updating}
            />
            <Text
              title={locale.info_card_website}
              regex={
                /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
              }
              subtitle={restaurant.website}
              value={restaurant.website}
              group={locale.info_card_title}
              editing={editing}
              handleChange={handleChange}
              updating={updating}
            />
          </Card>
          {/* <Card editClassName="edit-5" title={locale.opening_hours_title} loading={loading} setEditing={setEditing}
            updating={updating} editing={editing} onSubmit={submit}
            subtitle={locale.info_card_subtitle}
          >
            <OpeningsWrapper>
              {
                restaurant.opening_hours && restaurant.opening_hours.sort((a, b) => b.weekday - a.weekday).map((o, index) =>
                  <Text
                    key={o.id}
                    id={o.id}
                    title={WEEKDAYS[o.weekday - 1]}
                    group={locale.opening_hours_title}
                    editing={editing}
                    handleChange={handleChange}
                    updating={updating}
                    type='time'
                    handleTimeChange={handleTimeChange}
                    from_hour={o.from_hour}
                    duration={o.duration}
                    subtitle={<>
                      <span className='hour'>{formatTime(o.from_hour)}</span>
                      <p className='to'>to</p>
                      <span className='hour'>{formatTime(o.from_hour, o.duration)}</span>
                    </>}
                  />)
              }
            </OpeningsWrapper>
          </Card> */}
        </Col>
      </Row>
    </>
  );
};

export default withRouter(
  connect(
    (state) => ({
      selectedRestaurant: state.user.selectedRestaurant,
      tutorial: state.user.tutorialSteps,
      permissions: state.user.permissions,
      locale: state.locale[state.locale.active],
    }),
    {
      getRestaurantById,
      updateRestaurant,
      makeAlert,
      updateOpeningHourById,
      addOpeningHour,
      editImages,
      updateUser,
      changePassword,
      getTags,
      getTaxes,
      ChangeTutorial,
    }
  )(Profile)
);
