/*
 * Order statuses:
 */
export const NEW = 0;
export const IN_PROGRESS = 1;
export const CANCELLED_BY_RESTAURANT = 2;
export const READY = 4;
export const ON_THE_WAY = 5;
export const COMPLETED = 6;
export const IN_PROGRESS_UPDATED = 7;
export const READY_UPDATED = 8;
export const STRANDED = 9;
export const REJECTED_BY_RESTAURANT = 10;
export const DELIVERED = 12;
