import React, { useState } from "react";
import { StyledSwitch } from "./styles";
import SkeletonLoading from "components/common/SkeletonLoading";
import PropTypes from "prop-types";

const Switch = ({
  checked,
  onChange,
  name,
  label,
  classes,
  loading,
  ...props
}) => {
  return (
    <StyledSwitch className={classes}>
      <label htmlFor={name}>
        {!loading ? (
          <>
            <input
              id={name}
              type="checkbox"
              checked={checked}
              onChange={onChange}
            />
            <span className="checkmark"></span>
          </>
        ) : (
          <span className="checkmark--loading">
            <SkeletonLoading
              borderRadius={4}
              width={36}
              height={12}
              count={1}
            />
          </span>
        )}
        <span>{label}</span>
      </label>
    </StyledSwitch>
  );
};

Switch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.any,
  classes: PropTypes.string,
  loading: PropTypes.bool,
};

export default Switch;
