import styled from "styled-components";
import { Colors } from "assets/colors";

export const StyledWrapper = styled.div`
  width: 540px;

  .percentage-input {
    position: relative;
  }

  .input-label {
    position: absolute;
    left: 309px;
    top: 7px;
    font-family: "Manrope";
  }

  .search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-family: "Manrope";
    z-index: 2;
    color: ${Colors.textPrimary1};
  }

  .search-input {
    padding: 8px 34px !important;
    width: 380px !important;
    height: 32px !important;
    margin-right: 8px;
  }

  .input-row {
    display: flex;
    flex-direction: row;
  }

  .title {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #1a1f36;

    &-box {
      height: 60px;
      padding: 18px 20px;
    }
  }

  .row {
    &-box {
      padding: 11px 20px;
      background: #f5f7f8;
      border-top: 1px solid #dde1e4;
      position: relative;
      min-height: 58px;

      &:first-child {
        border: none;
        align-items: center;
        display: flex;
      }
    }
  }

  .search-input {
    width: 500px !important;
    height: 42px !important;
    padding: 11px 12px 11px 38px !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin: 0;
  }

  .search-icon {
    color: #666b6d;
  }

  .item-checkbox {
    width: 100%;

    .checkmark-icon {
      left: 22px;
      top: 50%;
      transform: translateY(-50%);
    }

    label {
      padding-left: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;

    &-left {
      display: flex;
      flex-direction: column;
    }

    &-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #2b3235;
      margin-top: 2px;
      margin-bottom: 2px;
    }

    &-price {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #666b6d;
      margin-bottom: 0;
    }

    &-label {
      width: 100%;
    }

    img {
      width: 42px;
      height: 42px;
      object-fit: contain;
      border: 1px solid #dde1e4;
      border-radius: 5px;
      overflow: hidden;
      background: #ffffff;
    }
  }

  .btns {
    background: #ffffff;
    padding: 16px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 0 0 5px 5px;

    &-right {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }

  .btn {
    height: 28px;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;

    &:hover {
      color: ${Colors.textPrimary1};
    }

    &-cancel {
      margin-right: 12px;
    }

    &-add {
      &:hover {
        color: #ffffff;
      }
    }
  }

  .products {
    max-height: 420px;
    overflow: auto;
    border-top: 1px solid #dde1e4;
    border-bottom: 1px solid #dde1e4;
  }

  .no-results {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }

  .strong {
    color: ${Colors.textPrimary1};
  }
`;
