import styled from "styled-components";

export const StyledModalWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 100vh;
  background-color: #000000de;
  z-index: 1000;

  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .modal-general {
    background-color: white;
    min-width: 100px;
    min-height: 100px;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: fade 0.5s;
    maxHeight: 95vh;
    overflow-y: auto;
  }

  .close {
    position: absolute;
    top: 16px;
    right: 24px;
    font-size: 24px;
    cursor: pointer;
  }

  .modal-close-btn {
    top: 16px;
    right: 20px;
    z-index: 2;
  }
`;
