import { fetchApi } from './'
import { async } from 'q';

export const getCategoriesByRestaurantId = (id) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`restaurants/${id}/categories/?query={id,name,description}`, 'GET'))

  return res
}

export const createCategory = (payload) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`categories/`, 'POST', payload, false))

  return res
}

export const editCategory = (id, payload) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`categories/${id}/`, 'PATCH', payload, true))

  return res
}

export const getCategory = (id) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`categories/${id}/?query={*,items{id}}`))

  return res
}

export const removeCategory = (id) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`categories/${id}/`, 'DELETE'))

  return res
}

export const changeCategoryFeatured = (id, item) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`categories/${id}/featured/`, 'PATCH', {item}))

  return res
}

export const changeCategoryItems = (id, items) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`categories/${id}/items/`, 'PUT', {items}))

  return res
}

export const reorderCategory = (id, position) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`categories/${id}/move/`, 'POST', { position }, true))

  return res
}
