import { Card, Col, Form, Row } from "react-bootstrap";
import React, { useState } from "react";

import Button from "components/button";
import { Link } from "react-router-dom";
import Loading from "components/Loading";
import { connect } from "react-redux";
import { loginUser } from "actions/user";
import { makeAlert } from "actions";
import { register } from "api/auth";

const PersonalDetails = ({ locale, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password1: "",
    password2: "",
    ...props.registerForm,
  });
  const [error, setError] = useState({
    firstanmeError: false,
    lastnameError: false,
    emailError: false,
    phonenumberError: false,
    passwordError: false,
    password2Error: false,
  });

  const handleChange = (e) => {
    setError({
      firstanmeError: false,
      lastnameError: false,
      emailError: false,
      phonenumberError: false,
      passwordError: false,
      password2Error: false,
    });
    const { value, name } = e.target;
    setForm({ ...form, [name]: value });
  };

  const submit = (e) => {
    e.preventDefault();
    // const data = {
    //     province: form.Province,
    //     city: form.City,
    //     address: form.StreetAddress,
    //     postal_code: form.PostalCode,
    //     business_number: form.BusinessNumber,
    //     business_name: form.BusinessName,
    //     website: form.Website,
    // }
    // props.setStep(2)
    if (validate()) {
      props.setStep(2);
      props.setRegisterForm(form);
      // setLoading(true)
      // props.register({...form, ...data}).then(res => {
      //     props.setStep(3)
      //     setLoading(false)
      // }).catch(e => {
      //     // props.makeAlert(e, 'error')
      //     console.log(e);
      //     setLoading(false)
      // })
    }
  };

  const validate = () => {
    const { first_name, last_name, email, phone, password1, password2 } = form;
    let {
      firstnameError,
      lastnameError,
      emailError,
      phonenumberError,
      passwordError,
      password2Error,
    } = form;
    let valid = true;
    const emailRegx =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!first_name) {
      firstnameError = true;
      valid = false;
    }
    if (!last_name) {
      lastnameError = true;
      valid = false;
    }
    if (!phone) {
      phonenumberError = true;
      valid = false;
    }
    if (!email || !emailRegx.test(email)) {
      emailError = true;
      valid = false;
    }
    if (!password1 || password1.length < 8) {
      passwordError = true;
      valid = false;
    }
    if (!password2 || password1 !== password2) {
      password2Error = true;
      valid = false;
    }
    setError({
      firstnameError,
      lastnameError,
      phonenumberError,
      emailError,
      passwordError,
      password2Error,
    });
    return valid;
  };

  return (
    <Card className="o-hidden border-0 shadow-lg my-5">
      <Card.Body>
        <Row className="">
          <Col md={12}>
            <h3 className="h4 text-dark my-2 text-center font-weight-bold">
              {locale.personaldetails_text}
            </h3>
            <p className="p-0 m-0 text-center">
              {locale.give_personaldetail_text}{" "}
            </p>
          </Col>
        </Row>
        <Row>
          <div className="col-lg-12">
            <div className="p-5">
              <Form className="user" onSubmit={submit}>
                <Form.Group className="row">
                  <Col sm={6} className="mb-3 mb-sm-0">
                    <Form.Label> {locale.firstname}* </Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control "
                      placeholder={locale.firstname}
                      name="first_name"
                      onChange={handleChange}
                      value={form.first_name}
                    />
                    {error.firstnameError && (
                      <Form.Control.Feedback
                        style={{ display: "block" }}
                        type="invalid"
                      >
                        *{locale.required_text}{" "}
                      </Form.Control.Feedback>
                    )}
                  </Col>
                  <Col sm={6}>
                    <Form.Label>{locale.lastname}* </Form.Label>

                    <Form.Control
                      name="last_name"
                      onChange={handleChange}
                      value={form.last_name}
                      type="text"
                      className="form-control "
                      placeholder={locale.lastname}
                    />
                    {error.lastnameError ? (
                      <Form.Control.Feedback
                        style={{ display: "block" }}
                        type="invalid"
                      >
                        *{locale.required_text}
                      </Form.Control.Feedback>
                    ) : null}
                  </Col>
                </Form.Group>
                <Form.Group className="row">
                  <Col md={6}>
                    <Form.Label>{locale.email_address}* </Form.Label>

                    <Form.Control
                      name="email"
                      onChange={handleChange}
                      value={form.email}
                      type="email"
                      className="form-control"
                      placeholder={locale.email_address}
                    />
                    {error.emailError && (
                      <Form.Control.Feedback
                        style={{ display: "block" }}
                        type="invalid"
                      >
                        *{locale.valid_email_text}{" "}
                      </Form.Control.Feedback>
                    )}
                  </Col>
                  <Col md={6}>
                    <Form.Label>{locale.phone_number}* </Form.Label>

                    <Form.Control
                      name="phone"
                      onChange={handleChange}
                      value={form.phone}
                      type="phone"
                      className="form-control "
                      placeholder={locale.phone_number}
                    />
                    {error.phonenumberError && (
                      <Form.Control.Feedback
                        style={{ display: "block" }}
                        type="invalid"
                      >
                        *{locale.phone_number_required}{" "}
                      </Form.Control.Feedback>
                    )}
                  </Col>
                </Form.Group>
                <Form.Group className="row">
                  <Col sm={6} className="mb-3 mb-sm-0">
                    <Form.Label>{locale.password}*</Form.Label>

                    <Form.Control
                      name="password1"
                      onChange={handleChange}
                      value={form.password1}
                      type="password"
                      className="form-control "
                      placeholder={locale.password}
                    />
                    {error.passwordError && (
                      <Form.Control.Feedback
                        style={{ display: "block" }}
                        type="invalid"
                      >
                        *{locale.strong_password_required}{" "}
                      </Form.Control.Feedback>
                    )}
                  </Col>
                  <Col sm={6}>
                    <Form.Label>{locale.repeat_password_text}*</Form.Label>

                    <Form.Control
                      name="password2"
                      onChange={handleChange}
                      value={form.password2}
                      type="password"
                      className="form-control "
                      placeholder={locale.repeat_password_text}
                    />
                    {error.password2Error && (
                      <Form.Control.Feedback
                        style={{ display: "block" }}
                        type="invalid"
                      >
                        *{locale.password_match}{" "}
                      </Form.Control.Feedback>
                    )}
                  </Col>
                </Form.Group>
                <div className="text-center">
                  <Button onClick={submit} className="px-5">
                    {!loading ? locale.next_button_text : <Loading nopadding />}
                  </Button>
                </div>
                <hr />
              </Form>
              <div className="text-center">
                <Link className="small" to="/login">
                  {locale.existing_account_text}{" "}
                </Link>
              </div>
            </div>
          </div>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default connect(
  (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    locale: state.locale[state.locale.active],
  }),
  { register, loginUser, makeAlert }
)(PersonalDetails);
