import Loading from "../Loading";
import PropTypes from "prop-types";
import React from "react";
import { StyledButton } from "./styles";
import { useHistory } from "react-router-dom";

/*
 * Button component which accepts label, onClick and custom classes
 */

const Button = ({
  onClick,
  label,
  classes,
  id,
  loading,
  type,
  disabled,
  ...props
}) => {
  const history = useHistory();
  return (
    <StyledButton
      id={id}
      className={classes}
      onClick={loading ? null : onClick}
      loading={loading}
      type={type}
      disabled={disabled}
    >
      {loading ? <Loading classes="loading" /> : label}
    </StyledButton>
  );
};

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.element.isRequired,
  classes: PropTypes.string,
  id: PropTypes.string,
  loading: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Button;
