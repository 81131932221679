import { fetchApi } from "./";
import { generateURLWithParams } from "../utils";

export const getLatestOrders =
  (merchantId, params = {}) =>
  async (dispatch, getState) => {
    let url = generateURLWithParams(`merchants/${merchantId}/orders/`, {
      ...params,
      separate_qty_from_item_names_for_migrated_orders: 1
    });
    let res = await dispatch(fetchApi(url, "GET"));

    return res;
  };

export const updateOrderState = (id, data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`orders/update/${id}/`, "POST", data));

  return res;
};
export const estimateOrder = (id) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`orders/estimate/${id}/`));

  return res;
};

export const isRideServiceAvailable = (id) => async (dispatch, getState) => {
  let res = await dispatch(
    fetchApi(
      `orders/is-service-available/${id}/`,
      "GET",
      null,
      false,
      false,
      true
    )
  );

  return res;
};

export const calculateNets = (id) => async (dispatch, getState) => {
  let res = await dispatch(
    fetchApi(`orders/calculate-nets/${id}/`, "GET", null, false, false, true)
  );

  return res;
};

export const onboardMerchant = (data) => async (dispatch, getState) => {
  let res = await dispatch(
    fetchApi(`billing/merchant/onboard_merchant/`, "POST", data)
  );

  return res;
};

export const onboardMerchantLink =
  (account, data) => async (dispatch, getState) => {
    let res = await dispatch(
      fetchApi(`billing/merchant/account_links/${account}/`, "POST", data)
    );

    return res;
  };
