import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as CalendarIcon } from "assets/image/calendar.svg";
import { ReactComponent as ArrowDownIcon } from "assets/image/arrowDown.svg";
import DatePicker from "react-datepicker";
import { StyledDatePicker } from "./styles";
import PropTypes from "prop-types";
import { useOutsideClick } from "../../hooks/useClickOutside";

const RangeCalendar = ({
  actionOnSelect,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  showDatePicker,
  setShowDatePicker,
  isActive,
  customCanelFunction,
  ...props
}) => {
  const [startDateInternal, setStartDateInternal] = useState(startDate);
  const [endDateInternal, setEndDateInternal] = useState(endDate);
  const MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dropdownRef = useRef(null);

  useOutsideClick(dropdownRef, () => handleCloseDatePicker());

  useEffect(() => {
    setStartDateInternal(startDate);
    setEndDateInternal(endDate);
  }, [startDate, endDate]);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDateInternal(start);
    setEndDateInternal(end);
  };

  const handleSelectDate = () => {
    setStartDate(startDateInternal);
    setEndDate(endDateInternal);
    setShowDatePicker(false);
    actionOnSelect();
  };

  const handleCloseDatePicker = () => {
    setShowDatePicker(false);
    setStartDateInternal(null);
    setEndDateInternal(null);
    customCanelFunction();
  };

  const getDayAndMonth = (date) => {
    return MONTHS[new Date(date).getMonth()] + " " + new Date(date).getDate();
  };

  return (
    <StyledDatePicker $hasEndDate={endDateInternal}>
      <button
        onClick={() => {
          setShowDatePicker(!showDatePicker);
        }}
        className={`overview-filter ${
          isActive ? "overview-filter--active" : ""
        }`}
      >
        <CalendarIcon className="overview-filter--cal" />
        {startDate ? getDayAndMonth(startDate) : ""} {endDate && " →"}
        {endDate ? ` ${getDayAndMonth(endDate)}` : ""}
        {(startDate || endDate) && <span style={{ marginRight: "8px" }}></span>}
        {showDatePicker ? (
          <ArrowDownIcon className="overview-smooth overview-up" />
        ) : (
          <ArrowDownIcon className="overview-smooth" />
        )}
      </button>
      {showDatePicker && (
        <div ref={dropdownRef} className="date-picker">
          <DatePicker
            selected={startDateInternal}
            onChange={onChange}
            startDate={startDateInternal}
            endDate={endDateInternal}
            selectsRange
            inline
            showYearDropdown
            monthsShown={2}
          />
          <div className="date-picker-bottom">
            <div className="date-picker-bottom--date">
              {startDateInternal ? getDayAndMonth(startDateInternal) : ""}{" "}
              {endDateInternal && " →"}
              {endDateInternal ? ` ${getDayAndMonth(endDateInternal)}` : ""}
            </div>
            <button
              className="date-picker-bottom-btn"
              onClick={handleCloseDatePicker}
            >
              Cancel
            </button>
            <button
              className="date-picker-bottom-btn date-picker-bottom-btn--colored"
              onClick={handleSelectDate}
              disabled={!startDateInternal}
            >
              Apply date
            </button>
          </div>
        </div>
      )}
    </StyledDatePicker>
  );
};

RangeCalendar.propTypes = {
  actionOnSelect: PropTypes.func,
  startDate: PropTypes.any,
  setStartDate: PropTypes.func,
  endDate: PropTypes.any,
  setEndDate: PropTypes.func,
  showDatePicker: PropTypes.bool,
  setShowDatePicker: PropTypes.func,
  isActive: PropTypes.bool,
  customCanelFunction: PropTypes.func,
};

export default RangeCalendar;
