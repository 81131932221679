export const Colors = {
  black: "#0000000",
  white: "#ffffff",
  green: "#68d300",
  red: "#ff0000",
  lightOrange: "#f8aa30",
  lighterOrange: "#FCB045",
  orange: "#f48530",
  darkestGray: "#4C4C4C",
  darkerGray: "#4D4D4C",
  darkGray: "#696666",
  darkGray2: "#4E4E4E",
  normalGray: "#696565",
  lightGray: "#7B7B7B",
  lightGray2: "#DADADA",
  mediumGray: "#999",
  grayBorder: "#9C9696",
  almost_black: "#473F47",
  purple: "#672792",
  alert: "#e84118",
  success: "#4BB43A",
  primary1: "#2B64F5",
  textPrimary1: "#131b1f",
};
