import React from "react";
import styled from "styled-components";
import { Row, Col, Card, Button, Spinner } from "react-bootstrap";
import { Colors } from "assets/colors";
import { ReactComponent as OCLogoIcon } from "assets/image/OCLogo.svg";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background: white;
  justify-content: center;
  height: 100vh;
  width: 100%;

  h4 {
    text-align: center;
    font-weight: bold;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    color: white;
  }

  img {
    width: 200px;
    height: auto;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
    align-self: center;
  }

  button {
    color: ${Colors.primary1};
    background: white;
    border-color: white;
    border-radius: 50px;
  }
`;
const SCard = styled(Card)`
  background: orange;
  min-width: 500px;
  border-radius: 20px;
  border: none;
  padding: 25px;
  align-items: center;
`;

const Reauth = (props) => {
  return (
    <Wrapper>
      <Row className="fulls">
        <Col xs={12} sm={12}>
          <SCard>
            <OCLogoIcon />
            <h4 as={"h4"}>{props.title}</h4>
            <Card.Body>
              {props.children}
              <Button onClick={props.buttonClick}>
                {props.loading ? (
                  <Spinner animation="border" />
                ) : (
                  props.buttonText
                )}
              </Button>
            </Card.Body>
          </SCard>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Reauth;
