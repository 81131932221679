import React from 'react'
import styled from 'styled-components'
import { Row, Button, Modal } from 'react-bootstrap'

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;

    iframe {
        // width: 750px;
        // max-width: 750px;
        // height: 1334px;
        // margin: 0 auto;
        // border-style: none;
        // border-color: inherit;
        // border-width: 0px;
        // -webkit-transform: scale(0.5);
        // -webkit-transform-origin: 0 0;
        // display: block;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }

    /* Track */
        ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #88888888;
        border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #999;
    }
`

const PreviewWrapper = styled.div`
    position: relative;
    margin-bottom: 20px;
    display: flex;
`

const Preview = props => {
    const {params} = props.match
    let id = null

    if (params) {
        id = params.id
    } else {
        return
    }

    return (
        <Wrapper>
            <PreviewWrapper>
                <Button
                    onClick={() => props.history.push('/console/create-menus')}
                >
                    Go Back
                </Button>
            </PreviewWrapper>
            <iframe src={`https://app.appetizevm.com/${id}/`} 
                seamless
                width={400}
                height={600}
            />
        </Wrapper>
    )
}

export default Preview