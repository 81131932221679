import React from "react";
import useModal from "../hooks/useModal";
import GeneralModal from "components/GeneralModal";

let ModalContext;
let { Provider } = (ModalContext = React.createContext());

let ModalProvider = ({ children }) => {
  let {
    modal,
    handleModal,
    modalContent,
    hideCloseBtn,
    setHideCloseBtn,
    classes,
    setClasses,
  } = useModal();
  return (
    <Provider
      value={{
        modal,
        handleModal,
        modalContent,
        hideCloseBtn,
        setHideCloseBtn,
        classes,
        setClasses,
      }}
    >
      <GeneralModal />
      {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };
