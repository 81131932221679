import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter,
} from "react-router-dom";
import { Col, Container, Row, Card, Form } from "react-bootstrap";
import styled from "styled-components";
import Navbar from "components/Layout/Navbar";

const StyledCard = styled(Card)`
  /* background: red; */
  width: 70vw;
  padding: 2rem 3rem 2rem 2rem;
  @media (max-width: 575px) {
    width: 95vw;
    padding: 1rem 1.5rem 1rem 1rem;
  }
  .title {
    color: #000;
    font-family: Inter;
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    margin-top: 2rem;
    @media (max-width: 575px) {
      font-size: 1.25rem;
    }
  }
  .text {
    margin-top: 0.5rem;
    color: #000;
    font-family: Inter;
    font-weight: 400;
    font-size: 1rem;
    @media (max-width: 575px) {
      font-size: 0.85rem;
    }
  }
  .underline_header {
    font-style: italic;
    text-decoration: underline;
  }
  ol {
    padding-left: 1.25rem;
  }
  p {
    margin-top: 0.5rem;
    color: #000;
    font-family: Inter;
    font-weight: 400;
    font-size: 1rem;
    @media (max-width: 575px) {
      font-size: 0.85rem;
    }
  }
`;
const PrivacyContainer = styled.div`
  width: 100vw;
  min-height: 70vh;
  display: flex;
  justify-content: center;
`;

const PwaDeliveryTerms = (props) => {
  return (
    <>
      <Navbar />
      <PrivacyContainer>
        <StyledCard className="o-hidden border-0 shadow-lg my-5">
          <span className="title">OMNICART PLATFORM TERMS OF SERVICE</span>
          <p style={{ fontStyle: "italic", fontSize: "14px" }}>
            Last Updated: Feburary 13, 2021
          </p>
          <p>
            PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY AS IT
            CONSTITUTES A LEGALLY BINDING AGREEMENT BETWEEN YOU AND THE
            APPLICABLE OMNICART ENTITY (AS FURTHER DEFINED IN SECTION 1 OF PART
            I) IN RESPECT OF ACCESS AND USE OF OMNICART’S WEBSITE LOCATED AT{" "}
            <a href="https://www.omnicart.tech/">WWW.OMNICART.COM</a>, AND ANY
            SUBDOMAINS USED IN CONNECTION WITH OR RELATED TO THE SAME AND ANY
            FUTURE VERSIONS (THE “WEBSITE”) AS WELL AS OMNICART MOBILE
            APPLICATIONS (THE “OMNICART PLATFORM APP”)(TOGETHER THE OMNICART
            PLATFORM APP AND THE WEBSITE CONSTITUTE THE “OMNICART PLATFORM”)
            THESE TERMS OF SERVICE APPLY TO ALL USERS OF THE OMNICART PLATFORM,
            INCLUDING USERS WHO ARE VENDORS OF GOODS OR PROVIDERS OF COURIER
            SERVICES TO OTHER USERS OF THE OMNICART PLATFORM, USERS WHO PURCHASE
            GOODS OR SERVICES THROUGH THE OMNICART PLATFORM, USERS WHO DOWNLOAD
            THE OMNICART PLATFORM APP AND USERS WHO SIMPLY VIEW THE CONTENT ON
            OR AVAILABLE THROUGH THE WEBSITE. YOU MUST HAVE REACHED THE AGE OF
            MAJORITY IN YOUR JURISDICTION IN ORDER TO PLACE ORDERS THROUGH THE
            OMNICART PLATFORM AS A CUSTOMER OR TO OPERATE AS A VENDOR OR COURIER
            (AS SUCH TERMS ARE DEFINED IN SECTION 2 OF PART I). IF YOU ARE USING
            THE OMNICART PLATFORM ON BEHALF OF ANOTHER PERSON OR A CORPORATE
            ENTITY, YOU REPRESENT AND WARRANT THAT YOU HAVE THE AUTHORITY TO
            BIND SUCH PERSON OR ENTITY TO THESE TERMS OF SERVICE. THE TERM
            “USER”, “YOU” OR “YOUR” REFERS TO THE INDIVIDUAL OR LEGAL ENTITY, AS
            APPLICABLE, ACCESSING OR OTHERWISE USING OMNICART PLATFORM OR THE
            OMNICART SERVICES. YOU ACKNOWLEDGE AND AGREE THAT THE OMNICART
            PLATFORM AND THE OMNICART SERVICES ARE MADE AVAILABLE TO FACILITATE
            THE ORDERING OF FOOD, BEVERAGES OR OTHER PRODUCTS PROVIDED BY
            VENDORS THAT MAY BE DELIVERED BY COURIERS. YOU UNDERSTAND THAT
            OMNICART’S TECHNOLOGY AND SERVICES FACILITATE THESE TRANSACTIONS IN
            CERTAIN JURISDICTIONS ONLY AND THAT OMNICART IS NOT A VENDOR OR
            COURIER NOR IS OMNICART RESPONSIBLE FOR FULFILLING ORDERS PLACED BY
            CUSTOMERS WITH VENDORS OR COURIERS.
          </p>
          <p>
            BY ACCESSING, BROWSING AND USING THE OMNICART PLATFORM, YOU INDICATE
            THAT YOU HAVE READ AND ACCEPT THESE TERMS OF SERVICE. IF YOU DO NOT
            ACCEPT THESE TERMS OF SERVICE, THEN YOU MAY NOT ACCESS OR USE THE
            OMNICART PLATFORM OR OMNICART SERVICES OR ANY OF THEIR CONTENT OR
            SERVICES.
          </p>
          <p>
            IMPORTANT NOTICE: PLEASE BE AWARE THAT SECTIONS 9 AND 10 OF PART I
            CONTAIN IMPORTANT DISCLAIMERS AND LIMITATIONS OF LIABILITY. IN
            ADDITION, SECTION 12 OF PART I CONTAINS PROVISIONS GOVERNING HOW
            CLAIMS THAT YOU AND WE HAVE AGAINST EACH OTHER ARE RESOLVED,
            INCLUDING, WITHOUT LIMITATION, ANY CLAIMS THAT AROSE OR WERE
            ASSERTED PRIOR TO THE EFFECTIVE DATE OF THIS AGREEMENT. IN
            PARTICULAR, IT CONTAINS AN ALTERNATIVE DISPUTE RESOLUTION PROCESS
            AND YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AND SEEK RELIEF
            AGAINST US ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS
            MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING AND YOU
            ARE WAIVING YOUR RIGHT TO SEEK RELIEF IN A COURT OF LAW AND TO HAVE
            A JURY TRIAL ON YOUR CLAIMS.
          </p>
          <p>
            THESE TERMS OF SERVICE ARE SUBJECT TO CHANGE BY OMNICART IN ITS SOLE
            DISCRETION AT ANY TIME TO THE FULLEST EXTENT PERMITTED BY APPLICABLE
            LAW. When changes are made, OMNICART will make a new copy of the
            Terms of Service available on the OMNICART Platform. We will also
            update the "Last Updated" date at the top of the Terms of Service.
            If we make any material changes, and you have registered with us to
            create an account we may also send an e- mail to you at the last
            e-mail address you provided to us pursuant to the Terms of Service.
            Any changes to the Terms of Service will be effective immediately
            after posting notice of such changes on the OMNICART Platform.
            OMNICART may require you to provide consent to the updated Terms of
            Service in a specified manner before further use of the OMNICART
            Platform and/or the OMNICART Services is permitted. If you do not
            agree to any change(s) after receiving a notice of such change(s),
            you shall stop using the OMNICART Platform and/or the OMNICART
            Services. Otherwise, your continued use of the OMNICART Platform
            and/or OMNICART Services constitutes your acceptance of such
            change(s). PLEASE REGULARLY CHECK THE OMNICART PLATFORM TO VIEW THE
            MOST CURRENT TERMS.
          </p>
          <p>
            INDEX You should read these Terms of Service in their entirety,
            however, this index is provided for ease of reference to enable you
            to navigate to specific sections of the Terms.
          </p>
          <ol type="i">
            <li>
              <p className="text">GENERAL TERMS</p>
            </li>
            <ol type="1">
              <li>
                <p className="text">OMNICART Parties</p>
              </li>
              <li>
                <p className="text">Users and Applicable Legal Terms</p>
              </li>
              <li>
                <p className="text">OMNICART Accounts</p>
              </li>
              <li>
                <p className="text">
                  Licensed Access to the OMNICART Platform and Permitted Use
                </p>
              </li>
              <li>
                <p className="text">No Unlawful or Prohibited Use</p>
              </li>
              <li>
                <p className="text">Intellectual Property Terms</p>
              </li>
              <li>
                <p className="text">Advertising and Third-Party Websites</p>
              </li>
              <li>
                <p className="text">Platform Updates and Availability</p>
              </li>
              <li>
                <p className="text">Disclaimers</p>
              </li>
              <li>
                <p className="text">
                  No Implied Warranties; Limitations of Liability
                </p>
              </li>
              <li>
                <p className="text">Indemnification</p>
              </li>
              <li>
                <p className="text">Governing Law and Dispute Resolution</p>
              </li>
              <li>
                <p className="text">Miscellaneous</p>
              </li>
            </ol>
            <li>
              <p className="text">ORDERING TERMS</p>
            </li>
            <ol type="1">
              <li>
                <p className="text">Access, OMNICART Services and Products</p>
              </li>
              <li>
                <p className="text">OMNICART Customer Account</p>
              </li>
              <li>
                <p className="text">Placing Orders</p>
              </li>
              <li>
                <p className="text">
                  Customer Care, Goods Contract, Third Party Couriers and
                  Refunds
                </p>
              </li>
              <li>
                <p className="text">Payment Processing Services</p>
              </li>
              <li>
                <p className="text">Price and Payment</p>
              </li>
              <li>
                <p className="text">Pickup or Delivery</p>
              </li>
              <li>
                <p className="text">Alcohol</p>
              </li>
            </ol>
            <li>
              <p className="text">
                PROMOTIONAL VOUCHERS, REFER-A-FRIEND PROGRAM, GIFT CARDS AND
                OMNICART CREDITS AND CONTESTS
              </p>
            </li>
            <ol type="1">
              <li>
                <p className="text">Promotional Vouchers</p>
              </li>
              <li>
                <p className="text">Refer-A-Friend Program</p>
              </li>
              <li>
                <p className="text">Gift Card Terms and OMNICART Credits</p>
              </li>
              <li>
                <p className="text">Contests/Competitions</p>
              </li>
            </ol>
          </ol>
          <ol type="i">
            <li>
              <p className="text">GENERAL TERMS</p>
            </li>
            <ol type="1">
              <li>
                <p className="text">OMNICART Parties</p>
              </li>
              <ul>
                <li>
                  <p className="text">
                    If you are accessing or using the OMNICART Platform outside
                    of the United States, these Terms of Service form an
                    agreement between you and OMNICART Merchant Services Inc
                    (“OMNICART Canada”), a company incorporated under the laws
                    of Canada. For non-U.S. Users, use of the terms “OMNICART”,
                    “us”, “we” or “our” means OMNICART Canada.
                  </p>
                </li>
                <li>
                  <p className="text">
                    If you are accessing or using the OMNICART Platform within
                    the United States, these Terms of Service form an agreement
                    between you and OMNICART, Corp. (“OMNICART Us”), which is a
                    wholly owned subsidiary of OMNICART Canada. For U.S. Users,
                    use of the terms “OMNICART”, “us”, “we” refers to OMNICART
                    U.S.
                  </p>
                </li>
                <li>
                  <p className="text">
                    OMNICART Canada, OMNICART U.S., and their affiliates,
                    officers, directors and employees, are referred to in these
                    Terms of Service as the “OMNICART Group”. For the avoidance
                    of doubt, the OMNICART Group does not include any third
                    parties such as Vendors and Couriers (as such terms are
                    defined in Section 2 of Part I offering goods or services
                    through the OMNICART Platform.
                  </p>
                </li>
              </ul>
              <li>
                <p className="text">Users and Applicable Legal Terms</p>
              </li>
              <ul>
                <li>
                  <p className="text">
                    Definition of Users. The OMNICART Platform facilitates the
                    ordering by consumers/end users of the OMNICART Platform
                    ("Customers") of food, drinks and other products
                    (collectively "Goods") offered by merchants and store owners
                    through the platform ("Vendors") and the delivery of such
                    Goods through independently contracted couriers who are
                    users of the OMNICART Platform (the "Couriers").
                    Collectively, Customers, Vendors and Couriers constitute
                    "Users" of the OMNICART Platform and these Terms of Service
                    apply to all Users.
                  </p>
                </li>
                <li>
                  <p className="text">
                    OMNICART Privacy Policy. Please note that important
                    information about OMNICART’s practices on the collection,
                    use and disclosure of Users’ personal information submitted
                    via the Website or the OMNICART Platform App is governed by
                    our Privacy Policy, the terms of which are incorporated into
                    this Terms of Service. Please see further:
                    https://www.omnicart.tech/privacy-policy ("Privacy Policy").
                  </p>
                </li>
                <li>
                  <p className="text">
                    Additional Terms for Vendors and Couriers. In addition to
                    these Terms of Service and the Privacy Policy, Vendors and
                    Couriers are subject to additional terms agreed in separate
                    agreements with OMNICART in respect of the goods/services
                    offered by such Users. In the event of any conflict between
                    these Terms of Services and the separate agreement you have
                    with OMNICART, the terms of the separate OMNICART agreement
                    will govern.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Additional Terms for Customers. Specific terms on ordering
                    are set out in Part II of these Terms of Service. Vendors
                    may have additional privacy policies or statements which
                    govern their practices in collecting, storing, using and
                    disclosing your Personal Information when you order from
                    them. Please read those additional privacy policies or
                    statements carefully. You hereby represent and warrant to
                    OMNICART that you have familiarized yourself and agree with
                    those privacy policies or statements imposed by any Vendor
                    with whom you elect to deal through the OMNICART Platform.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Additional Terms for All Users. Voucher Terms and Contest
                    Rules are addressed in Part III of this Terms of Service.
                  </p>
                </li>
                <li>
                  <p className="text">
                    OMNICART Platform User Code. The OMNICART Social Code
                    applies equally to all Users. OMNICART may from time to time
                    implement additional guidelines and codes of conduct for use
                    of the OMNICART Platform and Customer Services. Any such
                    terms will be notified to Users and will be incorporated by
                    reference into these Terms of Service.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Additional Terms and Conditions for OMNICART Platform App
                    Users. To the extent the other terms and conditions of these
                    Terms of Service are less restrictive than, or otherwise
                    conflict with, the terms and conditions of this Section, the
                    more restrictive or conflicting terms and conditions in this
                    Section apply, but solely with respect to the use of Google
                    Maps services and content, or to the OMNICART Platform App
                    if downloaded from the Apple App Store or the Google Play
                    Store.
                  </p>
                </li>
              </ul>
              <li>
                <p className="text">
                  The OMNICART Platform may use services and content from Google
                  Maps or Google Earth. You acknowledge and agree that you are
                  bound by the Google Maps/Google Earth Additional Terms of
                  Service (including the Google Privacy Policy). The following
                  additional terms and conditions apply to you if you are using
                  a OMNICART Platform App. You acknowledge and agree that: These
                  Terms of Service are entered into between you and OMNICART.
                  Neither Apple, Inc. ("Apple") nor Google, Inc. ("Google")
                  (collectively, the "download service providers") are parties
                  to this Terms of Service agreement nor will they have
                  obligations with respect to the OMNICART Platform App. The
                  applicable OMNICART party not Apple or Google, is solely
                  responsible for the OMNICART Platform App, including any
                  maintenance and support, and the content thereof as set forth
                  hereunder. However, the download service providers, along with
                  their respective subsidiaries, are third-party beneficiaries
                  of these Terms of Service. Upon your acceptance of these Terms
                  of Service, the download service provider used to obtain the
                  OMNICART Platform App will have the right (and will be deemed
                  to have accepted the right) to enforce these Terms of Service
                  against you as a third-party beneficiary. If OMNICART fails to
                  conform to any applicable warranty, you may notify the
                  download service provider used to obtain the OMNICART Platform
                  App and they will refund the purchase price (if any) of the
                  OMNICART Platform App in accordance with their policies, and,
                  to the maximum extent permitted by applicable law, the
                  download service provider used to obtain the OMNICART Platform
                  App will have no other warranty obligation whatsoever with
                  respect to the application and, as between the download
                  service provider used to obtain the OMNICART Platform App and
                  OMNICART, any other claims, losses, liabilities, damages,
                  costs or expenses attributable to any failure to confirm to
                  any warranty is OMNICART’s responsibility. You and us both
                  acknowledge that OMNICART, not the download service provider
                  used to obtain the OMNICART Platform App, is responsible for
                  addressing any end-user or third-party claims related to the
                  OMNICART Platform App, including, but not limited to: (a)
                  product liability claims, (b) any claim that the OMNICART
                  Platform App fails to conform to any applicable legal or
                  regulatory requirement, and (c) claims arising under consumer
                  protection or similar law. Further, you and us both
                  acknowledge that, in the event of any third-party claim that
                  the OMNICART Platform App or your possession or use of the
                  OMNICART Platform App infringes on that third party’s
                  intellectual property rights, as between OMNICART and the
                  download service provider used to obtain the OMNICART Platform
                  App, the OMNICART Platform App will be solely responsible for
                  the investigation, defense, settlement and discharge of any
                  such intellectual property infringement claim. You represent
                  and warrant that you are not located in a country that is
                  subject to a U.S. Government embargo, or that has been
                  designated by the U.S. Government as a "terrorist supporting"
                  country, and you are not listed on any U.S. Government list of
                  prohibited or restricted parties.
                </p>
              </li>
              <ul>
                <li>
                  <p className="text">
                    OMNICART Rewards. If you use the OMNICART Platform as a
                    Customer, you will be deemed to have accepted the OMNICART
                    Rewards Terms (i) when you request that we create a OMNICART
                    Account for you, if you do not yet have a OMNICART Account
                    when this version of the Terms of Service comes into effect,
                    or (ii) when this version of the Terms of Service comes into
                    effect or otherwise becomes binding on you, if you already
                    have a OMNICART Account at that time.
                  </p>
                </li>
              </ul>
              <li>
                <p className="text">OMNICART Accounts</p>
              </li>
              <ul>
                <li>
                  <p className="text">
                    Accurate Information. You represent and warrant that all
                    information supplied by you on the OMNICART Platform
                    (including information provided by you to create an account
                    for use of the OMNICART Platform (a "OMNICART Account")) or
                    in connection with your use of the OMNICART Services is
                    true, accurate, current and complete.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Security of OMNICART Accounts. Any OMNICART Account must be
                    kept secure and you agree that you will not share or
                    disclose your OMNICART Account credentials with anyone. No
                    members of the OMNICART Group will be liable for any loss or
                    damage arising from your failure to safeguarding your
                    OMNICART Account, use a strong password or limit its use to
                    your OMNICART Account. You may browse some of the OMNICART
                    Platform without creating a OMNICART Account.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Discretionary Access to OMNICART Accounts. OMNICART reserves
                    the right to deny your request for a OMNICART Account
                    (including usernames) and to disable or terminate access to
                    any OMNICART Account (including usernames) issued to you at
                    any time in OMNICART’s sole discretion. If OMNICART disables
                    access to a OMNICART Account issued to you, you will be
                    prevented from accessing the OMNICART Platform or the
                    OMNICART Services, your OMNICART Account details, Orders or
                    other information that is associated with your OMNICART
                    Account.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Suspension, Term, Termination and Deletion of OMNICART
                    Accounts. We may suspend or terminate your ability to access
                    the OMNICART Platform, or cease providing you with all or
                    part of the OMNICART Services at any time for any or no
                    reason, including, if we believe: (i) you may have violated
                    these Terms of Service, (ii) you may create risk or possible
                    legal exposure for us; (iii) prolonged inactivity; or (iv)
                    our provision of the OMNICART Services to you is no longer
                    commercially viable. Suspension or termination may also
                    include removal of some or all of the materials uploaded by
                    you. We may make reasonable efforts to notify you by the
                    email address associated with your account, through the
                    OMNICART Platform or the next time you attempt to access
                    your account, depending on the circumstances. You
                    acknowledge and agree that all suspensions or terminations
                    may be made by OMNICART in its sole discretion and that no
                    members of the OMNICART Group will be liable to you or any
                    third-party for any suspension or termination of your access
                    or for the removal of any of the materials uploaded by you
                    to the OMNICART Platform. Any suspension or termination of
                    this Agreement by OMNICART will be in addition to any and
                    all other rights and remedies that OMNICART may have.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Account Deletion and Termination. You may request the
                    deletion of your account by requesting (by email or through
                    any then-available interfaces) that your OMNICART Account be
                    deleted, ceasing use of the OMNICART Platform, the OMNICART
                    Services and uninstalling and removing all local software
                    components thereof, if any. If you request deletion of your
                    OMNICART Customer Account, we may disable your OMNICART
                    Customer Account but keep the disabled OMNICART Customer
                    Account for fraud prevention or other lawful purposes. If we
                    have received all outstanding payment from you, you may
                    request termination these Terms of Service at any time by
                    requesting by email (or any then-available interface on the
                    OMNICART Platform) that your OMNICART Account be deleted. We
                    may terminate these Terms of Service at any time by giving
                    notice to you, at our discretion, by email at your current
                    email address on file with us or through the OMNICART
                    Platform.
                  </p>
                </li>
              </ul>
              <li>
                <p className="text">
                  Licensed Access to The OMNICART Platform and Permitted Use
                </p>
              </li>
              <ul>
                <li>
                  <p className="text">
                    Subject to your compliance with these Terms of Service,
                    OMNICART hereby grants you a personal, revocable,
                    non-exclusive and non-transferable license to use the
                    OMNICART Platform in accordance with these Terms of Service
                    in the following manner depending on the type of User that
                    you are:
                  </p>
                </li>
                <ol type="i">
                  <li>
                    <p className="text">
                      for Customers, you may use the OMNICART Platform to view
                      menus provided by Vendors, create an account, place orders
                      and pay for Goods (including the delivery of Goods) (each
                      an "Order"). You may also use feedback features of the
                      OMNICART Platform to rate, post comments, rating and
                      reviews on Goods, Vendors and Couriers (as applicable);
                    </p>
                  </li>
                  <li>
                    <p className="text">
                      for Vendors, you may create an account and use the
                      OMNICART Platform to manage the offer and fulfilment of
                      Orders; and
                    </p>
                  </li>
                  <li>
                    <p className="text">
                      for Couriers, you may create an account and use the
                      OMNICART Platform to manage and fulfill requests for
                      deliveries of Orders,
                    </p>
                  </li>
                </ol>
                <li>
                  <p className="text">
                    (i)-(iii) constitute the "Permitted Use" as applicable for a
                    specific type of User.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Reservation of Rights. OMNICART retains the right, at its
                    sole discretion, to deny access to anyone to the OMNICART
                    Platform or the OMNICART Services, at any time and for any
                    reason (or no reason at all), including, but not limited to,
                    for violation of these Terms of Service or use other than
                    the Permitted Use. You will cease and desist from any such
                    access or use of the OMNICART Platform or OMNICART Services
                    immediately upon request by OMNICART.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Geographic Application of the OMNICART Platform. The
                    OMNICART Platform and/or specific services may not be
                    available at all or any times and in all or any
                    jurisdictions. Furthermore, nothing on the OMNICART Platform
                    constitutes an offer or solicitation to buy or sell any
                    product or service to anyone in any jurisdiction in which
                    such an offer or solicitation may be prohibited by law.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Changes to the OMNICART Platform. OMNICART reserves the
                    right to change or allow a third party to change any
                    information, material or content (including, but not limited
                    to, price, features, availability of Goods, menus, Vendors,
                    posting and rating/reviews of Vendors) contained on or
                    provided through the OMNICART Platform (the "Content") at
                    any time, and from time to time, without notice. For greater
                    certainty, the Content include the User Content (as defined
                    below).
                  </p>
                </li>
              </ul>
              <li>
                <p className="text">
                  No Unlawful or Prohibited Use <br /> You may not, without
                  OMNICART's prior written permission, use the OMNICART
                  Platform, the OMNICART Services or any Content (including
                  other User’s User Content) for purposes other than the
                  applicable Permitted Use. Without limiting the generality of
                  the foregoing, you will not, and will not permit anyone else
                  to, or attempt to use the OMNICART Platform and/or the Content
                  to:
                </p>
              </li>
              <ul>
                <li>
                  <p className="text">
                    "frame", "mirror" or otherwise incorporate the OMNICART
                    Platform, the OMNICART Services or the Content or any part
                    thereof on any commercial or non-commercial website;
                  </p>
                </li>
                <li>
                  <p className="text">
                    access, monitor or copy any part of the OMNICART Platform,
                    the OMNICART Services or the Content using any robot,
                    spider, scraper or other automated means or any manual
                    process for any purpose without OMNICART’s express written
                    permission;
                  </p>
                </li>
                <li>
                  <p className="text">violate any laws;</p>
                </li>
                <li>
                  <p className="text">
                    violate the restrictions in any robot exclusion headers on
                    the Content or the OMNICART Platform or bypass or circumvent
                    other measures employed to prevent or limit access to the
                    OMNICART Services or the OMNICART Platform;
                  </p>
                </li>
                <li>
                  <p className="text">
                    remove (or permit anyone else to remove) any watermarks,
                    labels or other legal or proprietary notices included in the
                    OMNICART Platform or the Content;
                  </p>
                </li>
                <li>
                  <p className="text">
                    modify or attempt to modify (or permit anyone else to modify
                    or attempt to modify) the OMNICART Platform, or any Vendor
                    menu, including any modification for the purpose of
                    disguising or changing any indications of the ownership or
                    source of the OMNICART Platform;
                  </p>
                </li>
                <li>
                  <p className="text">
                    forge headers or otherwise manipulate identifiers in order
                    to disguise the origin of any material transmitted to or
                    through the OMNICART Platform or impersonate another person
                    or organization or misrepresent their affiliation with a
                    person or entity;
                  </p>
                </li>
                <li>
                  <p className="text">
                    attempt to, assist, authorise or encourage others to
                    circumvent, disable or defeat any of the security features
                    or components, such as digital rights management software or
                    encryption, that protect the OMNICART Platform or the
                    OMNICART Services;
                  </p>
                </li>
                <li>
                  <p className="text">
                    copy, reproduce, modify, translate, distribute, transfer,
                    sell, publish, broadcast, perform, transmit, license or
                    circulate in any form any part of the OMNICART Platform or
                    the Content;
                  </p>
                </li>
                <li>
                  <p className="text">
                    license, sell, rent, lease, transfer, assign, distribute,
                    host, or otherwise commercially exploit the OMNICART
                    Platform;
                  </p>
                </li>
                <li>
                  <p className="text">
                    create derivative works based on the OMNICART Platform, the
                    OMNICART Services or the Content, in whole or in part, or
                    decompile, disassemble, reverse engineer or other exploit
                    any part of the OMNICART Platform, the OMNICART Services or
                    the Content;
                  </p>
                </li>
                <li>
                  <p className="text">
                    use of access the OMNICART Platform in a manner that
                    violates the rights (including, but not limited to
                    contractual, intellectual property or proprietary rights) of
                    any third party; or
                  </p>
                </li>
                <li>
                  <p className="text">
                    upload to or transmit through the OMNICART Platform any
                    information, images, text, data, media or other content that
                    is offensive, harmful, tortuous, hateful, obscene,
                    defamatory or violates any laws or is otherwise
                    objectionable, in each case as determined by OMNICART in its
                    sole discretion.
                  </p>
                </li>
              </ul>
              <li>
                <p className="text">Intellectual Property Terms</p>
              </li>
              <ul>
                <li>
                  <p className="text">Content and Intellectual Property</p>
                </li>
                <ol type="i">
                  <li>
                    <p className="text">
                      OMNICART Content and Intellectual Property Rights. The
                      OMNICART Platform and all content contained therein
                      (excluding User Content as defined in Section 6(b) below),
                      is owned or licensed by OMNICART and protected by
                      copyright, trade-mark and other intellectual property laws
                      ("OMNICART Content"). OMNICART expressly reserves all
                      rights in the OMNICART Platform and all materials provided
                      by OMNICART in connection with these Terms of Service that
                      are not specifically granted to you. You acknowledge that
                      all right, title and interest in the OMNICART Platform,
                      all materials provided by OMNICART in connection with
                      these Terms of Service (including the OMNICART Content),
                      and any update, adaptation, translation, customization or
                      derivative work thereof, and all intellectual property
                      rights therein will remain with OMNICART (or third party
                      suppliers or licensors, if applicable), and that the
                      OMNICART Platform and all materials provided by OMNICART
                      hereunder are licensed and not "sold" to you.
                    </p>
                  </li>
                  <li>
                    <p className="text">
                      User Content. All information, data, text, software,
                      music, sound, photographs, graphics, video, messages or
                      other materials, whether publicly posted or privately
                      transmitted to the OMNICART Platform by Users ("User
                      Content"), is the sole responsibility of such Users. This
                      means that the User, and not members of the OMNICART
                      Group, are entirely responsible for all such material
                      uploaded, posted, emailed, transmitted or otherwise made
                      available on the OMNICART Platform. OMNICART may monitor
                      User Content from time to time but OMNICART does not
                      guarantee the accuracy, integrity or quality of such
                      content. Under no circumstances will any members of the
                      OMNICART Group be liable in any way for any User Content
                      including for any loss or damage of any kind incurred as a
                      result of the viewing or use of any materials posted,
                      emailed, transmitted or otherwise made available via the
                      OMNICART Platform. To the extent your User Content
                      contains any personally identifiable information, that
                      data is handled in accordance with the OMNICART Privacy
                      Policy and all other User Content will be considered
                      non-confidential.
                    </p>
                  </li>
                  <li>
                    <p className="text">
                      User-Related Content. OMNICART may collect data,
                      information, records, files, material or other content
                      from your local computer (including mobile) system or from
                      third-parties with your permission, and (in each case)
                      including all results from processing such data, including
                      compilations, and derivative works thereof ("User-Related
                      Content"). You grant to OMNICART Group a perpetual,
                      transferrable, irrevocable, royalty-free, worldwide and
                      sublicensable license to access, collect, store and use
                      any such User-Related Content. To the extent your
                      User-Related Content contains any personally identifiable
                      information, that data is handled in accordance with the
                      OMNICART Privacy Policy. User-Related Content is not
                      considered confidential or proprietary.
                    </p>
                  </li>
                </ol>
                <li>
                  <p className="text">
                    User Content License to OMNICART. By submitting, posting or
                    displaying User Content on or through the OMNICART Platform
                    or otherwise to OMNICART, you grant OMNICART Group, a
                    worldwide, non- exclusive, royalty-free, perpetual,
                    transferable, and fully sublicensable right to use, refrain
                    from using, remove, reproduce, modify, edit, copy, adapt,
                    publish, translate, create derivative works from,
                    distribute, transmit, perform display and otherwise use User
                    Content, in whole or in part. You further grant OMNICART the
                    right (although OMNICART does not have the obligation) to
                    pursue at law any person or entity that violates your or
                    OMNICART’s rights in the User Content by a breach of these
                    Terms of Service. You represent and warrant that you have
                    all the rights, power and authority necessary to grant the
                    rights granted herein to any User Content that you submit.
                    For the avoidance of doubt, OMNICART has no obligation to
                    post or display any User Content on the OMNICART Platform.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Use of OMNICART Content and User Content by other Users. You
                    may only use OMNICART Content and other Users Content (eg.
                    menus provided by Vendors) for the applicable Permitted Use.
                    If you print extracts from the OMNICART Platform for your
                    own personal, non- commercial use, you must not modify the
                    digital or papers of such materials or use any graphics,
                    pictures, photographs or videos separately from any
                    accompanying text.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Terms on Submissions/Feedback and Other User Content
                  </p>
                </li>
                <ol type="i">
                  <li>
                    <p className="text">
                      Creating Submissions and Other User Content. Through the
                      capabilities of the OMNICART Platform you may be able to
                      provide ratings/reviews, suggestions or other feedback
                      ("Submissions"). Submissions are a subset of User Content.
                      In creating and posting any Submissions (or other User
                      Content) you represent and warrant that you will not post
                      or transmit to or from the OMNICART Platform any material
                      or content which does or may:
                    </p>
                  </li>
                  <ol type="1">
                    <li>
                      <p className="text">
                        Breach any applicable local, national or international
                        law;
                      </p>
                    </li>
                    <li>
                      <p className="text">Be unlawful or fraudulent;</p>
                    </li>
                    <li>
                      <p className="text">
                        Amount to unauthorised advertising;
                      </p>
                    </li>
                    <li>
                      <p className="text">
                        Contain any defamatory, obscene or offensive material;
                      </p>
                    </li>
                    <li>
                      <p className="text">
                        Promote violence or discrimination;
                      </p>
                    </li>
                    <li>
                      <p className="text">
                        Infringe the intellectual property rights of another
                        person;
                      </p>
                    </li>
                    <li>
                      <p className="text">
                        Breach any legal duty owed to a third party (such as a
                        duty of confidence);
                      </p>
                    </li>
                    <li>
                      <p className="text">
                        Promote illegal activity or invade another’s privacy;
                      </p>
                    </li>
                    <li>
                      <p className="text">
                        Give the impression that they originate from us; or
                      </p>
                    </li>
                    <li>
                      <p className="text">
                        Be used to impersonate another person or to misrepresent
                        your affiliation with another person.
                      </p>
                    </li>
                  </ol>
                  <li>
                    <p className="text">
                      You will be solely liable for any damages resulting from
                      any violation of the foregoing restrictions, or any other
                      harm resulting from your posting of Submissions or other
                      User Content to the OMNICART Platform.
                    </p>
                  </li>
                  <li>
                    <p className="text">
                      Status of Submissions on the OMNICART Platform. We reserve
                      the right to remove or edit at any time any Submissions or
                      other User Content posted, uploaded or transmitted to the
                      OMNICART Platform that we determine breaches the
                      restrictions in Section 6(d)(i) above or is otherwise
                      objectionable or may expose us or any third parties to any
                      harm or liability of any type, or for any reason. Any
                      Submissions on the OMNICART Platform is for information
                      purposes only and does not constitute advice from us or
                      the opinions of anyone within the OMNICART Group. User
                      Content may reflect the opinions of Customers who have
                      ordered through the OMNICART Platform and any statements,
                      advice or opinions provided by such persons are theirs
                      only. Accordingly, to the fullest extent permitted by law,
                      no members of the OMNICART Group assume any responsibility
                      or liability to any person for any User Content, including
                      without limitation any mistakes, defamation, obscenity,
                      omissions or falsehoods that you may encounter in any such
                      materials.
                    </p>
                  </li>
                  <li>
                    <p className="text">
                      Public Display of Submissions. You acknowledge that
                      OMNICART may choose to provide attribution of your
                      Submissions (for example, listing a date, User’s name and
                      city on a User’s rating/review that you submit) at
                      OMNICART’s discretion, and that such User Content may be
                      visible to other Users and visitors to the OMNICART
                      Platform. By providing a rating/review you acknowledge and
                      consent to us using your given name in association with
                      the rating/review. All ratings/reviews that we receive may
                      be moderated before we publish them. We may do this to
                      make sure that ratings/reviews fit our content guidelines.
                    </p>
                  </li>
                </ol>
                <li>
                  <p className="text">
                    Note on Privacy. If you access any Person Information
                    through or in connection with the OMNICART Platform you
                    agree that you will treat such Personal Information in
                    accordance with OMNICART's current Privacy Policy and you
                    will not collect or harvest any Personal Information,
                    including Personal Information of other users, except as
                    permitted by these Terms of Service.
                  </p>
                </li>
              </ul>
              <li>
                <p className="text">Advertising and Third Party Websites</p>
              </li>
              <ul>
                <li>
                  <p className="text">
                    If you elect to have any business dealings with anyone whose
                    products or services may be advertised on the OMNICART
                    Platform, you acknowledge and agree that such dealings are
                    solely between you and such advertiser and you further
                    acknowledge and agree that no member of the OMNICART Group
                    will have any responsibility or liability for any losses or
                    damages that you may incur as a result of any such dealings.
                  </p>
                </li>
                <li>
                  <p className="text">
                    The OMNICART Platform may provide links to third party
                    websites and such links are provided solely for your
                    convenience. Third party websites: Links to third party
                    websites on the OMNICART Platform are provided solely for
                    your convenience. If you use these links, you leave the
                    OMNICART Platform. We have not reviewed and do not control
                    any of these third-party websites (and are not responsible
                    for these websites or their content or availability). We do
                    not endorse or make any representation about these websites,
                    their content, or the results from using such websites or
                    content. If you decide to access any of the third-party
                    websites linked to the OMNICART Platform, you do so entirely
                    at your own risk.
                  </p>
                </li>
                <li>
                  <p className="text">
                    OMNICART reserves the right to prohibit or remove (or
                    require you to remove) any link to the OMNICART Platform,
                    including, without limitation, any link which contains or
                    makes available any content or information of the foregoing
                    nature, at any time.
                  </p>
                </li>
              </ul>
              <li>
                <p className="text">Availability and Updates</p>
              </li>
              <ul>
                <li>
                  <p className="text">
                    OMNICART may alter, suspend, or discontinue the OMNICART
                    Platform or Service at any time and for any reason or no
                    reason, without notice. The OMNICART Platform or Service may
                    be unavailable from time to time due to maintenance or
                    malfunction of computer or network equipment or other
                    reasons. OMNICART may periodically add or update the
                    information and materials on this the OMNICART Platform
                    without notice.
                  </p>
                </li>
                <li>
                  <p className="text">
                    While we try to ensure the OMNICART Platform is normally
                    available twenty four (24) hours a day, we do not undertake
                    any obligation to do so, and no members of the OMNICART
                    Group will be liable to you if the OMNICART Platform is
                    unavailable at any time or for any period.
                  </p>
                </li>
                <li>
                  <p className="text">
                    OMNICART may upgrade or alter the OMNICART Platform at any
                    time. OMNICART may remotely access the OMNICART Platform
                    (including for greater certainty any OMNICART Platform App)
                    at any time for any lawful reason, in particular to update
                    the software or to perform software maintenance.
                  </p>
                </li>
              </ul>
              <li>
                <p className="text">
                  DISCLAIMERS  <br /> THE LAWS OF CERTAIN JURISDICTIONS,
                  INCLUDING QUEBEC, DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                  LEGAL WARRANTIES, CONDITIONS OR REPRESENTATIONS. IF THESE LAWS
                  APPLY TO YOU, SOME OR ALL OF THE EXCLUSIONS OR LIMITATIONS IN
                  THESE TERMS OF SERVICE (INCLUDING THE FOLLOWING DISCLAIMERS
                  AND LIMITATIONS OF LIABILITY) MAY NOT APPLY AND YOU MAY HAVE
                  ADDITIONAL RIGHTS.
                </p>
              </li>
              <ul>
                <li>
                  <p className="text">
                    Content: While we try to ensure that information on the
                    OMNICART Platform is correct, we do not promise it is
                    accurate or complete. We may make changes to the material on
                    the OMNICART Platform including to Goods and prices
                    described on it, at any time without notice. The material on
                    the OMNICART Platform may be out of date, and we make no
                    commitment to update that material.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Allergy, dietary and other menu information: We try to
                    accurately copy the item names, descriptions, prices,
                    special offer information, heat and allergenic warnings and
                    other information ("Menu Information") from the menus that
                    are provided to us by Vendors. However, it is the Vendors
                    that are responsible for providing this Menu Information and
                    ensuring that it is factually accurate and up-to-date, and
                    we do not undertake any such responsibility. If you are in
                    doubt about allergy warnings, contents of a dish or any
                    other Menu Information, you should confirm with the Vendor
                    directly before ordering.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Vendor actions and omissions: The legal contract for the
                    supply and purchase of Goods is between the Customer and the
                    Vendor that you place your Order with. We have no control
                    over the actions or omissions of any Vendor or Courier.
                    Without limiting the generality of the foregoing, you
                    acknowledge and accept the following by using the OMNICART
                    Platform:
                  </p>
                </li>
                <ol type="i">
                  <li>
                    <p className="text">
                      We do not give any undertaking that the Goods ordered from
                      any Vendor through the OMNICART Platform will be of
                      satisfactory quality or suitable for your purpose and we
                      disclaim any such warranties.
                    </p>
                  </li>
                  <li>
                    <p className="text">
                      Estimated times for deliveries and pick-ups are provided
                      by the Vendors and are only estimates. Neither we nor the
                      Vendors or Couriers guarantee that Orders will be
                      delivered or will be available for pick-up within the
                      estimated times.
                    </p>
                  </li>
                  <li>
                    <p className="text">
                      We encourage all Vendors accept all Orders and to
                      communicate any rejection promptly, and we will notify you
                      (generally by email) as soon as reasonably practicable if
                      a Vendor rejects your Order. However, we do not guarantee
                      that Vendors will accept all Orders, and Vendors have the
                      discretion to reject Orders at any time because they are
                      too busy, due to weather conditions or for any other
                      reason.
                    </p>
                  </li>
                </ol>
                <li>
                  <p className="text">
                    The foregoing disclaimers do not affect Customer’s statutory
                    rights against any Vendor or Courier.
                  </p>
                </li>
                <li>
                  <p className="text">Service Disclaimers</p>
                </li>
                <ol type="i">
                  <li>
                    <p className="text">
                      Viruses. The downloading and viewing of the OMNICART
                      Platform or the Content is done at your own risk. OMNICART
                      cannot and does not guarantee or warrant that the OMNICART
                      Platform or the Content are compatible with your computer
                      system or that the OMNICART Platform or the Content, or
                      any links from the OMNICART Platform or the Content, will
                      be free of viruses, worms, malware, trojan horses or
                      disabling devices or other code that manifests
                      contaminating or destructive properties. You are
                      responsible for implementing safeguards to protect the
                      security and integrity of your computer system, and you
                      are responsible for the entire cost of any service,
                      repairs or connections of and to your computer system that
                      may be necessary as a result of your use of the OMNICART
                      Platform.
                    </p>
                  </li>
                  <li>
                    <p className="text">
                      Communications Not Confidential. OMNICART does not
                      guarantee the confidentiality of any communications made
                      by you through the OMNICART Platform. Although OMNICART
                      generally adheres to the accepted industry practices in
                      securing the transmission of data to, from and through the
                      OMNICART Platform, you understand, agree and acknowledge
                      that OMNICART cannot and does not guarantee the security
                      of data transmitted over the Internet or public networks
                      in connection with your use of the OMNICART Platform.
                    </p>
                  </li>
                </ol>
              </ul>
              <li>
                <p className="text">
                  NO IMPLIED WARRANTIES; LIMITATIONS OF LIABILITY.
                </p>
              </li>
              <ul>
                <li>
                  <p className="text">
                    NO IMPLIED WARRANTIES. THE OMNICART SERVICES, THE OMNICART
                    PLATFORM AND THE CONTENT ARE PROVIDED "AS IS" WITHOUT
                    WARRANTY OR CONDITION OF ANY KIND. TO THE FULLEST EXTENT
                    PERMITTED BY LAW, OMNICART DISCLAIMS ALL WARRANTIES,
                    REPRESENTATIONS AND CONDITIONS OF ANY KIND WITH RESPECT TO
                    THE OMNICART SERVICES, OMNICART PLATFORM AND THE CONTENT
                    WHETHER EXPRESS, IMPLIED, STATUTORY OR COLLATERAL,
                    INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES AND
                    CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                    PURPOSE AND NON-INFRINGEMENT OR THAT THE OMNICART SERVICES,
                    THE OMNICART PLATFORM OR THE CONTENT ARE OR WILL BE
                    ERROR-FREE OR WILL OPERATE WITHOUT INTERRUPTION.
                  </p>
                </li>
                <li>
                  <p className="text">
                    EXCLUSION OF INDIRECT DAMAGES. IN NO EVENT WILL OMNICART OR
                    ANY MEMBER OF THE OMNICART GROUP BE LIABLE, WHETHER BASED ON
                    WARRANTY, CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR
                    ANY OTHER LEGAL THEORY, FOR ANY DAMAGES OF ANY KIND
                    (INCLUDING, WITHOUT LIMITATION, INDIRECT, INCIDENTAL,
                    CONSEQUENTIAL, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES, LOST
                    PROFITS, LOSS OF REVENUE, LOSS OF USE, LOSS OF DATA,
                    PERSONAL INJURY, FINES, FEES, PENALTIES OR OTHER
                    LIABILITIES), WHETHER OR NOT OMNICART IS ADVISED OF THE
                    POSSIBILITY OF SUCH DAMAGES, RESULTING FROM OR RELATED TO
                    THE USE OF, OR THE INABILITY TO MAKE USE OF, THE OMNICART
                    SERVICES, THE OMNICART PLATFORM OR THE CONTENT.
                  </p>
                </li>
                <li>
                  <p className="text">
                    NO RESPONSIBILITY FOR VENDORS AND COURIERS. VENDORS AND
                    COURIERS OFFERING GOODS OR SERVICES THROUGH THE OMNICART
                    PLATFORM ARE INDEPENDENT PERSONS OR ORGANIZATIONS AND NOT
                    REPRESENTATIVES, AGENTS OR EMPLOYEES OF OMNICART. OMNICART
                    IS THEREFORE NOT LIABLE FOR THE ACTS, ERRORS, OMISSIONS,
                    REPRESENTATIONS, WARRANTIES, CONTRACTUAL BREACHES OR
                    NEGLIGENCE OF ANY VENDORS OR COURIERS OR FOR ANY PERSONAL
                    INJURY, DEATH, PROPERTY DAMAGE, OR OTHER DAMAGES OR EXPENSES
                    RESULTING THEREFROM AND TAKES NO RESPONSIBILITY WHATSOEVER
                    FOR THE PRODUCTS OR SERVICES OFFERED BY VENDORS OR COURIERS.
                  </p>
                </li>
                <li>
                  <p className="text">
                    LIMITATION OF LIABILITY. TO THE EXTENT THAT THE FOREGOING
                    LIMITATIONS DO NOT APPLY, IN NO EVENT WILL THE TOTAL
                    AGGREGATE LIABILITY OF THE OMNICART GROUP IN CONNECTION WITH
                    OR UNDER THESE TERMS OF SERVICE, INCLUDING IN CONNECTION
                    WITH YOUR USE OF, OR INABILITY TO MAKE USE OF, THE OMNICART
                    SERVICES, THE OMNICART PLATFORM OR THE CONTENT EXCEED: (I)
                    THE FEES PAID BY YOU IN THE PRECEDING 4 MONTHS; OR (II) CDN
                    $100.00 (ONE HUNDRED CANADIAN DOLLARS), WHICHEVER IS LOWER.
                    FOR GREATER CERTAINTY, THE EXISTENCE OF ONE OR MORE CLAIMS
                    UNDER THESE TERMS OF SERVICE WILL NOT INCREASE THE MAXIMUM
                    LIABILITY AMOUNT.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Additional costs: You assume full and sole responsibility
                    for any additional or associated costs that you may incur in
                    connection with or as a result of your use of the OMNICART
                    Platform, including without limitation costs relating to the
                    servicing, repair or adaptation of any equipment, software
                    or data that you may own, lease, license or otherwise
                    use. THE LIMITATIONS ABOVE REFLECTS A FAIR ALLOCATION OF
                    RISK BUT FOR WHICH OMNICART WOULD NOT MAKE THE OMNICART
                    PLATFORM AND/OR OMNICART SERVICES AVAILABLE TO USERS. THE
                    LIMITATIONS SPECIFIED IN THIS SECTION WILL SURVIVE AND APPLY
                    EVEN IF ANY LIMITED REMEDY SPECIFIED IN THESE TERMS IS FOUND
                    TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
                  </p>
                </li>
              </ul>
              <li>
                <p className="text">
                  Indemnification <br /> You agree to defend, indemnify and hold
                  harmless OMNICART Group, its licensors and their directors,
                  officers, agents, contractors, partners, representatives and
                  employees from and against any threated or actual claims,
                  causes of action, demands, recoveries, losses, damages, fines,
                  penalties or other costs or expenses of any kind or nature
                  including but not limited to reasonable legal fees, incurred
                  in connection with or as a result of:
                </p>
              </li>
              <ul>
                <li>
                  <p className="text">
                    your breach of these Terms of Service or any documents
                    referenced herein;
                  </p>
                </li>
                <li>
                  <p className="text">
                    your violation of any law or the rights of a third party
                    (including, without limitation, intellectual property
                    rights);
                  </p>
                </li>
                <li>
                  <p className="text">
                    your breach of or failure to perform in respect of any
                    Orders made by you or by any third party acting on your
                    behalf or with your permission; or OMNICART reserves the
                    right, at its own expense, to assume the exclusive defence
                    and control of any matter otherwise subject to
                    indemnification by you hereunder, and you shall cooperate as
                    fully as reasonably required by OMNICART.
                  </p>
                </li>
              </ul>
              <li>
                <p className="text">Governing Law and Dispute Resolution</p>
              </li>
              <ul>
                <li>
                  <p className="text">
                    Governing Law. These Terms of Service will be governed by
                    the laws of the Province of Ontario, unless you access the
                    OMNICART Platform or receive the OMNICART Services in
                    Quebec, in which case the laws of the Province of Quebec
                    apply to the extent of such access or use of the OMNICART
                    Services, and the federal laws of Canada applicable therein.
                    These laws apply to your access to or use of the, the
                    OMNICART Platform, the OMNICART Services or the Content,
                    notwithstanding your domicile, residency or physical
                    location. The OMNICART Platform, the OMNICART Services and
                    the Content are intended for use only in jurisdictions where
                    they may lawfully be offered for use.
                  </p>
                </li>
                <li>
                  <p className="text">Dispute Resolution Process.</p>
                </li>
                <ol type="i">
                  <li>
                    <p className="text">
                      Except to the extent restricted by applicable law, if
                      there is any dispute or controversy between (1) you and
                      (2) OMNICART or any members of the OMNICART Group,
                      including any dispute or controversy arising out of or
                      relating to these Terms of Service, the OMNICART Platform
                      or OMNICART Services, any interactions or transactions
                      between (1) you and (2) OMNICART or any members of the
                      OMNICART Group, or in respect of any legal relationship
                      associated with or derived from these Terms of Service,
                      including the validity, existence, breach, termination,
                      construction or application, or the rights, duties or
                      obligations of you or us, (each, a "Dispute"), the
                      disputing person will serve notice on the other person and
                      each you and us must use good faith efforts to resolve the
                      Dispute informally.
                    </p>
                  </li>
                  <li>
                    <p className="text">
                      Except to the extent restricted by applicable law, if the
                      Dispute is not resolved after twenty (20) business days of
                      a person serving notice on the other party that there is a
                      Dispute, you and us agree that the Dispute will be finally
                      resolved by confidential arbitration before a single
                      arbitrator in accordance with the Arbitration Rules of the
                      ADR Institute of Canada, Inc. The seat of the arbitration
                      will be Ontario or wherever convenient or necessary acting
                      reasonably. There will be no appeals of any kind. The
                      language of the arbitration will be English unless
                      otherwise required by applicable law or agreed to by you
                      and us. OMNICART will pay the reasonable arbitration
                      costs.
                    </p>
                  </li>
                  <li>
                    <p className="text">
                      Except to the extent restricted by applicable law, you and
                      us agree that you and us will resolve any Dispute on an
                      individual basis. Any claim you may have must be brought
                      individually, in your individual capacity and not as a
                      representative plaintiff or class member, and you will not
                      join such claim with claims of any other person or entity,
                      or bring, join or participate in a class action lawsuit,
                      collective or representative proceeding of any kind
                      (existing or future) against any members of the OMNICART
                      Group.
                    </p>
                  </li>
                  <li>
                    <p className="text">
                      Nothing in this Section will prohibit us from seeking
                      interim measures from a court, including preliminary or
                      injunctive relief of breach of you of any intellectual
                      property rights.
                    </p>
                  </li>
                </ol>
              </ul>
              <li>
                <p className="text">Miscellaneous</p>
              </li>
              <ul>
                <li>
                  <p className="text">
                    Interpretation. Unless the context clearly requires
                    otherwise, (a) references to the plural include the
                    singular, the singular the plural, the part the whole, (b)
                    references to any gender include all genders, (c)
                    “including” has the inclusive meaning frequently identified
                    with the phrase “but not limited to” and (d) references to
                    “hereunder” or “herein” relate to this Agreement. The
                    section headings in this Agreement are for reference and
                    convenience only and shall not be considered in the
                    interpretation of these Terms of Service.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Entire Agreement. If you are a Customer, these Terms of
                    Service, the Privacy Policy and any documents incorporated
                    by reference herein constitute the entire agreement between
                    OMNICART and you pertaining to the subject matter hereof and
                    supersede all prior or contemporaneous communications and
                    proposals, whether electronic, oral or written, between you
                    and OMNICART with respect to the OMNICART Platform. A
                    printed version of these Terms of Service and of any notice
                    given in electronic form will be admissible in judicial or
                    administrative proceedings based upon or relating to these
                    Terms of Service to the same extent and subject to the same
                    conditions as other business documents and records
                    originally generated and maintained in printed form.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Notices. OMNICART may, in its sole discretion, provide any
                    notices to you in connection with these Terms of Service
                    through the OMNICART Platform or by email at the
                    then-current email address for you on file with OMNICART.
                    OMNICART may be contacted in writing at: DINE EASY Holdings
                    Inc. 301 - 134 Abbott St Vancouver, BC V6B 2K4 or
                    contact@omnicart.tech <br />
                     OMNICART may change its notice contact information from
                    time to time by posting updated contact details on the
                    OMNICART Platform.
                  </p>
                </li>
                <li>
                  <p className="text">
                    No Waiver. OMNICART's failure to insist upon or enforce
                    strict performance of any provision of these Terms of
                    Service will not be construed as a waiver of any provisions
                    or right.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Force Majeure. OMNICART will not be liable to you for any
                    failure of or delay in the performance of its obligations
                    under these Terms of Service for the period that such
                    failure or delay is due to causes beyond OMNICART's
                    reasonable control, including but not limited to acts of
                    God, power outages, internet disconnectivity, war, strikes
                    or labor disputes, embargoes, government orders or any other
                    force majeure event.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Severability. If any of the provisions contained in these
                    Terms of Service are determined to be void, invalid or
                    otherwise unenforceable by a court of competent
                    jurisdiction, the provision shall be modified by the court
                    and interpreted so as best to accomplish the objectives of
                    the original provision to the fullest extent permitted by
                    law, and the remaining provisions of this Agreement shall
                    remain in effect.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Order of Precedence. If any of the provisions contained in
                    these Terms of Service conflict with the terms of another
                    agreement between you and us, then these Terms of Service
                    will prevail; provided that, if you are also a Vendor
                    registered on the OMNICART Platform, the terms applicable to
                    the Vendor agreement will govern your relationship with
                    OMNICART in connection with your use of the OMNICART
                    Platform as a Vendor offering its services.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Language. These Terms of Services are made available in
                    English and in French for French speaking Users. In the
                    event of a conflict between this English language version of
                    the Terms of the OMNICART Services and the French language
                    version of the Terms of Service, this English language
                    version shall prevail except for users in Quebec for whom
                    the French language version will prevail. En cas
                    d’incompatibilité entre la présente version anglaise des
                    conditions du site et sa version française, cette version
                    anglaise prévaudra.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Assignment. You may not transfer any of your rights or
                    obligations under these Terms of Service without our prior
                    written consent. We may transfer any of our rights or
                    obligations under these Terms of Service without your prior
                    written consent to any of our affiliates or any business
                    that we enter into a joint venture with, purchase or are
                    sold to.
                  </p>
                </li>
              </ul>
            </ol>
            <li>
              <p className="text">ORDERING TERMS</p>
            </li>
            <ol type="1">
              <li>
                <p className="text">
                  Access, OMNICART Services and Products Through the OMNICART
                  Platform you may place orders for Goods from Vendors, you may
                  receive delivery of the Goods and OMNICART may provide
                  customer care support between you, Couriers and Vendors (these
                  services and associated services such as order management,
                  payment processing for Orders and provision of access to the
                  OMNICART Platform provided by OMNICART from time to time are
                  the "OMNICART Services"). For avoidance of doubt, OMNICART
                  does not prepare or fulfil any orders for Goods or provide
                  delivery services directly and no members of the OMNICART
                  Group will be liable for any transactions between Customers,
                  Couriers or Vendors. OMNICART may provide products or services
                  to Couriers or Vendors through a separate agreement executed
                  with such parties. Menus and other Vendor-related information
                  on the OMNICART Platform is content provided by the Vendor and
                  not OMNICART.
                </p>
              </li>
              <li>
                <p className="text">
                  OMNICART Customer Account To use the OMNICART Platform and
                  most features of the OMNICART Services, you are required to
                  successfully create a user account (the "OMNICART Customer
                  Account") using the available interfaces at the OMNICART
                  Platform.
                </p>
              </li>
              <li>
                <p className="text">Placing Orders</p>
              </li>
              <ul>
                <li>
                  <p className="text">
                    Once you have created your OMNICART Customer Account you may
                    select Goods from a Vendor’s menu, choose your desired
                    quantities, and then add them to your cart. Your cart may be
                    edited at any time prior to placing an Order. Once you have
                    made your selection, you may proceed to place your Order. At
                    this time you will have the opportunity to review your cart
                    items. After you review and confirm that the items in your
                    cart are correct, you may proceed to place your Order,
                    provide your payment card information or select the cash
                    payment option, if available, and an Order will be created
                    and sent to the Vendor. You are responsible to ensure that
                    all of your Order details, including billing, delivery
                    address and other information is current, complete and
                    accurate. Your payment card will be charged when you place
                    your Order if the Order is a Card Order (as defined in
                    section 6(b)). Once you have placed your Order, you will not
                    be entitled to change or cancel your Order and you will not
                    be entitled to a refund (except where required by law).
                    Notwithstanding that you are not entitled as of right to
                    change or cancel your Order or be entitled to a refund
                    (except where prohibited by law), if you wish to amend or
                    cancel your Order for a total or partial refund of your
                    Order’s price, you must get in touch with OMNICART via chat,
                    email or the OMNICART support phone line at the contact
                    information provided on the OMNICART Platform.
                  </p>
                </li>
                <li>
                  <p className="text">
                    We encourage Vendors to confirm all Orders and to
                    communicate any inability or unwillingness to confirm
                    promptly, and we will notify you as soon as reasonably
                    practicable if the Vendor does not confirm your Order.
                    Vendor's may have the discretion to not confirm Orders. For
                    Card Orders (as defined in section 6(b) below), OMNICART
                    will provide you with a refund of the price of your Order
                    after communicating to you the non- confirmation or
                    cancellation of your Order by the Vendor.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Because of standard banking procedures, once you have placed
                    a Card Order and your payment has been authorised, your bank
                    or card issuer will "ring-fence" the full amount of your
                    Order. If your Order is not confirmed by the Vendor or
                    cancelled for any other reason, your bank or card issuer
                    will not transfer the funds for the Order to us, and will
                    instead release the relevant amount back into your available
                    balance. However, this may take a period of typically up to
                    5 working days (and in some cases up to 30 days, depending
                    on your bank or card issuer). You acknowledge and agree that
                    no members of the OMNICART Group nor the relevant Vendor
                    will be responsible or liable to you in relation to this
                    delay by your bank or card issuer in the release of funds
                    back into your account.
                  </p>
                </li>
                <li>
                  <p className="text">
                    You agree that OMNICART or the Vendor will have no liability
                    to you for Orders that are not confirmed, are cancelled or
                    that the Vendor has been unable or unwilling to fulfill.
                  </p>
                </li>
              </ul>
              <li>
                <p className="text">
                  Customer Care, Goods Contract, Third Party Couriers and
                  Refunds
                </p>
              </li>
              <ul>
                <li>
                  <p className="text">
                    OMNICART may offer customer care in respect of issues
                    relating to the Vendors and the Couriers. If you have any
                    problems, questions or suggestions with respect to the
                    OMNICART Platform or your Order, please contact OMNICART’s
                    customer care team (and not the Vendor or Courier) via chat
                    (preferable), email or phone at the contact information
                    provided on the OMNICART Platform.
                  </p>
                </li>
                <li>
                  <p className="text">
                    The legal contract for the purchase of Goods will in all
                    cases be between the Customer and the Vendor. You
                    irrevocably appoint and authorize us to act as your sole and
                    exclusive agent for the purpose of concluding contracts for
                    the sale of Goods between you and the Vendor by means of you
                    placing Orders via the OMNICART Platform. We may act as
                    agent for the Customer and the Vendor for the legal contract
                    for the purchase and sale of the Goods. YOU ACKNOWLEDGE AND
                    AGREE THAT NO MEMBER OF THE OMNICART GROUP HAS ANY CONTROL
                    OVER THE QUALITY OF THE GOODS OR SERVICES OFFERED BY VENDORS
                    AND COURIERS AND THAT NO MEMBERS OF THE OMNICART GROUP WILL
                    HAVE LIABILITY TO YOU FOR ANY PROBLEMS CAUSED BY THE VENDOR
                    CONCERNING YOUR ORDERS, INCLUDING, WITHOUT LIMITATION,
                    MISSED OR LATE DELIVERIES, MISSED GOODS ITEMS, AND ANY
                    PROBLEMS WITH RESPECT TO THE QUALITY OF THE GOODS DELIVERED
                    INCLUDING IF GOODS CAUSE ILLNESS, ALLERGIC REACTIONS OR HAVE
                    INCORRECT NUTRITIONAL VALUES.
                  </p>
                </li>
                <li>
                  <p className="text">
                    For Delivery Orders that the Vendor arranges or for which
                    third party couriers provide delivery services, we are not
                    liable for any service or product provided by such couriers.
                  </p>
                </li>
                <li>
                  <p className="text">
                    OMNICART may provide, in its discretion, the refund or
                    compensation by payment to your payment credit or debit card
                    or providing the Customer with OMNICART Credits (as defined
                    below).
                  </p>
                </li>
              </ul>
              <li>
                <p className="text">
                  Payment Processing Services Payment processing services for
                  the OMNICART Platform are provided by payment services
                  providers as OMNICART may choose from time to time
                  (collectively referred to as the "Payment Processing Services
                  Provider"). You hereby authorize OMNICART to share with the
                  Payment Processing Services Provider that financial
                  information, banking details and transaction information
                  related to your use of the payment processing services and the
                  OMNICART Platform. You also agree that the OMNICART Group is
                  not responsible for the use or misuse of such financial
                  information, banking details and transaction information by
                  the Payment Processing Services Provider or any third party.
                </p>
              </li>
              <li>
                <p className="text">Price and Payment</p>
              </li>
              <ul>
                <li>
                  <p className="text">
                    Goods prices listed on the OMNICART Platform from Vendor in
                    Canada are in Canadian dollars, Good prices listed on the
                    OMNICART Platform from a Vendor in the United States are in
                    United States dollars. Good prices may not include
                    applicable tax and delivery charges. Taxes and delivery
                    charges may be added at checkout and will be payable by you
                    along with the Order price.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Payment for Orders must be made with an accepted credit or
                    debit card through the OMNICART Platform ("Card Orders") or
                    in certain circumstances may be made by cash directly to the
                    Vendor or courier ("Cash Orders"). If you choose a Cash
                    Order, you must pay the Vendor or courier, as applicable and
                    are liable for failure to do so.
                  </p>
                </li>
                <li>
                  <p className="text">
                    For Card Orders, you will be charged at the time of placing
                    your Order. The OMNICART Platform uses the third party
                    payment processors to effect charges onto your debit or
                    credit card. You consent to the collection and use of your
                    information (including, if applicable, personal information)
                    by such payment processing service as necessary to process
                    your payments. We reserve the right to change, or to stop
                    accepting, any permitted payment method at any time in our
                    sole discretion. You agree we may charge your payment card
                    for any order placed and for any additional amounts
                    (including any taxes) as may be applicable in connection
                    with your purchase. You are responsible to ensure that all
                    of your billing information is current, complete, and
                    accurate. We will provide you with an online or emailed
                    billing summary statement which you may review, save, or
                    print at your discretion. This is the only billing statement
                    that will be provided by us.
                  </p>
                </li>
                <li>
                  <p className="text">
                    OMNICART or the Vendor is under no obligation to fulfill
                    your Order at the incorrect price. Prices at the Vendor’s
                    Goods in the Vendor’s physical location and at the OMNICART
                    Platform may be different. Any such differences will not
                    entitle you to a refund or a reduction in price of your
                    Order.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Goods’ prices, delivery charges and any other fees are
                    subject to change at any time at OMNICART’s or the Vendor’s
                    discretion.
                  </p>
                </li>
              </ul>
              <li>
                <p className="text">
                  Pickup or Delivery  <br /> You may have the ability to choose
                  if you would like your Order to be picked up by you at the
                  premise of the Vendor ("Pickup Order") or delivered to you by
                  a third party courier or courier of the Vendor ("Delivery
                  Order"). You may be provided a time for when the Pickup Order
                  will be ready for pickup or when the Delivery Order will be
                  delivered. These times are only an estimate and OMNICART and
                  the Vendor offer no guarantee that these times will be
                  achieved. OMNICART is not responsible for any delays in
                  receiving or having your Order ready for any reason. For a
                  Pickup Order, you will arrive at the time provided and
                  OMNICART and the Vendor are under no obligation to remake or
                  refund the Goods if you do not arrive when indicated.
                </p>
              </li>
              <li>
                <p className="text">
                  Alcohol <br /> If you order alcohol, you warrant that you are
                  of legal age to purchase and consume alcohol in the area in
                  which the alcohol is delivered and are not ordering alcohol
                  for any person below the legal age in the applicable
                  jurisdiction. You acknowledge and agree that Vendors or
                  Couriers may require valid government-issued photo
                  identification(s) matching the name on the order and proving
                  your age to the Vendor or Courier, and that the recipient is
                  not intoxicated when receiving delivery. If you are unable to
                  meet these conditions to the satisfaction of the Vendor or the
                  courier then alcohol products may be withheld, and any refund
                  for such withholding is at the discretion of the Vendor,
                  OMNICART or Courier. If the you order alcohol and the alcohol
                  is to be delivered, the delivery address must be a residential
                  or private addresses. For greater certainty, alcohol orders
                  may be subject to time and location restrictions.
                </p>
              </li>
            </ol>
            <li>
              <p className="text">
                PROMOTIONAL VOUCHERS, REFER-A-FRIEND PROGRAM, GIFT CARDS AND
                OMNICART CREDITS AND CONTESTS
              </p>
            </li>
            <ol type="1">
              <li>
                <p className="text">
                  romotional Vouchers The following terms and conditions will
                  apply to all vouchers issued for free from time to time for
                  promotional, advertising, or marketing purposes for use on
                  Orders on the OMNICART Platform ("Promotional Vouchers"):
                </p>
              </li>
              <ul>
                <li>
                  <p className="text">
                    Promotional Vouchers may only be redeemed towards Orders on
                    the OMNICART Platform and cannot be used for Goods that
                    include alcohol and may not applied to taxes or other
                    items/fees/charges as determined from time to time by us.
                    Promotional Vouchers may have a specific dollar amount, may
                    provide a percentage-based discount, or may have other
                    effects (such as making a charitable donation). The
                    particular terms of a Promotional Voucher (for example, the
                    amount of any dollar or percentage discount, number of times
                    the Promotional Voucher can be used, expiry date, area
                    Promotional Voucher can be use, whether applicable for
                    Pickup Orders or Delivery Orders) any other rules that may
                    apply are described in the information accompanying or in
                    conjunction with the Promotional Voucher (for example, the
                    social media post or advertisement where the Promotional
                    Voucher was posted).
                  </p>
                </li>
                <li>
                  <p className="text">
                    Promotional Vouchers must be used in conjunction with a
                    OMNICART Customer Account. Promotional Vouchers must be
                    applied to an online Order by no later than the deadline
                    specified on the Promotional Voucher and will expire if not
                    applied by this time. If the Promotional Voucher provides
                    for a discount on an Order, an amount determined by the
                    rules of the Promotional Voucher will be credited to the
                    Order to which the Promotional Voucher is applied.
                  </p>
                </li>
                <li>
                  <p className="text">
                    The right to use a Promotional Voucher is personal to the
                    original recipient and may not be transferred. No
                    Promotional Voucher may be copied, reproduced, distributed,
                    or published directly or indirectly in any form or by any
                    means for use by an entity other than the original
                    recipient, or stored in a data retrieval system, without our
                    prior written permission. For greater certainty, Promotional
                    Vouchers distributed or circulated without our written
                    approval, for example on an Internet message board or on a
                    "bargains" website, are not valid for use and may be refused
                    or canceled.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Promotional Vouchers may not be used in conjunction with
                    other vouchers or any other discounts or promotions provided
                    or advertised from time to time unless otherwise stated.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Promotional Vouchers may not be exchanged, redeemed, or
                    refunded for cash, and may not be transferred or assigned.
                  </p>
                </li>
                <li>
                  <p className="text">
                    No members of the OMNICART Group will not be liable to you,
                    any customer or household for any loss or claim arising out
                    of the refusal, rejection, cancellation or withdrawal of any
                    Promotional Voucher or any failure or inability of a
                    customer or household to use a Promotional Voucher for any
                    reason.
                  </p>
                </li>
                <li>
                  <p className="text">
                    For greater certainty, we reserve the right, at any time and
                    in our sole discretion, to add to or amend terms and
                    conditions (including these Terms of Service) in relation to
                    the use of Promotional Vouchers or to vary or terminate the
                    operation of a Promotional Voucher at any time without
                    notice.
                  </p>
                </li>
              </ul>
              <li>
                <p className="text">Refer-A-Friend Program</p>
              </li>
              <ul>
                <li>
                  <p className="text">
                    If you are a OMNICART Customer Account holder, you may be
                    entitled to voluntarily participate in the OMNICART refer a
                    friend program (the "Referral Program"). A unique
                    alphanumeric code is assigned to your OMNICART Customer
                    Account, referred to as your personalized referral code
                    ("Referral Code"). To participate in the Referral Program,
                    you will need to refer friends who have never placed an
                    order with us ("Friends") and invite your Friends to use the
                    OMNICART Platform with your Referral Code.
                  </p>
                </li>
                <li>
                  <p className="text">
                    You will earn OMNICART credit ("Referral OMNICART Credit")
                    in the amount we determined from time to time once your
                    Friend ("Referred User") has completed an eligible order on
                    the OMNICART Platform ("Eligible Order"). Criteria for
                    Eligible Orders is determined in our sole discretion from
                    time to time. Referral Credit will be applied automatically
                    against your item subtotal on your next Card Order, up to
                    and including the item subtotal before taxes, tip, and
                    delivery fee. If any credit happens to remain after being
                    applied to the order, that credit will remain on your
                    account. Referral Credits are subject to expiration and must
                    be used within the timeframe, if stated or otherwise
                    determined by us from time to time. The amount of Referral
                    Credits earned and redeemed are subject to change at any
                    time at OMNICART’s sole discretion.
                  </p>
                </li>
                <li>
                  <p className="text">
                    The Referral Code may only be used for personal and
                    non-commercial purposes. You may share your code with your
                    personal connections via social media, email, or word of
                    mouth, where you are the primary content owner. In cases
                    where you are not the primary owner of the content or
                    website (e.g., Wikipedia, coupon websites, affiliate
                    websites, discussion forums, etc.), public distribution is
                    not allowed. Promoting or advertising your Referral Code via
                    search engine marketing or any other paid advertising media
                    (e.g., Bing/Yahoo/Adwords) is not allowed. You are not
                    allowed to misrepresent your relationship with any members
                    of the OMNICART Group or with the OMNICART Services to any
                    third parties, and you will not make any warranty or
                    representation on behalf of any members of the OMNICART
                    Group. You are not allowed to refer yourself or members of
                    your household for the purposes of earning Referral Credit.
                  </p>
                </li>
                <li>
                  <p className="text">
                    OMNICART reserves the right, at its sole discretion, to
                    deactivate your account(s) and revoke the Referral Credits
                    of any account holder who it finds or believes to be earning
                    or using Referral Credits against these Terms of Service, or
                    who is manipulating or abusing the OMNICART Services or the
                    OMNICART Platform.
                  </p>
                </li>
                <li>
                  <p className="text">
                    For greater certainty, we reserve the right, at any time and
                    in our sole discretion, to add to or amend these Terms of
                    Service in relation to the use of Referral Program or to
                    vary or terminate the operation of a Referral OMNICART
                    Credit issued pursuant to the Referral Program at any time
                    without notice.
                  </p>
                </li>
              </ul>
              <li>
                <p className="text">Gift Card Terms and OMNICART Credits</p>
              </li>
              <ul>
                <li>
                  <p className="text">
                    OMNICART may grant you credit toward eligible purchases as
                    set out in these terms ("Eligible Purchases") in the form of
                    OMNICART gift cards ("Gift Cards") or OMNICART credit
                    ("OMNICART Credit"). When you purchase a Gift Card, you are
                    purchasing credit toward Eligible Purchases in a particular
                    dollar amount and currency that is stored in an electronic
                    form and linked to a unique gift card claim code ("Claim
                    Code"). To use a Gift Card, you must have a OMNICART
                    Customer Account, log in to your OMNICART Customer Account,
                    and apply the Gift Card to your OMNICART Customer Account by
                    entering the Claim Code on the OMNICART Platform. OMNICART
                    Credit is credit toward Eligible Purchases in a particular
                    dollar amount that is associated with a unique OMNICART
                    Customer Account. The value of OMNICART Credit including
                    credit from Gift Cards applied to your OMNICART Customer
                    Account is referred to as your "OMNICART Credit Balance".
                    When you apply a Gift Card to your OMNICART Customer
                    Account, your OMNICART Credit Balance will be credited by
                    the dollar value of the Gift Card, and the value associated
                    with the Claim Code will be reduced to zero. From time to
                    time, OMNICART may also issue OMNICART Credit to you for
                    other purposes (for example, to refund a purchase that you
                    have made). Your OMNICART Credit Balance includes the total
                    remaining balance of all OMNICART Credit that has been
                    applied to your OMNICART Customer Account by way of Gift
                    Card or otherwise, but not yet applied to a purchase. To
                    check your OMNICART Credit Balance, log in to your OMNICART
                    Customer Account. To check the balance of a Gift Card before
                    it has been applied to a OMNICART Customer Account, simply
                    review the email or other document in which you received the
                    Gift Card, where the face value will be printed.
                  </p>
                </li>
                <li>
                  <p className="text">
                    The Eligible Purchases that you may purchase using Gift
                    Cards and OMNICART Credit consist of Goods, including all
                    fees and costs on the Order for those Goods which you are
                    permitted to purchase in accordance with these Terms of
                    Service. OMNICART reserves the right to restrict the use of
                    Gift Cards and OMNICART Credit to comply with applicable
                    law. Gift Cards and OMNICART Credit cannot be used to
                    purchase anything other than Eligible Purchases and must be
                    used in the currency in which they were paid or credited
                    for.
                  </p>
                </li>
                <li>
                  <p className="text">
                    When you make a purchase from your OMNICART Customer Account
                    using OMNICART Credit (including OMNICART Credit from a Gift
                    Card), the value of the OMNICART Credit that you use for
                    that purchase will be deducted from your OMNICART Credit
                    Balance. As long as you are only purchasing Eligible
                    Purchases, you may use OMNICART Credit to pay for all fees
                    and costs on your Order, including but not limited to the
                    cost of Goods, delivery charges, tips, and taxes. Any unused
                    OMNICART Credit Balance will remain associated with your
                    OMNICART Customer Account. If the cost of an Order exceeds
                    your OMNICART Credit Balance, the remaining amount must be
                    paid with another payment method.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Gift Cards and OMNICART Credit do not expire. There are no
                    fees associated with purchasing, using, or redeeming Gift
                    Cards or OMNICART Credit.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Gift Cards and OMNICART Credit cannot be resold (except with
                    the express written permission of OMNICART), used for
                    payment outside the Eligible Purchases, transferred for
                    value, returned for a cash refund, or redeemed for cash,
                    except to the extent required by applicable law. Gift Cards
                    cannot be reloaded. No portion of your OMNICART Credit
                    Balance may be transferred to another OMNICART Customer
                    Account or otherwise assigned or transferred to another
                    person. OMNICART Credit cannot be used in conjunction with
                    Cash Orders.
                  </p>
                </li>
                <li>
                  <p className="text">
                    The risk of loss and title for Gift Cards pass to the
                    purchaser upon our electronic transmission of the Gift Card
                    to the purchaser or designated recipient. OMNICART is not
                    responsible if any Gift Card is lost, stolen or destroyed,
                    if your OMNICART Credit Balance or any Gift Card is used
                    without your permission, or if you lose access to your
                    OMNICART Customer Account or any OMNICART Credits.
                  </p>
                </li>
                <li>
                  <p className="text">
                    Gift Cards and OMNICART Credit may be subject to any
                    additional terms and conditions that will be specified in
                    connection with the specific Gift Card and OMNICART Credit
                    grant.
                  </p>
                </li>
                <li>
                  <p className="text">
                    OMNICART has the right to void Gift Cards or OMNICART
                    Credit, close OMNICART Customer Accounts and bill
                    alternative forms of payment if it suspects that a Gift Card
                    is obtained, used or applied to a OMNICART Customer Account
                    (or OMNICART Credit is applied to a purchase) fraudulently,
                    unlawfully or otherwise in violation of these Terms of
                    Service, or in a manner that abuses the OMNICART Platform.
                  </p>
                </li>
                <li>
                  <p className="text">
                    For greater certainty, to the extent permitted by law, we
                    reserve the right, at any time and in our sole discretion,
                    to add to or amend terms and conditions (including these
                    Terms of Service) in relation to the use of Gift Cards or
                    OMNICART Credit
                  </p>
                </li>
              </ul>
              <li>
                <p className="text">Contests/Competitions</p>
              </li>
              <ul>
                <li>
                  <p className="text">
                    From time to time, OMNICART may run contests or competitions
                    ("Contests"). Those Contests will be subject to these Terms
                    of Service, as well as rules specific to each Contest
                    ("Contest Rules").
                  </p>
                </li>
                <li>
                  <p className="text">
                    In general, Contests for Canadian users will be open to
                    individual residents of Canada over the age of majority in
                    the province or territory in which they reside, with the
                    exception of:
                  </p>
                </li>
                <ol type="i">
                  <li>
                    <p className="text">
                      employees, representatives and agents (and persons with
                      whom they are domiciled, whether related or not) of
                      OMNICART Group, its advertising and promotion agencies;
                      and
                    </p>
                  </li>
                  <li>
                    <p className="text">
                      residents of Quebec, unless specified in the Contest
                      Rules.
                    </p>
                  </li>
                </ol>
                <li>
                  <p className="text">
                    The Contest Rules may specify other eligibility rules.
                  </p>
                </li>
                <li>
                  <p className="text">
                    For greater certainty, subject to applicable law, we reserve
                    the right, at any time and in our sole discretion, to
                    withdraw any Contest, to add to or amend terms and
                    conditions (including these Terms of Service) in relation to
                    any Contest or to vary or terminate the operation of a
                    Contest at any time without notice.
                  </p>
                </li>
              </ul>
            </ol>
          </ol>
        </StyledCard>
      </PrivacyContainer>
    </>
  );
};

export default PwaDeliveryTerms;
