import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME || ''}>
      <App />
    </BrowserRouter>
  ),
  document.getElementById('root'),
);

serviceWorker.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      if (window.confirm('New version available! Refresh to update your app?')) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        window.location.reload();
      }
    }
  },
});

// serviceWorker.registerPushSw()

// if ('serviceWorker' in navigator) {
//     const swUrl = `${process.env.PUBLIC_URL}/pushServiceWorker.js`;

//     navigator.serviceWorker.register(swUrl)
// }
