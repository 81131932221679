import * as orderStatus from "../../../utils/status";

import React, { useContext, useEffect, useState } from "react";
import { calcSomeDaysAgoDate, formatDate, getData } from "../../../utils";

import { ReactComponent as ArrowDownIcon } from "assets/image/arrowDown.svg";
import { ReactComponent as CrossIcon } from "assets/image/cross.svg";
import CustomPagination from "components/CustomPagination";
import { ModalContext } from "../../../context/modalContext";
import PaginateTable from "components/PaginateTable";
import RangeCalendar from "components/RangeCalendar";
import { StyledTableModal } from "./styles";
import { ReactComponent as TickIcon } from "assets/image/tick.svg";
import { getLatestOrders } from "../../../api/orders";
import moment from "moment";
import { useDispatch } from "react-redux";
import usePagination from "../../../hooks/usePagination";
import { useSelector } from "react-redux";

const Orders = ({
  selectedRestaurantId,
  ordersStartDate,
  ordersEndDate,
  formatDateToTableDate,
  getOrdersFilter,
  rowCreators,
}) => {
  const [ordersData, setOrdersData] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
  const [ordersLoading, setOrdersLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    getOrders();
  }, [selectedRestaurantId, ordersStartDate, ordersEndDate]);

  const getOrders = (page) => {
    if (ordersStartDate) {
      setOrdersLoading(true);
      setOrdersData([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
      let queries = {
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        ordering: `-is_new,-timestamp`,
        start: ordersStartDate,
        end: ordersEndDate,
        page,
      };
      dispatch(getLatestOrders(selectedRestaurantId, queries))
        .then((res) => {
          setOrdersData(getData(res)?.results);
          setPagination &&
            setPagination({
              count: getData(res)?.count,
              next: getData(res)?.next,
              previous: getData(res)?.previous,
            });
          setOrdersLoading(false);
        })
        .catch((err) => {
          setOrdersLoading(false);
        });
    }
  };

  const { page, setPage, pagination, setPagination, paginationEl } =
    usePagination(getOrders, ordersLoading);

  return (
    <div>
      <StyledTableModal>
        <div className="title">Orders · {getOrdersFilter()}</div>
        <PaginateTable
          getData={getOrders}
          loading={ordersLoading}
          headers={[
            "Payment date",
            "status",
            "Order number",
            "Merchant net",
            "Tax",
            "Payment Fees",
            "Application Fees",
          ]}
          dataArray={ordersData}
          dataPaging={pagination}
          rowCreators={rowCreators}
          detailed={true}
          pagination={paginationEl}
        />
      </StyledTableModal>
    </div>
  );
};

export default Orders;
