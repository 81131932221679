import React, {useReducer} from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import styled from 'styled-components'
import {connect} from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileUpload } from '@fortawesome/free-solid-svg-icons'

import { Colors } from '../../assets/colors'
import { IMAGE_VALID_FORMATS } from '../../constants'

const ImageForm = styled(Form.Group)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Image = styled.img`
  max-width: 100%;
  width: 400px;
  object-fit: cover;
`


const Error = (props) => (
  !props.text
    ? <Form.Label style={{ color: 'red' }}>{props.locale.field_cannot_be_empty}</Form.Label>
    : <Form.Label style={{ color: 'red' }}>{props.text}</Form.Label>
)

const StyledA = styled.a`
  position: absolute;
  bottom: 0;
  width:400px;
  height: 35px;
  background-color: #0005;
  bottom: 0;
  color: ${Colors.white} !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  align-item:center;
  justify-content: center;
  font-weight: bold;
`

const CustomImage = ({locale, ...props}) => {
  let fileUpload = React.createRef();
  const [state, setState] = useReducer((state, newState) =>
    ({...state, ...newState}),
    {errors: []}
  )

  const handleChange = (e) => {
    const { value, name, files } = e.target
    const reader = new FileReader();
    if (files) {
      const url = reader.readAsDataURL(files[0]);
      const filesize = ((files[0].size/1000)).toFixed(4); // KB
      const validFileType = IMAGE_VALID_FORMATS.includes((files[0].type).split('image/'))
      reader.onloadend = function (e) {
        setState({ [name + 'File']: files[0], validImageFileType: validFileType, imageFileSize: filesize, [name]: reader.result })
        props.onChange({ [name + 'File']: files[0], validImageFileType: validFileType, imageFileSize: filesize, [name]: reader.result })
      }
    }
  }
  return (
    <>
      <ImageForm className="row">
        {
          state[props.name]
            ? <>
              <Image src={state[props.name]} width={100} height={100} />
              <StyledA onClick={() => fileUpload.click()}>
                <FontAwesomeIcon icon={faFileUpload} className="mx-3" />
                Click to change item Image
              </StyledA>
            </>
            : <a className="btn" onClick={() => fileUpload.click()}>
              <FontAwesomeIcon icon={faFileUpload} className="mx-3" />
              Click to upload item Image
          </a>
        }
        <Col sm={12} className='text-center'>
          <Form.Control
            type="file"
            name={props.name}
            placeholder="Item Image"
            onChange={handleChange}
            ref={el => fileUpload = el}
            style={{ display: 'none' }}
          />
        </Col>
      </ImageForm>
      {state.errors.includes('imageFile') && <Error locale={locale} />}
      {state.errors.includes('imageFileSize') && <Error locale={locale} text={locale.image_size_error}/>}
      {state.errors.includes('validImageFileType') && <Error locale={locale} text={locale.image_type_error}/>}
    </>
  )
}

export default connect(state => ({locale: state.locale[state.locale.active]}))(CustomImage)
