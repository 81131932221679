import { faFileUpload, faSearch, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, InputGroup, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useDropzone } from "react-dropzone";
import ReactJoyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from "react-joyride";
import { connect } from "react-redux";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import CreatableSelect from "react-select/creatable";
import styled from "styled-components";

import { makeAlert } from "actions";
import { addImages, editImages, fetchImages, removeImages, uploadImage } from "api/images";
import { addIngredient, addItem, deleteItem, editIngredient, editItem, getDiets, getIngredients, getItemById, getItemsByRestaurantId } from "api/items";
import { Colors } from "assets/colors";
import CustomButton from "components/button";
import withMaxLength from "components/forms/withMaxLength";
import Loading from "components/Loading";
import ModalBody from "components/modal/StyledModalBody";
import { colors, IMAGE_VALID_FORMATS, UNDO_PERIOD } from "constants";
import { parseString } from "utils";

import { getData } from "../../utils";
import SelectImage from "./SelectImage";

const Image = styled.img`
  max-width: 100%;
  width: 400px;
  object-fit: cover;
`;
const StyledA = styled.a`
  position: absolute;
  bottom: 0;
  width: 400px;
  height: 35px;
  background-color: #0005;
  bottom: 0;
  color: ${Colors.white} !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  align-item: center;
  justify-content: center;
  font-weight: bold;
`;
const Item = styled.button`
  background: ${Colors.white};
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid #bdbbbb;
  display: flex;
  height: 130px;
  margin: 0 10px;
  margin-bottom: 20px;
  padding: 10px;
  color: #373636;
  flex: 1 0 21%;
  /* max-width: 21%; */

  .title {
    font-weight: bold;
    text-align: left;
    padding-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }

  .price {
    font-weight: bold;
    margin: 5px 0;
    padding-bottom: 5px;
  }

  .description {
    font-size: 15px;
    font-size: 15px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  img {
    height: 90px;
    object-fit: cover;
    width: 100%;
    border-radius: 5px;
  }
`;

export const Error = (props) => {
  const color = props.type !== "info" ? "red" : "inherit";
  return !props.text ? (
    <Form.Label style={{ color }}>
      {props.locale.field_cannot_be_empty}
    </Form.Label>
  ) : (
    <Form.Label style={{ color }}>{props.text}</Form.Label>
  );
};

const ImageForm = styled(Form.Group)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledModalBody = styled(ModalBody)`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const StyledLabel = styled(Form.Label)`
  font-weight: bold;
  display: block;
`;
const StyledInputGroup = styled(InputGroup)`
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.14);
  border-radius: 25px;

  .input-group-text {
    border: none;
    background-color: transparent;
  }

  .form-control {
    border: none;
    border-radius: 25px;
  }
`;
const CustomRow = styled(Row)`
  min-height: 300px;
`;
const DropZone = styled.div`
  border: 1px solid #666;
  background-color: #eee;
  padding: 25px;
  border-radius: 5px;
`;
const ModalHeader = styled(Modal.Header)`
  border: none;
  position: absolute;
  right: 10px;
  z-index: 99999;
`;

const initialForm = {
  name: "",
  value: "",
  value_label: "",
  value2: null,
  value2_label: "",
  value3: null,
  value3_label: "",
  value4: null,
  value4_label: "",
  images: [],
  description: "",
  item_ingredient: [],
  item_diet: [],
  category: [],
  validImageFileType: true,
};

const ItemModal = ({ locale, ...props }) => {
  const [form, setForm] = useState(initialForm);
  const [initial, setInitial] = useState(initialForm);
  const [createItemStep, setCreateItemStep] = useState("");
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState([]);
  const [steps, setSteps] = useState([]);
  const [run, setRun] = useState(false);
  const [joyrideStepIndex, setJoyrideStepIndex] = useState(0);

  const createSteps = [
    {
      target: ".step-1",
      content: (
        <div>
          <h3 class=" font-weight-bold">{locale.item_modal_tour_title_1}</h3>
          <br />
          <p>{locale.item_modal_tour_description_1}</p>
        </div>
      ),
      spotlightClicks: true,
      disableBeacon: true,
      placement: "right",
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: true,
    },
    {
      target: ".step-3",
      content: (
        <div>
          <h3 class=" font-weight-bold">{locale.item_modal_tour_title_2}</h3>
          <br />
          <p>{locale.item_modal_tour_description_2}</p>
        </div>
      ),
      spotlightClicks: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: true,
      placement: "right",
    },
    {
      target: ".step-2",
      content: (
        <div>
          <h3 class=" font-weight-bold">{locale.item_modal_tour_title_3}</h3>
          <br />
          <p>{locale.item_modal_tour_description_3}</p>
        </div>
      ),
      hideBackButton: true,
    },
  ];

  const editSteps = [
    {
      target: ".step-2",
      content: (
        <div>
          <p>{locale.item_modal_edit_tour_1}</p>
        </div>
      ),
      disableBeacon: true,
      placement: "center",
    },
  ];

  const startIntro = (startIntro) => {
    setRun(startIntro);
    props.type === "add" ? setSteps(createSteps) : setSteps(editSteps);
  };

  const handleOnhide = () => {
    setRun(false);
    setSteps([]);
    setJoyrideStepIndex(0);
    setForm(initialForm);
    setSelected([]);
    props.onHide();
  };

  const handleCallback = (data) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setJoyrideStepIndex(stepIndex);
    }
  };

  useEffect(() => {
    setCreateItemStep(props.type === "add" ? "selectImage" : "");

    if (props.intro && props.type !== "add") {
      startIntro(props.intro);
    }
  }, [props.type]);

  return (
    <Modal size="lg" show={props.show} onHide={() => handleOnhide()}>
      <ModalHeader closeButton />
      <StyledModalBody>
        <ReactJoyride
          showSkipButton
          continuous
          scrollToFirstStep
          run={run}
          steps={steps}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
          locale={{ last: "Ok" }}
          stepIndex={joyrideStepIndex}
          callback={(data) => handleCallback(data)}
        />
        {createItemStep === "selectImage" ? (
          <SelectImage
            {...props}
            selected={selected}
            setSelected={setSelected}
            form={form}
            setForm={setForm}
            setCreateItemStep={setCreateItemStep}
            startIntro={startIntro}
            setJoyrideStepIndex={setJoyrideStepIndex}
            joyrideStepIndex={joyrideStepIndex}
            setRun={setRun}
            run={run}
          />
        ) : (
          <ItemForm
            {...props}
            setForm={setForm}
            initial={initial}
            setInitial={setInitial}
            locale={locale}
            form={form}
            onEdit={props.onEdit}
            selected={selected}
            setSelected={setSelected}
            setCreateItemStep={setCreateItemStep}
            items={props.items}
            handleOnhide={handleOnhide}
          />
        )}
      </StyledModalBody>
    </Modal>
  );
};

const WMLFormControl = withMaxLength(Form.Control);

const ItemForm = (props) => {
  let ingName = "";
  let fileUpload = React.createRef();
  const {
    selected,
    setSelected,
    items,
    form,
    setForm,
    handleOnhide,
    locale,
    setInitial,
    initial,
  } = props;
  const required = ["name", "value", "image", "description"];

  const [actionLoading, setActionLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [additionalPrices, setAdditionalPrices] = useState(0);
  const [ingredients, setIngredients] = useState([]);
  const [diets, setDiets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);

  useEffect(() => {
    // props.getIngredients().then((res) => {
      // setIngredients(getData(res)?.results);
      // props.getDiets().then((res) => {
        // setDiets(getData(res)?.results);
        setDiets([]);
      // });
    // });
  }, []);

  useEffect(() => {
    if (props.id && !props.form.fromSelectImage) {
      setLoading(true);
      props.getItemById(props.id).then((res) => {
        setAdditionalPrices(() => {
          if (getData(res)?.value4) {
            return 3;
          } else if (getData(res)?.value3) {
            return 2;
          } else if (getData(res)?.value2) {
            return 1;
          } else {
            return 0;
          }
        });
        setForm((prevState) => {
          return {
            ...prevState,
            ...res,
            description: getData(res)?.description,
            item_ingredient: getData(res)?.item_ingredient.map((item) => ({
              label: item.name_org || item.name,
              value: item.id,
            })),
            item_diet: getData(res)?.item_diet.map((diet) => ({
              label: diet.title,
              value: diet.id,
            })),
          };
        });

        setInitial((prevState) => {
          return {
            ...prevState,
            ...res,
            description: getData(res)?.description,
            item_ingredient: getData(res)?.item_ingredient.map((item) => ({
              label: item.name_org || item.name,
              value: item.id,
            })),
            item_diet: getData(res)?.item_diet.map((diet) => ({
              label: diet.title,
              value: diet.id,
            })),
          };
        });

        setSelected(form.selected ? form.selected : getData(res)?.images);
        setLoading(false);
      });
    } else if (!form.images) setForm(initialForm);
  }, [props.id]);

  const handleChange = (e) => {
    const { value, name, files } = e.target;
    const reader = new FileReader();
    if (files) {
      const url = reader.readAsDataURL(files[0]);
      const filesize = (files[0].size / 1000).toFixed(4); // KB
      const validFileType = IMAGE_VALID_FORMATS.includes(
        files[0].type.split("image/")[1]
      );

      reader.onloadend = function (e) {
        setForm({
          ...form,
          imageFile: files[0],
          validImageFileType: validFileType,
          imageFileSize: filesize,
          image: reader.result,
        });
      };
      // }
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  const goToSelectImageStep = () => {
    props.setCreateItemStep("selectImage");
  };

  const handleAddItem = () => {
    setActionLoading(true);
    let ing = form.item_ingredient
      .filter((item) => item)
      .map((item) => item?.value);
    let diet = form.item_diet.filter((item) => item).map((item) => item?.value);

    let data = {
      restaurant: props.selectedRestaurant,
      name: form.name,
      value: form.value,
      value_label: form.value_label,
      description: form.description,
      item_ingredient_ids: ing,
      item_diet_ids: diet,
    };

    if (form.value2) {
      data = {
        ...data,
        value2: form.value2,
        value2_label: form.value2_label,
      };
    }

    if (form.value3) {
      data = {
        ...data,
        value3: form.value3,
        value3_label: form.value3_label,
      };
    }

    if (form.value4) {
      data = {
        ...data,
        value4: form.value4,
        value4_label: form.value4_label,
      };
    }

    handleOnhide();
    props.updateItems({}, "add_loading");
    props
      .addItem(data, form.imageFile)
      .then((res) => {
        if (getData(res)?.id) {
          props.makeAlert(locale.item_added_alert);

          /** Add item id to image's item list */
          handleAddImage(getData(res)?.id);

          /**  update Items List */
          props.updateItems(
            { ...res, images: [{ image_med: selected[0]?.image_med }] },
            "add"
          );

          setActionLoading(false);
        }
      })
      .catch((e) => {
        setActionLoading(false);
        props.makeAlert(locale.item_did_not_added_alert, "error");
        // props.setModal({ show: false, type: null, id: null })
      });
  };

  const handleAddImage = (itemId) => {
    // let images = items.filter(item => selected.includes(item.id))
    selected.forEach((image) => {
      const data = { items: [...image.items, itemId] };
      props.editImages(image.id, data);
    });
  };

  const handleEditItem = () => {
    // setActionLoading(true)

    let ing = form.item_ingredient.map((item) => item.value);
    let diet = form.item_diet.map((item) => item.value);

    let data = {
      name: form.name,
      value: parseFloat(form.value),
      value_label: form.value_label,
      value2: form.value2,
      value2_label: form.value2_label,
      value3: form.value3,
      value3_label: form.value3_label,
      value4: form.value4,
      value4_label: form.value4_label,
      description: form.description,
      item_ingredient_ids: ing,
      item_diet_ids: diet,
      category: form.category || [],
    };

    handleOnhide();

    /** if images chenged update theme */
    if (props.onEdit)
      props.onEdit({
        id: props.id,
        ...data,
        images: form.selected || form.images,
      });
    if (props.updateItems)
      props.updateItems({ ...data, images: selected, id: props.id }, "update");

    // if (props.updateItems) props.updateItems({}, "add_loading", props.id)
    const timer = setTimeout(() => {
      if (form.images && form.selected)
        updateImages(form.images, form.selected);
      props
        .editItem(props.id, data)
        .then((res) => {
          if (res) {
            /**  update Items List */

            setActionLoading(false);
            // setForm(initialForm)
          }
        })
        .catch((e) => {
          setActionLoading(false);
          props.makeAlert(locale.item_did_not_updated_alert, "error");
          // props.setModal({ show: false, type: null, id: null })
        });
    }, UNDO_PERIOD);

    const handleUndo = (t) => {
      clearTimeout(timer);
      // if (props.updateItems) props.updateItems({}, "update")
      if (props.updateItems)
        props.updateItems(
          { ...initial, images: form.images, id: props.id },
          "update"
        );

      setActionLoading(false);
    };

    props.makeAlert(
      parseString(locale.item_updated_alert, UNDO_PERIOD / 1000),
      null,
      handleUndo
    );
  };

  const updateImages = (prevImages, newImages) => {
    let newImagesId = newImages.map((img) => img.id);
    let prevImagesId = prevImages.map((img) => img.id);

    prevImages.forEach((image) => {
      if (!newImagesId.includes(image.id)) {
        const data = { items: image.items.filter((i) => i !== props.id) };
        // props.editImages(image.id, data)
        props.removeImages(image.id, {
          type: "ITEM",
          id: props.id,
        });
      }
    });
    newImages.forEach((image) => {
      if (!prevImagesId.includes(image.id)) {
        const data = { items: [...image.items, props.id] };
        // props.editImages(image.id, data)
        props.addImages(image.id, {
          type: "ITEM",
          id: props.id,
        });
      }
    });
  };

  // handleEditItem
  const handleDeleteItem = (e) => {
    e.preventDefault();
    setActionLoading(true);
    const items = props.items;
    // console.log(items);

    props.setModal({ show: false, type: null, id: null });
    props.updateItems(props.id, "remove");

    const timer = setTimeout(() => {
      props
        .deleteItem(props.id)
        .then((res) => {
          if (res === 204) {
            /**  update Items List */
            props.updateItems(props.id, "remove");

            setActionLoading(false);
          }
        })
        .catch((e) => {
          setActionLoading(false);
          props.makeAlert(locale.item_did_not_deleted_alert, "error");
        });
    }, UNDO_PERIOD);

    const handleUndo = (t) => {
      clearTimeout(timer);
      props.setItems(items);
    };

    props.makeAlert(
      parseString(locale.item_deleted_alert, UNDO_PERIOD / 1000),
      null,
      handleUndo
    );
  };

  const handleConfirmDeleteItem = (e) => {
    e.preventDefault();
    setDeleteAlert(true);
    // props.setDeleting(deleting => [...deleting, props.id])
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors([]);
    let tempErrors = [];
    required.forEach((key, index) => {
      if (!form[key] && Object.keys(initialForm).includes(key))
        tempErrors.push(key);
    });
    if (form.value < 0) {
      tempErrors.push("negativePrice");
    }
    if (form.imageFileSize > 201 && form.imageFile) {
      tempErrors.push("imageFileSize");
    }
    if (!form.validImageFileType && form.imageFile) {
      tempErrors.push("validImageFileType");
    }

    if (tempErrors.length === 0) {
      setActionLoading(true);
      props.type === "add" ? handleAddItem() : handleEditItem();
    } else setErrors(tempErrors);
  };

  const handleIngredientChange = (event, action) => {
    /**
     * add exist ingridient ti list
     * create ingridient handled in on onCreate method
     */
    const tempErrors = errors;
    // if (action.includes('Create ""')) {
    //   return
    // }

    if (event && event.length > 80) {
      tempErrors.push("ingredintLength");
      setErrors(tempErrors);
      return;
    }
    tempErrors.filter((error) => error !== "ingredintLength");
    setErrors(tempErrors);

    if (action.action === "clear") {
      setForm({ ...form, item_ingredient: [] });
    } else if (action.action === "remove-value") {
      let newIng = Array.from(form.item_ingredient);
      newIng = newIng.filter((ing) => ing.value !== action.removedValue.value);
      setForm({ ...form, item_ingredient: newIng });
    } else if (action.action !== "create-option") {
      setForm({
        ...form,
        item_ingredient: [...form.item_ingredient, action.option],
      });
    }
  };

  const handleDietChange = (event, action) => {
    if (action.action === "clear") {
      setForm({ ...form, item_diet: [] });
    } else if (action.action === "remove-value") {
      let newIng = Array.from(form.item_diet);
      newIng = newIng.filter((ing) => ing.value !== action.removedValue.value);
      setForm({ ...form, item_diet: newIng });
    } else if (action.action !== "create-option") {
      setForm({
        ...form,
        item_diet: [...form.item_diet, action.option],
      });
    }
  };

  const handleCancel = () => {
    props.setDeleting((deleting) => deleting.filter((d) => d != props.id));
    props.setModal({ show: false, type: null, id: null });
  };

  const onCreate = (name) => {
    const newIngs = [...form.item_ingredient, { label: name, value: name }];

    setForm({
      ...form,
      item_ingredient: newIngs,
    });
    setActionLoading(true);
    props.addIngredient(name).then((res) => {
      setForm((form) => ({
        ...form,
        item_ingredient: [
          ...form.item_ingredient.map((ing) => {
            if (ing.value === name) {
              ing.value = getData(res)?.id;
              //
              // return ing
            }

            return ing;
          }),
        ],
      }));
      setActionLoading(false);
    });
  };

  // const handleIngredientInputChange = async (value, action) => {
  //   ingName = value;
  //   // setIngredients([{name: 'loading...'}])
  //   const res = await props.getIngredients(value);
  //   return getData(res)?.results.map((ingredient) => ({
  //     label: ingredient.name,
  //     value: ingredient.id,
  //   }));
  // };

  const handleDietInputChange = async (value, action) => {
    if (ingName !== value) {
      ingName = value;
      return [];

      // const res = await props.getDiets(value);
      // return getData(res).results.map((ingredient) => ({
      //   label: ingredient.title,
      //   value: ingredient.id,
      // }));
    }
  };

  const removePrice = (n) => {
    setAdditionalPrices((prev) => prev - 1);
    if (n === 4) {
      setForm({ ...form, value4: null, value4_label: "" });
    }
    if (n === 3) {
      if (form.value4) {
        let value4 = form.value4;
        let value4_label = form.value4_label;
        setForm({
          ...form,
          value3: value4,
          value3_label: value4_label,
          value4: null,
          value4_label: "",
        });
      } else {
        setForm({ ...form, value3: null, value3_label: "" });
      }
    }
    if (n === 2) {
      let value4 = form.value4;
      let value4_label = form.value4_label;

      let value3 = form.value3;
      let value3_label = form.value3_label;

      setForm({
        ...form,
        value2: value3,
        value2_label: value3_label,
        value3: value4,
        value3_label: value4_label,
        value4: null,
        value4_label: "",
      });
    }
  };

  return (
    <Container className="step-2">
      <Row>
        <Col md={12} className="text-center mb-2">
          <h3 className="text-center text-dark font-weight-bold">
            {props.type === "add"
              ? locale.create_item_title
              : locale.edit_item_title}
          </h3>
          {props.type === "add" ? "" : locale.edit_item_description}
        </Col>
      </Row>

      <Row className="modal-step-2">
        <Col md={12}>
          {loading ? (
            <Loading />
          ) : (
            <Form onSubmit={handleSubmit}>
              <ImageForm className="row">
                {selected && selected[0] ? (
                  <>
                    <Image
                      src={selected[0].image_med}
                      width={100}
                      height={100}
                    />
                    <StyledA onClick={goToSelectImageStep}>
                      <FontAwesomeIcon icon={faFileUpload} className="mx-3" />
                      {locale.click_change_image}
                    </StyledA>
                  </>
                ) : (
                  <a className="btn" onClick={goToSelectImageStep}>
                    <FontAwesomeIcon icon={faFileUpload} className="mx-3" />
                    {locale.click_upload_image}
                  </a>
                )}
                <Col sm={12} className="text-center">
                  <Form.Control
                    type="file"
                    name="imageFile"
                    placeholder="Item Image"
                    onChange={handleChange}
                    ref={(el) => (fileUpload = el)}
                    style={{ display: "none" }}
                  />
                </Col>
              </ImageForm>
              {errors.includes("imageFile") && <Error locale={locale} />}
              {errors.includes("imageFileSize") && (
                <Error locale={locale} text={locale.image_size_error} />
              )}
              {errors.includes("validImageFileType") && (
                <Error locale={locale} text={locale.image_type_error} />
              )}
              <Form.Group className="row">
                <Col sm={6}>
                  <StyledLabel>{locale.item_form_name}</StyledLabel>
                  <WMLFormControl
                    type="text"
                    name="name"
                    placeholder={locale.item_form_name_placeholder}
                    value={form.name}
                    onChange={handleChange}
                    maxLength={50}
                  />
                  {errors.includes("name") && <Error locale={locale} />}
                </Col>
                <Col sm={3}>
                  <StyledLabel>{locale.item_form_price}*</StyledLabel>
                  <WMLFormControl
                    type="number"
                    name="value"
                    placeholder={locale.item_form_price_placeholder}
                    value={form.value}
                    min={0}
                    onChange={handleChange}
                  />
                  {errors.includes("value") && <Error locale={locale} />}
                  {errors.includes("negativePrice") && (
                    <Error
                      locale={locale}
                      text={locale.item_negative_price_error}
                    />
                  )}
                </Col>
                <Col sm={3}>
                  <StyledLabel>
                    {"Price Label"}
                    <sup>1</sup>
                  </StyledLabel>
                  <WMLFormControl
                    type="text"
                    name="value_label"
                    placeholder={"e.g. Medium Dish"}
                    value={form.value_label}
                    onChange={handleChange}
                    maxLength={50}
                  />
                  {errors.includes("label") && <Error locale={locale} />}
                </Col>
              </Form.Group>
              <Form.Group className="row">
                {additionalPrices >= 1 && (
                  <>
                    <Col sm={2}>
                      <StyledLabel>{locale.item_form_price} #2</StyledLabel>
                      <WMLFormControl
                        type="number"
                        name="value2"
                        placeholder={locale.item_form_price_placeholder}
                        value={form.value2}
                        min={0}
                        onChange={handleChange}
                      />
                      {errors.includes("value2") && <Error locale={locale} />}
                      {errors.includes("negativePrice") && (
                        <Error
                          locale={locale}
                          text={locale.item_negative_price_error}
                        />
                      )}
                    </Col>
                    <Col sm={3}>
                      <StyledLabel>{"Price Label"} #2</StyledLabel>
                      <WMLFormControl
                        type="text"
                        name="value2_label"
                        placeholder={"e.g. Large Dish"}
                        value={form.value2_label}
                        onChange={handleChange}
                        maxLength={50}
                      />
                      {errors.includes("value2_label") && (
                        <Error locale={locale} />
                      )}
                    </Col>
                    <Col sm={1}>
                      <StyledLabel
                        style={{
                          color: "#fff",
                          visibility: "hidden",
                        }}
                      >
                        {"D"}
                      </StyledLabel>
                      <Button variant={"danger"} onClick={() => removePrice(2)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </Col>
                  </>
                )}

                {additionalPrices >= 2 && (
                  <>
                    <Col sm={2}>
                      <StyledLabel>{locale.item_form_price} #3</StyledLabel>
                      <WMLFormControl
                        type="number"
                        name="value3"
                        placeholder={locale.item_form_price_placeholder}
                        value={form.value3}
                        min={0}
                        onChange={handleChange}
                      />
                      {errors.includes("value3") && <Error locale={locale} />}
                      {errors.includes("negativePrice") && (
                        <Error
                          locale={locale}
                          text={locale.item_negative_price_error}
                        />
                      )}
                    </Col>
                    <Col sm={3}>
                      <StyledLabel>{"Price Label"} #3</StyledLabel>
                      <WMLFormControl
                        type="text"
                        name="value3_label"
                        placeholder={"e.g. Small Dish"}
                        value={form.value3_label}
                        onChange={handleChange}
                        maxLength={50}
                      />
                      {errors.includes("value3_label") && (
                        <Error locale={locale} />
                      )}
                    </Col>
                    <Col sm={1}>
                      <StyledLabel
                        style={{
                          color: "#fff",
                          visibility: "hidden",
                        }}
                      >
                        {"D"}
                      </StyledLabel>
                      <Button variant={"danger"} onClick={() => removePrice(3)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </Col>
                  </>
                )}

                {additionalPrices >= 3 && (
                  <>
                    <Col sm={2}>
                      <StyledLabel>{locale.item_form_price} #4</StyledLabel>
                      <WMLFormControl
                        type="number"
                        name="value4"
                        placeholder={locale.item_form_price_placeholder}
                        value={form.value4}
                        min={0}
                        onChange={handleChange}
                      />
                      {errors.includes("value4") && <Error locale={locale} />}
                      {errors.includes("negativePrice") && (
                        <Error
                          locale={locale}
                          text={locale.item_negative_price_error}
                        />
                      )}
                    </Col>
                    <Col sm={3}>
                      <StyledLabel>{"Price Label"} #4</StyledLabel>
                      <WMLFormControl
                        type="text"
                        name="value4_label"
                        placeholder={"e.g. Small Dish"}
                        value={form.value4_label}
                        onChange={handleChange}
                        maxLength={50}
                      />
                      {errors.includes("value4_label") && (
                        <Error locale={locale} />
                      )}
                    </Col>
                    <Col sm={1}>
                      <StyledLabel
                        style={{
                          color: "#fff",
                          visibility: "hidden",
                        }}
                      >
                        {"D"}
                      </StyledLabel>
                      <Button variant={"danger"} onClick={() => removePrice(4)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </Col>
                  </>
                )}

                {additionalPrices < 3 && (
                  <Col sm={3}>
                    <Button
                      variant={"primary"}
                      onClick={() => setAdditionalPrices((prev) => prev + 1)}
                    >
                      Add Another Price
                    </Button>
                  </Col>
                )}
              </Form.Group>
              <Form.Group className="row">
                <Col sm={12}>
                  <StyledLabel>{locale.item_form_description}*</StyledLabel>
                  <Form.Label>
                    {locale.item_form_description_subtitle}
                  </Form.Label>
                  <WMLFormControl
                    type="text"
                    name="description"
                    as="textarea"
                    placeholder={locale.item_form_description_placeholder}
                    value={form.description}
                    onChange={handleChange}
                    maxLength={1000}
                  />
                  {errors.includes("description") && <Error locale={locale} />}
                </Col>
              </Form.Group>
              <Form.Group className="row">
                <Col sm={6}>
                  <StyledLabel>{locale.item_form_ingredients}</StyledLabel>
                  <Form.Label>
                    {locale.item_form_ingredients_subtitle}
                  </Form.Label>
                  {/* <Select
                      value={form.item_ingredient}
                      isMulti
                      name='ingredients'
                      options={ingredients.map(ingredient => ({
                        label: ingredient.name,
                        value: ingredient.id
                      }))}
                      onInputChange={handleIngredientInputChange}
                      onChange={handleIngredientChange}
                      // onCreateOption={onCreate}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    /> */}
                  {/* <CreatableSelect
                      value={form.item_ingredient}
                      isMulti
                      name='ingredients'
                      options={ingredients.map(ingredient => ({
                        label: ingredient.name,
                        value: ingredient.id
                      }))}
                      onInputChange={handleIngredientInputChange}
                      onChange={handleIngredientChange}
                      onCreateOption={onCreate}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    /> */}
                  {/* <AsyncCreatableSelect
                    cacheOptions
                    defaultOptions={ingredients.map((ingredient) => ({
                      label: ingredient.name,
                      value: ingredient.id,
                    }))}
                    loadOptions={handleIngredientInputChange}
                    value={form.item_ingredient}
                    isMulti
                    name="ingredients"
                    // options=
                    // onInputChange={handleIngredientInputChange}
                    onChange={handleIngredientChange}
                    onCreateOption={onCreate}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  /> */}
                  {errors.includes("ingredintLength") && (
                    <Error
                      locale={locale}
                      text={locale.item_form_ingredients_error}
                    />
                  )}
                </Col>
                <Col sm={6}>
                  <StyledLabel>{locale.item_form_diet}</StyledLabel>
                  <Form.Label>{locale.item_form_diet_subtitle}</Form.Label>
                  <AsyncSelect
                    value={form.item_diet}
                    isMulti
                    name="diets"
                    cacheOptions
                    defaultOptions={diets.map((ingredient) => ({
                      label: ingredient.title,
                      value: ingredient.id,
                    }))}
                    loadOptions={handleDietInputChange}
                    // onInputChange={handleDietInputChange}
                    onChange={handleDietChange}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </Col>
              </Form.Group>
              <Row className="align-items-center justify-content-center">
                {!deleteAlert ? (
                  <>
                    <CustomButton
                      className="rounded-pill px-4 shadow-sm"
                      variant="primary"
                      type="submit"
                      loading={actionLoading ? true : false}
                      onClick={handleSubmit}
                    >
                      {props.type === "edit"
                        ? locale.submit_button
                        : locale.create_item_button}
                    </CustomButton>
                    {props.type === "edit" ? (
                      <Button
                        className="rounded-pill "
                        variant="light"
                        style={{ marginLeft: "0.5rem" }}
                        type="submit"
                        onClick={handleConfirmDeleteItem}
                      >
                        {locale.delete_button}
                      </Button>
                    ) : (
                      <Button
                        className="rounded-pill "
                        variant="light"
                        style={{ marginLeft: "0.5rem" }}
                        onClick={handleCancel}
                      >
                        {locale.cancel_button}
                      </Button>
                    )}
                  </>
                ) : (
                  <Row className="align-items-center justify-content-center">
                    Are You Sure You Want To Delete This Item?!
                    <CustomButton
                      className="rounded-pill px-4 shadow-sm"
                      variant="primary"
                      style={{ marginLeft: "0.5rem" }}
                      type="submit"
                      onClick={handleDeleteItem}
                    >
                      Yes, I am!
                    </CustomButton>
                    <Button
                      className="rounded-pill "
                      variant="light"
                      style={{ marginLeft: "0.5rem" }}
                      onClick={handleCancel}
                    >
                      {locale.cancel_button}
                    </Button>
                  </Row>
                )}
              </Row>
            </Form>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default connect(
  (state) => ({
    selectedRestaurant: state.user.selectedRestaurant,
    locale: state.locale[state.locale.active],
  }),
  {
    getItemById,
    addItem,
    editItem,
    deleteItem,
    makeAlert,
    getItemsByRestaurantId,
    getIngredients,
    addIngredient,
    editIngredient,
    getDiets,
    fetchImages,
    editImages,
    addImages,
    removeImages,
    uploadImage,
  }
)(ItemModal);
