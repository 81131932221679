import React, { useState, useEffect } from "react";
import { StyledWrapper } from "./styles";
import PropTypes from "prop-types";

const WeekDaySelection = ({ classes, weekdays, setWeekdays, ...props }) => {
  const WEEK_DAYS = [
    { id: 0, title: "M", isChecked: false },
    { id: 1, title: "T", isChecked: false },
    { id: 2, title: "W", isChecked: false },
    { id: 3, title: "T", isChecked: false },
    { id: 4, title: "F", isChecked: false },
    { id: 5, title: "S", isChecked: false },
    { id: 6, title: "S", isChecked: false },
  ];
  const [weekDayNames, setWeekDayNames] = useState(WEEK_DAYS);

  useEffect(() => {
    let temp = [...weekDayNames];
    for (let i = 0; i < weekdays.length; i++) {
      const dayIndex = weekdays[i] - 1;
      temp[dayIndex] = { ...temp[dayIndex], isChecked: true };
    }
    setWeekDayNames([...temp]);
  }, []);

  useEffect(() => {
    setWeekdays([
      ...weekDayNames.filter((w) => w.isChecked).map((d) => d.id + 1),
    ]);
  }, [weekDayNames]);

  const handleSelect = (d, i) => {
    let temp = [...weekDayNames];

    temp[i] = { ...temp[i], isChecked: !temp[i].isChecked };
    setWeekDayNames(temp);
  };

  return (
    <StyledWrapper>
      {weekDayNames.map((d, index) => (
        <div
          className={`day ${d.isChecked ? "day-checked" : ""}`}
          onClick={() => handleSelect(d, index)}
          key={index}
        >
          {d.title}
        </div>
      ))}
    </StyledWrapper>
  );
};

WeekDaySelection.propTypes = {
  classes: PropTypes.string,
};

export default WeekDaySelection;
