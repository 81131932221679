import { fetchApi } from "./";

export const getLoyaltyActivityData =
  (restaurant) => async (dispatch, getState) => {
    let res = await dispatch(
      fetchApi(`analytics/loyalty-activity/${restaurant}`, "GET")
    );

    return res;
  };

export const getMonthlyScans = (restaurant) => async (dispatch, getState) => {
  let res = await dispatch(
    fetchApi(`analytics/monthly-qr-scans/${restaurant}`)
  );

  return res;
};

export const getAgeRanges = (restaurant) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`analytics/age-ranges/${restaurant}`));

  return res;
};

export const getGenders = (restaurant) => async (dispatch, getState) => {
  let res = await dispatch(
    fetchApi(`analytics/gender-percentages/${restaurant}`)
  );

  return res;
};

export const getTopMenus = (restaurant) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`analytics/top-menus/${restaurant}`));

  return res;
};

export const getTopCategories = (restaurant) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`analytics/top-categories/${restaurant}`));

  return res;
};

export const getTopItems = (restaurant) => async (dispatch, getState) => {
  let res = await dispatch(
    fetchApi(
      `analytics/top-items/${restaurant}/?query={item{id,views,name},views}`
    )
  );

  return res;
};

export const getOverview =
  (restaurantId, startDate, endDate) => async (dispatch, getState) => {
    let query = endDate
      ? `?start=${startDate}&end=${endDate}`
      : `?start=${startDate}&end=${startDate}`;
    let res = await dispatch(
      fetchApi(`restaurants/${restaurantId}/analytics/overview/${query}`)
    );

    return res;
  };

export const getPayoutAnalytics =
  (restaurantId, startDate, endDate, startingAfter) =>
  async (dispatch, getState) => {
    if (startDate) {
      let query = endDate
        ? `?start=${startDate}&end=${endDate}`
        : `?start=${startDate}&end=${startDate}`;
      query += startingAfter ? `&starting_after=${startingAfter}` : "";
      let res = await dispatch(
        fetchApi(`restaurants/${restaurantId}/analytics/payouts/${query}`)
      );

      return res;
    }
  };
