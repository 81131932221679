import { fetchApi } from './'
import { changeUserRestaurants } from '../actions/user'

export const getRestaurantById = (id, query) => async (dispatch, getState) => {
  let url = `restaurants/${id}/`
  if (query) url += '?query={' + query + '}'
  let res = await dispatch(fetchApi(url, 'GET'))

  return res
}

export const getRestaurantByUuid = (uuid) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`restaurants/uuid/${uuid}/?query={id,uuid}`, 'GET'))

  return res
}

export const totalQRScans = (uuid) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`totalqrscans`))

  return res
}

export const createRestaurant = (data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`restaurants/`, 'POST', data, true))

  return res
}

export const updateRestaurant = (id, data, hasFile) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`restaurants/${id}/`, 'PATCH', data, hasFile))

  return res
}

export const getRestaurantItemsById = (id) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`restaurants/${id}/items/?query={id,name,description,images{id,image_med},value,category}`, 'GET'))

  return res
}

export const getMyRestaurants = (query) => async (dispatch, getState) => {
  let url = `restaurants/mine/`

  if (query) url += `?search=${query}`

  let res = await dispatch(fetchApi(url, 'GET'))
  // dispatch(changeUserRestaurants(res.results))

  return res
}

export const getMyRestaurantsCount = () => async (dispatch, getState) => {
  let url = `restaurants/mine/?query={id}`

  let res = await dispatch(fetchApi(url, 'GET'))
  return res
}

export const getMyContactTracings = (restaurant, body, page) => async (dispatch, getState) => {
  let url = `restaurants/${restaurant}/tracing/get/`
  if (page) url += `?page=${page}`
  let res = await dispatch(fetchApi(url, 'POST', body))

  return res
}

export const getMyContactTracingsCSV = (restaurant, body, page) => async (dispatch, getState) => {
  let url = `restaurants/${restaurant}/tracing/get/csv/`
  if (page) url += `?page=${page}`
  let res = await dispatch(fetchApi(url, 'POST', body))

  return res
}

export const changeTracingStatus = (restaurant, body) => async (dispatch, getState) => {
  let url = `restaurants/${restaurant}/tracing_status/create/`
  let res = await dispatch(fetchApi(url, 'PUT', body))

  return res
}

export const changeTakeoutStatus = (restaurant, body) => async (dispatch, getState) => {
  let url = `restaurants/${restaurant}/takeout_status/`
  let res = await dispatch(fetchApi(url, 'PUT', body))

  return res
}
export const changeOrderEmailNotification = (restaurant, body) => async (dispatch, getState) => {
  let url = `restaurants/${restaurant}/email_notification/`
  let res = await dispatch(fetchApi(url, 'PATCH', body))

  return res
}

export const getTakeoutStatus = (restaurant) => async (dispatch, getState) => {
  let url = `restaurants/${restaurant}/takeout_status/`
  let res = await dispatch(fetchApi(url))

  return res
}

export const getRestaurantPlexiDesign = (restaurant, body) => async (dispatch, getState) => {
  let url = `restaurants/${restaurant}/plexi_stand/`
  let res = await dispatch(fetchApi(url))

  return res
}

export const getMyPlexiOrders = (restaurant, body) => async (dispatch, getState) => {
  let url = `restaurants/${restaurant}/plexi_orders/`
  let res = await dispatch(fetchApi(url))

  return res
}

export const getQRDesign = (data) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`restaurants/get_qrdesign/`, 'POST', data))

  return res
}

export const getTags = (search = '') => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`tags/?query={id,title}&search=${search}`, 'GET'))

  return res
}

export const getParent = (parent) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`parents/${parent}/`))

  return res
}
export const getParentByIP = () => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`get_parent/`, 'GET', null, false, false, true))

  return res
}

export const getTaxes = (search = '', restaurant) => async (dispatch, getState) => {
  let url = `tax/active-tax/?query={id,title}&search=${search}`
  if (restaurant) url += `&restaurant=${restaurant}`
  let res = await dispatch(fetchApi(url))

  return res
}

export const getRestaurantTax = (restaurant) => async (dispatch, getState) => {
  let res = await dispatch(fetchApi(`tax/restaurant/${restaurant}/`, 'GET',
    null, false, false, true))

  return res
}

export const getParents = (query) => async (dispatch, getState) => {
  let url = `organizations/`
  if (query) url += `?search=${query}`

  let res = await dispatch(fetchApi(url))

  return res
}
