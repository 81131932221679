export const Images = {
  headerGradient: require("./headerGradient.webp"),
  phoneUIWebUI: require("./panel_preview.webp"), // This import was failing - kai
  navbarBg: require("./navbar_bg.svg"),
  step1: require("./step1.webp"),
  step2: require("./step2.webp"),
  step3: require("./step3.webp"),
  step4: require("./step4.webp"),
  eateryPic: require("./eateryPic.webp"),
  partner_1: require("./partner_1.png"),
  partner_2: require("./partner_2.png"),
  partner_3: require("./partner_3.png"),
  partner_4: require("./partner_4.png"),
  partner_5: require("./partner_5.png"),
  partner_6: require("./partner_6.png"),
  form_section_background: require("./form_section_background.png"),
  ellipse: require("./ellipse.png"),
  contact_less: require("./contact_less.png"),
  analytics_logo: require("./analytics_logo.png"),
  dynamic_menu: require("./dynamic_menu.png"),
  plansBackground: require("./plansBackground.webp"),
  header_gradient_mobile: require("./header_gradient_mobile.webp"),
  medium: require("./medium.png"),
  qrcode: require("./Vector.png"),
  phoneLogo: require("./Vector(1).png"),
  pen: require("./Vector(2).png"),
  touch: require("./Vector(3).png"),
  alphabet: require("./Vector(4).png"),
  digital_board: require("./digital_board.png"),
  digital_marketing: require("./digital_marketing.png"),
  marketing_seo: require("./marketing_seo.png"),
  custom_mobile_application: require("./Custom_mobile_application.png"),
  custom_mobile_application_2: require("./Custom_mobile_application_2.png"),
  mcafee: require("./mcafee.png"),
  TRUSTeSeal: require("./TRUSTeSeal.png"),
  envelop: require("./envelop.png"),
  macbook: require("./mac.png"),
  image_vector: require("./image_vector.png"),
  take_out: require("./take_out.png"),
  dine_in: require("./dine_in.png"),
  delivery: require("./delivery.png"),
  omniLogo: require("./OCLogo.png"),
};
