import React from "react";
import { StyledButtonWrapper } from "./styles";
import PropTypes from "prop-types";
import Loading from "../../Loading";

const Button = React.forwardRef(
  (
    {
      label,
      onClick,
      type,
      leadingIcon,
      trailingIcon,
      isActive,
      disabled,
      classes,
      loading,
      ...props
    },
    ref
  ) => {
    return (
      <StyledButtonWrapper>
        <button
          ref={ref}
          className={`button ${type} ${classes}`}
          onClick={onClick}
          disabled={disabled}
        >
          {loading ? <Loading nopadding /> : null}
          {leadingIcon ? (
            <span className="icon icon--lead">{leadingIcon}</span>
          ) : null}
          {loading ? <span>Loading</span> : <span>{label}</span>}
          {trailingIcon ? (
            <span className="icon icon--trail">{trailingIcon}</span>
          ) : null}
        </button>
      </StyledButtonWrapper>
    );
  }
);

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.any,
  classes: PropTypes.string,
  /** type is one of ["filled", "neutral", "text"] */
  type: PropTypes.string,
  leadingIcon: PropTypes.element,
  trailingIcon: PropTypes.element,
  isActive: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

export default Button;
