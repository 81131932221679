import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import styled from "styled-components";
import { Colors } from "assets/colors";

import Loading from "components/Loading";

const StyeledButton = styled(Button)`
  background: ${Colors.primary1};
  border-radius: 3px;
  border: none;
  padding: 20px;
  width: 100%;
  font-weight: bold;
  font-size: 20px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #ffffff;
`;

const CustomButton = (props) => (
  <StyeledButton
    {...props}
    disabled={props.loading}
    variant={props.variant}
    type="submit"
    onClick={props.onClick}
  >
    {!props.loading ? props.children : <Loading nopadding />}
  </StyeledButton>
);

export default CustomButton;

CustomButton.defaultProps = {
  variant: "primary",
  onClick: null,
};
