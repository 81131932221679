import { combineReducers } from 'redux'
import { user } from './user'
import { locale } from './locale'
import { orders, orderNotifAlarm } from './orders'

const alerts = (state = [], action) => {
  switch (action.type) {
    case 'ADD_TO_ALERTS':
      return [
        {
          text: action.text,
          type: action.alertType,
          undo: action.undo
        },
        ...state
      ]
    default:
      return state
  }
}

const loading = (state = 1, action) => {
  switch (action.type) {
    case 'ON':
      return state + 1
    case 'OFF':
      return state - 1
    default:
      return state
  }
}

const reducers = combineReducers({
  alerts,
  loading,
  locale,
  orders,
  user,
  orderNotifAlarm
})

export default reducers
