import React from 'react';
import { Card } from "react-bootstrap";
import styled from 'styled-components'

import { Colors } from '../../assets/colors'

const StyledCard = styled(Card)`
  box-shadow: 0px 0px 30px -6px rgba(0,0,0,0.2);
  display: flex;
  border-width: 0;
  justify-content: center;
  text-align: center;
  margin-bottom: 50px;
  border-radius: 0.5rem;
  flex:1;
`

const StyledCardHeader = styled(Card.Header)`
  background: ${Colors.white} !important;
  border: none !important;
`

const Iframe = styled.iframe`
  width: 100%;
  height: 350px;
  border-radius: 0.5rem;
  border: none;
`

const Title = styled.h3`
  font-size: 1.75rem;
  color: ${Colors.normalGray};
  font-weight: bold;
  text-align:center;
`

const Subtitle = styled.h3`
  font-size: 1.1rem;
  color: ${Colors.darkGray};
  margin-bottom: 15px;
  text-align:center;
`

const VideoTutorial = (props) => {
  return (
    <StyledCard className="mt-3">
      {props.title &&
        <StyledCardHeader>
          <Title>{props.title}</Title>
          <Subtitle>{props.subtitle}</Subtitle>
        </StyledCardHeader>
      }
      <Card.Body>
        <Iframe src={props.src}
          frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen={true}>
        </Iframe>
      </Card.Body>
    </StyledCard>
  )
}

export default VideoTutorial
