import styled from "styled-components";
import { Colors } from "assets/colors";

export const StyledWrapper = styled.div`
  margin-bottom: 24px;

  .dropdown-button {
    background: transparent !important;
    color: ${Colors.primaryText1};
    padding: 8px !important;

    .icon--lead {
      background: #ffffff;
      box-shadow: 0px 2px 5px rgb(60 66 87 / 8%), 0px 1px 1px rgb(0 0 0 / 12%);
      border-radius: 100px;
      width: 30px !important;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 7px;
    }
  }

  .truncate {
    white-space: nowrap;
    max-width: 168px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
  }

  .list-view {
    background: #ffffff;
    border: 0.5px solid #d0d1d2;
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 16px 10px 8px;
    width: 240px;
    min-height: 100px;
    max-height: 244px;
    position: absolute;
    z-index: 1000;
  }

  .search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-family: "Manrope";
    z-index: 2;
    color: #697386;
  }

  .cross-icon {
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
    font-family: "Manrope";
    z-index: 2;
    color: #697386;
    cursor: pointer;
  }

  .search-input {
    padding: 8px 34px !important;
    width: 220px !important;
    height: 32px !important;
    margin-right: 8px;
    box-shadow: 0px 0px 0px 1px #d5d6d6, 0px 2px 4px rgba(0, 0, 0, 0.07),
      0px 1px 1px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }

  .merchant-list {
    display: flex;
    flex-direction: column;
    max-height: 180px;
    overflow: auto;
    margin: 8px 0;
    margin-left: 0;

    &-item {
      color: ${Colors.textPrimary1};
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      padding: 9px 2px;
      cursor: pointer;
    }
  }

  .badge {
    padding-right: 0;
  }
`;
