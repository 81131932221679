import styled from "styled-components";
import { Container } from "react-bootstrap";

export const StyledWrapper = styled.div`
  max-width: 100vw;
  overflow: hidden;
  display: flex;
`;

export const StyledContent = styled.div`
  overflow: hidden !important;
  align-items: center;
  width: calc(100% - 272px) !important;
  margin-left: 272px;
  display: flex;
  flex-direction: column;
`;

export const StyledBody = styled(Container)`
  z-index: 101;
  min-height: calc(100vh - 75px);
  overflow: hidden;
  align-items: center;
`;

export const Footer = styled.div`
width: 100%;
height: 40px;
`
