import React, { useState, useEffect, useRef } from "react";
import { StyledWrapper } from "./styles.js";
import { ReactComponent as SearchIcon } from "assets/image/search.svg";
import Input from "components/common/Input";
import Checkbox from "components/common/Checkbox";
import Button from "components/common/Button";
import { useContext } from "react";
import { ModalContext } from "../../../context/modalContext.js";

const ProductModal = ({ selectedItems, setSelectedItems, value, ...props }) => {
  const [key, setKey] = useState(value);
  const [filtered, setFiltered] = useState([...selectedItems]);
  const [checkedItems, setCheckedItems] = useState([...filtered]);
  const inputRef = useRef(null);
  const { modal, handleModal, modalContent, hideCloseBtn, classes } =
    useContext(ModalContext);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    setFiltered([
      ...selectedItems.filter((i) =>
        i.name.toUpperCase().includes(key.toUpperCase())
      ),
    ]);
  }, [key]);

  const handleSelectItems = (item) => {
    let index = checkedItems.findIndex((i) => i.id === item?.id);
    let temp = [...checkedItems];
    temp[index] = { ...temp[index], checked: !temp[index].checked };
    setCheckedItems([...temp]);
  };

  const handleCloseModal = () => {
    handleModal();
  };

  const handleClearData = () => {
    setCheckedItems([
      ...checkedItems.map((i) => {
        return { ...i, checked: false };
      }),
    ]);
  };

  const handleAdd = () => {
    setSelectedItems([...checkedItems]);
    handleCloseModal();
  };

  return (
    <StyledWrapper>
      <div className="title title-box">Add products</div>
      <div className="row-box">
        <Input
          ref={inputRef}
          type="text"
          id="find-product"
          value={key}
          onChange={(val) => setKey(val)}
          placeholder="Search a product..."
          inputClasses="search-input"
          leadingIcon={<SearchIcon className="search-icon" />}
        />
      </div>
      <div className="products">
        {filtered?.length === 0 ? (
          <div className="row-box no-results">
            {key ? (
              <>
                No results found for "<span className="strong">{key}</span>"
              </>
            ) : (
              "No results found"
            )}
          </div>
        ) : (
          filtered?.map((item) => (
            <div className="row-box" key={item.id}>
              <Checkbox
                classes=""
                name={`${item?.id}`}
                checked={checkedItems.find((i) => i.id === item?.id).checked}
                onChange={() => handleSelectItems(item)}
                label={
                  <div className="item">
                    <div className="item-left">
                      <p className="item-title">{item?.name}</p>
                      <p className="item-price">${item.value}</p>
                    </div>
                    {item?.images[0]?.image_thumbnail && (
                      <img src={item?.images[0]?.image_thumbnail} />
                    )}
                  </div>
                }
                labelClasses="item-label"
                classes="item-checkbox"
              />
            </div>
          ))
        )}
      </div>

      <div className="btns">
        <Button
          onClick={handleClearData}
          label="Clear"
          type="neutral"
          classes="btn"
        />
        <div className="btns-right">
          <Button
            onClick={handleCloseModal}
            label="Cancel"
            type="neutral"
            classes="btn btn-cancel"
          />
          <Button
            onClick={handleAdd}
            label="Add"
            type="filled"
            classes="btn btn-add"
          />
        </div>
      </div>
    </StyledWrapper>
  );
};

export default ProductModal;
