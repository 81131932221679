import React, { useContext, useEffect, useState } from "react";
import { StyledWrapper } from "./styles";
import RadioButtonGroup from "components/RadioButtonGroup";
import Input from "components/common/Input";
import Button from "components/common/Button";
import { ModalContext } from "../../../context/modalContext";
import { useDispatch, useSelector } from "react-redux";
import { propTypes } from "react-bootstrap/esm/Image";
import { changeTakeoutStatus } from "api/restaurant";

const PauseDialogue = ({
  takeoutStatus,
  pause,
  setPause,
  handleConfirm,
  loading,
  ...props
}) => {
  const [reason, setReason] = useState("");
  const [time, setTime] = useState("");
  const PAUSE_OPTIONS = [
    { id: 0, title: "Busy, please check again later." },
    { id: 1, title: "Closed, place your order as a pre-order." },
    {
      id: 2,
      title: (
        <Input
          type="text"
          id="custom-reason-for-pausing"
          value={reason}
          onChange={setReason}
          placeholder="Other reason"
        />
      ),
    },
  ];
  const UNPAUSE_OPTIONS = [
    { id: 0, title: "2 hours", time: 2 },
    { id: 1, title: "4 hours", time: 4 },
    {
      id: 2,
      title: (
        <div className="input-wrap">
          <Input
            type="number"
            id="custom-time"
            value={time}
            onKeyDown={(e) => {
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
            }}
            onChange={(val) => {
              if (val === "" || (val > 0 && val <= 24)) {
                setTime(val);
              }
            }}
            placeholder="Custom time"
          />
          <span className="unit">hours</span>
        </div>
      ),
      time: time,
    },
  ];
  const [selectedOption, setSelectedOption] = useState(
    !pause ? PAUSE_OPTIONS[0] : UNPAUSE_OPTIONS[0]
  );

  const { handleModal } = useContext(ModalContext);

  const handleCancel = () => {
    handleModal();
  };

  return (
    <StyledWrapper>
      <div className="top">
        <h2 className="title">{!pause ? "Pause Orders" : "Unpause Orders"}</h2>
        <p className="description">
          {!pause
            ? "Please select a reason for pausing."
            : "Please select a time for unpausing."}
        </p>
      </div>

      {!pause ? (
        <RadioButtonGroup
          options={PAUSE_OPTIONS}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          classes="list"
          labelClasses="list-item"
          checkmarkClasses="checkmark"
        />
      ) : (
        <RadioButtonGroup
          options={UNPAUSE_OPTIONS}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          classes="list"
          labelClasses="list-item"
          checkmarkClasses="checkmark"
        />
      )}
      <div className="btns">
        <Button
          onClick={handleCancel}
          label="Cancel"
          type="neutral"
          classes="cancel-btn"
        />
        <Button
          onClick={() => {
            handleConfirm(selectedOption, reason, time);
            handleModal();
          }}
          label="Confirm"
          type="filled"
          classes="confirm-btn"
          disabled={
            !pause
              ? selectedOption.id === 2 && !reason
              : selectedOption.id === 2 && !time
          }
          loading={loading}
        />
      </div>
    </StyledWrapper>
  );
};

PauseDialogue.propTypes = {};

export default PauseDialogue;
