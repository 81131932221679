import React, { useState, useEffect } from 'react'
import { Card, Form } from 'react-bootstrap';
import { Draggable } from 'react-beautiful-dnd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faTimes, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { connect } from 'react-redux'

import ItemModal from 'containers/items/ItemModal';
import {changeCategoryFeatured} from 'api/categories'

const StyledCard = styled(Card)`
  background-position: center;
  background-size: cover;
  overflow: hidden;
  border-radius: 0;

  .empty {
    height: 25px;
  }

  img {
    height: 70px;
    object-fit: cover;
    width: 100%;
  }
`
const StyledCardBody = styled(Card.Body)`
  /* background:#000a; */
  color:#555;
  padding: 0 5px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    display: flex;
    width: 85%;

    .name {
        font-weight: bold;
        /* max-width: 50%; */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .price {
        font-weight: bold;
        color: #999;
        margin: 0 5px;
    }
    p {
      margin: 0;
    }
  }
`
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor:pointer;
`

const Item = ({ item, itemId, index, removeItem, className, editItem, locale, ...props }) => {
  const [isFeatured, setIsFeatured] = useState(props.isFeatured)
  const removeHandler = (event) => {
    event.stopPropagation()
    removeItem(item)
  }

  useEffect(() => {
    setIsFeatured(props.isFeatured)
  }, [props.isFeatured])

  const handleFeatureChange = event => {
    props.changeCategoryFeatured(props.category, item.id)
    props.setFeatured(item.id)
    setIsFeatured(event.target.checked)
  }

  return (
    <React.Fragment>
      <Draggable draggableId={itemId} index={index}>
        {(provided) => (
          <StyledCard
            className={className + " m-2 shadow-sm"}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            // onClick={() => editItem(item.id)} // removed the ability to edit item!
          >
            {item.images.length > 0 ? <LazyLoadImage effect='blur' className='img'
              src={item.images && item.images[0] && item.images[0].image_med}
            />  : <div className='empty'/>}
            <StyledCardBody>
              <span className='spec'>
                <div className='name'>{item.name.substring(0, 20)}</div>
                <p className='price'>{item.value}{locale.currency}</p>
              </span>
              <StyledFontAwesomeIcon
                icon={faTimes}
                className="float-right"
                onClick={(event) => removeHandler(event)}
              />
            </StyledCardBody>            
            {props.featuredActive && <StyledCardBody>
              <span className='spec'>
                <div>{isFeatured ? 'Featured' : ''}</div>
              </span>
              <FormCheck
                onChange={handleFeatureChange}
                checked={isFeatured}
                style={{
                  marginLeft: 0
                }}
              />
            </StyledCardBody>}
          </StyledCard>
        )}
      </Draggable>
    </React.Fragment>
  )
}

const FormCheck = styled(Form.Check)`
  input {
    margin-left: 0;
  }
`

export default connect(state => ({locale: state.locale[state.locale.active]}), {
  changeCategoryFeatured
})(Item)
