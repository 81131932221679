import { faEdit } from "@fortawesome/free-solid-svg-icons";
import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import styled from "styled-components";

import { createBillingAddress, getBillingAddress, updateBillingAddress } from "api/billing/customers";
import CustomButton from "components/button";
import Loading from "components/Loading";
import { COUNTRIES } from "constants";

import { getData } from "../../utils";
import BillingCard from "./Card";
import Modal from "./modal";

const FullWidthLine = styled.hr`
  width: 5000px;
  margin-left: -1000px;
  background-color: #a8a8a8;
`;

const TableHeader = styled.span`
  color: #848282;

  font-size: 14.4px;
  letter-spacing: 0.57px;
  line-height: 17px;
`;
const Title = styled.h6`
  color: #848282;
  /* font-family: "Proxima Nova";	 */
  font-size: 14.4px;
  font-weight: 600;
  letter-spacing: 0.57px;
  line-height: 17px;
`;
const Desc = styled.p`
  color: #545454;

  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 20px;
`;

const BillingAddress = (props) => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { loading: false, address: null }
  );
  const [modal, setModal] = useState(false);

  useEffect(() => {
    setState({ loading: true });

    if (props.getBillingAddress)
      props.getBillingAddress().then((res) => {
        setState({
          ...state,
          address: { ...state.form, ...res },
          loading: false,
        });
      });
  }, []);

  return (
    <BillingCard
      title={props.locale.billing_title2}
      subtitle={props.locale.billing_subtitle}
      icon={faEdit}
      editText={
        !(state.address && !state.address.error)
          ? props.locale.billing_edit_text
          : props.locale.billing_edit_text2
      }
      onClick={() => setModal(true)}
    >
      <FullWidthLine className="my-1" />
      <Row className="px-3">
        <Col md={4}>
          <TableHeader>{props.locale.billing_type}</TableHeader>
        </Col>
        <Col md={4}>
          <TableHeader>{props.locale.billing_country}</TableHeader>
        </Col>
        <Col md={4}>
          <TableHeader>{props.locale.billing_postal}</TableHeader>
        </Col>
      </Row>
      <FullWidthLine className="my-1  mb-3" />
      {state.loading ? (
        <Loading />
      ) : state.address && !state.address.error ? (
        <Row className="px-3">
          <Col md={4}>
            <TableHeader>{state.address.address_1}</TableHeader>
          </Col>
          <Col md={4}>
            <TableHeader>{state.address.country}</TableHeader>
          </Col>
          <Col md={4}>
            <TableHeader>{state.address.postal_code}</TableHeader>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col md={12} className="text-center my-3">
            <TableHeader className="text-center">
              No billing address found.
            </TableHeader>
          </Col>
        </Row>
      )}

      {/* <div className="w-50">
                <Title>TAX LOCATION</Title>
                <Desc>{data.address}</Desc>
            </div> */}

      <BillingModal
        show={modal}
        setState={setState}
        setModal={setModal}
        address={state.address}
      />
    </BillingCard>
  );
};

const Label = styled(Form.Label)`
  color: #8b8b8b;

  font-size: 14px;
  letter-spacing: 0.26px;
  line-height: 17px;
`;

export const NotConnectedBillingForm = forwardRef((props, ref) => {
  // const [loading, setLoading] = useState(false)
  const { errorsInPlexi } = props;
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      loading: false,
      errors: [],
      form: {
        address_1: "",
        address_2: "",
        country: "CA",
        province: "",
        city: "",
        postal_code: "",
      },
    }
  );

  useEffect(() => {
    setState({ loading: true });

    if (props.getBillingAddress)
      props.getBillingAddress().then((res) => {
        setState({
          ...state,
          form: { ...state.form, ...getData(res) },
          loading: false,
        });
        if (props.setState)
          props.setState((prev) => ({ ...prev, address: getData(res) }));
        if (props.setForm && !res.error) props.setForm(getData(res));
      });
  }, []);

  const submit = useRef();

  useEffect(() => {
    if (props.address) {
      setState({ form: props.address });
    }
  }, []);

  useImperativeHandle(ref, () => ({
    handleSubmit: () => submit.current.click(),
    loading: state.loading,
  }));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ form: { ...state.form, [name]: value } });
    if (props.setForm) {
      props.setForm({ ...state.form, [name]: value });
    }
  };

  const validateForm = () => {
    let errors = [];
    Object.keys(state.form).forEach((field, i) => {
      if (field !== "address_2" && !state.form[field]) {
        errors = [...errors, field];
      }
    });

    setState({ errors });
    if (errors.length > 0) {
      return false;
    } else {
      return true;
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    props.setLoading(true);
    props.getBillingAddress().then((res) => {
      if (res.error) {
        props.createBillingAddress(state.form).then((res) => {
          // setState({loading: false})
          props.setLoading(false);

          if (props.onSubmit) props.onSubmit(getData(res));
        });
      } else {
        props.updateBillingAddress(getData(res)?.id, state.form).then((res) => {
          // setState({loading: false})
          props.setLoading(false);

          if (props.onSubmit) props.onSubmit(getData(res));
        });
      }
    });
    // props.onSubmit()
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={12}>
            <Form.Group>
              <Label>ADDRESS LINE 1</Label>
              <Form.Control
                type="text"
                value={state.form.address_1}
                placeholder="Address line 1"
                name="address_1"
                onChange={handleChange}
              />
              {state.errors.includes("address_1") ||
                (errorsInPlexi?.includes("address_1") && (
                  <Form.Control.Feedback
                    style={{ display: "block" }}
                    type="invalid"
                  >
                    * this field is required
                  </Form.Control.Feedback>
                ))}
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group>
              <Label>ADDRESS LINE 2 (OPTIONAL)</Label>
              <Form.Control
                type="text"
                value={state.form.address_2}
                placeholder="Address line 2"
                name="address_2"
                onChange={handleChange}
              />
              {state.errors.includes("address_2") ||
                (errorsInPlexi?.includes("address_2") && (
                  <Form.Control.Feedback
                    style={{ display: "block" }}
                    type="invalid"
                  >
                    * this field is required
                  </Form.Control.Feedback>
                ))}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Label>COUNTRY</Label>
              <Form.Control
                as="select"
                value={state.form.country}
                placeholder="Address line 1"
                name="country"
                onChange={handleChange}
              >
                {COUNTRIES.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </Form.Control>
              {state.errors.includes("country") ||
                (errorsInPlexi?.includes("country") && (
                  <Form.Control.Feedback
                    style={{ display: "block" }}
                    type="invalid"
                  >
                    * this field is required
                  </Form.Control.Feedback>
                ))}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Label>PROVINCE</Label>
              <Form.Control
                type="text"
                value={state.form.province}
                placeholder="British Colombia"
                name="province"
                onChange={handleChange}
              />
              {state.errors.includes("province") ||
                (errorsInPlexi?.includes("province") && (
                  <Form.Control.Feedback
                    style={{ display: "block" }}
                    type="invalid"
                  >
                    * this field is required
                  </Form.Control.Feedback>
                ))}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Label>CITY</Label>
              <Form.Control
                type="text"
                value={state.form.city}
                placeholder="Vancouver"
                name="city"
                onChange={handleChange}
              />
              {state.errors.includes("city") ||
                (errorsInPlexi?.includes("city") && (
                  <Form.Control.Feedback
                    style={{ display: "block" }}
                    type="invalid"
                  >
                    * this field is required
                  </Form.Control.Feedback>
                ))}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Label>POSTAL CODE</Label>
              <Form.Control
                type="text"
                value={state.form.postal_code}
                placeholder="V1W6T7"
                name="postal_code"
                onChange={handleChange}
              />
              {state.errors.includes("postal_code") ||
                (errorsInPlexi?.includes("postal_code") && (
                  <Form.Control.Feedback
                    style={{ display: "block" }}
                    type="invalid"
                  >
                    * this field is required
                  </Form.Control.Feedback>
                ))}
            </Form.Group>
          </Col>
        </Row>
        <button style={{ display: "none" }} ref={submit} type="submit">
          submit
        </button>
      </Form>
    </div>
  );
});

export const BillingForm = connect(
  null,
  { getBillingAddress, createBillingAddress, updateBillingAddress },
  null,
  { forwardRef: true }
)(NotConnectedBillingForm);

const BillingModal = (props) => {
  const [loading, setLoading] = useState(false);
  const address = useRef();

  const handleSubmit = (data) => {
    props.setState({ address: data });
    props.setModal(false);
  };

  return (
    <Modal
      header="Billing Address"
      show={props.show}
      size="lg"
      onHide={() => props.setModal(false)}
    >
      <BillingForm
        {...props}
        ref={address}
        setLoading={setLoading}
        onSubmit={handleSubmit}
      />
      <Row className="justify-content-center mt-3">
        <Button variant="light" onClick={() => props.setModal(false)}>
          Cancel
        </Button>
        <CustomButton
          className="px-5"
          onClick={() => address.current.handleSubmit()}
        >
          {loading ? <Loading nopadding={true} /> : "Save Changes"}
        </CustomButton>
      </Row>
    </Modal>
  );
};

export default connect(
  (state) => ({
    address: state.user.info,
    locale: state.locale[state.locale.active],
  }),
  { getBillingAddress }
)(BillingAddress);
