import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import Navbar from "components/Layout/Navbar";
import { Colors } from "assets/colors";
import { Images } from "assets/image";
import { appScheme } from "../../constants";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.white};
  font-size: 25px;
  font-weight: bold;
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(
    180deg,
    ${Colors.primary1} 10%,
    ${Colors.primary1} 100%
  );
  flex-direction: column;
  img {
    filter: brightness(100) grayscale(1) contrast(100);
  }
`;

const AppDownload = (props) => {
  const [dots, setDots] = useState(1);
  useEffect(() => {
    const dots = setInterval(dotsProgress, 1000);
    const os = getOS();
    const id = props.location.search.split("?uuid=")[1];

    document.location = `${appScheme}menus/${id}`;

    setTimeout(() => {
      // window.location.replace(`https://app.dineeasy.ca/uuid/${id}`);
      // switch (os) {
      //   case 'Android':
      //     window.location.replace('https://play.google.com/store/apps/details?id=com.appetizevm.menuapp')
      //     break;
      //   case 'iOS':
      //     document.location = 'https://apps.apple.com/ca/app/appetize/id1492938675';
      //     break;
      //   default:
      //     window.location.replace(`https://app.appetizevm.com/${id}`)
      // }
    }, 300);
  }, []);

  useEffect(() => {
    return clearInterval(dots);
  });

  const getOS = () => {
    let userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
      iosPlatforms = ["iPhone", "iPad", "iPod"],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows";
    } else if (/Android/.test(userAgent)) {
      os = "Android";
    } else if (!os && /Linux/.test(platform)) {
      os = "Linux";
    }

    return os;
  };

  const dotsProgress = () => {
    const now = Date.now();
    setDots((((now / 1000) % 3) + 1).toFixed(0));
  };

  return (
    <>
      <Wrapper>
        <img
          src={Images.OCLogo}
          style={{ height: 45, paddingLeft: 0, marginBottom: "1rem" }}
        />
        <div className="progess">
          Redirecting
          {Array.from({ length: dots }, (_, i) => (
            <span key={i}>.</span>
          ))}
        </div>
      </Wrapper>
    </>
  );
};

export default AppDownload;
