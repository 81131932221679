import styled from "styled-components";
import { Colors } from "assets/colors";

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;

  .day {
    width: 30px;
    height: 30px;
    background: #f3f4f4;
    box-shadow: 0px 0px 0px 1px #d5d6d6, 0px 2px 4px rgba(0, 0, 0, 0.07),
      0px 1px 1px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    font-family: "Manrope";
    color: #131b1f;
    margin-right: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &-checked {
      color: #ffffff;
      background: #2b64f5;
      box-shadow: 0px 0px 0px 1px #2b64f5, 0px 2px 4px rgba(0, 0, 0, 0.07),
        0px 1px 1px rgba(0, 0, 0, 0.05);
    }
  }
`;
