import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import jsCookie from "js-cookie";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter,
} from "react-router-dom";
import { Col, Container, Row, Card, Form, Button } from "react-bootstrap";
import styled from "styled-components";

import Navbar from "components/Layout/Navbar";
import { loginUser } from "actions/user";
import { makeAlert } from "actions";
import { verifyEmail } from "api/auth";
import Loading from "components/Loading";
import { Colors } from "assets/colors";

const Switch = styled.div`
  a {
    font-size: 15px;
    color: #555 !important;

    span {
      display: block;
      color: ${Colors.primary1};
      text-decoration: underline;
      font-weight: bold;
    }
  }
`;

const Submit = styled.div`
  a {
    color: ${Colors.white} !important;
    font-weight: bold;
    font-size: 15px !important;
  }
`;

const ForgetWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  a {
    font-size: 15px;
    color: #555 !important;
  }
`;

const EmailVerification = (props) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({});
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState({});

  const handleChange = (e) => {
    const { value, name } = e.target;

    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    submit();
  }, []);

  const submit = () => {
    setLoading(true);
    const { id } = props.match.params;
    // console.log(id);
    props
      .verifyEmail({ key: id })
      .then((res) => {
        setRedirect(true);
        setLoading(false);
      })
      .catch((e) => {
        // props.makeAlert('Wrong Credentials!', 'error')
        console.log(e);
        setLoading(false);
      });
  };

  // useEffect(() => {
  //   setRedirect(props.isLoggedIn)
  // }, [props.isLoggedIn])

  // const { from } = props.location.state || { from: { pathname: '/console' } }

  // if (redirect === true) {
  //   return <Redirect to={from} />
  // }

  return (
    <>
      <Navbar />
      <div
        style={{ height: "100vh", display: "flex", justifyContent: "center" }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col xl={10} lg={12} md={9}>
              <Card className="o-hidden border-0 shadow-lg my-5">
                <Card.Body className="p-3">
                  <Row className="">
                    <Col md={12}>
                      <h3 className="h4 text-dark my-2 text-center font-weight-bold">
                        Email Verification
                      </h3>
                      <p className="p-0 m-0 text-center">
                        Your account succesfully verified, now you can login to
                        your account.
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <div className="col-lg-12">
                      <div className="p-5">
                        <Submit className="text-center">
                          <button
                            disabled={loading}
                            onClick={() => props.history.push("/console")}
                            className="btn btn-user"
                            style={{
                              color: Colors.white,
                              background: "${Colors.primary1}",
                              minWidth: 120,
                              fontWeight: "bold",
                            }}
                          >
                            {!loading ? (
                              "Go to Dashboard"
                            ) : (
                              <Loading nopadding={true} />
                            )}
                          </button>
                        </Submit>
                      </div>
                    </div>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    locale: state.locale[state.locale.active],
  }),
  { verifyEmail, loginUser, makeAlert }
)(EmailVerification);
