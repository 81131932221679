import React, { useEffect } from "react";

import PhoneInput from "react-phone-input-2";
import { StyledInput } from "./styles";

const CustomInput = ({
  name,
  value,
  setValue,
  placeholder,
  classes,
  label,
  labelClasses,
  icon,
  id,
  type,
  error,
  onChange,
  ...props
}) => {
  useEffect(() => {}, [value]);
  return (
    <StyledInput error={error} className={classes}>
      {type === "phone" ? (
        <PhoneInput
          dropdownClass="phone-dropdown"
          buttonClass="phone-country"
          inputClass="input phone-input no-border"
          containerClass="input phone-input"
          dropdownClass="dropdown-box"
          onlyCountries={["ca"]}
          country="ca"
          countryCodeEditable={false}
          value={value}
          onChange={(phone) => {
            setValue(phone);
          }}
        />
      ) : (
        <input
          className="input"
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange ? onChange : (e) => setValue(e.target.value)}
          id={id}
          type={type}
        />
      )}
      <label className={`label ${labelClasses}`}>{label}</label>
      <span className="stick-right">{icon}</span>
    </StyledInput>
  );
};

CustomInput.propTypes = {};

export default CustomInput;
