import styled from "styled-components";
import { Colors } from "assets/colors";

export const StyledButton = styled.button`
  margin: 5px;
  background: ${Colors.primary1};
  border-radius: 3px;
  font-weight: bold;
  font-size: 16px;
  padding: 12px 50px;
  border: none;
  text-align: center;
  text-transform: uppercase;
  color: #000;

  @media (max-width: 1120px) {
    background: ${(p) =>
      p.loading ? "#fff !important" : `${Colors.primary1}`};
    border: ${(p) =>
      p.loading
        ? "solid 1px rgba(21, 21, 21, 0.15) !important"
        : `${Colors.primary1}`};
    color: ${(p) => (p.loading ? "rgba(21, 21, 21, 0.3) !important" : "")};
    box-shadow: ${(p) => (p.loading ? "none !important" : "")};
  }

  .loading {
    padding: 0;
  }

  :hover {
    box-shadow: 0 2px 10px #0000004d;
    color: #000;
  }

  :active {
    box-shadow: none;
    transform: scale(0.98);
    color: #000;
  }

  :disabled {
    background: #8a8a8a !important;
    color: #fff !important;
  }
`;
