import { Button, Col, Row, Spinner } from "react-bootstrap";
import React, { useState } from "react";

import { connect } from "react-redux";
import { getData } from "../../utils";
import { onboardMerchant } from "api/orders";
import { useRouteMatch } from "react-router-dom";

const OnboardingNeeded = ({ locale, ...props }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    props
      .onboardMerchant({
        base_url: window.location.origin,
      })
      .then((res) => {
        window.location = getData(res)?.url;
        setLoading(false);
      });
  };

  // return (
  //   <Button onClick={handleClick}>
  //     {loading ? <Spinner animation='border' size='sm' /> : 'Enable'}
  //   </Button>
  // )

  return (
    <Row className="fulls">
      <Col xs={12} sm={12} className="text-center">
        <h2 className="h3">Stripe Onboarding Needed</h2>
      </Col>
      <Col xs={12} sm={12} className="text-center">
        <h4>
          In order to enable ordering and payment for your restaurants
          <br />
          you need to onboard with Stripe
        </h4>
        <br />
        <Button onClick={handleClick}>
          {loading ? <Spinner animation="border" /> : "Start Onboarding"}
        </Button>
      </Col>
    </Row>
  );
};

export default connect(
  (state) => ({
    locale: state.locale[state.locale.active],
  }),
  {
    onboardMerchant,
  }
)(OnboardingNeeded);
