import React, { useState, useEffect, useCallback, useContext } from "react";
import { StyledWrapper } from "./styles";
import { ReactComponent as SearchIcon } from "assets/image/search.svg";
import { ReactComponent as AddIcon } from "assets/image/add.svg";
import { ReactComponent as DiscountTagIcon } from "assets/image/discountTag.svg";
import { getDiscountsApiCall } from "api/discounts";
import Button from "components/common/Button";
import { useDebouncedCallback } from "use-debounce";
import usePagination from "../../hooks/usePagination";
import Table from "./Table";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getData } from "utils";
import axios from "axios";
import NoPermissions from "components/commons/NoPermissions";

const Discounts = () => {
  const [search, setSearch] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [discountsLoading, setDiscountsLoading] = useState(true);
  const [discounts, setDiscounts] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
  const history = useHistory();
  const dispatch = useDispatch();
  const restaurantId = useSelector((state) => state.user?.selectedRestaurant);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const permissions = useSelector((state) => state.user.permissions);

  const getDiscounts = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel();
    }
    setCancelTokenSource({ ...axios.CancelToken.source() });
  };

  useEffect(() => {
    if (cancelTokenSource) {
      setDiscountsLoading(true);
      setDiscounts([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
      dispatch(
        getDiscountsApiCall(restaurantId, page, searchKey, {
          cancelToken: cancelTokenSource?.token,
        })
      )
        .then((res) => {
          if (getData(res)?.results) {
            if (getData(res).results[0]?.id) {
              setDiscounts([...getData(res).results]);
              setPagination({
                count: getData(res).count,
                next: getData(res).next,
                previous: getData(res).previous,
              });
              setDiscountsLoading(false);
            } else {
              setDiscounts([]);
            }
          } else {
            setDiscounts([]);
          }
        })
        .catch((err) => {
          setDiscountsLoading(false);
        });
    }
  }, [cancelTokenSource]);

  const { setPage, page, pagination, setPagination, paginationEl } =
    usePagination(getDiscounts, discountsLoading);

  useEffect(() => {
    getDiscounts();
  }, [searchKey]);

  const debounced = useDebouncedCallback(async (value) => {
    resetPaging();
    setSearchKey(value);
  }, 500);

  const resetPaging = () => {
    setPage(1);
    setPagination({
      count: null,
      next: null,
      previous: null,
    });
  };

  const reload = () => {
    if (page !== 1) {
      resetPaging();
    }
    getDiscounts();
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    // setSearchKey(e.target.value);
    debounced.callback(e.target.value);
  };

  const handleCreateDiscount = (row) => {
    history.push(`/console/discounts/create`);
  };

  if (!permissions.includes("__panel__") && !permissions.includes("discount")) {
    return (
      <StyledWrapper>
        <NoPermissions />
      </StyledWrapper>
    );
  }

  return (
    <StyledWrapper>
      {/* Search Discounts */}
      <div className="search-input flex-row">
        <input
          type="text"
          className="input"
          name="search"
          autoComplete="off"
          value={search}
          onChange={handleSearchChange}
          placeholder="Search discounts"
        />
        <SearchIcon className="label" />
      </div>

      {/* Title row  */}
      <div className="title-row flex-row">
        <div className="flex-row">
          <h1 className="title">Discounts</h1>
          <Button
            label="Create new"
            leadingIcon={<AddIcon />}
            type="text"
            classes="add-btn"
            type="neutral"
            onClick={() => handleCreateDiscount()}
          />
        </div>
      </div>
      {discounts?.length ? (
        <div className="table-box">
          <Table
            loading={discountsLoading}
            discounts={discounts}
            pagination={pagination}
            resetPaging={resetPaging}
            reload={reload}
          />
          <div className="pagination">{paginationEl}</div>
        </div>
      ) : (
        <div className="empty">
          <DiscountTagIcon className="tag" />
          <p className="empty-title">You don't have any discounts!</p>
          <p className="empty-desc">
            Create discounts that apply automatically to a customer’s cart.
          </p>
          <Button
            label="Create a discount"
            leadingIcon={<AddIcon />}
            type="text"
            classes="empty-btn"
            type="filled"
            onClick={() => handleCreateDiscount()}
          />
        </div>
      )}
    </StyledWrapper>
  );
};

export default Discounts;
