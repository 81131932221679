import { apiUrl, baseUrl } from "constants";

import axios from "axios";
import { errorHandling } from "../utils";
import jsCookie from "js-cookie";
import { makeAlert } from "actions";
import { logoutWhenUnauthorized } from "actions/user";

export const fetchApi =
  (reqUrl, reqMethod, reqData, reqHasFiles, api, resolveError, cancelObj) =>
  (dispatch, getState) =>
    new Promise(async (resolve, reject) => {
      let config = {
        url: `${apiUrl}/${reqUrl}`,
        method: reqMethod || "get",
        headers: {
          "Content-Type": reqHasFiles
            ? "multipart/form-data"
            : "application/json",
        },
        cancelToken: cancelObj?.cancelToken,
      };
      if (api) {
        config.url = `${baseUrl}api/${reqUrl}`;
      }

      // const token = await jsCookie.get('token')
      const token = getState().user.token;

      if (token) {
        config.headers.Authorization = "Token " + token;
      }

      if (reqHasFiles) {
        let formData = new FormData();
        if (reqData)
          Object.entries(reqData).map(([key, val]) => {
            if (Array.isArray(val))
              val.map((item) => {
                formData.append(key, item);
              });
            else {
              formData.append(key, val);
            }
          });
        config.data = formData;
      }

      if (reqMethod !== "GET" && reqData && !reqHasFiles) config.data = reqData;

      try {
        axios(config)
          .then((res) => {
            const { data, status, statusText } = res;

            errorHandling(res);
            dispatch(logoutWhenUnauthorized(status));
            if (status === 204) {
              resolve(data || status);
            } else if (status < 299) {
              resolve(data);
            } else {
              dispatch(makeAlert(statusText, "error"));
              reject({
                error: statusText,
              });
            }
          })
          .catch((e) => {
            if (axios.isCancel(e)) {
                console.log("Request canceled", e.message);
            } else {
              // handle error
            }
            if (e && e.response) {
                errorHandling(e.response);
              dispatch(logoutWhenUnauthorized(e.response?.status));
              const { data, status, statusText } = e.response;
                if(status === 500) {
                    console.log(e, status)
                    dispatch(
                        makeAlert(
                            "Sorry, we couldn't complete your request, please try again!",
                            "error"
                        )
                    );
                    reject({
                        data,
                        status,
                        statusText,
                    });
                }
                if (status === 410) {
                  resolve({
                  data,
                  status,
                  statusText,
                });
              } else if (status < 500) {
                  if (!resolveError) {
                  if (data.message || data.data?.message) {
                  } else {
                    if (
                      Object.keys(data).includes("token") &&
                      data.token[0] === "Invalid value"
                    ) {
                        dispatch(
                        makeAlert(
                          "Invalid password reset link, please reset password again.",
                          "error"
                        )
                      );
                    }
                  }
                } else {
                    resolve({
                        data,
                        status,
                        statusText,
                    });
                }
              }
              reject({
                data,
                status,
                statusText,
              });
            } else {
                reject({
                data: "",
                status: "",
                statusText: "",
              });
            }
          });
      } catch (e) {
        reject(e);
      }
    });
