import 'react-phone-input-2/lib/style.css';

import { Elements, CardNumberElement } from '@stripe/react-stripe-js';
import React, { lazy, useEffect, useState } from 'react';
import {
  Redirect,
  BrowserRouter as Router,
  useHistory,
} from 'react-router-dom';

import { register, userInfo } from 'api/auth';

import AddressInput from 'components/forms/AddressInput';
import AsyncSelect from 'react-select/async';
import { Button } from 'react-bootstrap';

import CustomInput from 'components/customInput';
import CustomSelect from 'components/customSelect';
import { ReactComponent as EmailIcon } from 'assets/image/email.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as HomeIcon } from 'assets/image/home.svg';
import { ReactComponent as KeyIcon } from 'assets/image/key.svg';
import { ReactComponent as PersonIcon } from 'assets/image/person.svg';
import { ReactComponent as PhoneIcon } from 'assets/image/phone.svg';
import PhoneInput from 'react-phone-input-2';
import { STRIPE_PUBLIC_KEY, isDevelopment } from 'constants';
import Select from 'react-select';
import { connect, useDispatch } from 'react-redux';

import { getParent } from 'api/restaurant';
import { loadStripe } from '@stripe/stripe-js';
import { loginUser } from 'actions/user';
import { makeAlert } from 'actions';
import moment from 'moment';
import styled from 'styled-components';
import { googleForwardGeocoding } from '../../api/geocoding';
import PersonalDetails from './PersonalDetails';
import BusinessDetails from './BusinessDetails';
import AuthenticationCard from './AuthenticationCard';
import { getParentByIP, getParents } from '../../api/restaurant';
import { checkRegex, isFailedRequest, getData } from '../../utils';
import PaymentDetails from './PaymentDetails';

const customStylesAsyncSelect = {
  option: (provided, state) => ({
    ...provided,
  }),
  control: () => ({
    borderRadius: '0',
    border: 'none',
    borderBottom: '1px solid #ccc',
    display: 'flex',
    flexDirection: 'row',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-countent: center;

  @media (max-width: 1120px) {
    flex-direction: column;
  }
`;

const StyledPersonal = styled.div`
  width: 100%;
  padding: 0 55px;

  @media (max-width: 1120px) {
    padding: 0 11px 100px 25px;
  }

  .m-left {
    margin-left: 20px;
    @media (max-width: 1120px) {
      margin-left: 0;
    }
  }
`;

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

function Register({ locale, ...props }) {
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [redirect, setRedirect] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [parent, setParent] = useState({});
  const [parents, setParents] = useState([]);
  const [plan, setPlan] = useState({});
  const [interval, setInterval] = useState('month');
  const [stripe, setStripe] = useState(null);
  const [elements, setElements] = useState(null);
  const [stripeComplete, setStripeComplete] = useState(false);
  const [stripeError, setStripeError] = useState(null);
  const [businessName, setBusinessName] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [businessAddress, setBusinessAddress] = useState(null);
  const [choosedLocation, setChoosedLocation] = useState(null);
  const [businessNumber, setBusinessNumber] = useState(null);
  const [province, setProvince] = useState(null);
  const [email, setEmail] = useState(null);
  const [error, setError] = useState(null);
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [businessNameError, setBusinessNameError] = useState(null);
  const [businessNumberError, setBusinessNumberError] = useState(null);
  const [businessAddressError, setBusinessAddressError] = useState(null);
  const [provinceError, setProvinceError] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [password2, setPassword2] = useState(null);
  const [password2Error, setPassword2Error] = useState(null);
  const [phoneNumberError, setPhoneNumberError] = useState(null);
  const [isCardFilled, setIsCardFilled] = useState(false);
  const dispatch = useDispatch();

  const handleParentChange = (event, action) => {
    setParent(parents.find((p) => p.id === event.value));
  };

  const handleParentInputChange = async (value, action) => {
    if (value !== '') {
      const res = await props.getParents(value);
      return getData(res)?.results.map((p) => ({
        label: p.title,
        value: p.id,
      }));
    }
  };

  const handleChangeStripe = (event) => {
    if (event.complete) {
      setStripeComplete(true);
    } else {
      setStripeComplete(false);
    }
    if (event.error) {
      setStripeError(event.error.message);
    } else {
      setStripeError();
    }
  };

  const handleSubmit = async (payload) => {
    console.log('handleSubmit');
    if (
      (parent?.is_subscription_required ? step === 3 : step === 2) &&
      (await finalFormValidation())
    ) {
      setLoading(true);
      const data = {
        email,
        password1: password,
        password2: password,
        province,
        business_number: businessNumber,
        address: businessAddress?.place_name,
        location: choosedLocation,
        business_name: businessName,
        date_of_birth: '2001-06-01',
        gender: 1,
        is_merchant: true,
        yearly: interval === 'year',
      };

      if (parent?.is_subscription_required) {
        await stripe
          .createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement),
          })
          .then((res) => {
            // console.log(res);
            data.payment_method = getData(res)?.paymentMethod?.id;
          });
      }

      if (findSelectedParentSlug()) {
        data.parent = findSelectedParentSlug();
      }

      props
        .register(data)
        .then((res) => {
          setLoading(false);
          // GOOGLE TAG MANAGER: PUSH DATA TO DATA LAYER ON LOGIN --- REFACTOR LATER
          if (typeof window !== 'undefined') {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'registration',
              authenticationMethod: 'email',
              email: data.email,
              province: data.province,
            });
          }
          if (!isFailedRequest(res)) {
            history.push('/login', { needVerification: true });
          }
        })
        .catch((err) => {
          setLoading(false);
          // GOOGLE TAG MANAGER: PUSH DATA TO DATA LAYER ON LOGIN --- REFACTOR LATER
          if (typeof window !== 'undefined') {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'error',
              errorType: 'registration error',
              errorMessage: err,
            });
          }
        });
    }
  };

  // const checkAddessValidity = (address) => {
  //   if (address !== "") {
  //     dispatch(
  //       googleForwardGeocoding(address, [
  //         props.coords?.longitude,
  //         props.coords?.latitude,
  //       ])
  //     ).then((res) => {
  //       if (res.find((res) => res.place_name === address)) {
  //         console.log(address);
  //         return true;
  //       }
  //     });
  //     return false;
  //   }
  //   return false;
  // };

  useEffect(() => {
    /** Validation: Check address with mapbox available locations */
    if (businessAddress) {
      if (businessAddress?.place_name) {
        setBusinessAddressError(false);
      } else {
        setBusinessAddressError(true);
      }
    }
  }, [businessAddress]);

  const personalFormValidation = () => {
    setFirstNameError(false);
    setLastNameError(false);
    setBusinessNameError(false);
    setBusinessNumberError(false);
    setProvinceError(false);
    setEmailError(false);
    setPhoneNumberError(false);
    setPasswordError(false);
    setPassword2Error(false);
    let valid = true;
    const emailRegx =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!firstName) {
      valid = false;
      setFirstNameError(true);
    }
    if (!lastName) {
      valid = false;
      setLastNameError(true);
    }
    if (!businessName) {
      valid = false;
      setBusinessNameError(true);
    }
    if (!businessNumber || businessNumber?.length < 10) {
      valid = false;
      setBusinessNumberError(true);
    }
    if (businessAddressError || !businessAddress) {
      valid = false;
      setBusinessAddressError(true);
    }
    if (!province) {
      valid = false;
      setProvinceError(true);
    }
    if (!email) {
      valid = false;
      setEmailError(true);
    }
    if (email) {
      if (!checkRegex(email, emailRegx)) {
        setEmailError(true);
        valid = false;
      } else {
        setEmailError(false);
      }
    }
    return valid;
  };
  const personalForm2Validation = (doNotSetError) => {
    if (!doNotSetError) {
      setPasswordError(false);
      setPassword2Error(false);
    }
    let valid = true;

    if (!password || password2 !== password) {
      valid = false;
      if (!doNotSetError) setPasswordError(true);
    }
    if (!password2 || password2 !== password) {
      valid = false;
      if (!doNotSetError) setPassword2Error(true);
    }
    return valid;
  };

  const finalFormValidation = async () => {
    if (parent?.is_subscription_required) {
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
      });
      if (result?.error) {
        return false;
      }
      return true;
    }
    return true;
  };

  const findSelectedParentSlug = () => {
    const parentSlug = parents?.find((p) => p?.title === province)?.slug;
    // console.log(parentSlug);
    return parentSlug;
  };

  let StepContent = null;

  switch (step) {
  case 0:
    StepContent = (
      <div
        style={{
          width: '90%',
        }}
      >
        <div
          style={{
            padding: '35px 0',
            color: '#222',
            fontSize: 20,
          }}
        >
          Omnicart only operates in selected Canadian cities. Please choose
          from the list below to continue.
        </div>
        <AsyncSelect
          isDisabled={initialLoading}
          value={{
            label: parent.title,
            value: parent.id,
          }}
          name="parents"
          cacheOptions
          defaultOptions={parents.map((p) => ({
            label: p.name,
            value: p.id,
          }))}
          loadOptions={handleParentInputChange}
          onChange={handleParentChange}
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder="Type parent..."
          styles={customStylesAsyncSelect}
        />
      </div>
    );
    break;
  case 1:
    StepContent = (
      <Personal
        setChoosedLocation={setChoosedLocation}
        handleChangeStripe={handleChangeStripe}
        setStripe={setStripe}
        stripeError={stripeError}
        setElements={setElements}
        PARENT_SLUG={findSelectedParentSlug()}
        firstName={firstName}
        setFirstName={setFirstName}
        firstNameError={firstNameError}
        lastName={lastName}
        setLastName={setLastName}
        lastNameError={lastNameError}
        businessName={businessName}
        setBusinessName={setBusinessName}
        businessAddress={businessAddress?.place_name}
        setBusinessAddress={setBusinessAddress}
        businessNumber={businessNumber}
        setBusinessNumber={setBusinessNumber}
        province={province}
        setProvince={setProvince}
        email={email}
        setEmail={setEmail}
        error={error}
        businessNameError={businessNameError}
        businessNumberError={businessNumberError}
        businessAddressError={businessAddressError}
        provinceError={provinceError}
        emailError={emailError}
        parents={parents}
      />
    );
    break;

  case 2:
    StepContent = (
      <PersonalAuth
        password={password}
        setPassword={setPassword}
        passwordError={passwordError}
        password2={password2}
        setPassword2={setPassword2}
        password2Error={password2Error}
      />
    );
    break;

  case 3:
    StepContent = null;
    break;
  default:
    StepContent = null;
  }

  useEffect(() => {
    setRedirect(props.isLoggedIn);
  }, [props.isLoggedIn]);

  const handleGetParents = async () => {
    setInitialLoading(true);
    const data = getData(await props.getParents());
    setParent(data[0]);
    setParents(data.results);
    setInitialLoading(false);
  };

  useEffect(() => {
    if (findSelectedParentSlug()) {
      setStep(1);
      props
        .getParent(findSelectedParentSlug())
        .then((res) => {
          if (getData(res)?.id) {
            setParent(getData(res));
            // setPlan(getData(res)?.plans.find((p) => p.interval === interval));
            setInitialLoading(false);
          }
        })
        .catch((err) => {
          props.history.push('/not-found');
        });
    } else {
      handleGetParents();
    }
  }, [findSelectedParentSlug()]);

  // useEffect(() => {
  //   if (parent.plans)
  //     setPlan(parent.plans.find((p) => p.interval === interval));
  // }, [interval]);

  const { from } = props.location.state || { from: { pathname: '/console' } };

  if (redirect === true) {
    return <Redirect to={from} />;
  }

  const handleNextRegisterForm = () => {
    /** Determine next step if form is validate */
    if (personalFormValidation() && step === 1) {
      setStep(2);
    } else if (personalForm2Validation() && step === 2) {
      if (parent.is_subscription_required) {
        setStep(3);
      } else {

      }
    }
  };

  return (
    <AuthenticationCard
      title="Let’s sign you up"
      subtitle={parent?.title}
      register
      step={step}
      setStep={setStep}
      handleSubmit={handleSubmit}
      disabled={!parent}
      loading={loading}
      initialLoading={initialLoading}
      locale={locale}
      handleNextRegisterForm={handleNextRegisterForm}
      isCardFilled={isCardFilled}
      parent={parent}
      personalForm2Validation={personalForm2Validation}
    >
      {StepContent}
      {findSelectedParentSlug() && <div />}
    </AuthenticationCard>
  );
}

export default connect(
  (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    locale: state.locale[state.locale.active],
  }),
  {
    register, loginUser, makeAlert, getParent, getParents, getParentByIP,
  },
)(Register);

function Personal({
  firstName,
  setFirstName,
  firstNameError,
  lastName,
  setLastName,
  lastNameError,
  businessName,
  setBusinessName,
  businessAddress,
  setBusinessAddress,
  businessNumber,
  setBusinessNumber,
  province,
  setProvince,
  email,
  setEmail,
  businessNameError,
  businessNumberError,
  businessAddressError,
  provinceError,
  emailError,
  parents,
  setChoosedLocation,
  ...props
}) {
  const options = (parents) => {
    const selectOptions = [];
    parents.map((p) => {
      if (p.id === 1) return;
      if (p.parents && Array.isArray(p.parents) && p.parents.length > 0) {
        p.parents.forEach((parent) => {
          selectOptions.push({
            value: `${p.name}(${parent.title})`,
            label: `${p.name}(${parent.title})`,
          });
        });
      } else {
        selectOptions.push({ value: p.name, label: p.name });
      }
    });
    return selectOptions;
  };

  return (
    <StyledPersonal>
      <StyledRow>
        <CustomInput
          name="firstName"
          id="firstName"
          label={(
            <>
              <PersonIcon />
              &nbsp;&nbsp;|&nbsp;&nbsp;
            </>
          )}
          type="text"
          placeholder="First name"
          value={firstName}
          setValue={setFirstName}
          error={firstNameError}
        />
        <CustomInput
          name="lastName"
          id="lastName"
          label={(
            <>
              <PersonIcon />
              &nbsp;&nbsp;|&nbsp;&nbsp;
            </>
          )}
          type="text"
          placeholder="Last name"
          value={lastName}
          setValue={setLastName}
          error={lastNameError}
          classes="m-left"
        />
      </StyledRow>
      <CustomInput
        name="email"
        id="email"
        label={(
          <>
            <EmailIcon />
            &nbsp;&nbsp;|&nbsp;&nbsp;
          </>
        )}
        type="email"
        placeholder="Email"
        value={email}
        setValue={setEmail}
        error={emailError}
      />
      <CustomInput
        name="businessName"
        id="businessName"
        label={(
          <>
            <PersonIcon />
            &nbsp;&nbsp;|&nbsp;&nbsp;
          </>
        )}
        type="text"
        placeholder="Business Name"
        value={businessName}
        setValue={setBusinessName}
        error={businessNameError}
      />
      <AddressInput
        name="businessAddress"
        id="businessAddress"
        label={(
          <>
            <HomeIcon />
            &nbsp;&nbsp;|&nbsp;&nbsp;
          </>
        )}
        type="text"
        placeholder="Business Address"
        value={businessAddress}
        setValue={setBusinessAddress}
        error={businessAddressError}
        onSelect={(event) => {
          setChoosedLocation({
            lat: event.center[1],
            lng: event.center[0],
          });
        }}
      />

      <StyledRow>
        <CustomSelect
          isSearchable={false}
          options={options(parents)}
          onChange={setProvince}
          placeholder="City..."
          setValue={setProvince}
          value={province}
          error={provinceError}
          id="province"
        />
        <CustomInput
          name="businessNumber"
          id="businessNumber"
          label={(
            <>
              <PhoneIcon />
              &nbsp;&nbsp;|&nbsp;&nbsp;
            </>
          )}
          type="phone"
          placeholder="Business Number"
          value={businessNumber}
          setValue={setBusinessNumber}
          error={businessNumberError}
          classes="m-left"
        />
      </StyledRow>
    </StyledPersonal>
  );
}

function PersonalAuth({
  password,
  setPassword,
  password2,
  setPassword2,
  passwordError,
  password2Error,
  ...props
}) {
  const [showPass, setShowPass] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  return (
    <StyledPersonal>
      <CustomInput
        name="password"
        label={<KeyIcon />}
        type={showPass ? 'text' : 'password'}
        placeholder="Password"
        value={password}
        setValue={setPassword}
        error={passwordError}
        id="password"
        icon={(
          <div onClick={() => setShowPass(!showPass)}>
            {!showPass ? 'Show' : 'Hide'}
          </div>
        )}
      />
      <CustomInput
        name="password2"
        label={<KeyIcon />}
        type={showPass2 ? 'text' : 'password'}
        placeholder="Re-type password"
        value={password2}
        setValue={setPassword2}
        error={password2Error}
        id="password"
        icon={(
          <div onClick={() => setShowPass2(!showPass2)}>
            {!showPass2 ? 'Show' : 'Hide'}
          </div>
        )}
      />
    </StyledPersonal>
  );
}
