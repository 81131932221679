import React, { useEffect, useState, useReducer } from "react";
import { StyledWrapper } from "./styles";
import { ReactComponent as DropdownIcon } from "assets/image/dropdown.svg";
import { ReactComponent as InfoIcon } from "assets/image/info.svg";
import { ReactComponent as SearchIcon } from "assets/image/search.svg";
import { ReactComponent as CrossIcon } from "assets/image/cross.svg";
import Input from "components/common/Input";
import Dropdown from "components/common/Dropdown";
import BadgeLabel from "components/common/BadgeLabel";
import RadioButtonGroup from "components/RadioButtonGroup";
import Button from "components/common/Button";
import Checkbox from "components/common/Checkbox";
import WeekDaySelection from "components/common/WeekDaySelection";
import TimePicker from "components/common/TimePicker";
import DatePickerComponent from "components/common/DatePicker";
import { createDiscountApiCall, editDiscountApiCall } from "api/discounts";
import { useContext } from "react";
import { ModalContext } from "../../../context/modalContext";
import ProductModal from "../ProductModal";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { getData, actionCreator } from "../../../utils";
import { getItemsApiCall } from "../../../api/discounts";
import moment from "moment";
import SkeletonLoading from "components/common/SkeletonLoading";
import DeleteModal from "../../Discounts/DeleteModal";
import { toast } from "react-toastify";
import * as types from "./reducer/actionTypes";
import { datesReducer } from "./reducer/reducer";

const Form = ({ discountData, ...props }) => {
  const [name, setName] = useState("");
  const [selectedTypeOption, setSelectedTypeOption] = useState("");
  const [percentage, setPercentage] = useState("");
  const [items, setItems] = useState([]);
  const [product, setProduct] = useState("");
  const [dates, datesDispatch] = useReducer(datesReducer, {
    selectedDateTypeTitle: "",
    isWeekly: false,
    weekdays: "",
    fromHour: "",
    toHour: "",
    isDaily: false,
    hasEndDate: false,
    startDate: "",
    startDateFromHour: "",
    endDate: "",
    endDateFromHour: "",
  });
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [itemsLoading, setItemsLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState([...items]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const restaurantId = useSelector((state) => state.user?.selectedRestaurant);
  const { id } = useParams();
  const TYPE_OPTIONS = [
    {
      id: 0,
      title: "Percentage",
    },
    {
      id: 1,
      title: "Fixed amount",
    },
  ];
  const APPLIES_TO_OPTIONS = [
    {
      id: 0,
      title: "All products",
    },
    {
      id: 1,
      title: "Specific products",
    },
  ];
  const [selectedAppliedToOption, setSelectedAppliedToOption] = useState(
    APPLIES_TO_OPTIONS[1]
  );
  const DATES_OPTIONS = [
    {
      title: "Forever",
      element: <div className="">Forever</div>,
    },
    {
      title: "Custom duration",
      element: <div className="">Custom duration</div>,
    },
  ];
  const { modal, handleModal, modalContent, hideCloseBtn, classes } =
    useContext(ModalContext);

  const fillDiscountData = () => {
    // Set the initial data in the form
    if (discountData?.id) {
      setSelectedTypeOption(
        discountData?.discount_type?.value === 0
          ? TYPE_OPTIONS[0]
          : TYPE_OPTIONS[1]
      );
      setItemsLoading(true);
      if (discountData.on_all_items) {
        setSelectedAppliedToOption(APPLIES_TO_OPTIONS[0]);
      } else {
        setSelectedAppliedToOption(APPLIES_TO_OPTIONS[1]);
      }
      dispatch(getItemsApiCall(restaurantId))
        .then((res) => {
          setItems(getData(res));
          setItemsLoading(false);
          let temp = [
            ...getData(res).map((i) => {
              return { ...i, checked: false };
            }),
          ];
          for (let i = 0; i < discountData.items.length; i++) {
            const elementId = discountData.items[i]?.id;
            let index = temp.findIndex((i) => i.id === elementId);
            temp[index] = { ...temp[index], checked: true };
          }
          setSelectedItems([...temp]);
        })
        .catch((err) => setItemsLoading(false));
      setName(discountData.name);
      setPercentage(discountData?.amount);
      datesDispatch({
        type: types.FILL_DATES_FIELDS,
        payload: {
          selectedDateTypeTitle:
            discountData?.starts_at ||
            discountData.active_hours[0]?.weekdays?.length
              ? DATES_OPTIONS[1].title
              : DATES_OPTIONS[0].title,
          isWeekly:
            discountData.active_hours[0]?.weekdays?.length > 0 ? true : false,
          weekdays: discountData?.active_hours[0]?.weekdays || [],
          fromHour: discountData?.active_hours[0]?.from_hour,
          toHour: discountData?.active_hours[0]?.to_hour,
          isDaily: discountData?.starts_at ? true : false,
          hasEndDate: discountData?.ends_at ? true : false,
          startDate: discountData?.starts_at,
          startDateFromHour: discountData?.starts_at
            ? moment(discountData?.starts_at)
            : "",
          endDate: discountData?.ends_at,
          endDateFromHour: discountData?.ends_at
            ? moment(discountData?.ends_at)
            : "",
        },
      });
    }
  };

  const handleInitialValue = () => {
    setSelectedTypeOption(TYPE_OPTIONS[0]);
    setSelectedAppliedToOption(APPLIES_TO_OPTIONS[0]);
    datesDispatch({
      type: types.SET_SELECTED_DATE_TYPE_TITLE,
      payload: DATES_OPTIONS[0].title,
    });
    dispatch(getItemsApiCall(restaurantId)).then((res) => {
      setItems(getData(res));
      setItemsLoading(false);
      setSelectedItems([
        ...getData(res).map((i) => {
          return { ...i, checked: false };
        }),
      ]);
    });
  };

  useEffect(() => {
    if (discountData) {
      fillDiscountData();
    } else if (!id) {
      handleInitialValue();
    }
  }, [discountData]);

  useEffect(() => {
    /** Select all items */
    if (selectedAppliedToOption.id === APPLIES_TO_OPTIONS[0].id) {
      setSelectedItems(
        [...items].map((i) => {
          return { ...i, checked: true };
        })
      );
    } else {
      setSelectedItems(
        [...items].map((i) => {
          return { ...i, checked: false };
        })
      );
    }
  }, [selectedAppliedToOption]);

  const handleCheckIsForever = () => {
    return (
      dates.selectedDateTypeTitle === DATES_OPTIONS[0]?.title ||
      (!dates.startDateFromHour && !dates.endDateFromHour && !dates.isWeekly)
    );
  };

  const checkIsValid = () => {
    let valid = true;
    /** If user selects weekly but don't selects a day, we show him an error */
    if (dates.isWeekly && dates.weekdays?.length === 0) {
      toast.error("Please select at least one active day in the week");
      valid = false;
    }
    return valid;
  };

  const handleSave = () => {
    if (!checkIsValid()) {
      return;
    }
    console.log(dates.endDate, dates.endDateFromHour);
    let data = {
      name: name,
      description: "",
      amount: percentage,
      discount_type: TYPE_OPTIONS.find(
        (t) => t?.title === selectedTypeOption?.title
      )?.id,
      starts_at:
        dates.startDate && dates.startDateFromHour
          ? new Date(
              moment(
                `${moment(dates.startDate).format("ddd MMM D YYYY")} ${
                  dates.startDateFromHour
                }`
              )
            )
          : null,
      ends_at:
        dates.endDate && dates.endDateFromHour
          ? new Date(
              moment(
                `${moment(dates.endDate).format("ddd MMM D YYYY")} ${
                  dates.endDateFromHour
                }`
              )
            )
          : null,
      is_active: true,
      items: selectedItems.filter((i) => i.checked).map((i) => i.id),
      on_all_items: selectedAppliedToOption?.id === 0 ? true : false,
      active_hours: handleCheckIsForever()
        ? []
        : [
            {
              from_hour:
                dates.isWeekly && dates.fromHour ? dates.fromHour : "00:00:00",
              to_hour:
                dates.isWeekly && dates.toHour ? dates.toHour : "23:59:59",
              weekdays: dates.isWeekly
                ? dates.weekdays?.length
                  ? dates.weekdays
                  : []
                : [1, 2, 3, 4, 5, 6, 7],
            },
          ],
    };
    setSaveBtnLoading(true);
    if (discountData) {
      dispatch(editDiscountApiCall(restaurantId, discountData.id, data))
        .then((res) => {
          setSaveBtnLoading(false);
          handleGoToListPage();
        })
        .catch((err) => setSaveBtnLoading(false));
    } else {
      dispatch(createDiscountApiCall(restaurantId, data))
        .then((res) => {
          setSaveBtnLoading(false);
          handleGoToListPage();
        })
        .catch((err) => setSaveBtnLoading(false));
    }
  };

  const handleGoToListPage = () => {
    history.push("/console/discounts");
  };

  const handleCancel = () => {
    handleGoToListPage();
  };

  const handleBrowse = (val) => {
    handleModal(
      <ProductModal
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        value={val}
      />
    );
  };

  const handleClearItem = (item) => {
    let index = selectedItems.findIndex((i) => i.id === item?.id);
    let temp = [...selectedItems];
    temp[index] = { ...temp[index], checked: !temp[index].checked };
    setSelectedItems([...temp]);
  };

  const handleDelete = () => {
    handleModal(<DeleteModal row={discountData} reload={handleGoToListPage} />);
  };

  const handleChangeDateTypeTitle = (val) => {
    datesDispatch({
      type: types.SET_SELECTED_DATE_TYPE_TITLE,
      payload: val,
    });
    if (val === DATES_OPTIONS[0]?.title) {
      datesDispatch({
        type: types.RESET_DATES,
      });
    }
  };

  const handleResetWeekdays = () => {
    if (dates.isWeekly) {
      datesDispatch({
        type: types.RESET_WEEKS,
      });
    } else {
      datesDispatch({
        type: types.SET_IS_WEEKLY,
        payload: true,
      });
    }
  };

  const handleResetDaily = () => {
    if (dates.isDaily) {
      datesDispatch({
        type: types.RESET_DAILY,
      });
    } else {
      datesDispatch({
        type: types.SET_IS_DAILY,
        payload: true,
      });
    }
  };

  const handleResetEndDate = () => {
    if (dates.hasEndDate) {
      datesDispatch(actionCreator(types.RESET_END_DATE));
    } else {
      datesDispatch(actionCreator(types.SET_HAS_END_DATE, true));
    }
  };

  return (
    <StyledWrapper>
      {/* Name */}
      <div className="box box--top">
        <h6 className="box-title">Name</h6>
        <Input
          type="text"
          id="discount-name"
          value={name}
          onChange={setName}
          placeholder="e.g. Thanksgiving"
          inputClasses="name-input"
        />
      </div>
      {/* Type */}
      <div className="box">
        <h6 className="box-title">Type</h6>
        <RadioButtonGroup
          options={TYPE_OPTIONS}
          selectedOption={selectedTypeOption}
          setSelectedOption={setSelectedTypeOption}
          classes="list"
          labelClasses="list-item-custom"
          checkmarkClasses="checkmark-custom"
        />
      </div>
      {/* Percentage off*/}
      <div className="box">
        <h6 className="box-title">{selectedTypeOption?.title}</h6>
        <Input
          type="number"
          id="percentage-name"
          value={percentage}
          onKeyDown={(e) => {
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
          }}
          onChange={(val) => {
            if (val === "" || val > 0) {
              setPercentage(val);
            }
          }}
          placeholder=""
          inputClasses="percentage-input"
          trailingIcon={
            selectedTypeOption?.title === TYPE_OPTIONS[0]?.title ? (
              <span className="input-label">%</span>
            ) : (
              <span className="input-label">$</span>
            )
          }
        />
      </div>
      {/* Applies to */}
      <div className="box">
        <h6 className="box-title">Applies to</h6>
        {itemsLoading ? (
          <SkeletonLoading borderRadius={4} width={456} height={32} count={2} />
        ) : (
          <RadioButtonGroup
            options={APPLIES_TO_OPTIONS}
            selectedOption={selectedAppliedToOption}
            setSelectedOption={setSelectedAppliedToOption}
            classes="list"
            labelClasses="list-item-custom"
            checkmarkClasses="checkmark-custom"
          />
        )}

        {/* Specific products */}
        {selectedAppliedToOption.title ===
        APPLIES_TO_OPTIONS[0].title ? null : (
          <>
            {itemsLoading ? (
              <SkeletonLoading
                borderRadius={4}
                width={456}
                height={32}
                count={1}
              />
            ) : (
              <div className="input-row">
                <Input
                  type="text"
                  id="find-product"
                  value={product}
                  onChange={(val) => {
                    setProduct(() => {
                      if (!modal) {
                        handleBrowse(val);
                      }
                      return val;
                    });
                  }}
                  placeholder="Find a product"
                  inputClasses="percentage-input search-input"
                  leadingIcon={<SearchIcon className="search-icon" />}
                />
                <Button
                  onClick={() => handleBrowse("")}
                  label="Browse"
                  type="neutral"
                  classes="btn browse"
                />
              </div>
            )}
            <div className="selected">
              {selectedItems
                ?.filter((s) => s.checked)
                .map((item) => (
                  <div key={item.id} className="row-box">
                    <div className="item">
                      <div className="item-left">
                        <p className="item-title">{item?.name}</p>
                        <p className="item-price">${item?.value}</p>
                      </div>
                      <div className="item-right">
                        {" "}
                        {item?.images[0]?.image_thumbnail && (
                          <img src={item?.images[0]?.image_thumbnail} />
                        )}
                        <CrossIcon onClick={() => handleClearItem(item)} />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
      {/* Active Dates */}
      <div className="box">
        <h6 className="box-title">Active dates</h6>
        <Dropdown
          label={
            <BadgeLabel
              label={
                DATES_OPTIONS.find(
                  (t) => t.title === dates.selectedDateTypeTitle
                )?.element
              }
              trailingIcon={<DropdownIcon />}
              classes="date-dropdown-label"
            />
          }
          options={DATES_OPTIONS}
          onChange={handleChangeDateTypeTitle}
          selected={null}
          noArrow
          btnClass="date-dropdown-label-btn"
          btnOpenClass="date-dropdown-label-btn-open"
          listClass="date-dropdown-label-list"
          optionClass="date-dropdown-label-list-item"
        />
        <p className="info-note">
          <InfoIcon />
          <span>
            Select the right time rule for your discounts. Discounts will be
            deactivated once time rule ends.
          </span>
        </p>

        {dates.selectedDateTypeTitle === DATES_OPTIONS[1].title ? (
          <div>
            <Checkbox
              classes=""
              name="weekly"
              checked={dates.isWeekly}
              onChange={handleResetWeekdays}
              label={"Weekly"}
              labelClasses="item-label"
              classes="item-checkbox"
            />
            {dates.isWeekly ? (
              <div className="weekday-box">
                <WeekDaySelection
                  weekdays={[...dates.weekdays]}
                  setWeekdays={(w) =>
                    datesDispatch({
                      type: types.SET_WEEK_DAYS,
                      payload: w,
                    })
                  }
                />
                <div className="weekly-row">
                  <TimePicker
                    time={dates.fromHour}
                    setTime={(time) =>
                      datesDispatch(actionCreator(types.SET_FROM_HOUR, time))
                    }
                  />
                  <span className="to">To</span>
                  <TimePicker
                    time={dates.toHour}
                    setTime={(time) =>
                      datesDispatch(actionCreator(types.SET_TO_HOUR, time))
                    }
                  />
                </div>
              </div>
            ) : null}
            <Checkbox
              classes=""
              name="daily"
              checked={dates.isDaily}
              onChange={handleResetDaily}
              label={"Date range"}
              labelClasses="item-label"
              classes="item-checkbox"
            />
            {dates.isDaily ? (
              <div className="date-box">
                <p className="date-range">Start Date</p>
                <div className="date-row">
                  <DatePickerComponent
                    selectedDate={dates.startDate}
                    setSelectedDate={(date) =>
                      datesDispatch(actionCreator(types.SET_START_DATE, date))
                    }
                  />
                  <TimePicker
                    time={dates.startDateFromHour}
                    setTime={(time) =>
                      datesDispatch(
                        actionCreator(types.SET_START_DATE_FROM_HOUR, time)
                      )
                    }
                  />
                </div>

                <Checkbox
                  classes=""
                  name="hasEndDate"
                  checked={dates.hasEndDate}
                  onChange={handleResetEndDate}
                  label={"Set end date"}
                  labelClasses="item-label item-label--sub"
                  classes="item-checkbox"
                />
                {dates.hasEndDate ? (
                  <>
                    <p className="date-range">End Date</p>
                    <div className="date-row">
                      <DatePickerComponent
                        selectedDate={dates.endDate}
                        setSelectedDate={(date) =>
                          datesDispatch(actionCreator(types.SET_END_DATE, date))
                        }
                      />
                      <TimePicker
                        time={dates.endDateFromHour}
                        setTime={(time) =>
                          datesDispatch(
                            actionCreator(types.SET_END_DATE_FROM_HOUR, time)
                          )
                        }
                      />
                    </div>
                  </>
                ) : null}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      <div className="box--bottom">
        {discountData ? (
          <Button
            onClick={handleDelete}
            label="Delete"
            type="neutral"
            classes="btn btn-delete"
          />
        ) : null}
        <div className="btns-right">
          <Button
            onClick={handleCancel}
            label="Cancel"
            type="neutral"
            classes="btn btn-cancel"
          />
          {discountData ? (
            <Button
              onClick={handleSave}
              label="Save"
              type="filled"
              classes="btn btn-create"
              loading={saveBtnLoading}
              disabled={!name || !percentage || itemsLoading}
            />
          ) : (
            <Button
              onClick={handleSave}
              label="Create discount"
              type="filled"
              classes="btn btn-create"
              loading={saveBtnLoading}
              disabled={!name || !percentage || itemsLoading}
            />
          )}
        </div>
      </div>
    </StyledWrapper>
  );
};

export default Form;
