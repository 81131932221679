import styled from "styled-components";
import ArrowDown from "assets/image/arrowDown.svg";
import { Colors } from "assets/colors";

export const StyledDatePicker = styled.div`
  position: relative;

  .date-picker {
    position: absolute;
    top: 37px;
    right: 0;
    z-index: 1000;
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.03),
      0px 0.5px 0.5px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    height: 344px;
    cursor: initial;

    &-bottom {
      width: 100%;
      display: flex;
      height: 52px;
      border-top: 1px solid #eeedf0;
      padding: 4px 16px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      bottom: 0;

      &-btn {
        position: relative;
        margin: 0 0 0 10px;
        background: #ffffff;
        border: none;
        border-radius: 4px;
        color: #4f566a;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        box-shadow: 0px 2px 5px rgb(60 66 87 / 8%),
          0px 0px 0px 1px rgb(60 66 87 / 16%), 0px 1px 1px rgb(0 0 0 / 12%);
        padding: 6px 8px;
        height: 30px;
        color: #050505;

        &:hover {
          box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.22),
            0px 0px 0px 1px rgba(60, 66, 87, 0.16),
            0px 1px 1px rgba(0, 0, 0, 0.16);
        }

        &--colored {
          background-color: #3765f0;
          color: white;
          box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
            0px 0px 0px 1px #3765f0, 0px 1px 1px rgba(0, 0, 0, 0.12);

          &:hover {
            box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.18),
              0px 0px 0px 1px #2f5ce3, 0px 1px 1px rgba(0, 0, 0, 0.16);
            background: #2f5ce3;
          }

          &:disabled {
            background-color: #afc1f9;
            box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
              0px 0px 0px 1px #afc1f9, 0px 1px 1px rgba(0, 0, 0, 0.12);
          }
        }
      }
    }
  }

  .date-picker-bottom--date {
    position: absolute;
    left: 99px;
    color: ${Colors.textPrimary1};
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }

  .react-datepicker {
    display: flex;
    border: none;
    margin: 14px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .react-datepicker__month-container {
    border-right: 1px solid #f0f0f0;
    padding-bottom: 5px;
    height: 290px;
    padding-top: 14px;

    &:last-child {
      border-right: none;
    }
  }

  .react-datepicker__header {
    background: white;
    border-bottom: none;
    margin: 0;
    padding-top: 0;
  }

  .react-datepicker__navigation--previous {
    left: 0;
  }

  .react-datepicker__navigation--next {
    right: 0;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  .react-datepicker__year-dropdown-container {
    position: absolute;
    top: 267px;
    left: -9px;
    width: 72px;
    z-index: 2;
    position: relative;
    margin: 0 0 0 10px;
    background: #ffffff;
    border: none;
    border-radius: 4px;
    color: #4f566a;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    box-shadow: 0px 2px 5px rgb(60 66 87 / 8%),
      0px 0px 0px 1px rgb(60 66 87 / 16%), 0px 1px 1px rgb(0 0 0 / 12%);
    padding: 6px 8px;
    height: 30px;
    color: #050505;

    &:hover {
      box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.22),
        0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.16);
    }
  }

  .react-datepicker__header__dropdown {
    height: 0;
    width: 0;
  }

  .react-datepicker__year-read-view {
    text-align: left;
  }

  .react-datepicker__day--today {
    background-color: white;
    color: #19181a;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
  }

  .react-datepicker__day-name {
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    /* color: gray; */
    color: #19181a;
    opacity: 0.4;
    margin-top: 12px;
  }

  .react-datepicker__day {
    width: 32px;
    height: 32px;
    margin: 0;
    padding-top: 3px;
    border: 1px solid transparent;
    background-color: white;
    border-radius: 0;
    color: #19181a;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
  }

  .react-datepicker__day:hover {
    border: 1px solid ${Colors.textPrimary1};
    background: white;
    color: #19181a;
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--range-end {
    background: ${Colors.textPrimary1} !important;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: white !important;
    border-radius: 50%;
  }

  .react-datepicker__day--range-end {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: -1px;
      left: -58%;
      width: 16px;
      height: 32px;
      background-color: transparent;
      box-shadow: 15px 0 0 #f0f0f0;
      z-index: -1;
      border-radius: 0;
    }
  }

  .react-datepicker__day--selected {
    position: relative;

    &:before {
      display: ${(p) => (p.$hasEndDate ? "block" : "none")};
      content: "";
      position: absolute;
      top: -1px;
      right: 46%;
      width: 16px;
      height: 32px;
      background-color: transparent;
      box-shadow: 15px 0 0 #f0f0f0;
      z-index: -1;
      border-radius: 0;
    }
  }

  .react-datepicker__day--outside-month {
    background-color: white !important;
    background-image: none !important;
    box-shadow: none !important;

    &:before {
      display: none;
    }
  }

  .react-datepicker__day--001.react-datepicker__day--in-range {
    background-image: linear-gradient(to right, white 11%, #f0f0f0);
    border: none;
  }

  /* .react-datepicker__day--030.react-datepicker__day--in-range {
    background-image: linear-gradient(to left, white 11%, #f0f0f0);
    border: none;
  } */

  .react-datepicker__day--031.react-datepicker__day--in-range {
    background-image: linear-gradient(to left, white 11%, #f0f0f0);
    border: none;
  }

  .react-datepicker__day--in-range {
    background: #f0f0f0;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #19181a;
  }

  .react-datepicker__day--in-selecting-range {
    background: #f0f0f0;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #19181a;
    position: relative;

    &:hover {
      /* box-shadow: -10px 0 0 #f0f0f0; */
    }
  }

  .react-datepicker__week {
    display: flex;
    position: relative;
    z-index: 1;
  }

  .react-datepicker__year-read-view--down-arrow {
    position: relative;
    border-top: none !important;

    &::before {
      display: none;
    }

    &::after {
      content: "";
      position: absolute;
      top: -5px;
      right: -6px;
      width: 12px;
      height: 12px;
      background-image: url(${ArrowDown});
    }
  }

  .react-datepicker__year-dropdown {
    background: #ffffff;
    border: 1px solid #f0f0f0;
    width: 100%;
    height: 100px;
    left: 0;
    bottom: 0;
    margin-top: 3px;
    overflow: auto;
  }

  .react-datepicker__year-option {
    padding: 7px 0;
  }

  .react-datepicker__year-option--selected {
    display: none;
  }

  .react-datepicker__navigation--next {
    border: none !important;
    height: 26px;
    width: 26px;
    margin-top: 10px;
    right: -8px;
    top: 2px;

    &::after {
      content: "";
      position: absolute;
      top: 13%;
      right: 19%;
      width: 17px;
      height: 15px;
      padding: 2px;
      transform: rotate(-90deg);
      background-image: url(${ArrowDown});
      background-repeat: no-repeat;
    }
  }

  .react-datepicker__year-read-view {
    color: #3c4257;
    visibility: visible !important;
  }

  .react-datepicker__year-option {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #3c4257;
    margin-left: 6px;
    width: 50px;

    &:hover {
      background: #f3f3f3;
      border-radius: 4px;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #3c4257;
      margin-left: 6px;
      width: 50px;
    }
  }

  .react-datepicker__navigation--previous {
    border: none !important;
    height: 26px;
    width: 26px;
    margin-top: 10px;
    left: -8px;
    top: 2px;

    &::after {
      content: "";
      position: absolute;
      top: 13%;
      right: 19%;
      width: 17px;
      height: 21px;
      padding: 2px;
      transform: rotate(90deg);
      background-image: url(${ArrowDown});
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  /* 
  .react-datepicker__day--today {
    background-color: gray;
  } */
`;
