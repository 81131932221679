import React, { useRef, useState } from "react";
import { StyledDropdownWrapper } from "./styles";
import PropTypes from "prop-types";
import { ReactComponent as ArrowDownIcon } from "assets/image/arrowDown.svg";
import Button from "../Button";
import { useOutsideClick } from "../../../hooks/useClickOutside";

const DropdownGeneral = ({
  classes,
  label,
  listView,
  onChange,
  selected,
  noArrow,
  btnOpenClass,
  btnClass,
  ...props
}) => {
  const [showList, setShowList] = useState(false);
  const btnRef = useRef(null);
  const dropdownRef = useRef(null);

  useOutsideClick(dropdownRef, () => handleCloseList());

  const handleCloseList = () => {
    setShowList(false);
  };

  return (
    <StyledDropdownWrapper>
      <Button
        ref={btnRef}
        label={label}
        type="text"
        onClick={() => (!showList ? setShowList(true) : null)}
        trailingIcon={
          noArrow ? null : showList ? (
            <ArrowDownIcon className="arrow-smooth arrow-up" />
          ) : (
            <ArrowDownIcon className="arrow-smooth" />
          )
        }
        classes={showList ? `btn-open ${btnOpenClass}` : `btn  ${btnClass}`}
      />
      {showList ? <div ref={dropdownRef}>{listView}</div> : null}
    </StyledDropdownWrapper>
  );
};

DropdownGeneral.propTypes = {
  classes: PropTypes.string,
  label: PropTypes.any,
  listView: PropTypes.any,
  onChange: PropTypes.func,
  noArrow: PropTypes.bool,
  btnOpenClass: PropTypes.string,
  btnClass: PropTypes.string,
};

export default DropdownGeneral;
