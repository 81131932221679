import styled from "styled-components";
import { Colors } from "assets/colors";

export const StyledButtonWrapper = styled.div`
  .button {
    position: relative;
    margin: 0;
    background: #ffffff;
    border: none;
    border-radius: 4px;
    color: #4f566a;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    box-shadow: 0px 2px 5px rgb(60 66 87 / 8%),
      0px 0px 0px 1px rgb(60 66 87 / 16%), 0px 1px 1px rgb(0 0 0 / 12%);
    padding: 6px 8px;
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 9px 10px 9px 8px;
  }

  .filled {
    background: #2b64f5;
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px #2b64f5,
      0px 1px 1px rgba(0, 0, 0, 0.12);
    color: #ffffff;

    &:hover {
      box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.2), 0px 0px 0px 1px #2b64f5,
        0px 1px 1px rgba(0, 0, 0, 0.12);
    }

    &:focus {
      box-shadow: 0px 0px 0px 2px rgba(43, 100, 245, 0.4),
        0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px #2b64f5,
        0px 1px 1px rgba(0, 0, 0, 0.12);
    }

    &:active {
      box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px #2b64f5,
        0px 1px 1px rgba(0, 0, 0, 0.12);
    }

    &:disabled {
      box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px #2b64f5,
        0px 1px 1px rgba(0, 0, 0, 0.12);
      background: #2b64f5;
      opacity: 0.5;
    }
  }

  .neutral {
    color: ${Colors.textPrimary1};

    &--active {
      color: #050505;
      box-shadow: 0px 2px 5px rgb(60 66 87 / 8%), 0px 0px 0px 1px #3c4257,
        0px 1px 1px rgb(0 0 0 / 12%);
    }

    &:hover {
      box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.2),
        0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);
    }

    &:focus {
      box-shadow: 0px 0px 0px 2px rgba(43, 100, 245, 0.4),
        0px 2px 5px rgba(60, 66, 87, 0.08),
        0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);
    }

    &:active {
      box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
        0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);
    }

    &:disabled {
      color: ${Colors.textPrimary1};
      box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
        0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);
    }
  }

  .text {
    border: none;
    box-shadow: none;
    color: ${Colors.textPrimary1};

    &:disabled {
      color: ${Colors.textPrimary1};
    }
  }

  .icon {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;

    &--lead {
      margin-right: 8px;
    }

    &--trail {
      margin-left: 8px;
    }
  }

  .spinner-border {
    width: 15px;
    height: 15px;
    border-width: 2px;
    margin-right: 10.5px;
  }
`;
