import React from 'react';
import { Row, Card, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Subheader = styled.p`
	color: #848080;
    font-size: 16px;
    letter-spacing: 0.63px;
    line-height: 19px;
`
const Header = styled.h4`
    color: #404040;
    	
    font-size: 23px;
    font-weight: bold;
    letter-spacing: 0.43px;
    line-height: 28px;
    margin-bottom: 5px;
`
const StyledButton = styled(Button)`
	color: #686767;
    	
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 20px;
`

const BillingCard = props => {
    return (
        <>
            <Row ref={props.ref} className="pb-5">
                <Col md={12}>
                    <Card className="p-3 border-0 shadow-sm overflow-hidden">
                        <Card.Body>
                            <div className="mb-4">
                                <div className="align-items-center d-flex justify-content-between">
                                    <Header>{props.title}</Header>

                                    {
                                        props.editText
                                            ? <StyledButton variant="link" onClick={props.onClick || null}>
                                                {props.editText}
                                                {' '}
                                                {
                                                    props.icon
                                                        ? <FontAwesomeIcon icon={props.icon} />
                                                        : null
                                                }
                                            </StyledButton>
                                            : null
                                    }

                                </div>
                                <Subheader className="w-75">{props.subtitle}</Subheader>
                            </div>

                            {props.children}

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default BillingCard
