import React, { lazy, useEffect } from "react";

import AddEditModifiers from "containers/modifiers/AddEditModifiers";
import AddNewItem from "containers/items/AddNewItem";
import AddNewMenu from "containers/menus/AddNewMenu";
import Analytics from "containers/analytics";
import AppDownload from "containers/app-download";
import Checkout from "containers/checkout";
import CreateDiscounts from "containers/CreateDiscounts";
import Discounts from "containers/Discounts";
import EmailVerification from "containers/login/EmailVerification";
import ForgetPassword from "containers/login/ForgetPassword";
import ForgetPasswordConfirm from "containers/login/ForgetPasswordConfirm";
import Home from "containers/home";
import Items from "containers/items";
import Landing from "containers/landing";
import Layout from "components/Layout";
import Login from "containers/login";
import Modifiers from "containers/modifiers";
import NotFound from "containers/404";
import NotFoundPage from "components/notFound";
import Orders from "containers/Orders";
import PaymentStatus from "containers/checkout/PaymentStatus";
import Plain from "components/Layout/Plain";
import { Preview, ViewMenu } from "containers/menus";
import Privacy from "containers/privacy";
import PrivateRoute from "components/routes/PrivateRoute";
import Profile from "containers/profile";
import Promotions from "containers/promotions";
import PwaDeliveryPrivacy from "containers/pwaPrivacy/delivery";
import PwaDeliveryTerms from "containers/terms/deliveryTerms";
import PwaPrivacy from "containers/pwaPrivacy";
import Register from "containers/login/Register";
import RestaurantPreview from "containers/preview";
import { Route } from "react-router-dom";
import StripeReauth from "containers/stripe/Reauth";
import StripeReturn from "containers/stripe/Return";
import Terms from "containers/terms";
import { connect } from "react-redux";
import styled from "styled-components";

// import ProfileQRCode from "containers/profile/QRCode";














// import Billing from "containers/billing";

const routes = [
  {
    path: "/",
    exact: true,
    layout: Plain,
    component: Landing,
  },
  {
    path: "/checkout",
    exact: true,
    layout: Plain,
    component: Checkout,
  },
  {
    path: "/payment-status",
    exact: true,
    layout: Plain,
    component: PaymentStatus,
  },
  {
    path: "/login",
    exact: true,
    layout: Plain,
    component: Login,
  },
  {
    path: "/register",
    exact: true,
    layout: Plain,
    component: Register,
  },
  {
    path: "/register/:parent",
    exact: true,
    layout: Plain,
    component: Register,
  },
  {
    path: "/forget-password",
    exact: true,
    layout: Plain,
    component: ForgetPassword,
  },
  {
    path: "/redirect/api/user/rest-auth/password/reset/confirm/:uid/:token/",
    exact: true,
    layout: Plain,
    component: ForgetPasswordConfirm,
  },
  {
    path: "/redirect/api/v1/user/rest-auth/password/reset/confirm/:uid/:token/",
    exact: true,
    layout: Plain,
    component: ForgetPasswordConfirm,
  },
  {
    path: "/redirect/verify-email/:id",
    exact: true,
    layout: Plain,
    component: EmailVerification,
  },
  {
    path: "/stripe_reauth/:account",
    exact: true,
    layout: Plain,
    component: StripeReauth,
    private: true,
  },
  {
    path: "/stripe_return/:account",
    exact: true,
    layout: Plain,
    component: StripeReturn,
    private: true,
  },
  {
    path: "/console",
    exact: true,
    layout: Layout,
    component: Home,
    private: true,
  },
  {
    path: "/console/profile-info",
    exact: true,
    layout: Layout,
    private: true,
    component: Profile,
  },
  // {
  //   path: "/console/profile-qrcode",
  //   exact: true,
  //   layout: Layout,
  //   private: true,
  //   component: ProfileQRCode,
  // },
  {
    path: "/console/create-items",
    exact: true,
    layout: Layout,
    private: true,
    component: Items,
  },
  {
    path: "/console/create-items/add",
    exact: true,
    layout: Layout,
    private: true,
    component: AddNewItem,
  },
  {
    path: "/console/create-items/edit/:id",
    exact: true,
    layout: Layout,
    private: true,
    component: AddNewItem,
  },
  {
    path: "/console/create-modifiers/",
    exact: true,
    layout: Layout,
    private: true,
    component: Modifiers,
  },
  {
    path: "/console/create-modifiers/add/",
    exact: true,
    layout: Layout,
    private: true,
    component: AddEditModifiers,
  },
  {
    path: "/console/create-modifiers/edit/:id/",
    exact: true,
    layout: Layout,
    private: true,
    component: AddEditModifiers,
  },
  {
    path: "/console/create-menus",
    exact: true,
    layout: Layout,
    private: true,
    component: Preview,
  },
  {
    path: "/console/create-menus/add",
    exact: true,
    layout: Layout,
    private: true,
    component: AddNewMenu,
  },
  {
    path: "/console/create-menus/edit/:id",
    exact: true,
    layout: Layout,
    private: true,
    component: AddNewMenu,
  },
  {
    path: "/console/create-menus/view/:id",
    exact: true,
    layout: Layout,
    private: true,
    component: ViewMenu,
  },
  {
    path: "/console/create-categories/:id/items/",
    exact: true,
    layout: Layout,
    private: true,
    component: Items,
  },
  {
    path: "/console/analytics",
    exact: true,
    layout: Layout,
    private: true,
    component: Analytics,
    layoutStyles: {
      paddingTop: "30px",
      paddingLeft: "0",
      paddingRight: "0",
      width: "100%",
      maxWidth: "none",
      height: "fit-content",
      minHeight: "100vh",
      overflowY: "auto",
    },
  },
  {
    path: "/console/discounts",
    exact: true,
    layout: Layout,
    private: true,
    component: Discounts,
    layoutStyles: { padding: 0, maxWidth: "unset" },
  },
  {
    path: "/console/discounts/create",
    exact: true,
    layout: Layout,
    private: true,
    component: CreateDiscounts,
    layoutStyles: { padding: 0, maxWidth: "unset" },
  },
  {
    path: "/console/discounts/edit/:id",
    exact: true,
    layout: Layout,
    private: true,
    component: CreateDiscounts,
    layoutStyles: { padding: 0, maxWidth: "unset" },
  },
  {
    path: "/console/promotions",
    exact: true,
    layout: Layout,
    private: true,
    component: Promotions,
  },
  // {
  //   path: "/console/orders",
  //   exact: true,
  //   layout: Layout,
  //   private: true,
  //   component: Orders,
  // },
  {
    path: "/console/orders",
    exact: true,
    layout: Layout,
    private: true,
    component: Orders,
    layoutStyles: { padding: 0, maxWidth: "unset" },
  },
  // {
  //   path: "/console/billing",
  //   exact: true,
  //   layout: Layout,
  //   private: true,
  //   component: Billing,
  // },
  {
    path: "/console/preview/:id",
    exact: true,
    layout: Plain,
    private: true,
    component: RestaurantPreview,
  },
  {
    path: "/privacy",
    exact: true,
    layout: Plain,
    private: false,
    component: Privacy,
  },
  {
    path: "/privacy/pwa",
    exact: false,
    layout: Plain,
    private: false,
    component: PwaPrivacy,
  },
  {
    path: "/privacy/delivery",
    exact: false,
    layout: Plain,
    private: false,
    component: PwaDeliveryPrivacy,
  },
  {
    path: "/terms/delivery",
    exact: false,
    layout: Plain,
    private: false,
    component: PwaDeliveryTerms,
  },
  {
    path: "/terms",
    exact: true,
    layout: Plain,
    private: false,
    component: Terms,
  },
  {
    path: "/app-download",
    exact: true,
    layout: Plain,
    private: false,
    component: AppDownload,
  },
  {
    path: "/404",
    layout: Plain,
    exact: true,
    component: NotFoundPage,
    private: false,
    name: "notFound",
  },
];

const StyledWrapper = styled.div`
   direction: ${(p) => (p.rtl ? "rtl" : "ltr")};
   text-align: ${(p) => (p.rtl ? "right" : "left")};
  * {
    /* font-family: 'Vazir', sans-serif; */
  }

  @media (min-width: 768px) {
    .sidebar {
      width: 14rem!important;
    }
    .offset-md-3 {
      margin-right: 25%;
    }
  }
  .modal-content {
    direction: ${(p) => (p.rtl ? "rtl" : "ltr")};
    text-align: ${(p) => (p.rtl ? "right" : "left")};
    /* font-family: 'Vazir', sans-serif; */
  }
  .sidebar .nav-item .nav-link {
    text-align: ${(p) => (p.rtl ? "right" : "left")};
  }

  .sidebar .nav-item .nav-link i {
    margin-${(p) => (!p.rtl ? "right" : "left")}: .25rem;
  }
  .sidebar {
  }
  #wrapper #content-wrapper {
    overflow-x: hidden !important;
    /* margin-${(p) => (p.rtl ? "right" : "left")}: 6.5rem; */
    margin-${(p) => (p.rtl ? "right" : "left")}: 0;
    background: #fff;
  }

  @media (min-width: 768px) {
    #wrapper #content-wrapper {
      /* margin-${(p) => (p.rtl ? "right" : "left")}: 224px; */
      margin-${(p) => (p.rtl ? "right" : "left")}: 0;
    }
  }
`;

// Route containers
const Routes = (props) => {
  const routeTypes = {
    private: PrivateRoute,
    normal: Route,
  };

  if (
    routes.filter((r) =>
      r.path.includes(props.location.pathname.toString().split("/")[1])
    )?.length === 0
  ) {
    return <Route path="*" component={NotFoundPage} />;
  }

  return (
    <StyledWrapper rtl={props.rtl}>
      {routes.map((route, index) => {
        const RouteTypes = routeTypes[route.private ? "private" : "normal"];
        return (
          <RouteTypes
            key={index}
            path={route.path}
            exact={route.exact}
            component={(props) => {
              return (
                <route.layout {...props} layoutStyles={route.layoutStyles}>
                  <route.component {...props} />
                </route.layout>
              );
            }}
          />
        );
      })}
    </StyledWrapper>
  );
};

export default connect((state) => ({
  rtl: state.locale[state.locale.active].rtl,
}))(Routes);
