import React from "react";
import { StyledTooltip } from "./styles";

const Tooltip = ({
  tooltipBoxClasses,
  label,
  tooltipBody,
  tooltipBodyClasses,
  ...props
}) => {
  return (
    <StyledTooltip>
      <div className={`tooltip-wrapper ${tooltipBoxClasses}`}>
        {label}
        <div className={`tooltip-body ${tooltipBodyClasses}`}>
          {tooltipBody}
        </div>
      </div>
    </StyledTooltip>
  );
};

export default Tooltip;
