import React, { useEffect, useRef, useState } from "react";
import { StyledClose, StyledInput, StyledOptions } from "./styles";

import CustomButton from "../customButton";
import { ReactComponent as DownArrowIcon } from "assets/image/downArrow.svg";
import Select from "react-select";

const CustomSelect = ({
  name,
  value,
  setValue,
  placeholder,
  classes,
  options,
  isSearchable,
  error,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);

  const handleClose = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (document) {
      document.addEventListener("mousedown", handleClose);

      return function cleanup() {
        document.removeEventListener("mousedown", handleClose);
      };
    }
  }, [document]);

  const handleSelect = (val) => {
    setValue(val);
    setOpen(false);
  };

  return (
    <StyledInput error={error} className={classes}>
      <div
        onClick={() => setOpen(!open)}
        className={`input react-select-container ${value && "selected"} ${
          open && "focused"
        }`}
      >
        <p>{value || placeholder}</p>
      </div>
      {open && (
        <StyledOptions ref={menuRef}>
          {options.map((option) => (
            <div className="item" onClick={() => handleSelect(option.value)}>
              <p>{option.value}</p>
            </div>
          ))}
          <StyledClose>
            <CustomButton
              onClick={() => setOpen(false)}
              classes="btn"
              label="Close"
            />
          </StyledClose>
        </StyledOptions>
      )}
      <span className="arrow">
        <DownArrowIcon className={open && "rotate"} />
      </span>
      {/* <Select
        value={value}
        className="input react-select-container"
        placeholder={placeholder}
        isSearchable={false}
        options={options}
        onChange={(e) => setValue(e, value)}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          borderColor: "transparent",
          height: "100%",
          minHeight: "none",
        })}
        styles={colourStyles}
      /> */}
    </StyledInput>
  );
};

CustomSelect.propTypes = {};

export default CustomSelect;
