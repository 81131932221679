import * as orderStatus from "../../../utils/status";

import React, { useContext } from "react";

import BadgeLabel from "components/common/BadgeLabel";
import { ReactComponent as DeliveryIcon } from "assets/image/delivery.svg";
import { ModalContext } from "../../../context/modalContext";
import OrderDialogue from "../OrderDialogue";
import PaginateTable from "components/PaginateTable";
import { ReactComponent as PickUpIcon } from "assets/image/pickUp.svg";
import RealTime from "components/RealTime";
import { ReactComponent as ScheduledIcon } from "assets/image/schedule.svg";
import { StyledTable } from "./styles";
import { changeAlarmStatus } from "actions/orders";
import { getEndTime } from "../../../utils";
import moment from "moment";
import { useDispatch } from "react-redux";

const Table = ({
  categoryTitle,
  ordersLoading,
  orders,
  noHeader,
  handleOrderUpdate,
  handleOrderSeen,
  ...props
}) => {
  const { handleModal } = useContext(ModalContext);
  const dispatch = useDispatch();

  const isScheduled = (order) => {
    return order?.scheduled_for;
  };

  const getStatus = (order) => {
    /** Map order state to a badge */
    switch (order?.state) {
      case orderStatus.NEW:
        return (
          <div
            className={`status status--new ${
              isScheduled(order) ? "status--scheduled" : ""
            }`}
          >
            {isScheduled(order) ? <ScheduledIcon /> : null}
            <span>New</span>
          </div>
        );
        break;
      case orderStatus.IN_PROGRESS:
        return (
          <div
            className={`status status--accepted ${
              isScheduled(order) ? "status--scheduled" : ""
            }`}
          >
            {isScheduled(order) ? <ScheduledIcon /> : null}
            <span>Accepted</span>
          </div>
        );
        break;
      case orderStatus.CANCELLED_BY_RESTAURANT:
        return <div className="status status--cancelled">Cancelled</div>;
        break;
      case orderStatus.READY:
        return <div className="status status--ready">Ready</div>;
        break;
      case orderStatus.ON_THE_WAY:
        return <div className="status status--on-the-way">On the Way</div>;
        break;
      case orderStatus.COMPLETED:
        return <div className="status status--completed">Completed</div>;
        break;
      case orderStatus.IN_PROGRESS_UPDATED:
        return <div className="status status--accepted">Accepted</div>;
        break;
      case orderStatus.DELIVERED:
        return <div className="status status--delivered">DELIVERED</div>;
        break;
      case orderStatus.READY_UPDATED:
        return <div className="status status--ready">Ready</div>;
        break;
      case orderStatus.STRANDED:
        return <div className="">-</div>;
        break;
      case orderStatus.REJECTED_BY_RESTAURANT:
        return <div className="status status--rejected">Rejected</div>;
        break;

      default:
        break;
    }
  };

  const isEstimatedTimePassed = (order) => {
    let remainingTime = moment(
      order?.estimated_pickup_time || order?.estimate
    ).diff(moment(), "minutes");

    if (remainingTime && remainingTime < 0 && isOrderInProgressOrReady(order)) {
      return true;
    }
    return false;
  };

  const isOrderInProgressOrReady = (order) => {
    /** Return true if it's In Progress/Ready */
    if (
      order?.state === orderStatus.IN_PROGRESS ||
      order?.state === orderStatus.READY ||
      order?.state === orderStatus.IN_PROGRESS_UPDATED ||
      order?.state === orderStatus.READY_UPDATED
    ) {
      return true;
    }
    return false;
  };

  const getOrderType = (order) => {
    if (order?.order_type === 1) {
      return (
        <BadgeLabel
          label="Pick Up"
          leadingIcon={<PickUpIcon />}
          classes="type-size"
        />
      );
    } else if (order?.order_type === 2) {
      return (
        <BadgeLabel
          label="Delivery"
          leadingIcon={<DeliveryIcon />}
          classes="type-size"
        />
      );
    }
  };

  const getOrderItems = (order) => {
    let items = order?.order_items?.map(
      (item) => `${item?.item_name} (${item?.qty})`
    );
    if (items?.length <= 2) {
      return (
        <div>
          {items?.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      );
    } else if (items?.length > 2) {
      return (
        <div>
          {items?.map((item, index) => {
            if (index <= 2) {
              return <div key={index}>{item}</div>;
            } else {
              return null;
            }
          })}
          <div className="items--more">Show all orders</div>
        </div>
      );
    }
  };

  const handleEstimateTime = (order) => {
    /** Map estimated time to a text */
    if (
      order.state === orderStatus.NEW ||
      order.state === orderStatus.CANCELLED_BY_RESTAURANT ||
      order.state === orderStatus.REJECTED_BY_RESTAURANT
    ) {
      return "-";
    }
    if (order.order_type === 2) {
      /** Delivery type of orders */

      if (isOrderInProgressOrReady(order) && order.delivery_state < 2) {
        /** for 'In Progress' / 'Ready' but no driver assigned yet: 'Looking for Drivers' */
        return "Looking for drivers";
      }
      if (isOrderInProgressOrReady(order) && order.delivery_state >= 2) {
        /** for 'In Progress' / 'Ready' and driver assigned: Estimated minutes to arrival */
        let remainingTime = moment(order?.estimated_pickup_time).diff(
          moment(),
          "minutes"
        );
        if (remainingTime > 2) {
          return remainingTime + " minutes until arrive";
        } else if (remainingTime >= 0) {
          /** for 'In Progress' / 'Ready' and status is 'Driver Arriving' in Spare (or less than 2 mins): 'Driver Arriving' */
          return "Driver Arriving";
        } else {
          return moment(order.estimate).fromNow();
        }
      }
      if (order.state === orderStatus.COMPLETED) {
        return "Done";
      }
    } else {
      if (order.estimate && isOrderInProgressOrReady(order)) {
        return moment(order.estimate).fromNow();
      }
      if (order.state === orderStatus.COMPLETED) {
        return "Done";
      }
    }
  };

  const showModal = (row) => {
    handleModal(
      <OrderDialogue
        data={row}
        getStatus={getStatus}
        getOrderType={getOrderType}
        handleOrderUpdate={handleOrderUpdate}
      />,
      false,
      "modal-close-btn"
    );
  };

  const handleOpenOrder = (row) => {
    showModal(row);
    dispatch(changeAlarmStatus(false));
    if (row?.new) {
      let visitedRow = { ...row };
      visitedRow.new = false;
      handleOrderSeen(row?.id, visitedRow);
    }
  };

  const getTime = (row) => {
    if ([orderStatus.COMPLETED, orderStatus.DELIVERED].includes(row?.state))
      return <span>---</span>;
    if (
      isScheduled(row) &&
      row?.state !== orderStatus.COMPLETED &&
      row?.state !== orderStatus.CANCELLED_BY_RESTAURANT &&
      row?.state !== orderStatus.REJECTED_BY_RESTAURANT
    ) {
      return (
        <div className={`eta ${isEstimatedTimePassed(row) ? "eta--red" : ""}`}>
          <span className="eta--first-row">
            {moment(row?.scheduled_for).format("hh:mm")} -
            {moment(row?.scheduled_for).add(1, "hour").format("hh:mm")}
          </span>
          <span className="eta--sec-row">
            {moment(row?.scheduled_for).format("ddd, MMM DD")}
          </span>
        </div>
      );
    } else {
      return (
        <div className={`eta ${isEstimatedTimePassed(row) ? "eta--red" : ""}`}>
          <RealTime
            interval={30000}
            renderTime={() => {
              return handleEstimateTime(row);
            }}
          />
        </div>
      );
    }
  };

  return (
    <StyledTable>
      {/* Table */}
      <PaginateTable
        loading={ordersLoading}
        headers={
          noHeader
            ? [
                { title: "", width: "156px" },
                { title: "", width: "120px" },
                { title: "", width: "116px" },
                { title: "", width: "104px" },
                { title: "", width: "340px" },
                { title: "", width: "108px" },
                { title: "", width: "auto" },
              ]
            : [
                { title: "customer", width: "156px" },
                { title: "status", width: "120px" },
                { title: "Pick up time", width: "116px" },
                { title: "Type", width: "104px" },
                { title: "items", width: "240px" },
                { title: "net", width: "60px" },
                { title: "order source", width: "150px" },
                { title: "code", width: "auto" },
              ]
        }
        dataArray={ordersLoading ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] : orders}
        rangeEl={
          categoryTitle ? (
            <div
              className={`table-category ${
                noHeader ? "table-category--no-header" : ""
              }`}
            >
              <div className="table-category--left">
                <p className="table-category-title">{categoryTitle}</p>
              </div>
            </div>
          ) : null
        }
        rowCreators={[
          (row, index) => (
            <div className="name">
              {row?.new ? <div className="new-badge"></div> : null}
              <span>{row?.customer?.name}</span>
            </div>
          ),
          (row, index) => <div className="">{getStatus(row)}</div>,
          (row, index) => <div className="">{getTime(row)}</div>,
          (row, index) => <div className="order-type">{getOrderType(row)}</div>,
          (row, index) => <div className="items">{getOrderItems(row)}</div>,
          (row, index) => (
            <div className="net">${row?.restaurant_net?.toFixed(2)}</div>
          ),
          (row, index) => <div className="badge">{row?.order_source}</div>,
          (row, index) => <div className="code">{row?.number}</div>,
        ]}
        hideResults
        noRecord="There are no records to display."
        onClick={ordersLoading ? () => {} : handleOpenOrder}
        newRowClasses="new-row"
      />
    </StyledTable>
  );
};

export default Table;
