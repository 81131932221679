import * as types from "./actionTypes";

export const datesReducer = (state, action) => {
  switch (action.type) {
    case types.SET_SELECTED_DATE_TYPE_TITLE:
      return { ...state, selectedDateTypeTitle: action.payload };
    case types.SET_IS_WEEKLY:
      return { ...state, isWeekly: action.payload };
    case types.SET_WEEK_DAYS:
      return { ...state, weekdays: action.payload };
    case types.SET_FROM_HOUR:
      return { ...state, fromHour: action.payload };
    case types.SET_TO_HOUR:
      return { ...state, toHour: action.payload };
    case types.SET_IS_DAILY:
      return { ...state, isDaily: action.payload };
    case types.SET_HAS_END_DATE:
      return { ...state, hasEndDate: action.payload };
    case types.SET_START_DATE:
      return { ...state, startDate: action.payload };
    case types.SET_START_DATE_FROM_HOUR:
      return { ...state, startDateFromHour: action.payload };
    case types.SET_END_DATE:
      return { ...state, endDate: action.payload };
    case types.SET_END_DATE_FROM_HOUR:
      return { ...state, endDateFromHour: action.payload };
    case types.RESET_DATES:
      return {
        ...state,
        isWeekly: false,
        weekdays: "",
        fromHour: "",
        toHour: "",
        isDaily: false,
        hasEndDate: false,
        startDate: "",
        startDateFromHour: "",
        endDate: "",
        endDateFromHour: "",
      };
    case types.RESET_WEEKS:
      return {
        ...state,
        isWeekly: false,
        weekdays: "",
        fromHour: "",
        toHour: "",
      };
    case types.RESET_DAILY:
      return {
        ...state,
        isDaily: false,
        startDate: "",
        startDateFromHour: "",
        hasEndDate: false,
        endDate: "",
        endDateFromHour: "",
      };
    case types.RESET_END_DATE:
      return {
        ...state,
        hasEndDate: false,
        endDate: "",
        endDateFromHour: "",
      };
    case types.FILL_DATES_FIELDS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
