const initialState = {
  token: null,
  isLoggedIn: false,
  restaurants: [],
  info: {},
  selectedRestaurant: null,
  tutorial: "profile",
  tutorialSteps: 1,
  menus: [],
  permissions: [],
  selectedRestaurant: {},
};

export const user = (state = initialState, action) => {
  switch (action.type) {
    case "TOKEN":
      return { ...state, token: action.token };
    case "USER_LOGGED_IN":
      return { ...state, isLoggedIn: action.isLoggedIn };
    case "USER_INFO":
      return { ...state, info: action.info };
    case "USER_SELECTED_RESTAURANT_CHANGED":
      return { ...state, selectedRestaurant: action.selectedRestaurant };
    case "SET_SELECTED_RESTAURANT_DATA":
      return {
        ...state,
        selectedRestaurantData: action.selectedRestaurantData,
      };
    case "USER_RESTAURANTS_CHANGED":
      return { ...state, restaurants: action.restaurants };
    case "TUTORIAL":
      return {
        ...state,
        tutorial: action.tutorial,
        tutorialSteps: action.tutorialSteps,
      };
    case "MENUS":
      return { ...state, menus: action.menus };
    case "UPDATE_MY_PERMISSIONS":
      return { ...state, permissions: action.permissions };
    case "PURGE_USER":
      return initialState;
    default:
      return state;
  }
};
