import {
  faCheck,
  faFileUpload,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useDropzone } from "react-dropzone";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { connect } from "react-redux";
import styled from "styled-components";

import { makeAlert } from "actions";
import { editImages, fetchImagesByRestaurantId, uploadImage } from "api/images";
import CustomButton from "components/button";
import Loading from "components/Loading";
import { subtractArrays } from "utils";

import { Colors } from "assets/colors";
import { colors, IMAGE_VALID_FORMATS, IMAGE_VALID_SIZE } from "../../constants";
import { getData } from "../../utils";
import { Error } from "./ItemModal";

import "react-lazy-load-image-component/src/effects/blur.css";

const StyledInputGroup = styled(InputGroup)`
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.14);
  border-radius: 25px;

  .input-group-text {
    border: none;
    background-color: transparent;
  }

  .form-control {
    border: none;
    border-radius: 25px;
  }
`;
const DropZone = styled.div`
  border: 1px solid #666;
  background-color: #eee;
  padding: 25px;
  border-radius: 5px;
`;
const ImagesList = styled.ul`
  max-width: none;
  white-space: nowrap;

  -webkit-overflow-scrolling: touch;

  li {
    width: 145px;
  }
`;
const ImageWrap = styled.div`
  display: flex;
  overflow-x: scroll;

  .yours {
    position: sticky;
    left: 0;
    background: #fff;
    z-index: 9999;
  }

  hr {
    height: auto;
    border-right: 1px solid #999;
  }

  h5 {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    padding: 0 5px 0 10px;
    margin: 0;
  }
`;

const CustomRow = styled(Row)`
  min-height: 300px;
  margin-bottom: 0.5rem;
`;
const Item = styled.button`
  background: ${Colors.white};

  display: flex;
  justify-content: flex-end;
  ${"" /* justify-content: space-between; */}
  justify-content: flex-end;
  flex-direction: column;
  width: 145px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  border: 1.5px solid ${(p) => (p.sel ? "#68d300" : "#BDBBBB")};
  display: flex;
  height: 130px;
  margin: 0 5px;
  padding: 7px;
  color: #373636;
  flex: 1 0 21%;
  position: relative;
  /* max-width: 21%; */

  .title {
    font-weight: bold;
    text-align: left;
    padding-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }

  .price {
    font-weight: bold;
    margin: 5px 0;
    padding-bottom: 5px;
  }

  .description {
    font-size: 15px;
    font-size: 15px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .name {
    width: 100px;
    text-align: left;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  span.badge {
    width: 18px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18px;
    background: #68d300;
    margin-bottom: 5px;

    svg {
      width: 11px !important;
    }
  }

  img {
    height: 90px;
    object-fit: cover;
    width: 100%;
    border-radius: 5px;
  }
`;

const ItemComp = ({
  i,
  item,
  selected,
  select,
  handleDeleteImage,
  sel,
  name,
}) => {
  const isSel = selected.find((e) => e.id === item.id) || sel;

  return (
    <Item
      className={i === 0 ? "step-1" : ""}
      key={i}
      sel={isSel}
      onClick={() => select(item)}
    >
      <div className="header">
        {/* <span className='name'>{item.name}</span> */}
        {isSel ? (
          <span className="badge" onClick={() => handleDeleteImage(item.id)}>
            <FontAwesomeIcon icon={faCheck} color={"#fff"} />
          </span>
        ) : (
          <span />
        )}
      </div>
      <img src={item.image_med} effect="blur" />
    </Item>
  );
};

const SelectImage = ({ locale, ...props }) => {
  const { selected, setSelected } = props;
  const [loading, setLoading] = useState(true);
  const [addLoading, setAddLoading] = useState(false);
  const [allRestaurantImages, setAllRestaurantImages] = useState([]);
  const [stockImages, setStockImages] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [errors, setErrors] = useState([]);

  // const [page, setPage] = useState({ no: 1, next: null })
  //
  // const getImage = (pageNo) => {
  //   if (!loading) {
  //     setLoading(true)
  //     props.fetchImagesByRestaurantIdprops(pageNo, props.selectedRestaurant).then(res => {
  //       setAllRestaurantImages((prevState) => {
  //         const restImages = res.reverse().map(item => {
  //           const name = genName(item)
  //           return {...item, name}
  //         })
  //         const nextState = [...prevState, ...restImages]
  //
  //         setFilteredItems(nextState);
  //         return nextState
  //       });
  //
  //       setPage({ no: pageNo, next: res.next })
  //       setLoading(false)
  //     })
  //   }
  // }

  const onDrop = useCallback((files) => {
    const reader = new FileReader();
    const url = reader.readAsDataURL(files[0]);
    const filesize = (files[0].size / 1000).toFixed(4); // KB
    const validFileType = IMAGE_VALID_FORMATS.includes(
      files[0].type.split("image/")[1]
    );

    setErrors([]);
    let tempErrors = [];

    if (filesize > IMAGE_VALID_SIZE) tempErrors.push("imageFileSize");
    if (!validFileType) tempErrors.push("validImageFileType");

    if (tempErrors.length === 0) {
      reader.onloadend = function (e) {
        setAddLoading(true);
        props
          .uploadImage({
            image: files[0],
            restaurants: [props.selectedRestaurant],
          })
          .then((res) => {
            setAddLoading(false);
            setAllRestaurantImages((prevState) => {
              return [getData(res), ...prevState];
            });
            setSelected((prevState) => {
              return !props.multiple
                ? [getData(res)]
                : [getData(res), ...prevState];
            });
          });
      };
    } else setErrors(tempErrors);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const genName = (item) => {
    let name = "";
    const url = item.image_med && item.image_med.split("/");
    if (url) {
      name = url && url[url.length - 1];
      // .split('-med')[0]
    }
    return name;
  };

  useEffect(() => {
    setLoading(true);
    props.fetchImagesByRestaurantId(1, props.selectedRestaurant).then((res) => {
      let restImages = getData(res)
        ?.reverse()
        .map((item) => {
          const name = genName(item);
          return { ...item, name };
        });

      restImages = [...selected, ...subtractArrays(restImages, selected, "id")];

      let stock = restImages.filter((r) => r.stock);
      let nonStock = restImages.filter((r) => !r.stock);

      setAllRestaurantImages(restImages);
      setStockImages(stock);
      setFilteredItems(nonStock);
      // setPage({ no: 1, next: res.next })
      setLoading(false);
      if (props.intro) {
        setTimeout(() => {
          props.startIntro(props.intro);
        }, 500);
      }
    });
  }, [props.selectedRestaurant]);

  const handleChange = (e) => {
    const searchSorted = allRestaurantImages.filter((a) =>
      a.name.includes(e.target.value)
    );
    setFilteredItems(searchSorted);
  };

  const select = (item) => {
    let isExist = selected.find((e) => e.id === item.id);
    if (!props.multiple)
      isExist ? handleDeleteImage(item.id) : setSelected([item]);
    else
      isExist ? handleDeleteImage(item.id) : setSelected([...selected, item]);

    if (props.setJoyrideStepIndex) props.setJoyrideStepIndex(1);
  };

  const handleDeleteImage = (id) => {
    let tmp = Array.from(selected);
    let index = tmp.findIndex((el) => el.id === id);
    if (index > -1) tmp.splice(index, 1);
    setSelected(tmp);
  };

  const nextStep = () => {
    let tempErrors = errors;

    if (!props.required || selected.length) {
      if (props.nextStep) {
        props.nextStep();
      } else {
        props.setForm({ ...props.form, selected, fromSelectImage: true });
        props.setCreateItemStep("selected");

        if (props.run) {
          props.setRun(false);
          props.setJoyrideStepIndex(2);
          setTimeout(() => {
            props.setRun(true);
          }, 1000);
        }
      }
    } else {
      setErrors((errors) => [...errors, "imageFile"]);
    }
  };

  return (
    <Container>
      <Row>
        <Col md={12} className="text-center mb-2">
          <h3 className="text-center text-dark font-weight-bold">
            {props.header ? props.header : locale.create_item_title}
          </h3>
          {props.subHeader ? props.subHeader : locale.select_image_subtitle}
        </Col>
      </Row>

      <Row className="mt-5">
        <Col md={12}>
          <p className="font-weight-bold mb-0">
            {props.subHeader ? props.subHeader : locale.select_a_photo}
          </p>
          {
            //   props.header ? ""
            //     : <p>
            //       Picking one of these item photos will autofill your items details for you.
            // </p>
          }
        </Col>
        {/* <Col md={6} sm={12}>
          <StyledInputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="Search for an item"
              aria-label="item"
              aria-describedby="basic-addon1"
              onChange={handleChange}
            />
          </StyledInputGroup>
        </Col> */}
      </Row>

      <CustomRow className="overflow-auto pr-3">
        {loading ? (
          <Loading />
        ) : (
          <>
            <ImageWrap md={12}>
              {/* <h5>Stock Images</h5>
                  <ImagesList className="pr-3" style={{ padding: 0, columnCount: (stockImages.length / 2).toFixed(), listStyle: "none" }}>
                    {
                      stockImages.map((item, i) => (
                        <li className="mb-3" key={item.id || i}><ItemComp i={i} item={item} selected={selected} sel={item.sel}
                          handleDeleteImage={handleDeleteImage} select={select} /></li>
                      ))
                    }
                  </ImagesList>
                  <hr />
                  <h5 className='yours'>Your Images</h5> */}
              <ImagesList
                className="pr-3"
                style={{
                  padding: 0,
                  columnCount: (allRestaurantImages.length / 2).toFixed(),
                  listStyle: "none",
                }}
              >
                {allRestaurantImages.map((item, i) => (
                  <li className="mb-3" key={item.id || i}>
                    <ItemComp
                      i={i}
                      item={item}
                      selected={selected}
                      sel={item.sel}
                      handleDeleteImage={handleDeleteImage}
                      select={select}
                    />
                  </li>
                ))}
              </ImagesList>
            </ImageWrap>
          </>
        )}
      </CustomRow>

      <Row className="mb-3">
        <Col>
          {
            // page.next
            //   ? <Button variant={"light"} onClick={() => getImage(page.no + 1)}>
            //     Load More
            //   </Button>
            //   : null
          }
        </Col>
      </Row>
      {
        // <>
        //   <hr />
        //
        //   <Row className="align-item-center justify-content-center my-4">
        //     <p className="font-weight-bold text-center m-0">Selected</p>
        //   </Row>
        //   <Row className="my-4">
        //     <ImageWrap md={12}>
        //       {
        //         images.map((item, i)=>{
        //           return (
        //               <ItemComp key={i} i={i} item={item} selected={selected}
        //                 handleDeleteImage={handleDeleteImage} select={select}/>
        //           )
        //         })
        //       }
        //     </ImageWrap>
        //   </Row>
        //
        //   <hr />
        // </>
      }
      <Row>
        <Col md={12}>
          <p className="font-weight-bold mb-0">{locale.upload_a_photo}</p>
          <DropZone {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p className="text-center">{locale.drop_file_here}</p>
            ) : (
              <p className="text-center">
                {locale.drag_and_drop}
                <br />
                {locale.or}
                <br />
                <a className="btn btn-light rounded-pill shadow-sm">
                  <span
                    className="font-weight-bold"
                    style={{ color: colors.orange }}
                  >
                    {locale.choose_file}
                  </span>
                </a>
                <div
                  style={{
                    textAlign: "center",
                    color: "#222",
                    margin: 0,
                    marginTop: 15,
                  }}
                >
                  <Error
                    locale={locale}
                    text={locale.image_size_error}
                    type="info"
                  />
                  <br />
                  <Error
                    locale={locale}
                    text={locale.image_type_error}
                    type="info"
                  />
                </div>
              </p>
            )}
          </DropZone>
          {errors.includes("imageFile") && <Error locale={locale} />}
          {errors.includes("imageFileSize") && (
            <Error locale={locale} text={locale.image_size_error} />
          )}
          {errors.includes("validImageFileType") && (
            <Error locale={locale} text={locale.image_type_error} />
          )}
        </Col>
      </Row>

      <Row className="alightn-itemsp-center justify-content-center my-3">
        <CustomButton
          loading={addLoading}
          className="px-5 step-3"
          onClick={nextStep}
        >
          {locale.next}
        </CustomButton>
        <Button
          variant="light"
          onClick={() => props.setModal({ show: false, type: null, id: null })}
        >
          {locale.cancel_button}
        </Button>
      </Row>
    </Container>
  );
};

export default connect(
  (state) => ({
    selectedRestaurant: state.user.selectedRestaurant,
    locale: state.locale[state.locale.active],
  }),
  { makeAlert, editImages, fetchImagesByRestaurantId, uploadImage }
)(SelectImage);
